import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Input, ValidationContext } from '../../utils/inputs';
import { Filter, FilterTypes, MultiSelectFilter } from '../../utils/FilterTypes';
import { EntitlementCategoriesConfig } from '../../app/appConfig';
import * as _ from 'lodash';
import { ZeroingOption } from '../BeneficiaryActivitiesPage';
import { FormEvent } from 'react';
interface Props {
    categories?: EntitlementCategoriesConfig;
    title: string;
    subTitle?: React.ReactElement<'div'>;
    confirmTitle: string;
    onClose: () => void;
    onConfirm: (reason: string, source: string, zeroingOption?: ZeroingOption) => void;
    selectedOptions?: Filter[];
    withTimeRangeLimit?: boolean;
    onFilterChange: (v) => any;
}
interface State {
    reason?: string;
    source?: string;
    selectedOptions?: Filter[];
}

export default class SourceReasonDialog extends React.Component<Props, State> {
    _callOnFilterChanged: (filter) => void;
    static defaultProps = {
        onFilterChange: (v) => v,
    };
    constructor(props) {
        super(props);

        this._callOnFilterChanged = _.noop;
        this.state = {
            source: '',
            reason: '',
            selectedOptions: _.cloneDeep(this.props.selectedOptions),
        };
    }

    validationContext = new ValidationContext();

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.selectedOptions !== this.props.selectedOptions) {
            this.setState({ selectedOptions: _.cloneDeep(nextProps.selectedOptions) });
        }
    }

    handleInputChange({ target }) {
        const { name, value } = target;
        this.setState({ [name]: value });
    }

    private filterChanged = (filter: Filter) => {
        return (event: FormEvent<HTMLInputElement>) => {
            const target = event.currentTarget;
            if (target !== undefined) {
                const newValue = target.value;
                this.setState((prevState: State) => {
                    const selectedFilters = prevState.selectedOptions;
                    const filterToChange: Filter = selectedFilters.find((f) => f.name === filter.name);
                    filterToChange.value = newValue;
                    this._callOnFilterChanged(filterToChange);

                    return { selectedOptions: selectedFilters };
                });
            }
            const newValue = event;
            this.setState((prevState) => {
                const selectedFilters = prevState.selectedOptions;
                const filterToChange: Filter = selectedFilters.find((f) => f.name === filter.name);
                filterToChange.isSelected = true;
                filterToChange.value = this.props.onFilterChange(newValue);
                this._callOnFilterChanged(filterToChange);
                return { selectedOptions: selectedFilters };
            });
        };
    };

    renderInputField(options: { readonly: boolean; value: string; name: string; required: boolean; type: string }) {
        return (
            <div className="col-sm-8">
                {options.readonly && <label className="normal">{options.value}</label>}
                {!options.readonly && (
                    <Input
                        className="form-control"
                        context={this.validationContext}
                        name={options.name}
                        onChange={this.handleInputChange.bind(this)}
                        required={options.required}
                        type={options.type}
                        value={options.value}
                    />
                )}
            </div>
        );
    }

    renderMultiSelectField(options: {
        required: boolean;
        filter: MultiSelectFilter;
        type: string;
        onlyOption?: string;
    }) {
        const filter = options.filter;
        return (
            <div className="col-sm-8">
                <Input
                    context={this.validationContext}
                    name={filter.name}
                    onChange={this.filterChanged(filter).bind(this)}
                    onlyOption={options.onlyOption}
                    options={filter.options}
                    required={options.required}
                    type={options.type}
                    value={filter.value}
                />
            </div>
        );
    }

    private renderZeroBalanceOption() {
        return (
            <div>
                {this.state.selectedOptions &&
                    this.state.selectedOptions.map((selectOption) => (
                        <div className="wfp-form--group row" key={selectOption.name}>
                            <label className="col-sm-4 ta-right">{selectOption.label}: </label>
                            {this.renderMultiSelectField({
                                required: true,
                                filter: selectOption as MultiSelectFilter,
                                onlyOption: selectOption.onlyOneOption,
                                type: selectOption.type === FilterTypes.select ? 'select' : 'multiSelect',
                            })}
                        </div>
                    ))}
            </div>
        );
    }

    renderInputFields() {
        return (
            <div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Source: </label>
                    {this.renderInputField({
                        readonly: false,
                        value: this.state.source,
                        name: 'source',
                        required: true,
                        type: 'text',
                    })}
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Reason: </label>
                    {this.renderInputField({
                        readonly: false,
                        value: this.state.reason,
                        name: 'reason',
                        required: true,
                        type: 'text',
                    })}
                </div>
            </div>
        );
    }

    onSubmit(event) {
        event.preventDefault();
        const { reason, source, selectedOptions } = this.state;
        if (selectedOptions) {
            const selectedCategory = selectedOptions.find((filter) => filter.name === 'category').value;
            const selectedType = selectedOptions.find((filter) => filter.name === 'type').value;

            const zeroingOption = {
                category: selectedCategory.map((category) => category.value),
                availabilityType: selectedType.value,
            };
            this.props.onConfirm(reason, source, zeroingOption);
        } else {
            this.props.onConfirm(reason, source);
        }
    }

    render() {
        const { onClose, title, confirmTitle, subTitle } = this.props;
        return (
            <div>
                <div className="fade modal-backdrop in" />
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>
                            {title}
                            {subTitle && subTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Form context={this.validationContext} onSubmit={this.onSubmit.bind(this)}>
                        <Modal.Body>
                            {this.renderZeroBalanceOption()}
                            {this.renderInputFields()}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
                                Close
                            </button>
                            <button className="wfp-btn--primary" type="submit">
                                {confirmTitle}
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal.Dialog>
            </div>
        );
    }
}
