import * as React from 'react';
import { TransactionRequest } from './taskAuthorizations';
import { currencyFormatter, displayTime } from '../utils/utils';
import { TransactionType } from '../transactions/transactions';
import { BigNumber } from '../utils/bigNumber';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import { dispatch } from '@wfp-root-app/store/store';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

const columnStyle: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
};

const flexColumn: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
};

const flexRow: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
};

const CommentCellHeader: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
};

const CommentCellLabel: React.CSSProperties = {
    marginRight: 5,
};

const CommentCellContainer: React.CSSProperties = {
    display: 'flex',
    marginBottom: 10,
    flexDirection: 'column',
    padding: 20,
};

interface Props {
    isRequested: boolean;
    transactionRequest: TransactionRequest;
    readonly: boolean;
    authorizedManagerId: string;
    currency: EntitlementCurrencyConfig;
    onPost: (transactionRequestId: string, originalTransactionRequestId: number, amount: number) => void;
    onReject: (transactionRequestId: string, originalTransactionRequestId: number) => void;
}

interface ValueFieldProps {
    title: string;
    value: string;
    isGreen?: boolean;
}
function ValueField(props: ValueFieldProps) {
    const textColor = props.isGreen ? { color: 'green' } : null;
    return (
        <div style={Object.assign({ marginBottom: 15, marginRight: 80 }, textColor)}>
            <label style={CommentCellLabel}>{props.title}</label>
            <p style={{ display: 'inline', float: 'right' }}>{props.value}</p>
        </div>
    );
}

function senderFieldProps(transactionRequest: TransactionRequest): ValueFieldProps {
    if (transactionRequest.senderId === transactionRequest.vendor.id.toString()) {
        const value = transactionRequest.vendor.name || transactionRequest.vendor.id.toString();
        return { title: 'Sender:', value };
    }
    return { title: 'Sender:', value: transactionRequest.senderId };
}

function receiverFieldProps(transactionRequest: TransactionRequest): ValueFieldProps {
    if (transactionRequest.receiverId === transactionRequest.vendor.id.toString()) {
        const value = transactionRequest.vendor.name || transactionRequest.vendor.id.toString();
        return { title: 'Receiver:', value };
    }
    return { title: 'Receiver:', value: transactionRequest.receiverId };
}

function renderTimeEntry(title: string, time: string, name: string) {
    return (
        <div style={flexRow}>
            <label>{title}</label>
            <p style={{ marginLeft: 5 }}>
                {displayTime(time)} by {name}
            </p>
        </div>
    );
}

function renderTimeEntries(transactionRequest: TransactionRequest) {
    const auth = transactionRequest.authorization;
    const { createdAt, authorizedAt, deletedAt } = auth;
    const createdName = auth.createdByManager ? auth.createdByManager.name : '-';
    const authorizedName = auth.authorizedByManager ? auth.authorizedByManager.name : '-';
    const deletedName = auth.deletedByManager ? auth.deletedByManager.name : '-';
    return (
        <div>
            {auth.createdAt && renderTimeEntry('Parked at:', createdAt, createdName)}
            {auth.authorizedAt && renderTimeEntry('Posted at:', authorizedAt, authorizedName)}
            {auth.deletedAt && renderTimeEntry('Rejected at:', deletedAt, deletedName)}
        </div>
    );
}

function onTransactionId(transactionId: number) {
    dispatch(routerHelperActions.makeRedirect('/transactions/' + transactionId));
}

function countAmountByType(transactionRequest, originalTransaction, entitlement, isPosted): BigNumber {
    if (!isPosted) {
        return transactionRequest.type === TransactionType.spendBalance
            ? new BigNumber(originalTransaction).minus(new BigNumber(entitlement))
            : new BigNumber(originalTransaction).plus(new BigNumber(entitlement));
    }
    return transactionRequest.type === TransactionType.spendBalance
        ? new BigNumber(originalTransaction).plus(new BigNumber(entitlement))
        : new BigNumber(originalTransaction).minus(new BigNumber(entitlement));
}

export default function TransactionRequestCell(props: Props) {
    const { transactionRequest, onPost, onReject, readonly, authorizedManagerId } = props;
    const isNotPosted = !transactionRequest.authorization.authorizedAt || !!transactionRequest.authorization.deletedAt;
    const entitlement = transactionRequest.entitlements[0];
    const receiver = receiverFieldProps(transactionRequest);
    const sender = senderFieldProps(transactionRequest);
    const originalTransaction = transactionRequest.originalTransaction;
    const secondLineLabel = isNotPosted ? 'Current Amount: ' : 'Previous Amount: ';
    const thirdLineLabel = isNotPosted ? 'New Amount: ' : 'Current Amount: ';
    const fourthLineLabel = 'Category: ';

    const newAmount = isNotPosted
        ? Number(
              countAmountByType(transactionRequest, originalTransaction.currentAmount, entitlement.value, isNotPosted)
          )
        : originalTransaction.currentAmount;
    const currentOrBeforeAdjustment = isNotPosted
        ? originalTransaction.currentAmount
        : Number(
              countAmountByType(transactionRequest, originalTransaction.currentAmount, entitlement.value, isNotPosted)
          );

    return (
        <div className="shadowed-container" style={{ ...CommentCellContainer }}>
            <div style={CommentCellHeader}>
                <div style={flexColumn}>
                    <div style={flexRow}>
                        <label>ID:</label>
                        <p style={{ marginLeft: 5 }}>{transactionRequest.id}</p>
                    </div>
                    <div style={flexRow}>
                        <label>Original Transaction ID:</label>
                        <a
                            onClick={() => onTransactionId(originalTransaction.id)}
                            style={{ marginLeft: 5, border: 'none' }}
                        >
                            {originalTransaction.id}
                        </a>
                    </div>
                </div>
                <div style={flexColumn}>{renderTimeEntries(transactionRequest)}</div>
            </div>
            <div style={flexRow}>
                <div style={columnStyle}>
                    <ValueField {...sender} />
                    <ValueField {...receiver} />
                    <ValueField title="Source: " value={transactionRequest.source || '-'} />
                    <ValueField title="Reason: " value={transactionRequest.reason || '-'} />
                    {transactionRequest.authorization.comment && (
                        <ValueField title="Comment: " value={transactionRequest.authorization.comment} />
                    )}
                </div>
                <div style={columnStyle}>
                    <ValueField
                        title="Original Amount: "
                        value={currencyFormatter(props.currency).format(originalTransaction.originalAmount)}
                    />
                    <ValueField
                        title={secondLineLabel}
                        value={currencyFormatter(props.currency).format(currentOrBeforeAdjustment)}
                    />
                    <ValueField
                        isGreen={true}
                        title={thirdLineLabel}
                        value={currencyFormatter(props.currency).format(newAmount)}
                    />
                    <ValueField title={fourthLineLabel} value={originalTransaction.category} />
                    {transactionRequest.authorization.createdByManager.id !== authorizedManagerId && (
                        <div
                            style={{
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                ...columnStyle,
                            }}
                        >
                            {!readonly && (
                                <div className="wfp-form--actions">
                                    <button
                                        className="wfp-btn negative-color"
                                        onClick={() => {
                                            onReject(transactionRequest.id, originalTransaction.id);
                                        }}
                                        type="button"
                                    >
                                        Reject
                                    </button>
                                    <button
                                        className="wfp-btn"
                                        onClick={() => {
                                            onPost(transactionRequest.id, originalTransaction.id, newAmount);
                                        }}
                                        style={{ marginLeft: 10 }}
                                        type="button"
                                    >
                                        Post
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
