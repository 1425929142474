import { Products } from '../types/product.type';
import { Outlets } from '../types/outlet.type';
import { SortOptions } from '../../utils/hooks/useSort';
import {
    checkProductGroupPendingUpdateRequests,
    checkProductGroupScheduledUpdates,
    findOutletsByGroupId,
    findProductsByGroupId,
} from '../../api/product-management';
import { PriceVersion, ProductVersionType } from '../types/product-version.type';

export interface LoadPrices {
    products: Products;
    outlets: Outlets;
    hasPendingUpdateRequest: boolean;
    scheduledUpdates: ProductVersionType[];
    metadata: PriceVersion;
}

export async function loadPrices(groupId: string, timezone: string): Promise<LoadPrices> {
    const [products, outlets, hasPendingUpdateRequest, scheduledUpdates] = await Promise.all([
        getProductData(groupId, null, timezone),
        getOutlets(groupId),
        checkProductGroupPendingUpdateRequests(groupId),
        checkProductGroupScheduledUpdates(groupId),
    ]);
    return {
        metadata: products.metadata,
        products: products.data,
        outlets,
        hasPendingUpdateRequest,
        scheduledUpdates,
    };
}

export async function getProductData(
    groupId: string,
    sortOptions?: SortOptions,
    timezone?: string
): Promise<{ metadata: PriceVersion; data: Products }> {
    const prices = await findProductsByGroupId(groupId, new Date(), sortOptions, timezone);
    return {
        metadata: prices.metadata,
        data: prices.data.reduce((acc, price) => {
            const _product = {
                name: price.productName,
                minPrice: price.minPrice,
                maxPrice: price.maxPrice,
                productId: price.productId,
                priceId: price.priceId,
                isFixed: price.isFixed,
            };
            return [...acc, _product];
        }, []),
    };
}

export async function getOutlets(groupId: string, sortOptions?: SortOptions): Promise<Outlets> {
    const outlets = await findOutletsByGroupId(groupId, new Date(), sortOptions);
    return outlets.reduce(
        (outlets, branchCode) => ({
            ...outlets,
            [branchCode.outlet]: [...(outlets[branchCode.outlet] || []), branchCode],
        }),
        {}
    );
}
