import { RelatedRowProcessing, RelatedTransactionResponse } from '../activityLogs';
import { compose, currencyFormatter, separateCamelCase, wfpFormat } from '../../utils/utils';
import * as React from 'react';
import { TransactionTypes } from '../../transactions/transactions';
import { PredefinedCurrency } from '../../app/appConfig';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

export function transactionsView(relatedTransactions: RelatedTransactionResponse[], currency: PredefinedCurrency) {
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {relatedTransactions.length > 0 ? (
                <table className="wfp-table--striped ">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Category</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Transaction Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relatedTransactions.map((relatedTransaction) => {
                            return (
                                <tr key={`${relatedTransaction.id}-row`}>
                                    <td>
                                        <a onClick={() => navigate('/transactions/' + relatedTransaction.id)}>
                                            {relatedTransaction.id}
                                        </a>
                                    </td>
                                    <td>{relatedTransaction.category}</td>
                                    <td>{currencyFormatter(currency).format(relatedTransaction.amount)}</td>
                                    <td>{TransactionTypes.displayName(relatedTransaction.type)}</td>
                                    <td>{relatedTransaction.status}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p>No transaction has been created</p>
            )}
        </div>
    );
}

export function importUpdatesView(relatedRowProcessing: RelatedRowProcessing) {
    const feautures = Object.keys(relatedRowProcessing).filter(
        (feature) => !!relatedRowProcessing[feature] && feature !== 'reason'
    );
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {feautures ? (
                <table className="wfp-table--striped ">
                    <thead>
                        <tr>
                            {feautures.map((feature) => (
                                <th key={feature}>{compose(wfpFormat, separateCamelCase)(feature)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {feautures.map((feature) => (
                                <td key={feature}>{relatedRowProcessing[feature]}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            ) : (
                <p>No beneficiary details has been updated</p>
            )}
        </div>
    );
}
