import * as React from 'react';
import { compose, removePrefix, separateCamelCase, wfpFormat } from '../utils/utils';
import { useTransactionDetails } from './helpers/useTransactionDetails';
interface Props {
    transactionId: string;
    actionCreator: (dispatch) => (transactionId: string) => void;
}

function TransactionDetails({ transactionId, actionCreator }: Props) {
    const transactionDetails = useTransactionDetails(transactionId, actionCreator);
    if (!Array.isArray(transactionDetails)) {
        return null;
    }
    return (
        <div className="py-5">
            <h4>Details</h4>
            <table className="wfp-table mt4">
                <tbody>
                    {transactionDetails.map(({ id, ...transaction }, index) => {
                        const entries = Object.entries(transaction);
                        const style = index !== 0 && { marginTop: '25px' };
                        return (
                            <React.Fragment key={id}>
                                <tr>
                                    <td colSpan={2}>
                                        <h6 style={{ ...style }}>Transaction #{id}</h6>
                                    </td>
                                </tr>
                                {entries.map(([key, value]) => (
                                    <tr key={key}>
                                        <td>
                                            <strong>
                                                {compose<string, string>(
                                                    wfpFormat,
                                                    removePrefix('display name for'),
                                                    separateCamelCase
                                                )(key)}
                                            </strong>
                                        </td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default React.memo(TransactionDetails);
