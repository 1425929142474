import * as React from 'react';
import Switch from 'rc-switch';
import * as moment from 'moment-timezone';

import { ProductContainer, productStatuses, InputField, TabType } from './helpers';
import { Form } from '../utils/inputs';
import { useProductDetails } from './hooks/useProductDetails';
import { wfpFormat } from '../utils/utils';
import { Select } from '../utils/form/fields/Select';

export const ProductsDetails: React.FC<{ params: { productId: string } }> = function ProductsDetails({ params }) {
    const { editing, product, productId, toggleEdit, handleSubmit, handleChange, categories, tz } = useProductDetails(
        params
    );

    if (!product) return <div>loading...</div>;

    return (
        <ProductContainer activeTab={TabType.main} product={product} productId={productId}>
            <div>
                <h6>General Information</h6>
                <Form className="wfp-form" onSubmit={handleSubmit}>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Name</label>
                        <InputField
                            autoFocused={true}
                            maxLength={35}
                            name="name"
                            onChange={handleChange}
                            readonly={!editing}
                            required={true}
                            type="text"
                            value={product.name}
                        />
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Category</label>
                        <div className="col-sm-8">
                            {editing ? (
                                <Select
                                    name="category"
                                    onChange={handleChange as any}
                                    options={categories}
                                    value={product.category}
                                />
                            ) : (
                                wfpFormat(product.category)
                            )}
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Quantity Option</label>
                        <InputField
                            autoFocused={true}
                            maxLength={35}
                            name="quantityOption"
                            onChange={handleChange}
                            readonly={!editing}
                            required={true}
                            type="text"
                            value={product.quantityOption}
                        />
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Unit</label>
                        <InputField
                            autoFocused={true}
                            maxLength={35}
                            name="unit"
                            onChange={handleChange}
                            readonly={!editing}
                            required={true}
                            type="text"
                            value={product.unit}
                        />
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Status</label>
                        <div className="col-sm-8">
                            {editing ? (
                                <Select
                                    name="status"
                                    onChange={handleChange as any}
                                    options={productStatuses}
                                    value={product.status}
                                />
                            ) : (
                                wfpFormat(product.status)
                            )}
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Created By</label>
                        <div className="col-sm-8">
                            <label className="normal">{product.createdByManager}</label>
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Created At</label>
                        <div className="col-sm-8">
                            <label className="normal">
                                {moment(product.createdAt).tz(tz).format('DD/MM/YYYY HH:mm')}
                            </label>
                        </div>
                    </div>
                    {editing && (
                        <div className="wfp-form--actions text-center">
                            <div className="col-sm-8 col-sm-offset-4">
                                <button className="wfp-btn--primary" type="submit">
                                    Park & Post Changes
                                </button>
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        </ProductContainer>
    );
};
