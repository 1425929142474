import { LoadPrices } from '../../utils/load-prices.utils';
import { EventType, FieldState, ReducerActions, ReducerState } from './use-product-update.types';
import { normalizePriceFormat } from './use-product-update.utils';
import { ProductType } from '../../types/product.type';

export function createReducer(maxPriceValue: number) {
    return function reducer(state: ReducerState, event: ReducerActions): ReducerState {
        let updatedProduct = {} as ProductType & FieldState;
        switch (event.type) {
            case EventType.INITIALIZE:
                return createInitialState(event.prices);
            case EventType.MARKED_FOR_REMOVAL:
                const isNew = state.products[event?.productName]?.isNew;
                const { [event.productName]: _, ...products } = state.products;
                if (isNew) {
                    return {
                        ...state,
                        products,
                    };
                }
                updatedProduct = {
                    ...(state?.products?.[event.productName] || null),
                    markedForRemoval: event.value,
                };
                break;
            case EventType.ADD_PRODUCT:
                updatedProduct = { ...event.value };
                break;
            case EventType.EDIT:
                updatedProduct = {
                    ...(state?.products?.[event.productName] || null),
                    [event.field]: event.value,
                };
                break;
            default:
                // exhaustiveness check
                const x: never = event;
                break;
        }
        updatedProduct.error = updatedProduct.markedForRemoval
            ? false
            : validate(updatedProduct.minPrice, updatedProduct.maxPrice, updatedProduct.isFixed, maxPriceValue);
        updatedProduct.edited = compare(state.initProducts[event.productName], updatedProduct);
        return {
            ...state,
            products: {
                ...state.products,
                [event.productName]: updatedProduct,
            },
        };
    };
}

const createInitialState = (group: Partial<LoadPrices>): ReducerState => {
    const products = group.products.reduce(
        (products, product) => ({
            ...products,
            [product.name]: {
                priceId: product.priceId,
                productId: product?.productId,
                name: product.name,
                minPrice: Number(product.minPrice).toFixed(2),
                maxPrice: product.maxPrice === null ? '' : Number(product.maxPrice).toFixed(2),
                edited: false,
                isFixed: product.isFixed,
            },
        }),
        {}
    );
    return {
        metadata: group.metadata,
        products,
        initProducts: products,
        outlets: Object.entries(group.outlets),
    };
};

function validate(minPrice, maxPrice, isFixed, maxPriceValue) {
    if (isNaN(minPrice) || isNaN(maxPrice)) {
        return 'Price has to be a number';
    }
    if (Number(maxPrice) > Number(maxPriceValue) || Number(minPrice) > Number(maxPriceValue)) {
        return 'Price cannot be higher than ' + normalizePriceFormat(maxPriceValue);
    }

    if (maxPrice !== '' && Number(minPrice) > Number(maxPrice)) {
        return 'Max price cannot be smaller than min price';
    }

    if (isFixed) {
        return normalizePriceFormat(minPrice) === normalizePriceFormat(maxPrice)
            ? ''
            : 'Fixed price requires min and max prices to be the same';
    } else {
        return normalizePriceFormat(minPrice) === normalizePriceFormat(maxPrice)
            ? 'Fixed price has to be checked as fixed'
            : '';
    }
}

function compare(initialState: ProductType, currentState: ProductType & FieldState): boolean {
    if (currentState.markedForRemoval) {
        return true;
    }
    if (currentState.isNew) {
        return true;
    }
    if (normalizePriceFormat(initialState.minPrice) !== normalizePriceFormat(currentState.minPrice)) {
        return true;
    }
    if (normalizePriceFormat(initialState.maxPrice) !== normalizePriceFormat(currentState.maxPrice)) {
        return true;
    }

    if (initialState.isFixed !== currentState.isFixed) {
        return true;
    }

    return false;
}
