import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActionCreators, Transaction, TransactionChain, TransactionStatuses, TransactionTypes } from '../transactions';
import State from '../../app/store/state';
import { hasFeatureAccess } from '../../login/auth';
import { stopPropagation } from '../../utils/events';
import {
    PaymentRequestStatus,
    PaymentRequestStatusesThatBlockTxModification,
} from '../../paymentRequests/paymentRequests';
import { ConfirmationDialog } from '../../utils/Dialogs';
import TransactionAdjustmentDialog from './TransactionAdjustmentDialog';
import { CreateTransactionRequestBody, createTransactionRequestBody } from './adjustments';
import { BigNumber } from '../../utils/bigNumber';
import { PredefinedCurrency } from '../../app/appConfig';
import { countries } from '../../utils/countries';
import { ManagerPermission } from '../../permission-profiles/permission';

interface TransactionAdjustmentOwnProps {
    transaction: Transaction;
}

interface TransactionAdjustmentStateProps {
    transactionChainToAdjust?: TransactionChain;
    canAdjustTransaction: boolean;
    currency: PredefinedCurrency;
    country: countries;
}

interface TransactionAdjustmentDispatchProps {
    loadTransactionChain(transactionId: string): void;
    adjustTransaction(request: CreateTransactionRequestBody, originalTransactionId: string): void;
}

type TransactionAdjustmentProps = TransactionAdjustmentOwnProps &
    TransactionAdjustmentStateProps &
    TransactionAdjustmentDispatchProps;

interface TransactionAdjustmentState {
    paidTransactionToConfirm?: Transaction;
    requestedTransactionId?: string;
}

export class TransactionAdjustment extends React.Component<TransactionAdjustmentProps, TransactionAdjustmentState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    private shouldRenderAdjustmentDialog(): boolean {
        const { transactionChainToAdjust } = this.props;

        return (
            transactionChainToAdjust &&
            transactionChainToAdjust.items.length &&
            this.state.requestedTransactionId === transactionChainToAdjust.items[0].id.toString()
        );
    }

    private requestAdjustingTransaction = () => {
        const requestedTransactionId = (
            this.props.transaction.originalTransactionId || this.props.transaction.id
        ).toString();
        this.setState({ requestedTransactionId });
        this.props.loadTransactionChain(requestedTransactionId);
    };

    private onCloseTransactionAdjustmentDialog = () => {
        this.setState({ requestedTransactionId: null });
    };

    private adjustTransaction = (
        transactionChain: TransactionChain,
        source: string,
        reason: string,
        amount: BigNumber,
        currency: PredefinedCurrency
    ) => {
        this.props.adjustTransaction(
            createTransactionRequestBody(transactionChain, source, reason, amount, currency),
            transactionChain.items[0].id.toString()
        );
        this.onCloseTransactionAdjustmentDialog();
    };

    private renderAdjustmentDialog = () => {
        const { transactionChainToAdjust, currency, country } = this.props;

        if (!this.shouldRenderAdjustmentDialog()) {
            return <span />;
        }

        return (
            <TransactionAdjustmentDialog
                country={country}
                currency={currency}
                onAdjust={this.adjustTransaction}
                onClose={this.onCloseTransactionAdjustmentDialog}
                transactionChain={transactionChainToAdjust}
            />
        );
    };

    private renderPaidTransactionAdjustmentConfirmation = () => {
        const { paidTransactionToConfirm } = this.state;
        return (
            paidTransactionToConfirm && (
                <ConfirmationDialog
                    message="The adjustments to the transaction already included in the Payment File will be accrued in the next Payment period. Do you want to continue?"
                    onClose={() => this.setState({ paidTransactionToConfirm: null })}
                    onConfirm={() => {
                        this.requestAdjustingTransaction();
                        this.setState({ paidTransactionToConfirm: null });
                    }}
                    title="The Payment File is Already Paid"
                />
            )
        );
    };

    render() {
        const { canAdjustTransaction, transaction } = this.props;

        if (transaction.pendingTransactionRequestId) {
            return <span>Adjustment Parked</span>;
        }

        const isAdjustableType = TransactionTypes.contributingToVendorPayment.includes(transaction.type);
        const isIncludedInPayment =
            transaction.paymentRequest && transaction.paymentRequest.status !== PaymentRequestStatus.Cancelled;
        const isPaymentPending =
            isIncludedInPayment &&
            PaymentRequestStatusesThatBlockTxModification.includes(transaction.paymentRequest.status);
        const isAdjustableStatus = TransactionStatuses.adjustableStatuses.includes(transaction.status);

        const canAdjust = canAdjustTransaction && isAdjustableType && isAdjustableStatus && !isPaymentPending;

        return (
            <span onClick={stopPropagation(() => {})}>
                {this.renderAdjustmentDialog()}
                {this.renderPaidTransactionAdjustmentConfirmation()}

                {canAdjust && !isIncludedInPayment && (
                    <a onClick={() => this.requestAdjustingTransaction()}>Park Adjustment</a>
                )}
                {canAdjust && isIncludedInPayment && (
                    <a
                        onClick={() =>
                            this.setState({
                                paidTransactionToConfirm: transaction,
                            })
                        }
                    >
                        Park Adjustment
                    </a>
                )}
            </span>
        );
    }
}

function mapStateToProps(state: State): TransactionAdjustmentStateProps {
    return {
        transactionChainToAdjust: state.transactionChain,
        canAdjustTransaction: hasFeatureAccess(state.auth, ManagerPermission.transactionRequestPark),
        currency: state.appConfig.entitlementCurrencyConfig,
        country: state.appConfig.country,
    };
}

function mapDispatchToProps(dispatch): TransactionAdjustmentDispatchProps {
    return {
        loadTransactionChain: bindActionCreators(ActionCreators.loadTransactionChain, dispatch),
        adjustTransaction: bindActionCreators(ActionCreators.adjustTransaction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionAdjustment);
