import * as React from 'react';
import { useCallback } from 'react';
import { Beneficiary, BeneficiaryStatus, BeneficiaryStatuses } from './beneficiaries';
import { displayTime, wfpFormat } from '../utils/utils';
import { SortableTableHead } from '../utils/table/table-head/sortable-table-head';
import { ChangeSortOptions, SortOptions } from '../utils/hooks/useSort';
import { BeneficiaryType } from './beneficiary-type';
import { useSelector } from 'react-redux';
import State from '../app/store/state';
import { countries } from '../utils/countries';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

interface Props {
    status: BeneficiaryStatus;
    defaultView: boolean;
    beneficiaries: Array<Beneficiary>;
    onSort(arg: any): void;
}
const columnToAttributeMapping = {
    'Beneficiary ID': 'id',
    Reason: 'statusInfo.reason',
    Source: 'statusInfo.source',
    Status: 'status',
    'Last Activity': 'lastActiveAt',
    'Last Transaction': 'lastTransactionAt',
    'Blocked at': 'statusChangedAt',
    'Block Reason': 'statusInfo.reason',
    'Block Source': 'statusInfo.source',
};
export default function BeneficiariesList({ status, defaultView, beneficiaries, onSort }: Props) {
    const navigate = useNavigate();

    const onBeneficiaryChosen = (beneficiary: Beneficiary) => () => {
        navigate('/beneficiaries/beneficiary', { beneficiaryId: beneficiary.id });
    };
    const handleSort: ChangeSortOptions = useCallback(([column, direction]) => {
        const options = new SortOptions({ column: columnToAttributeMapping[column], direction });
        onSort(options);
    }, []);
    const country = useSelector((state: State) => state.appConfig.country);
    return (
        <table className="wfp-table--striped mt4 table-hover">
            <SortableTableHead changeSortOptions={handleSort}>
                {country === countries.BANGLADESH ? <th>Type</th> : null}
                <th>Beneficiary ID</th>
                {defaultView && <th>Reason</th>}
                {defaultView && <th>Source</th>}
                {defaultView && <th>Status</th>}
                {(status === BeneficiaryStatuses.active || defaultView) && <th>Last Activity</th>}
                {(status === BeneficiaryStatuses.active || defaultView) && <th>Last Transaction</th>}
                {status === BeneficiaryStatuses.blocked && !defaultView && <th>Blocked at</th>}
                {status === BeneficiaryStatuses.blocked && !defaultView && <th>Block Reason</th>}
                {status === BeneficiaryStatuses.blocked && !defaultView && <th>Block Source</th>}
            </SortableTableHead>
            <tbody>
                {beneficiaries.map((beneficiary) => (
                    <tr
                        className={
                            'cursor-pointer ' + (beneficiary.status === BeneficiaryStatuses.blocked ? 'failed' : '')
                        }
                        key={beneficiary.id}
                        onClick={onBeneficiaryChosen(beneficiary)}
                    >
                        {country === countries.BANGLADESH ? (
                            <td>
                                <BeneficiaryType type={beneficiary.groupType} />
                            </td>
                        ) : null}
                        <td>{beneficiary.id}</td>
                        {defaultView && <td>{beneficiary.statusInfo && beneficiary.statusInfo.reason}</td>}
                        {defaultView && <td>{beneficiary.statusInfo && beneficiary.statusInfo.source}</td>}
                        {defaultView && <td>{wfpFormat(beneficiary.status)}</td>}
                        {(status === BeneficiaryStatuses.active || defaultView) && (
                            <td>{displayTime(beneficiary.lastActiveAt)}</td>
                        )}
                        {(status === BeneficiaryStatuses.active || defaultView) && (
                            <td>{displayTime(beneficiary.lastTransactionAt)}</td>
                        )}
                        {status === BeneficiaryStatuses.blocked && !defaultView && (
                            <td>{displayTime(beneficiary.statusChangedAt)}</td>
                        )}
                        {status === BeneficiaryStatuses.blocked && !defaultView && (
                            <td>{beneficiary.statusInfo && beneficiary.statusInfo.reason}</td>
                        )}
                        {status === BeneficiaryStatuses.blocked && !defaultView && (
                            <td>{beneficiary.statusInfo && beneficiary.statusInfo.source}</td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
