import * as React from 'react';
import Switch from 'rc-switch';

import { Form, Input, ExternalValidationError, ValidationContext } from '../utils/inputs';
import { displayTime } from '../utils/utils';
import { stopPropagation } from '../utils/events';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { EntityType } from '../authorization/taskAuthorizations';
import { InformationDialog } from '../utils/Dialogs';
import { PartnerGeneralInfo, PartnerStatuses } from './partners';
import { Post } from './PartnerListRow';
import { DownloadPermissionsButton } from '../permissions-files/DownloadPermissionsButton';
import { ManagerPermission } from '../permission-profiles/permission';

interface OwnProps {
    params: { partnerId: string };
}

interface Props extends OwnProps {
    authState: AuthState;
    partnerGeneralInfo: PartnerGeneralInfo;
    validationError?: ExternalValidationError;
    parkEnabled: boolean;
    postEnabled: boolean;
    managerId: string;
    isNewPartner: boolean;

    onSaved: (Vendor) => void;
    authorize: (Vendor) => void;
    cancelPark: (id: string) => void;
}

interface PartnerDetailsState {
    partnerGeneralInfo: PartnerGeneralInfo;
    editChecked: boolean;
    hasPendingDetailsUpdate: boolean;
}

export default class PartnerDetails extends React.Component<Props, PartnerDetailsState> {
    _validationContext = new ValidationContext();

    constructor(props: Props) {
        super(props);
        this.state = {
            partnerGeneralInfo: this.props.partnerGeneralInfo,
            editChecked: false,
            hasPendingDetailsUpdate: false,
        };
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        if (props.partnerGeneralInfo.id) {
            this.setState({ partnerGeneralInfo: props.partnerGeneralInfo });
        }
        if (props.validationError) {
            this._validationContext.pushError(props.validationError);
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({ partnerGeneralInfo: this.props.partnerGeneralInfo });
    }

    renderInputField(options: {
        readonly: boolean;
        value: string;
        name: string;
        required: boolean;
        type: string;
        autoFocused?: boolean;
        maxLength?: number;
    }) {
        return (
            <div className="col-sm-8">
                {options.readonly && <label className="normal">{options.value}</label>}
                {!options.readonly && (
                    <Input
                        className="form-control"
                        context={this._validationContext}
                        isFocused={options.autoFocused}
                        maxLength={options.maxLength}
                        name={options.name}
                        onChange={this._handleInputChange.bind(this)}
                        required={options.required}
                        type={options.type}
                        value={options.value}
                    />
                )}
            </div>
        );
    }

    thisManagerParked(parkedByManagerId) {
        return parkedByManagerId === this.props.managerId;
    }

    isCreatePartnerPage() {
        return this.props.partnerGeneralInfo.id === 'new';
    }

    getNextAction(partner: PartnerGeneralInfo, postEnabled: boolean, authState: AuthState) {
        const cancellationText = 'Cancel';
        const cancelledText = 'Cancelled';
        return partner.id ? (
            <div className="wfp-form--group row">
                <label className="col-sm-4 ta-right">Status</label>
                <div className="col-sm-8">
                    <label className="normal">
                        Parked at {displayTime(partner.createdAt)}
                        <div>by {partner.createdByManager && partner.createdByManager.name}</div>
                        {/*18-04-2018 date of last parked manager on stage before loggin parkedByManagerId -> after that date no name of parker would be error */}
                    </label>
                    {partner.authorizedByManager ? (
                        <label className="normal">
                            Posted at {displayTime(partner.authorizedAt)}
                            {partner.authorizedByManager && <div>by {partner.authorizedByManager.name}</div>}
                        </label>
                    ) : partner.cancelledByManager ? (
                        <label>
                            Cancelled at {displayTime(partner.cancelledAt)}
                            {partner.cancelledByManager && <div>by {partner.cancelledByManager.name}</div>}
                        </label>
                    ) : (
                        <label className="normal">
                            Parked
                            {this.thisManagerParked(partner.createdByManager) ? (
                                <span style={{ color: 'red' }}>*</span>
                            ) : (
                                postEnabled && (
                                    <span>
                                        <br />
                                        <Post auth={authState} authorize={this._authorizePartner} partner={partner} />
                                    </span>
                                )
                            )}
                            <br />
                            <a onClick={stopPropagation(() => this.props.cancelPark(partner.id))}>{cancellationText}</a>
                        </label>
                    )}
                    {this.thisManagerParked(partner.createdByManager) &&
                        !this.props.partnerGeneralInfo.authorizedAt &&
                        !this.props.partnerGeneralInfo.cancelledByManager && (
                            <label>
                                {' '}
                                <span style={{ color: 'red' }}>*</span>You cannot post it, because you parked it.
                            </label>
                        )}
                </div>
            </div>
        ) : (
            <div />
        );
    }

    toggleEdit(editChecked) {
        const { partnerGeneralInfo } = this.props;
        let hasPendingDetailsUpdate = false;
        if (partnerGeneralInfo && partnerGeneralInfo.pendingEntityUpdates) {
            const hasBeneficiaryDetailsEntity = partnerGeneralInfo.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.partnerDetails
            );
            hasPendingDetailsUpdate = hasBeneficiaryDetailsEntity.length !== 0;
        }
        if (hasPendingDetailsUpdate) {
            this.setState({ hasPendingDetailsUpdate: hasPendingDetailsUpdate });
        } else if (partnerGeneralInfo.authorizedAt) {
            this.setState({ editChecked: editChecked });
        }
    }

    renderSwitch() {
        const parkEnabled = hasFeatureAccess(this.props.authState, ManagerPermission.partnerPark);
        const isEditSwitchVisible = parkEnabled && !this.isCreatePartnerPage();
        if (isEditSwitchVisible) {
            return (
                <div className={'row'}>
                    <label className="col-sm-4 ta-right">Toggle Edit</label>
                    <div className="col-sm-8 pv1">
                        <Switch
                            checked={this.state.editChecked}
                            disabled={!this.state.partnerGeneralInfo.authorizedAt}
                            onClick={this.toggleEdit.bind(this)}
                        />
                    </div>
                </div>
            );
        }
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Partner details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending Updates"
            />
        );
    }

    render() {
        const {
            name,
            sublocation,
            city,
            country,
            status,
            phoneNumber,
            email,
            permissionFiles,
        } = this.state.partnerGeneralInfo;
        const { parkEnabled, postEnabled, isNewPartner, authState } = this.props;
        const isReadonly = !isNewPartner && (!parkEnabled || !this.state.editChecked);
        return (
            <div>
                <h6>General Information</h6>
                {!isNewPartner && this.renderSwitch()}
                {this.state.hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}
                <Form className="wfp-form" context={this._validationContext} onSubmit={this._saveChanges.bind(this)}>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Partner Name</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: name,
                            name: 'name',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Sublocation</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: sublocation,
                            name: 'sublocation',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">City</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: city,
                            name: 'city',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Country</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: country,
                            name: 'country',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Phone Number</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: phoneNumber,
                            name: 'phoneNumber',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Email</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: email,
                            name: 'email',
                            required: false,
                            type: 'email',
                        })}
                    </div>

                    {!isNewPartner && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Panel Access</label>
                            <div className="col-sm-8 pv1">
                                {
                                    <Switch
                                        checked={status === PartnerStatuses.active}
                                        disabled={!parkEnabled || !this.state.editChecked}
                                        onChange={this._handleActiveChange.bind(this, 'status')}
                                    />
                                }
                            </div>
                        </div>
                    )}

                    {this.getNextAction(this.state.partnerGeneralInfo, postEnabled, authState)}

                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Permissions File</label>
                        <span className="col-sm-8">
                            {permissionFiles && permissionFiles.length ? (
                                <DownloadPermissionsButton
                                    asyncTaskId={permissionFiles[0].id}
                                    fileName={permissionFiles[0].fileName}
                                    hasAccess={permissionFiles[0].hasAccess}
                                />
                            ) : (
                                'No File'
                            )}
                        </span>
                    </div>

                    {parkEnabled &&
                        (this.state.editChecked || isNewPartner) &&
                        hasFeatureAccess(this.props.authState, ManagerPermission.partnerPark) && (
                            <div className="wfp-form--actions text-center">
                                <div className="col-sm-8 col-sm-offset-4">
                                    <button
                                        className="wfp-btn--primary"
                                        disabled={!this.state.editChecked && !isNewPartner}
                                        type="submit"
                                    >
                                        {this.state.partnerGeneralInfo.id ? 'Park Changes' : 'Park'}
                                    </button>
                                </div>
                            </div>
                        )}
                </Form>
            </div>
        );
    }

    _handleActiveChange(field, checked) {
        const newStatus = checked ? PartnerStatuses.active : PartnerStatuses.blocked;
        const newpartnerGeneralInfo = this.state.partnerGeneralInfo;
        newpartnerGeneralInfo[field] = newStatus;
        this.setState({ partnerGeneralInfo: newpartnerGeneralInfo });
    }

    private _handleInputChange({ target }) {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const partnerGeneralInfo = this.state.partnerGeneralInfo;
        partnerGeneralInfo[target.name] = value;
        this.setState({ partnerGeneralInfo: partnerGeneralInfo });
    }

    private _authorizePartner = () => {
        this.props.authorize(this.props.partnerGeneralInfo.id);
    };

    private _saveChanges(event) {
        this.props.onSaved(this.state.partnerGeneralInfo);
        event.preventDefault();
    }
}
