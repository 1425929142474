import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
/* Utility hook for using redux action creators;
 * hooks action creators use signature (data)=>(dispatch)=>{...}
 * hocs action creators use signature (dispatch)=>(data)=>{...}
 */

export function useActionCreator(actionCreator, hook = false) {
    const dispatch = useDispatch();
    const hocActionCreator = useCallback((...data) => {
        actionCreator(...data)(dispatch);
    }, []);
    const hookActionCreator = useCallback((...data) => {
        actionCreator(dispatch)(...data);
    }, []);
    return hook ? hookActionCreator : hocActionCreator;
}
