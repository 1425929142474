import * as React from 'react';
import PermissionsFiles from '../permissions-files/PermissionsFiles';
import { useDispatch } from 'react-redux';
import { ActionCreators, defaultVendorSortingOrder, VendorGeneralInfo } from './vendors';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    vendor: VendorGeneralInfo;
}

function VendorPermissionsFiles({ vendor }: Props) {
    const dispatch = useDispatch();

    const uploadPermissionsFile = (userId: string, file: File) => {
        return dispatch(ActionCreators.uploadPermissionsFile(userId, file));
    };

    const loadVendors = () => {
        dispatch(ActionCreators.loadVendors(defaultVendorSortingOrder));
    };

    return (
        <PermissionsFiles
            loadUsers={loadVendors}
            permissionsFiles={vendor.permissionsFiles}
            requiredPermission={[ManagerPermission.vendorsPark, ManagerPermission.vendorsPost]}
            uploadPermissionsFile={uploadPermissionsFile}
            userId={vendor.id}
        />
    );
}

export { VendorPermissionsFiles };
