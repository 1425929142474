import * as React from 'react';
import ActivityLogExportPage, { ActivityLogPageType } from './ActivityLogExport';
import { ActivityType } from './activityLogs';
import { EditBeneficiaryCreators } from '../beneficiaries/edit/editBeneficiaryActionCreator';

const activities = [
    { key: ActivityType.beneficiaryImport, header: 'Beneficiary import' },
    {
        key: ActivityType.beneficiaryAutoBlock,
        header: 'Beneficiary auto block',
    },
    {
        key: ActivityType.beneficiaryUpdateByImport,
        header: 'Beneficiary update by import',
    },
    {
        key: ActivityType.beneficiaryDetailsUpdate,
        header: 'Beneficiary details update',
    },
    {
        key: ActivityType.beneficiarySingleBlock,
        header: 'Beneficiary single block',
    },
    {
        key: ActivityType.beneficiarySingleUnblock,
        header: 'Beneficiary single unblock',
    },
    { key: ActivityType.beneficiaryZeroing, header: 'Beneficiary zeroing' },
    {
        key: ActivityType.beneficiaryZeroingBlock,
        header: 'Beneficiary block and zeroing',
    },
    {
        key: ActivityType.beneficiaryMassZeroing,
        header: 'Beneficiary mass zeroing',
    },
    {
        key: ActivityType.beneficiaryMassStatusBalanceChange,
        header: 'Beneficiary mass status balance change',
    },
    {
        key: ActivityType.beneficiaryMassStatusChange,
        header: 'Beneficiary mass status change',
    },
    {
        key: ActivityType.beneficiaryMassStatusChangeAndZeroing,
        header: 'Beneficiary mass status change and zeroing',
    },
    {
        key: ActivityType.automaticReversal,
        header: 'Beneficiary automatic transaction reversal',
    },
    { key: ActivityType.alternativeCollectorsImport, header: 'AC import' },
    {
        key: ActivityType.removeAlternativeCollectorsImport,
        header: 'Remove AC import',
    },
    {
        key: ActivityType.alternativeCollectorStatusChange,
        header: 'AC status change',
    },
];

const selectedOptions = activities.map((activity) => activity.key);

interface Props {
    location: { state: { entityId: string } };
}

export default function BeneficiaryLogExport(props: Props) {
    return (
        <ActivityLogExportPage
            activities={activities}
            entityId={props.location.state.entityId}
            entityType={ActivityLogPageType.Beneficiary}
            exportActivityLog={EditBeneficiaryCreators.exportActivityLog}
            selectedOptions={selectedOptions}
        />
    );
}
