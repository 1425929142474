import Action from '../../action';

export const ActionTypes = {
    reset: 'SessionProlonging.resetState',
    showSessionProlongAlert: 'SessionProlonging.showAlert',
    closeSessionProlongAlert: 'SessionProlonging.closeSessionAlert',
    newToken: 'SessionProlonging.newAuthToken',
};

export const closeSessionProlongAlert = {
    type: ActionTypes.closeSessionProlongAlert,
};

export const resetSessionProlongAlertState = {
    type: ActionTypes.reset,
};

export class SessionExpirationState {
    constructor(public prolongSessionAlertPresented: boolean = false, public didCloseAlert: boolean = false) {}
}

export function sessionExpirationReducer(state = new SessionExpirationState(false, false), action: Action) {
    switch (action.type) {
        case ActionTypes.reset:
            return new SessionExpirationState(false, false);
        case ActionTypes.showSessionProlongAlert:
            return new SessionExpirationState(true, false);
        case ActionTypes.closeSessionProlongAlert:
            return new SessionExpirationState(false, true);
    }
    return state;
}
