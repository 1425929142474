import * as React from 'react';
import { UpdateStateListType } from './taskAuthorizations';

interface Props {
    listType: UpdateStateListType;
}

function messageForListType(listType: UpdateStateListType) {
    switch (listType) {
        case UpdateStateListType.Requested:
            return 'There are no requests to be authorized';
        case UpdateStateListType.Rejected:
            return 'There are no rejected requests';
        case UpdateStateListType.Posted:
            return 'There are no posted requests';
    }
}
export default function NoDataPlaceholder(props: Props) {
    return (
        <div className="text-center">
            <label>{messageForListType(props.listType)}</label>
        </div>
    );
}
