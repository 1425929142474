import * as React from 'react';
import { SideMenu } from '../utils/SideMenu';
import styled from 'styled-components';
import { wfpFormat } from '../utils/utils';
import { useProductGroupsPage } from './hooks/use-product-groups-page';
import { Loader } from '../utils/Loader';
import { SortableTableHead } from '../utils/table/table-head/sortable-table-head';

const Container = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    margin-top: 2rem;
    && table {
        margin-top: 0;
    }
`;

export function ProductGroupsPage() {
    const { activeGroup, handleSelect, groups, redirectToProductPage, handleSort } = useProductGroupsPage();
    if (!groups) {
        return <Loader />;
    }
    if (!groups.length) {
        return <p>No groups were found</p>;
    }

    return (
        <Container>
            <SideMenu
                activeTab={activeGroup?.name}
                onSelectTab={handleSelect}
                tabs={groups.map((productGroup) => productGroup.name)}
            />
            <table className="wfp-table mt4 wfp-table--striped table-hover cursor-pointer">
                <SortableTableHead changeSortOptions={handleSort}>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Quantity Option</th>
                    <th>Unit</th>
                </SortableTableHead>
                <tbody>
                    {!!activeGroup?.products?.length &&
                        activeGroup?.products?.map((product) => (
                            <tr key={product.name} onClick={redirectToProductPage(product.id)}>
                                <td>{product.name}</td>
                                <td>{wfpFormat(product.category?.name)}</td>
                                <td>{wfpFormat(product.quantityOption)}</td>
                                <td>{product.unit}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </Container>
    );
}
