import * as React from 'react';
import { ProductUpdate } from '../taskAuthorizations';
import { classNames, wfpFormat, withDecimal } from '../../utils/utils';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const rightBorder: React.CSSProperties = {
    borderRight: '1px solid #e8e8e8',
};
export const leftBorder: React.CSSProperties = {
    borderLeft: '1px solid #e8e8e8',
};
export const verticalBorders: React.CSSProperties = {
    borderRight: '1px solid #e8e8e8',
    borderLeft: '1px solid #e8e8e8',
};

interface Props {
    updates: ProductUpdate[];
}

export function ProductChangesList({ updates, children }: PropsWithChildren<Props>) {
    const products = updates.map(({ path, oldValue, newValue }) => {
        const isAdded = typeof oldValue === 'string';
        const isRemoved = typeof newValue === 'string';
        return { path, oldValue, newValue, isAdded, isRemoved };
    });
    const changedProducts = products.filter((product) => !product.isRemoved && !product.isAdded);
    const addedProducts = products.filter((product) => !product.isRemoved && product.isAdded);
    const removedProducts = products.filter((product) => product.isRemoved && !product.isAdded);
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <table className="wfp-table--striped mt4">
                <thead>
                    <tr>
                        <th>Product</th>
                        <th colSpan={3} style={verticalBorders}>
                            Old value
                        </th>
                        <th colSpan={3}>New value</th>
                    </tr>
                    <tr>
                        <td />
                        <td style={leftBorder}>Min price</td>
                        <td>Max price</td>
                        <td style={rightBorder}>Fixed price</td>
                        <td>Min price</td>
                        <td>Max price</td>
                        <td>Fixed price</td>
                    </tr>
                </thead>
                <tbody>
                    {[...addedProducts, ...changedProducts, ...removedProducts].map(
                        ({ path, oldValue, newValue, isAdded, isRemoved }) => {
                            const isMinPriceChanged = Boolean(String(newValue?.minPrice).trim().length);
                            const isMaxPriceChanged = Boolean(String(newValue?.maxPrice).trim().length);
                            return (
                                <Row
                                    className={classNames({
                                        'cursor-pointer': true,
                                        added: isAdded,
                                        removed: isRemoved,
                                        changed: !isAdded && !isRemoved,
                                    })}
                                    key={path}
                                >
                                    <td>{path ? wfpFormat(path) : '-'}</td>
                                    <td style={leftBorder}>
                                        {isMinPriceChanged ? withDecimal(oldValue?.minPrice) : ''}
                                    </td>
                                    <td>{isMaxPriceChanged ? withDecimal(oldValue?.maxPrice) : ''}</td>
                                    <td style={rightBorder}>{isAdded ? '' : oldValue?.isFixed ? 'Yes' : 'No'}</td>
                                    <td>{isMinPriceChanged ? withDecimal(newValue?.minPrice) : ''}</td>
                                    <td>{isMaxPriceChanged ? withDecimal(newValue?.maxPrice) : ''}</td>
                                    <td>{isRemoved ? '' : newValue?.isFixed ? 'Yes' : 'No'}</td>
                                </Row>
                            );
                        }
                    )}
                    {children || null}
                </tbody>
            </table>
        </div>
    );
}

const Row = styled.tr`
    &.added {
        color: green;
    }

    &.removed {
        color: red;
    }
    &.changed {
        color: #ffc600;
    }
    td {
        color: inherit !important;
    }
`;
