import { Dispatch } from 'redux';
import { compose } from './utils';
import { errorAction } from './notifications';

export function showErrorBanner(dispatch: Dispatch<any>) {
    return function errorHandler(entity: string) {
        return function catchErrorFn() {
            compose(dispatch, errorAction, errorMessage)(entity);
        };
    };
}
const errorMessage = (entity) => `Could not load ${entity}`;
