import { useEffect, useRef } from 'react';

export function useRefresh(callback: () => void, fromNow: boolean, timeout = 7000) {
    const savedCallback = useRef<(() => void) | null>(null);

    function tick() {
        savedCallback.current();
    }

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const id = window.setInterval(tick, timeout);
        if (fromNow) tick();

        return () => window.clearInterval(id);
    }, []);
}
