import {
    getAsyncTasksList,
    getAsyncTask,
    authorizeAsyncTask,
    downloadFile,
    appendAsyncTasksList,
    sign,
    cancelTask,
    cancelSubTask,
    downloadSpecificFile,
    manualSapPoToAmountPost,
    getAsyncTaskSubtasksArray,
    cancelRunningExport,
    cancelWholeImport,
    downloadStatic,
    openFile,
    addAdditionalFile,
    downloadAdditionalFile,
} from '../apiClient';
import { Filter } from './FilterTypes';
import { PagedState } from './paging';
import { wrapActionCreator } from './refresher';
import NamedId from './NamedId';
import State from '../app/store/state';
import { errorAction, successAction } from './notifications';
import { displayTime } from './utils';
import * as clonedeep from 'lodash.clonedeep';
import { Dispatch } from 'redux';
import { handleManualPoError } from './handleManualPoError';
import * as _ from 'lodash';
import { AppConfigState } from '../app/appConfig';
import { ActionWithState } from '../actionWithState';
import { handleImportFlowPoFetching } from './handleImportFlowPoFetching';
import moment = require('moment');
import validator from 'validator';
import { ManagerPermission } from '../permission-profiles/permission';

export class AsyncTaskScheduler {
    id?: number;
    asyncTaskId: string;
    scheduledFor: Date;
    executedAt?: Date;
    error?: string;

    constructor(attrs: AsyncTaskScheduler) {
        this.id = attrs.id;
        this.asyncTaskId = attrs.asyncTaskId;
        this.scheduledFor = attrs.scheduledFor;
        this.executedAt = attrs.executedAt;
        this.error = attrs.error;
    }
}

export class Signature {
    id: string;
    signedAt: Date;
    signedByManagerId: string;
    requiredRole: ManagerPermission;
}

export interface SapPoToAmountEntry {
    id?: string;
    poItem: string;
    poNumber: string;
    poAmount: string;
}

export interface AsyncTaskAdditionalFile {
    id: number;
    addedByManagerId: number;
    fileName: string;
    url: string;
    key: string;
    createdAt: string;
}

export interface SapPoToAmount {
    id: string;
    parkedByManagerId?: number;
    parkedAt: string;
    authorizedByManagerId?: number;
    authorizedAt?: string;
    cancelledByManagerId?: number;
    cancelledAt?: string;
    cancellationReason?: string;
}

export const AsyncTasksTypes = {
    ALL: 'all',
    IMPORT_ALTERNATIVE_COLLECTOR: 'alternative-collector-import-requests',
    IMPORT_BENEFICIARIES: 'beneficiary-import-requests',
    IMPORT_BENEFICIARIES_MAPPING: 'beneficiary-mapping-import-requests',
    EXPORT_BENEFICIARIES: 'beneficiary-export-requests',
    EXPORT_BENEFICIARIES_PINS: 'beneficiary-pin-export-requests',
    EXPORT_TRANSACTIONS: 'transaction-export-requests',
    VENDOR_EXPORT_TRANSACTIONS: 'vendor-transaction-export-requests',
    EXPORT_VENDORS: 'vendor-export-requests',
    EXPORT_REPORTS: 'reports-export-requests',
    BLOCK_BENEFICIARIES: 'beneficiary-block-requests',
    BENEFICIARY_ZEROING_BLOCK: 'beneficiary-zeroing-block',
    UNBLOCK_BENEFICIARIES: 'beneficiary-unblock-requests',
    EXPORT_ACTIVITY_LOG: 'activity-logs-export',
    BENEFICIARY_ZEROING: 'beneficiary-zeroing',
    UPLOAD_REPORT: 'upload-report-export-requests',
    SCOPE_UPLOAD_REPORT: 'scope-upload-report-requests',
    SCOPE_ANNOUNCEMENTS: 'scope-announcements',
    EXPORT_USERS: 'users-export-requests',
    IMPORT_ALTERNATIVE_COLLECTORS: 'alternative-collector-import-requests',
    REMOVE_ALTERNATIVE_COLLECTORS: 'remove-alternative-collector-requests',
    EXPORT_INVALID_BENEFICIARIES: 'invalid-beneficiary-export-requests',
    SAP_SES_PARK_REQUESTS: 'sap-ses-park-requests',
    EXPORT_REPORTS_SES_PARKING: 'export-reports-ses-parking-requests',
    SAP_SES_PARK_ALIGNMENT_REQUESTS: 'park-ses-alignment',
    UserPermissionsFileUpload: 'user-permissions-file-upload',
    PRODUCT_UPDATE: 'product-update',
};

export interface DisplayScheduledAsyncTask {
    scheduledFor?: Date;
    payload?: any;
    children?: DisplayScheduledAsyncTask[];
}

export enum CBT {
    BB = '01 - Building Blocks',
}

export enum OperationTypes {
    Add = 'ADD',
    Sub = 'SUB',
}

export enum SESRequestStatus {
    pending = 'pending',
    sent = 'sent',
    failed = 'failed',
}

export enum AsyncTaskRawStatus {
    created = 'created',
    parked = 'parked',
    pendingManualPoEntryPark = 'pendingManualPoEntryPark',
    pendingManualPoEntryPost = 'pendingManualPoEntryPost',
    pendingSesPark = 'pendingSesPark',
    cancelled = 'cancelled',
    rejected = 'rejected',
    inProgress = 'inProgress',
    waitingToSchedule = 'waitingToSchedule',
    failed = 'failed',
    finished = 'finished',
    toExecute = 'toExecute',
    toBeSigned = 'toBeSigned',
    toBeCancelled = 'toBeCancelled',
    inProgressWithFailures = 'inProgressWithFailures',
    completedWithFailures = 'completedWithFailures',
    finishedWithWarnings = 'finishedWithWarnings',
}

export enum AsyncTaskStatus {
    created = 'Created',
    parked = 'Parked',
    cancelled = 'Cancelled',
    rejected = 'Rejected',
    toExecute = 'Scheduled',
    inProgress = 'In progress',
    waitingToSchedule = 'Waiting to schedule',
    inProgressWithFailures = 'In progress with failures',
    completedWithFailures = 'Completed with failures',
    failed = 'Failed',
    finished = 'Finished',
    finishedWithWarnings = 'Finished with warnings',
    toBeSigned = 'To Be Signed',
    toBeCancelled = 'To Be Cancelled',
    pendingManualPoEntryPark = 'Pending Manual PO Entry Park',
    pendingManualPoEntryPost = 'Pending Manual PO Entry Post',
    pendingSesPark = 'Pending SES Park',
    overridden = 'Overridden',
}

export interface SesRequest {
    id: string;
    poNumber: string;
    poItem: string;
    poAmount: string;
    operationType: OperationTypes;
    status: SESRequestStatus;
    error?: string;
    createdAt: string;
}

export function isUrl(string) {
    if (typeof string !== 'string') {
        return false;
    }
    return validator.isURL(string, { require_tld: false }); //enable localhost
}

export function mapAsyncTaskTypeToRequiredPostingAccessFeature(asyncTaskType: string) {
    switch (asyncTaskType) {
        case AsyncTasksTypes.BLOCK_BENEFICIARIES:
            return ManagerPermission.beneficiariesMassBlockPost;
        case AsyncTasksTypes.UNBLOCK_BENEFICIARIES:
            return ManagerPermission.beneficiariesMassUnblockPost;
        case AsyncTasksTypes.IMPORT_BENEFICIARIES:
            return ManagerPermission.beneficiariesEntitlementVerify;
        case AsyncTasksTypes.IMPORT_ALTERNATIVE_COLLECTORS:
            return ManagerPermission.alternativeCollectorsImportPost;
        case AsyncTasksTypes.REMOVE_ALTERNATIVE_COLLECTORS:
            return ManagerPermission.removeAlternativeCollectorsImportPost;
        case AsyncTasksTypes.BENEFICIARY_ZEROING:
            return ManagerPermission.beneficiariesMassZeroPost;
        case AsyncTasksTypes.IMPORT_BENEFICIARIES_MAPPING:
            return ManagerPermission.beneficiaryMappingImport;

        default:
            return null;
    }
}

export function isValidator(asyncTask: AsyncTask): boolean {
    return !!(asyncTask.payload && asyncTask.payload.validate);
}

export function isFinancialProcessor(asyncTask: AsyncTask): boolean {
    return !!(asyncTask.payload && asyncTask.payload.financial);
}

export function isParkingSes(asyncTask: AsyncTask) {
    return asyncTask.type === AsyncTasksTypes.SAP_SES_PARK_REQUESTS;
}

export function isParkingSesAlignment(asyncTask: AsyncTask) {
    return asyncTask.type === AsyncTasksTypes.SAP_SES_PARK_ALIGNMENT_REQUESTS;
}

export function displayScheduledFor(task: DisplayScheduledAsyncTask): string {
    if (task.children.length === 0) {
        return displayTime(task.scheduledFor);
    }
    const scheduledChildren = task.children.filter(
        (child) => !(child.payload && child.payload.validate) && child.scheduledFor
    );
    if (scheduledChildren.length > 1) {
        return 'Multiple';
    } else if (scheduledChildren.length === 1) {
        return displayTime(scheduledChildren[0].scheduledFor);
    }
    return '-';
}

export function displayValidFrom(task: AsyncTask): string {
    if (task.children.length === 0) {
        return task.additionalInfo?.validFrom
            ? displayTime(moment(task.additionalInfo?.validFrom, 'YYYYMMDD').toDate())
            : '-';
    }
    const scheduledChildren = task.children.filter(
        (child) => !(child.payload && child.payload.validate) && child.additionalInfo?.validFrom
    );
    if (scheduledChildren.length > 1) {
        return 'Multiple';
    } else if (scheduledChildren.length === 1) {
        return displayTime(moment(scheduledChildren[0].additionalInfo?.validFrom, 'YYYYMMDD').toDate());
    }
    return '-';
}

function createNewDetails(currentDetails: AsyncTask, signed: Signature) {
    const newDetails = clonedeep(currentDetails) as AsyncTask;
    newDetails.signatures = newDetails.signatures.map((signature) => (signature.id === signed.id ? signed : signature));
    return newDetails;
}

export class AsyncTask {
    id?: string;
    parentId?: string;
    type?: string;
    additionalInfo?: any;
    fileName?: string;
    createdAt?: Date;
    parkedAt?: Date;
    scheduledFor?: Date;
    startedAt?: Date;
    createdByManager?: NamedId;
    authorizedAt?: Date;
    authorizedByManager?: NamedId;
    cancelledAt?: Date;
    cancelledByManager?: NamedId;
    finishedAt?: Date;
    errors?: string[];
    warnings?: string[];
    progress?: string;
    children?: AsyncTask[];
    sapPoToAmount: SapPoToAmount;
    sapPoToAmountEntries: SapPoToAmountEntry[];
    sesRequests: SesRequest[];
    subtasks?: AsyncTask[];
    payload?: any;
    fileExpired: boolean;
    signatures: Signature[];
    createdByManagerId?: string;
    authorizedByManagerId?: string;
    cancelledByManagerId?: string;
    dryRunErrors?: string[];
    interventionName?: string[];
    preCancelledAt?: Date;
    preCancelledByManager?: NamedId;
    batchFileNames?: Array<string>;
    createdByVendorUser: NamedId;
    isSapActive: boolean;
    description?: string;
    retriesCount?: number;
    additionalFiles?: AsyncTaskAdditionalFile[];
    hasAccess?: boolean;
    cancelledByVendorUserId?: number;
    cancelledByVendorUser?: NamedId;
    created_at?: string;

    constructor(asyncTask: AsyncTask, appConfig?: AppConfigState) {
        this.id = asyncTask.id;
        this.parentId = asyncTask.parentId;
        this.type = asyncTask.type;
        this.children = (asyncTask.children || []).map((v) => new AsyncTask(v, appConfig));
        this.additionalInfo = _.isEmpty(asyncTask.additionalInfo)
            ? this.pickValidatorAdditonalInfo()
            : asyncTask.additionalInfo;
        this.fileName = asyncTask.fileName;
        this.createdAt = asyncTask.createdAt;
        this.parkedAt = asyncTask.parkedAt;
        this.authorizedAt = asyncTask.authorizedAt;
        this.scheduledFor = asyncTask.scheduledFor;
        this.startedAt = asyncTask.startedAt;
        this.createdByManager = asyncTask.createdByManager;
        this.authorizedAt = asyncTask.authorizedAt;
        this.authorizedByManager = asyncTask.authorizedByManager;
        this.cancelledAt = asyncTask.cancelledAt;
        this.cancelledByManager = asyncTask.cancelledByManager;
        this.finishedAt = asyncTask.finishedAt;
        this.errors = asyncTask.errors;
        this.warnings = asyncTask.warnings;
        this.progress = asyncTask.progress;
        this.sapPoToAmount = asyncTask.sapPoToAmount;
        this.sapPoToAmountEntries = asyncTask.sapPoToAmountEntries;
        this.sesRequests = asyncTask.sesRequests;
        this.subtasks = this.children.filter((child) => child.type === this.type);
        this.payload = asyncTask.payload || {};
        this.fileExpired = asyncTask.fileExpired;
        this.signatures = asyncTask.signatures;
        this.createdByManagerId = asyncTask.createdByManagerId;
        this.authorizedByManagerId = asyncTask.authorizedByManagerId;
        this.cancelledByManagerId = asyncTask.cancelledByManagerId;
        this.dryRunErrors = asyncTask.dryRunErrors;
        this.interventionName = asyncTask.interventionName;
        this.preCancelledAt = asyncTask.preCancelledAt;
        this.preCancelledByManager = asyncTask.preCancelledByManager;
        this.batchFileNames = asyncTask.batchFileNames;
        this.createdByVendorUser = asyncTask.createdByVendorUser;
        this.isSapActive = appConfig ? appConfig.sapConfig.isSapActive : false;
        this.description = this.determineDescription();
        this.additionalFiles = asyncTask.additionalFiles;
        this.hasAccess = asyncTask.hasAccess;
        this.cancelledByVendorUser = asyncTask.cancelledByVendorUser;
    }

    get status(): AsyncTaskStatus {
        return AsyncTaskStatus[this.rawStatus];
    }

    get rawStatus(): AsyncTaskRawStatus {
        if (this.subtasks && this.subtasks.length > 0) {
            const atLeastOneErroneous = this.subtasks.find(
                (children) => children.isAsyncTaskErroneous() && !isFinancialProcessor(children)
            );
            if (atLeastOneErroneous && this.finishedAt) {
                const derivedStatus = this.areSubtasksFinished()
                    ? AsyncTaskRawStatus.completedWithFailures
                    : AsyncTaskRawStatus.inProgressWithFailures;
                return derivedStatus;
            }
        }
        if (this.cancelledAt) {
            return this.createdByManagerId === this.cancelledByManagerId
                ? AsyncTaskRawStatus.cancelled
                : AsyncTaskRawStatus.rejected;
        }
        if (this.preCancelledAt) {
            return AsyncTaskRawStatus.toBeCancelled;
        }
        if (this.isSapActive && !this.parentId && this.isTaskPendingManualPoPark()) {
            return AsyncTaskRawStatus.pendingManualPoEntryPark;
        }
        if (this.isSapActive && !this.parentId && this.isTaskPendingManualPoPost()) {
            return AsyncTaskRawStatus.pendingManualPoEntryPost;
        }
        if (this.parkedAt && !this.isTaskAuthorized()) {
            return AsyncTaskRawStatus.parked;
        }
        if (this.finishedAt && this.isAsyncTaskErroneous()) {
            return AsyncTaskRawStatus.failed;
        }
        if (this.isAsyncTaskFinished() && this.isAsyncTaskWithWarnings()) {
            return AsyncTaskRawStatus.finishedWithWarnings;
        }
        if (this.isAsyncTaskFinished() && !this.isAsyncTaskErroneous()) {
            return AsyncTaskRawStatus.finished;
        }
        if (this.isTaskAuthorized() && !this.startedAt && this.scheduledFor) {
            return AsyncTaskRawStatus.toExecute;
        }
        if (
            this.isSapActive &&
            this.isTaskAuthorized() &&
            !this.startedAt &&
            this.signatures &&
            this.signatures.length > 0 &&
            this.isTaskPendingSESPark()
        ) {
            return AsyncTaskRawStatus.pendingSesPark;
        }
        if (
            !this.startedAt &&
            !this.isAsyncTaskFinished() &&
            this.signatures &&
            this.signatures.length > 0 &&
            this.signatures.every((signature) => !!signature.signedAt)
        ) {
            return AsyncTaskRawStatus.waitingToSchedule;
        }

        if (this.isTaskAuthorized() && !this.startedAt && this.signatures && this.signatures.length > 0) {
            if (!this.isSapActive) {
                return AsyncTaskRawStatus.toBeSigned;
            } else if (this.isSapActive && this.getSuccessfulSESParkingChild()) {
                return AsyncTaskRawStatus.toBeSigned;
            }
        }

        if (this.startedAt && !this.isAsyncTaskFinished()) {
            return AsyncTaskRawStatus.inProgress;
        }
        return AsyncTaskRawStatus.created;
    }

    public isAsyncTaskErroneous(): boolean {
        if (this.errors) {
            return this.errors.length > 0;
        } else {
            return false;
        }
    }

    public isAsyncTaskWithWarnings(): boolean {
        if (this.warnings) {
            return this.warnings.length > 0;
        } else {
            return false;
        }
    }

    public isAsyncTaskMain(): boolean {
        return !this.parentId;
    }

    private isTaskAuthorized() {
        if (this.type === AsyncTasksTypes.SCOPE_ANNOUNCEMENTS) {
            return this.parkedAt;
        } else {
            return this.authorizedAt;
        }
    }

    private isAsyncTaskFinished() {
        return this.finishedAt && this.areSubtasksFinished();
    }

    private isTaskPendingManualPoPark() {
        const financialProcessor = this.getFinancialProcessor();
        return financialProcessor && financialProcessor.isAsyncTaskErroneous() && !this.isSapPoToAmountParked();
    }

    private isTaskPendingManualPoPost() {
        const financialProcessor = this.getFinancialProcessor();
        return (
            financialProcessor &&
            financialProcessor.isAsyncTaskErroneous() &&
            this.isSapPoToAmountParked() &&
            !this.isSapPoToAmountAuthorized()
        );
    }

    private isTaskPendingSESPark() {
        const successfulSesProcessor = this.getSuccessfulSESParkingChild();
        return this.isSapPoToAmountAuthorized() && !successfulSesProcessor;
    }

    private isSapPoToAmountParked() {
        if (this.sapPoToAmount) {
            return Boolean(this.sapPoToAmount.parkedAt && !this.sapPoToAmount.cancelledAt);
        } else {
            return false;
        }
    }

    private isSapPoToAmountAuthorized() {
        if (this.sapPoToAmount) {
            return Boolean(this.sapPoToAmount.authorizedAt && !this.sapPoToAmount.cancelledAt);
        } else {
            return false;
        }
    }

    private determineDescription() {
        if (isValidator(this)) {
            return 'This task validates file general data integrity.';
        } else if (isFinancialProcessor(this)) {
            return 'This task validates file financial data integrity (PO item, PO number, reload amount) and checks it in WINGS webservice.';
        } else if (isParkingSes(this)) {
            return 'This task parks SES by calling WINGS webservice';
        } else if (isParkingSesAlignment(this)) {
            return 'This task parks SES alignments by calling WINGS webservice';
        } else {
            return null;
        }
    }

    public getFinancialProcessor() {
        if (this.children && this.children.length > 0) {
            const financialProcessor = this.children.find((subTask) => subTask.payload && subTask.payload.financial);
            return financialProcessor;
        } else {
            return null;
        }
    }

    public getValidationProcessor() {
        if (this.children && this.children.length > 0) {
            const validationProcessor = this.children.find((subTask) => subTask.payload && subTask.payload.validate);
            return validationProcessor;
        } else {
            return null;
        }
    }

    public getSuccessfulSESParkingChild() {
        if (this.children && this.children.length > 0) {
            const sesParkingTasks = this.children.find(
                (subTask) =>
                    subTask.type === AsyncTasksTypes.SAP_SES_PARK_REQUESTS &&
                    !subTask.isAsyncTaskErroneous() &&
                    subTask.isAsyncTaskFinished()
            );
            return sesParkingTasks;
        } else {
            return null;
        }
    }

    private areSubtasksFinished(): boolean {
        return !this.subtasks.find(
            (child) =>
                (child.finishedAt === null || child.finishedAt === undefined) &&
                (child.cancelledAt === null || child.cancelledAt === undefined)
        );
    }

    private pickValidatorAdditonalInfo(): object {
        if (this.getValidationProcessor()) {
            const validationProcessor = this.getValidationProcessor();
            const additionalInfo = validationProcessor.additionalInfo;
            return _.pick(additionalInfo, ['rowCount', 'rowSum', 'lastBatchIndex', 'numberOfBatches']);
        } else {
            return null;
        }
    }
}

export const finishedTasksStatuses = [
    AsyncTaskStatus.cancelled,
    AsyncTaskStatus.rejected,
    AsyncTaskStatus.finished,
    AsyncTaskStatus.failed,
    AsyncTaskStatus.completedWithFailures,
    AsyncTaskStatus.finishedWithWarnings,
];

export enum SapPoToAmountDialogType {
    editable = 'Park Manual SAP PO',
    authorizable = 'Post/Reject Manual SAP PO',
    display = 'Show SAP PO',
}

export enum SapPoToAmountRequestAction {
    park = 'park',
    post = 'post',
    cancel = 'cancel',
}

export class AsyncTasksState {
    asyncTasks: any;
    details: AsyncTask;
    schedulerDetails: AsyncTaskScheduler;
    error: string;
    subtasksArray: AsyncTask[];

    constructor(
        asyncTasks: PagedState<AsyncTask> = null,
        details: AsyncTask = null,
        error: string = null,
        schedulerDetails: AsyncTaskScheduler = null,
        appConfig: AppConfigState = null,
        subtasksArray: AsyncTask[] = []
    ) {
        if (!this.asyncTasks) {
            this.asyncTasks = {};
        }
        if (asyncTasks && asyncTasks.items && asyncTasks.items.length) {
            asyncTasks.items = asyncTasks.items.map((task) => new AsyncTask(task, appConfig));
            const type =
                asyncTasks.items[0].type === AsyncTasksTypes.SCOPE_ANNOUNCEMENTS
                    ? AsyncTasksTypes.IMPORT_BENEFICIARIES
                    : asyncTasks.items[0].type;
            this.asyncTasks[type] = asyncTasks;
        }
        this.details = details ? new AsyncTask(details, appConfig) : null;
        this.schedulerDetails = schedulerDetails ? new AsyncTaskScheduler(schedulerDetails) : null;
        this.error = error;
        this.subtasksArray = subtasksArray.map((task) => new AsyncTask(task, appConfig));
    }
}

export class AppendableAsyncTasksState {
    asyncTasks: any;
    details: AsyncTask;
    error: string;

    constructor(
        asyncTasks: PagedState<AsyncTask> = null,
        details: AsyncTask = null,
        error: string = null,
        appConfig: AppConfigState = null
    ) {
        if (!this.asyncTasks) {
            this.asyncTasks = {};
        }
        if (asyncTasks && asyncTasks.items) {
            asyncTasks.items = asyncTasks.items.map((task) => new AsyncTask(task, appConfig));
            const type =
                asyncTasks.items[0].type === AsyncTasksTypes.SCOPE_ANNOUNCEMENTS
                    ? AsyncTasksTypes.IMPORT_BENEFICIARIES
                    : asyncTasks.items[0].type;
            this.asyncTasks[type] = asyncTasks;
        }
        this.details = details ? new AsyncTask(details, appConfig) : null;
        this.error = error;
    }
}

export const ActionTypes = {
    loadData: 'AsyncTasksList.loadData',
    loaded: 'AsyncTasksList.loaded',
    error: 'AsyncTaskList.error',
    appendData: 'AsyncTaskList.appendData',
    signed: 'AsyncTaskList.signed',
    overrideSapPo: 'AsyncTaskList.overrideSapPo',
    clearDetails: 'AsyncTaskDetails.clearDetails',
    loadedSubtasksArray: 'AsyncTaskDetails.loadedSubtasksArray',
};

export const HookActionCreators = {
    openFile(dispatch) {
        return async function openFileRequest(taskType: string, id: string) {
            const res = await openFile(taskType, id);
            if (res.url) {
                window.open(res.url, '_blank', 'noopener, noreferrer, nofollow');
            } else {
                dispatch(errorAction('Could not open the file'));
            }
        };
    },
};
export const ActionCreators = {
    loadAsyncTasksList(page, limit = 25, type: string, filters: Array<Filter> = []) {
        return (dispatch, getState) =>
            wrapActionCreator(
                () =>
                    getAsyncTasksList(filters, page, limit, type).then((tasks) => {
                        dispatch({
                            type: ActionTypes.loadData,
                            payload: tasks,
                            state: getState(),
                        });
                        return tasks;
                    }),
                7000
            );
    },
    fetchAsyncTasksList(page, limit = 10, type: string, filters: Array<Filter> = []) {
        return (dispatch, getState) => {
            getAsyncTasksList(filters, page, limit, type).then((tasks) => {
                dispatch({
                    type: ActionTypes.loadData,
                    payload: tasks,
                    state: getState(),
                });
                return tasks;
            });
        };
    },

    appendAsyncTasksList(limit = 10, type: string, filters: Array<Filter> = []) {
        return (dispatch, getState: () => State) => {
            const state = getState();
            const offset = state.asyncTasks.asyncTasks[type].items.length;

            // new tasks
            const currentTasks = state.asyncTasks.asyncTasks[type].items;
            const appConfig = state.appConfig;

            return wrapActionCreator(() =>
                appendAsyncTasksList(filters, offset, limit, type).then((tasks) => {
                    const items = tasks.items.map((i) => new AsyncTask(i, appConfig));
                    const mergedTasks = currentTasks.concat(items);
                    const newState = new PagedState();
                    newState.paging = tasks.paging;
                    newState.items = mergedTasks;

                    // PagedState { ...oldPagedState ,items: old + new}
                    dispatch({
                        type: ActionTypes.appendData,
                        payload: newState,
                        state: getState(),
                    });
                    return tasks;
                })
            );
        };
    },

    getSubtasksArray(id: string, type: string) {
        return (dispatch, getState) =>
            wrapActionCreator(() =>
                getAsyncTaskSubtasksArray(type, id).then((tasks) => {
                    dispatch({
                        type: ActionTypes.loadedSubtasksArray,
                        payload: tasks,
                        state: getState(),
                    });
                    return tasks;
                })
            );
    },

    getAsyncTask(type: string, id: string) {
        return (dispatch, getState) =>
            wrapActionCreator(() =>
                getAsyncTask(type, id).then((task) => {
                    dispatch({
                        type: ActionTypes.loaded,
                        payload: task,
                        state: getState(),
                    });
                    return task;
                })
            );
    },

    authorizeAsyncTask(type: string, id: string) {
        return async (dispatch: Dispatch<State>, getState) => {
            const task = await authorizeAsyncTask(type, id);
            dispatch({
                type: ActionTypes.loaded,
                payload: task,
                state: getState(),
            });
        };
    },

    downloadFile(taskType: string, id: string, index?: number) {
        return async (dispatch: Dispatch<State>) => {
            const res =
                index !== undefined
                    ? await downloadSpecificFile(taskType, id, index)
                    : await downloadFile(taskType, id);

            if (!Array.isArray(res) && isUrl(res)) {
                window.location.replace(res);
            } else if (Array.isArray(res)) {
                res.forEach((url, idx) => {
                    if (isUrl(url)) {
                        setTimeout(() => {
                            window.location.href = url;
                        }, idx * 3000);
                    }
                });
            } else {
                switch (res) {
                    case 403:
                        dispatch(errorAction("Can't download export file - task caused errors/warnings"));
                        break;
                    case 404:
                        dispatch(errorAction('Not existing task'));
                        break;
                    case 422:
                        dispatch(errorAction('Not available file'));
                        break;
                    case 423:
                        dispatch(errorAction('File expired'));
                        break;
                    default:
                        dispatch(errorAction('Unhandled error'));
                }
                return false;
            }
            return true;
        };
    },

    downloadStatic(fileName: string) {
        return async (dispatch: Dispatch<State>) => {
            const res = await downloadStatic(fileName);

            if (isUrl(res)) {
                window.open(res, '_blank');
            } else {
                switch (res) {
                    case 404:
                        dispatch(errorAction('No existing file'));
                        break;
                    default:
                        dispatch(errorAction('Unhandled error'));
                }
            }
        };
    },

    downloadAdditionalFile(fileKey: string) {
        return async (dispatch: Dispatch<State>) => {
            const res = await downloadAdditionalFile(fileKey);
            if (isUrl(res)) {
                window.open(res, '_blank');
            } else {
                switch (res) {
                    case 404:
                        dispatch(errorAction('No existing file'));
                        break;
                    default:
                        dispatch(errorAction('Unhandled error'));
                }
            }
        };
    },

    cancelTask(type: string, id: string, isSubtask?: boolean, parkPostAction?: boolean) {
        if (isSubtask) {
            return async (dispatch, getState) => {
                const task = await cancelSubTask(type, id);
                dispatch({
                    type: ActionTypes.loaded,
                    payload: task,
                    state: getState(),
                });
            };
        }
        return async (dispatch, getState) => {
            const task = parkPostAction ? await cancelWholeImport(type, id) : await cancelTask(type, id);
            dispatch({
                type: ActionTypes.loaded,
                state: getState(),
                payload: task,
            });
        };
    },

    cancelRunningTask(type: string, id: string) {
        return async (dispatch, getState) => {
            cancelRunningExport(type, id)
                .then((task) => {
                    dispatch({
                        type: ActionTypes.loaded,
                        state: getState(),
                        payload: task,
                    });
                })
                .catch((err) => {
                    if (err.status === 400) {
                        dispatch(errorAction('Export is finished'));
                        dispatch(ActionCreators.getAsyncTask(type, id));
                    }
                });
        };
    },

    sign(signatureId: string, managerId: string) {
        return (dispatch, getState) =>
            sign(signatureId, managerId).then((res) => {
                dispatch({
                    type: ActionTypes.signed,
                    state: getState(),
                    payload: res,
                });
            });
    },
    sapPoToAmountEntriesAction(
        asyncTaskId: string,
        sapPoToAmountEntries: Array<SapPoToAmountEntry>,
        action: SapPoToAmountRequestAction
    ) {
        return (dispatch, getState) =>
            manualSapPoToAmountPost(asyncTaskId, sapPoToAmountEntries, action)
                .then((res) => {
                    dispatch({
                        type: ActionTypes.overrideSapPo,
                        state: getState(),
                        payload: res,
                    });
                    dispatch(ActionCreators.getAsyncTask(AsyncTasksTypes.IMPORT_BENEFICIARIES, asyncTaskId));
                    dispatch(successAction(`SAP PO override ${action} successful`));
                })
                .catch((err) => handleManualPoError(err, dispatch));
    },
    handlePoDetailsFetchingError(err) {
        return (dispatch) => handleImportFlowPoFetching(err, dispatch);
    },
    clearDetails() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.clearDetails,
            });
        };
    },
    uploadAdditionalFile(data: File, taskId: string, taskType: string, managerId: string) {
        const params = {
            data,
            taskId,
            managerId,
        };
        return (dispatch) => {
            return addAdditionalFile(params, taskType)
                .then((_) => dispatch(successAction('File uploaded')))
                .catch((err) => {
                    if (err.status === 403) {
                        dispatch(errorAction('You do not have required permission for this action'));
                    } else if (err.status === 409) {
                        dispatch(errorAction('File limit exceeded'));
                    } else if (err.status === 422) {
                        dispatch(errorAction('File not supported'));
                    } else if (err.status === 413) {
                        dispatch(errorAction('File too large'));
                    } else {
                        dispatch(errorAction('Unhandled Error'));
                    }
                });
        };
    },
};

export function asyncTasksReducer(state: AsyncTasksState = new AsyncTasksState(), action: ActionWithState) {
    let appConfig;
    if (action.state && action.state.appConfig) {
        appConfig = action.state.appConfig;
    }
    switch (action.type) {
        case ActionTypes.loadData:
            return new AsyncTasksState(action.payload, state.details, null, null, appConfig);
        case ActionTypes.loaded:
            return new AsyncTasksState(
                state.asyncTasks,
                action.payload.task,
                null,
                action.payload.scheduler,
                appConfig
            );
        case ActionTypes.loadedSubtasksArray:
            return new AsyncTasksState(
                state.asyncTasks,
                state.details,
                null,
                action.payload.scheduler,
                appConfig,
                action.payload.tasks
            );
        case ActionTypes.appendData:
            return new AppendableAsyncTasksState(action.payload, state.details, null, appConfig);
        case ActionTypes.error:
            return new AsyncTasksState(state.asyncTasks, state.details, action.payload, null, appConfig);
        case ActionTypes.signed:
            const newDetails = createNewDetails(state.details, action.payload);
            return new AsyncTasksState(state.asyncTasks, newDetails, null, null, appConfig);
        case ActionTypes.clearDetails:
            return new AsyncTasksState(state.asyncTasks, null, state.error, state.schedulerDetails, appConfig);
        default:
            return state;
    }
}
