import * as moment from 'moment';

const DateFormat = 'DD MMM YYYY';
function dateString(str) {
    const arr = str.split(' ');
    return [...arr.slice(0, 5), 'UTC+00'].join(' ');
}
export function periodLabelFor(periodStart: string, periodEnd: string): string {
    const start = new Date(dateString(periodStart)).toISOString();
    const end = new Date(dateString(periodEnd)).toISOString();
    return `${moment.utc(start).format(DateFormat)} – ${moment.utc(end).format(DateFormat)}`;
}
