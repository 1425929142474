import * as React from 'react';
import { RowMenu } from '../../utils/RowMenu';
import { ActionCreators, UpdateStateListType } from '../taskAuthorizations';
import State from '../../app/store/state';
import { useDispatch, useSelector } from 'react-redux';

export function AuthorizationRowMenu() {
    const activeListType = useSelector((state: State) => state.taskAuthorizations.activeListType);
    const dispatch = useDispatch();
    const changeListType = (selected) => {
        ActionCreators.clearUpdateRequests()(dispatch);
        dispatch(ActionCreators.changeListType(selected));
    };
    const requestsTabs = [UpdateStateListType.Requested, UpdateStateListType.Posted, UpdateStateListType.Rejected];
    return <RowMenu activeTab={activeListType} onSelectTab={changeListType} tabs={requestsTabs} />;
}
