import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import State from '../../app/store/state';

import { DateSelectFilter, Filter, SelectOption, TextFilter } from '../../utils/FilterTypes';
import { TypeTotalResponse, TypeTotalsActionCreators } from '../../transactions/transactionTypeTotals';
import { TransactionStatuses, TransactionType, txNamesByType } from '../../transactions/transactions';
import { WfpTotals } from './wfpTotals';

import { createTransactionTypeFilter, PredefinedDateRanges } from '../../../utils/createPredefinedFilters';
import { FiltersCreators, FiltersTypes } from '../../utils/filtersStore';
import { alignDateToUtcMoment, PredefinedDateFilters } from '../../utils/DateFilter';
import * as moment from 'moment';
import { RowMenu } from '../../utils/RowMenu';
import DateFilterField from '../../utils/filterFields/DateFilterField';
import { EntitlementCategoriesConfig, EntitlementCurrencyConfig } from '../../app/appConfig';
import { AuthState, hasFeatureAccess } from '../../login/auth';
import { transformDateFilters } from '../../../utils/transformDateFilters';
import { ManagerPermission } from '../../permission-profiles/permission';
import { withNavigate } from '@wfp-common/hooks/withNavigate';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';

const summaryStatisticsPredefinedDateRanges = PredefinedDateRanges.filter(
    (selectOption) => selectOption.value !== PredefinedDateFilters.lastYear
);

const initialStartDate = moment(moment().startOf('month').toDate()).toDate();

let initialEndDate = moment(moment().subtract(1, 'day').format('YYYY-MM-DD')).endOf('day').toDate();

initialEndDate = moment(initialStartDate).isBefore(moment(initialEndDate))
    ? initialEndDate
    : moment(initialStartDate).endOf('day').toDate();
const initialFilter = new DateSelectFilter('Date', 'createdAt', '', summaryStatisticsPredefinedDateRanges, {
    startDate: initialStartDate,
    endDate: initialEndDate,
});

function retrieveSummaryStatsFilterFromState(state: State): DateSelectFilter {
    const summaryStatsFilterState = state.filters.find((filter) => filter.relatedTo === FiltersTypes.summaryStatistics);
    if (summaryStatsFilterState) {
        return summaryStatsFilterState.selectedFilters[0] as DateSelectFilter;
    } else {
        return initialFilter;
    }
}

function mapStateToProps(state: State) {
    return {
        typeTotalsSinceSystemStartResponse: state.typeTotals.typeTotalsSinceSystemStartResponse,
        dynamicStartDateTypeTotalsResponse: state.typeTotals.dynamicStartDateTypeTotalsResponse,
        filter: retrieveSummaryStatsFilterFromState(state),
        categories: state.appConfig.entitlementsConfig,
        auth: state.auth,
        currency: state.appConfig.entitlementCurrencyConfig,
        timezone: state.appConfig.timeZone,
        systemStartDate: state.appConfig.initialTransactionDate,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadTransactionTypesTotals: bindActionCreators(TypeTotalsActionCreators.loadTransactionTypesTotals, dispatch),
        saveFilters: bindActionCreators(FiltersCreators.saveFilters, dispatch),
    };
}

function txTypesForTypesContribution(typesContribution): string[] {
    const types = [];
    if (typesContribution.includes(txNamesByType.topup)) {
        types.push(TransactionType.topup);
    }
    if (typesContribution.includes(txNamesByType.unload)) {
        types.push(TransactionType.unload);
    }
    if (typesContribution.includes(txNamesByType.reverse)) {
        types.push(TransactionType.reverse);
    }
    if (typesContribution.includes(txNamesByType.spendBalance)) {
        types.push(TransactionType.spendBalance);
    }
    if (typesContribution.includes(txNamesByType.addVendorBalance)) {
        types.push(TransactionType.addVendorBalance);
    }
    if (typesContribution.includes(txNamesByType.reclaimBalance)) {
        types.push(TransactionType.reclaimBalance);
    }
    return types;
}

interface Props {
    loadTransactionTypesTotals: (
        timezone: string,
        startDate?: string,
        endDate?: string,
        types?: Array<TransactionType>
    ) => void;
    saveFilters: (filters: Filter[], relatedTo: string) => void;
    categories: EntitlementCategoriesConfig;
    downloadSummaryStatsTxs: (startDate, endDate, category, typesContribution) => void;

    typeTotalsSinceSystemStartResponse: TypeTotalResponse;
    dynamicStartDateTypeTotalsResponse: TypeTotalResponse;
    filter: DateSelectFilter;
    auth: AuthState;
    currency: EntitlementCurrencyConfig;
    timezone: string;
    systemStartDate?: Date;
    navigate: NavigateHook;
}

interface SummaryStatisticsPageState {
    activeCategory: string;
}

const typesContributionSinceBeginning = [
    `${txNamesByType.topup} + ${txNamesByType.reverse} - ${txNamesByType.unload} - ${txNamesByType.spendBalance}`,
    `${txNamesByType.addVendorBalance} + ${txNamesByType.spendBalance} - ${txNamesByType.reverse} - ${txNamesByType.reclaimBalance}`,
    `${txNamesByType.unload} + ${txNamesByType.reclaimBalance} - ${txNamesByType.topup} - ${txNamesByType.addVendorBalance}`,
];

class SummaryStatisticsPage extends React.Component<Props, SummaryStatisticsPageState> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.filter && this.props.filter.dateRange) {
            this.loadTotalsBasedOnTimezone(this.props.filter, this.props.timezone);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.categories && nextProps.categories.categories) {
            this.setState({
                activeCategory: nextProps.categories.categories[0],
            });
        }
    }

    loadTotalsBasedOnTimezone = (filter: DateSelectFilter, timezone: string) => {
        const { startDate, endDate } = filter.dateRange;
        const startDateWithoutTimeOffset = alignDateToUtcMoment(startDate).toISOString();
        const endDateWithoutTimeOffset = alignDateToUtcMoment(moment(endDate).add(1, 'second').toDate()).toISOString(); // stats are calculated to full day
        this.props.loadTransactionTypesTotals(timezone, startDateWithoutTimeOffset, endDateWithoutTimeOffset);
        this.props.loadTransactionTypesTotals(timezone, null, endDateWithoutTimeOffset);
    };

    filterChanged(filter) {
        this.loadTotalsBasedOnTimezone(filter, this.props.timezone);
        this.props.saveFilters([filter], FiltersTypes.summaryStatistics);
    }

    changeStatisticsCategory(activeCategoryTab) {
        this.setState({ activeCategory: activeCategoryTab });
    }

    downloadSummaryStatisticsTransactions(timezone: string, typesContribution: string, transactionsCount: number) {
        const summaryStatisticsFilters = this.getSummaryStatisticsFilters(typesContribution);
        const _filters = summaryStatisticsFilters.map(transformDateFilters(timezone));

        this.props.navigate(`/transactions/download`, {
            transactionsFilters: _filters,
            transactionsTotalCount: transactionsCount,
        });
    }

    getSummaryStatisticsFilters(typesContribution: string) {
        const filters = [];

        const startDate = typesContributionSinceBeginning.includes(typesContribution)
            ? null
            : this.props.filter.dateRange.startDate;
        const dateRange = this.props.filter.dateRange;
        dateRange.startDate = startDate;
        const dateFilter = new DateSelectFilter('Date', 'createdAt', null, null, dateRange);
        dateFilter.isSelected = true;
        filters.push(dateFilter);

        const categoryFilter = new TextFilter('Category', 'category', this.state.activeCategory);
        categoryFilter.isSelected = true;
        filters.push(categoryFilter);

        const typeFilter = createTransactionTypeFilter();
        typeFilter.isSelected = true;
        typeFilter.value = txTypesForTypesContribution(typesContribution).map((type) => {
            const txType = Object.keys(TransactionType).find((key) => TransactionType[key] === type);
            const label = txNamesByType[txType];
            return new SelectOption(label, type);
        });
        filters.push(typeFilter);

        const txStatusesForSummaryStatisticsExport = TransactionStatuses.allStatuses().filter(
            (status) => status !== TransactionStatuses.unknown && status !== TransactionStatuses.failed
        );
        const statusFilter = new TextFilter('Status', 'status', txStatusesForSummaryStatisticsExport.toString());
        statusFilter.isSelected = true;
        filters.push(statusFilter);

        return filters;
    }

    render() {
        const {
            filter,
            typeTotalsSinceSystemStartResponse,
            dynamicStartDateTypeTotalsResponse,
            categories,
            systemStartDate,
        } = this.props;
        const StatisticsCategoryTabs = categories ? categories.categories : [];

        return (
            <>
                <div className="filters-form">
                    {filter && (
                        <div className="col-sm-12">
                            <DateFilterField
                                endDate={
                                    filter.dateRange &&
                                    filter.dateRange.endDate &&
                                    filter.dateRange.endDate.toISOString()
                                }
                                filter={filter}
                                filterChanged={this.filterChanged.bind(this)}
                                key={'date'}
                                maxDate={moment().endOf('year').toDate()}
                                minDate={new Date(this.props.systemStartDate)}
                                startDate={
                                    filter.dateRange &&
                                    filter.dateRange.startDate &&
                                    filter.dateRange.startDate.toISOString()
                                }
                                withIsSelected={false}
                            />
                        </div>
                    )}
                </div>
                <br />
                <br />
                <div className="row">
                    <h5 className="col-sm-4">Summary Statistics</h5>
                </div>
                {this.state &&
                    this.state.activeCategory &&
                    typeTotalsSinceSystemStartResponse &&
                    dynamicStartDateTypeTotalsResponse &&
                    filter &&
                    StatisticsCategoryTabs.length > 0 &&
                    filter.dateRange && (
                        <div>
                            <div>
                                <RowMenu
                                    activeTab={this.state.activeCategory}
                                    onSelectTab={this.changeStatisticsCategory.bind(this)}
                                    tabs={StatisticsCategoryTabs}
                                />
                                <WfpTotals
                                    canDownloadSummaryReport={
                                        hasFeatureAccess(
                                            this.props.auth,
                                            ManagerPermission.viewAndDownloadSummaryReports
                                        ) && hasFeatureAccess(this.props.auth, ManagerPermission.transactionsDownload)
                                    }
                                    currency={this.props.currency}
                                    downloadSummaryStatisticsTransactions={this.downloadSummaryStatisticsTransactions.bind(
                                        this
                                    )}
                                    dynamicStartTypeTotals={
                                        dynamicStartDateTypeTotalsResponse[this.state.activeCategory.toString()] &&
                                        dynamicStartDateTypeTotalsResponse[this.state.activeCategory.toString()]
                                            .typeTotals
                                    }
                                    endDate={filter.dateRange.endDate}
                                    generationPeriod={
                                        dynamicStartDateTypeTotalsResponse[this.state.activeCategory.toString()] &&
                                        dynamicStartDateTypeTotalsResponse[this.state.activeCategory.toString()]
                                            .generationPeriod
                                    }
                                    startDate={filter.dateRange.startDate}
                                    systemStartDate={systemStartDate}
                                    timezone={this.props.timezone}
                                    typeTotalsSinceSystemStart={
                                        typeTotalsSinceSystemStartResponse[this.state.activeCategory.toString()] &&
                                        typeTotalsSinceSystemStartResponse[this.state.activeCategory.toString()]
                                            .typeTotals
                                    }
                                />
                            </div>
                        </div>
                    )}
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigate(SummaryStatisticsPage, 'SummaryStatisticsPage'));
