import { ActivityLog, ActivityTypes } from '../activityLogs';
import * as React from 'react';
import PagingComponent, { PagedState } from '../../utils/paging';
import { displayTime } from '../../utils/utils';
import { VendorNickName } from '../../vendors/vendors';

interface Props {
    getActivityLogs: (page: number, limit: number) => void;
    onActivityLogChosen: (log: ActivityLog<any>) => void;

    activityLogsChildList: PagedState<ActivityLog<any>>;
    vendorsNickNames: VendorNickName[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

const PageLimit = 25;
export class ChildListView extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {};
    }
    UNSAFE_componentWillMount() {
        this.onChangePageRequested(1);
    }

    onChangePageRequested(page: number) {
        this.props.getActivityLogs(page, PageLimit);
    }

    private tableHeader(hasCendor: boolean) {
        return (
            <thead>
                <tr>
                    <th>Created</th>
                    <th>BeneficiaryId</th>
                    <th>Activity</th>
                    {hasCendor && <th>Vendor</th>}
                </tr>
            </thead>
        );
    }

    tableBody(hasVendor: boolean) {
        const { activityLogsChildList, vendorsNickNames } = this.props;
        return (
            <tbody className="transactions">
                {activityLogsChildList &&
                    activityLogsChildList.items &&
                    activityLogsChildList.items.map((activityLog) => {
                        const vendor = activityLog.vendorId
                            ? vendorsNickNames.find((name) => name.id === activityLog.vendorId)
                            : null;
                        const vendorName = vendor ? vendor.nickName : '';
                        return (
                            <tr
                                className={'cursor-pointer '}
                                key={activityLog.id}
                                onClick={() => this.props.onActivityLogChosen.bind(this)(activityLog)}
                            >
                                <td>{displayTime(new Date(activityLog.created_at))}</td>
                                <td>{activityLog.beneficiaryId}</td>
                                <td>{ActivityTypes.displayName(activityLog.activity)}</td>
                                {hasVendor && <td>{vendorName}</td>}
                            </tr>
                        );
                    })}
            </tbody>
        );
    }

    render() {
        const hasVendor = Array.isArray(this.props.activityLogsChildList?.items)
            ? this.props.activityLogsChildList?.items?.some((log) => log.vendorId !== null)
            : false;
        return (
            <div>
                <table className="wfp-table--striped mt4 table-hover">
                    {this.tableHeader(hasVendor)}
                    {this.tableBody(hasVendor)}
                </table>
                {this.props.activityLogsChildList && (
                    <PagingComponent
                        key="transactionsPagination"
                        onPageChanged={this.onChangePageRequested.bind(this)}
                        paging={this.props.activityLogsChildList.paging}
                    />
                )}
            </div>
        );
    }
}
