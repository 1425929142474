import {
    authorizeProductPrice,
    cancelProductPrice,
    getGroupedProductPrices,
    getProductPriceById,
} from '../../apiClient';
import { errorAction, successAction } from '../../utils/notifications';
import { compose } from '../../utils/utils';
import { ProductPriceState } from './reducer';

export enum ACTION_TYPE {
    LOAD = 'productPrice.load',
    UNLOAD_PRICES = 'prodctPrice.unload-prices',
    LOAD_ONE = 'productPrice.load-one',
    CANCEL = 'productPrice.cancel',
    AUTHORIZE = 'productPrice.authorize',
    CLEAR_CURRENT_PRICE = 'productPrice.clear-current-price',
    CHANGE_QUERY = 'productPrice.change-query',
}

export enum QUERY_TYPE {
    status = 'status',
    vendors = 'vendors',
    outlets = 'outlets',
}

export const productPriceActions = {
    loadProductPrices(dispatch) {
        return async (sortOptions, filters: Record<string, string[]>) => {
            try {
                const res = await getGroupedProductPrices(sortOptions, filters);
                dispatch({ type: ACTION_TYPE.LOAD, payload: res });
                return res;
            } catch (error) {
                compose(dispatch, errorAction)(messages.loadError);
            }
        };
    },
    clearProductPrices(dispatch) {
        return () => {
            dispatch({ type: ACTION_TYPE.UNLOAD_PRICES });
        };
    },
    loadProductPriceById(dispatch) {
        return async (priceId) => {
            try {
                const res = await getProductPriceById(priceId);
                dispatch({ type: ACTION_TYPE.LOAD_ONE, payload: res });
                return res;
            } catch (error) {
                compose(dispatch, errorAction)(messages.loadError);
            }
        };
    },

    authorizeProductPrice(dispatch, productPriceState: ProductPriceState) {
        return async (priceId) => {
            try {
                await authorizeProductPrice(priceId);
                dispatch({ type: ACTION_TYPE.AUTHORIZE });
                const query = Object.entries(productPriceState).reduce(
                    (queryObject, [key, value]) => ({ ...queryObject, [key]: value.map((item) => item.value) }),
                    {}
                );
                await productPriceActions.loadProductPrices(dispatch)({}, query);
                compose(dispatch, successAction)(messages.authorizeSuccess);
            } catch (error) {
                compose(dispatch, errorAction)(messages.authorizeError);
            }
        };
    },

    cancelProductPrice(dispatch, productPriceState: ProductPriceState) {
        return async (priceId) => {
            try {
                await cancelProductPrice(priceId);
                dispatch({ type: ACTION_TYPE.CANCEL });
                const query = Object.entries(productPriceState).reduce(
                    (queryObject, [key, value]) => ({ ...queryObject, [key]: value.map((item) => item.value) }),
                    {}
                );
                await productPriceActions.loadProductPrices(dispatch)({}, query);
                compose(dispatch, successAction)(messages.cancelSuccess);
            } catch (error) {
                compose(dispatch, errorAction)(messages.cancelError);
            }
        };
    },
    clearCurrentPrice(dispatch) {
        return () => {
            dispatch({ type: ACTION_TYPE.CLEAR_CURRENT_PRICE });
        };
    },
    changeStatus(dispatch) {
        return (payload) => {
            dispatch({
                type: ACTION_TYPE.CHANGE_QUERY,
                payload: { key: 'status', data: payload },
            });
        };
    },

    changeQuery(dispatch) {
        return (payload: unknown, key: QUERY_TYPE) => {
            dispatch({
                type: ACTION_TYPE.CHANGE_QUERY,
                payload: { key, data: payload },
            });
        };
    },
};

const messages = {
    cancelError: `Could not cancel price`,
    cancelSuccess: `Price cancelled successfully`,
    authorizeError: `Could not authorize price`,
    authorizeSuccess: `Price authorized successfully`,
    loadError: `Data could not be loaded`,
};
