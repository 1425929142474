import * as React from 'react';
import { useCallback } from 'react';

interface Props {
    userId: number | string;
    uploadPermissionsFile: (userId: number | string, file: File) => void;
}

function UploadPermissionsButton({ userId, uploadPermissionsFile, ...props }: Props) {
    const uploadPermissions = useCallback(
        (event) => {
            event.persist();
            uploadPermissionsFile(userId, event.target.files[0]);
        },
        [userId]
    );
    return (
        <button className="wfp-btn col-sm-4 p-0" type="button" {...props}>
            <label className="mb-0 w-100 cursor-pointer" htmlFor="permissions-file" style={{ padding: '8px 16px' }}>
                Upload Permissions
                <input
                    accept="image/png, image/jpeg, application/pdf"
                    hidden
                    id="permissions-file"
                    name="permissions-file"
                    onChange={uploadPermissions}
                    type="file"
                />
            </label>
        </button>
    );
}

export { UploadPermissionsButton };
