import { InformationDialog } from '../../utils/Dialogs';
import * as React from 'react';

export function ScheduledUpdateModal({ groupName, scheduledDate, onClose }) {
    return (
        <InformationDialog
            message={<ScheduledUpdateMessage group={groupName} scheduledDate={scheduledDate} />}
            onClose={onClose}
            title="Scheduled updates"
        />
    );
}
const ScheduledUpdateMessage = ({ group, scheduledDate }) => (
    <>
        <strong>{group} </strong> product group cannot be updated because another update is already scheduled for
        <strong> {scheduledDate}</strong>
    </>
);
