import * as React from 'react';

import { displayTime, currencyFormatter } from './utils';
import PagingComponent, { PagedState } from './paging';
import { Transaction, TransactionStatuses, TransactionTypes } from '../transactions/transactions';
import VendorTransaction from '../vendorPanel/transactions/transaction';
import { isTransaction, TransactionHeaders } from './transactionTableUtils';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import { SortableTableHead } from './table/table-head/sortable-table-head';
import { useCallback } from 'react';
import { SORT_OPTIONS, SortOptions } from './hooks/useSort';
interface HeaderToCell {
    [tableHeader: string]: string | number;
}

function mapTransactionToHeaders(
    transaction: Transaction | VendorTransaction,
    currency: EntitlementCurrencyConfig
): HeaderToCell {
    return {
        [TransactionHeaders.time]: displayTime(new Date(transaction.createdAt)),
        [TransactionHeaders.sender]: TransactionStatuses.mapVendorNickNameToSender(transaction),
        [TransactionHeaders.receiver]: TransactionStatuses.mapVendorNickNameToReceiver(transaction),
        [TransactionHeaders.transactionType]: TransactionTypes.displayName(transaction.type),
        [TransactionHeaders.transactionId]: transaction.id,
        [TransactionHeaders.originalTransactionId]: transaction.originalTransactionId || transaction.id,
        [TransactionHeaders.amount]: currencyFormatter(currency).format(transaction.amount.toNumber()),
        [TransactionHeaders.transactionStatus]: transaction.displayNameForTransactionStatus,
        [TransactionHeaders.paymentStatus]: transaction.displayNameForFinancialStatus,
        [TransactionHeaders.did]: transaction.stno,
        [TransactionHeaders.outlet]: transaction.outlet,
        [TransactionHeaders.category]: transaction.category,
        [TransactionHeaders.agency]: transaction.agency,
    };
}

interface Props {
    transactions: PagedState<Transaction | VendorTransaction>;
    headers: Array<TransactionHeaders>;
    currency: EntitlementCurrencyConfig;
    onChangePageRequested: (page: number) => void;
    onTransactionChosen?: (transaction: Transaction | VendorTransaction) => void;
    onSort?(options: SORT_OPTIONS): void;
}
const columnToAttributeMapping = {
    Time: 'created_at',
    Sender: 'sender_id',
    Receiver: 'receiver_id',
    'TX #': 'id',
    'Original Transaction ID': 'original_transaction_id',
    Amount: 'amount',
    'TX Status': 'status',
    'Transaction ITN': 'itn',
    DID: 'stno',
    Branch: 'branchCode',
    Category: 'category',
};

function TransactionsTable({
    transactions,
    headers,
    currency,
    onChangePageRequested,
    onTransactionChosen,
    onSort,
}: Props) {
    const handleSort = useCallback(([column, direction]) => {
        const options = new SortOptions({
            column: columnToAttributeMapping[column],
            direction,
        });
        onSort?.(options);
    }, []);
    return (
        <div style={{ minWidth: 1250 }}>
            <table className="wfp-table--striped mt4 table-hover">
                <SortableTableHead changeSortOptions={handleSort}>
                    {headers.map((header) =>
                        columnToAttributeMapping[header] ? (
                            <th key={header}>
                                <span>{header}</span>
                            </th>
                        ) : (
                            <SortableTableHead.DisabledColumn key={header}>{header}</SortableTableHead.DisabledColumn>
                        )
                    )}
                </SortableTableHead>
                <tbody className="transactions">
                    {transactions.items &&
                        transactions.items.map((transaction) => (
                            <TransactionRow
                                currency={currency}
                                headers={headers}
                                key={transaction.id}
                                onTransactionChosen={onTransactionChosen}
                                transaction={transaction}
                            />
                        ))}
                </tbody>
            </table>
            <PagingComponent
                key="transactionsPagination"
                onPageChanged={onChangePageRequested}
                paging={transactions.paging}
            />
        </div>
    );
}
export default TransactionsTable;

function TransactionRow({ transaction, onTransactionChosen, currency, headers }) {
    const isFailedInvalidTransactions = transaction.status === TransactionStatuses.failed;
    const isUnknownInvalidTransactions = transaction.status === TransactionStatuses.unknown;
    const transactionPendingClass = isTransaction(transaction) ? transaction.pendingTransactionRequestId : false;
    const transactionStatusClass = isFailedInvalidTransactions
        ? 'failed'
        : isUnknownInvalidTransactions
        ? 'unknown'
        : transactionPendingClass
        ? 'pending-action'
        : 'success';
    const pointerStatusClass = onTransactionChosen ? 'cursor-pointer' : '';

    const headersWithValues = mapTransactionToHeaders(transaction, currency);
    return (
        <tr
            className={`${pointerStatusClass} ${transactionStatusClass}`}
            key={transaction.id}
            onClick={() => onTransactionChosen?.(transaction)}
        >
            {headers.map((header) => (
                <td key={header + headersWithValues[header]}>{headersWithValues[header]}</td>
            ))}
        </tr>
    );
}
