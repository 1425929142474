import * as React from 'react';
import { Link } from 'react-router';
import FeatureButton from '../utils/FeatureButton';
import State from '../app/store/state';
import { connect } from 'react-redux';
import { AuthState } from '../login/auth';
import PartnersList from './PartnersList';
import { bindActionCreators } from 'redux';
import { ActionCreators } from './partnersActions';
import { ManagerPermission } from '../permission-profiles/permission';

interface DispatchProps {
    authorizePartner: (id: string) => void | any;
    resetPassword: (id: string) => void | any;
    cancelPartner: (partnerId: string) => void | any;
}

interface StateProps {
    auth: AuthState;
}

type Props = DispatchProps & StateProps;

class PartnersPage extends React.Component<Props, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Partners</span>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <h3 className="col-sm-4">Partners</h3>
                    <div className="col-sm-8 tr">
                        <FeatureButton
                            linkTo="/partners/new"
                            manager={this.props.auth.manager}
                            restrictedToFeatures={[ManagerPermission.partnerPark]}
                            title="Add Partner"
                        />
                    </div>
                </div>
                <PartnersList
                    auth={this.props.auth}
                    authorize={this.props.authorizePartner.bind(this)}
                    handleCancel={this.props.cancelPartner}
                    resetPassword={this.props.resetPassword.bind(this)}
                />
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        auth: state.auth,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        cancelPartner: bindActionCreators(ActionCreators.cancelPartner, dispatch),
        authorizePartner: bindActionCreators(ActionCreators.authorizePartner, dispatch),
        resetPassword: bindActionCreators(ActionCreators.resetPassword, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersPage as any);
