import * as React from 'react';
import { ConfirmationDialog } from '../utils/Dialogs';
import styled from 'styled-components';
import { wfpFormat } from '../utils/utils';

export function ConfirmProductManagementUpdateModalComponent({ onClose, onConfirm, title, entityName }) {
    return (
        <ConfirmationDialog
            message={
                <WarningContainer>
                    <p>
                        <strong>
                            <div>ATTENTION:</div>

                            <div>
                                Please check to ensure that all products, prices, branches, restrictions, and vendors
                                for {wfpFormat(entityName)} are correct.{' '}
                                <em>
                                    In particular, please triple-check the prices as even a single BDT 0.01 difference
                                    will mean all transactions related to the product(s) will be incorrect, which may
                                    result in irrecoverable financial loss to WFP.
                                </em>
                            </div>

                            <div>
                                Furthermore, please note all changes become active the instant level II is posted and
                                will remain active until a further change is fully posted. Among other things, this
                                means that if new products/prices are available but are not yet fully posted in BB, the
                                old product/prices will continue to be applied, which again could result in errors and
                                irrecoverable financial loss to WFP.
                            </div>
                        </strong>
                    </p>
                </WarningContainer>
            }
            onClose={onClose}
            onConfirm={onConfirm}
            title={title}
        />
    );
}

const WarningContainer = styled.div`
    color: white;
    background: red;
    border-radius: 3px;
    padding: 1rem;
    position: relative;

    div {
        margin-bottom: 0.5rem;
        em {
            font-style: normal;
            text-decoration: underline;
        }
    }
`;
