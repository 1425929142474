import * as React from 'react';
import LoginPage from './LoginPage';
import { bindActionCreators } from 'redux';
import { ActionCreators } from './auth';
import { connect } from 'react-redux';
import { windowStorage, WindowStorageToken } from '../windowStorage';

function ResetOtpPage(props) {
    if (windowStorage.getItem(WindowStorageToken.managerApiToken)) {
        // eslint-disable-next-line react/prop-types
        props.logout(window.location.href);
    }
    return <LoginPage otpReset={true} />;
}

function mapDispatchToProps(dispatch: any) {
    return {
        logout: bindActionCreators(ActionCreators.logout, dispatch),
        checkToken: bindActionCreators(ActionCreators.checkToken, dispatch),
    };
}

export default connect(null, mapDispatchToProps, null)(ResetOtpPage);
