import * as React from 'react';
import AppState from '../app/store/state';
import { PagedState } from '../utils/paging';
import {
    ActionCreators,
    CustomEntityUpdateListType,
    EntityType,
    EntityUpdate,
    EntityUpdateRequest,
    ListedEntitiesType,
    ProductUpdateRequest,
    UpdateStateListType,
} from './taskAuthorizations';
import { bindActionCreators } from 'redux';
import EntityUpdatesAuthorizationList, { UpdateRowConfiguration } from './EntityUpdatesAuthorizationList';
import { AuthState, hasFeatureAccess } from '../login/auth';
import configurationForBeneficiaryUpdate from './configurationForBeneficiaryUpdate';
import { default as Manager } from '../managers/manager';
import { Filter, SelectFilter, SelectOption, TextFilter } from '../utils/FilterTypes';
import { RowMenu, RowMenuProps } from '../utils/RowMenu';
import FiltersComponent from '../utils/filters';
import { connect } from 'react-redux';
import { AuthorizationTab } from './TaskAuthorizationsPage';
import { EntitlementCategoriesConfig } from '../app/appConfig';
import { ManagerPermission } from '../permission-profiles/permission';

const beneficiaryFiltersOptions = [
    new SelectOption('Details', EntityType.beneficiaryDetails),
    new SelectOption('Single Block', EntityType.beneficiarySingleBlock),
    new SelectOption('Single Unblock', EntityType.beneficiarySingleUnblock),
    new SelectOption('Zeroing Block', EntityType.beneficiaryZeroingBlock),
    new SelectOption('Zeroing', EntityType.beneficiaryZeroing),
];

const beneficiaryFilters: Filter[] = [
    new TextFilter('Beneficiary ID', 'entityId', ''),
    new SelectFilter('Update Type', 'entityType', beneficiaryFiltersOptions[0].value, beneficiaryFiltersOptions),
];

const filtersForList = {
    [AuthorizationTab.Beneficiaries]: beneficiaryFilters,
};

function AuthorizationRowMenu(props: RowMenuProps<UpdateStateListType>) {
    return RowMenu(props);
}
const authorizationTypeToListedEntitiesType = {
    [AuthorizationTab.Beneficiaries]: CustomEntityUpdateListType.beneficiaryAllUpdates,
    [AuthorizationTab.VendorsDetails]: EntityType.vendorDetails,
    [AuthorizationTab.ManagerDetails]: EntityType.managerDetails,
    [AuthorizationTab.VendorsUsersDetails]: EntityType.vendorUserDetails,
    [AuthorizationTab.PartnersDetails]: EntityType.partnerDetails,
    [AuthorizationTab.PartnersUsersDetails]: EntityType.partnerUserDetails,
    [AuthorizationTab.Locations]: EntityType.locationDetails,
    [AuthorizationTab.Products]: EntityType.productManagement,
    [AuthorizationTab.PermissionProfiles]: EntityType.permissionProfileDetails,
};

interface Props {
    tab: AuthorizationTab;
    auth: AuthState;
    entityUpdateRequests: PagedState<EntityUpdateRequest | ProductUpdateRequest>;
    activeListType: UpdateStateListType;
    editEntityUpdateRequest: (id: string, newUpdates: EntityUpdate[]) => void;
    loadEntityUpdateRequests: (
        listedEntitiesType: ListedEntitiesType,
        listType: UpdateStateListType,
        page: number,
        limit: number,
        filters?: Filter[]
    ) => void;
    parkEntityUpdateRequest: (entityUpdateRequest: EntityUpdateRequest, manager: Manager) => void;
    confirmEntityUpdateRequest: (entityUpdateRequest: EntityUpdateRequest) => void;
    rejectEntityUpdateRequest: (entityUpdateRequest: EntityUpdateRequest, comment: string) => void;
    changeListType: (activeListType: UpdateStateListType) => void;
    categories: EntitlementCategoriesConfig;
    clearUpdateRequests: () => void;
}

interface State {
    filters?: Filter[];
}

const PageLimit = 15;
class EntityUpdateAuthorizationPage extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            filters: filtersForList[props.tab],
        };
    }

    componentDidMount() {
        const listedEntityType = authorizationTypeToListedEntitiesType[this.props.tab];
        this.loadEntityUpdateRequests(listedEntityType, this.props.activeListType, 1);
    }
    componentWillUnmount() {
        this.props.clearUpdateRequests();
    }

    configurationForUpdateRequest(entityUpdateRequest: EntityUpdateRequest): UpdateRowConfiguration {
        if (entityUpdateRequest.authorizedAt || entityUpdateRequest.deletedAt) {
            return new UpdateRowConfiguration(true, null);
        }
        const isCreator = entityUpdateRequest.createdByManager
            ? entityUpdateRequest.createdByManager.id === this.props.auth.manager.id
            : false;
        const isOwnAffected =
            entityUpdateRequest.entityType === EntityType.managerDetails &&
            entityUpdateRequest.entityId === this.props.auth.manager.id;
        const hasNotPermission =
            entityUpdateRequest.updates.find((v) => v.path === 'otpEnabled') &&
            !hasFeatureAccess(this.props.auth, ManagerPermission.otpStatusChangePost);
        if (this.props.auth.manager.id !== '1' && (isOwnAffected || isCreator || hasNotPermission)) {
            let comment;
            if (isCreator) {
                comment = 'You cannot post an update that you parked yourself';
            } else if (isOwnAffected) {
                comment = 'You cannot post an update that is related to you';
            } else if (hasNotPermission) {
                comment = 'You cannot post an update without related permission';
            }
            return new UpdateRowConfiguration(isCreator || isOwnAffected || hasNotPermission, comment);
        }
        if (
            entityUpdateRequest.updates.map((u) => u.path).includes('expirationDate') &&
            entityUpdateRequest.entityId === this.props.auth.manager.id
        ) {
            //todo to remove check user id
            const comment = "You cannot post this update, because it includes your account's expiration date change";
            return new UpdateRowConfiguration(isCreator, comment);
        }
        switch (entityUpdateRequest.entityType) {
            case EntityType.beneficiaryDetails:
            case EntityType.beneficiaryZeroing:
            case EntityType.beneficiaryZeroingBlock:
            case EntityType.beneficiarySingleBlock:
            case EntityType.beneficiarySingleUnblock:
            case EntityType.alternativeCollectorStatusChange: {
                return configurationForBeneficiaryUpdate(entityUpdateRequest, this.props.auth);
            }
            case EntityType.managerDetails: {
                const readonly = !hasFeatureAccess(this.props.auth, ManagerPermission.managersManagementPost);
                const comment = readonly ? 'No permission to post user details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            case EntityType.vendorDetails: {
                const readonly = !hasFeatureAccess(this.props.auth, ManagerPermission.vendorsPost);
                const comment = readonly ? 'No permission to post vendor details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            case EntityType.vendorUserDetails: {
                const readonly = !hasFeatureAccess(this.props.auth, ManagerPermission.vendorUserPost);
                const comment = readonly ? 'No permission to post vendor user details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            case EntityType.partnerDetails: {
                const readonly = !hasFeatureAccess(this.props.auth, ManagerPermission.partnerPost);
                const comment = readonly ? 'No permission to post partner details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            case EntityType.partnerUserDetails: {
                const readonly = !hasFeatureAccess(this.props.auth, ManagerPermission.partnerUserPost);
                const comment = readonly ? 'No permission to post partner user details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            case EntityType.permissionProfileDetails: {
                const readonly = !hasFeatureAccess(this.props.auth, ManagerPermission.managersManagementPost);
                const comment = readonly ? 'No permission to post permission profile details' : null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            case EntityType.preParkedBeneficiary: {
                const readonly = {
                    edit: !hasFeatureAccess(this.props.auth, ManagerPermission.beneficiariesPark),
                    park: !hasFeatureAccess(this.props.auth, ManagerPermission.beneficiariesPark),
                    post:
                        !hasFeatureAccess(this.props.auth, ManagerPermission.beneficiariesPost) ||
                        (entityUpdateRequest.createdByManager &&
                            entityUpdateRequest.createdByManager.id === this.props.auth.manager.id),
                };
                const comment = null;
                return new UpdateRowConfiguration(readonly, comment);
            }
            default:
                return new UpdateRowConfiguration(false, null);
        }
    }

    loadEntityUpdateRequests(
        listedEntitiesType: ListedEntitiesType,
        activeListTypeTab: UpdateStateListType,
        page: number
    ) {
        this.props.loadEntityUpdateRequests(listedEntitiesType, activeListTypeTab, page, PageLimit, this.state.filters);
    }

    private applyFilters(filters: Filter[]) {
        this.setState({ filters }, () => {
            this.loadEntityUpdateRequests(
                CustomEntityUpdateListType.beneficiaryAllUpdates,
                this.props.activeListType,
                1
            );
        });
    }

    renderBeneficiariesAuthorization() {
        return (
            <div>
                <div style={{ marginBottom: 50 }}>
                    <FiltersComponent
                        defaultFilters={filtersForList[this.props.tab]}
                        filters={this.state.filters}
                        onApplyFilters={this.applyFilters.bind(this)}
                        withApply={true}
                        withIsSelected={true}
                    />
                </div>
                {this.renderEntityUpdateAuthorization(this.configurationForUpdateRequest.bind(this))}
            </div>
        );
    }

    parkUpdate(entityUpdateRequest: EntityUpdateRequest) {
        this.props.parkEntityUpdateRequest(entityUpdateRequest, this.props.auth.manager);
    }

    renderEntityUpdateAuthorization(
        configurationForUpdate: (entityUpdateRequest: EntityUpdateRequest) => UpdateRowConfiguration
    ) {
        const { entityUpdateRequests, auth } = this.props;
        const listedEntitiesType = authorizationTypeToListedEntitiesType[this.props.tab];
        if (!auth.manager) {
            return null;
        }

        return (
            <EntityUpdatesAuthorizationList
                categories={this.props.categories.categories}
                configurationForUpdate={configurationForUpdate}
                entityUpdateRequests={entityUpdateRequests}
                listType={this.props.activeListType}
                managerId={auth.manager.id}
                onConfirmUpdateRequest={this.props.confirmEntityUpdateRequest}
                onEditUpdateRequest={this.props.editEntityUpdateRequest}
                onPageChanged={(page) =>
                    this.loadEntityUpdateRequests.bind(this)(listedEntitiesType, this.props.activeListType, page)
                }
                onParkUpdateRequest={this.parkUpdate.bind(this)}
                onRejectUpdateRequest={this.props.rejectEntityUpdateRequest}
            />
        );
    }

    changeRequestsTab(activeListType) {
        const listedEntitiesType = authorizationTypeToListedEntitiesType[this.props.tab];
        this.props.changeListType(activeListType);
        this.loadEntityUpdateRequests(listedEntitiesType, activeListType, 1);
    }

    render() {
        const requestsTabs = [UpdateStateListType.Requested, UpdateStateListType.Posted, UpdateStateListType.Rejected];
        return (
            <div>
                <AuthorizationRowMenu
                    activeTab={this.props.activeListType}
                    onSelectTab={this.changeRequestsTab.bind(this)}
                    tabs={requestsTabs}
                />
                {this.props.tab === AuthorizationTab.Beneficiaries && this.renderBeneficiariesAuthorization()}
                {[
                    AuthorizationTab.ManagerDetails,
                    AuthorizationTab.VendorsDetails,
                    AuthorizationTab.VendorsUsersDetails,
                    AuthorizationTab.PartnersDetails,
                    AuthorizationTab.PartnersUsersDetails,
                    AuthorizationTab.Locations,
                    AuthorizationTab.PermissionProfiles,
                    AuthorizationTab.Products,
                ].includes(this.props.tab) &&
                    this.renderEntityUpdateAuthorization(this.configurationForUpdateRequest.bind(this))}
            </div>
        );
    }
}

interface OwnProps {
    tab: AuthorizationTab;
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        auth: state.auth,
        tab: ownProps.tab,
        entityUpdateRequests: state.taskAuthorizations.entityUpdateRequests,
        activeListType: state.taskAuthorizations.activeListType,
        categories: state.appConfig.entitlementsConfig,
    };
}
function mapDispatchToProps(dispatch: any) {
    return {
        editEntityUpdateRequest: bindActionCreators(ActionCreators.editEntityUpdateRequest, dispatch),
        loadEntityUpdateRequests: bindActionCreators(ActionCreators.loadEntityUpdateRequests, dispatch),
        confirmEntityUpdateRequest: bindActionCreators(ActionCreators.confirmEntityUpdateRequest, dispatch),
        parkEntityUpdateRequest: bindActionCreators(ActionCreators.parkEntityUpdateRequest, dispatch),
        rejectEntityUpdateRequest: bindActionCreators(ActionCreators.rejectEntityUpdateRequest, dispatch),
        changeListType: bindActionCreators(ActionCreators.changeListType, dispatch),
        clearUpdateRequests: bindActionCreators(ActionCreators.clearUpdateRequests, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityUpdateAuthorizationPage as any);
