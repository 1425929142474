import * as React from 'react';
import { displayTime } from '../utils/utils';
import {
    ActionCreators,
    AsyncTask,
    AsyncTaskRawStatus,
    AsyncTaskStatus,
    AsyncTasksTypes,
    displayValidFrom,
    finishedTasksStatuses,
    isFinancialProcessor,
    isParkingSes,
    isParkingSesAlignment,
    isValidator,
} from '../utils/asyncTasks';
import Manager from '../managers/manager';
import { connect } from 'react-redux';
import State from '../app/store/state';
import { bindActionCreators } from 'redux';
import { scaleFloatToInteger } from '../utils/customMath';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ManagerPermission } from '../permission-profiles/permission';
import { withNavigate } from '@wfp-common/hooks/withNavigate';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';

interface Props {
    cancelTask: (type: string, id: string, isSubtask: boolean) => void;
    manager: Manager;
    data: Array<AsyncTask>;
    canAuthorizeTask?: boolean;
    canCancelSubtask?: boolean;
    authorizedManagerId: string;
    authorize: (type: string, id: string) => Promise<void>;
    canDownload: boolean;
    downloadFile: (type: string, id: string) => string;
    navigate: NavigateHook;
}

class AsyncTasksListView extends React.Component<Props, any> {
    constructor(props) {
        super(props);
    }

    getNameTagPostfix(asyncTask: AsyncTask, index = 1): string {
        let nameTag = `Subimport #${index}`;
        if (isValidator(asyncTask)) {
            nameTag = 'Integrity validation';
        } else if (isFinancialProcessor(asyncTask)) {
            nameTag = 'Financial validation';
        } else if (isParkingSes(asyncTask)) {
            nameTag = 'SES Parking';
        } else if (isParkingSesAlignment(asyncTask)) {
            nameTag = 'SES Alignment Parking';
        }
        return nameTag;
    }

    private renderNameCell(asyncTask: AsyncTask, isChild: boolean, index: number) {
        if (isChild) {
            return <>{this.getNameTagPostfix(asyncTask, index)}</>;
        } else {
            const fileName = asyncTask.fileName;
            const interventionName = asyncTask.interventionName;

            return (
                <div>
                    <div className="wfp-form--group ">{interventionName}</div>
                    <div className="wfp-form--group ">{fileName}</div>
                </div>
            );
        }
    }

    renderRow(row: AsyncTask, child?: boolean, index?: number) {
        if (row) {
            let signatureOne = null;
            let signatureTwo = null;
            if (row.signatures.length > 0) {
                signatureOne = row.signatures.find(
                    (s) => s.requiredRole === ManagerPermission.beneficiariesSignLevelOne
                );
                signatureTwo = row.signatures.find(
                    (s) => s.requiredRole === ManagerPermission.beneficiariesSignLevelTwo
                );
            }

            let manualParkedPO = false;
            if (row.parentId) {
                const sapPOToAmount = this.props.data.find((task) => task.id === row.parentId).sapPoToAmount;
                manualParkedPO = sapPOToAmount ? !!sapPOToAmount.authorizedByManagerId : false;
            }

            const isRowWithRetries = row.retriesCount > 1;
            let rowStatus;
            if (isFinancialProcessor(row) && row.status === AsyncTaskStatus.failed && manualParkedPO) {
                rowStatus = AsyncTaskStatus.overridden;
            } else {
                rowStatus = isRowWithRetries
                    ? `${row.status === AsyncTaskStatus.created ? 'To be retried' : row.status} (${
                          row.retriesCount
                      } attempt)`
                    : row.status;

                rowStatus +=
                    row.progress && row.children.length === 0 && row.rawStatus === AsyncTaskRawStatus.inProgress
                        ? `(${scaleFloatToInteger(parseFloat(row.progress), 2)}%)`
                        : '';
            }

            const isScope = row.type === AsyncTasksTypes.SCOPE_ANNOUNCEMENTS;
            const level1Sign = signatureOne ? !!signatureOne.signedByManagerId : false;
            const level2Sign = signatureTwo ? !!signatureTwo.signedByManagerId : false;
            return (
                <tr
                    className={child ? 'child' : 'parent'}
                    data-parent={row.parentId}
                    key={row.id}
                    onClick={this._onRowChosen.bind(this, row)}
                >
                    <td>{this.renderNameCell(row, child, index)}</td>
                    <td>{displayTime(row.createdAt)}</td>
                    <td>{isScope ? 'SCOPE' : displayTime(row.parkedAt)}</td>
                    <td>{isScope ? 'SCOPE' : displayTime(row.authorizedAt)}</td>
                    <td>{displayValidFrom(row)}</td>
                    <td>{displayTime(row.startedAt)}</td>
                    <td>{displayTime(row.finishedAt)}</td>
                    <td>{rowStatus}</td>
                    <td>
                        {!child && row.signatures.length > 0 && (
                            <div>
                                <div className="wfp-form--group row">
                                    <input
                                        checked={level1Sign}
                                        name={'sign_1'}
                                        readOnly
                                        style={{ marginRight: 5 }}
                                        type={'checkbox'}
                                    />
                                    <label>Level 1</label>
                                </div>
                                <div className="wfp-form--group row">
                                    <input
                                        checked={level2Sign}
                                        name={'sign_2'}
                                        readOnly
                                        style={{ marginRight: 5 }}
                                        type={'checkbox'}
                                    />
                                    <label>Level 2</label>
                                </div>
                            </div>
                        )}
                    </td>
                    {this.props.canDownload ? (
                        <td>
                            {!child && row.fileName && (
                                <a
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.props.downloadFile(row.type, row.id);
                                    }}
                                >
                                    Download
                                </a>
                            )}
                        </td>
                    ) : (
                        <td>-</td>
                    )}
                </tr>
            );
        }
    }

    sortAsyncTaskChild(childrenExcludingUploadReports: AsyncTask[]) {
        const validator = childrenExcludingUploadReports.find((task) => isValidator(task));
        const financials = childrenExcludingUploadReports.filter((task) => isFinancialProcessor(task));
        const financialSummarise = financials[0];
        if (financialSummarise) {
            financialSummarise.retriesCount = financials.length;
        }
        const sesParkings = childrenExcludingUploadReports.filter((task) => isParkingSes(task));
        const sesParkingSummarise = sesParkings[0];
        if (sesParkingSummarise) {
            sesParkingSummarise.retriesCount = sesParkings.length;
        }
        const sesAlignmentParkings = childrenExcludingUploadReports.filter((task) => isParkingSesAlignment(task));
        const sesAlignmentParkingSummarise = sesAlignmentParkings[0];
        if (sesAlignmentParkingSummarise) {
            sesAlignmentParkingSummarise.retriesCount = sesAlignmentParkings.length;
        }
        const allOtherTasks = childrenExcludingUploadReports.filter(
            (task) =>
                !isFinancialProcessor(task) && !isValidator(task) && !isParkingSes(task) && !isParkingSesAlignment(task)
        );
        const sortedAllOtherChildren = allOtherTasks.sort((a, b) => {
            if (a.scheduledFor && b.scheduledFor) {
                return new Date(a.scheduledFor).getTime() - new Date(b.scheduledFor).getTime();
            }
            if (a?.additionalInfo?.validFrom && b?.additionalInfo?.validFrom) {
                return (
                    moment(a.additionalInfo.validFrom, 'YYYYMMDD').toDate().getTime() -
                    moment(b.additionalInfo.validFrom, 'YYYYMMDD').toDate().getTime()
                );
            }
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        });
        const tasksToDisplay = [validator, financialSummarise, sesParkingSummarise, ...sortedAllOtherChildren];
        const unfinishedChildrenCount = tasksToDisplay.filter(
            (task) => task && !finishedTasksStatuses.includes(task.status)
        ).length;
        if (
            unfinishedChildrenCount === 0 &&
            sesAlignmentParkingSummarise &&
            (sortedAllOtherChildren.length > 0 || sesAlignmentParkingSummarise.status !== AsyncTaskStatus.created)
        ) {
            tasksToDisplay.push(sesAlignmentParkingSummarise);
        }
        return _.compact(tasksToDisplay);
    }

    render() {
        const rows = [];

        for (const row of this.props.data) {
            rows.push(this.renderRow(row));
            if (row.children && row.children.length > 0) {
                const childrenExcludingUploadReports = row.children.filter(
                    (children) =>
                        ![AsyncTasksTypes.UPLOAD_REPORT, AsyncTasksTypes.SCOPE_UPLOAD_REPORT].includes(children.type)
                );
                const sortedChildren = this.sortAsyncTaskChild(childrenExcludingUploadReports);
                let index = 1;
                for (const childTask of sortedChildren) {
                    rows.push(this.renderRow(childTask, true, index));
                    if (
                        !(
                            isValidator(childTask) ||
                            isParkingSes(childTask) ||
                            isFinancialProcessor(childTask) ||
                            isParkingSesAlignment(childTask)
                        )
                    ) {
                        index++;
                    }
                }
            }
        }

        return (
            <main>
                <table className=" mt4 table-hover async-tasks-table">
                    <thead>
                        <tr>
                            <th>Task</th>
                            <th>Created</th>
                            <th>Parked</th>
                            <th>Posted</th>
                            <th>Valid from</th>
                            <th>Started</th>
                            <th>Finished</th>
                            <th>Status</th>
                            <th>Signatures</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </main>
        );
    }

    camelCaseToDash(myStr) {
        return _.kebabCase(myStr);
    }

    getRedirectUrlForRow(row: AsyncTask): string {
        let url = `/tasks/${row.type}/${row.id}`;
        if (isFinancialProcessor(row) || isParkingSes(row) || isParkingSesAlignment(row)) {
            const dashedTaskType = this.camelCaseToDash(this.getNameTagPostfix(row));
            url = `/subtasks/${row.parentId}/${dashedTaskType}`;
        }

        return url;
    }

    _onRowChosen(row: AsyncTask) {
        const redirectUrl = this.getRedirectUrlForRow(row);
        this.props.navigate(redirectUrl);
    }
}

function mapStateToProps(state: State) {
    return {
        manager: state.auth.manager,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        cancelTask: bindActionCreators(ActionCreators.cancelTask, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(AsyncTasksListView, 'AsyncTasksListView'));
