import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { displayTime } from '../utils/utils';
import { ActionCreators, EntityBalanceResponse, EntityBalanceType } from '../entityBalance/entityBalance';
import { TypedBalance } from './typedBalance';
import { EntitlementCategoriesConfig, PredefinedCurrency } from '../app/appConfig';

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

interface Props {
    beneficiaryId: string;
    lastBalance: EntityBalanceResponse;
    loadLastEntityBalance: (entityId: string, entityType: EntityBalanceType) => void;
    currency: PredefinedCurrency;
    categories: EntitlementCategoriesConfig;
}

class BeneficiariesTotals extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.loadLastEntityBalance(this.props.beneficiaryId, EntityBalanceType.Beneficiary);
    }

    sectionHeader(title: string) {
        return <h6>{title}</h6>;
    }

    calculationData() {
        const { lastBalance } = this.props;
        if (lastBalance && lastBalance.lastTransactionAt) {
            return <small>Above totals were calculated {displayTime(lastBalance.lastTransactionAt)} </small>;
        } else {
            return null;
        }
    }

    renderTotalsOfGivenCategory(category: string) {
        return (
            <div key={category}>
                {this.sectionHeader(`Beneficiary totals of type ${category}`)}
                <div className="beneficiary-totals-component">
                    <div>
                        <TypedBalance
                            beneficiaryId={this.props.beneficiaryId}
                            currency={this.props.currency}
                            desiredCategory={category}
                            entityBalance={this.props.lastBalance}
                        />
                    </div>
                    {this.calculationData()}
                </div>
            </div>
        );
    }

    render() {
        const { categories } = this.props;
        return (
            <div>
                {categories && categories.categories
                    ? categories.categories.map((category) => this.renderTotalsOfGivenCategory(category))
                    : null}
                <small>
                    Beneficiary totals are calculated automatically every hour, if beneficiary has made any transactions
                </small>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any, ownProps: OwnProps) {
    return {
        loadLastEntityBalance: bindActionCreators(ActionCreators.loadLastEntityBalance, dispatch),
    };
}

function mapStateToProps(state, ownProps: OwnProps) {
    return {
        beneficiaryId: ownProps.location.state.beneficiaryId,
        lastBalance: state.entityBalance.lastEntityBalance,
        categories: state.appConfig.entitlementsConfig,
        currency: state.appConfig.entitlementCurrencyConfig,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(BeneficiariesTotals);
