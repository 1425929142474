import * as React from 'react';

import BreadCrumbs from '../utils/BreadCrumbs';
import { DocumentListItem } from './DocumentListItem';
import FeatureButton from '../utils/FeatureButton';
import { RemoveDocumentConfirmationModal } from './RemoveDocumentConfirmationModal';
import { DocumentSection } from './DocumentSection';
import { useDocuments } from './utils/useDocuments';
import { useDragAndDrop } from '../utils/useDragAndDrop';
import { ManagerPermission } from '../permission-profiles/permission';

const DocumentsPage = () => {
    const {
        documents,
        isModalOpen,
        selectedDocument,
        hasPermission,
        handleSave,
        handleClickCapture,
        handleDelete,
        toggleIsModalOpen,
        auth,
        handleDownloadSuccess,
        reorderSections,
        saveSectionOrder,
        getDocuments,
    } = useDocuments();
    const { componentProps, elements } = useDragAndDrop(
        {
            onDrop: saveSectionOrder,
            onDragEnter(event) {
                reorderSections(elements.selected.dataset.section, event.target.dataset.section);
            },
        },
        ManagerPermission.documentManagement
    );
    if (!documents) return null;
    return (
        <>
            <main>
                <BreadCrumbs path="/documents" />
                <div className="BDS-documents-page-header">
                    <div className="header-container">
                        <h3>Documents</h3>
                    </div>
                    <FeatureButton
                        linkTo="/documents/create"
                        manager={auth.manager}
                        restrictedToFeatures={[ManagerPermission.documentManagement]}
                        title="Add Document"
                    />
                </div>
                {documents.map(([section, documents]) => (
                    <div
                        className={`BDS-document-section-wrapper ${elements.selected ? 'active' : ''}`}
                        data-section={section}
                        key={section}
                        {...componentProps}
                    >
                        <DocumentSection hasPermission={hasPermission} name={section} onSave={handleSave} />
                        {documents?.map((document) => (
                            <div key={document.id}>
                                <div className={`BDS-document-list-item-wrapper `}>
                                    <DocumentListItem
                                        document={document}
                                        getDocuments={getDocuments}
                                        hasPermission={hasPermission}
                                        onClickCapture={handleClickCapture(document)}
                                        onSuccess={handleDownloadSuccess(document.id, document.isNew)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </main>
            {isModalOpen && (
                <RemoveDocumentConfirmationModal
                    document={selectedDocument}
                    onCancel={toggleIsModalOpen}
                    onDelete={handleDelete}
                />
            )}
        </>
    );
};

export default DocumentsPage;
