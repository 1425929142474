import { useDispatch } from 'react-redux';

export function useAssignDispatch(...actions) {
    const dispatch = useDispatch();
    return actions.reduce(
        (acc, action) => ({
            ...acc,
            [action.name]: (...param) => action(...param)(dispatch),
        }),
        {}
    );
}
