import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Form, ValidationContext } from './inputs';
import { SapPoToAmountDialogType, SapPoToAmountEntry, SapPoToAmountRequestAction } from './asyncTasks';
import { currencyFormatter } from './utils';
import { EntitlementCurrencyConfig } from '../app/appConfig';

interface Props {
    onSubmit: (sapPoToAmountEntries: Array<SapPoToAmountEntry>, action: SapPoToAmountRequestAction) => void;
    onClose: () => void;
    existingSapPoToAmountEntries: Array<SapPoToAmountEntry>;
    dialogType: SapPoToAmountDialogType;
    currency: EntitlementCurrencyConfig;
}

interface SapPoToAmountEntryInput extends SapPoToAmountEntry {
    row: number;
}

interface State {
    sapPoToAmountEntries: Array<SapPoToAmountEntryInput>;
    validationContext: ValidationContext;
}

export default class SapPoToAmountEntryStaticDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sapPoToAmountEntries: [{ poNumber: '', poItem: '', poAmount: '', row: 0 }],
            validationContext: new ValidationContext(),
        };
    }
    private formatter = currencyFormatter(this.props.currency).format;

    componentDidMount(): void {
        const { existingSapPoToAmountEntries } = this.props;
        if (existingSapPoToAmountEntries && existingSapPoToAmountEntries.length > 0) {
            const existingPoRows = this.props.existingSapPoToAmountEntries.map((sapPoToAmount, index) => {
                return { ...sapPoToAmount, row: index };
            });
            this.setState({ sapPoToAmountEntries: existingPoRows });
        }
    }

    renderSapPoToAmountEntryHeaders() {
        return (
            <thead>
                <tr>
                    <th>PO Number</th>
                    <th>Line Item</th>
                    <th>Amount</th>
                    <th>Currency</th>
                </tr>
            </thead>
        );
    }

    renderStaticSapPoToAmountEntryRow(row: number) {
        const { sapPoToAmountEntries } = this.state;
        const currentRow = sapPoToAmountEntries.find((sapPoToAmountEntry) => sapPoToAmountEntry.row === row);
        return (
            <>
                <td>{currentRow.poNumber}</td>
                <td>{currentRow.poItem}</td>
                <td>{this.formatter(parseFloat(currentRow.poAmount))}</td>
                <td>JOD</td>
            </>
        );
    }

    renderStaticTableRows = (sapPoToAmountEntries: Array<SapPoToAmountEntryInput>) => {
        return sapPoToAmountEntries.map((sapPoToAmount) => {
            return (
                <tr className={'border p-3 m-3'} key={`poOverrideRow${sapPoToAmount.row}`}>
                    {this.renderStaticSapPoToAmountEntryRow(sapPoToAmount.row)}
                </tr>
            );
        });
    };

    renderStaticTable(sapPoToAmountEntries: Array<SapPoToAmountEntryInput>) {
        return (
            <div className="pre-scrollable m-3">
                <table className="wfp-table--striped mt3 table-hover">
                    {this.renderSapPoToAmountEntryHeaders()}
                    <tbody>{this.renderStaticTableRows(sapPoToAmountEntries)}</tbody>
                </table>
            </div>
        );
    }

    render() {
        const { onClose, onSubmit, dialogType } = this.props;
        const { validationContext, sapPoToAmountEntries } = this.state;

        return (
            <div>
                <div className="fade modal-backdrop in" />
                <Modal.Dialog dialogClassName="modal-large">
                    <Modal.Header>
                        <Modal.Title>SAP PO Details</Modal.Title>
                    </Modal.Header>

                    <Form
                        className="wfp-form"
                        context={validationContext}
                        onSubmit={() => onSubmit(sapPoToAmountEntries, SapPoToAmountRequestAction.post)}
                    >
                        <Modal.Body>
                            <div>{this.renderStaticTable(sapPoToAmountEntries)}</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="wfp-btn"
                                onClick={() => onClose()}
                                style={{ marginRight: 10 }}
                                type="button"
                            >
                                Close
                            </button>
                            {dialogType === SapPoToAmountDialogType.authorizable && (
                                <div>
                                    <button
                                        className="wfp-btn negative-color"
                                        onClick={() =>
                                            onSubmit(sapPoToAmountEntries, SapPoToAmountRequestAction.cancel)
                                        }
                                        style={{ marginRight: 10 }}
                                        type="button"
                                    >
                                        Reject PO Update
                                    </button>
                                    <button className="wfp-btn--primary" type="submit">
                                        Post PO Update
                                    </button>
                                </div>
                            )}
                        </Modal.Footer>
                    </Form>
                </Modal.Dialog>
            </div>
        );
    }
}
