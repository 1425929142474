enum HttpStatus {
    OK = 200,
    created = 201,
    accepted = 202,
    noContent = 204,
    badRequest = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    conflict = 409,
    gone = 410,
    unprocessable = 422,
    unsupportedMedia = 415,
    locked = 423,
    tooManyRequests = 429,
    serverError = 500,
    payloadToLarge = 413,
}

export { HttpStatus };
