import { ActivityLog, ActivityType, BalanceEntryExtended } from './activityLogs';
import { AsyncTasksTypes } from '../utils/asyncTasks';
import { ManagerNickName } from '../managers/managers';
import { VendorNickName, VendorUserName } from '../vendors/vendors';
import { ExpirationTokenTypes } from '../transactions/transactions';
import getManagerEmail from '../utils/getManagerEmail';
import { PartnerName, PartnerUserName } from '../partners/partners';
import { displayTime } from '../utils/utils';

function asyncTaskTitle(asyncTaskType: string) {
    switch (asyncTaskType) {
        case AsyncTasksTypes.BENEFICIARY_ZEROING:
            return 'Mass zeroing';
        case AsyncTasksTypes.BENEFICIARY_ZEROING_BLOCK:
            return 'Mass zeroing block';
        case AsyncTasksTypes.UNBLOCK_BENEFICIARIES:
            return 'Mass unblock';
        case AsyncTasksTypes.BLOCK_BENEFICIARIES:
            return 'Mass block';
        case AsyncTasksTypes.IMPORT_BENEFICIARIES:
            return 'Import beneficiaries';
        case AsyncTasksTypes.IMPORT_ALTERNATIVE_COLLECTORS:
            return 'Import alternative collectors';
        case AsyncTasksTypes.REMOVE_ALTERNATIVE_COLLECTORS:
            return 'Remove alternative collectors';
        case AsyncTasksTypes.IMPORT_BENEFICIARIES_MAPPING:
            return 'Import beneficiaries mapping';
    }
}
function reasonSourceMessage({ reason, source }) {
    return `reason: ${reason ? reason : ''} source: ${source ? source : ''}`;
}

function generateBalanceEntryMessage(
    balanceEntries: BalanceEntryExtended[],
    activity: string,
    expirationTokenTypes: ExpirationTokenTypes = ''
): string {
    if (balanceEntries) {
        const blockedBalance = balanceEntries.reduce((prevString, entry) => {
            return `${prevString} ${expirationTokenTypes} tokens of ${entry.category} before ${activity} is ${entry.value} ${entry.currency.name}.`;
        }, '');
        return blockedBalance;
    } else {
        return '';
    }
}

function messageForBlockingBalance(balanceEntries: BalanceEntryExtended[]): string {
    return generateBalanceEntryMessage(balanceEntries, 'block');
}

function messageForZeroingBalance(
    balanceEntries: { [tokenType: string]: BalanceEntryExtended[] },
    createdTransactionsIds: number[]
) {
    if (createdTransactionsIds) {
        const noTransactions = createdTransactionsIds.length === 0;
        if (noTransactions) {
            return 'No transaction has been created for this zeroing.';
        }
        return Object.keys(balanceEntries).map((key) => generateBalanceEntryMessage(balanceEntries[key], 'zero', key));
    }
    return '';
}

export function createActivityLogMessage(
    activityLog: ActivityLog<any>,
    managersNickName: ManagerNickName[],
    vendorsNickNames: VendorNickName[],
    vendorUsersNames: VendorUserName[],
    partnersNames: PartnerName[],
    partnerUsersNames: PartnerUserName[]
): string {
    const postedByManager = getManagerEmail(activityLog.postedByManagerId, managersNickName);
    const cancelledByManager = getManagerEmail(activityLog.cancelledByManagerId, managersNickName);

    const parkedByManager = getManagerEmail(activityLog.parkedByManagerId, managersNickName);
    const manager = getManagerEmail(activityLog.managerId, managersNickName);
    const lvlOneSignedByManager = getManagerEmail(activityLog.lvlOneSignedByManagerId, managersNickName);
    const lvlTwoSignedByManager = getManagerEmail(activityLog.lvlTwoSignedByManagerId, managersNickName);
    const vendorName = activityLog.vendorId
        ? vendorsNickNames?.find((name) => name.id === activityLog.vendorId).nickName
        : '';
    // const vendor = activityLog.vendorId
    //     ? vendorsNickNames.find((name) => name.id === activityLog.vendorId)
    //     : null;
    const managerName = getManagerEmail(activityLog.managerId, managersNickName);

    const vendorUser =
        activityLog.payload && activityLog.payload.vendorUserId
            ? vendorUsersNames?.find(
                  (vendorUser) => vendorUser.id.toString() === activityLog.payload.vendorUserId.toString()
              )
            : null;
    const vendorUserName = vendorUser ? vendorUser.firstName + ' ' + vendorUser.lastName : '';
    const log = activityLog;

    const permissionProfileId = activityLog.permissionProfileId ? activityLog.permissionProfileId : '';

    const partnerName = activityLog.partnerId
        ? partnersNames?.find((name) => name.id === activityLog.partnerId).name
        : '';

    const partnerUser =
        activityLog.payload && activityLog.payload.partnerUserId
            ? partnerUsersNames?.find(
                  (partnerUser) => partnerUser.id.toString() === activityLog.payload.partnerUserId.toString()
              )
            : null;
    const partnerUserName = partnerUser ? partnerUser.firstName + ' ' + partnerUser.lastName : '';

    const expirationDate = activityLog?.payload?.expirationDate || null;

    switch (activityLog.activity) {
        case ActivityType.beneficiaryImport: {
            if (postedByManager) {
                if (log.parentId) {
                    return `Created beneficiary id='${log.beneficiaryId}' by user ${postedByManager}.`;
                } else {
                    return `Import beneficiaries by user ${postedByManager}.`;
                }
            }
            if (parkedByManager) {
                return `${asyncTaskTitle(log.payload.asyncTaskType)} has been parked by user ${parkedByManager}`;
            }
            return '';
        }
        case ActivityType.beneficiaryUpdateByImport: {
            if (postedByManager) {
                if (log.parentId) {
                    let message = `Changed details for beneficiary id='${log.beneficiaryId}' by user ${postedByManager}.`;
                    if (log.loadAmount) {
                        message += `Load amount: ${log.loadAmount}. `;
                    }
                    if (log.updates) {
                        message += ` \nUpdates: ${Object.keys(log.updates).map((key) => {
                            return `\n ${key}: ${log.updates[key]}`;
                        })}`;
                    }
                    return message;
                } else {
                    return `Update by import beneficiaries by user ${postedByManager}.`;
                }
            }
            return '';
        }
        case ActivityType.beneficiaryZeroing:
        case ActivityType.beneficiaryZeroingBlock:
            const blockMessage = activityLog.activity === ActivityType.beneficiaryZeroingBlock ? 'and blocking' : '';
            if (postedByManager) {
                const zeroedMessage = messageForZeroingBalance(
                    log.payload.balanceBeforeZeroing,
                    log.payload.createdTransactionsIds
                );
                return `Zeroing ${blockMessage} of beneficiary id='${
                    log.beneficiaryId
                }' by user ${postedByManager} with ${reasonSourceMessage(log.payload)}. ${zeroedMessage}`;
            }
            if (parkedByManager) {
                return `Beneficiary id='${
                    log.beneficiaryId
                }' zeroing ${blockMessage} parked by user ${parkedByManager} with ${reasonSourceMessage(log.payload)}.`;
            }
            return '';
        case ActivityType.beneficiaryDetailsUpdate: {
            if (postedByManager) {
                return `Changed details for beneficiary id='${log.beneficiaryId} by user ${postedByManager}.`;
            }
            if (parkedByManager) {
                return `Parked changing details for beneficiary id='${log.beneficiaryId}' by user ${parkedByManager}.`;
            }
            return '';
        }
        case ActivityType.beneficiarySingleBlock: {
            if (postedByManager) {
                const balanceMessage = messageForBlockingBalance(log.payload.balanceBeforeBlocking);
                return `Blocked beneficiary id='${
                    log.beneficiaryId
                }' by user ${postedByManager} with ${reasonSourceMessage(log.payload)}. ${balanceMessage}`;
            }
            if (parkedByManager) {
                return `Parked blocking beneficiary id='${
                    log.beneficiaryId
                }' by user ${parkedByManager} with ${reasonSourceMessage(log.payload)}.`;
            }
            return '';
        }
        case ActivityType.beneficiarySingleUnblock: {
            if (postedByManager) {
                const balanceMessage = messageForBlockingBalance(log.payload.balanceBeforeBlocking);
                return `Unblocked beneficiary id='${
                    log.beneficiaryId
                }' by user ${postedByManager} with ${reasonSourceMessage(log.payload)}. ${balanceMessage}`;
            }
            if (parkedByManager) {
                return `Parked unblocking beneficiary id='${
                    log.beneficiaryId
                }' by user ${parkedByManager} with ${reasonSourceMessage(log.payload)}.`;
            }
            return '';
        }
        case ActivityType.beneficiaryAutoBlock: {
            const zeroedMessage = messageForZeroingBalance(
                log.payload.balanceBeforeZeroing,
                log.payload.createdTransactionsIds
            );
            return `Automatically blocked and zeroed beneficiary id='${log.beneficiaryId}' with ${reasonSourceMessage(
                log.payload
            )}. ${zeroedMessage}`;
        }
        case ActivityType.beneficiaryMassZeroing: {
            if (postedByManager) {
                const zeroedMessage = messageForZeroingBalance(
                    log.payload.balanceBeforeZeroing,
                    log.payload.createdTransactionsIds
                );
                if (log.parentId) {
                    return `Zeroing of beneficiary id='${
                        log.beneficiaryId
                    }' by user ${postedByManager} ${reasonSourceMessage(log.payload)}. ${zeroedMessage}`;
                } else {
                    return `Zeroing beneficiaries by user ${postedByManager}.`;
                }
            }
            return '';
        }
        case ActivityType.beneficiaryMassStatusChange: {
            if (postedByManager) {
                const balanceMessage = messageForBlockingBalance(log.payload.balanceBeforeBlocking);
                const targetStatus =
                    log.payload.asyncTaskType === AsyncTasksTypes.UNBLOCK_BENEFICIARIES ? 'active' : 'blocked';
                if (log.parentId) {
                    return `Changed status to ${targetStatus} for beneficiary id='${
                        log.beneficiaryId
                    }' by user ${postedByManager} ${reasonSourceMessage(log.payload)}. ${balanceMessage}`;
                } else {
                    return `Changed beneficiaries status to ${targetStatus} by user ${postedByManager}.`;
                }
            }
            return '';
        }
        case ActivityType.beneficiaryMassStatusChangeAndZeroing: {
            if (postedByManager) {
                const zeroedMessage = log.payload.createdTransactionsIds
                    ? messageForZeroingBalance(log.payload.balanceBeforeZeroing, log.payload.createdTransactionsIds)
                    : '';
                const targetStatus =
                    log.payload.asyncTaskType === AsyncTasksTypes.UNBLOCK_BENEFICIARIES ? 'active' : 'blocked';
                if (log.parentId) {
                    return `Changed status to ${targetStatus} for beneficiary id='${
                        log.beneficiaryId
                    }' by user ${postedByManager} ${reasonSourceMessage(log.payload)}. ${zeroedMessage}`;
                } else {
                    return `Changed beneficiaries status to ${targetStatus} and zeroing by user ${postedByManager}.`;
                }
            }
            return '';
        }
        case ActivityType.beneficiaryMassStatusBalanceChange: {
            if (parkedByManager) {
                return `${asyncTaskTitle(log.payload.asyncTaskType)} has been parked by user ${parkedByManager}`;
            }
            return '';
        }
        case ActivityType.beneficiaryPinReset: {
            return `Pin has been reset for beneficiary ${log.beneficiaryId} by user ${managerName}`;
        }
        case ActivityType.vendorDetails: {
            if (postedByManager) {
                return `Changed details for vendor ${vendorName} by user ${postedByManager}.`;
            }
            if (parkedByManager) {
                return `Parked changing details for vendor ${vendorName} by user ${parkedByManager}.`;
            }
            return '';
        }
        case ActivityType.vendorCreate: {
            if (parkedByManager) {
                return `User ${parkedByManager} created vendor ${vendorName}.`;
            }
            return '';
        }
        case ActivityType.vendorAuthorize: {
            if (postedByManager) {
                return `User ${postedByManager} authorized vendor ${vendorName}.`;
            }
            return '';
        }
        case ActivityType.managerDetails: {
            if (postedByManager) {
                return `Changed details for user ${managerName} by user ${postedByManager}.`;
            }
            if (parkedByManager) {
                return `Parked changing details for user ${managerName} by user ${parkedByManager}.`;
            }
            return '';
        }
        case ActivityType.managerGpgKeyUpdate: {
            return `Own GPG public key has been updated for user ${managerName}.`;
        }
        case ActivityType.paymentRequestCreate: {
            if (parkedByManager) {
                return `Payment request id="${log.payload.paymentRequestId}" has been created for vendor ${vendorName}.`;
            }
            return '';
        }
        case ActivityType.paymentRequestChangeStatus: {
            if (postedByManager) {
                return `Changed status to ${log.payload.targetStatus} for payment request id='${log.payload.paymentRequestId}' by user ${postedByManager}.`;
            }
            if (parkedByManager) {
                return `Changed status to ${log.payload.targetStatus} for payment request id='${log.payload.paymentRequestId}' by user ${parkedByManager}.`;
            }
            return '';
        }
        case ActivityType.automaticReversal: {
            const { toReverseTransactionId, status, transactionAmount, category, currency } = log.payload;
            return `Automatic reversal of transaction ${toReverseTransactionId} finished with status ${status} ${
                transactionAmount ? transactionAmount : ''
            } ${transactionAmount ? currency : ''} ${transactionAmount ? category : ''}`;
        }
        case ActivityType.alternativeCollectorsImport:
        case ActivityType.removeAlternativeCollectorsImport:
        case ActivityType.beneficiaryMappingImport: {
            if (postedByManager) {
                return `${asyncTaskTitle(log.payload.asyncTaskType)} has been posted by user ${postedByManager}`;
            }
            if (parkedByManager) {
                return `${asyncTaskTitle(log.payload.asyncTaskType)} has been parked by user ${parkedByManager}`;
            }
            return '';
        }
        case ActivityType.alternativeCollectorStatusChange: {
            if (postedByManager) {
                return `Alternative Collector ${log.payload.alternativeCollectorCaseId} status change to '${activityLog.payload.newStatus}' with reason: '${activityLog.payload.reason}' and source: '${activityLog.payload.source}'
                        has been posted by user ${postedByManager}`;
            }
            if (parkedByManager) {
                return `Alternative Collector ${log.payload.alternativeCollectorCaseId} status change to '${activityLog.payload.newStatus}' with reason: '${activityLog.payload.reason}' and source: '${activityLog.payload.source}'
                        has been parked by user ${parkedByManager}`;
            }
            return '';
        }
        case ActivityType.vendorUserLogin: {
            return `Vendor User ${vendorUserName} logged in`;
        }
        case ActivityType.vendorUserLogout: {
            return `Vendor User ${vendorUserName} logged out`;
        }
        case ActivityType.vendorUserCreate: {
            return `Vendor User ${vendorUserName} parked by ${parkedByManager}`;
        }
        case ActivityType.vendorUserAuthorize: {
            return `Vendor User ${vendorUserName} posted by ${postedByManager}`;
        }
        case ActivityType.vendorUserDetails: {
            if (postedByManager) {
                return `Posted changing details for vendor user ${vendorUserName} by user ${postedByManager}`;
            }
            if (parkedByManager) {
                return `Parked changing details for vendor user ${vendorUserName} by user ${parkedByManager}`;
            }
            return '';
        }
        case ActivityType.login:
            return `User ${manager} logged in`;
        case ActivityType.logout:
            return `User ${manager} logged out`;
        case ActivityType.passwordChange:
            return `User ${manager} changed his password`;
        case ActivityType.passwordReset:
            return `User ${manager} reset password`;
        case ActivityType.passwordBlocked:
            return `User ${manager} password blocked`;
        case ActivityType.passwordUnblocked:
            return `User ${manager} password unblocked`;
        case ActivityType.otpSecretChange:
            return `User ${manager} MFA secret change`;
        case ActivityType.otpSecretReset:
            return `User ${manager} MFA secret reset`;
        case ActivityType.entityUpdatePark:
            return `Entity Update parked by ${parkedByManager}`;
        case ActivityType.entityUpdatePost:
            return `Entity Update parked by ${postedByManager}`;
        case ActivityType.phoneNumberChange:
            return `User ${manager} changed his phone number`;
        case ActivityType.paymentRequestGeneration:
            return `Payment request for vendor ${vendorName} generated by user ${parkedByManager}`;
        case ActivityType.paymentRequestCancellation:
            return `Payment request for vendor ${vendorName} cancelled by user ${parkedByManager}`;
        case ActivityType.paymentRequestIssuePaymentPark:
            return `Payment request for vendor ${vendorName} issue payment parked by user ${parkedByManager}`;
        case ActivityType.paymentRequestIssuePaymentPost:
            return `Payment request for vendor ${vendorName} issue payment posted by user ${parkedByManager}`;
        case ActivityType.paymentRequestPaid:
            if (postedByManager) {
                return `Payment request for vendor ${vendorName} posted as paid by user ${postedByManager}`;
            }
            if (parkedByManager) {
                return `Payment request for vendor ${vendorName} parked as paid by user ${parkedByManager}`;
            }
            return '';
        case ActivityType.scopeAnnouncement:
            if (lvlOneSignedByManager) {
                return `Scope Announcement Lvl One Signed By ${lvlOneSignedByManager}`;
            }
            if (lvlTwoSignedByManager) {
                return `Scope Announcement Lvl Two Signed By ${lvlTwoSignedByManager}`;
            }
            return '';
        case ActivityType.cancelVendor:
            return `Vendor ${vendorName} cancelled by ${postedByManager}`;
        case ActivityType.cancelManager:
            return `Manager ${managerName} cancelled by ${postedByManager}`;
        case ActivityType.transactionAdjustment:
            if (cancelledByManager) {
                const newValue = log.payload.newAmount;
                const currentValue = log.payload.currentAmount;
                const original = log.payload.originalTransactionId;
                return `Reject adjustment  original transaction id="${original}" for beneficiary id='${
                    log.beneficiaryId
                }' and vendor ${vendorName}  by user ${parkedByManager} with ${reasonSourceMessage(log.payload)}.
                Current Value: ${currentValue}, New value: ${newValue}`;
            }
            if (postedByManager) {
                const currentValue = log.payload.newAmount;
                const previousValue = log.payload.currentAmount;
                const original = log.payload.originalTransactionId;
                return `Adjustment original transaction id="${original}" for beneficiary id='${
                    log.beneficiaryId
                }' and vendor ${vendorName}  by user ${postedByManager}
                 with ${reasonSourceMessage(log.payload)}.
                 Previous Value: ${previousValue}, Current Value: ${currentValue}`;
            }
            if (parkedByManager) {
                const newValue = log.payload.newAmount;
                const currentValue = log.payload.currentAmount;
                const original = log.payload.originalTransactionId;
                return `Park adjustment  for original transaction id="${original}" for beneficiary id='${
                    log.beneficiaryId
                }' and vendor ${vendorName}  by user ${parkedByManager} with ${reasonSourceMessage(log.payload)}.
                Current Value: ${currentValue}, New Value: ${newValue}`;
            }
            return '';
        case ActivityType.vendorUserPasswordReset:
            return `Password reset of vendor user ${vendorUserName}`;
        case ActivityType.permissionProfileCreate:
            return `Permission Profile ${permissionProfileId} parked by ${parkedByManager}`;
        case ActivityType.permissionProfileAuthorize:
            return `Permission Profile ${permissionProfileId} posted by ${postedByManager}`;
        case ActivityType.permissionProfileDetails:
            if (postedByManager) {
                return `Permission Profile ${permissionProfileId} updated by ${postedByManager}`;
            }
            if (parkedByManager) {
                return `Permission Profile ${permissionProfileId} updated by ${parkedByManager}`;
            }
            return `Permission Profile ${permissionProfileId} was updated.`;
        case ActivityType.cancelPartner:
            return `Partner ${partnerName} cancelled by ${postedByManager}`;
        case ActivityType.partnerDetails: {
            if (postedByManager) {
                return `Changed details for partner ${partnerName} by user ${postedByManager}.`;
            }
            if (parkedByManager) {
                return `Parked changing details for partner ${partnerName} by user ${parkedByManager}.`;
            }
            return '';
        }
        case ActivityType.partnerCreate: {
            if (parkedByManager) {
                return `User ${parkedByManager} created partner ${partnerName}.`;
            }
            return '';
        }
        case ActivityType.partnerAuthorize: {
            if (postedByManager) {
                return `User ${postedByManager} authorized partner ${partnerName}.`;
            }
            return '';
        }
        case ActivityType.partnerUserLogin: {
            return `Partner User ${partnerUserName} logged in`;
        }
        case ActivityType.partnerUserLogout: {
            return `Partner User ${partnerUserName} logged out`;
        }
        case ActivityType.partnerUserCreate: {
            return `Partner User ${partnerUserName} parked by ${parkedByManager}`;
        }
        case ActivityType.partnerUserAuthorize: {
            return `Partner User ${partnerUserName} posted by ${postedByManager}`;
        }
        case ActivityType.partnerUserDetails: {
            if (postedByManager) {
                return `Posted changing details for partner user ${partnerUserName} by user ${postedByManager}`;
            }
            if (parkedByManager) {
                return `Parked changing details for partner user ${partnerUserName} by user ${parkedByManager}`;
            }
            return '';
        }
        case ActivityType.partnerUserPasswordReset:
            return `Password reset of partner user ${partnerUserName}`;
        case ActivityType.partnerUserExpired:
            return `Partner user expired on ${displayTime(expirationDate)}`;
        case ActivityType.managerExpired:
            return `Manager expired on ${displayTime(expirationDate)}`;
    }
}
