import * as React from 'react';
import { FormEvent } from 'react';
import * as _ from 'lodash';

import DateFilterField from './filterFields/DateFilterField';
import TextFilterField from './filterFields/TextFilterField';
import SelectFilterField from './filterFields/SelectFilterField';
import { Filter, FilterTypes, SelectFilter, TextFilter, DateSelectFilter, NumberFilter } from './FilterTypes';
import MultiSelectFilterField from './filterFields/MultiSelectFilterField';
import NumberFilterField from './filterFields/NumberFilterField';

interface Props {
    onFilterChanged?: (filter) => void;
    onApplyFilters?: (filters) => void;

    defaultFilters: Filter[];
    filters: Filter[];

    withApply: boolean;
    disableRemove?: boolean;
    withIsSelected: boolean;

    withTimeRangeLimit?: boolean;
    extraButtons?: {
        className: string;
        onClick: any;
        name: string;
    }[];
}

interface LocalState {
    selectedFilters: Filter[];
}

export default class FiltersComponent extends React.Component<Props, LocalState> {
    _callOnFilterChanged: (filter) => void;

    constructor(props) {
        super(props);
        this.state = {
            selectedFilters: _.cloneDeep(this.props.filters),
        };

        const onFilterChanged = this.props.onFilterChanged || _.noop;

        this._callOnFilterChanged = this.props.withApply
            ? onFilterChanged
            : _.debounce((changedFilter) => onFilterChanged(changedFilter), 300);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.filters !== this.props.filters) {
            this.setState({ selectedFilters: _.cloneDeep(nextProps.filters) });
        }
    }

    private filterChanged = (filter: Filter, event: FormEvent<HTMLInputElement>) => {
        const target = event.currentTarget;
        if (target !== undefined) {
            const newValue = target.value;
            this.setState((prevState) => {
                const isCheckbox = target.type === 'checkbox';
                const selectedFilters = prevState.selectedFilters;
                let filterToChange: Filter = selectedFilters.find((f) => f.name === filter.name);

                const value = isCheckbox ? target.checked : target.value;

                if (filter.type === FilterTypes.date) {
                    filterToChange = Object.assign({}, filter);
                } else if (target.name === filter.name + 'Selected') {
                    filterToChange.isSelected = !!value;
                } else {
                    filterToChange.isSelected = true;
                    filterToChange.value = newValue;
                }

                this._callOnFilterChanged(filterToChange);
                return { selectedFilters };
            });
        } else {
            const newValue = event;
            this.setState((prevState) => {
                const selectedFilters = prevState.selectedFilters;
                const filterToChange: Filter = selectedFilters.find((f) => f.name === filter.name);
                filterToChange.isSelected = true;
                filterToChange.value = newValue;
                this._callOnFilterChanged(filterToChange);
                return { selectedFilters };
            });
        }
    };

    private _apply = (event: FormEvent<HTMLFormElement>) => {
        this.props.onApplyFilters(this.state.selectedFilters);
        event.preventDefault();
    };

    private _reset = (event: FormEvent<HTMLFormElement>) => {
        this.setState({ selectedFilters: _.cloneDeep(this.props.defaultFilters) }, () =>
            this.props.onApplyFilters(this.state.selectedFilters)
        );
        event.preventDefault();
    };

    private filterComponent(filter: Filter) {
        switch (filter.type) {
            case FilterTypes.text:
                return (
                    <TextFilterField
                        filter={filter as TextFilter}
                        filterChanged={this.filterChanged}
                        key={filter.name}
                        withIsSelected={this.props.withIsSelected}
                    />
                );
            case FilterTypes.number:
                return (
                    <NumberFilterField
                        filter={filter as NumberFilter}
                        filterChanged={this.filterChanged}
                        key={filter.name}
                        withIsSelected={this.props.withIsSelected}
                    />
                );
            case FilterTypes.select:
                return (
                    <SelectFilterField
                        filter={filter as SelectFilter}
                        filterChanged={this.filterChanged}
                        key={filter.name}
                        withIsSelected={this.props.withIsSelected}
                    />
                );
            case FilterTypes.multiselect:
                return (
                    <MultiSelectFilterField
                        filter={filter as SelectFilter}
                        filterChanged={this.filterChanged}
                        key={filter.name}
                        withIsSelected={this.props.withIsSelected}
                    />
                );
            case FilterTypes.date:
                return (
                    <DateFilterField
                        endDate={filter.dateRange ? filter.dateRange.endDate : null}
                        filter={filter as DateSelectFilter}
                        filterChanged={this.filterChanged}
                        key={filter.name}
                        maxDate={filter.dateLimit ? filter.dateLimit.maxDate : null}
                        minDate={filter.dateLimit ? filter.dateLimit.minDate : null}
                        startDate={filter.dateRange ? filter.dateRange.startDate : null}
                        withIsSelected={this.props.withIsSelected}
                        withTimeRangeLimit={this.props.withTimeRangeLimit}
                    />
                );
        }
    }

    render() {
        return (
            <form className="wfp-form--stacked filters-form" onReset={this._reset} onSubmit={(e) => e.preventDefault()}>
                <div className="row col-sm-12">
                    {this.state.selectedFilters.map((filter, index) => (
                        <div className="col-sm-6" key={index}>
                            {this.filterComponent(filter)}
                        </div>
                    ))}
                </div>
                <br />
                {this.props.withApply && (
                    <div className="wfp-form--actions text-center">
                        <button className="wfp-btn--primary" onClick={this._apply.bind(this)} type="submit">
                            Apply Filter(s)
                        </button>
                        {!this.props.disableRemove && (
                            <button className="wfp-btn ml3" type="reset">
                                Remove Filter(s)
                            </button>
                        )}
                        {this.props.extraButtons &&
                            this.props.extraButtons.map((buttonProperties, id) => (
                                <button
                                    className={buttonProperties.className}
                                    key={`additional-button-${id}`}
                                    onClick={buttonProperties.onClick(this.state.selectedFilters)}
                                    type="button"
                                >
                                    {buttonProperties.name}
                                </button>
                            ))}
                    </div>
                )}
            </form>
        );
    }
}
