import { connect } from 'react-redux';
import AppState from '../app/store/state';
import { EditBeneficiaryCreators } from './edit/editBeneficiaryActionCreator';
import { ActivityLogsList } from '../activityLogs/ActivityLogsList';
import { bindActionCreators } from 'redux';
import { ActionCreators as ManagerActionCreators } from '../managers/managers';
import { ActionCreators as VendorActionCreators } from '../vendors/vendors';
import { ManagerPermission } from '../permission-profiles/permission';
import { ActionCreators as PartnerActionCreators } from '../partners/partnersActions';

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

function mapDispatchToProps(dispatch: any, ownProps: OwnProps) {
    const { beneficiaryId } = ownProps.location.state;
    return {
        loadActivityLogs: (page, limit) =>
            dispatch(EditBeneficiaryCreators.loadBeneficiaryActivityLogs(beneficiaryId, page, limit)),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        loadVendorsNickNames: bindActionCreators(VendorActionCreators.loadVendorsNickNames, dispatch),
        loadVendorUsersNames: bindActionCreators(VendorActionCreators.loadVendorUsersNames, dispatch),
        loadPartnersNames: bindActionCreators(PartnerActionCreators.loadPartnersNames, dispatch),
        loadPartnerUserNames: bindActionCreators(PartnerActionCreators.loadPartnerUsersName, dispatch),
        isManager: false,
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    const { beneficiaryId } = ownProps.location.state;

    return {
        activityLogs: state.editBeneficiary.activityLogs,
        managersNickNames: state.managers.managerNickNameList,
        vendorsNickNames: state.vendors.nickNameList,
        vendorUsersNames: state.vendors.usersNames,
        url: '/beneficiaries/export-activity-log',
        entityId: beneficiaryId,
        auth: state.auth,
        downloadRestriction: [ManagerPermission.operationActivityDownload],
        partnersNickNames: state.partners.nameList,
        partnerUsersNickNames: state.partners.partnerUsersNamesList,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ActivityLogsList);
