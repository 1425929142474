import * as React from 'react';
import { Partner } from './partners';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { useCallback } from 'react';
import { stopPropagation } from '../utils/events';
import { capitalizeFirstLetter, supStyle } from '../utils/utils';
import { ManagerPermission } from '../permission-profiles/permission';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

interface Props {
    partner: Partner;
    auth: AuthState;
    handleCancel(partner: Partner): void;
    authorize(partnerId: string): void;
}

export function PartnerListRow({ partner, auth, handleCancel, authorize }: Props) {
    const navigate = useNavigate();
    const notificationNumber = partner.requestCount;
    const handlePartnerChosen = useCallback(() => {
        navigate('/partners/' + partner.id);
    }, [partner]);
    const trClassName = `cursor-pointer ${partner.isPasswordBlocked ? 'attention-color' : ''}`;
    return (
        <tr className={trClassName} key={partner.id} onClick={handlePartnerChosen}>
            <td>{partner.name}</td>
            <td>{partner.sublocation}</td>
            <td>{partner.city}</td>
            <td>{partner.country}</td>
            <td>
                <Status notificationNumber={notificationNumber} partner={partner} />
            </td>
            <td>
                <Cancel auth={auth} handleCancel={handleCancel} partner={partner} />
                <Post auth={auth} authorize={authorize} partner={partner} />
            </td>
        </tr>
    );
}

interface CancelProps {
    partner: Partner;
    auth: AuthState;
    handleCancel: (string) => void;
}

function Cancel({ partner, auth, handleCancel }: CancelProps) {
    const thisManagerParked = auth?.manager ? Number(partner.createdByManager.id) === Number(auth.manager.id) : true;
    const hasPermissions = hasFeatureAccess(auth, ManagerPermission.partnerPost);
    const cancelPartner = (partnerId) => () => handleCancel(partnerId);
    if (partner.status === 'parked' && (hasPermissions || thisManagerParked)) {
        return (
            <a className="mr-3" onClick={stopPropagation(cancelPartner(partner.id))}>
                Cancel
            </a>
        );
    }
    return null;
}

interface StatusProps {
    partner: Partner;
    notificationNumber: string;
}

function Status({ partner, notificationNumber }: StatusProps) {
    return (
        <>
            {partner.authorizedByManager ? (
                <div>
                    {capitalizeFirstLetter(partner.status)}{' '}
                    {parseInt(notificationNumber) > 0 && <sup style={supStyle}>{notificationNumber}</sup>}
                </div>
            ) : partner.cancelledByManager ? (
                'Cancelled'
            ) : (
                'Parked'
            )}
        </>
    );
}

interface PostProps {
    partner: Partner;
    auth: AuthState;
    authorize: (string) => void;
}

export function Post({ partner, auth, authorize }: PostProps) {
    const thisManagerParked = auth?.manager ? Number(partner.createdByManager.id) === Number(auth.manager.id) : true;
    const hasPermissions = hasFeatureAccess(auth, ManagerPermission.partnerPost);
    if (!thisManagerParked && hasPermissions && partner.status === 'parked') {
        return <a onClick={stopPropagation(() => authorize(partner.id))}>Post</a>;
    }
    return null;
}
