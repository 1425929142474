import * as React from 'react';
import Switch from 'rc-switch';
import * as clonedeep from 'lodash.clonedeep';
import { ExternalValidationError, Form, Input, ValidationContext } from '../utils/inputs';
import { displayTime } from '../utils/utils';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { ActionCreators, VendorStatuses, VendorUser } from './vendors';
import { connect } from 'react-redux';
import State from '../app/store/state';
import { bindActionCreators } from 'redux';
import { EntityType } from '../authorization/taskAuthorizations';
import { ManagerPermission } from '../permission-profiles/permission';

interface OwnProps {
    params: { vendorId: string; userId: string };
}

interface Props {
    auth: AuthState;
    vendorUserId: string;
    vendorUser: VendorUser;
    validationError?: ExternalValidationError;
    resetPasswordRequest: (vendorUser: VendorUser) => void;
    saveVendorUser: (vendorUser: VendorUser) => void;
    authorizeVendorUser: (vendorUserId: string) => void;
    getVendorUser: (id) => void;
    createVendorUser: (vendorUser: VendorUser) => void;
    vendorId: string;
}

interface VendorUserState {
    vendorUser: VendorUser;
    editChecked: boolean;
    hasPendingDetailsUpdate: boolean;
}

class VendorUserDetails extends React.Component<Props, VendorUserState> {
    _validationContext = new ValidationContext();

    constructor(props: Props) {
        super(props);
        this.state = {
            vendorUser: new VendorUser(),
            editChecked: false,
            hasPendingDetailsUpdate: false,
        };
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        if (props.vendorUser.id) {
            this.setState({ vendorUser: clonedeep(props.vendorUser) });
        }
        if (props.validationError) {
            this._validationContext.pushError(props.validationError);
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.vendorUser.id.length > 0) {
            this.setState({ vendorUser: clonedeep(this.props.vendorUser) });
        } else if (this.props.vendorUserId) {
            this.props.getVendorUser(this.props.vendorUserId);
        }
    }

    renderInputField(options: {
        readonly: boolean;
        value: string;
        name: string;
        required: boolean;
        type: string;
        placeholder?: string;
        isFocused?: boolean;
    }) {
        return (
            <div className="col-sm-8">
                {options.readonly && <label className="normal">{options.value}</label>}
                {!options.readonly && (
                    <Input
                        className="form-control"
                        context={this._validationContext}
                        isFocused={options.isFocused}
                        name={options.name}
                        onChange={this._handleInputChange.bind(this)}
                        placeholder={options.placeholder || ''}
                        required={options.required}
                        type={options.type}
                        value={options.value}
                    />
                )}
            </div>
        );
    }

    toggleEdit(editChecked) {
        const { vendorUser } = this.state;
        let hasPendingDetailsUpdate = false;
        if (vendorUser && vendorUser.pendingEntityUpdates) {
            const hasBeneficiaryDetailsEntity = vendorUser.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.vendorUserDetails
            );
            hasPendingDetailsUpdate = hasBeneficiaryDetailsEntity.length !== 0;
        }
        if (hasPendingDetailsUpdate) {
            this.setState({ hasPendingDetailsUpdate: hasPendingDetailsUpdate });
        } else if (vendorUser.authorizedAt) {
            this.setState({ editChecked: editChecked });
        }
    }

    isCreateVendorUserPage() {
        return this.props.vendorUserId === 'add-user' || !this.props.vendorUserId;
    }

    renderSwitch() {
        const parkEnabled = hasFeatureAccess(this.props.auth, ManagerPermission.vendorsPark);
        const isEditSwitchVisible = parkEnabled && !this.isCreateVendorUserPage();
        if (isEditSwitchVisible) {
            return (
                <div className={'row'}>
                    <label className="col-sm-4 ta-right">Toggle edit</label>
                    <div className="col-sm-8 pv1">
                        <Switch checked={this.state.editChecked} onClick={this.toggleEdit.bind(this)} />
                    </div>
                </div>
            );
        }
    }

    render() {
        const isExistingUser =
            this.state.vendorUser.id !== null &&
            this.state.vendorUser.id !== undefined &&
            this.state.vendorUser.id !== '';
        const saveTitle = isExistingUser ? 'Park changes' : 'Park new user';
        const isActive = this.state.vendorUser.status === 'active';

        return (
            <div>
                {!this.isCreateVendorUserPage() && this.renderSwitch()}
                <Form className="wfp-form" context={this._validationContext} onSubmit={this._saveChanges.bind(this)}>
                    {isExistingUser && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Vendor User ID</label>
                            {this.renderInputField({
                                readonly: true,
                                value: this.state.vendorUser.id,
                                name: 'id',
                                required: true,
                                type: 'text',
                            })}
                        </div>
                    )}
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">First name</label>
                        {this.renderInputField({
                            readonly: !this.state.editChecked && !this.isCreateVendorUserPage(),
                            value: this.state.vendorUser.firstName,
                            name: 'firstName',
                            required: true,
                            type: 'text',
                            isFocused: true,
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Last name</label>
                        {this.renderInputField({
                            readonly: !this.state.editChecked && !this.isCreateVendorUserPage(),
                            value: this.state.vendorUser.lastName,
                            name: 'lastName',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Email</label>
                        {this.renderInputField({
                            readonly: !this.state.editChecked && !this.isCreateVendorUserPage(),
                            value: this.state.vendorUser.email,
                            name: 'email',
                            required: true,
                            type: 'email',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Phone number</label>
                        {this.renderInputField({
                            readonly: !this.state.editChecked && !this.isCreateVendorUserPage(),
                            value: this.state.vendorUser.phoneNumber,
                            name: 'phoneNumber',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    {!this.isCreateVendorUserPage() &&
                        this.props.vendorUser.authorizedAt &&
                        isActive &&
                        hasFeatureAccess(this.props.auth, ManagerPermission.vendorPasswordReset) && (
                            <div className="wfp-form--group row">
                                <label className="col-sm-4 ta-right">Password</label>
                                <div className="col-sm-8 pv1">
                                    <a onClick={() => this.props.resetPasswordRequest(this.props.vendorUser)}>
                                        Reset Password
                                    </a>
                                </div>
                            </div>
                        )}
                    {!this.isCreateVendorUserPage() && this.props.vendorUser.authorizedAt && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Password Blocked</label>
                            {this.renderInputField({
                                readonly: true,
                                value: this.state.vendorUser.isPasswordBlocked ? 'Yes' : 'No',
                                name: 'passwordBlocked',
                                required: true,
                                type: 'text',
                            })}
                        </div>
                    )}
                    {/*{this.props.vendorUser && this.props.vendorUser.authorizedAt && <div className="wfp-form--group row">*/}
                    {/*<label className="col-sm-4 ta-right">Password blocked</label>*/}
                    {/*{this.renderInputField({ readonly: true, value: this.props.vendorUser.isPasswordBlocked? "Yes" : "No", name: "passwordBlocked", required: true, type: "text" })}*/}
                    {/*</div>}*/}
                    {!this.isCreateVendorUserPage() && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Active</label>
                            <div className="col-sm-8 pv1">
                                {
                                    <Switch
                                        checked={this.state.vendorUser.status === VendorStatuses.active}
                                        disabled={!this.state.editChecked}
                                        onChange={this._handleActiveChange.bind(this)}
                                    />
                                }
                            </div>
                        </div>
                    )}
                    {!this.isCreateVendorUserPage() && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Status</label>
                            <div className="col-sm-8">
                                <label className="normal">
                                    Parked at {displayTime(this.state.vendorUser.createdAt)}
                                    <div>
                                        by{' '}
                                        {this.state.vendorUser.createdByManager
                                            ? this.state.vendorUser.createdByManager.name
                                            : new Date(this.state.vendorUser.createdAt) <= new Date(2018, 4, 18)
                                            ? 'System'
                                            : 'ERROR'}
                                    </div>
                                    {/*18-04-2018 date of last parked manager on stage before loggin parkedByManagerId -> after that date no name of parker would be error */}
                                </label>

                                {this.state.vendorUser.authorizedAt ? (
                                    <label className="normal">
                                        Posted at {displayTime(this.state.vendorUser.authorizedAt)}
                                        <div>
                                            by{' '}
                                            {this.state.vendorUser.authorizedByManager
                                                ? this.state.vendorUser.authorizedByManager.name
                                                : 'System'}
                                        </div>
                                    </label>
                                ) : this.canPost(this.state.vendorUser) ? (
                                    <span>
                                        <a onClick={this._authorizeVendorUser.bind(this)}>Post</a>
                                    </span>
                                ) : null}
                                {/*{this.props.thisManagerParked && !this.state.authorizedAt &&*/}
                                {/*<label> <span style={{ color: "red" }}>*</span>You cannot post it, because you parked it.</label>*/}
                                {/*}*/}
                            </div>
                        </div>
                    )}
                    {(this.state.editChecked || this.isCreateVendorUserPage()) &&
                        hasFeatureAccess(this.props.auth, ManagerPermission.vendorUserPark) && (
                            <div className="wfp-form--actions text-center">
                                <div className="col-sm-8 col-sm-offset-4">
                                    <button
                                        className="wfp-btn--primary"
                                        disabled={
                                            !hasFeatureAccess(this.props.auth, ManagerPermission.vendorUserPark) ||
                                            (!this.state.editChecked && !this.isCreateVendorUserPage())
                                        }
                                        type="submit"
                                    >
                                        {saveTitle}
                                    </button>
                                </div>
                            </div>
                        )}
                </Form>
            </div>
        );
    }

    canPost(vendorUser) {
        const hasPermission = hasFeatureAccess(this.props.auth, ManagerPermission.vendorUserPost);
        const thisManagerParked = vendorUser.createdByManager
            ? this.props.auth.manager && vendorUser.createdByManager.id === this.props.auth.manager.id
            : false;

        return hasPermission && !thisManagerParked;
    }

    private _handleInputChange({ target }) {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const newVendorUser = Object.assign(this.state.vendorUser, {
            [target.name]: value,
        });
        this.setState({ vendorUser: newVendorUser });
    }

    private _saveChanges(event) {
        if (!this.props.vendorUserId) {
            const vendorUser = clonedeep(this.state.vendorUser);
            vendorUser.vendorId = this.props.vendorId;
            this.props.createVendorUser(vendorUser);
        } else {
            this.props.saveVendorUser(this.state.vendorUser);
        }
        event.preventDefault();
    }

    private _authorizeVendorUser(event) {
        this.props.authorizeVendorUser(this.state.vendorUser.id);
        event.preventDefault();
    }

    _handleActiveChange(checked) {
        const vendorUser = Object.assign(this.state.vendorUser, {
            status: checked ? VendorStatuses.active : VendorStatuses.blocked,
        });

        this.setState({ vendorUser });
    }
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        auth: state.auth,
        vendorId: ownProps.params.vendorId,
        validationError: state.managers.validationError,
        vendorUser: state.vendors.vendorUser,
        vendorUserId: ownProps.params.userId,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        resetPasswordRequest: bindActionCreators(ActionCreators.requestPasswordReset, dispatch),
        createVendorUser: bindActionCreators(ActionCreators.createVendorUser, dispatch),
        authorizeVendorUser: bindActionCreators(ActionCreators.authorizeVendorUser, dispatch),
        getVendorUser: bindActionCreators(ActionCreators.getVendorUser, dispatch),
        saveVendorUser: bindActionCreators(ActionCreators.saveVendorUser, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorUserDetails as any);
