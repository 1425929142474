import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { loadPaymentPeriods } from '../../apiClient';
import { useDispatch } from 'react-redux';
import { ActionCreators } from '../paymentRequests';
export const limit = 10;
export const paymentsLimit = 25;

export function usePaymentPeriods(activeCategory: string) {
    const runOnce = useRef(false);
    const [periods, setPeriods] = useState([]);
    const [paging, setPaging] = useState({ total: 0, offset: 0 });
    const [page, setPage] = useState(1);
    const [activePeriod, setActivePeriod] = useState('');
    const dispatch = useDispatch();
    const handleSelect = useCallback(
        (periodLabel: string) => {
            const period = periods.find(({ label }) => periodLabel === label);
            ActionCreators.loadPaymentRequests(
                1,
                paymentsLimit,
                new Date(period.periodEnd),
                activeCategory
            )(dispatch).catch((v) => v);
            setActivePeriod(period.label);
        },
        [activeCategory, periods]
    );

    useEffect(() => {
        (async () => {
            const res = (await loadPaymentPeriods(page, limit)) as any;
            setPeriods(res.data);
            setPaging(res.paging);
        })().catch((v) => v);
    }, [page]);

    useEffect(() => {
        if (!activePeriod && periods.length) {
            setActivePeriod(periods[0].label);
        }
    }, [periods]);

    useEffect(() => {
        if (periods.length && !runOnce.current) {
            runOnce.current = true;
            ActionCreators.loadPaymentRequests(
                1,
                paymentsLimit,
                new Date(periods[0].periodEnd),
                activeCategory
            )(dispatch).catch((v) => v);
        }
    }, [periods]);

    const handlePaginate = useCallback(
        (type: string) => () => {
            switch (type) {
                case 'previous':
                    setPage((state) => (state > 1 ? state - 1 : state));
                    break;
                case 'next':
                    setPage((state) => state + 1);
                    break;
                default:
                    break;
            }
        },
        []
    );

    return { activePeriod, periods, paging, page, handlePaginate, handleSelect };
}

export function getStyles(predicate: boolean): CSSProperties {
    return {
        visibility: predicate ? 'visible' : 'hidden',
        pointerEvents: predicate ? 'all' : 'none',
        userSelect: 'none',
        textAlign: 'center',
    };
}
