import { ActionCreators } from '../../login/auth';
import { errorAction } from '../notifications';
import { checkTokenExpiration } from './checkTokenExpiration';
import ManagerState from '../../app/store/state';

const SessionExpirationMessage = 'Your session has expired.';
let managerScheduledTiemout;
const ExpirationCheckTimeInterval = 60 * 1000;

function getTokenFromState(state: ManagerState) {
    return state.auth.apiToken;
}
function onTokenExpiration(dispatch) {
    dispatch(ActionCreators.logout());
    dispatch(errorAction(SessionExpirationMessage));
}
function scheduleManagerTokenExpirationChecks() {
    return (dispatch) => {
        if (managerScheduledTiemout) {
            clearInterval(managerScheduledTiemout);
        }

        dispatch(checkTokenExpiration(getTokenFromState, onTokenExpiration));

        managerScheduledTiemout = setInterval(() => {
            dispatch(checkTokenExpiration(getTokenFromState, onTokenExpiration));
        }, ExpirationCheckTimeInterval);
    };
}
export default scheduleManagerTokenExpirationChecks;
