import * as React from 'react';
import { MobilizationStatistics } from './MobilizationStatistics';
import {
    getAppConfig,
    getMobilizationStatistics,
    getTodayMobilizationStatistics,
    getMobilizationStatisticsSyncDate,
    loadVendorsNickNames,
    loadBranchCodes,
} from '../../apiClient';
import { useOptionsForSelectFields } from './helpers';

export function MobilizationStatisticsPage() {
    const options = useOptionsForSelectFields({
        loadVendors: loadVendorsNickNames,
        loadAppConfig: getAppConfig,
        loadBranchCodes: loadBranchCodes,
    });
    return (
        <MobilizationStatistics
            fetchRange={getMobilizationStatistics}
            fetchSyncDate={getMobilizationStatisticsSyncDate}
            fetchToday={getTodayMobilizationStatistics}
            {...options}
        />
    );
}
