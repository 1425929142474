import * as React from 'react';

import { compose, displayTime, separateCamelCase, wfpFormat } from '../utils/utils';
import PagingComponent, { PagedState } from '../utils/paging';
import { ActivityLog, ActivityType, ActivityTypes, unRedirectableLogs } from '../activityLogs/activityLogs';
import { VendorNickName } from '../vendors/vendors';
import { ManagerNickName } from '../managers/managers';
import { PartnerName } from '../partners/partners';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';
import { withNavigate } from '@wfp-common/hooks/withNavigate';

interface Props {
    activityLogs: PagedState<ActivityLog<any>>;
    onChangePageRequested: (page: number) => void;
    withStatus?: boolean;
    vendorsNickNames: VendorNickName[];
    managersNickNames: ManagerNickName[];
    partnersNames: PartnerName[];
    navigate: NavigateHook;
}

class OversightsTable extends React.Component<Props, {}> {
    constructor(props) {
        super(props);
    }

    private createManagerFullName(managerId: number, managersNickName: ManagerNickName[]) {
        const manager = managerId ? managersNickName.find((name) => name.id === managerId.toString()) : null;
        if (manager) {
            return manager.firstName + ' ' + manager.lastName;
        }
        return managerId;
    }

    private tableHeader() {
        return (
            <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Type</th>
                    <th>User</th>
                    <th>Beneficiary ID</th>
                    <th>Vendor</th>
                    <th>Partner</th>
                    <th>Parked by</th>
                    <th>Posted by</th>
                    <th>Lvl1 Signed by</th>
                    <th>Lvl2 Signed by</th>
                    <th>Pre Cancelled by</th>
                    <th>Cancelled by</th>
                </tr>
            </thead>
        );
    }

    private onActivityLogChosen = (activityLog: ActivityLog<any>) => {
        if (!unRedirectableLogs.includes(activityLog.activity as ActivityType)) {
            this.props.navigate(`/activity-logs/${activityLog.id}`);
        }
    };

    private getStyle(type: string) {
        let style = 'shadowed-container';
        if (!unRedirectableLogs.includes(type as ActivityType)) {
            style += ' cursor-pointer';
        }
        return style;
    }

    tableBody() {
        const { activityLogs, vendorsNickNames, partnersNames, managersNickNames } = this.props;
        return (
            <tbody className="transactions">
                {activityLogs.items &&
                    activityLogs.items.map((activityLog) => {
                        const affectedManager = this.createManagerFullName(activityLog.managerId, managersNickNames);
                        const postedByManager = this.createManagerFullName(
                            activityLog.postedByManagerId,
                            managersNickNames
                        );
                        const parkedByManager = this.createManagerFullName(
                            activityLog.parkedByManagerId,
                            managersNickNames
                        );
                        const lvlOneSignedByManager = this.createManagerFullName(
                            activityLog.lvlOneSignedByManagerId,
                            managersNickNames
                        );
                        const lvlTwoSignedByManager = this.createManagerFullName(
                            activityLog.lvlTwoSignedByManagerId,
                            managersNickNames
                        );
                        const preCancelledByManager = this.createManagerFullName(
                            activityLog.preCancelledByManagerId,
                            managersNickNames
                        );
                        const cancelledByManager = this.createManagerFullName(
                            activityLog.cancelledByManagerId,
                            managersNickNames
                        );

                        const vendor = activityLog.vendorId
                            ? vendorsNickNames.find((name) => name.id === activityLog.vendorId)
                            : null;
                        const vendorName = vendor ? vendor.nickName : '';
                        // const managerName = this.createManagerFullName(activityLog.managerId, managersNickNames)

                        const partner = activityLog.partnerId
                            ? partnersNames.find((name) => name.id === activityLog.partnerId)
                            : null;
                        const partnerName = partner ? partner.name : '';

                        return (
                            <tr
                                className={this.getStyle(activityLog.activity)}
                                key={activityLog.id}
                                onClick={() => this.onActivityLogChosen(activityLog)}
                            >
                                <td>{displayTime(new Date(activityLog.created_at))}</td>
                                <td>
                                    {compose(
                                        wfpFormat,
                                        separateCamelCase,
                                        ActivityTypes.displayName
                                    )(activityLog.activity)}
                                </td>
                                <td>{affectedManager}</td>

                                <td>{activityLog.beneficiaryId}</td>
                                <td>{vendorName}</td>
                                <td>{partnerName}</td>
                                {activityLog.parkedByManagerId ? (
                                    <td>{parkedByManager}</td>
                                ) : (
                                    <td>{parkedByManager}</td>
                                )}
                                {activityLog.postedByManagerId ? (
                                    <td>{postedByManager}</td>
                                ) : (
                                    <td>{postedByManager}</td>
                                )}
                                {activityLog.lvlOneSignedByManagerId ? (
                                    <td>{lvlOneSignedByManager}</td>
                                ) : (
                                    <td>{lvlOneSignedByManager}</td>
                                )}
                                {activityLog.lvlTwoSignedByManagerId ? (
                                    <td>{lvlTwoSignedByManager}</td>
                                ) : (
                                    <td>{lvlTwoSignedByManager}</td>
                                )}
                                <td>{preCancelledByManager}</td>
                                <td>{cancelledByManager}</td>
                            </tr>
                        );
                    })}
            </tbody>
        );
    }

    render() {
        return (
            <div>
                <table className="wfp-table--striped mt4 table-hover">
                    {this.tableHeader()}
                    {this.tableBody()}
                </table>
                <PagingComponent
                    key="transactionsPagination"
                    onPageChanged={this.props.onChangePageRequested}
                    paging={this.props.activityLogs.paging}
                />
            </div>
        );
    }
}

export default withNavigate(OversightsTable, 'OversightsTable');
