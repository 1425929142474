import * as React from 'react';
import { Tabs } from '../utils/Tabs';
import { TabsContainer } from '../statistics/styles';
import { ProductGroupsPage } from '../product-groups/product-groups-page';
import { wfpFormat } from '../utils/utils';
import { ProductManagementPage } from '../product-management/product-management.page';
import BreadCrumbs from '../utils/BreadCrumbs';
import { AppConfigActionTypes } from '../app/appConfig';
import { useDispatch, useSelector } from 'react-redux';
import State from '../app/store/state';
import { ProductTabs } from './helpers';
import { ProductMobileUIGroups } from './ProductMobileUI/ProductMobileUIGroups';

export const ProductsPageContainer = function ProductsPageContainer() {
    const dispatch = useDispatch();
    const tab = useSelector((state: State) => state.appConfig.productManagementConfig.productsActiveTab);
    const tabs = Object.values(ProductTabs);
    const activeTab = tab ? tab : tabs[0];

    const handleClick = (tab: string) => dispatch({ type: AppConfigActionTypes.changeProductTab, payload: tab });

    return (
        <main>
            <BreadCrumbs path="/products" />
            <div className="row">
                <h3 className="col-sm-4">Products</h3>
            </div>
            {tabs.length > 1 && (
                <TabsContainer>
                    <Tabs active={activeTab}>
                        {tabs.map((tab) => (
                            <Tabs.Item key={tab} label={tab} onClick={() => handleClick(tab)}>
                                {wfpFormat(tab)}
                            </Tabs.Item>
                        ))}
                    </Tabs>
                </TabsContainer>
            )}
            <Products active={activeTab} />
        </main>
    );
};

function Products({ active }: { active: ProductTabs }) {
    switch (active) {
        // case 'List':
        //     return <ProductsPage />;
        case ProductTabs.MobileGroups:
            return <ProductMobileUIGroups />;
        case ProductTabs.Groups:
            return <ProductGroupsPage />;
        default:
            return <ProductManagementPage />;
    }
}
