import * as React from 'react';

export interface FileTaskReference {
    id: string;
    type: string;
    fileName?: string;
    startedAt?: string;
    finishedAt?: string;
    error?: string;
}

interface FileStatusProps {
    shouldShow: boolean;
    fileReference: FileTaskReference;

    downloadFile: (type: string, id: string) => string;
}

export function FileStatus(props: FileStatusProps) {
    if (!props.shouldShow) {
        return <span>Not Generated</span>;
    }

    if (!props.fileReference || !props.fileReference.finishedAt) {
        return <span>Generating...</span>;
    }

    return <a onClick={() => props.downloadFile(props.fileReference.type, props.fileReference.id)}>Download</a>;
}
