import Action from '../action';

import {
    authorizeBranch,
    authorizeVendor,
    authorizeVendorUser,
    cancelBranch,
    cancelVendorPark,
    cancelVendorUserPark,
    createVendorUser,
    exportActivityLog,
    exportVendors,
    getVendorUser,
    importVendors,
    loadBranchCodes,
    loadVendor,
    loadVendors,
    loadVendorsNickNames,
    loadVendorUsers,
    loadVendorUsersNames,
    parkBranch,
    rejectBranch,
    requestVendorUserPasswordReset,
    saveVendor,
    saveVendorUser,
    uploadVendorPermissionsFile,
} from '../apiClient';
import { errorAction, successAction } from '../utils/notifications';
import { SerializedBalanceEntry } from './model';
import { UploadResult, UploadStatuses } from '../utils/import';
import { ExternalValidationError, FieldValidationActionType } from '../utils/inputs';
import NamedId from '../utils/NamedId';
import { EntityUpdateReference } from '../authorization/taskAuthorizations';
import * as clonedeep from 'lodash.clonedeep';
import State from '../app/store/state';
import hasNewFieldsChanges from '../utils/hasNewFieldsChanges';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { ActivityLogPageType } from '../activityLogs/ActivityLogExport';
import { AsyncTask } from '../utils/asyncTasks';
import { compose } from '../utils/utils';
import { Filter } from '../utils/FilterTypes';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

const messages = {
    permissionUploadSuccess: (fileName: string): string => `${fileName} was uploaded successfully`,
    permissionUploadError: (fileName): string => `${fileName} could not be uploaded`,
    permissionProfilesLoadError: `Permission profiles could not be loaded`,
};

export const vendorUserColumnToAttributeMapping = {
    'First Name': 'firstName',
    'Last Name': 'lastName',
    Email: 'email',
    Status: 'status',
} as const;

export const defaultVendorSortingOrder: SORT_OPTIONS = {
    'orderBy:column': 'nickName',
    'orderBy:direction': 'ASC',
};

export const defaultVendorUsersSortingOrder: SORT_OPTIONS<VendorUserSortAttributes> = {
    'orderBy:column': 'firstName',
    'orderBy:direction': 'ASC',
};

export type VendorUserTableColumns = keyof typeof vendorUserColumnToAttributeMapping;
export type VendorUserSortAttributes = typeof vendorUserColumnToAttributeMapping[VendorUserTableColumns];

export class Vendor {
    id = '';
    name = '';
    nickName = '';
    sapId = '';
    sublocation = '';
    city = '';
    country = '';
    blockchainAddress = '';
    entitlements: Array<SerializedBalanceEntry> = [];
    authorizedAt?: Date;
    createdAt?: Date;
    createdByManager?: NamedId;
    createdByManagerId: string;
    cancelledByManagerId: string;
    authorizedByManager?: NamedId;
    wfpBankKey = '';
    vendorBankKey = '';
    paymentFrequency = '';
    paymentDiscount = '';
    pendingEntityUpdates?: EntityUpdateReference[];
    panelAccessStatus: string;
    transactionsExecutingStatus: string;
    parkedVendorUsers?: Array<ParkedVendorUsers>;
    vendorUsersRequestCount = 0;
    vendorBranchesRequestCount = 0;
    phoneNumber = '';
    email = '';
    permissionsFiles: Array<AsyncTask> = [];
}

export class ParkedVendorUsers {
    vendorId?: string;
    createdByManagerId?: number;
}

export class VendorGeneralInfo {
    id: string;
    sapId: string;
    name: string;
    nickName: string;
    sublocation: string;
    city: string;
    country: string;
    authorizedAt: Date;
    createdAt: Date;
    createdByManagerId: string;
    createdByManager: NamedId;
    cancelledByManagerId: string;
    authorizedByManager: NamedId;
    panelAccessStatus: string;
    transactionsExecutingStatus: string;
    phoneNumber: string;
    email: string;
    pendingEntityUpdates?: EntityUpdateReference[];
    paymentDiscount = '';
    permissionsFiles: Array<AsyncTask> = [];

    constructor(vendor: Vendor = new Vendor()) {
        this.id = vendor.id || '';
        this.sapId = vendor.sapId || '';
        this.name = vendor.name || '';
        this.nickName = vendor.nickName || '';
        this.sublocation = vendor.sublocation || '';
        this.city = vendor.city || '';
        this.country = vendor.country || '';
        this.createdByManagerId = vendor.createdByManagerId || '';
        this.authorizedAt = vendor.authorizedAt;
        this.createdAt = vendor.createdAt;
        this.createdByManager = vendor.createdByManager;
        this.authorizedByManager = vendor.authorizedByManager;
        this.cancelledByManagerId = vendor.cancelledByManagerId;
        this.pendingEntityUpdates = vendor.pendingEntityUpdates;
        this.panelAccessStatus = vendor.panelAccessStatus;
        this.transactionsExecutingStatus = vendor.transactionsExecutingStatus;
        this.phoneNumber = vendor.phoneNumber;
        this.email = vendor.email;
        this.permissionsFiles = vendor.permissionsFiles;
    }
}

export class VendorNickName {
    id = '';
    name = '';
    nickName = '';
    authorizedAt: Date;
}

export class VendorUserName {
    id = '';
    firstName = '';
    lastName = '';
    authorizedAt: Date;
}

export class VendorUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    vendorId: string;
    createdByManager: NamedId;
    createdAt: string;
    authorizedByManager: NamedId;
    authorizedAt: string;
    status: string;
    pendingEntityUpdates?: EntityUpdateReference[];
    isPasswordBlocked: boolean;

    constructor() {
        this.id = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phoneNumber = '';
        this.vendorId = '';
        this.createdByManager = null;
        this.createdAt = '';
        this.authorizedByManager = null;
        this.authorizedAt = '';
        this.status = '';
        this.pendingEntityUpdates = [];
        this.isPasswordBlocked = false;
    }
}

export class VendorUserDraft {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    vendorId: string;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phoneNumber = '';
        this.vendorId = '';
    }
}

export type VendorBranchStatuses = 'active' | 'parked' | 'rejected';

export class VendorBranch {
    id: string;
    outlet: string;
    status: VendorBranchStatuses;
    vendorId: string;
    authorizedAt: Date;
    createdAt: Date;
    updatedAt: Date;
    cancelledAt: Date;
    createdByManagerId: string;
    createdByManagerEmail: string;
    updatedByManagerId: number;
    cancelledByManagerId: number;

    constructor(branch: VendorBranch) {
        this.id = branch.id;
        this.outlet = branch.outlet;
        this.status = branch.status;
        this.vendorId = branch.id;
        this.authorizedAt = branch.authorizedAt;
        this.createdAt = branch.createdAt;
        this.updatedAt = branch.updatedAt;
        this.cancelledAt = branch.cancelledAt;
        this.createdByManagerId = branch.createdByManagerId;
        this.createdByManagerEmail = branch.createdByManagerEmail;
        this.updatedByManagerId = branch.updatedByManagerId;
        this.cancelledByManagerId = branch.cancelledByManagerId;
    }
}

export class VendorsState {
    list: Array<Vendor> = [];
    nickNameList: Array<VendorNickName> = [];
    validationError?: ExternalValidationError = null;
    importStatus?: string;
    importResult?: UploadResult;
    usersList: Array<VendorUser> = [];
    vendorUser: VendorUser;
    branchCodes: Array<string>;
    usersNames: Array<VendorUserName> = null;

    constructor(
        list: Array<Vendor> = [],
        usersList = [],
        vendorUser = new VendorUser(),
        validationError: ExternalValidationError = null,
        importStatus: string = null,
        importResult: UploadResult = null,
        nickNameList: Array<VendorNickName> = [],
        vendorUsersNames: Array<VendorUserName> = [],
        branchCodes = []
    ) {
        this.list = list || [];
        this.nickNameList = nickNameList || [];
        this.validationError = validationError;
        this.importStatus = importStatus;
        this.importResult = importResult;
        this.usersList = usersList;
        this.vendorUser = vendorUser;
        this.usersNames = vendorUsersNames;
        this.branchCodes = branchCodes;
    }
}

const ActionTypes = {
    dataLoaded: 'VendorsPage.dataLoaded',
    nickNameLoaded: 'VendorsPage.nickNameLoaded',
    singleVendorLoaded: 'VendorsPage.singleVendorLoaded',
    importStarted: 'VendorsPage.importStarted',
    importFinished: 'VendorsPage.importFinished',
    vendorCancelled: 'VendorsPage.vendorCancelled',
    cancelledVendorUser: 'VendorsPage.cancelledVendorUser',
    vendorUsersLoaded: 'VendorsPage.vendorUsersLoaded',
    authorizedVendorUser: 'VendorsPage.authorizedVendorUser',
    vendorUserLoaded: 'VendorsPage.vendorUserLoaded',
    vendorUsersNamesLoaded: 'VendorsPage.vendorUsersNamesLoaded',
    branchCodesLoaded: 'VendorsPage.branchCodesLoaded',
    permissionFileUploaded: 'VendorsPage.permissionFileUploaded',
};

export const VendorStatuses = {
    parked: 'parked',
    active: 'active',
    blocked: 'blocked',
};

export function updateVendor(oldVendor: Vendor, data: any) {
    const newVendor: Vendor = {
        id: data.id || oldVendor.id,
        name: data.name || oldVendor.name,
        nickName: data.nickName || oldVendor.nickName,
        sapId: data.sapId || oldVendor.sapId,
        sublocation: data.sublocation || oldVendor.sublocation,
        city: data.city || oldVendor.city,
        country: data.country || oldVendor.country,
        blockchainAddress: oldVendor.blockchainAddress,
        entitlements: oldVendor.entitlements,
        createdByManagerId: oldVendor.createdByManagerId,
        cancelledByManagerId: oldVendor.cancelledByManagerId,
        authorizedAt: oldVendor.authorizedAt,
        authorizedByManager: oldVendor.authorizedByManager,
        wfpBankKey: data.wfpBankKey || oldVendor.wfpBankKey,
        vendorBankKey: data.vendorBankKey || oldVendor.vendorBankKey,
        paymentFrequency: data.paymentFrequency || oldVendor.paymentFrequency,
        paymentDiscount: data.paymentDiscount || oldVendor.paymentDiscount,
        pendingEntityUpdates: oldVendor.pendingEntityUpdates,
        panelAccessStatus: data.panelAccessStatus || oldVendor.panelAccessStatus,
        transactionsExecutingStatus: data.transactionsExecutingStatus || oldVendor.transactionsExecutingStatus,
        vendorUsersRequestCount: data.requestCount || oldVendor.vendorUsersRequestCount,
        vendorBranchesRequestCount: data.requestCount || oldVendor.vendorBranchesRequestCount,
        phoneNumber: data.phoneNumber || oldVendor.phoneNumber,
        email: data.email || oldVendor.email,
        permissionsFiles: data.permissionsFiles || oldVendor.permissionsFiles,
    };
    return newVendor;
}

function createVendorWithoutForbiddenEditedKeys(vendor: Vendor): Vendor {
    const validVendor = clonedeep(vendor);
    delete validVendor.authorizedAt;
    delete validVendor.authorizedByManagerId;
    delete validVendor.password;
    delete validVendor.createdByManagerId;
    return validVendor;
}

function createVendorUserWithoutForbiddenEditedKeys(vendorUser: VendorUser): VendorUser {
    const validVendorUser = vendorUser;
    delete validVendorUser.authorizedAt;
    delete validVendorUser.createdByManager;

    return validVendorUser;
}

function getVendorUserDraft(vendorUser: VendorUser) {
    return {
        firstName: vendorUser.firstName,
        lastName: vendorUser.lastName,
        email: vendorUser.email,
        phoneNumber: vendorUser.phoneNumber,
        vendorId: vendorUser.vendorId,
    };
}

function getVendorUsersWithAuthorized(vendorUsers: Array<VendorUser>, vendorUser: VendorUser) {
    const oldUsers = clonedeep(vendorUsers);

    const index = oldUsers.findIndex((user) => user.id === vendorUser.id);
    if (index >= 0) {
        oldUsers[index] = vendorUser;
    }

    return oldUsers;
}

function vendorsWithCancelledManager(vendors: Array<Vendor>, cancelledVendor: Vendor): Array<Vendor> {
    const newVendors: Array<Vendor> = clonedeep(vendors);
    const index = newVendors.findIndex((vendor) => vendor.id === cancelledVendor.id.toString());
    if (index >= 0) {
        newVendors[index] = cancelledVendor;
    }
    return newVendors;
}

export const ActionCreators = {
    loadVendors(sortOptions: SORT_OPTIONS, filters?: Filter[]) {
        return (dispatch) =>
            loadVendors(sortOptions, filters).then((vendors) => {
                dispatch({
                    type: ActionTypes.dataLoaded,
                    payload: vendors,
                });
            });
    },
    loadVendorUsers(id: string, sortOptions: SORT_OPTIONS<VendorUserSortAttributes>, filters?: Filter[]) {
        return (dispatch) =>
            loadVendorUsers(id, sortOptions, filters).then((vendorUsers) => {
                dispatch({
                    type: ActionTypes.vendorUsersLoaded,
                    payload: vendorUsers,
                });
            });
    },
    loadVendorBranchCodes() {
        return (dispatch) =>
            loadBranchCodes().then((branchCodes) => {
                dispatch({
                    type: ActionTypes.branchCodesLoaded,
                    payload: branchCodes,
                });
            });
    },
    getVendorUser(vendorUserId: string) {
        return (dispatch) =>
            getVendorUser(vendorUserId).then((vendorUser) => {
                dispatch({
                    type: ActionTypes.vendorUserLoaded,
                    payload: vendorUser,
                });
            });
    },
    loadVendorsNickNames(query: SORT_OPTIONS) {
        return (dispatch) =>
            loadVendorsNickNames(query).then((vendors) => {
                dispatch({
                    type: ActionTypes.nickNameLoaded,
                    payload: vendors,
                });
            });
    },
    loadVendorUsersNames(sortOptions: SORT_OPTIONS) {
        return (dispatch) =>
            loadVendorUsersNames(sortOptions).then((vendorUsers) => {
                dispatch({
                    type: ActionTypes.vendorUsersNamesLoaded,
                    payload: vendorUsers,
                });
            });
    },
    loadVendor(vendorId: string) {
        return (dispatch) =>
            loadVendor(vendorId).then((vendor) =>
                dispatch({
                    type: ActionTypes.singleVendorLoaded,
                    payload: vendor,
                })
            );
    },

    saveVendor(vendor: Vendor, permissionsFile?: File) {
        const validVendor = createVendorWithoutForbiddenEditedKeys(vendor);
        return (dispatch, getState: () => State) => {
            const oldVendorData = getState().vendors.list.find((v) => v.id === vendor.id);
            const noChangesToUpdate = oldVendorData && !hasNewFieldsChanges(oldVendorData, vendor);
            if (noChangesToUpdate) {
                dispatch(errorAction('There are no changes to be updated'));
                return;
            }
            return saveVendor(validVendor, permissionsFile).then((res) => {
                if (res === 409) {
                    dispatch(errorAction('A vendor with the same ID or email already exists.'));
                } else if (res === 400) {
                    dispatch(errorAction('Data Validation Error, please check if you you send proper data'));
                } else {
                    dispatch(routerHelperActions.makeRedirect('/vendors'));
                    dispatch(successAction('Vendor saved.'));
                }
            });
        };
    },
    saveVendorUser(vendorUser: VendorUser) {
        const validVendorUser = createVendorUserWithoutForbiddenEditedKeys(vendorUser);
        return (dispatch) => {
            return saveVendorUser(validVendorUser).then((res) => {
                if (res === 400) {
                    dispatch(errorAction('Request failed - no updates or updates already pending.'));
                } else {
                    dispatch(routerHelperActions.makeRedirect('/vendors'));
                    dispatch(successAction('Vendor saved.'));
                }
            });
        };
    },
    createVendorUser(vendorUser: VendorUser) {
        const vendorUserDraft = getVendorUserDraft(vendorUser);
        return (dispatch) =>
            createVendorUser(vendorUserDraft)
                .then(() => {
                    dispatch(routerHelperActions.makeRedirect(`/vendors/${vendorUser.vendorId}/panel`));
                    dispatch(successAction('Created Vendor User'));
                })
                .catch((err) => {
                    if (err.status === 409) {
                        dispatch(errorAction('Conflicting data'));
                    } else {
                        dispatch(errorAction('Unknown error'));
                    }
                });
    },
    authorizeVendorUser(vendorUserId: string) {
        return (dispatch) =>
            authorizeVendorUser(vendorUserId).then((vendorUser) => {
                dispatch({
                    type: ActionTypes.authorizedVendorUser,
                    payload: vendorUser,
                });
                dispatch(routerHelperActions.makeRedirect(`/vendors/${vendorUser.vendorId}/panel`));
                dispatch(successAction('Authorized Vendor User'));
            });
    },
    authorizeVendor(vendorId: string) {
        return (dispatch) =>
            authorizeVendor(vendorId).then((vendor) => {
                dispatch({
                    type: ActionTypes.singleVendorLoaded,
                    payload: vendor,
                });

                dispatch(routerHelperActions.makeRedirect('/vendors'));
                dispatch(successAction('Vendor posted successfully.'));
            });
    },
    cancelVendorUserPark(vendorUserId: string) {
        return (dispatch) =>
            cancelVendorUserPark(vendorUserId).then((vendorUser) => {
                dispatch({
                    type: ActionTypes.cancelledVendorUser,
                    payload: vendorUser,
                });
                dispatch(successAction('Vendor user cancelled successfully.'));
            });
    },

    requestPasswordReset(vendorUser: VendorUser, transport: string) {
        return (dispatch) =>
            requestVendorUserPasswordReset(vendorUser, transport).then(() =>
                dispatch(successAction('Reset password link sent.'))
            );
    },

    exportVendors(columns: any[]) {
        return (dispatch) =>
            exportVendors(columns).catch((err) => {
                if (err.status === 423) {
                    dispatch(
                        errorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        )
                    );
                }
            });
    },

    importVendors(data) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.importStarted,
            });

            return importVendors(data).then((result) =>
                dispatch({
                    type: ActionTypes.importFinished,
                    payload: result,
                })
            );
        };
    },
    cancelPark(id: string) {
        return (dispatch) =>
            cancelVendorPark(id).then((vendor) => {
                dispatch({
                    type: ActionTypes.vendorCancelled,
                    payload: vendor,
                });
                dispatch(routerHelperActions.makeRedirect('/vendors'));
                dispatch(successAction('Vendor cancelled successfully.'));
            });
    },
    parkBranch(vendorId: string, outlet: string) {
        return (dispatch) =>
            parkBranch(vendorId, outlet)
                .then(() => {
                    dispatch(routerHelperActions.makeRedirect(`/vendors/${vendorId}/branches`));
                    dispatch(successAction('Created Branch'));
                })
                .catch((err) => {
                    if (err.status === 409) {
                        dispatch(errorAction('Conflicting data'));
                    } else {
                        dispatch(errorAction('Unknown error'));
                    }
                });
    },
    authorizeBranch(branchId: string, vendorId: string) {
        return (dispatch) =>
            authorizeBranch(vendorId, branchId)
                .then(() => {
                    dispatch(successAction('Authorized Branch'));
                })
                .catch((err) => {
                    if (err.status === 409) {
                        dispatch(errorAction('Conflicting data'));
                    } else {
                        dispatch(errorAction('Unknown error'));
                    }
                });
    },
    rejectBranch(branchId: string, vendorId: string) {
        return (dispatch) =>
            rejectBranch(vendorId, branchId)
                .then(() => {
                    dispatch(successAction('Rejected Branch'));
                })
                .catch(() => {
                    dispatch(errorAction('Unknown error'));
                });
    },
    cancelBranch(branchId: string, vendorId: string) {
        return (dispatch) =>
            cancelBranch(vendorId, branchId)
                .then(() => {
                    dispatch(successAction('Cancelled Branch'));
                })
                .catch(() => {
                    dispatch(errorAction('Unknown error'));
                });
    },
    exportActivityLog(vendorId: string, options: any, filter: any) {
        return (dispatch) =>
            exportActivityLog(vendorId, ActivityLogPageType.Vendor, options, filter).catch((err) => {
                if (err.status === 423) {
                    dispatch(
                        errorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        )
                    );
                }
            });
    },
    uploadPermissionsFile(id: string, file: File) {
        return async (dispatch) => {
            try {
                await uploadVendorPermissionsFile(id, file);
                compose<string, void>(dispatch, successAction, messages.permissionUploadSuccess)(file.name);

                const vendors = await loadVendors(defaultVendorSortingOrder);
                dispatch({
                    type: ActionTypes.dataLoaded,
                    payload: vendors,
                });
            } catch (err) {
                const error = await err.json();

                if (error.message) {
                    compose<string, void>(dispatch, errorAction, () => error.message)(file.name);
                } else {
                    compose<string, void>(dispatch, errorAction, messages.permissionUploadError)(file.name);
                }
            }
        };
    },
};

export function vendorsReducer(state: VendorsState = new VendorsState(), action: Action) {
    switch (action.type) {
        case ActionTypes.dataLoaded:
            return new VendorsState(action.payload, state.usersList);
        case ActionTypes.nickNameLoaded:
            return new VendorsState(
                state.list,
                state.usersList,
                state.vendorUser,
                state.validationError,
                state.importStatus,
                state.importResult,
                action.payload,
                state.usersNames,
                state.branchCodes
            );
        case ActionTypes.singleVendorLoaded:
            const existingVendor = state.list.find((v) => v.id === action.payload.id);
            if (existingVendor) {
                Object.assign(existingVendor, action.payload);
                return state;
            } else {
                return new VendorsState(
                    state.list.concat([action.payload]),
                    state.usersList,
                    state.vendorUser,
                    null,
                    null,
                    null,
                    state.nickNameList,
                    state.usersNames
                );
            }
        case ActionTypes.vendorCancelled:
            const newVendors = vendorsWithCancelledManager(state.list, action.payload.vendor);
            return new VendorsState(newVendors, state.usersList, state.vendorUser);
        case ActionTypes.importStarted:
            return new VendorsState(state.list, state.usersList, state.vendorUser, null, UploadStatuses.pending);
        case ActionTypes.importFinished:
            return new VendorsState(
                [],
                state.usersList,
                state.vendorUser,
                null,
                UploadStatuses.finished,
                action.payload
            );
        case FieldValidationActionType:
            return new VendorsState(state.list, state.usersList, state.vendorUser, action.payload);
        case ActionTypes.vendorUsersLoaded:
            return new VendorsState(
                state.list,
                action.payload.vendorUsers,
                state.vendorUser,
                state.validationError,
                state.importStatus,
                state.importResult,
                state.nickNameList,
                state.usersNames,
                state.branchCodes
            );
        case ActionTypes.cancelledVendorUser:
        case ActionTypes.authorizedVendorUser:
            const newVendorUsers = getVendorUsersWithAuthorized(state.usersList, action.payload);
            return new VendorsState(state.list, newVendorUsers, state.vendorUser);
        case ActionTypes.vendorUserLoaded:
            return new VendorsState(state.list, state.usersList, action.payload);
        case ActionTypes.vendorUsersNamesLoaded:
            return new VendorsState(
                state.list,
                state.usersList,
                state.vendorUser,
                null,
                null,
                null,
                state.nickNameList,
                action.payload
            );
        case ActionTypes.branchCodesLoaded:
            return new VendorsState(
                state.list,
                state.usersList,
                state.vendorUser,
                state.validationError,
                state.importStatus,
                state.importResult,
                state.nickNameList,
                state.usersNames,
                action.payload
            );
    }

    return state;
}
