import { TransactionChain, TransactionStatuses, TransactionType, TransactionTypes } from '../transactions';
import { BigNumber } from '../../utils/bigNumber';
import { PredefinedCurrency } from '../../app/appConfig';

export interface CreateTransactionRequestBody {
    type: string;
    senderId: string;
    receiverId: string;
    amount: BigNumber;
    category: string;
    source: string;
    reason: string;
    causedByTransactionId: number;
    comments?: string;
}

const ReverseTypeMap = new Map<TransactionType, TransactionType>([
    [TransactionType.addVendorBalance, TransactionType.reclaimBalance],
    [TransactionType.reclaimBalance, TransactionType.addVendorBalance],
    [TransactionType.topup, TransactionType.unload],
    [TransactionType.unload, TransactionType.topup],
    [TransactionType.reverse, TransactionType.spendBalance],
    [TransactionType.spendBalance, TransactionType.reverse],
]);

export function createTransactionRequestBody(
    transactionChain: TransactionChain,
    source: string,
    reason: string,
    amount: BigNumber,
    currency: PredefinedCurrency
): CreateTransactionRequestBody {
    if (transactionChain.amount.isEqualTo(amount)) {
        throw new Error('Requested amount is equal to the current amount, no adjustment required.');
    }
    if (amount.isNegative()) {
        throw new Error('Requested amount is negative, amount should never be negative.');
    }

    const isReversal = amount.isLessThan(transactionChain.amount);
    const originalTransaction = transactionChain.items[0];
    const adjustableTxs = transactionChain.items.filter(
        (x) =>
            TransactionTypes.contributingToVendorPayment.includes(x.type) &&
            TransactionStatuses.adjustableStatuses.includes(x.status)
    );

    return {
        type: isReversal ? ReverseTypeMap.get(originalTransaction.type) : originalTransaction.type,
        senderId: isReversal ? originalTransaction.receiverId : originalTransaction.senderId,
        receiverId: isReversal ? originalTransaction.senderId : originalTransaction.receiverId,
        category: originalTransaction.category,
        causedByTransactionId: adjustableTxs[adjustableTxs.length - 1].id, // last adjustable
        amount: new BigNumber(transactionChain.amount).minus(amount).abs().decimalPlaces(currency.fractionDigits),
        comments: null,
        source,
        reason,
    };
}
