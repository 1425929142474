import * as React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { displayTime } from './utils';
import State from '../app/store/state';
import { ActionCreators, AsyncTask, AsyncTaskRawStatus } from './asyncTasks';
import { RefreshSignaler } from './refresher';
import { ActionCreators as ManagerActionCreators, ManagerNickName } from '../managers/managers';

const rawStatusesToStopRefresher = [AsyncTaskRawStatus.failed, AsyncTaskRawStatus.finished, AsyncTaskRawStatus.parked];

interface Props {
    getSubtasksArray: (id: string, subtaskCategory: string) => Promise<RefreshSignaler>;
    authorizeAsyncTask: (type: string, id: string) => void;
    downloadFile: (type: string, id: string, index?: number) => string;
    loadManagersNickNames: () => void;
    clearDetails: () => void;

    data: AsyncTask[];
    entityName: string;
    entityUrl: string;
    taskKind: string;
    subTaskKind: string;
    error: string;
    managerNickNames: ManagerNickName[];
    params: { parentId: string; subtaskCategory: string };
}

class AsyncTasksWithRetriesDetailsView extends React.Component<Props, any> {
    row: AsyncTask;
    private refresher?: RefreshSignaler;

    constructor(props) {
        super(props);
        this.props.clearDetails();
    }

    async UNSAFE_componentWillMount() {
        await this.props.loadManagersNickNames();
        this.refresher = await this.props.getSubtasksArray(
            this.props.params.parentId,
            this.props.params.subtaskCategory
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            this.refresher &&
            this.props.data &&
            this.props.data[0] &&
            !rawStatusesToStopRefresher.includes(this.props.data[this.props.data.length - 1].rawStatus)
        ) {
            this.refresher.stop();
        }
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    render() {
        return (
            <div>
                {this.props.entityName !== 'ActivityLogs' && this.props.entityName !== 'ReportsResult' && (
                    <nav className="wfp-breadcrumbs">
                        <ol className="breadcrumbs--wrapper">
                            <li className="breadcrumbs--item">
                                <Link to="/home" className="breadcrumbs--link">
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <Link to={this.props.entityUrl} className="breadcrumbs--link">
                                    <span>{this.props.entityName}</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <Link
                                    to={
                                        this.props.entityUrl +
                                        '/' +
                                        (this.props.taskKind || '').toLowerCase() +
                                        '/' +
                                        (this.props.subTaskKind || '').toLowerCase()
                                    }
                                    className="breadcrumbs--link"
                                >
                                    <span>
                                        {this.props.taskKind.substring(0, 1).toUpperCase() +
                                            this.props.taskKind.substring(1) +
                                            ' ' +
                                            this.props.subTaskKind.substring(0, 1).toUpperCase() +
                                            this.props.subTaskKind.substring(1)}
                                    </span>
                                </Link>
                            </li>
                            {this.props.data && this.props.data[0] && (
                                <li className="breadcrumbs--item">
                                    <span className="breadcrumbs--last">{`Show Details of ${this.props.taskKind} "${this.props.data[0].id}"`}</span>
                                </li>
                            )}
                        </ol>
                    </nav>
                )}
                <table className="wfp-table mt4 table-hover">
                    {this.props.data && this.props.data[0] && (
                        <tbody>
                            <tr>
                                <th>Attempts count</th>
                                <td>{this.props.data.length}</td>
                            </tr>
                            {this.props.data[0].description && (
                                <tr>
                                    <th>Description</th>
                                    <td>{this.props.data[0].description}</td>
                                </tr>
                            )}
                            {this.props.data[0].createdByManagerId && (
                                <tr>
                                    <th>Created by</th>
                                    <td>{this.props.data[0].createdByManager.name}</td>
                                </tr>
                            )}
                        </tbody>
                    )}
                </table>
                {this.props.data && this.props.data[0] && (
                    <table className="wfp-table--striped ">
                        <thead>
                            <tr>
                                <th>Created at</th>
                                <th>Started at</th>
                                <th>Finished at</th>
                                <th>Status</th>
                                <th>Errors</th>
                                {this.props.data[0].children && this.props.data[0].children.length > 0 && (
                                    <th>Report file</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.data.map((attempt) => (
                                <tr key={attempt.id}>
                                    <td>{displayTime(attempt.createdAt)}</td>
                                    <td>{displayTime(attempt.startedAt)}</td>
                                    <td>{displayTime(attempt.finishedAt)}</td>
                                    <td>{attempt.status}</td>
                                    <td>{attempt.errors ? attempt.errors.map((error) => <p>{error}</p>) : null}</td>
                                    {this.props.data[0].children && this.props.data[0].children.length > 0 && (
                                        <td>
                                            {attempt.children.map((uploadReportChild, index) => {
                                                return (
                                                    <p key={uploadReportChild.toString() + index}>
                                                        {uploadReportChild.fileExpired &&
                                                            `Upload Report ${uploadReportChild.fileName} is expired`}
                                                        {uploadReportChild.errors &&
                                                            'Errors in upload report creation occurred'}
                                                        {!uploadReportChild.errors && (
                                                            <a
                                                                onClick={() =>
                                                                    this.props.downloadFile(
                                                                        uploadReportChild.type,
                                                                        uploadReportChild.id
                                                                    )
                                                                }
                                                            >
                                                                Download
                                                            </a>
                                                        )}
                                                    </p>
                                                );
                                            })}
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        );
    }
}

function mapStateToProps(state: State) {
    const subTaskKind = '';
    const taskKind = 'Import';

    const { entityName = null, entityUrl = null } = {
        entityName: 'Beneficiaries',
        entityUrl: '/beneficiaries',
    };

    return {
        data: state.asyncTasks.subtasksArray,
        scheduler: state.asyncTasks.schedulerDetails,
        entityName,
        entityUrl,
        taskKind,
        subTaskKind,
        error: state.asyncTasks.error,
        managerNickNames: state.managers.managerNickNameList,
    };
}

export default connect(mapStateToProps, (dispatch) =>
    bindActionCreators(Object.assign({}, ActionCreators, ManagerActionCreators), dispatch)
)(AsyncTasksWithRetriesDetailsView as any);
