import * as React from 'react';
import AppState from '../app/store/state';
import Switch from 'rc-switch';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EditBeneficiaryCreators } from './edit/editBeneficiaryActionCreator';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { Form, ValidationContext } from '../utils/inputs';
import { Beneficiary } from './beneficiaries';
import hasConflictingBeneficiaryUpdates from '../utils/hasConflictingBeneficiaryUpdates';
import { EntityType } from '../authorization/taskAuthorizations';
import { InputCard, ReadonlyCard, ReadonlyCardLink, ReadonlyCardMultipleValues } from './Cards';
import { InformationDialog } from '../utils/Dialogs';
import { countries } from '../utils/countries';
import { GROUP_TYPE } from './group-type.enum';
import styled from 'styled-components';
import { ManagerPermission } from '../permission-profiles/permission';
import { ButtonWithModalConfirmation } from '../utils/components/button-with-modal-confirmation';
import { withNavigate } from '@wfp-common/hooks/withNavigate';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';

const flexRow: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

const spacing: React.CSSProperties = { width: 20 };

interface Props {
    beneficiaryId: string;
    beneficiary?: Beneficiary;
    saveAllowed: boolean;
    updateBeneficiary: (beneficiary: Beneficiary) => void;
    resetPin: (beneficiaryId: string) => void;
    country: countries;
    auth: AuthState;
    navigate: NavigateHook;
}

interface State {
    hasPendingDetailsUpdate: boolean;
    beneficiary: Beneficiary;
    isEditEnabled: boolean;
}

interface InputOptions {
    value: any;
    title: string;
    context: any;
    onInputChange: (value) => void;
    name: string;
    inputRequired: boolean;
    image: string;
}

class BeneficiaryPersonalDetails extends React.Component<Props, State> {
    private validationContext = new ValidationContext();

    constructor(props: Props) {
        super(props);
        this.state = this.createDefaultState();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.beneficiaryId !== this.props.beneficiaryId) {
            this.setState(this.createDefaultState());
        }
        if (nextProps.beneficiary) {
            this.setState({ beneficiary: nextProps.beneficiary });
        }
    }

    createDefaultState() {
        return {
            beneficiary: this.props.beneficiary || new Beneficiary(),
            isEditEnabled: false,
            hasPendingDetailsUpdate: false,
        };
    }

    toggleEdit(editChecked) {
        const hasPendingDetailsUpdate = hasConflictingBeneficiaryUpdates(this.props.beneficiary, [
            EntityType.beneficiaryDetails,
        ]);
        if (hasPendingDetailsUpdate) {
            this.setState({ hasPendingDetailsUpdate });
        } else {
            this.setState({
                isEditEnabled: editChecked,
                beneficiary: this.props.beneficiary,
            });
        }
    }

    createInputOptions(
        value: any,
        title: string,
        name: string,
        inputRequired: boolean,
        image?: string,
        type?: string,
        onInputChange: any = this.handleInputChange.bind(this)
    ): InputOptions {
        const inputOptions = {
            value: value,
            title: title,
            context: this.validationContext,
            onInputChange: onInputChange,
            name: name,
            inputRequired: inputRequired,
            image: image,
            type: type,
        };
        return inputOptions;
    }

    sectionHeader(title: string) {
        return <h6>{title}</h6>;
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Beneficiary details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending updates"
            />
        );
    }

    renderField(isEditEnabled: boolean, inputOptions: InputOptions, onClick = null) {
        const { image, title, value } = inputOptions;
        if (isEditEnabled) {
            return <InputCard image={image} inputOptions={inputOptions} title={title} value={value || ''} />;
        } else if (onClick) {
            return <ReadonlyCardLink image={image} onClick={onClick} title={title} value={value} />;
        } else {
            return <ReadonlyCard image={image} title={title} value={value} />;
        }
    }

    renderIndividualBeneficiaries = () => {
        const beneficiary = this.state.beneficiary;
        const individualBeneficiaries =
            beneficiary.groupType === 'individual' ? [beneficiary.id] : beneficiary.individualBeneficiaries;
        const title = 'Individual ID(s)';

        return <ReadonlyCardMultipleValues title={title} values={individualBeneficiaries} />;
    };

    redirectToBeneficiary(caseId) {
        return () => {
            this.props.navigate('/beneficiaries/beneficiary', { beneficiaryId: caseId });
        };
    }

    render() {
        const { saveAllowed } = this.props;
        const { isEditEnabled, beneficiary, hasPendingDetailsUpdate } = this.state;
        const parkButtonPresented = saveAllowed && this.state.isEditEnabled;
        const householdIdInputOptions = this.createInputOptions(
            beneficiary.householdId,
            'Household ID',
            'householdId',
            false,
            'thematic-affected-population-pos'
        );

        const householdSizeInputOptions = this.createInputOptions(
            beneficiary.householdSize,
            'Household Size',
            'householdSize',
            true,
            null,
            'number'
        );
        const governorateInputOptions = this.createInputOptions(
            beneficiary.locationOne,
            'Level 1',
            'locationOne',
            false
        );
        const districtInputOptions = this.createInputOptions(beneficiary.locationTwo, 'Level 2', 'locationTwo', false);
        const cityInputOptions = this.createInputOptions(beneficiary.locationThree, 'Level 3', 'locationThree', false);
        const isIndividualAccount = beneficiary?.groupType === GROUP_TYPE.INDIVIDUAL;

        const hasBeneficiaryPinsPermission = hasFeatureAccess(
            this.props.auth,
            ManagerPermission.beneficiariesPinManagement
        );
        return (
            <div>
                {hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}
                <h6>Details</h6>
                <div className={'row'}>
                    {saveAllowed && <label>Toggle edit</label>}
                    {saveAllowed && (
                        <div style={{ marginLeft: '10px' }}>
                            <Switch checked={isEditEnabled} onClick={this.toggleEdit.bind(this)} />
                        </div>
                    )}
                </div>
                <Form className="wfp-form" context={this.validationContext} onSubmit={this.saveChanges.bind(this)}>
                    <Container>
                        {/*{this.renderField(isEditEnabled, phoneNumberInputOptions)}*/}
                        {this.renderField(isEditEnabled, householdSizeInputOptions)}
                        {this.renderField(
                            false,
                            householdIdInputOptions,
                            isIndividualAccount ? this.redirectToBeneficiary(householdIdInputOptions.value) : null
                        )}
                    </Container>
                    {this.props.country === countries.BANGLADESH ? this.renderIndividualBeneficiaries() : null}
                    {this.sectionHeader('Location')}
                    <div style={flexRow}>
                        {this.renderField(isEditEnabled, governorateInputOptions)}
                        <div style={spacing} />
                        {this.renderField(isEditEnabled, districtInputOptions)}
                        <div style={spacing} />
                        {this.renderField(isEditEnabled, cityInputOptions)}
                    </div>
                    <div className="wfp-form--actions text-center">
                        <button className="wfp-btn--primary" disabled={!parkButtonPresented} type="submit">
                            Park changes
                        </button>
                        {hasBeneficiaryPinsPermission && (
                            <ButtonWithModalConfirmation
                                buttonStyle={{ margin: '30px 0px' }}
                                customModalBody={'Do you confirm reset beneficiary pin ?'}
                                customModalTitle={'Reset Beneficiary Pin'}
                                onApprove={this.resetBeneficiaryPinHandler}
                            >
                                Reset Beneficiary Transaction Pin
                            </ButtonWithModalConfirmation>
                        )}
                    </div>
                </Form>
            </div>
        );
    }

    handleInputChange({ target }) {
        const newBeneficiary = Object.assign({}, this.state.beneficiary);
        newBeneficiary[target.name] = target.value;
        this.setState({ beneficiary: newBeneficiary });
    }

    saveChanges(event) {
        this.props.updateBeneficiary(this.state.beneficiary);
        this.setState({
            isEditEnabled: false,
            beneficiary: this.props.beneficiary,
        });
        event.preventDefault();
    }

    resetBeneficiaryPinHandler = (event) => {
        this.props.resetPin(this.state.beneficiary.id);
        event.preventDefault();
        event.stopPropagation();
    };
}

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

function mapDispatchToProps(dispatch: any) {
    return {
        updateBeneficiary: bindActionCreators(EditBeneficiaryCreators.updateBeneficiary, dispatch),
        loadBeneficiary: bindActionCreators(EditBeneficiaryCreators.loadBeneficiary, dispatch),
        resetPin: bindActionCreators(EditBeneficiaryCreators.resetPin, dispatch),
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    const saveAllowed = hasFeatureAccess(state.auth, ManagerPermission.beneficiariesPark);
    return {
        beneficiary: state.editBeneficiary.beneficiary,
        beneficiaryId: ownProps.location.state.beneficiaryId,
        saveAllowed: saveAllowed,
        country: state.appConfig.country,
        auth: state.auth,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigate(BeneficiaryPersonalDetails, 'BeneficiaryPersonalDetails'));

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px;
`;
