import * as React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';

import State from '../app/store/state';
import { ActionCreators as BeneficiaryActionCreators } from './beneficiaries';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes, AsyncTask } from '../utils/asyncTasks';
import { Filter } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';
import { RefreshSignaler } from '../utils/refresher';
import * as _ from 'lodash';
import { EntitlementCategoriesConfig } from '../app/appConfig';

interface OwnProps {
    invalidBalances: boolean;
}

interface Props {
    exports: PagedState<AsyncTask>;
    beneficiariesTotalCount?: number;
    beneficiaryFilters: Array<Filter>;
    downloadFile: (type: string, id: string) => string;
    loadAsyncTasksList: (page: number, limit: number, type: string) => Promise<RefreshSignaler>;
    loadBeneficiaries: () => Promise<any>;
    exportBeneficiaries: (
        timezone: string,
        mapping: Array<{ key; header }>,
        filters: any,
        invalidBalance?: boolean
    ) => Promise<any>;
    authorizeTask: (type: string, id: string) => Promise<any>;
    appendAsyncTasksList: (limit: number, type: string) => void;
    managerId: string;
    invalidBalances: boolean;
    categories: EntitlementCategoriesConfig;
    timezone: string;
}

interface LocalState {
    selectedColumns: any;
    selectAll: boolean;
}

const entitlementsColumnsCategories = [
    'ActiveTokensBeforeZeroing',
    'ExpiredTokensBeforeZeroing',
    'FutureTokensBeforeZeroing',
    'ActiveBalance',
    'ExpiredBalance',
    'FutureBalance',
    // 'BalanceBlockchainBased', 'BalanceDbComparisionBased',
    // 'Expenditures',
    // 'Entitlements',
    // 'Adjustments',
    // 'Unloads',
];

class BeneficiaryExportPage extends React.Component<Props, LocalState> {
    private availableColumns: any = [
        { key: 'id', header: 'Beneficiary ID' },
        //{ key: 'balance', header: 'Balance' },
        { key: 'householdId', header: 'Household ID' },
        { key: 'householdSize', header: 'Account ID Size' },
        { key: 'createdAt', header: 'Create Date' },
        { key: 'lastActiveAt', header: 'Last Activity At' },
        { key: 'lastTransactionAt', header: 'Last Transaction At' },
        { key: 'status', header: 'Status' },
        { key: 'statusInfo.reason', header: 'Status Info - Reason' },
        { key: 'statusInfo.source', header: 'Status Info - Source' },
        {
            key: 'statusInfo.zeroingReason',
            header: 'Status Info - Zeroing Reason',
        },
        {
            key: 'statusInfo.zeroingSource',
            header: 'Status Info - Zeroing Source',
        },
        { key: 'locationOne', header: 'Location One' },
        { key: 'locationTwo', header: 'Location Two' },
        { key: 'locationThree', header: 'Location Three' },
        { key: 'statusChangedAt', header: 'Status Changed at' },
        { key: 'alternativeCollectorCaseId', header: 'AC ID' },
        {
            key: 'alternativeCollectorStatus',
            header: 'AC Status',
        },
        {
            key: 'alternativeCollectorStatusInfo.reason',
            header: 'Alternative Collector Status - Reason',
        },
        {
            key: 'alternativeCollectorStatusInfo.source',
            header: 'Alternative Collector Status - Source',
        },
    ];
    entitlementsColumns = [];

    private defaultPageSize = 10;
    private refresher?: RefreshSignaler;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedColumns: {
                id: true,
                householdId: true,
            },
            selectAll: false,
        };
    }

    async changePageRequested() {
        this.props.appendAsyncTasksList(this.defaultPageSize, AsyncTasksTypes.EXPORT_BENEFICIARIES);
    }

    async UNSAFE_componentWillMount() {
        if (!this.props.beneficiariesTotalCount) {
            await this.props.loadBeneficiaries();
        }
        if (this.props.categories) {
            const categories = this.props.categories.categories;
            this.entitlementsColumns = entitlementsColumnsCategories.reduce(function (acc, columnName) {
                return acc.concat(
                    categories.map((category) => {
                        return {
                            key: `entitlementColumns.${category.toLowerCase()}${columnName}`,
                            header: _.startCase(`${category.toLowerCase()}${columnName}`).replace(
                                'Future',
                                'Scheduled'
                            ),
                        };
                    })
                );
            }, []);
        }
        const asyncType = this.props.invalidBalances
            ? AsyncTasksTypes.EXPORT_INVALID_BENEFICIARIES
            : AsyncTasksTypes.EXPORT_BENEFICIARIES;
        this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize, asyncType);
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.categories && nextProps.categories.categories) {
            this.entitlementsColumns = entitlementsColumnsCategories.reduce(function (acc, columnName) {
                return acc.concat(
                    nextProps.categories.categories.map((category) => {
                        return {
                            key: `entitlementColumns.${category.toLowerCase()}${columnName}`,
                            header: _.startCase(`${category.toLowerCase()}${columnName}`).replace(
                                'Future',
                                'Scheduled'
                            ),
                        };
                    })
                );
            }, []);
        }
    }

    renderExportFeature() {
        return (
            <div>
                <div className="wfp-form--group">
                    <div className="checkbox" key="all" style={{ marginBottom: 25 }}>
                        <label>
                            <input
                                checked={this.state.selectAll}
                                name="all"
                                onChange={this._handleCheckAll.bind(this)}
                                type="checkbox"
                            />
                            Select All
                        </label>
                    </div>
                    {this.availableColumns.map((column) => (
                        <div className="checkbox" key={column.key}>
                            <label>
                                <input
                                    checked={this.state.selectedColumns[column.key] || false}
                                    name={column.key}
                                    onChange={this._handleCheck.bind(this)}
                                    type="checkbox"
                                />{' '}
                                {column.header}
                            </label>
                        </div>
                    ))}
                    {this.entitlementsColumns.map((column) => (
                        <div className="checkbox" key={column.key}>
                            <label>
                                <input
                                    checked={this.state.selectedColumns[column.key] || false}
                                    name={column.key}
                                    onChange={this._handleCheck.bind(this)}
                                    type="checkbox"
                                />{' '}
                                {column.header}
                            </label>
                        </div>
                    ))}
                </div>

                <div className="wfp-form--actions">
                    <button className="wfp-btn" type="submit">
                        Generate
                    </button>
                </div>
            </div>
        );
    }

    renderExportFeatureDescription(invalidBalances: boolean) {
        return (
            <div>
                {!invalidBalances && <p>{this.props.beneficiariesTotalCount} Beneficiaries are listed.</p>}
                <p>Please select the information you would like to download per Beneficiary:</p>
            </div>
        );
    }

    render() {
        const { invalidBalances } = this.props;
        const title = 'Download Beneficiaries';
        const balanceFilter = this.props.beneficiaryFilters.find((filter) => filter.name === 'doBalanceMatch');

        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/beneficiaries">
                                <span>Beneficiaries</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{title}</span>
                        </li>
                    </ol>
                </nav>
                <h3>{title}</h3>

                {this.renderExportFeatureDescription(invalidBalances)}
                {balanceFilter && balanceFilter.isSelected && (
                    <p>
                        Please note that export might not include new beneficiaries whose balances have been not
                        compared yet.
                    </p>
                )}

                {this.entitlementsColumns.length > 0 && (
                    <form onSubmit={this._exportBeneficiaries.bind(this)}>{this.renderExportFeature()}</form>
                )}
                {this.props.exports && this.props.exports.items.length > 0 && (
                    <div>
                        <AsyncTasksListExportView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={this.props.managerId}
                            canDownload={true}
                            data={this.props.exports.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <div className="wfp-form--actions">
                            <button
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                                onClick={this.changePageRequested.bind(this)}
                                type="button"
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleCheck(event) {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState((prevState) => {
            const selectedColumns = prevState.selectedColumns;
            selectedColumns[key] = value;
            return { selectedColumns };
        });
    }

    _handleCheckAll(event) {
        const value = event.target.checked;
        const selectedColumns = {};

        this.availableColumns.forEach((column) => {
            selectedColumns[column.key] = value;
        });

        this.entitlementsColumns.forEach((column) => {
            selectedColumns[column.key] = value;
        });

        this.setState(() => {
            return { selectedColumns, selectAll: value };
        });
    }

    _exportBeneficiaries(event) {
        event.preventDefault();

        let mapping = this.availableColumns.filter((column) => this.state.selectedColumns[column.key]);
        mapping = mapping.concat(this.entitlementsColumns.filter((column) => this.state.selectedColumns[column.key]));
        const asyncType = this.props.invalidBalances
            ? AsyncTasksTypes.EXPORT_INVALID_BENEFICIARIES
            : AsyncTasksTypes.EXPORT_BENEFICIARIES;

        return this.props
            .exportBeneficiaries(
                this.props.timezone,
                mapping,
                this.props.beneficiaryFilters,
                this.props.invalidBalances
            )
            .then(() => this.props.loadAsyncTasksList(1, this.defaultPageSize, asyncType));
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        exportBeneficiaries: bindActionCreators(BeneficiaryActionCreators.exportBeneficiaries, dispatch),
        loadBeneficiaries: bindActionCreators(BeneficiaryActionCreators.loadBeneficiaries, dispatch),
        loadAsyncTasksList: bindActionCreators(AsyncActionCreators.loadAsyncTasksList, dispatch),
        appendAsyncTasksList: bindActionCreators(AsyncActionCreators.appendAsyncTasksList, dispatch),
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
    };
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        beneficiariesTotalCount: state.beneficiaries && state.beneficiaries.paging && state.beneficiaries.paging.total,
        exports: state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_BENEFICIARIES] || new PagedState(),
        beneficiaryFilters: state.beneficiaries && state.beneficiaries.filters,
        managerId: state.auth.manager ? state.auth.manager.id : null,
        invalidBalanced: ownProps.invalidBalances,
        categories: state.appConfig.entitlementsConfig,
        timezone: state.appConfig.timeZone,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(BeneficiaryExportPage as any);
