import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import State from '../app/store/state';
import { AuthorizationSideMenu as SideMenu, AuthorizationSideMenuProps } from '../utils/SideMenu';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { ActionCreators, UpdateStateListType } from './taskAuthorizations';
import { ManagerPermission } from '../permission-profiles/permission';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

export enum AuthorizationTab {
    VendorsDetails = 'Vendors',
    Beneficiaries = 'Beneficiaries',
    Transactions = 'Transactions',
    ManagerDetails = 'Users',
    VendorsUsersDetails = 'Vendor Users',
    PartnersDetails = 'Partners',
    PartnersUsersDetails = 'Partner Users',
    Locations = 'Locations',
    Products = 'Products',
    PermissionProfiles = 'Permission Profiles',
}

const urlPathForTab = {
    [AuthorizationTab.Transactions]: 'transactions',
    [AuthorizationTab.VendorsDetails]: 'vendors',
    [AuthorizationTab.Beneficiaries]: 'beneficiaries',
    [AuthorizationTab.ManagerDetails]: 'users',
    [AuthorizationTab.VendorsUsersDetails]: 'vendor-users',
    [AuthorizationTab.PartnersDetails]: 'partners',
    [AuthorizationTab.PartnersUsersDetails]: 'partner-users',
    [AuthorizationTab.Locations]: 'locations',
    [AuthorizationTab.Products]: 'products',
    [AuthorizationTab.PermissionProfiles]: 'permission-profiles',
};
const urlPrefix = '/adjustments';

export function adjustmentsRedirectPath(state: State) {
    const permissionPaths = {
        [ManagerPermission.transactionRequestRead]: 'transactions',
        [ManagerPermission.beneficiariesRead]: 'beneficiaries',
        [ManagerPermission.vendorsRead]: 'vendors',
        [ManagerPermission.managersManagement]: 'users',
        [ManagerPermission.vendorsRead]: 'vendor-users',
    };
    for (const key of Object.keys(permissionPaths)) {
        if (hasFeatureAccess(state.auth, key as ManagerPermission)) {
            return `${urlPrefix}/${permissionPaths[key]}`;
        }
    }
    return `${urlPrefix}/transactions`;
}

interface Props {
    routes: { path: string }[];
    auth: AuthState;
    redirectToPage: (url: string) => void;
    changeListType: (listType: UpdateStateListType) => void;
    pendingAdjustmentActions: any;
    locationToggle: boolean;
}

type TransactionAuthorizationState = {};

function AuthorizationSideMenu(props: AuthorizationSideMenuProps<any>) {
    return SideMenu(props);
}

class TaskAuthorizationsPage extends React.Component<Props, TransactionAuthorizationState> {
    constructor(props) {
        super(props);
        const elements = this.sidemenuElements();
        this.state = {
            activeTab: elements[0],
        };
    }

    UNSAFE_componentWillMount() {
        this.props.changeListType(UpdateStateListType.Requested);
    }

    sidemenuElements(): [{ text: any; counter: number }] {
        const { auth, pendingAdjustmentActions } = this.props;
        const hasTransactionsRead = hasFeatureAccess(auth, ManagerPermission.transactionRequestRead);
        const hasBeneficiaryUpdatesRead = hasFeatureAccess(auth, ManagerPermission.beneficiariesRead);
        const hasLocationUpdatesRead = hasFeatureAccess(auth, ManagerPermission.beneficiariesPost);
        const hasVendorUpdatesRead = hasFeatureAccess(auth, ManagerPermission.vendorsRead);
        const hasVendorUsersUpdatesRead = hasFeatureAccess(auth, ManagerPermission.vendorsRead);
        const hasManagersUpdatesRead = hasFeatureAccess(auth, ManagerPermission.managersManagement);
        const hasPartnersRead = hasFeatureAccess(auth, ManagerPermission.partnersRead);
        const hasProfilesRead = hasFeatureAccess(auth, ManagerPermission.profilesRead);
        const hasProductsRead = hasFeatureAccess(auth, ManagerPermission.productsRead);
        const tabs: any = [];

        const transactionsTab = {
            text: AuthorizationTab.Transactions,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.transactions > 0
                    ? Number(pendingAdjustmentActions.transactions)
                    : 0,
        };
        const beneficiariesTab = {
            text: AuthorizationTab.Beneficiaries,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.beneficiaries > 0
                    ? Number(pendingAdjustmentActions.beneficiaries)
                    : 0,
        };
        const vendorsTab = {
            text: AuthorizationTab.VendorsDetails,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.vendors > 0
                    ? Number(pendingAdjustmentActions.vendors)
                    : 0,
        };
        const usersTab = {
            text: AuthorizationTab.ManagerDetails,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.users > 0
                    ? Number(pendingAdjustmentActions.users)
                    : 0,
        };
        const vendorUsersTab = {
            text: AuthorizationTab.VendorsUsersDetails,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.vendorUsers > 0
                    ? Number(pendingAdjustmentActions.vendorUsers)
                    : 0,
        };
        const locationsTab = {
            text: AuthorizationTab.Locations,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.locationActions > 0
                    ? Number(pendingAdjustmentActions.locationActions)
                    : 0,
        };
        const partnersTab = {
            text: AuthorizationTab.PartnersDetails,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.partners > 0
                    ? Number(pendingAdjustmentActions.partners)
                    : 0,
        };
        const partnersUsersTab = {
            text: AuthorizationTab.PartnersUsersDetails,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.partnerUsers > 0
                    ? Number(pendingAdjustmentActions.partnerUsers)
                    : 0,
        };
        const productsTab = {
            text: AuthorizationTab.Products,
            counter:
                Number(pendingAdjustmentActions?.products) + Number(pendingAdjustmentActions?.productSignatures) ?? 0,
        };

        const permissionProfilesTab = {
            text: AuthorizationTab.PermissionProfiles,
            counter:
                pendingAdjustmentActions && pendingAdjustmentActions.permissionsProfiles > 0
                    ? Number(pendingAdjustmentActions.permissionsProfiles)
                    : 0,
        };

        if (hasTransactionsRead) {
            tabs.push(transactionsTab);
        }
        if (hasBeneficiaryUpdatesRead) {
            tabs.push(beneficiariesTab);
        }
        if (this.props.locationToggle && hasLocationUpdatesRead) {
            tabs.push(locationsTab);
        }
        if (hasVendorUpdatesRead) {
            tabs.push(vendorsTab);
        }
        if (hasManagersUpdatesRead) {
            tabs.push(usersTab);
        }
        if (hasVendorUsersUpdatesRead) {
            tabs.push(vendorUsersTab);
        }
        if (hasPartnersRead) {
            tabs.push(partnersTab, partnersUsersTab);
        }
        if (hasProfilesRead) {
            tabs.push(permissionProfilesTab);
        }
        if (hasProductsRead) {
            tabs.push(productsTab);
        }
        return tabs;
    }

    activeMenuTab(): AuthorizationTab {
        const { routes } = this.props;
        const lastUrlPart = routes[routes.length - 1].path;
        for (const key of Object.keys(urlPathForTab)) {
            if (urlPathForTab[key] === lastUrlPart) {
                return key as AuthorizationTab;
            }
        }
    }

    renderSidemenu() {
        const tabs = this.sidemenuElements();
        const activeTab = this.activeMenuTab();
        return (
            <div style={{ marginTop: 60 }}>
                <AuthorizationSideMenu
                    activeTab={activeTab}
                    onSelectTab={this.changeActiveTab.bind(this)}
                    tabs={tabs}
                />
            </div>
        );
    }

    changeActiveTab(activeTab) {
        const url = urlPathForTab[activeTab.toString()];
        this.props.redirectToPage(`${urlPrefix}/${url}`);
    }

    render() {
        return (
            <main className={'row'}>
                <div className="col-sm-3">{this.renderSidemenu()}</div>
                <div className="col-sm-9" style={{ marginBottom: 150 }}>
                    {this.props.children}
                </div>
                <div className="clearfix" />
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        auth: state.auth,
        pendingAdjustmentActions: state.pendingActions.adjustments,
        locationToggle: state.appConfig.location.isActive,
    };
}
function mapDispatchToProps(dispatch: Dispatch<State>) {
    return {
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
        changeListType: bindActionCreators(ActionCreators.changeListType, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(TaskAuthorizationsPage);
