import * as React from 'react';
import { Controls } from './style/grouped-prices-page.style';
import { Select } from '../utils/form/fields/Select';
import styled from 'styled-components';
import { Loader } from '../utils/Loader';
import { ProductList } from './product-list';
import { useProductManagement } from './hooks/use-product-management.hook';
import { OutletList } from './outlet-list';
import { displayTime } from '../utils/utils';
import { PendingUpdateModal } from './modals/pending-update.modal';
import { ScheduledUpdateModal } from './modals/scheduled-update.modal';
import { useSelector } from 'react-redux';
import State from '../app/store/state';

export function ProductManagementPage() {
    const {
        isLoading,
        handleChange,
        checkBehaviour,
        isModalOpen,
        group,
        toggleDialog,
        productGroupOptions,
        prices,
        canParkUpdate,
        handleOutletSort,
        handleProductSort,
    } = useProductManagement();
    const isPendingUpdate = isModalOpen === 'pending';
    const isScheduledUpdate = isModalOpen === 'scheduled';
    const priceSchedulingEnabled = useSelector(
        (state: State) => state.appConfig.productManagementConfig.priceSchedulingEnabled
    );
    return (
        <>
            {isPendingUpdate && <PendingUpdateModal groupName={group?.label} onClose={toggleDialog} />}
            {isScheduledUpdate && (
                <ScheduledUpdateModal
                    groupName={group?.label}
                    onClose={toggleDialog}
                    scheduledDate={displayTime(prices?.scheduledUpdates?.[0]?.validFrom)}
                />
            )}
            <Container>
                <div className="wrapper">
                    <Controls>
                        <div className="controls__filters">
                            <label>
                                <span>Group:</span>
                                <Select
                                    name="productGroup"
                                    onChange={handleChange}
                                    options={productGroupOptions}
                                    value={group}
                                />
                            </label>
                            {canParkUpdate && (
                                <label className="btn-container">
                                    <button className="wfp-btn" onClick={checkBehaviour}>
                                        Update
                                    </button>
                                </label>
                            )}
                        </div>
                    </Controls>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        prices && (
                            <>
                                <div className="product-management-content">
                                    <div>
                                        {priceSchedulingEnabled && (
                                            <p>
                                                <small>
                                                    <em>
                                                        {prices.scheduledUpdates[0]
                                                            ? `Price update scheduled for
                                                    ${displayTime(prices.scheduledUpdates[0].validFrom)}`
                                                            : `No scheduled prices`}
                                                    </em>
                                                </small>
                                            </p>
                                        )}
                                        <ProductList {...prices} onSort={handleProductSort} />
                                    </div>
                                </div>
                                <OutletList {...prices} onSort={handleOutletSort} />
                            </>
                        )
                    )}
                </div>
            </Container>
        </>
    );
}

const Container = styled.div`
    display: grid;
    width: 1200px;

    & > .wrapper {
        display: inline-grid;
        gap: 1.5rem;
        margin-top: 2rem;
    }

    .product-management-content {
        display: grid;
        gap: 1.5rem;

        p {
            text-align: right;
        }
    }
`;
