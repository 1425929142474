import * as React from 'react';
import { supStyle } from './utils';

export interface SideMenuProps<T> {
    tabs: T[];
    activeTab: T;
    onSelectTab: (tab: T) => void;
    pendingActions?: Array<number>;
}

export function SideMenu<T>(props: SideMenuProps<T>) {
    const { tabs, activeTab, onSelectTab, pendingActions } = props;
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {tabs.map((tab, index) => (
                <button
                    className={'wfp-btn ' + (activeTab === tab ? 'active' : '')}
                    key={index}
                    onClick={() => onSelectTab(tab)}
                    style={{ margin: '5px 0' }}
                    type={'button'}
                >
                    {tab}
                    {pendingActions &&
                        pendingActions.length &&
                        pendingActions.length > index &&
                        !!pendingActions[index] && (
                            <sup style={{ ...supStyle, marginLeft: '4px' }}>{pendingActions[index]}</sup>
                        )}
                </button>
            ))}
        </div>
    );
}

export interface AuthorizationSideMenuProps<T> {
    tabs: Array<{ text: T; counter: number }>;
    activeTab: T;
    onSelectTab: (tab: T) => void;
}

export function AuthorizationSideMenu<T>(props: AuthorizationSideMenuProps<T>) {
    const { tabs, activeTab, onSelectTab } = props;
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {tabs.map((tab, index) => (
                <button
                    className={'wfp-btn ' + (activeTab === tab.text ? 'active' : '')}
                    key={index}
                    onClick={() => onSelectTab(tab.text)}
                    style={{ margin: '5px 0' }}
                    type={'button'}
                >
                    {tab.text}
                    {tab.counter && tab.counter > 0 ? <sup style={supStyle}>{tab.counter}</sup> : <></>}
                </button>
            ))}
        </div>
    );
}

interface SimpleTab<T> {
    text: T;
    id: number;
    counter?: number;
}

export interface SimpleSideMenuProps<T> {
    tabs: SimpleTab<T>[];
    activeTab: SimpleTab<T>;
    onSelectTab: (tab: SimpleTab<T>) => void;
}

export function SimpleSideMenu<T>(props: SimpleSideMenuProps<T>) {
    const { tabs, activeTab, onSelectTab } = props;
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {tabs.map((tab) => (
                <button
                    className={'wfp-btn ' + (activeTab?.id === tab.id ? 'active' : '')}
                    key={tab.id}
                    onClick={() => onSelectTab(tab)}
                    style={{ margin: '5px 0' }}
                    type={'button'}
                >
                    {tab.text}
                    {tab.counter && tab.counter > 0 ? <sup style={supStyle}>{tab.counter}</sup> : <></>}
                </button>
            ))}
        </div>
    );
}
