import * as React from 'react';
import { useState } from 'react';
import { Form, Input, ValidationContext } from '../utils/inputs';
import PermissionsList from './permissions-list';
import { createPermissionProfile } from '../apiClient';
import { useDispatch } from 'react-redux';
import { errorAction, successAction } from '../utils/notifications';
import BreadCrumbs from '../utils/BreadCrumbs';
import { ManagerPermission } from './permission';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

interface NewPermissionProfile {
    name: string;
    permissions: ManagerPermission[];
}

const AddPermissionProfilePage = () => {
    const dispatch = useDispatch();
    const validationContext = new ValidationContext();

    const [permissionProfile, setPermissionProfile] = useState<NewPermissionProfile>({ name: '', permissions: [] });

    const onSubmit = () => {
        createPermissionProfile(permissionProfile)
            .then(() => {
                dispatch(routerHelperActions.makeRedirect('/admin/permission-profiles'));
                dispatch(successAction('The permission profile create request was created successfully.'));
            })
            .catch(() => dispatch(errorAction('Something went wrong.')));
    };

    return (
        <main>
            <BreadCrumbs path={'/admin/permission-profiles/new'} />
            <div className="row">
                <h3 className="col-sm-12">Add Permission Profiles</h3>
            </div>
            <div className="row">
                <div className="col-sm-9" style={{ margin: 'auto auto' }}>
                    <Form className="wfp-form" context={validationContext} onSubmit={onSubmit}>
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Profile Name</label>
                            <div className="col-sm-8">
                                <Input
                                    className="form-control"
                                    context={validationContext}
                                    isFocused={true}
                                    name={'name'}
                                    onChange={(e) =>
                                        setPermissionProfile({ ...permissionProfile, name: e.target.value })
                                    }
                                    required={true}
                                    type={'text'}
                                    value={permissionProfile.name}
                                />
                            </div>
                        </div>
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Permissions</label>
                            <div className="col-sm-8">
                                <PermissionsList
                                    editDisabled={false}
                                    onPermissionsChanged={(newPermissions) =>
                                        setPermissionProfile({ ...permissionProfile, permissions: newPermissions })
                                    }
                                    permissions={permissionProfile.permissions}
                                />
                            </div>
                        </div>
                        <div className="wfp-form--actions text-center">
                            <div className="col-sm-8 col-sm-offset-4">
                                <button className="wfp-btn--primary" type="submit">
                                    Create Permission Profile
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </main>
    );
};

AddPermissionProfilePage.displayName = 'AddPermissionProfilePage';

export { AddPermissionProfilePage };
