import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';

import State from '../app/store/state';
import { PagedState } from '../utils/paging';
import { TextFilter, Filter, MultiSelectFilter, DateSelectFilter } from '../utils/FilterTypes';
import FiltersComponent from '../utils/filters';
import { ActionCreators as VendorActionCreators, defaultVendorSortingOrder, VendorNickName } from '../vendors/vendors';
import { ActionCreators as PartnerActionCreators } from '../partners/partnersActions';

import { AuthState, hasFeatureAccess } from '../login/auth';
import { FiltersCreators, FiltersTypes } from '../utils/filtersStore';
import { ActionCreators, ActivityLog } from '../activityLogs/activityLogs';
import OversightsTable from './OversightsTable';
import { PredefinedDateRanges } from '../../utils/createPredefinedFilters';
import { ActionCreators as ManagerActionCreators, ManagerNickName } from '../managers/managers';
import { updateFiltersWithNickNames } from '../utils/createEntityMultiSelect';
import { createActivityTypeFilter } from './createPredefinedFilters';
import { defaultPartnerSortOptions, PartnerName } from '../partners/partners';
import { defaultDateLimit } from '../utils/DateFilter';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { ManagerPermission } from '../permission-profiles/permission';

const defaultFilters = [
    new TextFilter('Beneficiary ID', 'beneficiaryId', ''),
    new MultiSelectFilter('User', 'managerId', []),
    new MultiSelectFilter('Vendor Nickname', 'vendorId', []),
    new MultiSelectFilter('Partner Name', 'partnerId', []),
    new MultiSelectFilter('Parked by User', 'parkedByManagerId', []),
    new MultiSelectFilter('Posted by User', 'postedByManagerId', []),
    createActivityTypeFilter([]),
    new DateSelectFilter('Date', 'createdAt', '', PredefinedDateRanges, null, defaultDateLimit),
];

interface Props {
    activityLogs: PagedState<ActivityLog<any>>;
    auth: AuthState;
    filters: Filter[];
    vendorsNickNames: VendorNickName[];
    partnersNames: PartnerName[];
    managersNickNames: ManagerNickName[];
    loadPartnersNames: (sortOptions: SORT_OPTIONS) => void;
    loadManagersNickNames: () => void;
    loadVendorsNickNames: (sortOptions: SORT_OPTIONS) => void;
    loadActivityLogs: (timezone: string, page: number, limit: number, filters?: Array<Filter>) => void;
    saveFilters: (filters: Filter[], relatedTo: string) => void;
    timezone: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LocalState {
    defaultFilters: Filter[];
}

class OversightsPage extends React.Component<Props, LocalState> {
    defaultPageSize = 25;

    constructor(props) {
        super(props);
        this.state = {
            defaultFilters: defaultFilters,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            nextProps.vendorsNickNames !== this.props.vendorsNickNames ||
            nextProps.managersNickNames !== this.props.managersNickNames ||
            nextProps.partnersNames !== this.props.partnersNames
        ) {
            const vendorName = nextProps.vendorsNickNames.filter((name) => name.authorizedAt, 'vendorId');
            const partnerName = nextProps.partnersNames.filter((name) => name.authorizedAt, 'partnerId');
            const managerName = nextProps.managersNickNames.filter((name) => name.authorizedAt, 'managerId');
            let newFilters = updateFiltersWithNickNames(vendorName, this.props.filters, 'vendorId');
            newFilters = updateFiltersWithNickNames(partnerName, newFilters, 'partnerId');
            newFilters = updateFiltersWithNickNames(managerName, newFilters, 'managerId');
            newFilters = updateFiltersWithNickNames(managerName, newFilters, 'parkedByManagerId');
            newFilters = updateFiltersWithNickNames(managerName, newFilters, 'postedByManagerId');
            this.setState({
                defaultFilters: newFilters,
            });
            this.props.saveFilters(newFilters, FiltersTypes.activityLogs);
            this.props.loadActivityLogs(this.props.timezone, 1, this.defaultPageSize, this.props.filters);
        }
    }

    UNSAFE_componentWillMount() {
        this.props.loadActivityLogs(this.props.timezone, 1, this.defaultPageSize, this.props.filters);
        this.props.loadVendorsNickNames(defaultVendorSortingOrder);
        this.props.loadPartnersNames(defaultPartnerSortOptions);
        this.props.loadManagersNickNames();
    }

    private changePageRequested(newPage) {
        this.props.loadActivityLogs(this.props.timezone, newPage, this.defaultPageSize, this.props.filters);
    }

    private applyFilters(filters: Array<Filter>) {
        this.props.loadActivityLogs(this.props.timezone, 1, this.defaultPageSize, filters);
        this.props.saveFilters(filters, FiltersTypes.activityLogs);
    }

    checkIfEntitiesListsAreFetched() {
        return (
            this.props.managersNickNames &&
            this.props.vendorsNickNames &&
            this.props.managersNickNames.length > 0 &&
            this.props.vendorsNickNames.length > 0
        );
    }

    render() {
        const { auth, activityLogs } = this.props;
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Oversight</span>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <h3 className="col-sm-6">Oversight</h3>
                    <div className="col-sm-6 tr">
                        {hasFeatureAccess(auth, ManagerPermission.operationActivityDownload) &&
                            activityLogs &&
                            activityLogs.items &&
                            activityLogs.items.length > 0 && (
                                <Link className="ml2" to="/oversight/download">
                                    <button className="wfp-btn" type="button">
                                        Download Activity Logs
                                    </button>
                                </Link>
                            )}
                    </div>
                </div>

                {activityLogs && this.checkIfEntitiesListsAreFetched() && (
                    <div>
                        <FiltersComponent
                            defaultFilters={this.state.defaultFilters}
                            filters={this.props.filters}
                            onApplyFilters={this.applyFilters.bind(this)}
                            withApply={true}
                            withIsSelected={true}
                        />
                        <OversightsTable
                            activityLogs={this.props.activityLogs}
                            managersNickNames={this.props.managersNickNames}
                            onChangePageRequested={this.changePageRequested.bind(this)}
                            partnersNames={this.props.partnersNames}
                            vendorsNickNames={this.props.vendorsNickNames}
                            withStatus={true}
                        />
                    </div>
                )}
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        activityLogs: state.activityLogs.activityLogs,
        auth: state.auth,
        filters: state.filters.find((x) => x.relatedTo === FiltersTypes.activityLogs)
            ? state.filters.find((x) => x.relatedTo === FiltersTypes.activityLogs).selectedFilters
            : defaultFilters,
        partnersNames: state.partners.nameList,
        vendorsNickNames: state.vendors.nickNameList,
        managersNickNames: state.managers.managerNickNameList,
        timezone: state.appConfig.timeZone,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadActivityLogs: bindActionCreators(ActionCreators.loadActivityLogs, dispatch),

        loadVendorsNickNames: bindActionCreators(VendorActionCreators.loadVendorsNickNames, dispatch),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        loadPartnersNames: bindActionCreators(PartnerActionCreators.loadPartnersNames, dispatch),

        saveFilters: bindActionCreators(FiltersCreators.saveFilters, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OversightsPage as any);
