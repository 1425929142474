import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import State from '../app/store/state';
import PagingComponent, { PagedState } from '../utils/paging';
import { ActionCreators, PaymentPendingAction, PaymentRequest, PaymentRequestStatus } from './paymentRequests';
import { AuthState } from '../login/auth';
import PaymentRequestsTable from './PaymentRequestsTable';
import { RowMenu } from '../utils/RowMenu';
import { EntitlementCurrencyConfig, PaymentConfig, SapConfig } from '../app/appConfig';
import { PaymentPeriods } from './PaymentPeriods';
import BreadCrumbs from '../utils/BreadCrumbs';

interface Props {
    auth: AuthState;
    payments: PagedState<PaymentRequest>;
    periodEnd: Date;
    categories: string[];
    sapConfig: SapConfig;
    pendingActionState: PagedState<PaymentPendingAction>;
    currency: EntitlementCurrencyConfig;
    paymentsConfig: PaymentConfig;
    activeEntitlement: string;

    loadPayments: (page: number, limit: number, periodEnd: Date, category: string) => void;
    changePaymentRequestStatus: (paymentRequestId: string, targetStatus: PaymentRequestStatus) => void;
    selectActiveEntitlement: (activeEntitlement: string) => void;
}

interface LocalState {
    visiblePeriodsOffset: number;
}

class PaymentRequestsPage extends React.Component<Props, LocalState> {
    private defaultPageSize = 25;
    constructor(props: Props) {
        super(props);

        this.state = {
            visiblePeriodsOffset: 0,
        };
    }

    componentDidMount() {
        this.setState((state) => ({
            ...state,
        }));
    }

    changePageRequested(newPage) {
        this.props.loadPayments(newPage, this.defaultPageSize, this.props.periodEnd, this.props.activeEntitlement);
    }

    changeCategoryTab(activeCategory) {
        this.props.selectActiveEntitlement(activeCategory);
        this.props.loadPayments(1, this.defaultPageSize, this.props.periodEnd, activeCategory);
    }

    render() {
        const { auth, payments, periodEnd, changePaymentRequestStatus, categories, sapConfig } = this.props;
        return (
            <main>
                <div className="col-sm-12">
                    <BreadCrumbs path="/payments" />
                </div>
                <div className="row">
                    <div className="col-sm-3 mt3">
                        <PaymentPeriods activeCategory={this.props.activeEntitlement} />
                    </div>
                    <div className="col-sm-9" style={{ marginBottom: 150 }}>
                        <RowMenu
                            activeTab={this.props.activeEntitlement}
                            onSelectTab={this.changeCategoryTab.bind(this)}
                            tabs={categories}
                        />
                        <PaymentRequestsTable
                            auth={auth}
                            category={this.props.activeEntitlement}
                            changePaymentRequestStatus={changePaymentRequestStatus}
                            currency={this.props.currency}
                            isSapModuleActive={sapConfig.isSapActive}
                            payments={payments.items}
                            periodEnd={periodEnd}
                        />
                        <PagingComponent
                            onPageChanged={this.changePageRequested.bind(this)}
                            paging={this.props.payments.paging}
                        />
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        auth: state.auth,
        payments: state.paymentRequests.requests,
        activeEntitlement: state.paymentRequests.activeEntitlement,
        periodEnd: state.paymentRequests.periodEnd,
        pendingActionState: state.paymentRequests.pendingActionState,
        categories: state.appConfig.entitlementsConfig.categories as string[],
        sapConfig: state.appConfig.sapConfig,
        currency: state.appConfig.entitlementCurrencyConfig,
        paymentsConfig: state.appConfig.paymentConfig,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadPayments: bindActionCreators(ActionCreators.loadPaymentRequests, dispatch),
        changePaymentRequestStatus: bindActionCreators(ActionCreators.changePaymentRequestStatus, dispatch),
        selectActiveEntitlement: bindActionCreators(ActionCreators.selectActiveEntitlement, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRequestsPage as any);
