import * as React from 'react';
import Switch from 'rc-switch';

import { VendorGeneralInfo, VendorStatuses } from './vendors';
import { Form, Input, ExternalValidationError, ValidationContext } from '../utils/inputs';
import { displayTime } from '../utils/utils';
import { stopPropagation } from '../utils/events';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { EntityType } from '../authorization/taskAuthorizations';
import { InformationDialog } from '../utils/Dialogs';
import { BigNumber } from '../utils/bigNumber';
import { DownloadPermissionsButton } from '../permissions-files/DownloadPermissionsButton';
import { ManagerPermission } from '../permission-profiles/permission';
import { PencilIcon } from '../icons/Pencil.svg';
import { ClearIcon } from '../icons/Clear.svg';
import styled from 'styled-components';

interface Props {
    authState: AuthState;
    vendorGeneralInfo: VendorGeneralInfo;
    validationError?: ExternalValidationError;
    parkEnabled: boolean;
    postEnabled: boolean;
    managerId: string;
    isNewVendor: boolean;

    onSaved: (Vendor, permissionsFile: File) => void;
    authorize: (Vendor) => void;
    cancelPark: (id: string) => void;
}

interface State {
    vendorGeneralInfo: VendorGeneralInfo;
    editChecked: boolean;
    hasPendingDetailsUpdate: boolean;
    permissionsFile: File;
    permissionsFileUrl: string;
}

const IconWrapper = styled.button`
    all: unset;
    display: flex;
    align-items: center;

    svg {
        width: 1.25rem;
        fill: lighten(#2a93fc, 25%);
        will-change: fill;
        transition: fill 130ms ease-in;
        cursor: pointer;

        &:hover {
            fill: #2a93fc;
        }
    }
`;

export default class VendorDetails extends React.Component<Props, State> {
    _validationContext = new ValidationContext();

    constructor(props: Props) {
        super(props);
        this.state = {
            vendorGeneralInfo: this.props.vendorGeneralInfo,
            editChecked: false,
            hasPendingDetailsUpdate: false,
            permissionsFile: null,
            permissionsFileUrl: '',
        };
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        if (props.vendorGeneralInfo.id) {
            this.setState({ vendorGeneralInfo: props.vendorGeneralInfo });
        }
        if (props.validationError) {
            this._validationContext.pushError(props.validationError);
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({ vendorGeneralInfo: this.props.vendorGeneralInfo });
    }

    renderInputField(options: {
        readonly: boolean;
        value: string;
        name: string;
        required: boolean;
        type: string;
        autoFocused?: boolean;
        maxLength?: number;
    }) {
        return (
            <div className="col-sm-8">
                {options.readonly && <label className="normal">{options.value}</label>}
                {!options.readonly && (
                    <Input
                        className="form-control"
                        context={this._validationContext}
                        isFocused={options.autoFocused}
                        maxLength={options.maxLength}
                        name={options.name}
                        onChange={this._handleInputChange.bind(this)}
                        required={options.required}
                        type={options.type}
                        value={options.value}
                    />
                )}
            </div>
        );
    }

    thisManagerParked(parkedByManagerId) {
        return parkedByManagerId === this.props.managerId;
    }

    isCreateVendorPage() {
        return this.props.vendorGeneralInfo.id === 'new';
    }

    getNextAction(vendor: VendorGeneralInfo, postEnabled) {
        const cancellationText = 'Cancel';
        const cancelledText = 'Cancelled';
        return vendor.id ? (
            <div className="wfp-form--group row">
                <label className="col-sm-4 ta-right">Status</label>
                <div className="col-sm-8">
                    <label className="normal">
                        Parked at {displayTime(vendor.createdAt)}
                        {vendor.createdByManager && <div>by {vendor.createdByManager.name}</div>}{' '}
                    </label>
                    {vendor.authorizedByManager ? (
                        <label className="normal">
                            Posted at {displayTime(vendor.authorizedAt)}
                            {vendor.authorizedByManager && <div>by {vendor.authorizedByManager.name}</div>}
                        </label>
                    ) : vendor.cancelledByManagerId ? (
                        <label>{cancelledText}</label>
                    ) : (
                        <label className="normal">
                            Parked
                            {this.thisManagerParked(vendor.createdByManagerId) ? (
                                <span style={{ color: 'red' }}>*</span>
                            ) : (
                                postEnabled && (
                                    <span>
                                        <br />
                                        <a onClick={this._authorizeVendor.bind(this)}>Post</a>
                                    </span>
                                )
                            )}
                            <br />
                            <a onClick={stopPropagation(() => this.props.cancelPark(vendor.id))}>{cancellationText}</a>
                        </label>
                    )}
                    {this.thisManagerParked(vendor.createdByManagerId) &&
                        !this.props.vendorGeneralInfo.authorizedAt &&
                        !this.props.vendorGeneralInfo.cancelledByManagerId && (
                            <label>
                                {' '}
                                <span style={{ color: 'red' }}>*</span>You cannot post it, because you parked it.
                            </label>
                        )}
                </div>
            </div>
        ) : (
            <div />
        );
    }

    toggleEdit(editChecked) {
        // when entity updates are done, change this to show modal when trying to edit partner with pending request
        const { vendorGeneralInfo } = this.props;
        let hasPendingDetailsUpdate = false;
        if (vendorGeneralInfo && vendorGeneralInfo.pendingEntityUpdates) {
            const hasBeneficiaryDetailsEntity = vendorGeneralInfo.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.vendorDetails
            );
            hasPendingDetailsUpdate = hasBeneficiaryDetailsEntity.length !== 0;
        }
        if (hasPendingDetailsUpdate) {
            this.setState({ hasPendingDetailsUpdate: hasPendingDetailsUpdate });
        } else if (vendorGeneralInfo.authorizedAt) {
            this.setState({ editChecked: editChecked });
        }
    }

    renderSwitch() {
        const parkEnabled = hasFeatureAccess(this.props.authState, ManagerPermission.vendorsPark);
        const isEditSwitchVisible = parkEnabled && !this.isCreateVendorPage();
        if (isEditSwitchVisible) {
            return (
                <div className={'row'}>
                    <label className="col-sm-4 ta-right">Toggle Edit</label>
                    <div className="col-sm-8 pv1">
                        <Switch
                            checked={this.state.editChecked}
                            disabled={!this.state.vendorGeneralInfo.authorizedAt}
                            onClick={this.toggleEdit.bind(this)}
                        />
                    </div>
                </div>
            );
        }
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Vendor details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending Updates"
            />
        );
    }

    render() {
        const {
            sapId,
            name,
            nickName,
            sublocation,
            city,
            country,
            paymentDiscount,
            phoneNumber,
            email,
        } = this.state.vendorGeneralInfo;
        const { parkEnabled, postEnabled, isNewVendor } = this.props;
        const isReadonly = !isNewVendor && (!parkEnabled || !this.state.editChecked);
        return (
            <div>
                <h6>General Information</h6>
                {!isNewVendor && this.renderSwitch()}
                {this.state.hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}
                <Form className="wfp-form" context={this._validationContext} onSubmit={this._saveChanges.bind(this)}>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">WFP Vendor ID</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: sapId,
                            name: 'sapId',
                            required: true,
                            type: 'text',
                            autoFocused: true,
                            maxLength: 35,
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Business Name</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: name,
                            name: 'name',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Nickname</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: nickName,
                            name: 'nickName',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Sublocation</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: sublocation,
                            name: 'sublocation',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">City</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: city,
                            name: 'city',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Country</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: country,
                            name: 'country',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Phone number</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: phoneNumber,
                            name: 'phoneNumber',
                            required: false,
                            type: 'text',
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Email</label>
                        {this.renderInputField({
                            readonly: isReadonly,
                            value: email,
                            name: 'email',
                            required: false,
                            type: 'email',
                        })}
                    </div>
                    {isNewVendor && (
                        <>
                            <div className="wfp-form--group row">
                                <label className="col-sm-4 ta-right">Payment Discount(%)</label>
                                <div className="col-sm-8">
                                    <Input
                                        className="form-control"
                                        context={this._validationContext}
                                        errorMessages={{
                                            min: 'Payment Discount must be positive',
                                            max: 'Payment Discount must be less than 10',
                                        }}
                                        max={10.0}
                                        min={0}
                                        name="paymentDiscount"
                                        onChange={this._handlePaymentSavingInputChange.bind(this)}
                                        required={true}
                                        step={0.1}
                                        type="number"
                                        value={new BigNumber(paymentDiscount).multipliedBy(100).toString()}
                                    />
                                </div>
                            </div>
                            <div className="wfp-form--group row">
                                <label className="col-sm-4 ta-right">Permissions File</label>
                                {this.state.permissionsFile ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <a
                                            href={this.state.permissionsFileUrl}
                                            rel="noreferrer"
                                            style={{ margin: '0 16px' }}
                                            target="_blank"
                                        >
                                            {this.state.permissionsFile?.name}
                                        </a>
                                        <IconWrapper type="button">
                                            <label htmlFor="change-permissions-file">
                                                <PencilIcon />
                                                <input
                                                    accept="image/png, image/jpeg, application/pdf"
                                                    hidden
                                                    id="change-permissions-file"
                                                    name="change-permissions-file"
                                                    onChange={this.handlePermissionFileChange}
                                                    type="file"
                                                />
                                            </label>
                                        </IconWrapper>
                                        <IconWrapper onClick={this.clearPermissionFile} type="button">
                                            <ClearIcon />
                                        </IconWrapper>
                                    </div>
                                ) : (
                                    <button className="wfp-btn col-sm-4 p-0" style={{ margin: '0 16px' }} type="button">
                                        <label
                                            className="mb-0 w-100 cursor-pointer"
                                            htmlFor="permissions-file"
                                            style={{ padding: '8px 16px' }}
                                        >
                                            Upload Permissions
                                            <input
                                                accept="image/png, image/jpeg, application/pdf"
                                                hidden
                                                id="permissions-file"
                                                name="permissions-file"
                                                onChange={this.handlePermissionFileChange}
                                                type="file"
                                            />
                                        </label>
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                    {this.props.vendorGeneralInfo.id && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Panel Access</label>
                            <div className="col-sm-8 pv1">
                                {
                                    <Switch
                                        checked={
                                            this.state.vendorGeneralInfo.panelAccessStatus === VendorStatuses.active
                                        }
                                        disabled={!parkEnabled || !this.state.editChecked}
                                        onChange={this._handleActiveChange.bind(this, 'panelAccessStatus')}
                                    />
                                }
                            </div>
                        </div>
                    )}
                    {this.props.vendorGeneralInfo.id && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Executing transactions</label>
                            <div className="col-sm-8 pv1">
                                {
                                    <Switch
                                        checked={
                                            this.state.vendorGeneralInfo.transactionsExecutingStatus ===
                                            VendorStatuses.active
                                        }
                                        disabled={!parkEnabled || !this.state.editChecked}
                                        onChange={this._handleActiveChange.bind(this, 'transactionsExecutingStatus')}
                                    />
                                }
                            </div>
                        </div>
                    )}
                    {this.getNextAction(this.state.vendorGeneralInfo, postEnabled)}

                    {parkEnabled &&
                        (this.state.editChecked || isNewVendor) &&
                        hasFeatureAccess(this.props.authState, ManagerPermission.vendorsPark) && (
                            <div className="wfp-form--actions text-center">
                                <div className="col-sm-8 col-sm-offset-4">
                                    <button
                                        className="wfp-btn--primary"
                                        disabled={!this.state.editChecked && !isNewVendor}
                                        type="submit"
                                    >
                                        {this.state.vendorGeneralInfo.id ? 'Park changes' : 'Park'}
                                    </button>
                                </div>
                            </div>
                        )}
                    {this.state.vendorGeneralInfo.id && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Permissions File</label>
                            <span className="col-sm-8">
                                {this.state.vendorGeneralInfo.permissionsFiles &&
                                this.state.vendorGeneralInfo.permissionsFiles.length ? (
                                    <DownloadPermissionsButton
                                        asyncTaskId={this.state.vendorGeneralInfo.permissionsFiles[0].id}
                                        fileName={this.state.vendorGeneralInfo.permissionsFiles[0].fileName}
                                        hasAccess={this.state.vendorGeneralInfo.permissionsFiles[0].hasAccess}
                                    />
                                ) : (
                                    'No File'
                                )}
                            </span>
                        </div>
                    )}
                </Form>
            </div>
        );
    }

    handlePermissionFileChange = ({ target }) => {
        this.setState({
            ...this.state,
            permissionsFile: target.files[0],
            permissionsFileUrl: URL.createObjectURL(target.files[0]),
        });
    };

    clearPermissionFile = () => {
        this.setState({
            ...this.state,
            permissionsFile: null,
            permissionsFileUrl: '',
        });
    };

    _handleActiveChange(field, checked) {
        const newStatus = checked ? VendorStatuses.active : VendorStatuses.blocked;
        const newVendorGeneralInfo = this.state.vendorGeneralInfo;
        newVendorGeneralInfo[field] = newStatus;
        this.setState({ vendorGeneralInfo: newVendorGeneralInfo });
    }

    private _handleInputChange({ target }) {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const newVendorGeneralInfo = this.state.vendorGeneralInfo;
        newVendorGeneralInfo[target.name] = value;
        this.setState({ vendorGeneralInfo: newVendorGeneralInfo });
    }

    private _handlePaymentSavingInputChange({ target }) {
        const value = target.value;
        const newVendorGeneralInfo = this.state.vendorGeneralInfo;
        newVendorGeneralInfo[target.name] = new BigNumber(value).dividedBy(100).toString();
        this.setState({ vendorGeneralInfo: newVendorGeneralInfo });
    }

    private _authorizeVendor(event) {
        this.props.authorize(this.state.vendorGeneralInfo.id);
        event.preventDefault();
    }

    private _saveChanges(event) {
        this.props.onSaved(this.state.vendorGeneralInfo, this.state.permissionsFile);
        event.preventDefault();
    }
}
