import * as React from 'react';
import { Outlets } from './outlets.component';
import styled from 'styled-components';
import { Outlet } from './types/outlet.type';
import { ChangeSortOptions } from '../utils/hooks/useSort';

interface Props {
    outlets: Record<string, Outlet[]>;
    onSort: ChangeSortOptions;
}

export function OutletList({ outlets, onSort }: Props) {
    const outletsArray = Object.entries(outlets || {});
    return (
        <Container>
            <table className="wfp-table">
                {outletsArray.length ? (
                    <>
                        <Outlets onSort={onSort} outlets={outletsArray} />
                    </>
                ) : (
                    <tr>
                        <td colSpan={7}>
                            <p>
                                <strong>No outlets found</strong>
                            </p>
                        </td>
                    </tr>
                )}
            </table>
        </Container>
    );
}
const Container = styled.div`
    display: grid;
    padding-bottom: 1rem;
    table {
        table-layout: fixed;
        .col-width {
            width: 300px;
        }
        thead tr th {
            background: rgba(238, 246, 255, 1);
        }
    }
    h6 {
        color: rgba(55, 55, 55, 0.8);
        font-size: 1rem;
        font-weight: 700;
    }
`;
