import * as React from 'react';
import { browserHistory, IndexRedirect, IndexRoute, Route } from 'react-router';

import AppContainer from './app/AppContainer';
import ErrorPage from './app/ErrorPage';
import HomePage from './home/HomePage';
import LoginPage from './login/LoginPage';
import VendorsPage from './vendors/VendorsPage';
import VendorEditPage from './vendors/VendorEditPage';
import VendorExportPage from './vendors/VendorExportPage';
import PaymentRequestsPage from './paymentRequests/PaymentRequestsPage';
import PaymentRequestDetailsPage from './paymentRequests/PaymentRequestDetailsPage';
import BeneficiaryExportPage from './beneficiaries/BeneficiaryExportPage';
import AsyncTaskDetailsView from './utils/AsyncTaskDetailsView';
import BeneficiariesPage from './beneficiaries/BeneficiariesPage';
import EditBeneficiaryPage from './beneficiaries/edit/EditBeneficiaryPage';
import TransactionsPage from './transactions/TransactionsPage';
import TransactionsExportPage from './transactions/TransactionsExportPage';
import SummaryStatisticsPage from './statistics/summaryStatistics/SummaryStatisticsPage';
import SecurityPage from './security/SecurityPage';
import ManagersMainPage from './managers/ManagersMainPage';
import ManagerEditPage from './managers/ManagerEditPage';
import ManagerEditPersonalPage from './managers/ManagerEditPersonalPage';
import DocumentsPage from './documents/DocumentsPage';
import TaskAuthorizationsPage, {
    adjustmentsRedirectPath,
    AuthorizationTab,
} from './authorization/TaskAuthorizationsPage';
import { ActionCreators, ActionTypes, hasFeatureAccess } from './login/auth';
import TransactionDetailsPage from './transactions/TransactionChainPage';
import ForceResetPasswordPage from './security/ForceResetPasswordPage';
import EntitlementsView from './beneficiaries/EntitlementsView';
import BeneficiariesTokens from './beneficiaries/BeneficiariesTokens';
import BeneficiaryPendingActions from './beneficiaries/BeneficiaryPendingActions';
import { AdjustmentTransactions, ExpendituresTransactions } from './beneficiaries/BeneficiaryTransactions';
import BeneficiaryPersonalDetails from './beneficiaries/BeneficiaryPersonalDetails';
import BeneficiaryActivitiesPage from './beneficiaries/BeneficiaryActivitiesPage';
import TransactionAuthorizationPage from './authorization/TransactionAuthorizationPage';
import EntityUpdateAuthorizationPage from './authorization/EntityUpdateAuthorizationPage';
import BeneficiaryImportPage from './beneficiaries/fileUploadPages/BeneficiaryMassImport';
import AlternativeCollectorsImportPage from './beneficiaries/fileUploadPages/BeneficiaryAlternativeCollectorsImport';
import BeneficiaryMassUnblock from './beneficiaries/fileUploadPages/BeneficiaryMassUnblock';
import MassBlockWithoutZeroing from './beneficiaries/fileUploadPages/BeneficiaryMassBlock';
import BeneficiaryMassZeroing from './beneficiaries/fileUploadPages/BeneficiaryMassZeroing';
import BeneficiaryActivityLogs from './beneficiaries/BeneficiaryActivityLogs';
import State from './app/store/state';
import { AnyAction, Store } from 'redux';
import ManagerLogExport from './activityLogs/MenagerActivityLogExport';
import BeneficiaryTotals from './beneficiaries/BeneficiaryTotals';
import BeneficiaryAlerts from './beneficiaries/BeneficiaryAlerts';
import BeneficiaryLogExport from './activityLogs/BeneficiaryActivityLogExport';
import OversightsPage from './oversights/OversightsPage';
import ActivityLogsDetailsView from './activityLogs/details/ActivityLogsDetailsView';
import ManagerExportPage from './managers/ManagerExportPage';
import ManagersPage from './managers/ManagersPage';
import ActivityLogView from './managers/ActivityLogView';
import OversightsExportPage from './oversights/OversightsExportPage';
import ResetOtpPage from './login/ResetOtpPage';
import BeneficiaryRemoveAlternativeCollectors from './beneficiaries/fileUploadPages/BeneficiaryRemoveAlternativeCollectors';
import VendorUserList from './vendors/VendorUserList';
import VendorUserAdd from './vendors/VendorUserDetails';
import VendorUserDetails from './vendors/VendorUserDetails';
import VendorPayment from './vendors/VendorPayment';
import { ActivityLogsList } from './activityLogs/ActivityLogsList';
import VendorDetails from './vendors/VendorDetails';
import { getAppConfig, getMyself, getPendingActions, healthCheck } from './apiClient';
import { ThunkDispatch } from 'redux-thunk';
import { errorAction } from './utils/notifications';
import { AppConfigActionTypes } from './app/appConfig';
import { addInactivityListeners, removeInactivityAlertListeners } from './utils/inactivityDetection';
import { healthCheckRefresher } from './utils/refresher';
import AsyncTasksWithRetriesDetailsView from './utils/AsyncTasksWithRetriesDetailsView';
import PartnersPage from './partners/PartnersPage';
import PartnerEditPage from './partners/PartnerEditPage';
import PartnerDetails from './partners/PartnerDetails';
import PartnerAddPage from './partners/PartnerAddPage';
import { PartnerUsersList } from './partners/PartnerUsersList';
import { PartnerUserAddPage } from './partners/PartnerUserAddPage';
import { PartnerUserDetails } from './partners/PartnerUserDetails';
import LocationDetailsPage from './beneficiaries/locations/LocationDetailsPage';
import CreateLocationPage from './beneficiaries/locations/CreateLocationPage';
import LocationsPage from './beneficiaries/locations/LocationsPage';
import { MobilizationStatisticsPage } from './statistics/mobilizationStatistics/MobilizationStatisticsPage';
import { StatisticsPage } from './statistics/StatisticsPage';
import { UploadDocumentsForm } from './documents/UploadDocumentsForm';
import { ProductsDetails } from './products/ProductsDetails';
import { ProductsVendors } from './products/ProductsVendors';
import { ProductsAdd } from './products/ProductsAdd';
import { ProductsPricesDetails } from './products/ProductsPricesDetails';
import { ProductsPricesAdd } from './products/ProductsPricesAdd';
import { ProductsPageContainer } from './products/ProductsPageContainer';
import VendorBranches from './vendors/VendorBranches';
import VendorBranchesAdd from './vendors/VendorBranchesAdd';
import { VendorActivityLogExport } from './activityLogs/VendorActivityLogExport';
import { PartnerActivityLogsList } from './partners/PartnerActivityLogsList';
import { ProductUpdatePage } from './product-management/product-group-update/product-update.page';
import { ProductManagementAuthorizationPage } from './authorization/product-management/product-management-authorization.container';
import { VendorPermissionsFiles } from './vendors/VendorPermissionsFiles';
import { PermissionProfilesPage } from './permission-profiles/PermissionProfilesPage';
import { AddPermissionProfilePage } from './permission-profiles/AddPermissionProfilePage';
import { windowStorage, WindowStorageToken } from './windowStorage';
import { PartnerPermissionsFiles } from './partners/PartnerPermissionsFiles';
import { BeneficiaryLimitations } from './beneficiaries/BeneficiaryLimitations/BeneficiaryLimitations';
import { ManagerPermission } from './permission-profiles/permission';
import { BeneficiaryPinExportPage } from './beneficiaries/BeneficiaryPinExportPage';
import { useNavigate } from '@wfp-common/hooks/useNavigate';
import BeneficiaryMapping from './beneficiaries/fileUploadPages/BeneficiaryMapping';

export const sessionAutoExpirationMessage = 'The previous session time has auto-expired.';

function loadOnEnter(store: Store<State>): Promise<any> {
    return getMyself().then((res) => {
        store.dispatch({
            type: ActionTypes.loadedSelf,
            payload: res.manager,
        });

        addInactivityListeners(store, res.manager);

        return getAppConfig()
            .then((res) => {
                store.dispatch({
                    type: AppConfigActionTypes.loadAppConfig,
                    payload: res,
                });

                return getPendingActions().then((res) => {
                    const permissions = store.getState().auth.manager.profile?.permissions;
                    store.dispatch({
                        type: ActionTypes.loadedPendingActions,
                        payload: Object.assign({}, { pendingActions: res }, { permissions }),
                    });
                    return res;
                });
            })
            .catch((err) => {
                store.dispatch(errorAction(err));
                return err;
            });
    });
}

// eslint-disable-next-line react/display-name
export default (store: Store<State>) => {
    const dispatch = store.dispatch as ThunkDispatch<State, void, AnyAction>;

    const requireUser = (nextState, replace, cb) => {
        const token = store.getState().auth.apiToken;

        if (token) {
            const logOutOnHealthCheckOnError = async () => {
                await dispatch(ActionCreators.logout());
                dispatch(errorAction(sessionAutoExpirationMessage));
            };
            healthCheckRefresher(() => healthCheck(), logOutOnHealthCheckOnError).catch((err) =>
                // eslint-disable-next-line no-console
                console.log('Failed to start health checker', err)
            );

            loadOnEnter(store)
                .then((res) => {
                    cb();
                    return res;
                })
                .catch((err) => {
                    removeInactivityAlertListeners();
                    store.dispatch(errorAction(err));
                    windowStorage.removeItem(WindowStorageToken.managerApiToken);
                    store.dispatch({
                        type: ActionTypes.loginFailed,
                        payload: {},
                    });
                    replace('/login');
                    cb();
                    return err;
                });
            return;
        }

        if (!token || !store.getState().auth.manager) {
            const currentLocation = browserHistory.getCurrentLocation();
            const fullPath = currentLocation.pathname + currentLocation.search;
            replace(`/login?redirectUrl=${encodeURIComponent(fullPath)}`);
        }
        cb();
        if (store.getState().auth.manager) {
            addInactivityListeners(store, store.getState().auth.manager);
        }
    };

    const redirectIfLoggedIn = (nextState, replace, cb) => {
        const auth = store.getState().auth;
        const token = auth.apiToken;

        if (token) {
            loadOnEnter(store)
                .then((res) => {
                    replace('/home');
                    cb();
                    return res;
                })
                .catch((err) => {
                    removeInactivityAlertListeners();
                    store.dispatch(errorAction(err));
                    windowStorage.removeItem(WindowStorageToken.managerApiToken);
                    store.dispatch({
                        type: ActionTypes.loginFailed,
                        payload: {},
                    });
                    replace('/login');
                    cb();
                    return err;
                });
        } else {
            cb();
        }
    };

    const redirectIfOutdatedPassword = (nextState, replace, cb) => {
        if (store.getState().auth.manager?.askForNewPassword) {
            replace('/change-password');
        }
        cb();
    };

    const restrictedToFeatures = (features: ManagerPermission[], redirectPath = '/home-basic') => (
        nextState,
        replace,
        cb
    ) => {
        if (store.getState().auth.manager) {
            const auth = store.getState().auth;
            let isAllowed = false;
            for (const feature of features) {
                if (hasFeatureAccess(auth, feature)) {
                    isAllowed = true;
                    break;
                }
            }
            if (!isAllowed) {
                replace(redirectPath);
            }
        }
        cb();
    };

    const checkForBeneficiaryId = (nextState, replace, cb) => {
        const state = nextState.location.state;
        if (state && state.beneficiaryId) {
            cb();
        } else {
            replace('/beneficiaries');
            cb();
        }
    };

    const checkForEntityId = (redirectPath: string) => (nextState, replace, cb) => {
        const state = nextState.location.state;
        if (state && state.entityId) {
            cb();
        } else {
            replace(redirectPath);
            cb();
        }
    };
    const checkForLocationFeatureToggle = (redirectPath: string) => (nextState, replace, cb) => {
        const featureToggle = store.getState().appConfig.location.isActive;

        if (featureToggle) {
            cb();
        } else {
            const navigate = useNavigate();
            navigate(redirectPath);
            cb();
        }
    };

    return (
        <Route>
            <Route component={AppContainer} path="/">
                <IndexRedirect to="home" />
                <Route component={LoginPage} onEnter={redirectIfLoggedIn} path="login" />
                <Route component={ResetOtpPage} onEnter={redirectIfLoggedIn} path="set-otp" />

                <Route onChange={() => dispatch(ActionCreators.getPendingActions())} onEnter={requireUser}>
                    <Route component={ForceResetPasswordPage} path="change-password" />
                    <Route onEnter={redirectIfOutdatedPassword}>
                        <Route
                            component={StatisticsPage}
                            onEnter={restrictedToFeatures([ManagerPermission.viewAndDownloadSummaryReports])}
                            path="home"
                        />
                        <Route component={HomePage} path="home-basic" />

                        <Route onEnter={restrictedToFeatures([ManagerPermission.vendorsRead])} path="vendors">
                            <IndexRoute component={VendorsPage} />
                            <Route
                                component={VendorExportPage}
                                onEnter={restrictedToFeatures([ManagerPermission.vendorsDownload])}
                                path="download"
                            />
                            <Route component={VendorEditPage} path=":vendorId">
                                <IndexRedirect to="general" />
                                <Route component={VendorDetails} path={'general'} />
                                <Route component={VendorPayment} path={'payment'} />
                                <Route component={ActivityLogsList} path={'activity'} />
                                <Route component={VendorUserList} path="panel" />
                                <Route path="panel">
                                    <Route component={VendorUserAdd} path="add-user" />
                                    <Route component={VendorUserDetails} path=":userId" />
                                </Route>
                                {/*<Route component={VendorProductList} path="products" />*/}
                                <Route component={VendorBranches} path="branches" />
                                <Route path="branches">
                                    <Route component={VendorBranchesAdd} path="add" />
                                </Route>
                                <Route component={VendorActivityLogExport} path="export-activity-log" />
                                <Route component={VendorPermissionsFiles} path="permission-files" />
                            </Route>
                        </Route>

                        <Route
                            component={DocumentsPage}
                            onEnter={restrictedToFeatures([ManagerPermission.managersManagement])}
                            path="documents"
                        />

                        <Route component={UploadDocumentsForm} path="documents/create" />

                        <Route onEnter={restrictedToFeatures([ManagerPermission.partnersRead])} path="partners">
                            <IndexRoute component={PartnersPage} />
                            <Route
                                component={PartnerAddPage}
                                onEnter={restrictedToFeatures([ManagerPermission.partnerPark])}
                                path="new"
                            />
                            <Route component={PartnerEditPage} path=":partnerId">
                                <IndexRedirect to="general" />
                                <Route component={PartnerDetails} path={'general'} />
                                <Route component={PartnerActivityLogsList} path={'activity'} />
                                <Route component={PartnerUsersList} path="panel" />
                                <Route component={PartnerUserAddPage} path="panel/add-user" />
                                <Route component={PartnerUserDetails} path="panel/:partnerUserId" />
                                <Route component={PartnerPermissionsFiles} path="permission-files" />
                            </Route>
                        </Route>

                        <Route
                            component={ManagersPage}
                            onEnter={restrictedToFeatures([ManagerPermission.managersManagement])}
                            path="admin"
                        />
                        <Route
                            component={ManagerExportPage}
                            onEnter={restrictedToFeatures([ManagerPermission.userDownload])}
                            path="admin/download"
                        />
                        <Route
                            component={PermissionProfilesPage}
                            onEnter={restrictedToFeatures([
                                ManagerPermission.profilesRead,
                                ManagerPermission.profilesPark,
                            ])}
                            path="admin/permission-profiles"
                        />
                        <Route
                            component={AddPermissionProfilePage}
                            onEnter={restrictedToFeatures([
                                ManagerPermission.profilesRead,
                                ManagerPermission.profilesPark,
                            ])}
                            path="admin/permission-profiles/new"
                        />
                        <Route
                            component={PermissionProfilesPage}
                            onEnter={restrictedToFeatures([
                                ManagerPermission.profilesRead,
                                ManagerPermission.profilesPark,
                            ])}
                            path="admin/permission-profiles/:id"
                        />
                        <Route path="admin/:managerId">
                            <IndexRoute
                                component={ManagerEditPage}
                                onEnter={restrictedToFeatures([ManagerPermission.managersManagement])}
                            />
                            <Route
                                component={ManagerLogExport}
                                onEnter={restrictedToFeatures([ManagerPermission.userActivityDownload])}
                                path="export-activity-log"
                            />
                        </Route>

                        <Route component={ManagersMainPage} path={'personal'}>
                            <IndexRedirect to="details" />
                            <Route component={ManagerEditPersonalPage} path="details" />
                            <Route component={SecurityPage} path="security" />
                            <Route component={ActivityLogView} path="activity-log" />
                        </Route>

                        <Route
                            onEnter={restrictedToFeatures([ManagerPermission.beneficiariesRead])}
                            path="beneficiaries"
                        >
                            <IndexRoute component={BeneficiariesPage} />
                            <Route component={MobilizationStatisticsPage} path="mobilization-statistics" />
                            <Route
                                component={BeneficiaryExportPage}
                                onEnter={restrictedToFeatures([ManagerPermission.beneficiariesDownload])}
                                path="download"
                            />
                            <Route
                                component={BeneficiaryMapping}
                                onEnter={restrictedToFeatures([ManagerPermission.beneficiariesRead])}
                                path="mapping"
                            />
                            <Route
                                component={BeneficiaryPinExportPage}
                                onEnter={restrictedToFeatures([ManagerPermission.beneficiariesPinManagement])}
                                path="export-pins"
                            />
                            <Route path="import">
                                <IndexRoute component={BeneficiaryImportPage} />
                                <Route component={AlternativeCollectorsImportPage} path="alternative-collectors" />
                                <Route
                                    component={BeneficiaryRemoveAlternativeCollectors}
                                    path="remove-alternative-collectors"
                                />
                            </Route>

                            <Route onEnter={checkForLocationFeatureToggle('/home')} path="locations">
                                <IndexRoute component={LocationsPage} />
                                <Route component={CreateLocationPage} path="new" />
                                <Route component={LocationDetailsPage} path=":locationId" />
                            </Route>

                            <Route component={MassBlockWithoutZeroing} path="block" />
                            <Route component={BeneficiaryMassZeroing} path="zeroing" />
                            <Route component={BeneficiaryMassUnblock} path="unblock" />
                            <Route onEnter={checkForEntityId('/beneficiaries')} path="export-activity-log">
                                <IndexRoute
                                    component={BeneficiaryLogExport}
                                    onEnter={restrictedToFeatures([ManagerPermission.operationActivityDownload])}
                                />
                            </Route>
                            <Route component={EditBeneficiaryPage} onEnter={checkForBeneficiaryId} path="beneficiary">
                                <IndexRedirect to="main" />
                                <Route component={BeneficiaryActivitiesPage} path="main" />
                                <Route component={BeneficiaryPersonalDetails} path="details" />
                                <Route component={EntitlementsView} path="entitlements" />
                                <Route component={BeneficiariesTokens} path="tokens" />
                                <Route component={BeneficiaryPendingActions} path="pending-actions" />
                                <Route component={AdjustmentTransactions} path="adjustments" />
                                <Route component={ExpendituresTransactions} path="expenditures" />
                                <Route
                                    component={BeneficiaryLimitations}
                                    onEnter={restrictedToFeatures([ManagerPermission.productsLimitationRead])}
                                    path="limitations"
                                />
                                <Route
                                    component={BeneficiaryTotals}
                                    onEnter={restrictedToFeatures([ManagerPermission.transactionRequestRead])}
                                    path="totals"
                                />
                                <Route
                                    component={BeneficiaryAlerts}
                                    onEnter={restrictedToFeatures([ManagerPermission.transactionRequestRead])}
                                    path="alerts"
                                />
                                <Route
                                    component={BeneficiaryActivityLogs}
                                    onEnter={restrictedToFeatures([ManagerPermission.beneficiariesRead])}
                                    path="activity-logs"
                                />
                            </Route>
                        </Route>

                        <Route
                            onEnter={restrictedToFeatures([ManagerPermission.transactionRequestRead])}
                            path="transactions"
                        >
                            <IndexRoute component={TransactionsPage} />
                            <Route
                                component={TransactionsExportPage}
                                onEnter={restrictedToFeatures([ManagerPermission.transactionsDownload])}
                                path="download"
                            />
                            <Route component={TransactionDetailsPage} path=":transactionId" />
                        </Route>

                        <Route
                            component={TaskAuthorizationsPage}
                            onEnter={restrictedToFeatures([ManagerPermission.adjustmentsView])}
                            path="adjustments"
                        >
                            <IndexRoute
                                onEnter={(nextState, replace) => replace(adjustmentsRedirectPath(store.getState()))}
                            />
                            <Route
                                component={TransactionAuthorizationPage}
                                onEnter={restrictedToFeatures([ManagerPermission.transactionRequestRead])}
                                path="transactions"
                            />
                            <Route
                                component={() => <EntityUpdateAuthorizationPage tab={AuthorizationTab.Beneficiaries} />}
                                onEnter={restrictedToFeatures([ManagerPermission.beneficiariesRead])}
                                path="beneficiaries"
                            />
                            <Route
                                component={() => (
                                    <EntityUpdateAuthorizationPage tab={AuthorizationTab.VendorsDetails} />
                                )}
                                onEnter={restrictedToFeatures([ManagerPermission.vendorsRead])}
                                path="vendors"
                            />
                            <Route
                                component={() => (
                                    <EntityUpdateAuthorizationPage tab={AuthorizationTab.ManagerDetails} />
                                )}
                                onEnter={restrictedToFeatures([ManagerPermission.managersManagement])}
                                path="users"
                            />
                            {/*<Route path="pre-parked-beneficiaries"*/}
                            {/*onEnter={restrictedToFeatures([ManagerPermission.beneficiariesRead])}*/}
                            {/*component={() => <EntityUpdateAuthorizationPage*/}
                            {/*tab={AuthorizationTab.PreParkedBeneficiaries}/>}/>*/}
                            <Route
                                component={() => (
                                    <EntityUpdateAuthorizationPage tab={AuthorizationTab.VendorsUsersDetails} />
                                )}
                                onEnter={restrictedToFeatures([ManagerPermission.vendorsRead])}
                                path="vendor-users"
                            />
                            <Route
                                component={() => (
                                    <EntityUpdateAuthorizationPage tab={AuthorizationTab.PartnersDetails} />
                                )}
                                onEnter={restrictedToFeatures([ManagerPermission.partnersRead])}
                                path="partners"
                            />
                            <Route
                                component={() => (
                                    <EntityUpdateAuthorizationPage tab={AuthorizationTab.PartnersUsersDetails} />
                                )}
                                onEnter={restrictedToFeatures([ManagerPermission.partnersRead])}
                                path="partner-users"
                            />
                            <Route
                                component={() => <EntityUpdateAuthorizationPage tab={AuthorizationTab.Locations} />}
                                path="locations"
                            />
                            <Route
                                component={ProductManagementAuthorizationPage}
                                onEnter={restrictedToFeatures([ManagerPermission.productsRead])}
                                path="products"
                            />
                            <Route
                                component={() => (
                                    <EntityUpdateAuthorizationPage tab={AuthorizationTab.PermissionProfiles} />
                                )}
                                onEnter={restrictedToFeatures([ManagerPermission.managersManagement])}
                                path="permission-profiles"
                            />
                        </Route>

                        <Route
                            onEnter={restrictedToFeatures([ManagerPermission.operationActivityView])}
                            path="oversight"
                        >
                            <IndexRoute component={OversightsPage} />
                            <Route
                                component={OversightsExportPage}
                                onEnter={restrictedToFeatures([ManagerPermission.operationActivityDownload])}
                                path={'download'}
                            />
                        </Route>

                        <Route component={AsyncTaskDetailsView} path="tasks/:type/:taskId" />
                        <Route
                            component={AsyncTasksWithRetriesDetailsView}
                            path="subtasks/:parentId/:subtaskCategory"
                        />

                        <Route
                            component={ActivityLogsDetailsView}
                            onEnter={restrictedToFeatures([
                                ManagerPermission.operationActivityView,
                                ManagerPermission.userActivityView,
                            ])}
                            path="activity-logs/:logId"
                        />
                        <Route
                            onEnter={restrictedToFeatures([ManagerPermission.transactionRequestRead])}
                            path="summary-statistics"
                        >
                            <IndexRoute component={SummaryStatisticsPage} />
                        </Route>

                        <Route onEnter={restrictedToFeatures([ManagerPermission.paymentsRead])} path="payments">
                            <IndexRoute component={PaymentRequestsPage} />
                            <Route component={PaymentRequestDetailsPage} path=":requestId" />
                        </Route>

                        <Route
                            onEnter={restrictedToFeatures([ManagerPermission.productsRead], '/home')}
                            path="products"
                        >
                            <IndexRoute component={ProductsPageContainer} />
                            <Route component={ProductsVendors} path={'vendors'} />
                            <Route
                                component={ProductsAdd}
                                onEnter={restrictedToFeatures([ManagerPermission.productsPark], '/products')}
                                path={'add'}
                            />
                            <Route
                                component={ProductUpdatePage}
                                onEnter={restrictedToFeatures([ManagerPermission.productsPark], '/products')}
                                path="update/:groupName"
                            />
                            <Route path=":productId">
                                <IndexRedirect to="general" />
                                <Route component={ProductsDetails} path={'general'} />
                                <Route path={'prices'}>
                                    <IndexRedirect to="general" />
                                    <Route
                                        component={ProductsPricesAdd}
                                        onEnter={restrictedToFeatures([ManagerPermission.productsPark], '/products')}
                                        path={'add'}
                                    />
                                    <Route component={ProductsPricesDetails} path=":priceId" />
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route component={ErrorPage} path="*" />
                </Route>
            </Route>
        </Route>
    );
};
