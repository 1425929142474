import * as React from 'react';
import { EntityUpdateRequestContext } from '../entity-update-request/entity-update-request.context';
import { EntityType } from '../taskAuthorizations';
import { AuthorizationRowMenu } from '../entity-update-request/authorization-row-menu';
import ProductManagementAuthorizationListComponent from './product-management-authorization-list.component';
import { ProductManagementAuthorizationModalsComponent } from './product-management-authorization-modals.component';

export function ProductManagementAuthorizationPage() {
    return (
        <EntityUpdateRequestContext tab={EntityType.productManagement}>
            <ProductManagementAuthorizationModalsComponent />
            <AuthorizationRowMenu />
            <ProductManagementAuthorizationListComponent />
            <Pagination />
        </EntityUpdateRequestContext>
    );
}

function Pagination() {
    // todo: implement
    return null;
}
