import * as React from 'react';

// eslint-disable-next-line react/display-name
export default class extends React.Component<any, any> {
    render() {
        return (
            <main>
                <h3>Error</h3>
                <p>Page not found</p>
            </main>
        );
    }
}
