import * as React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import State from '../app/store/state';
import { bindActionCreators } from 'redux';
import { ActionCreators as AsyncActionCreators, AsyncTask, AsyncTasksTypes } from '../utils/asyncTasks';
import { RefreshSignaler } from '../utils/refresher';
import { Filter } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';
import { ActionCreators } from '../activityLogs/activityLogs';
import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';

interface Props {
    oversightFilters: Array<Filter>;
    managerId: string;
    exports: PagedState<AsyncTask>;
    oversightsTotalCount: number;
    loadAsyncTasksList: (page: number, limit: number, type: string) => Promise<RefreshSignaler>;
    appendAsyncTasksList: (limit: number, type: string) => void;
    exportOversights: (mapping: Array<{ key; header }>, filters: any) => Promise<any>;
    loadActivityLogs: (page: number, limit: number, filters?: Array<Filter>) => void;
    downloadFile: (type: string, id: string) => string;
}

interface LocalState {
    selectedColumns: any;
    selectAll: boolean;
}

class OversightsExportPage extends React.Component<Props, LocalState> {
    private availableColumns = [
        { key: 'id', header: 'Activity ID' },
        { key: 'created_at', header: 'Date/Time' },
        { key: 'activity', header: 'Activity Type' },
        { key: 'managerId', header: 'User' },
        { key: 'beneficiaryId', header: 'Beneficiary ID' },
        { key: 'vendorId', header: 'Vendor ID' },
        { key: 'parkedByManagerId', header: 'Parked By' },
        { key: 'postedByManagerId', header: 'Posted By' },
        { key: 'lvlOneSignedByManagerId', header: 'LVL1 Signed By' },
        { key: 'lvlTwoSignedByManagerId', header: 'LVL2 Signed By' },
    ];

    constructor(props) {
        super(props);
        this.state = {
            selectAll: false,
            selectedColumns: {},
        };
    }

    private defaultPageSize = 10;
    private refresher?: RefreshSignaler;

    async changePageRequested() {
        this.props.appendAsyncTasksList(this.defaultPageSize, AsyncTasksTypes.EXPORT_ACTIVITY_LOG);
    }

    async UNSAFE_componentWillMount() {
        if (!this.props.oversightsTotalCount) {
            this.props.loadActivityLogs(1, this.defaultPageSize, this.props.oversightFilters);
        }
        this.refresher = await this.props.loadAsyncTasksList(
            1,
            this.defaultPageSize,
            AsyncTasksTypes.EXPORT_ACTIVITY_LOG
        );
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    renderExportFeatureDescription() {
        return (
            <div>
                <p>{this.props.oversightsTotalCount} activity logs are listed.</p>
                <p>Please select the information you would like to download per Beneficiary:</p>
            </div>
        );
    }

    renderExportFeature() {
        return (
            <div>
                <div className="wfp-form--group">
                    <div className="checkbox" key="all" style={{ marginBottom: 25 }}>
                        <label>
                            <input
                                type="checkbox"
                                name="all"
                                checked={this.state.selectAll}
                                onChange={this._handleCheckAll.bind(this)}
                            />{' '}
                            Select all
                        </label>
                    </div>
                    {this.availableColumns.map((column) => (
                        <div className="checkbox" key={column.key}>
                            <label>
                                <input
                                    type="checkbox"
                                    name={column.key}
                                    checked={this.state.selectedColumns[column.key] || false}
                                    onChange={this._handleCheck.bind(this)}
                                />{' '}
                                {column.header}
                            </label>
                        </div>
                    ))}
                </div>

                <div className="wfp-form--actions">
                    <button type="submit" className="wfp-btn">
                        Generate
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link to="/home" className="breadcrumbs--link">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link to="/oversight" className="breadcrumbs--link">
                                <span>Oversight</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Download Oversight</span>
                        </li>
                    </ol>
                </nav>
                <h3>Download Oversight</h3>
                {this.renderExportFeatureDescription()}

                <form onSubmit={this._exportOversights.bind(this)}>{this.renderExportFeature()}</form>
                {this.props.exports && this.props.exports.items.length > 0 && (
                    <div>
                        <AsyncTasksListExportView
                            data={this.props.exports.items}
                            canDownload={true}
                            authorize={() => {}}
                            downloadFile={this.props.downloadFile.bind(this)}
                            authorizedManagerId={this.props.managerId}
                        />
                        <div className="wfp-form--actions">
                            <button
                                onClick={this.changePageRequested.bind(this)}
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleCheck(event) {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState((prevState) => {
            const selectedColumns = prevState.selectedColumns;
            selectedColumns[key] = value;
            return { selectedColumns };
        });
    }

    _handleCheckAll(event) {
        const value = event.target.checked;
        const selectedColumns = {};

        this.availableColumns.forEach((column) => {
            selectedColumns[column.key] = value;
        });

        this.setState((prevState) => {
            return { selectedColumns, selectAll: value };
        });
    }

    _exportOversights(event) {
        event.preventDefault();

        const mapping = this.availableColumns.filter((column) => this.state.selectedColumns[column.key]);

        return this.props
            .exportOversights(mapping, this.props.oversightFilters)
            .then(() => this.props.loadAsyncTasksList(1, this.defaultPageSize, AsyncTasksTypes.EXPORT_ACTIVITY_LOG));
    }
}

function mapStateToProps(state: State) {
    return {
        oversightFilters: state.activityLogs && state.activityLogs.activityLogs.filters,
        managerId: state.auth.manager ? state.auth.manager.id : null,
        exports: state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_ACTIVITY_LOG] || new PagedState(),
        oversightsTotalCount:
            state.activityLogs &&
            state.activityLogs.activityLogs.paging &&
            state.activityLogs.activityLogs.paging.totalAtLeast,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadAsyncTasksList: bindActionCreators(AsyncActionCreators.loadAsyncTasksList, dispatch),
        exportOversights: bindActionCreators(ActionCreators.exportOversights, dispatch),
        loadActivityLogs: bindActionCreators(ActionCreators.loadActivityLogs, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OversightsExportPage as any);
