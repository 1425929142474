import React = require('react');
import { AuthState } from '../login/auth';
import { defaultPartnerSortOptions } from './partners';
import { SortableTableHead } from '../utils/table/table-head/sortable-table-head';
import { ChangeSortOptions, SORT_OPTIONS } from '../utils/hooks/useSort';
import { PartnerListRow } from './PartnerListRow';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import State from '../app/store/state';
import { PartnerFilters } from './PartnerFilters';

interface Props {
    auth: AuthState;
    authorize: (partnerId: string) => void;
    resetPassword: (partnerId: string) => void;
    handleCancel: (partner) => void;
}

const columns = ['Partner Name', 'Sublocation', 'City', 'Country', 'Status', ''];
const columnToAttributeMapping = {
    'Partner Name': 'name',
    Sublocation: 'sublocation',
    City: 'city',
    Country: 'country',
    Status: 'status',
};

export default function PartnersList({ auth, authorize, handleCancel }: Props) {
    const partners = useSelector((state: State) => state.partners.list);
    const [sortOptions, setSortOptions] = useState<SORT_OPTIONS>(defaultPartnerSortOptions);

    const handleSort: ChangeSortOptions = async ([column, direction]) => {
        if (column) {
            setSortOptions({
                'orderBy:column': columnToAttributeMapping[column],
                'orderBy:direction': direction,
            });
        } else {
            setSortOptions(defaultPartnerSortOptions);
        }
    };
    return (
        <main>
            <PartnerFilters sortOptions={sortOptions} />
            <table className="wfp-table mt4 table-hover">
                <SortableTableHead changeSortOptions={handleSort}>
                    {columns.map((column) =>
                        column ? (
                            <th key={column}>{column}</th>
                        ) : (
                            <SortableTableHead.DisabledColumn key={column}>{column}</SortableTableHead.DisabledColumn>
                        )
                    )}
                </SortableTableHead>
                <tbody>
                    {partners.map((partner) => (
                        <PartnerListRow
                            auth={auth}
                            authorize={authorize}
                            handleCancel={handleCancel}
                            key={partner.id}
                            partner={partner}
                        />
                    ))}
                </tbody>
            </table>
        </main>
    );
}
