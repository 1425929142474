import * as React from 'react';

import { Modal, Button } from 'react-bootstrap';

export function ProductPriceDeleteModal({ price, onDelete, onCancel }: any) {
    return (
        <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>Remove Price</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to remove price for vendor <strong>{price.vendorNickname}</strong>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="wfp-btn" onClick={onCancel} variant="secondary">
                    Cancel
                </Button>
                <Button className="wfp-btn--primary" onClick={() => onDelete(price)} variant="primary">
                    Remove Document
                </Button>
            </Modal.Footer>
        </Modal.Dialog>
    );
}
