import * as React from 'react';
import BreadCrumbs from '../utils/BreadCrumbs';
import { Input } from '../utils/inputs';
import { useDocumentUpload } from './utils/useDocumentUpload';
import { ALLOWED_FILE_EXTENSIONS } from '@wfp-common/constants/documents';

export function UploadDocumentsForm() {
    const { handleChange, handleSubmit, values, uploading } = useDocumentUpload();
    return (
        <>
            <BreadCrumbs path="/documents/create" />
            <h3>Add Document</h3>
            <form className="wfp-form BDS-upload-documents-form-container" onSubmit={handleSubmit}>
                <label htmlFor="document">
                    <span>File</span>
                    <input
                        accept={Object.values(ALLOWED_FILE_EXTENSIONS).join(',')}
                        id="document"
                        name="document"
                        onChange={handleChange}
                        type="file"
                    />
                </label>
                <label>
                    <span>Section</span>
                    <Input name="section" onChange={handleChange} value={values.section} />
                </label>
                <label>
                    <span>File Name</span>
                    <Input name="fileName" onChange={handleChange} value={values.fileName} />
                </label>
                {uploading && (
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" />
                    </div>
                )}
                <button className="wfp-btn--primary" disabled={uploading} type="submit">
                    Upload
                </button>
            </form>
        </>
    );
}
