import * as React from 'react';
import { displayTime } from './utils';
import { ActionCreators, AsyncTask } from './asyncTasks';
import { stopPropagation } from './events';
import State from '../app/store/state';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Manager from '../managers/manager';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';
import { withNavigate } from '@wfp-common/hooks/withNavigate';

export type Field = 'fileName' | 'created' | 'parked' | 'posted' | 'started' | 'finished' | 'status' | 'download';

export class Fields {
    static fileName: Field = 'fileName';
    static created: Field = 'created';
    static started: Field = 'started';
    static finished: Field = 'finished';
    static status: Field = 'status';
}

interface Props {
    cancelTask: (type: string, id: string) => void;
    cancelRunningTask: (type: string, id: string) => void;
    manager: Manager;
    data: Array<AsyncTask>;
    canAuthorizeTask?: boolean;
    excludeFields?: Array<Field>;
    authorizedManagerId: string;
    authorize: (type: string, id: string) => Promise<void>;
    canDownload: boolean;
    downloadFile: (type: string, id: string) => string;
    showCreatedByManager?: boolean;
    navigate: NavigateHook;
}

class AsyncTasksListExportView extends React.Component<Props, any> {
    constructor(props) {
        super(props);
    }

    static defaultProps = {
        canAuthorizeTask: true,
    };

    shouldShowField(field: Field) {
        if (!this.props.excludeFields || this.props.excludeFields.length === 0) {
            return true;
        }
        return this.props.excludeFields.indexOf(field) === -1;
    }

    render() {
        return (
            <main>
                <table className="wfp-table--striped mt4 table-hover">
                    <thead>
                        <tr>
                            {this.shouldShowField(Fields.fileName) && <th>File Name</th>}
                            {this.props.showCreatedByManager && <th>Created By Manager</th>}
                            {this.shouldShowField(Fields.created) && <th>Requested</th>}
                            {this.shouldShowField(Fields.started) && <th>Started</th>}
                            {this.shouldShowField(Fields.finished) && <th>Finished</th>}
                            {this.shouldShowField(Fields.status) && <th>Status</th>}
                            {/*<th></th>*/}
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((row) => (
                            <tr className={'cursor-pointer'} key={row.id} onClick={this._onRowChosen.bind(this, row)}>
                                {this.shouldShowField(Fields.fileName) && <td>{row.fileName}</td>}
                                {this.props.showCreatedByManager && <td>{row.createdByManager.name}</td>}
                                {this.shouldShowField(Fields.created) && <td>{displayTime(row.createdAt)}</td>}
                                {this.shouldShowField(Fields.started) && <td>{displayTime(row.startedAt)}</td>}
                                {this.shouldShowField(Fields.finished) && <td>{displayTime(row.finishedAt)}</td>}
                                {this.shouldShowField(Fields.status) && (
                                    <td>
                                        <div>{row.status}</div>
                                    </td>
                                )}
                                {/*{this.getNextAction(row)}*/}

                                {this.props.canDownload ? (
                                    <td>
                                        {row.fileExpired ? (
                                            <span>File Expired</span>
                                        ) : row.errors ? (
                                            <span>Erroneous export</span>
                                        ) : (
                                            row.fileName && (
                                                <a
                                                    onClick={stopPropagation(() =>
                                                        this.props.downloadFile(row.type, row.id)
                                                    )}
                                                >
                                                    Download
                                                </a>
                                            )
                                        )}
                                    </td>
                                ) : (
                                    <td>-</td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </main>
        );
    }

    _onRowChosen(row: AsyncTask) {
        this.props.navigate(`/tasks/${row.type}/${row.id}`);
    }
}

function mapStateToProps(state: State, ownProps: any) {
    return {
        manager: state.auth.manager,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        cancelTask: bindActionCreators(ActionCreators.cancelTask, dispatch),
        cancelRunningTask: bindActionCreators(ActionCreators.cancelRunningTask, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigate(AsyncTasksListExportView, 'AsyncTasksListExportView'));
