import { SerializedBalanceEntry } from '../vendors/model';
import { PaymentRequestStatus } from '../paymentRequests/paymentRequests';
import { getActivityLog, loadActivityLogsChild, loadAllActivityLogs, exportOversights } from '../apiClient';
import { Filter } from '../utils/FilterTypes';
import Action from '../action';
import { PagedState } from '../utils/paging';
import NamedId from '../utils/NamedId';

export enum AutomaticReversalStatus {
    pendingReversal = 'pending',
    success = 'success',
    failed = 'failed',
}
export interface StatusTransition<T> {
    to: T;
    at: string;
    byManager?: NamedId;
}
import { EntityUpdate } from '../authorization/taskAuthorizations';
import { TransactionStatus, TransactionType } from '../transactions/transactions';
import { errorAction } from '../utils/notifications';

export interface ActivityLog<T> {
    id: string;
    activity: string;
    parkedByManagerId?: number;
    postedByManagerId?: number;
    lvlOneSignedByManagerId?: number;
    lvlTwoSignedByManagerId?: number;
    created_at: string;
    managerId?: number;
    beneficiaryId?: string;
    vendorId?: string;
    partnerId?: string;
    payload?: T;
    parentId?: string;
    preCancelledByManagerId?: number;
    cancelledByManagerId?: number;
    loadAmount?: number;
    updates?: any;
    permissionProfileId?: number;
}

export class ExtendedActivityLog {
    id: string;
    manager?: NamedId;
    activity: ActivityType;
    createdAt: Date;
    updatedAt: Date;
    beneficiaryId?: string;
    vendorId?: string;
    partnerId?: number;
    systemVendorId: string;
    parkedByManager?: NamedId;
    postedByManager?: NamedId;
    payload?: any;
    isParent: boolean;
    relatedModelResponse?: RelatedModelResponse;
    relatedTransactions?: RelatedTransactionResponse[];
    relatedRowProcessing?: RelatedRowProcessing;
    signOneByManager?: NamedId;
    signTwoByManager?: NamedId;
    documentId?: number;
    documentSection?: string;
    permissionProfileId?: number;
    permissionProfileName?: string;
}

export class RelatedModelResponse {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    entityId?: string;
    updates?: EntityUpdate[];
    asyncTaskType?: string;
    createdAt: Date;
    authorizedAt?: Date;
    deletedAt?: Date;
    deletedByManager?: NamedId;
    fileName?: string;
    finishedAt?: Date;
    startedAt?: Date;
    cancelledAt?: Date;
    cancelledByManager?: NamedId;
    signOneAt?: Date;
    signTwoAt?: Date;
    periodEnd?: Date;
    currentPeriodCredits?: number;
    currentPeriodDebits?: number;
    previousPeriodsCredits?: number;
    previousPeriodsDebits?: number;
    statusTransitions?: StatusTransition<PaymentRequestStatus>[];
    deleted?: boolean;
}

export class RelatedTransactionResponse {
    id: number;
    category: string;
    amount: number;
    currency: string;
    blockchainHash: string;
    status: TransactionStatus;
    type: TransactionType;
}

export class RelatedRowProcessing {
    reason?: string;
    locationOne?: string;
    locationTwo?: string;
    phoneNumber?: number;
    householdSize?: number;
}

export class ActivityLogsState {
    activityLogs: PagedState<ActivityLog<any>>;
    singleActivityLogs: ExtendedActivityLog;

    constructor(
        activityLogs: PagedState<ActivityLog<any>> = new PagedState<ActivityLog<any>>(),
        singleActivityLogs: ExtendedActivityLog = new ExtendedActivityLog()
    ) {
        this.activityLogs = activityLogs;
        this.singleActivityLogs = singleActivityLogs;
    }
}

const createDefaultState = () => {
    return {
        activityLogs: new PagedState<ActivityLog<any>>(),
        singleActivityLogs: null,
    };
};

export const ActivityLogsActionTypes = {
    dataLoaded: 'ActivityLogsPage.dataLoaded',
    detailsLoaded: 'ActivityLogsPage.detailsLoaded',
};

export function activityLogsReducer(state: ActivityLogsState = createDefaultState(), action: Action) {
    switch (action.type) {
        case ActivityLogsActionTypes.dataLoaded:
            return new ActivityLogsState(action.payload, state.singleActivityLogs);
        case ActivityLogsActionTypes.detailsLoaded:
            return new ActivityLogsState(state.activityLogs, action.payload);
    }
    return state;
}

export enum ActivityType {
    login = 'login',
    logout = 'logout',
    passwordChange = 'password-change',
    passwordReset = 'password-reset',
    passwordBlocked = 'password-blocked',
    passwordUnblocked = 'password-unblocked',
    otpSecretChange = 'otp-secret-change',
    otpSecretReset = 'otp-secret-reset',
    entityUpdatePark = 'entity-update-park',
    entityUpdatePost = 'entity-update-post',
    dataChange = 'data-change',
    phoneNumberChange = 'phone-number-change',
    beneficiaryImport = 'beneficiary-import',
    beneficiaryMappingImport = 'beneficiary-mapping-import',
    beneficiaryUpdateByImport = 'beneficiary-update-by-import',
    beneficiarySingleBlock = 'beneficiary-single-block',
    beneficiarySingleUnblock = 'beneficiary-single-unblock',
    beneficiaryDetailsUpdate = 'beneficiary-details-update',
    beneficiaryZeroingBlock = 'beneficiary-zeroing-block',
    beneficiaryAutoBlock = 'beneficiary-auto-block',
    beneficiaryZeroing = 'beneficiary-zeroing',
    beneficiaryMassZeroing = 'beneficiary-mass-zeroing',
    beneficiaryMassStatusChange = 'beneficiary-mass-status-change',
    beneficiaryMassStatusChangeAndZeroing = 'beneficiary-mass-status-change-and-zeroing',
    beneficiaryMassStatusBalanceChange = 'beneficiary-mass-status-balance-change',
    beneficiaryImportManualPoEntry = 'beneficiary-import-manual-po-entry',
    beneficiaryPinReset = 'beneficiary-pin-reset',
    managerDetails = 'manager-details-update',
    managerExpired = 'manager-expired',
    managerGpgKeyUpdate = 'manager-gpg-key-update',
    vendorDetails = 'vendor-details-update',
    vendorCreate = 'vendor-create',
    vendorAuthorize = 'vendor-authorize',
    paymentRequestCreate = 'payment-request-create',
    paymentRequestChangeStatus = 'payment-request-change-status',
    automaticReversal = 'automatic-transactions-reversal',
    alternativeCollectorsImport = 'alternative-collectors-import',
    removeAlternativeCollectorsImport = 'remove-alternative-collectors-import',
    alternativeCollectorStatusChange = 'alternative-collector-status-change',
    cancelVendor = 'cancel-vendor',
    cancelManager = 'cancel-manager',
    vendorUserDetails = 'vendor-user-details-update',
    vendorUserCreate = 'vendor-user-create',
    vendorUserAuthorize = 'vendor-user-authorize',
    vendorUserLogin = 'vendor-user-login',
    vendorUserLogout = 'vendor-user-logout',
    paymentRequestGeneration = 'payment-request-generation',
    paymentRequestIssuePaymentPark = 'payment-request-issue-payment-park',
    paymentRequestIssuePaymentPost = 'payment-request-issue-payment-post',
    paymentRequestCancellation = 'payment-request-cancellation',
    paymentRequestPaid = 'payment-request-paid',
    scopeAnnouncement = 'scope-announcement',
    transactionAdjustment = 'transaction-adjustment',
    vendorUserPasswordReset = 'vendor-user-password-reset',
    voidTransaction = 'void-transaction',
    locationCreate = 'location-create',
    locationAuthorize = 'location-authorize',
    locationDetailsUpdate = 'location-details-update',
    documentSectionCreate = 'document-section-create',
    documentSectionUpdate = 'document-section-update',
    documentSectionReorder = 'document-section-reorder',
    documentUpdate = 'document-update',
    documentUpload = 'document-upload',
    documentDownload = 'document-download',
    documentDelete = 'document-delete',
    userPark = 'user-park',
    userPost = 'user-post',
    permissionProfileCreate = 'permission-profile-create',
    permissionProfileAuthorize = 'permission-profile-authorize',
    permissionProfileDetails = 'permission-profile-details',
    partnerDetails = 'partner-details-update',
    partnerCreate = 'partner-create',
    partnerAuthorize = 'partner-authorize',
    cancelPartner = 'cancel-partner',
    partnerUserDetails = 'partner-user-details-update',
    partnerUserCreate = 'partner-user-create',
    partnerUserAuthorize = 'partner-user-authorize',
    partnerUserLogin = 'partner-user-login',
    partnerUserLogout = 'partner-user-logout',
    partnerUserPasswordReset = 'partner-user-password-reset',
    partnerUserExpired = 'partner-user-expired',
}

export enum OperativeActivityType {
    beneficiaryImport = 'beneficiary-import',
    beneficiaryImportManualPoEntry = 'beneficiary-import-manual-po-entry',
    beneficiaryUpdateByImport = 'beneficiary-update-by-import',
    beneficiaryStatusUpdate = 'beneficiary-status-update',
    beneficiaryDetailsUpdate = 'beneficiary-details-update',
    beneficiaryZeroingBlock = 'beneficiary-zeroing-block',
    beneficiaryAutoBlock = 'beneficiary-auto-block',
    beneficiaryZeroing = 'beneficiary-zeroing',
    beneficiarySingleBlock = 'beneficiary-single-block',
    beneficiarySingleUnblock = 'beneficiary-single-unblock',
    beneficiaryMassZeroing = 'beneficiary-mass-zeroing',
    beneficiaryMassStatusChange = 'beneficiary-mass-status-change',
    beneficiaryMassStatusChangeAndZeroing = 'beneficiary-mass-status-change-and-zeroing',
    beneficiaryMassStatusBalanceChange = 'beneficiary-mass-status-balance-change',
    vendorDetails = 'vendor-details-update',
    vendorUserDetails = 'vendor-user-details-update',
    vendorUserCreate = 'vendor-user-create',
    vendorCreate = 'vendor-create',
    vendorAuthorize = 'vendor-authorize',
    vendorUserAuthorize = 'vendor-user-authorize',
    scopeAnnouncement = 'scope-announcement',
    automaticReversal = 'automatic-transactions-reversal',
    paymentRequestCreate = 'payment-request-create',
    paymentRequestChangeStatus = 'payment-request-change-status',
    paymentRequestGeneration = 'payment-request-generation',
    paymentRequestIssuePaymentPark = 'payment-request-issue-payment-park',
    paymentRequestIssuePaymentPost = 'payment-request-issue-payment-post',
    paymentRequestCancellation = 'payment-request-cancellation',
    paymentRequestPaid = 'payment-request-paid',
    alternativeCollectorsImport = 'alternative-collectors-import',
    removeAlternativeCollectorsImport = 'remove-alternative-collectors-import',
    beneficiaryImportSubtaskCancellation = 'beneficiary-import-subtask-cancellation',
    beneficiaryImportCancellation = 'beneficiary-import-cancellation',
    transactionAdjustment = 'transaction-adjustment',
    voidTransaction = 'void-transaction',
    passwordReset = 'password-reset',
    passwordBlocked = 'password-blocked',
    passwordUnblocked = 'password-unblocked',
    otpSecretReset = 'otp-secret-reset',
    otpSecretChange = 'otp-secret-change',
    documentSectionReorder = 'document-section-reorder',
    documentUpdate = 'document-update',
    documentDelete = 'document-delete',
    documentSectionUpdate = 'document-section-update',
    documentSectionCreate = 'document-section-create',
    permissionProfileCreate = 'permission-profile-create',
    permissionProfileAuthorize = 'permission-profile-authorize',
    permissionProfileDetails = 'permission-profile-details',
    partnerDetails = 'partner-details-update',
    partnerCreate = 'partner-create',
    partnerAuthorize = 'partner-authorize',
}

export const unRedirectableLogs = [
    ActivityType.logout,
    ActivityType.login,
    ActivityType.passwordChange,
    ActivityType.passwordReset,
    ActivityType.otpSecretChange,
    ActivityType.otpSecretReset,
    ActivityType.passwordBlocked,
    ActivityType.passwordUnblocked,
    ActivityType.cancelManager,
    ActivityType.cancelVendor,
    ActivityType.cancelPartner,
    ActivityType.vendorUserLogin,
    ActivityType.vendorUserLogout,
    ActivityType.vendorUserPasswordReset,
];

export class OperativeActivityTypes {
    static all(): OperativeActivityType[] {
        const operativeActivityType = [];
        for (const type in OperativeActivityType) {
            operativeActivityType.push(OperativeActivityType[type]);
        }
        return operativeActivityType.sort();
    }
}

export class ActivityTypes {
    static displayName(type: string): string {
        switch (type) {
            case ActivityType.managerDetails:
                return 'user details update';
            default:
                return type.replace(/-/g, ' ');
        }
    }
}

export const ActionCreators = {
    loadActivityLogs(timezone, page, limit = 25, filters: Array<Filter> = []) {
        return (dispatch) => {
            return loadAllActivityLogs(timezone, page, limit, filters).then((activityLogs) => {
                const obj = {
                    type: ActivityLogsActionTypes.dataLoaded,
                    payload: Object.assign(activityLogs, { filters }),
                };
                dispatch(obj);
            });
        };
    },
    getActivityLog(activityLogsId: string) {
        return (dispatch) =>
            getActivityLog(activityLogsId).then((activityLog) => {
                const obj = {
                    type: ActivityLogsActionTypes.detailsLoaded,
                    payload: activityLog,
                };
                dispatch(obj);
            });
    },
    loadAsyncTaskActivityLogs(timezone, parentId, page, limit = 25, filters: Array<Filter> = []) {
        return (dispatch) =>
            loadActivityLogsChild(parentId, page, limit, filters).then((activityLogs) => {
                const obj = {
                    type: ActivityLogsActionTypes.dataLoaded,
                    payload: Object.assign(activityLogs, { filters }),
                };
                dispatch(obj);
            });
    },
    exportOversights(columns: any[], filters: Array<Filter> = []) {
        return (dispatch) =>
            exportOversights(columns, filters).catch((err) => {
                if (err.status === 423) {
                    dispatch(
                        errorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        )
                    );
                }
            });
    },
};

export interface BlockPayload {
    source: string;
    reason: string;
    balanceBeforeBlocking?: SerializedBalanceEntry[];
}

export interface ZeroingPayload extends BlockPayload {
    balanceBeforeZeroing?: {
        active: SerializedBalanceEntry[];
        inactive: SerializedBalanceEntry[];
    };
    createdTransactionsIds?: number[];
}

export interface AsyncTaskPayload {
    asyncTaskId: string;
    asyncTaskType: string;
}

export interface EntityUpdateZeroingLogPayload extends EntityUpdateLogPayload, ZeroingPayload {
    entityUpdateRequestId: string;
}

export interface EntityUpdateLogPayload extends BlockPayload {
    entityUpdateRequestId: string;
    balanceBeforeBlocking?: SerializedBalanceEntry[];
}
export interface AutomaticReversalPayload extends AsyncTaskPayload {
    toReverseTransactionId: number;
    transactionAmount: number;
    status: AutomaticReversalStatus;
    category: string;
    currency: string;
}

export interface MassBeneficiaryZeroingLogPayload extends ZeroingPayload, AsyncTaskPayload {}

export interface BenificiaryAutoBlockLogPayload extends ZeroingPayload, AsyncTaskPayload {}

export interface MassBeneficiaryStatusChangeLogPayload extends AsyncTaskPayload, ZeroingPayload {}

export interface PaymentRequestLogPayload {
    paymentRequestId: number;
    targetStatus: PaymentRequestStatus;
}
export interface BalanceEntryExtended {
    category: string;
    value: number;
    currency: {
        name: string;
        fractionDigits: number;
    };
}
