import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Price, Product } from '../helpers';
import State from '../../app/store/state';
import {
    deleteProductPriceForVendor,
    loadPricesForProduct,
    loadProduct,
    loadProductVendors,
    updateAndAuthorizeProductPrice,
} from '../../apiClient';
import { errorAction, successAction } from '../../utils/notifications';
import { Vendor } from '../../vendors/vendors';
import { useUpdateEffect } from '../../utils/useUpdateEffect';
import { useToggle } from '../../utils/useToggle';
import { AuthState } from '../../login/auth';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';
interface Data {
    product: Product;
    prices: Price[];
    vendors: Vendor[];
}

interface ReturnObject extends Data {
    price: Price;
    productId: string;
    editing: boolean;
    toggleEditing(): void;
    handleSubmit(event): void;
    handleChange(event): void;
    availableVendorsOptions: any[];
    tz: string;
    currencyConfig: any;
    auth: AuthState;
    toggleIsOpen(): void;
    isOpen: boolean;
    handleDelete(event): void;
}

export function useProductsPricesDetails(params): ReturnObject {
    const dispatch = useDispatch();
    const { productId, priceId } = params;
    const [price, setPrice] = useState<Price>(null);
    const [data, setData] = useState<Data>(null);
    const [editing, setEditing] = useState(false);
    const [availableVendorsOptions, setAvailableVendorsOptions] = useState([]);
    const [isOpen, toggleIsOpen] = useToggle();
    const { tz, currencyConfig, auth } = useSelector((state: State) => ({
        tz: state.appConfig.timeZone,
        currencyConfig: state.appConfig.entitlementCurrencyConfig,
        auth: state.auth,
    }));
    useEffect(() => {
        Promise.all([loadProduct(productId), loadPricesForProduct(productId), loadProductVendors()])
            .then(([product, prices, vendors]) => {
                const currentPrice = prices.find(({ id }) => id.toString() === priceId);
                setData({ product, prices, vendors });
                setPrice(currentPrice);
                const vendorsWithPrices = prices.map((price) => price.vendorId);
                setAvailableVendorsOptions(
                    vendors
                        .filter(
                            (vendor) => !vendorsWithPrices.includes(vendor.id) || currentPrice.vendorId === vendor.id
                        )
                        .map((vendor) => ({ label: vendor.nickname, value: vendor.nickname, vendorId: vendor.id }))
                );
            })
            .catch((v) => v);
    }, []);
    useUpdateEffect(() => {
        const currentPrice = data.prices.find(({ id }) => id.toString() === priceId);
        setPrice(currentPrice);
    }, [priceId]);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const vendorId = availableVendorsOptions.find((option) => option.value === price.vendorNickname)?.vendorId;
        updateAndAuthorizeProductPrice(productId, { ...price, vendorId })
            .then(() => {
                dispatch(routerHelperActions.makeRedirect(`/products/${productId}/prices/general`));
                dispatch(successAction('Product price updated'));
            })
            .catch(() => {
                dispatch(errorAction('Cannot update product price'));
            });
    };
    const handleChange = useCallback((event) => {
        event.persist?.();
        setPrice((state) => ({ ...state, [event.target.name]: event.target.value }));
    }, []);
    const toggleEditing = useCallback(() => {
        setEditing((state) => !state);
    }, []);
    const handleDelete = useCallback(async (price) => {
        try {
            await deleteProductPriceForVendor(price.productId, price.id);
            dispatch(routerHelperActions.makeRedirect(`/products/${price.productId}/prices`));
        } catch (error) {
            dispatch(errorAction('Could not delete price'));
        }
    }, []);
    return {
        ...data,
        price,
        productId,
        editing,
        toggleEditing,
        handleSubmit,
        handleChange,
        availableVendorsOptions,
        tz,
        currencyConfig,
        auth,
        toggleIsOpen,
        isOpen,
        handleDelete,
    };
}
