import * as React from 'react';
import PermissionProfile from './profile';
import { useEffect, useState } from 'react';
import { loadPermissionProfiles } from '../apiClient';
import { useDispatch } from 'react-redux';
import { errorAction } from '../utils/notifications';
import { SORT_OPTIONS } from '../utils/hooks/useSort';

export enum PermissionProfileStatus {
    all = 'all',
    allWithNotRejected = 'allWithNotRejected',
    authorizedWithNotActive = 'authorizedWithNotActive',
    authorizedActive = 'authorizedActive',
    rejected = 'rejected',
}

interface PermissionProfiles {
    items: PermissionProfile[];
    loading: boolean;
    refetchProfiles: () => void;
}

const usePermissionProfiles = (
    permissionProfilesStatus: PermissionProfileStatus,
    sortOptions?: SORT_OPTIONS
): PermissionProfiles => {
    const dispatch = useDispatch();
    const [permissionProfiles, setPermissionProfiles] = useState<PermissionProfile[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchPermissionProfiles();
    }, [sortOptions]);

    const fetchPermissionProfiles = async () => {
        try {
            setLoading(true);

            const profiles = await loadPermissionProfiles(
                sortOptions || {
                    'orderBy:column': 'name',
                    'orderBy:direction': 'ASC',
                },
                permissionProfilesStatus
            );
            setPermissionProfiles(profiles);
        } catch (e) {
            dispatch(errorAction(`Permission profiles could not be loaded`));
        } finally {
            setLoading(false);
        }
    };

    return { items: permissionProfiles, loading, refetchProfiles: fetchPermissionProfiles };
};

export interface PermissionProfilesHOCProps {
    permissionProfiles?: PermissionProfiles;
}

function withPermissionProfiles<P>(
    Component: React.ComponentType<P & PermissionProfilesHOCProps>,
    permissionProfilesStatus?: PermissionProfileStatus
) {
    const ComponentWithPermissionProfile = (props: P) => {
        const permissionProfiles = usePermissionProfiles(
            permissionProfilesStatus || PermissionProfileStatus.authorizedActive
        );

        return <Component {...props} permissionProfiles={permissionProfiles} />;
    };

    ComponentWithPermissionProfile.displayName = 'ComponentWithPermissionProfile';
    return ComponentWithPermissionProfile;
}

export { usePermissionProfiles, withPermissionProfiles, PermissionProfiles };
