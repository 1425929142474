import { ProductType } from '../../types/product.type';
import { Outlet } from '../../types/outlet.type';
import { PriceVersion } from '../../types/product-version.type';

export type Reducer = (state: ReducerState, actions: ReducerActions) => ReducerState;

export type FieldState = {
    edited?: boolean;
    markedForRemoval?: boolean;
    isNew?: boolean;
    error?: string | false;
};
export type ReducerActions = InitializeFormEvent | AddProductEvent | EditProductEvent | RemoveEvent;
export type ReducerState = {
    metadata: PriceVersion;
    products: Record<string, ProductType & FieldState>;
    initProducts: Record<string, ProductType & FieldState>;
    outlets: Array<[string, Outlet[]]>;
};

interface EditProductEvent {
    productName: string;
    field: string;
    type: EventType.EDIT;
    value: string | boolean;
    id?: number;
}
export interface AddProductEvent {
    productName: string;
    type: EventType.ADD_PRODUCT;
    value: {
        name: string;
        minPrice: number;
        maxPrice: number;
        priceId: number;
        productId: number;
        isNew: boolean;
        isFixed: boolean;
    };
}

interface InitializeFormEvent {
    type: EventType.INITIALIZE;
    prices: { products: ProductType[]; outlets: Record<string, Outlet[]> };
}

export interface RemoveEvent {
    type: EventType.MARKED_FOR_REMOVAL;
    productName: string;
    value: boolean;
}

export enum EventType {
    INITIALIZE = 'initialize',
    ADD_PRODUCT = 'add_product',
    EDIT = 'edit',
    MARKED_FOR_REMOVAL = 'marked_for_removal',
}
