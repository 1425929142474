import { ThunkDispatch } from 'redux-thunk';

import { AnyAction, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AsyncTasksPage, { AsyncTasksPageDispatchProps, AsyncTasksPageStateProps } from './AsyncTasksPage';
import State from '../../app/store/state';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes } from '../../utils/asyncTasks';
import { ActionCreators as BeneficiaryActionCreators } from '../beneficiaries';
import { hasFeatureAccess } from '../../login/auth';
import { ManagerPermission } from '../../permission-profiles/permission';

const zeroingMapping = {
    beneficiaryId: 1,
    reason: 2,
    source: 3,
    category: 4,
    expirationTokenType: 5,
};

function mapDispatchToProps(dispatch: ThunkDispatch<State, void, AnyAction>): AsyncTasksPageDispatchProps {
    return {
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
        loadAsyncTasksList: (page, limit) =>
            dispatch(AsyncActionCreators.loadAsyncTasksList(page, limit, AsyncTasksTypes.BENEFICIARY_ZEROING)),
        uploadFile: (data: File, dryRun?: boolean, dryRunAsyncTaskId?: string) => {
            const params: any = {
                data,
                mapping: zeroingMapping,
                startIndex: 2,
            };

            if (dryRun) {
                params.dryRun = true;
            }
            if (dryRunAsyncTaskId) {
                params.dryRunAsyncTaskId = dryRunAsyncTaskId;
            }

            return dispatch(BeneficiaryActionCreators.zeroBeneficiaries(params));
        },
        clearUploadTask: bindActionCreators(BeneficiaryActionCreators.clearUploadTask, dispatch),
    };
}

function mapStateToProps(state: State): AsyncTasksPageStateProps {
    return {
        canPark: hasFeatureAccess(state.auth, ManagerPermission.beneficiariesMassZeroPark),
        canPost: hasFeatureAccess(state.auth, ManagerPermission.beneficiariesMassZeroPost),
        canDownload: hasFeatureAccess(state.auth, ManagerPermission.beneficiariesDownload),
        tasks: state.asyncTasks.asyncTasks[AsyncTasksTypes.BENEFICIARY_ZEROING],
        authorizedManagerId: state.auth.manager ? state.auth.manager.id : null,
        exampleUploadFile: 'BB Beneficiary Mass Zeroing - [Category] - [Type] - [VYYYYMMDD].xlsx',
        name: 'Zero Beneficiaries',
        uploadDescription: 'Upload XLSX file with zeroed beneficiaries with proper structure.',
        exampleLinkTitle: 'See example mass zeroing file',
        uploadTaskState: state.uploadTaskState,
        shouldRunDryRun: true,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AsyncTasksPage);
