import { UpdateRowConfiguration } from './EntityUpdatesAuthorizationList';
import { BeneficiaryStatuses } from '../beneficiaries/beneficiaries';
import { hasFeatureAccess, AuthState } from '../login/auth';
import { EntityUpdateRequest, EntityType, EntityUpdate } from './taskAuthorizations';
import { ManagerPermission } from '../permission-profiles/permission';

function configurationForBeneficiaryStatusUpdate(
    entityUpdates: EntityUpdate[],
    auth: AuthState
): UpdateRowConfiguration {
    const statusUpdatePath = 'status';
    const statusChangeUpdate = entityUpdates.find((entityUpdate) => entityUpdate.path === statusUpdatePath);
    if (statusChangeUpdate) {
        switch (statusChangeUpdate.newValue) {
            case BeneficiaryStatuses.active: {
                const isReadonly = !hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleUnblockPost);
                const comment = isReadonly ? 'No permission to post beneficiary unblock' : null;
                return new UpdateRowConfiguration(isReadonly, comment);
            }
            case BeneficiaryStatuses.blocked: {
                const isReadonly = !hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleBlockPost);
                const comment = isReadonly ? 'No permission to post beneficiary block' : null;
                return new UpdateRowConfiguration(isReadonly, comment);
            }
        }
    }
    return new UpdateRowConfiguration(false);
}

export default function configurationForBeneficiaryUpdate(
    entityUpdateRequest: EntityUpdateRequest,
    auth: AuthState
): UpdateRowConfiguration {
    switch (entityUpdateRequest.entityType) {
        case EntityType.beneficiaryDetails: {
            const isReadonly = !hasFeatureAccess(auth, ManagerPermission.beneficiariesPost);
            const comment = isReadonly ? 'No permission to post beneficiary details' : null;
            return new UpdateRowConfiguration(isReadonly, comment);
        }
        case EntityType.beneficiarySingleBlock:
        case EntityType.beneficiarySingleUnblock:
            return configurationForBeneficiaryStatusUpdate(entityUpdateRequest.updates, auth);
        case EntityType.beneficiaryZeroingBlock: {
            const isReadonly =
                !hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleBlockPost) ||
                !hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleZeroPost);
            const comment = isReadonly ? 'No permission to post zeroing block' : null;
            return new UpdateRowConfiguration(isReadonly, comment);
        }
        case EntityType.beneficiaryZeroing: {
            const isReadonly = !hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleZeroPost);
            const comment = isReadonly ? 'No permission to post balance zeroing' : null;
            return new UpdateRowConfiguration(isReadonly, comment);
        }
        case EntityType.alternativeCollectorStatusChange:
            const isReadonly = !hasFeatureAccess(auth, ManagerPermission.alternativeCollectorStatusChangePost);
            const comment = isReadonly ? 'No permission to post alternative collector status change' : null;
            return new UpdateRowConfiguration(isReadonly, comment);
        default:
            return new UpdateRowConfiguration(true);
    }
}
