import * as React from 'react';
import { IndividualIcon } from '../icons/Individual.svg';
import { FamilyIcon } from '../icons/Family.svg';
import { BeneficiaryAccountType } from './beneficiaries';
import styled from 'styled-components';
interface Props {
    type: BeneficiaryAccountType;
}
export function BeneficiaryType({ type }: Props) {
    if (type === 'individual') {
        return (
            <Container>
                <IndividualIcon />
            </Container>
        );
    }

    return (
        <Container>
            <FamilyIcon />
        </Container>
    );
}

const Container = styled.div`
    svg {
        //fill: #0374e6;
        width: 1.5rem;
    }
`;
