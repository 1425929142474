import styled from 'styled-components';
import { MODAL, useEntityUpdateRequests } from './entity-update-request.context';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { EntityUpdateRequest } from '../taskAuthorizations';

export const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    button.wfp-btn--secondary {
        color: red;
        border-color: red;
        &:hover {
            background: red;
            color: white;
        }
        &:active,
        &:focus,
        &:focus-visible {
            border-color: red;
            outline-color: red;
        }
    }
`;

interface ActionButtonProps {
    updateRequest: EntityUpdateRequest;
}

export function RejectAction({ children, updateRequest }: PropsWithChildren<ActionButtonProps>) {
    const { modalStateReducer } = useEntityUpdateRequests();
    const [, open] = modalStateReducer;
    return (
        <button className="wfp-btn wfp-btn--secondary" onClick={() => open({ modal: MODAL.REJECT, updateRequest })}>
            {children || 'Reject'}
        </button>
    );
}

export function PostAction({ children, updateRequest }: PropsWithChildren<ActionButtonProps>) {
    const { modalStateReducer } = useEntityUpdateRequests();
    const [, open] = modalStateReducer;
    return (
        <button className="wfp-btn" onClick={() => open({ modal: MODAL.VERIFY, updateRequest })}>
            {children || 'Post'}
        </button>
    );
}

export function SignAction({ children, updateRequest }: PropsWithChildren<ActionButtonProps>) {
    const { modalStateReducer } = useEntityUpdateRequests();
    const [, open] = modalStateReducer;

    return (
        <button className="wfp-btn" onClick={() => open({ modal: MODAL.SIGN, updateRequest })}>
            {children || 'Sign'}
        </button>
    );
}
