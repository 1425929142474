import * as React from 'react';
import { FC, useState } from 'react';
import { PasswordChecker } from './PasswordChecker';

export function withVerifier<T>(
    WrappedComponent: FC,
    binder: { eventName: 'onClick' | 'onChange'; postExecuteFunction: (data?: T) => void },
    requestWithPasswordProtection: any
) {
    return (props) => {
        const [showModal, setShowModal] = useState(false);

        const onBeforeExecute = {
            [binder.eventName]: () => {
                setShowModal(true);
            },
        };

        const handleCloseModal = () => {
            setShowModal(false);
        };

        const onConfirm = (data: T) => {
            handleCloseModal();
            binder.postExecuteFunction(data);
        };

        return (
            <>
                {showModal && (
                    <PasswordChecker
                        onClose={() => handleCloseModal()}
                        onConfirm={onConfirm}
                        requestWithPasswordProtection={requestWithPasswordProtection}
                    />
                )}
                <WrappedComponent {...props} {...onBeforeExecute} />
            </>
        );
    };
}
