import styled, { keyframes } from 'styled-components';
import * as React from 'react';
const loader = keyframes`
    0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
`;
const Container = styled.div`
    display: inline-block;
    border-radius: 3px;
    font-size: 30px;
    padding: 1em;
    position: relative;
    margin: auto;
    margin-bottom: 0.25em;
    vertical-align: top;
    div {
        display: inline-block;
        width: 1em;
        height: 1em;
        color: inherit;
        vertical-align: middle;
        pointer-events: none;
        border: 0.2em solid #2a93fc;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: 1s ${loader} linear infinite;
        position: relative;
    }
`;

export function Loader() {
    return (
        <Container className="loader">
            <div />
        </Container>
    );
}
