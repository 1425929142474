import { ProductPrice } from '../types/product-price.type';
import { ACTION_TYPE } from './actions';
import { statuses } from '../utils/grouped-prices-page.utils';
import { capitalizeFirstLetter } from '../../utils/utils';

export function ProductPriceReducer(
    state = INITIAL_STATE,
    { type, payload }: { type: ACTION_TYPE; payload: any | unknown }
) {
    switch (type) {
        case ACTION_TYPE.LOAD:
            return new ProductPriceState({
                ...state,
                items: payload.data,
                metadata: payload.metadata,
            });
        case ACTION_TYPE.LOAD_ONE:
            return new ProductPriceState({
                ...state,
                current: payload,
            });
        case ACTION_TYPE.CLEAR_CURRENT_PRICE:
            return new ProductPriceState({
                ...state,
                current: null,
            });
        case ACTION_TYPE.CHANGE_QUERY:
            return new ProductPriceState({
                ...state,
                query: {
                    ...state.query,
                    [payload.key]: payload.data,
                },
            });
        case ACTION_TYPE.UNLOAD_PRICES:
            return new ProductPriceState({
                ...state,
                items: [],
            });
        default:
            return state;
    }
}

class ProductPriceQuery {
    status: { value: typeof statuses[number]; label: string }[];
    vendors: { value: string; label: string }[];
    outlets: { value: string; label: string }[];
    constructor({ status, vendors, outlets }: ProductPriceQuery) {
        this.status = status;
        this.vendors = vendors;
        this.outlets = outlets;
    }
}

export class ProductPriceState {
    items: ProductPriceItem[];
    current: ProductPriceItem;
    query: ProductPriceQuery;
    metadata: any;
    constructor({ items, current, query, metadata }: ProductPriceState) {
        this.items = items.map((item) => new ProductPriceItem(item));
        this.current = current ? new ProductPriceItem({ ...current }) : null;
        this.query = query as ProductPriceQuery;
        this.metadata = metadata;
    }
}

const INITIAL_STATE = new ProductPriceState({
    items: [],
    current: null,
    query: {
        status: [
            { value: statuses[0], label: capitalizeFirstLetter(statuses[0]) },
            { value: statuses[1], label: capitalizeFirstLetter(statuses[1]) },
            { value: statuses[2], label: capitalizeFirstLetter(statuses[2]) },
        ],
        vendors: [],
        outlets: [],
    },
    metadata: { version: 1, validFrom: new Date() },
});

export class ProductPriceItem implements ProductPrice {
    active: boolean;
    authorizedAt: Date;
    authorizedByManagerId: number;
    branchIds: number[];
    cancelledAt?: Date;
    cancelledByManagerId?: number;
    createdAt: Date;
    createdByManagerId: number;
    expiry: Date;
    id: number;
    invalidatedAt?: Date;
    invalidatedByPriceId?: number;
    invalidatedFrom?: Date;
    maxPrice: string;
    minPrice: string;
    originalPriceId?: number;
    productId: number;
    updatedAt: Date;
    updatedByManagerId?: number;
    validFrom?: Date;
    vendorsWithBranchIds: {
        outlets: { branchId: number; branchCode: string }[];
        vendorId: string;
        vendorNickname: string;
    }[];
    product: {
        id: number;
        name: string;
        categoryId: number;
        status: string;
        createdByManagerId: number;
        authorizedByManagerId: number;
        authorizedAt: Date;
        quantityOption: string;
        unit: string;
        category: {
            id: number;
            name: string;
            token: string;
        };
    };
    status: typeof statuses[number];
    productGroup: { name: string; id: number };
    constructor(productPrice: Partial<ProductPrice | any>) {
        this.active = productPrice.active;
        this.authorizedAt = productPrice.authorizedAt;
        this.authorizedByManagerId = productPrice.authorizedByManagerId;
        this.branchIds = productPrice.branchIds;
        this.cancelledAt = productPrice.cancelledAt;
        this.cancelledByManagerId = productPrice.cancelledByManagerId;
        this.createdAt = productPrice.createdAt;
        this.createdByManagerId = productPrice.createdByManagerId;
        this.expiry = productPrice.expiry;
        this.id = productPrice.id;
        this.invalidatedAt = productPrice.invalidatedAt;
        this.invalidatedByPriceId = productPrice.invalidatedByPriceId;
        this.invalidatedFrom = productPrice.invalidatedFrom;
        this.maxPrice = productPrice.maxPrice;
        this.minPrice = productPrice.minPrice;
        this.originalPriceId = productPrice.originalPriceId;
        this.productId = productPrice.productId;
        this.updatedAt = productPrice.updatedAt;
        this.updatedByManagerId = productPrice.updatedByManagerId;
        this.validFrom = productPrice.validFrom;
        this.vendorsWithBranchIds = productPrice.vendorsWithBranchIds?.length
            ? productPrice.vendorsWithBranchIds
            : productPrice.outlets?.map(({ vendor, ...outlet }) => ({
                  ...outlet,
                  vendorNickname: vendor?.nickName,
              }));
        this.product = {
            id: productPrice.product?.id,
            name: productPrice.product?.name,
            categoryId: productPrice.product?.categoryId,
            status: productPrice.product?.status,
            createdByManagerId: productPrice.product?.createdByManagerId,
            authorizedByManagerId: productPrice.product?.authorizedByManagerId,
            authorizedAt: productPrice.product?.authorizedAt,
            quantityOption: productPrice.product?.quantityOption,
            unit: productPrice.product?.unit,
            category: {
                id: productPrice.product?.category?.id,
                name: productPrice.product?.category?.name,
                token: productPrice.product?.category?.token,
            },
        };
        this.status = productPrice.status;
        this.productGroup = {
            name: productPrice?.productGroup?.name,
            id: productPrice?.productGroup?.id,
        };
    }
}
