import Action from '../action';
import { getSecurityData, postOtpSecret } from '../apiClient';
import { errorAction, successAction } from '../utils/notifications';
import { HttpStatus } from '../common/enums';
import { OtpData } from './SecurityPage';

export interface SecurityProps {
    otpSecret?: string;
    otpQRDataUrl?: string;
    otpEnabled?: boolean;
}

export class SecurityState implements SecurityProps {
    otpSecret: string;
    otpQRDataUrl: string;
    error: any;
    success: boolean;
    otpEnabled?: boolean;

    constructor(security: SecurityProps = {}, error?: any, success?: boolean) {
        this.otpSecret = security.otpSecret;
        this.otpQRDataUrl = security.otpQRDataUrl;
        this.error = error;
        this.success = success;
        this.otpEnabled = security.otpEnabled;
    }
}

export const ActionTypes = {
    clearOtpData: 'Security.clearOtpData',
    updateOtpData: 'Security.updateOtpData',
    successOtpEnable: 'Security.successOtpEnable',
    invalidOtp: 'Security.invalidOtp',
};

export function securityReducer(state: SecurityState = new SecurityState(), action: Action) {
    switch (action.type) {
        case ActionTypes.clearOtpData:
            return new SecurityState({ ...state, otpSecret: null, otpQRDataUrl: null });
        case ActionTypes.updateOtpData:
            return new SecurityState(action.payload);
        case ActionTypes.invalidOtp:
            return new SecurityState(state, action.payload);
        case ActionTypes.successOtpEnable:
            return new SecurityState(action.payload, null, true);
        default:
            return state;
    }
}

function handleSecurityResponse(response: OtpData, dispatch) {
    dispatch({
        type: ActionTypes.updateOtpData,
        payload: response,
    });
}

export const ActionCreators = {
    updateOtpData: (otpData: OtpData) => {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.updateOtpData,
                payload: otpData,
            });
        };
    },
    clearOtpData: () => {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.clearOtpData,
            });
        };
    },
    getSecurityData: () => {
        return (dispatch) => getSecurityData().then((response) => handleSecurityResponse(response, dispatch));
    },
    postOtpSecret: (otp: string, secret: string, managerId: number, oldOtp?: string) => {
        return (dispatch) =>
            postOtpSecret(otp, secret, managerId, oldOtp)
                .then((response) => {
                    dispatch(successAction('Multi-factor Authentication has been set successfully.'));
                    dispatch({
                        type: ActionTypes.successOtpEnable,
                        payload: response,
                    });
                })
                .catch(async (error) => {
                    if (error.status === HttpStatus.forbidden) {
                        dispatch({
                            type: ActionTypes.invalidOtp,
                            payload: Object.assign(error, { jsonResponse: await error.json() }),
                        });
                    } else {
                        dispatch(errorAction(error));
                    }
                });
    },
    removeSecretFromState: () => {
        return (dispatch) =>
            dispatch({
                type: ActionTypes.updateOtpData,
                payload: {},
            });
    },
};
