import { useCallback, useEffect, useState } from 'react';
import { loadProductGroups } from '../../apiClient';
import { useDispatch } from 'react-redux';
import { ChangeSortOptions, SORT_OPTIONS, SortOptions } from '../../utils/hooks/useSort';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

interface GroupProduct {
    id: number;
    name: string;
    quantityOption: string;
    unit: string;
    category: {
        name: string;
    };
}

interface Group {
    name: string;
    products: GroupProduct[];
}

const columnToAttributeMapping = {
    Product: 'name',
    Category: 'category',
    'Quantity Option': 'quantityOption',
    Unit: 'unit',
};

export function useProductGroupsPage(): ReturnType<
    () => {
        activeGroup: Group;
        groups: Group[];
        handleSelect(productGroupName: string): void;
        redirectToProductPage(productId: string | number): () => void;
        handleSort: ChangeSortOptions;
    }
> {
    const [groups, setGroups] = useState(null);
    const [activeGroup, setActiveGroup] = useState(null);
    const [sortOptions, setSortOptions] = useState<SORT_OPTIONS>(new SortOptions({ column: 'name', direction: 'ASC' }));

    useEffect(() => {
        loadProductGroups(sortOptions).then((res) => {
            setGroups(res);
            if (!activeGroup) {
                setActiveGroup(res?.[0]);
            } else {
                const currentActiveGroup = res?.find((productGroup) => activeGroup.name === productGroup.name);
                setActiveGroup(currentActiveGroup);
            }
        });
    }, [sortOptions]);

    const handleSort: ChangeSortOptions = ([column, direction]) => {
        if (!column) column = 'Product';
        const sortingOptions = new SortOptions({ column: columnToAttributeMapping[column], direction });
        setSortOptions(sortingOptions);
    };

    const handleSelect = useCallback(
        (productGroupName: string | number) => {
            const group = groups?.find((productGroup) => productGroupName === productGroup.name);
            setActiveGroup(group);
        },
        [groups]
    );
    const dispatch = useDispatch();
    const redirectToProductPage = useCallback(
        (productId) => () => {
            dispatch(routerHelperActions.makeRedirect(`/products/${productId}/general`));
        },
        []
    );
    return { activeGroup, handleSelect, groups, redirectToProductPage, handleSort };
}
