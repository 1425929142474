import * as React from 'react';
import { useEffect, useState } from 'react';
import { getBeneficiaryFinancialLimitations, getBeneficiaryProductRestrictions } from '../../apiClient';
import { BeneficiaryLimit } from './BeneficiaryLimit';
import { Tabs } from '../../utils/Tabs';
import styled from 'styled-components';

const TabsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SectionTitle = styled.div`
    margin-top: 25px;
    font-size: 1.3rem;
    font-weight: bold;
`;

interface Props {
    location: { state: { beneficiaryId: string } };
}

export interface Limitation {
    id: number;
    groupsId: number[];
    productIds: number[];
    intervention: string;
    limitationKey?: string;
    from: string;
    to: string;
    type: string;
    description: string;
    function: string;
    used: string;
    value: string;
    priority: number;
    functionObject: {
        extremum: string;
        type: LimitationType;
    };
    products: {
        id: number;
        name: string;
    }[];
    groups: {
        id: number;
        name: string;
    }[];
}

export enum LimitationType {
    restriction = 'restriction',
    financial = 'financial',
}

export const limitationTabs = ['Product Restrictions', 'Financial Limitations'];

const splitRestrictions = (
    limitations: Limitation[],
    activeTab: string
): {
    activeRestrictions: Limitation[];
    expiredRestrictions: Limitation[];
    futureRestrictions: Limitation[];
    showNoLimitationsMessage: boolean;
} => {
    const activeRestrictions = [],
        expiredRestrictions = [],
        futureRestrictions = [];
    let showNoLimitationsMessage = true;

    limitations.forEach((limit) => {
        if (activeTab === limitationTabs[0]) {
            showNoLimitationsMessage = false;
            if (new Date(limit.to) < new Date()) {
                expiredRestrictions.push(limit);
            } else if (new Date(limit.from) > new Date()) {
                futureRestrictions.push(limit);
            } else {
                activeRestrictions.push(limit);
            }
        }
    });

    return {
        activeRestrictions,
        expiredRestrictions,
        futureRestrictions,
        showNoLimitationsMessage,
    };
};

const BeneficiaryLimitations: React.FC<Props> = ({ location }) => {
    const [productRestrictions, setProductRestrictions] = useState<Limitation[]>([]);
    const [financialLimitations, setFinancialLimitations] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState<string>(limitationTabs[0]);
    const loadBeneficiaryLimitations = async (beneficiaryId: string) => {
        const productRestrictions = await getBeneficiaryProductRestrictions(beneficiaryId);
        const financialLimitations = await getBeneficiaryFinancialLimitations(beneficiaryId);
        setProductRestrictions(productRestrictions);
        setFinancialLimitations(financialLimitations);
    };

    const { activeRestrictions, expiredRestrictions, futureRestrictions, showNoLimitationsMessage } = splitRestrictions(
        productRestrictions,
        activeTab
    );

    useEffect(() => {
        if (location && location.state.beneficiaryId) loadBeneficiaryLimitations(location.state.beneficiaryId);
    }, [location]);

    const renderTabs = () => {
        const tabs = ['Product Restrictions', 'Financial Limitations'];
        return (
            <TabsContainer>
                <Tabs>
                    {tabs.map((tab) => (
                        <span key={tab} onClick={() => setActiveTab(tab)}>
                            {`${tab[0].toUpperCase()}${tab.slice(1)}`}
                        </span>
                    ))}
                </Tabs>
            </TabsContainer>
        );
    };

    const renderLimitationsList = (title: string, limitations: Limitation[], isFinancialTab: boolean) => {
        if (limitations.length < 1) {
            return null;
        }

        return (
            <div>
                <SectionTitle>{title}</SectionTitle>
                {limitations &&
                    limitations.map((limit) => (
                        <BeneficiaryLimit
                            activeTab={activeTab}
                            isFinancialTab={isFinancialTab}
                            key={limit.id}
                            limit={limit}
                        />
                    ))}
            </div>
        );
    };

    const renderProductsRestrictions = () => {
        if (showNoLimitationsMessage) {
            return (
                <div className="mt2">
                    <small>
                        <i>No products restrictions to show</i>
                    </small>
                </div>
            );
        }

        return (
            <>
                {renderLimitationsList('Future Restrictions', futureRestrictions, false)}
                {renderLimitationsList('Active Restrictions', activeRestrictions, false)}
                {renderLimitationsList('Expired Restrictions', expiredRestrictions, false)}
            </>
        );
    };

    const renderFinancialLimitations = () => {
        if (!financialLimitations.length) {
            return (
                <div className="mt2">
                    <small>
                        <i>No financial limitations to show</i>
                    </small>
                </div>
            );
        }

        return <>{renderLimitationsList('Active Limitations', financialLimitations, true)}</>;
    };

    return (
        <div>
            <h6>Limitations</h6>
            {renderTabs()}
            {activeTab === limitationTabs[0] ? renderProductsRestrictions() : renderFinancialLimitations()}
        </div>
    );
};

BeneficiaryLimitations.displayName = 'BeneficiaryLimitations';

export { BeneficiaryLimitations };
