import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { errorAction } from '../../utils/notifications';
import { getTokensStatistics } from '../../apiClient';
import { currencyFormatter } from '../../utils/utils';
import State from '../../app/store/state';
import { RowMenu } from '../../utils/RowMenu';
import { BigNumber } from '../../utils/bigNumber';
import { ExpirationTokenType } from '../../transactions/transactions';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

export type Accounting = {
    type: ExpirationTokenType;
    minted: string;
    burned: string;
    spendIn: string;
    spendOut: string;
    adjustIn: string;
    adjustOut: string;
};

export interface TokensStatisticsInterface {
    category: string;
    vendorExpired: Accounting;
    vendorActive: Accounting;
    vendorFuture: Accounting;
    beneficiaryExpired: Accounting;
    beneficiaryActive: Accounting;
    beneficiaryFuture: Accounting;
}

export interface StatsResponse {
    time: string;
    stats: Array<TokensStatisticsInterface>;
}

function calculateStats(givenCategoryStats: TokensStatisticsInterface) {
    const beneficiaryNetUploadsExpired =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryExpired.minted).minus(givenCategoryStats.beneficiaryExpired.burned);
    const beneficiaryNetUploadsActive =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryActive.minted).minus(givenCategoryStats.beneficiaryActive.burned);
    const beneficiaryNetUploadsFuture =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryFuture.minted).minus(givenCategoryStats.beneficiaryFuture.burned);

    const beneficiaryNetExpendituresExpired =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryExpired.spendOut)
            .plus(givenCategoryStats.beneficiaryExpired.adjustOut)
            .minus(givenCategoryStats.beneficiaryExpired.adjustIn);

    const beneficiaryNetExpendituresActive =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryActive.spendOut)
            .plus(givenCategoryStats.beneficiaryActive.adjustOut)
            .minus(givenCategoryStats.beneficiaryActive.adjustIn);

    const beneficiaryNetExpendituresFuture =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryFuture.spendOut)
            .plus(givenCategoryStats.beneficiaryFuture.adjustOut)
            .minus(givenCategoryStats.beneficiaryFuture.adjustIn);

    const vendorNetPaymentsExpired =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.vendorExpired.burned).minus(givenCategoryStats.vendorExpired.minted);
    const vendorNetPaymentsActive =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.vendorActive.burned).minus(givenCategoryStats.vendorActive.minted);
    const vendorNetPaymentsFuture =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.vendorFuture.burned).minus(givenCategoryStats.vendorFuture.minted);

    const totalBeneficiaryBalanceExpired =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryExpired.minted)
            .plus(givenCategoryStats.beneficiaryExpired.adjustIn)
            .minus(givenCategoryStats.beneficiaryExpired.burned)
            .minus(givenCategoryStats.beneficiaryExpired.spendOut)
            .minus(givenCategoryStats.beneficiaryExpired.adjustOut);
    const totalBeneficiaryBalanceActive =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryActive.minted)
            .plus(givenCategoryStats.beneficiaryActive.adjustIn)
            .minus(givenCategoryStats.beneficiaryActive.burned)
            .minus(givenCategoryStats.beneficiaryActive.spendOut)
            .minus(givenCategoryStats.beneficiaryActive.adjustOut);
    const totalBeneficiaryBalanceFuture =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.beneficiaryFuture.minted)
            .plus(givenCategoryStats.beneficiaryFuture.adjustIn)
            .minus(givenCategoryStats.beneficiaryFuture.burned)
            .minus(givenCategoryStats.beneficiaryFuture.spendOut)
            .minus(givenCategoryStats.beneficiaryFuture.adjustOut);

    const totalVendorBalanceExpired =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.vendorExpired.minted)
            .plus(givenCategoryStats.vendorExpired.spendIn)
            .plus(givenCategoryStats.vendorExpired.adjustIn)
            .minus(givenCategoryStats.vendorExpired.adjustOut)
            .minus(givenCategoryStats.vendorExpired.burned);

    const totalVendorBalanceActive =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.vendorActive.minted)
            .plus(givenCategoryStats.vendorActive.spendIn)
            .plus(givenCategoryStats.vendorActive.adjustIn)
            .minus(givenCategoryStats.vendorActive.adjustOut)
            .minus(givenCategoryStats.vendorActive.burned);

    const totalVendorBalanceFuture =
        givenCategoryStats &&
        new BigNumber(givenCategoryStats.vendorFuture.minted)
            .plus(givenCategoryStats.vendorFuture.spendIn)
            .plus(givenCategoryStats.vendorFuture.adjustIn)
            .minus(givenCategoryStats.vendorFuture.adjustOut)
            .minus(givenCategoryStats.vendorFuture.burned);
    return {
        beneficiaryNetUploadsExpired,
        beneficiaryNetUploadsActive,
        beneficiaryNetUploadsFuture,
        beneficiaryNetExpendituresExpired,
        beneficiaryNetExpendituresActive,
        beneficiaryNetExpendituresFuture,
        vendorNetPaymentsExpired,
        vendorNetPaymentsActive,
        vendorNetPaymentsFuture,
        totalBeneficiaryBalanceExpired,
        totalBeneficiaryBalanceActive,
        totalBeneficiaryBalanceFuture,
        totalVendorBalanceExpired,
        totalVendorBalanceActive,
        totalVendorBalanceFuture,
    };
}

function TokensStatistics() {
    const dispatch = useDispatch();
    const [time, setTime] = useState<StatsResponse>(null);
    const [calculatedStats, setCalculateStats] = useState(null);

    const { currencyConfig, entitlementsCategoryConfig } = useSelector((state: State) => ({
        entitlementsCategoryConfig: state.appConfig.entitlementsConfig,
        currencyConfig: state.appConfig.entitlementCurrencyConfig,
    }));
    const [activeCategory, setActiveCategory] = useState<string>(entitlementsCategoryConfig?.categories[0]);

    const errorWhileDataLoading = () => {
        dispatch(hideLoading());
        dispatch(errorAction('Failed while loading beneficiaries statistics.'));
    };
    useEffect(() => {
        dispatch(showLoading());
        getTokensStatistics()
            .then((res) => {
                dispatch(hideLoading());
                const givenCategoryStats = res.stats.find((stat) => stat.category === activeCategory);
                const calculatedStats = calculateStats(givenCategoryStats);
                setTime(res.time);
                return setCalculateStats(calculatedStats);
            })
            .catch(errorWhileDataLoading);
    }, [activeCategory]);
    return (
        time &&
        calculatedStats &&
        currencyConfig &&
        activeCategory &&
        entitlementsCategoryConfig && (
            <div>
                <br />
                <div>
                    <RowMenu
                        activeTab={activeCategory}
                        onSelectTab={setActiveCategory}
                        tabs={entitlementsCategoryConfig.categories}
                    />
                </div>
                <div>
                    <table className="wfp-table mt4 table-hover wfp-account-table">
                        <tbody>
                            <tr>
                                <td>Description</td>
                                <td>Expired Tx Sum</td>
                                <td>Active Tx Sum</td>
                                <td>Future Tx Sum</td>
                                <td>All Tx Sum</td>
                            </tr>
                            <tr>
                                <td>
                                    Beneficiary Net Uploads{' '}
                                    <span className="icon-help-dark xsmall" title="Load - Unload" />
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.beneficiaryNetUploadsExpired.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.beneficiaryNetUploadsActive.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.beneficiaryNetUploadsFuture.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.beneficiaryNetUploadsExpired
                                            .plus(calculatedStats.beneficiaryNetUploadsActive)
                                            .plus(calculatedStats.beneficiaryNetUploadsFuture)
                                            .toNumber()
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Beneficiary Net Expenditures{' '}
                                    <span className="icon-help-dark xsmall" title="Spend - Reverse" />
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.beneficiaryNetExpendituresExpired.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.beneficiaryNetExpendituresActive.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.beneficiaryNetExpendituresFuture.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.beneficiaryNetExpendituresExpired
                                            .plus(calculatedStats.beneficiaryNetExpendituresActive)
                                            .plus(calculatedStats.beneficiaryNetExpendituresFuture)
                                            .toNumber()
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Vendor Net Payments{' '}
                                    <span className="icon-help-dark xsmall" title="Reclaim - Direct" />
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.vendorNetPaymentsExpired.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.vendorNetPaymentsActive.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.vendorNetPaymentsFuture.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.vendorNetPaymentsExpired
                                            .plus(calculatedStats.vendorNetPaymentsActive)
                                            .plus(calculatedStats.vendorNetPaymentsFuture)
                                            .toNumber()
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total Beneficiary balance{' '}
                                    <span
                                        className="icon-help-dark xsmall"
                                        title="Load + Reverse - Unload - Spend"
                                     />
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalBeneficiaryBalanceExpired.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalBeneficiaryBalanceActive.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalBeneficiaryBalanceFuture.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalBeneficiaryBalanceExpired
                                            .plus(calculatedStats.totalBeneficiaryBalanceActive)
                                            .plus(calculatedStats.totalBeneficiaryBalanceFuture)
                                            .toNumber()
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total Vendor balance{' '}
                                    <span
                                        className="icon-help-dark xsmall"
                                        title="Direct + Spend - Reverse - Reclaim"
                                     />
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalVendorBalanceExpired.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalVendorBalanceActive.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalVendorBalanceFuture.toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalVendorBalanceExpired
                                            .plus(calculatedStats.totalVendorBalanceActive)
                                            .plus(calculatedStats.totalVendorBalanceFuture)
                                            .toNumber()
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Total WFP Liability{' '}
                                    <span
                                        className="icon-help-dark xsmall"
                                        title="Unload + Reclaim - Load - Direct"
                                     />
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalBeneficiaryBalanceExpired
                                            .plus(calculatedStats.totalVendorBalanceExpired)
                                            .toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalBeneficiaryBalanceActive
                                            .plus(calculatedStats.totalVendorBalanceActive)
                                            .toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalBeneficiaryBalanceFuture
                                            .plus(calculatedStats.totalVendorBalanceFuture)
                                            .toNumber()
                                    )}
                                </td>
                                <td>
                                    {currencyFormatter(currencyConfig).format(
                                        calculatedStats.totalBeneficiaryBalanceExpired
                                            .plus(calculatedStats.totalVendorBalanceExpired)
                                            .plus(calculatedStats.totalBeneficiaryBalanceActive)
                                            .plus(calculatedStats.totalVendorBalanceActive)
                                            .plus(calculatedStats.totalBeneficiaryBalanceFuture)
                                            .plus(calculatedStats.totalVendorBalanceFuture)
                                            .toNumber()
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <small>Total WFP liability should equal total Beneficiary balance plus total Vendor balance</small>
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            <small>
                                <i>All numbers calculated as of {time}</i>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

export { TokensStatistics };
