import { TransactionType } from './transactions';
import Action from '../action';
import { getTransactionTypeTotals } from '../apiClient';
import { isNullOrUndefined } from 'util';
import { BigNumber } from '../utils/bigNumber';
import { errorAction } from '../utils/notifications';

export interface TypeTotal {
    transactionType: TransactionType;
    transactionsSum: number;
    transactionsCount: number;
}

export interface GenerationPeriod {
    generatedFrom: string;
    generatedTo: string;
}

export interface BigNumberTypeTotal {
    transactionType: TransactionType;
    transactionsSum: BigNumber;
    transactionsCount: number;
}

export interface TypeTotalResponse {
    typeTotals: Array<TypeTotal>;
    generationPeriod: GenerationPeriod;
    currency: string;
}

export class TypeTotalsState {
    constructor(public typeTotalsSinceSystemStartResponse: any, public dynamicStartDateTypeTotalsResponse: any) {
        this.typeTotalsSinceSystemStartResponse = typeTotalsSinceSystemStartResponse;
        this.dynamicStartDateTypeTotalsResponse = dynamicStartDateTypeTotalsResponse;
    }
}

export const ActionTypes = {
    dynamicStartDateDataLoaded: 'dynamicStartTypeTotals.dynamicStartDateDataLoaded',
    dataSinceSystemStartLoaded: 'dynamicStartTypeTotals.dataSinceSystemStartLoaded',
};

export const TypeTotalsActionCreators = {
    loadTransactionTypesTotals(timezone: string, startDate?: string, endDate?: string, types?: Array<TransactionType>) {
        return (dispatch) => {
            return getTransactionTypeTotals(timezone, startDate, endDate, types)
                .then((typeTotals) => {
                    const actionType = isNullOrUndefined(startDate)
                        ? ActionTypes.dataSinceSystemStartLoaded
                        : ActionTypes.dynamicStartDateDataLoaded;
                    const obj = {
                        type: actionType,
                        payload: typeTotals,
                    };
                    dispatch(obj);
                })
                .catch((err) => {
                    if (err.status === 422) {
                        dispatch(
                            errorAction('Error during loading Summary Statistics. Please contact system administrator')
                        );
                    }
                });
        };
    },
};

export function typeTotalsReducer(state: TypeTotalsState = new TypeTotalsState(null, null), action: Action) {
    switch (action.type) {
        case ActionTypes.dataSinceSystemStartLoaded:
            return new TypeTotalsState(action.payload, state.dynamicStartDateTypeTotalsResponse);
        case ActionTypes.dynamicStartDateDataLoaded:
            return new TypeTotalsState(state.typeTotalsSinceSystemStartResponse, action.payload);
        default:
            return state;
    }
}
