import { Link } from 'react-router';
import * as React from 'react';
import { getCrumbsFromPath, wfpFormat } from './utils';
import { useMemo } from 'react';

export default function BreadCrumbs({ path }) {
    const crumbs = useMemo(() => getCrumbsFromPath(path), [path]);
    const lastCrumb = crumbs.length - 1;
    return (
        <nav className="wfp-breadcrumbs">
            <ol className="breadcrumbs--wrapper">
                {crumbs.map((crumb, i) => (
                    <li className="breadcrumbs--item" key={crumb.label}>
                        {i === lastCrumb ? (
                            <span className="breadcrumbs--last">
                                {wfpFormat(decodeURI(crumb.label).replaceAll('-', ' '))}
                            </span>
                        ) : (
                            <Link className="breadcrumbs--link" to={crumb.href || '/'}>
                                <span>{wfpFormat(decodeURI(crumb.label).replaceAll('-', ' '))}</span>
                            </Link>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
}
