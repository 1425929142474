import * as React from 'react';
import {
    EntityUpdateRequest,
    UpdateStateListType,
    EntityType,
    EntityUpdate,
    ProductUpdateRequest,
} from './taskAuthorizations';
import PagingComponent, { PagedState } from '../utils/paging';
import EntityDetailsUpdateRequestCell from './EntityDetailsUpdateRequestCell';
import { ConfirmationDialog } from '../utils/Dialogs';
import NoDataPlaceholder from './NoDataPlaceholder';
import RejectionDialog from '../utils/RejectionDialog';
import EditionDialog from '../utils/EditionDialog';

export class UpdateRowConfiguration {
    constructor(
        public isReadonly: boolean | { edit: boolean; park: boolean; post: boolean },
        public comment?: string
    ) {}
}

interface Props {
    entityUpdateRequests: PagedState<EntityUpdateRequest | ProductUpdateRequest>;
    listType: UpdateStateListType;
    managerId: string;
    configurationForUpdate: (entityUpdateRequest: EntityUpdateRequest) => UpdateRowConfiguration;
    onPageChanged: (page: number) => void;
    onEditUpdateRequest?: (id: string, newUpdates: EntityUpdate[]) => void;
    onParkUpdateRequest?: (entityUpdateRequest: EntityUpdateRequest) => void;
    onConfirmUpdateRequest: (entityUpdateRequest: EntityUpdateRequest | ProductUpdateRequest) => void;
    onRejectUpdateRequest: (entityUpdateRequest: EntityUpdateRequest | ProductUpdateRequest, comment: string) => void;
    categories: string[];
}

interface State {
    updatesToBePosted?: EntityUpdateRequest | ProductUpdateRequest;
    updatesToBeRejected?: EntityUpdateRequest | ProductUpdateRequest;
    updatesToBeParked?: EntityUpdateRequest | ProductUpdateRequest;
    updatesToBeEdited?: EntityUpdateRequest;
}

export default class EntityUpdatesAuthorizationList extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            updatesToBePosted: null,
            updatesToBeRejected: null,
        };
    }

    onEditRequest(change: EntityUpdateRequest) {
        this.setState({ updatesToBeEdited: change });
    }

    onParkRequested(change: EntityUpdateRequest | ProductUpdateRequest) {
        this.setState({ updatesToBeParked: change });
    }

    onPostRequested(change: EntityUpdateRequest | ProductUpdateRequest) {
        this.setState({ updatesToBePosted: change });
    }

    onRejectRequested(change: EntityUpdateRequest | ProductUpdateRequest) {
        this.setState({ updatesToBeRejected: change });
    }

    onCloseDialog() {
        this.setState({
            updatesToBeParked: null,
            updatesToBePosted: null,
            updatesToBeRejected: null,
            updatesToBeEdited: null,
        });
    }

    onEdit(editedUpdate: EntityUpdateRequest) {
        this.props.onEditUpdateRequest(editedUpdate.id, editedUpdate.updates);
        this.onCloseDialog();
    }

    onPark() {
        this.props.onParkUpdateRequest(this.state.updatesToBeParked);
        this.onCloseDialog();
    }

    onPost() {
        this.props.onConfirmUpdateRequest(this.state.updatesToBePosted);
        this.onCloseDialog();
    }

    onReject(comment) {
        this.props.onRejectUpdateRequest(this.state.updatesToBeRejected, comment);
        this.onCloseDialog();
    }

    displayNameForEntityType(entityType: EntityType) {
        switch (entityType) {
            case EntityType.beneficiaryDetails:
            case EntityType.beneficiarySingleBlock:
            case EntityType.beneficiarySingleUnblock:
            case EntityType.beneficiaryZeroingBlock:
                return 'beneficiary';
            case EntityType.vendorDetails:
                return 'vendor';
            case EntityType.vendorUserDetails:
                return 'vendor user';
            case EntityType.managerDetails:
                return 'user';
            case EntityType.preParkedBeneficiary:
                return 'pre-parked beneficiary';
            case EntityType.partnerDetails:
                return 'partner';
            case EntityType.partnerUserDetails:
                return 'partner user';
            case EntityType.locationDetails:
                return 'Locations';
            case EntityType.permissionProfileDetails:
                return 'Permission Profile';
            case EntityType.productManagement:
                return 'product';
            default:
                return '';
        }
    }

    renderEditDialog() {
        return (
            <EditionDialog
                onClose={this.onCloseDialog.bind(this)}
                onConfirm={this.onEdit.bind(this)}
                update={this.state.updatesToBeEdited}
            />
        );
    }

    renderParkingDialog() {
        const { title, entityType } = this.state.updatesToBeParked;
        const entityId = this.state.updatesToBeParked.updates.find((u) => u.path === 'id').newValue;
        const type = this.displayNameForEntityType(entityType);
        const message =
            title && title.length
                ? `Do you really want to park changes of ${type} - ${title}?`
                : `Do you really want to park changes of ${type} with id "${entityId}"?`;

        return (
            <ConfirmationDialog
                message={message}
                onClose={this.onCloseDialog.bind(this)}
                onConfirm={this.onPark.bind(this)}
                title="Park beneficiary"
            />
        );
    }

    renderPostingDialog() {
        const { entityId, title, entityType } = this.state.updatesToBePosted;
        const type = this.displayNameForEntityType(entityType);
        const message =
            title && title.length > 0
                ? `Do you really want to post changes of ${type} - ${title}?`
                : `Do you really want to post changes of ${type} with id "${entityId}"?`;

        return (
            <ConfirmationDialog
                message={message}
                onClose={this.onCloseDialog.bind(this)}
                onConfirm={this.onPost.bind(this)}
                title="Post Changes"
            />
        );
    }

    renderRejectionDialog() {
        const { entityId, title, entityType } = this.state.updatesToBeRejected;
        const type = this.displayNameForEntityType(entityType);
        const message = title
            ? `Do you really want to reject changes of ${type} - ${title}?`
            : `Do you really want to reject changes of ${type} with id "${entityId}"?`;

        return (
            <RejectionDialog
                message={message}
                onClose={this.onCloseDialog.bind(this)}
                onConfirm={this.onReject.bind(this)}
                title="Reject Changes"
            />
        );
    }

    isHisExpiryChange(changes: EntityUpdateRequest) {
        return (
            changes.updates.map((u) => u.path).includes('expirationDate') && changes.entityId === this.props.managerId
        );
    }

    renderCells() {
        return this.props.entityUpdateRequests.items.map((changesRequest) => {
            const updateConfiguration = this.props.configurationForUpdate(changesRequest);
            const readonly =
                updateConfiguration.isReadonly ||
                this.props.listType !== UpdateStateListType.Requested ||
                this.isHisExpiryChange(changesRequest);
            return (
                <EntityDetailsUpdateRequestCell
                    categories={this.props.categories}
                    comment={updateConfiguration.comment}
                    key={changesRequest.id}
                    onEdit={() => this.onEditRequest(changesRequest)}
                    onPark={() => this.onParkRequested(changesRequest)}
                    onPost={() => this.onPostRequested(changesRequest)}
                    onReject={() => this.onRejectRequested(changesRequest)}
                    readonly={readonly}
                    updateRequest={changesRequest}
                />
            );
        });
    }

    render() {
        const { entityUpdateRequests } = this.props;
        const { updatesToBeParked, updatesToBePosted, updatesToBeRejected, updatesToBeEdited } = this.state;

        const hasUpdateRequests = entityUpdateRequests.items.length > 0;

        return (
            <div>
                {updatesToBeEdited && this.renderEditDialog()}
                {updatesToBeParked && this.renderParkingDialog()}
                {updatesToBePosted && this.renderPostingDialog()}
                {updatesToBeRejected && this.renderRejectionDialog()}
                {hasUpdateRequests && this.renderCells()}
                {!hasUpdateRequests && <NoDataPlaceholder listType={this.props.listType} />}
                <PagingComponent onPageChanged={this.props.onPageChanged} paging={entityUpdateRequests.paging} />
            </div>
        );
    }
}
