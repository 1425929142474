import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import State from '../app/store/state';
import FeatureButton from '../utils/FeatureButton';
import { ActionCreators, Vendor, VendorBranch } from './vendors';
import { useEffect, useState } from 'react';
import { loadBranches } from '../apiClient';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { stopPropagation } from '../utils/events';
import { displayTime } from '../utils/utils';
import { ManagerPermission } from '../permission-profiles/permission';

export default function VendorBranches({ vendor, params }: { vendor: Vendor; params: { vendorId: string } }) {
    const vendorId = params.vendorId;
    const auth = useSelector((state: State) => state.auth);
    const config = useSelector((state: State) => state.appConfig);
    const [branches, setBranches] = useState<VendorBranch[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        loadBranches(vendorId).then((branches) => {
            setBranches(branches);
        });
    }, []);

    const authorizeBranch = async (branchId: string) => {
        await ActionCreators.authorizeBranch(branchId, vendorId)(dispatch);
        loadBranches(vendorId).then((branches) => {
            setBranches(branches);
        });
    };

    const rejectBranch = async (branchId: string) => {
        await ActionCreators.rejectBranch(branchId, vendorId)(dispatch);
        loadBranches(vendorId).then((branches) => {
            setBranches(branches);
        });
    };

    const cancelBranch = async (branchId: string) => {
        await ActionCreators.cancelBranch(branchId, vendorId)(dispatch);
        loadBranches(vendorId).then((branches) => {
            setBranches(branches);
        });
    };

    return (
        <div>
            {vendor?.authorizedAt && config.allowAddingBranches && (
                <FeatureButton
                    linkTo={`/vendors/${vendor.id}/branches/add`}
                    manager={auth.manager}
                    restrictedToFeatures={[ManagerPermission.vendorsPark]}
                    title={'Add Branch'}
                />
            )}
            <table className="wfp-table mt4 table-hover">
                <thead>
                    <th>Outlet</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    {branches.map((branch) => (
                        <tr key={branch.id}>
                            <td>{branch.outlet}</td>
                            <td>{branch.createdByManagerEmail}</td>
                            <td>{displayTime(branch.createdAt)}</td>
                            <td style={{ textTransform: 'capitalize' }}>
                                {branch.status}
                                <br />
                                {config.allowAddingBranches && (
                                    <NextAction
                                        auth={auth}
                                        authorizeBranch={authorizeBranch}
                                        branch={branch}
                                        cancelBranch={cancelBranch}
                                        rejectBranch={rejectBranch}
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function NextAction({
    branch,
    auth,
    authorizeBranch,
    rejectBranch,
    cancelBranch,
}: {
    branch: VendorBranch;
    auth: AuthState;
    authorizeBranch(arg: string): void;
    rejectBranch(arg: string): void;
    cancelBranch(arg: string): void;
}) {
    if (!auth?.manager?.id) {
        return null;
    }

    if (
        branch.status === 'parked' &&
        branch.createdByManagerId !== auth.manager.id &&
        hasFeatureAccess(auth, ManagerPermission.vendorsPost)
    ) {
        return (
            <>
                <a onClick={stopPropagation(() => authorizeBranch(branch.id))}>Post</a>
                <br />
                <a onClick={stopPropagation(() => rejectBranch(branch.id))}>Reject</a>
            </>
        );
    } else if (branch.status === 'parked' && branch.createdByManagerId === auth.manager.id) {
        return <a onClick={stopPropagation(() => cancelBranch(branch.id))}>Cancel</a>;
    }

    return null;
}
