import * as React from 'react';
import { Price, Product } from './helpers';
import { useSelector } from 'react-redux';
import State from '../app/store/state';
import * as moment from 'moment-timezone';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

export const ProductsPricesTable: React.FC<{ prices: Partial<Price>[]; product: Product }> = ({ prices, product }) => {
    const { tz, currencyConfig } = useSelector((state: State) => ({
        tz: state.appConfig.timeZone,
        currencyConfig: state.appConfig.entitlementCurrencyConfig,
    }));
    return (
        <table className="wfp-table mt4 wfp-table--striped table-hover">
            <thead>
                <tr>
                    <th>Vendor</th>
                    <th>Price</th>
                    <th>Is Fixed</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                </tr>
            </thead>
            <tbody>
                {prices.map((price) => (
                    <PriceRow
                        currencyConfig={currencyConfig}
                        key={product.id}
                        price={price}
                        product={product}
                        tz={tz}
                    />
                ))}
            </tbody>
        </table>
    );
};

function PriceRow({
    tz,
    product,
    price,
    currencyConfig,
}: {
    tz: string;
    product: Product;
    price: Partial<Price>;
    currencyConfig: State['appConfig']['entitlementCurrencyConfig'];
}) {
    const navigate = useNavigate();

    return (
        <tr className="cursor-pointer" onClick={() => navigate(`/products/${product.id}/prices/${price.id}`)}>
            <td>{price.vendorNickname}</td>
            <td>
                {' '}
                {currencyConfig.name} {Number(price.price).toFixed(currencyConfig.fractionDigits)}
            </td>
            <td>{price.isFixed ? 'Yes' : 'No'}</td>
            <td>{price.createdByManager}</td>
            <td>{moment(price.createdAt).tz(tz).format('DD/MM/YYYY HH:mm')}</td>
            <td>{moment(price.updatedAt).tz(tz).format('DD/MM/YYYY HH:mm')}</td>
        </tr>
    );
}
