import * as React from 'react';
import { AsyncTask, AsyncTasksTypes } from './asyncTasks';
import * as _ from 'lodash';

export interface UploadResult {
    totalRowsCount: number;
    rowsImportedCount: number;
    errors: Array<{ rowIdx?: number; sheetName?: string; error: any }>;
}

export type UploadStatus = null | 'pending' | 'finished';

export class UploadStatuses {
    static pending: UploadStatus = 'pending';
    static finished: UploadStatus = 'finished';
}

interface UploadStatusWidgetProps {
    importStatus: UploadStatus;
    importResult?: UploadResult;
    importTask?: AsyncTask;
    importError?: string;
    downloadFile?: (type: string, id: string, index?: number) => void;
}

export class UploadStatusWidget extends React.Component<UploadStatusWidgetProps> {
    _formatError(error: any) {
        const parts = [];

        if (error.message) {
            parts.push(error.message);
        }
        if (error.value) {
            parts.push(error.value);
        }

        if (error.errors) {
            error.errors.forEach((innerError) => parts.push(this._formatError(innerError)));
        }

        if (!parts.length) {
            parts.push(error.toString());
        }

        return parts.join(' - ');
    }

    private renderImportResult() {
        const { importResult } = this.props;
        const errors = (!!importResult && importResult.errors) || [];
        return (
            <div>
                {!!importResult.rowsImportedCount && !errors.length && (
                    <div className="alert alert-success">
                        Import finished. {importResult.rowsImportedCount} rows imported, no errors encountered.
                    </div>
                )}
                {!!importResult.rowsImportedCount && !!errors.length && (
                    <div className="alert alert-warning">
                        Import finished. {importResult.rowsImportedCount} rows imported, {errors.length} errors
                        encountered.
                    </div>
                )}
            </div>
        );
    }

    private renderImportTask(uploadReportChildrenWithoutDuplicates) {
        const { additionalInfo } = this.props.importTask;
        const errors = this.props.importTask.errors || [];
        const warnings = this.props.importTask.warnings || [];
        const isImport =
            this.props.importTask.type === AsyncTasksTypes.IMPORT_BENEFICIARIES ||
            AsyncTasksTypes.IMPORT_ALTERNATIVE_COLLECTOR;
        const successCount = additionalInfo ? additionalInfo.successCount : 'All ';
        return (
            <div>
                {!errors.length && !warnings.length && (
                    <div className="alert alert-success">
                        Validation finished. {successCount} rows validated, no errors encountered.
                    </div>
                )}
                {!!warnings.length && (
                    <div className="alert alert-warning">
                        Validation finished. {isImport ? warnings : `${warnings.length} warnings encountered.`}
                    </div>
                )}
                {!!errors.length && (
                    <div className="alert alert-danger">
                        Validation finished. {`${errors.length} errors encountered.`}
                    </div>
                )}
                {!!(errors.length || warnings.length) &&
                    uploadReportChildrenWithoutDuplicates.map((uploadReportChild, index) => {
                        return (
                            <p key={uploadReportChild.toString() + index}>
                                {uploadReportChild.fileExpired &&
                                    `Upload Report ${uploadReportChild.fileName} is expired`}
                                {uploadReportChild.errors && 'Errors in upload report creation occurred'}
                                {!uploadReportChild.errors && (
                                    <a
                                        onClick={() =>
                                            this.props.downloadFile(uploadReportChild.type, uploadReportChild.id)
                                        }
                                    >
                                        {uploadReportChild.fileName}
                                    </a>
                                )}
                            </p>
                        );
                    })}
            </div>
        );
    }

    render() {
        const { importStatus, importResult, importTask, importError } = this.props;
        const uploadReportChildrenWithoutDuplicates =
            this.props.importTask &&
            _.uniqBy(
                this.props.importTask.children.filter((child) => [AsyncTasksTypes.UPLOAD_REPORT].includes(child.type)),
                (child) => child.fileName
            );
        const progress = Math.max(Number(importTask?.progress ? importTask.progress : 1) * 100, 1);
        const progressString = `${progress}%`;

        return (
            <div className="mv3">
                {importStatus === UploadStatuses.pending && (
                    <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: progressString }}
                        />
                    </div>
                )}

                {!!importError && <div className="alert alert-danger">Upload error: {importError}</div>}
                {importStatus === UploadStatuses.finished && !!importResult && this.renderImportResult()}
                {importStatus === UploadStatuses.finished &&
                    !!importTask &&
                    this.renderImportTask(uploadReportChildrenWithoutDuplicates)}
            </div>
        );
    }
}
