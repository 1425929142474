import * as React from 'react';
import FeatureButton from '../utils/FeatureButton';
import { useDispatch, useSelector } from 'react-redux';
import State from '../app/store/state';
import { useEffect, useState } from 'react';
import { SimpleSideMenu } from '../utils/SideMenu';
import { PermissionProfileDetailsPage } from './PermissionProfileDetailsPage';
import { ActionCreators as ManagerActionCreators } from '../managers/managers';
import BreadCrumbs from '../utils/BreadCrumbs';
import { ManagerPermission } from './permission';
import { PermissionProfiles, PermissionProfileStatus, usePermissionProfiles } from './use-permission-profiles';
import PermissionProfile from './profile';

interface Tab {
    text: string;
    id: number;
}

interface Props {
    location: { state: { permissionProfileId: number | string } };
}

const PermissionProfilesPage: React.FC<Props> = ({ location }) => {
    const dispatch = useDispatch();
    const auth = useSelector((state: State) => state.auth);
    const permissionProfiles: PermissionProfiles = usePermissionProfiles(PermissionProfileStatus.allWithNotRejected);
    const [activeTab, setActiveTab] = useState<Tab>();
    const [activeProfile, setActiveProfile] = useState<PermissionProfile>();

    const activeProfileHandler = () => {
        if (permissionProfiles.items?.length < 1) {
            return;
        }
        if (location.state && location.state.permissionProfileId) {
            const profile = permissionProfiles.items.find(
                (profile) => profile.id === +location.state.permissionProfileId
            );
            setActiveTab({ text: profile.name, id: profile.id });
            setActiveProfile(profile);
        } else if (activeTab) {
            const profile = permissionProfiles.items.find((profile) => profile.id === activeProfile.id);
            if (profile) {
                setActiveTab({ text: profile.name, id: profile.id });
                setActiveProfile(profile);
            } else {
                setActiveTab({ text: permissionProfiles.items[0].name, id: permissionProfiles.items[0].id });
                setActiveProfile(permissionProfiles.items[0]);
            }
        } else {
            setActiveTab({ text: permissionProfiles.items[0].name, id: permissionProfiles.items[0].id });
            setActiveProfile(permissionProfiles.items[0]);
        }
    };

    useEffect(() => {
        activeProfileHandler();
    }, [permissionProfiles.items]);

    useEffect(() => {
        ManagerActionCreators.loadManagersNickNames()(dispatch);
    }, []);

    const changeTabHandler = (tab: Tab) => {
        const newActiveProfile = permissionProfiles.items.find((profile) => profile.id === tab.id);
        setActiveTab(tab);
        setActiveProfile(newActiveProfile);
    };

    const renderSideMenu = () => {
        if (!permissionProfiles) return null;

        const tabs = permissionProfiles.items.map((profile) => {
            return { text: profile.name, id: profile.id, counter: profile.pendingRequestsCount };
        });

        return <SimpleSideMenu activeTab={activeTab} onSelectTab={(tab) => changeTabHandler(tab)} tabs={tabs} />;
    };

    return (
        <main>
            <BreadCrumbs path={'/admin/permission-profiles'} />
            <div className="row">
                <h3 className="col-sm-4">Permission Profiles</h3>
                <div className="col-sm-8 tr">
                    <FeatureButton
                        linkTo={'/admin/permission-profiles/new'}
                        manager={auth.manager}
                        restrictedToFeatures={[ManagerPermission.managersManagementPark]}
                        title={'Add Permission Profile'}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">{renderSideMenu()}</div>
                <div className="col-sm-9">
                    {activeProfile && (
                        <PermissionProfileDetailsPage
                            permissionProfile={activeProfile}
                            refetchPermissionProfiles={permissionProfiles.refetchProfiles}
                        />
                    )}
                </div>
            </div>
        </main>
    );
};

PermissionProfilesPage.displayName = 'PermissionProfilesPage';

export { PermissionProfilesPage };
