import * as React from 'react';
import Select from 'react-select';
import { SelectFilter, Filter } from '../FilterTypes';
import { customStyles } from '../form/fields/Select';

interface Props {
    filter: SelectFilter;
    filterChanged: (filter: Filter, event: any) => void;
    withIsSelected: boolean;
}

export default class MultiSelectFilterField extends React.Component<Props, any> {
    checkbox(filter: SelectFilter) {
        return (
            <input
                checked={filter.isSelected}
                name={filter.name + 'Selected'}
                onChange={(e) => this.props.filterChanged(filter, e)}
                style={{ marginBottom: 25 }}
                type="checkbox"
            />
        );
    }
    render() {
        const filter = this.props.filter;
        return (
            <div className="wfp-form--group" key={filter.name}>
                <label htmlFor="selection">{filter.label}:</label>
                {this.props.withIsSelected && this.checkbox(filter)}
                <div className="replaced-form-input">
                    <Select
                        className="react-select-container"
                        isMulti={true}
                        name={filter.name}
                        onChange={(e) => this.props.filterChanged(filter, e)}
                        options={filter.options}
                        searchable={false}
                        styles={customStyles}
                        value={filter.value}
                    />
                </div>
            </div>
        );
    }
}
