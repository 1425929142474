import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Limitation } from './BeneficiaryLimitations';
import { useSelector } from 'react-redux';
import State from '../../app/store/state';
import { displayTime, withDecimal } from '../../utils/utils';

interface Props {
    activeTab: string;
    limit: Limitation;
    isFinancialTab: boolean;
}

const BeneficiaryLimit: React.FC<Props> = ({ limit, isFinancialTab }) => {
    const [showGroups, setShowGroups] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const currency = useSelector((state: State) => state.appConfig.entitlementCurrencyConfig);

    const percentValue = (parseInt(limit.used) / parseInt(limit.value)) * 100;

    const renderGroups = (limit: Limitation) => {
        const groups = limit.groups;
        if (!groups) return <List>There is no groups.</List>;
        return (
            <List>
                {groups
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((group) => (
                        <div key={group.id}>
                            {group.name} ({group.id})
                        </div>
                    ))}
            </List>
        );
    };

    const renderProducts = (limit: Limitation) => {
        const products = limit.products;
        if (!products) return <List>There is no products.</List>;
        return (
            <List>
                {products
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((product) => (
                        <div key={product.id}>
                            {product.name} ({product.id})
                        </div>
                    ))}
            </List>
        );
    };

    const displayMinMaxFunctionValue = (functionName: string) => {
        if (functionName.includes('max')) return 'max';
        return 'min';
    };

    const displayUsedValue = () => {
        const suffix = limit.function.includes('amount') ? currency.name : 'qty';

        if (isFinancialTab) {
            return `${withDecimal(limit.used)}/${withDecimal(limit.value)} ${suffix}`;
        }
        return `${limit.used}/${limit.value} ${suffix}`;
    };

    return (
        <Container className="shadowed-container" key={limit.id}>
            {isFinancialTab && (
                <div>
                    <Label>Intervention:</Label> <span>{limit.intervention}</span>
                </div>
            )}
            {isFinancialTab && (
                <div>
                    <Label>Limitation key:</Label> <span>{limit.limitationKey}</span>
                </div>
            )}
            {!isFinancialTab && (
                <div>
                    <Label>Description:</Label> <span>{limit.description}</span>
                </div>
            )}
            <ProgressBarContainer>
                <ProgressBar className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${percentValue}%` }} />
                </ProgressBar>
                <span
                    style={{
                        marginLeft: '20px',
                    }}
                >
                    {displayUsedValue()}
                </span>
            </ProgressBarContainer>
            {!isFinancialTab && (
                <div>
                    <Label>Function:</Label> <span>{displayMinMaxFunctionValue(limit.function)}</span>
                </div>
            )}

            {isFinancialTab && (
                <div>
                    <Label>Priority:</Label> <span>{limit.priority}</span>
                </div>
            )}
            <div>
                <Label>From:</Label> <span>{displayTime(new Date(limit.from))}</span>
            </div>
            <div>
                <Label>To:</Label> <span> {displayTime(new Date(limit.to))}</span>
            </div>

            <div onClick={() => setShowGroups(!showGroups)} style={{ cursor: 'pointer' }}>
                <label style={{ cursor: 'pointer' }}>Groups</label>
                {showGroups ? <ArrowUp /> : <ArrowDown />}
            </div>
            {showGroups && renderGroups(limit)}
            <div onClick={() => setShowProducts(!showProducts)} style={{ cursor: 'pointer' }}>
                <label style={{ cursor: 'pointer' }}>Products</label>
                {showProducts ? <ArrowUp /> : <ArrowDown />}
            </div>
            {showProducts && renderProducts(limit)}
        </Container>
    );
};

BeneficiaryLimit.displayName = 'BeneficiaryLimit';

export { BeneficiaryLimit };

const Container = styled.div`
    margin: 25px 0;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
`;

const ProgressBarContainer = styled.div`
    display: flex;
    margin: 5px 0 10px;
`;

const ProgressBar = styled.div`
    width: 300px;
    height: 30px;
`;

const Label = styled.label`
    width: 120px;
`;

const List = styled.div`
    margin-left: 15px;
`;

const ArrowDown = styled.div`
    margin-bottom: 3px;
    margin-left: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
`;

const ArrowUp = styled.div`
    margin-top: 5px;
    margin-left: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
`;
