import AsyncTasksPage from './AsyncTasksPage';
import { bindActionCreators } from 'redux';
import State from '../../app/store/state';
import { connect } from 'react-redux';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes } from '../../utils/asyncTasks';
import { ActionCreators as BeneficiaryActionCreators } from '../beneficiaries';
import { hasFeatureAccess } from '../../login/auth';
import AsyncTasksListView from '../AsyncTasksListView';
import { ManagerPermission } from '../../permission-profiles/permission';

const importMapping = {
    beneficiaryId: 1,
    reason: 2,
    source: 3,
};

function mapDispatchToProps(dispatch: any) {
    return {
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
        loadAsyncTasksList: (page, limit) =>
            dispatch(
                AsyncActionCreators.loadAsyncTasksList(page, limit, AsyncTasksTypes.REMOVE_ALTERNATIVE_COLLECTORS)
            ),
        uploadFile: (data: File, dryRun?: boolean, dryRunAsyncTaskId?: string) => {
            const params: any = {
                data: data,
                mapping: importMapping,
                startIndex: 2,
            };
            if (dryRun) {
                params.dryRun = true;
            }
            if (dryRunAsyncTaskId) {
                params.dryRunAsyncTaskId = dryRunAsyncTaskId;
            }
            return dispatch(BeneficiaryActionCreators.removeAlternativeCollectors(params));
        },
        clearUploadTask: bindActionCreators(BeneficiaryActionCreators.clearUploadTask, dispatch),
    };
}

function mapStateToProps(state: State) {
    const canImport = hasFeatureAccess(state.auth, ManagerPermission.removeAlternativeCollectorsImportPark);
    const canAuthorizeTask = hasFeatureAccess(state.auth, ManagerPermission.removeAlternativeCollectorsImportPost);
    const canDownload = hasFeatureAccess(state.auth, ManagerPermission.beneficiariesDownload);
    return {
        canPark: canImport,
        canPost: canAuthorizeTask,
        canDownload: canDownload,
        tasks: state.asyncTasks.asyncTasks[AsyncTasksTypes.REMOVE_ALTERNATIVE_COLLECTORS],
        authorizedManagerId: state.auth.manager ? state.auth.manager.id : null,
        exampleUploadFile: 'RemoveAlternativeCollectorsImportExample.xlsx',
        name: 'Remove Alternative Collectors',
        uploadDescription: 'Upload the Alternative Collectors XLSX file by clicking the button below.',
        exampleLinkTitle: 'See example import file',
        listView: AsyncTasksListView,
        uploadTaskState: state.uploadTaskState,
        shouldRunDryRun: true,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AsyncTasksPage);
