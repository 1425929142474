import {
    getTransactionRequestsToAuthorize,
    authorizeTransactionRequest,
    rejectTransactionRequest,
    getEntityUpdateRequests,
    authorizeEntityUpdateRequest,
    rejectEntityUpdateRequest,
    parkEntityUpdateRequest,
    editEntityUpdateRequest,
    authorizeEntityUpdateRequestWithOtp,
    signEntityUpdateRequest,
} from '../apiClient';
import { AnyAction } from 'redux';
import { PagedState } from '../utils/paging';
import { TransactionType } from '../transactions/transactions';
import { SerializedBalanceEntry } from '../vendors/model';
import { errorAction, successAction } from '../utils/notifications';
import NamedId from '../utils/NamedId';
import * as clonedeep from 'lodash.clonedeep';
import { throwErrorIfNotHandled } from '../utils/errorMiddleware';
import { Filter } from '../utils/FilterTypes';
import handleEntityUpdateConfirmationError from '../utils/handleEntityUpdateConfirmationError';
import Manager from '../managers/manager';
import { compose } from '../utils/utils';
import { AsyncTask } from '../utils/asyncTasks';

export enum EntityType {
    beneficiaryDetails = 'beneficiaryDetails',
    beneficiarySingleBlock = 'beneficiarySingleBlock',
    beneficiarySingleUnblock = 'beneficiarySingleUnblock',
    beneficiaryZeroingBlock = 'beneficiaryZeroingBlock',
    beneficiaryZeroing = 'beneficiaryZeroing',
    alternativeCollectorStatusChange = 'alternativeCollectorStatusChange',
    vendorDetails = 'vendorDetails',
    managerDetails = 'managerDetails',
    preParkedBeneficiary = 'preParkedBeneficiary',
    vendorUserDetails = 'vendorUserDetails',
    partnerDetails = 'partnerDetails',
    partnerUserDetails = 'partnerUserDetails',
    locationDetails = 'locationDetails',
    permissionProfileDetails = 'permissionProfileDetails',
    productManagement = 'productManagement',
}

const ActionTypes = {
    transactionsLoaded: 'taskAuthorizations.transactionsLoaded',
    transactionRequestPosted: 'taskAuthorizations.transactionRequestPosted',
    transactionRequestRejected: 'taskAuthorizations.transactionRequestRejected',
    entityUpdateRequestsLoaded: 'taskAuthorizations.entityUpdateRequestsLoaded',
    entityUpdateRequestEdited: 'taskAuthorizations.entityUpdateRequestEdited',
    entityUpdateRequestParked: 'taskAuthorization.entityUpdateRequestParked',
    entityUpdateRequestPosted: 'taskAuthorization.entityUpdateRequestPosted',
    entityUpdateRequestSigned: 'taskAuthorization.entityUpdateRequestSigned',
    entityUpdateRequestRejected: 'taskAuthorization.entityUpdateRequestRejected',
    listTypeChanged: 'taskAuthorization.listTypeChanged',
    clearEntityUpdateRequests: 'taskAuthorization.clearEntityUpdateRequests',
};

export enum UpdateStateListType {
    Requested = 'Requests',
    Posted = 'Posted',
    Rejected = 'Rejected',
}

export type ListedEntitiesType = EntityType | CustomEntityUpdateListType;

export const ActionCreators = {
    clearUpdateRequests() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.clearEntityUpdateRequests,
            });
        };
    },
    editEntityUpdateRequest(id: string, newUpdates: EntityUpdate[]) {
        return (dispatch): Promise<void> =>
            editEntityUpdateRequest(id, newUpdates)
                .then((): void => {
                    dispatch({
                        type: ActionTypes.entityUpdateRequestEdited,
                        payload: { id, newUpdates },
                    });
                })
                .catch((err): void => {
                    switch (err.status) {
                        case 404:
                            dispatch(errorAction('This entity update does not exist'));
                            break;
                        case 403:
                            dispatch(errorAction("It's forbidden to edit this type of adjustment"));
                            break;
                        case 400:
                            dispatch(errorAction('No updates sent'));
                            break;
                        case 422:
                            dispatch(errorAction('Error in new updates'));
                            break;
                        default:
                            dispatch(errorAction('Unknown error'));
                    }
                });
    },
    loadEntityUpdateRequests(
        listedEntitiesType: ListedEntitiesType,
        listType: UpdateStateListType,
        page: number,
        limit: number,
        filters?: Filter[]
    ) {
        return (dispatch): Promise<void> => {
            return getEntityUpdateRequests(listedEntitiesType, listType, page, limit, filters).then(
                (response): void => {
                    dispatch({
                        type: ActionTypes.entityUpdateRequestsLoaded,
                        payload: response,
                    });
                }
            );
        };
    },
    loadTransactionRequests(listType: UpdateStateListType, page: number, limit: number) {
        return (dispatch): Promise<void> => {
            return getTransactionRequestsToAuthorize(listType, page, limit).then((response): void => {
                dispatch({
                    type: ActionTypes.transactionsLoaded,
                    payload: response,
                });
            });
        };
    },
    confirmTransactionRequest(tranasctionRequestId: string) {
        return (dispatch): Promise<void> => {
            return authorizeTransactionRequest(tranasctionRequestId)
                .then((response): void => {
                    dispatch({
                        type: ActionTypes.transactionRequestPosted,
                        payload: response,
                    });
                    dispatch(successAction(`Successfully authorized transaction request ${tranasctionRequestId}`));
                })
                .catch(
                    async (err: Response): Promise<void> => {
                        if (err.status === 422) {
                            const body = await err.json();
                            if (body.event === 'ErrInsufficient' || body.event === 'NotEnoughResources') {
                                return dispatch(
                                    errorAction(
                                        'Unable to post adjustment. The amount requested exceeds the available balance of the sender.'
                                    )
                                );
                            } else if (body.event === 'ErrClosed') {
                                return dispatch(
                                    errorAction('Unable to post adjustment. Sender entitlement has expired.')
                                );
                            }
                        }

                        throwErrorIfNotHandled(`Failed to authorize transaction request ${tranasctionRequestId}`)(err);
                    }
                );
        };
    },
    rejectTransactionRequest(transactionRequestId: string, comment: string) {
        return (dispatch): Promise<void> => {
            return rejectTransactionRequest(transactionRequestId, comment)
                .then((): void => {
                    dispatch({
                        type: ActionTypes.transactionRequestRejected,
                        payload: { id: transactionRequestId },
                    });
                    dispatch(successAction('Transaction request has been successfully rejected.'));
                })
                .catch(throwErrorIfNotHandled('Failed to reject transaction request.'));
        };
    },
    parkEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest, manager: Manager) {
        return (dispatch): Promise<void> => {
            return parkEntityUpdateRequest(entityUpdateRequest)
                .then((): void => {
                    dispatch({
                        type: ActionTypes.entityUpdateRequestParked,
                        payload: {
                            id: entityUpdateRequest.id,
                            manager: manager,
                        },
                    });
                    dispatch(successAction(`Succesfully parked beneficiary with id=${entityUpdateRequest.entityId}`));
                })
                .catch((): void => {
                    return dispatch(errorAction(`Could not park beneficiary with id=${entityUpdateRequest.entityId}`));
                });
        };
    },
    confirmEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest, callback?: () => void) {
        return (dispatch): Promise<void> => {
            const otpEnabledPosting = entityUpdateRequest.updates.find((v) => v.path === 'otpEnabled');
            if (otpEnabledPosting) {
                return authorizeEntityUpdateRequestWithOtp(entityUpdateRequest)
                    .then((): void => {
                        dispatch({
                            type: ActionTypes.entityUpdateRequestPosted,
                            payload: { id: entityUpdateRequest.id },
                        });
                        let message = `Successfully authorized entity update request ${entityUpdateRequest.id}`;
                        if (entityUpdateRequest.entityType === EntityType.managerDetails) {
                            message = `Successfully authorized entity update request ${entityUpdateRequest.id}. Changes will be applied after relogging.`;
                        }
                        dispatch(successAction(message));
                    })
                    .then(() => {
                        callback?.();
                    })
                    .catch((err): void => handleEntityUpdateConfirmationError(err, entityUpdateRequest, dispatch));
            }
            return authorizeEntityUpdateRequest(entityUpdateRequest)
                .then((): void => {
                    dispatch({
                        type: ActionTypes.entityUpdateRequestPosted,
                        payload: { id: entityUpdateRequest.id },
                    });
                    let message = `Successfully authorized entity update request ${entityUpdateRequest.id}`;
                    if (entityUpdateRequest.entityType === EntityType.managerDetails) {
                        message = `Successfully authorized entity update request ${entityUpdateRequest.id}. Changes will be applied after relogging.`;
                    }
                    dispatch(successAction(message));
                })
                .then(() => {
                    callback?.();
                })
                .catch((err): void => handleEntityUpdateConfirmationError(err, entityUpdateRequest, dispatch));
        };
    },
    signEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest, callback?: () => void) {
        return async (dispatch): Promise<void> => {
            try {
                await signEntityUpdateRequest(entityUpdateRequest);
                dispatch({
                    type: ActionTypes.entityUpdateRequestSigned,
                });
                const message = `Successfully signed entity update request ${entityUpdateRequest.id}`;
                dispatch(successAction(message));
                callback?.();
            } catch (error) {
                if (error.status === 403) {
                    compose(
                        dispatch,
                        errorAction
                    )(`You do not have sufficient permissions to sign entityUpdateRequest ${entityUpdateRequest.id}`);
                } else {
                    compose(dispatch, errorAction)(`Unable to sign entity update request ${entityUpdateRequest.id}`);
                }
            }
        };
    },
    rejectEntityUpdateRequest(entityUpdateRequest: EntityUpdateRequest, comment: string, callback?: () => void) {
        return (dispatch): Promise<void> => {
            return rejectEntityUpdateRequest(entityUpdateRequest, comment)
                .then(() => {
                    dispatch({
                        type: ActionTypes.entityUpdateRequestRejected,
                        payload: { id: entityUpdateRequest.id },
                    });
                    dispatch(successAction('Entity update request has been successfully rejected.'));
                })
                .then(() => {
                    callback?.();
                })
                .catch(throwErrorIfNotHandled('Failed to reject entity update request.'));
        };
    },
    changeListType(listType: UpdateStateListType) {
        return {
            type: ActionTypes.listTypeChanged,
            payload: listType,
        };
    },
};

export class TransactionRequest {
    id?: string;
    beneficiaryId?: string;
    vendor: NamedId;
    entitlements?: Array<SerializedBalanceEntry>;
    receiverId?: string;
    senderId?: string;
    type?: TransactionType;
    createdAt?: string;
    source?: string;
    reason?: string;
    authorization?: {
        id?: string;
        causedByTransactionId?: string;
        comment?: string;
        createdAt?: string;
        authorizedAt?: string;
        deletedAt?: string;
        authorizedByManager?: NamedId;
        createdByManager?: NamedId;
        deletedByManager?: NamedId;
    };
    originalTransaction?: {
        id?: number;
        category?: string;
        originalAmount?: number;
        currentAmount?: number;
        currency?: string;
        type?: string;
    };
}

export class EntityUpdate {
    constructor(public path: string, public newValue, public oldValue?) {}
}

export class EntityUpdateReference {
    id: string;
    entityId: string;
    entityType: EntityType;
}

export enum CustomEntityUpdateListType {
    beneficiaryAllUpdates = 'beneficiaries',
}

export class EntityUpdateRequest {
    id?: string;
    title?: Array<string>;
    entityType?: EntityType;
    entityId?: string;
    updates?: EntityUpdate[];
    authorizedAt?: string;
    preParkedByPartnerId?: string;
    createdByManager?: NamedId;
    authorizedByManager?: NamedId;
    createdAt?: string;
    deletedAt?: string;
    deletedByManager?: NamedId;
    comment?: string;
    signatureChain?: Record<string, any>;
    validFrom?: Date | string;
    updateFile?: AsyncTask;
    documentUploadedByManager: { name: string };
}

export class ProductUpdate {
    constructor(
        public path: string,
        public newValue: {
            minPrice: string;
            maxPrice: string;
            isFixed?: boolean;
        },
        public oldValue?: { minPrice: string; maxPrice: string; isFixed?: boolean }
    ) {}
}

export class ProductUpdateRequest extends EntityUpdateRequest {
    entityType: EntityType.productManagement;
    updates?: ProductUpdate[];
}

export class BeneficiaryDetailsChangesRequest {
    id?: string;
}

export class TaskAuthorizationsState {
    constructor(
        public transactionRequests: PagedState<TransactionRequest> = new PagedState(),
        public entityUpdateRequests: PagedState<EntityUpdateRequest | ProductUpdateRequest> = new PagedState(),
        public activeListType: UpdateStateListType = UpdateStateListType.Requested
    ) {}
}

function pagedStateWithoutTransactionRequestWithId(
    pagedState: PagedState<TransactionRequest>,
    transactionRequestId: string
) {
    const newState = clonedeep(pagedState) as PagedState<TransactionRequest>;
    newState.items = newState.items.filter((request) => request.id !== transactionRequestId);
    return newState;
}

function pagedStateWithoutEntityUpdateRequestWithId(
    pagedState: PagedState<EntityUpdateRequest>,
    entityUpdateRequestId: string
) {
    const newState = clonedeep(pagedState) as PagedState<EntityUpdateRequest>;
    newState.items = newState.items.filter((request) => request.id !== entityUpdateRequestId);
    return newState;
}

function pagedStateAfterParkingEntityUpdateRequestWithId(
    pagedState: PagedState<EntityUpdateRequest>,
    entityUpdateRequestId: string,
    manager: Manager
) {
    const newState = clonedeep(pagedState) as PagedState<EntityUpdateRequest>;
    newState.items = newState.items.map((item) => {
        if (item.id === entityUpdateRequestId) {
            const newItem = item;
            newItem.createdByManager = {
                id: manager.id,
                name: [manager.firstName, manager.lastName].join(' '),
            };
            return newItem;
        } else {
            return item;
        }
    });
    return newState;
}

function pagedStateAfterEditingEntityUpdateRequestWithId(
    pagedState: PagedState<EntityUpdateRequest>,
    entityUpdateRequestId: string,
    newUpdates: EntityUpdate[]
) {
    const newState = clonedeep(pagedState) as PagedState<EntityUpdateRequest>;
    newState.items = newState.items.map((item) => {
        if (item.id === entityUpdateRequestId) {
            const newItem = item;
            newItem.updates = newUpdates;
            newItem.entityId = newUpdates.find((u) => u.path === 'id').newValue;
            return newItem;
        } else {
            return item;
        }
    });
    return newState;
}

export function taskAuthorizationsReducer(
    state: TaskAuthorizationsState = new TaskAuthorizationsState(),
    action: AnyAction
) {
    switch (action.type) {
        case ActionTypes.transactionsLoaded:
            return new TaskAuthorizationsState(action.payload, state.entityUpdateRequests, state.activeListType);
        case ActionTypes.transactionRequestPosted:
            const stateAfterTransactionPost = pagedStateWithoutTransactionRequestWithId(
                state.transactionRequests,
                action.payload.id
            );
            return new TaskAuthorizationsState(
                stateAfterTransactionPost,
                state.entityUpdateRequests,
                state.activeListType
            );
        case ActionTypes.transactionRequestRejected:
            const stateAfterTransactionRejection = pagedStateWithoutTransactionRequestWithId(
                state.transactionRequests,
                action.payload.id
            );
            return new TaskAuthorizationsState(
                stateAfterTransactionRejection,
                state.entityUpdateRequests,
                state.activeListType
            );
        case ActionTypes.entityUpdateRequestsLoaded:
            return new TaskAuthorizationsState(state.transactionRequests, action.payload, state.activeListType);
        case ActionTypes.entityUpdateRequestEdited:
            const stateAfterEntityUpdateRequestEditing = pagedStateAfterEditingEntityUpdateRequestWithId(
                state.entityUpdateRequests,
                action.payload.id,
                action.payload.newUpdates
            );
            return new TaskAuthorizationsState(
                state.transactionRequests,
                stateAfterEntityUpdateRequestEditing,
                state.activeListType
            );
        case ActionTypes.entityUpdateRequestParked:
            const stateAfterEntityUpdateRequestParking = pagedStateAfterParkingEntityUpdateRequestWithId(
                state.entityUpdateRequests,
                action.payload.id,
                action.payload.manager
            );
            return new TaskAuthorizationsState(
                state.transactionRequests,
                stateAfterEntityUpdateRequestParking,
                state.activeListType
            );
        case ActionTypes.entityUpdateRequestPosted:
            const stateAfterEntityUpdatePost = pagedStateWithoutEntityUpdateRequestWithId(
                state.entityUpdateRequests,
                action.payload.id
            );
            return new TaskAuthorizationsState(
                state.transactionRequests,
                stateAfterEntityUpdatePost,
                state.activeListType
            );
        case ActionTypes.entityUpdateRequestRejected:
            const stateAfterEntityUpdateRejection = pagedStateWithoutEntityUpdateRequestWithId(
                state.entityUpdateRequests,
                action.payload.id
            );
            return new TaskAuthorizationsState(
                state.transactionRequests,
                stateAfterEntityUpdateRejection,
                state.activeListType
            );
        case ActionTypes.listTypeChanged:
            return new TaskAuthorizationsState(state.transactionRequests, state.entityUpdateRequests, action.payload);
        case ActionTypes.clearEntityUpdateRequests:
            return new TaskAuthorizationsState();
    }
    return state;
}
