import * as React from 'react';
import { getStyles, limit, usePaymentPeriods } from './utils/usePaymentPeriods';
import { SideMenu } from '../utils/SideMenu';
export function PaymentPeriods({ activeCategory }) {
    const { activePeriod, handlePaginate, handleSelect, periods, page, paging } = usePaymentPeriods(activeCategory);
    if (!periods.length) return null;
    return (
        <>
            <div style={getStyles(page > 1)}>
                <a onClick={handlePaginate('previous')}>Later Periods</a>
            </div>
            <SideMenu
                activeTab={activePeriod}
                onSelectTab={handleSelect}
                pendingActions={periods.map(({ pendingActions }) => pendingActions)}
                tabs={periods.map((period) => period.label)}
            />
            <div style={getStyles(paging.total - limit > paging.offset)}>
                <a onClick={handlePaginate('next')}>Earlier Periods</a>
            </div>
        </>
    );
}
