import * as React from 'react';
import { BeneficiaryStatuses, Beneficiary, BeneficiaryAccountType } from './beneficiaries';
import { ReadonlyCard } from './Cards';
import SourceReasonDialog from './edit/SourceReasonDialog';
import { capitalizeFirstLetter, currencyFormatter, displayTime, wfpFormat } from '../utils/utils';
import { SerializedBalanceEntry } from '../vendors/model';
import { EntityType } from '../authorization/taskAuthorizations';
import { InformationDialog } from '../utils/Dialogs';
import hasConflictingBeneficiaryUpdates from '../utils/hasConflictingBeneficiaryUpdates';
import { PredefinedCurrency } from '../app/appConfig';
import StatusInfoBalance from './StatusInfoBalance';
import { ExpirationTokenType } from '../transactions/transactions';

const spacing: React.CSSProperties = { width: 20 };
const containerPairs: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
};

interface Props {
    beneficiary?: Beneficiary;
    status: string;
    groupType: string;
    accountType: string;
    canBlock: boolean;
    canActivate: boolean;
    reason?: string;
    source?: string;
    zeroingSource?: string;
    zeroingReason?: string;
    statusChangedAt?: string;
    balanceBeforeBlock?: SerializedBalanceEntry[];
    balanceBeforeZeroing?: {
        active: SerializedBalanceEntry[];
        inactive: SerializedBalanceEntry[];
    };
    onStatusUpdate: (status: BeneficiaryStatuses, reason: string, source: string) => void;
    categories: string[];
    currency: PredefinedCurrency;
    shouldShowAccountType: boolean;
}

interface State {
    reason: string;
    source: string;
    showBlockDialog: boolean;
    hasPendingStatusUpdate: boolean;
}

export default class EditBeneficiaryStatus extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            reason: '',
            source: '',
            showBlockDialog: false,
            hasPendingStatusUpdate: false,
        };
    }

    onBeneficiaryStatusUpdate(reason: string, source: string) {
        const status = this.statusToBeSet();
        this.props.onStatusUpdate(status, reason, source);
        this.onCloseDialogs();
    }

    hasPendingStatusUpdate() {
        const conflictingUpdates = [
            EntityType.beneficiarySingleBlock,
            EntityType.beneficiarySingleUnblock,
            EntityType.beneficiaryZeroingBlock,
            EntityType.beneficiaryZeroing,
        ];
        const hasConflictingUpdates = hasConflictingBeneficiaryUpdates(this.props.beneficiary, conflictingUpdates);
        return hasConflictingUpdates;
    }

    onBlockButtonClick() {
        const hasPendingStatusUpdate = this.hasPendingStatusUpdate();
        if (hasPendingStatusUpdate) {
            this.setState({ hasPendingStatusUpdate });
        } else {
            this.setState({ showBlockDialog: true });
        }
    }

    onCloseDialogs() {
        this.setState({ showBlockDialog: false });
    }

    renderStatusInfo(reason: string, source: string, isZeroing = false) {
        const isActive = this.props.status === BeneficiaryStatuses.active;
        let tittle = isActive ? 'Activation reason' : 'Deactivation reason';
        tittle = isZeroing ? 'Zeroing reason' : tittle;
        return (
            <div style={containerPairs}>
                <ReadonlyCard image="thematic-evaluation-pos" title={tittle} value={reason || ''} />
                <div style={spacing} />
                <ReadonlyCard image="thematic-evaluation-pos" title="Source" value={source || ''} />
            </div>
        );
    }

    renderTimeStatusChange() {
        const statusChangedAt = this.props.statusChangedAt;
        return (
            <ReadonlyCard
                image="thematic-monitoring-pos"
                title={'Last Status Change'}
                value={statusChangedAt ? displayTime(statusChangedAt) : '-'}
            />
        );
    }

    // renderStatusInfoBalance(balanceBefore: BalanceEntry[], action: string) {
    //     let components;
    //     if (!balanceBefore) {
    //         components = this.props.categories.map((category, index) => {
    //             let image;
    //             switch (category) {
    //                 case 'FOOD':
    //                     image = 'thematic-food-security-cluster-pos';
    //                     break;
    //                 case 'WASH':
    //                     image = 'thematic-food-for-assets-pos';
    //                     break;
    //                 case 'CASH':
    //                     image = 'thematic-cash-pos';
    //                     break;
    //                 default:
    //                     image = 'thematic-food-pos';
    //                     break;
    //             }
    //             return (
    //                 <ReadonlyCard
    //                     image={image}
    //                     key={index}
    //                     title={`${category} balance before last ${action}`}
    //                     value={'-'}
    //                 />
    //             );
    //         });
    //     } else {
    //         components = balanceBefore
    //             .filter((before) =>
    //                 this.props.categories.includes(before.category)
    //             )
    //             .map((balance, index) => {
    //                 const image = 'thematic-resourcing-pos'; // balance.category === 'FOOD' ? 'thematic-food-pos' : 'thematic-resourcing-pos'
    //                 return (
    //                     <ReadonlyCard
    //                         image={image}
    //                         key={index}
    //                         title={`${balance.category} balance before last ${action}`}
    //                         value={currencyFormatter(
    //                             this.props.currency
    //                         ).format(balance.value)}
    //                     />
    //                 );
    //             });
    //     }
    //     const componentsWithSpacing = [];
    //     components.forEach((card, index) => {
    //         if (index !== 0) {
    //             componentsWithSpacing.push(
    //                 <div key={'spacing-' + index} style={spacing} />
    //             );
    //         }
    //         componentsWithSpacing.push(card);
    //     });
    //     return componentsWithSpacing;
    // }

    statusToBeSet(): BeneficiaryStatuses {
        const shouldDeactivate = this.props.status === BeneficiaryStatuses.active;
        return shouldDeactivate ? BeneficiaryStatuses.blocked : BeneficiaryStatuses.active;
    }

    renderDataUpdateForm() {
        const displayedTitle = `Status change - ${this.props.status} to ${this.statusToBeSet()}`;
        return (
            <SourceReasonDialog
                confirmTitle="Park"
                onClose={this.onCloseDialogs.bind(this)}
                onConfirm={this.onBeneficiaryStatusUpdate.bind(this)}
                title={displayedTitle}
            />
        );
    }

    renderStatusCard() {
        return (
            <ReadonlyCard
                image="thematic-monitoring-pos"
                title="Status"
                value={capitalizeFirstLetter(this.props.status)}
            />
        );
    }

    renderAccountTypesInfo(accountType: string, groupType: string) {
        return (
            <div style={containerPairs}>
                <ReadonlyCard
                    image="thematic-monitoring-pos"
                    title="Account Type"
                    value={wfpFormat(accountType?.replace(/-/g, ' '))}
                />
                <div style={spacing} />
                <ReadonlyCard
                    image="thematic-monitoring-pos"
                    title="Group type"
                    value={wfpFormat(groupType?.replace(/-/g, ' '))}
                />
            </div>
        );
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Beneficiary status cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingStatusUpdate: false })}
                title="Pending updates"
            />
        );
    }

    render() {
        const { showBlockDialog, hasPendingStatusUpdate } = this.state;
        const isActive = this.props.status === BeneficiaryStatuses.active;
        const buttonText = isActive ? 'Block' : 'Activate';
        const canBeBlocked = isActive && this.props.canBlock;
        const canBeActivated = !isActive && this.props.canActivate;
        return (
            <div>
                <h6>Status</h6>
                <div className="wfp-form--group">
                    {hasPendingStatusUpdate && this.renderPendingUpdatesDialog()}
                    {this.renderStatusCard()}
                    {this.props.shouldShowAccountType
                        ? this.renderAccountTypesInfo(this.props.accountType, this.props.groupType)
                        : null}
                    {this.renderStatusInfo(this.props.reason, this.props.source)}
                    {this.renderStatusInfo(this.props.zeroingReason, this.props.zeroingSource, true)}

                    {this.renderTimeStatusChange()}
                    {/*<div style={containerPairs}>*/}
                    {/*{this.renderStatusInfoBalance(this.props.balanceBeforeBlock, 'block')}*/}
                    {/*</div>*/}
                    {/*<div style={containerPairs}>*/}
                    {/*    {this.renderStatusInfoBalance(*/}
                    {/*        this.props.balanceBeforeZeroing,*/}
                    {/*        'zeroing'*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    {[ExpirationTokenType.active, ExpirationTokenType.future, ExpirationTokenType.expired].map(
                        (expirationTokenType) => (
                            <StatusInfoBalance
                                action={'zeroing'}
                                balanceBefore={
                                    this.props.balanceBeforeZeroing &&
                                    this.props.balanceBeforeZeroing[expirationTokenType]
                                }
                                categories={this.props.categories}
                                currency={this.props.currency}
                                expirationTokenType={expirationTokenType}
                                key={expirationTokenType}
                            />
                        )
                    )}
                    {showBlockDialog && this.renderDataUpdateForm()}
                    {(canBeActivated || canBeBlocked) && (
                        <div className={'wfp-form--actions text-center'}>
                            <button
                                className="wfp-btn--primary"
                                onClick={this.onBlockButtonClick.bind(this)}
                                type="submit"
                            >
                                {buttonText}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
