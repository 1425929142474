import Manager from '../../managers/manager';
import { hasFeatureAccess } from '../../login/auth';
import { StatusContainer } from '../style/grouped-prices-page.style';
import * as React from 'react';
import { ProductPrice } from '../types/product-price.type';
import { useDispatch, useSelector } from 'react-redux';
import { productPriceActions } from '../state/actions';
import State from '../../app/store/state';
import { ManagerPermission } from '../../permission-profiles/permission';
export const statuses = ['active', 'future', 'parked', 'rejected'] as const;
export const columns = [
    'BC Token',
    'Group',
    'Unit',
    'Item',
    'Min Price',
    'Max Price',
    'Vendors',
    'Valid from',
    'Valid until',
    'Status',
] as const;

export const multisortColumns = [
    { label: 'BC Token', column: 'product.category.token' },
    { label: 'Group', column: '' },
    { label: 'Unit', column: 'product.unit' },
    { label: 'Item', column: 'product.name' },
    { label: 'Price', column: 'minPrice' },
    { label: 'Vendors', column: '' },
    { label: 'Valid from', column: 'validFrom' },
    { label: 'Valid until', column: 'expiry' },
    { label: 'Status', column: '' },
] as { label: typeof columns[number]; column: string }[];

export function Actions({ manager, price }: { manager: Manager; price: ProductPrice }) {
    const dispatch = useDispatch();
    const productPricesState = useSelector((state: State) => state.productPrices);
    const cancelPrice = productPriceActions.cancelProductPrice(dispatch, productPricesState);
    const authorizePrice = productPriceActions.authorizeProductPrice(dispatch, productPricesState);
    const handleCancel = async () => {
        await cancelPrice(price.id);
    };
    const handlePost = async () => {
        await authorizePrice(price.id);
    };

    if (price.status === 'parked') {
        if (Number(manager?.id) == Number(price?.createdByManagerId)) {
            return (
                <StatusContainer>
                    <button onClick={handleCancel}>Cancel</button>
                </StatusContainer>
            );
        }
        if (hasFeatureAccess({ manager }, ManagerPermission.productsVerify)) {
            return (
                <StatusContainer>
                    <button onClick={handleCancel}>Reject</button>
                    <button onClick={handlePost}>Post</button>
                </StatusContainer>
            );
        }
    }
    return null;
}
