import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import PagingComponent from '../../utils/paging';
import {
    getColumns,
    formatNumberCell,
    getReducedSumsFromData,
    mapRangeFromConfigToRange,
    mapRowToMergedData,
    roundKey,
} from './helpers';
import { useSelector } from 'react-redux';
import State from '../../app/store/state';
const bordersForTables = '1px solid #e8e8e8';
const defaultPageSize = 24;
const initialReducedSums = {
    uniqueToday: 0,
    uniqueAverage: 0,
    uniqueSum: 0,
    allToday: 0,
    allAverage: 0,
    allSum: 0,
};
const hoursToRangeConfig = [[0, 1, 2, 3, 4, 5, 6, 7], 8, 9, 10, 11, 12, 13, 14, 15, 16, [17, 18, 19, 20, 21, 22, 23]];
const columnBorders = [0, 3];
const columnBordersForColspanHeader = [0, 1];
interface Props {
    todayData: any;
    filteredData: any;
}
export const MobilizationStatisticsTable = React.memo(function MobilizationStatisticsTable({
    todayData,
    filteredData,
}: Props) {
    const tz = useSelector((state: State) => state.appConfig.timeZone);
    const [reducedSums, setReducedSums] = useState(initialReducedSums);
    const memoizedData = React.useMemo(() => {
        const mergedData = todayData.map(mapRowToMergedData(filteredData, todayData.length));
        const dataReducedByHoursRangeConfig = hoursToRangeConfig
            .map(mapRangeFromConfigToRange(mergedData))
            .map(roundKey('uniqueAverage'))
            .map(roundKey('allAverage'));

        const reducedSums = getReducedSumsFromData(mergedData, initialReducedSums);

        setReducedSums(reducedSums);

        return dataReducedByHoursRangeConfig;
    }, [filteredData, todayData]);
    const columns = useMemo(() => getColumns(tz), [tz]);
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, gotoPage } = useTable(
        {
            columns,
            data: memoizedData,
            initialState: { pageSize: defaultPageSize },
        },
        usePagination
    );
    const [paging, setPaging] = useState({
        offset: 0,
        limit: defaultPageSize,
        totalAtLeast: todayData.length,
        total: todayData.length,
    });

    useEffect(() => {
        setPaging({
            offset: 0,
            limit: defaultPageSize,
            totalAtLeast: todayData.length,
            total: todayData.length,
        });
    }, [todayData]);

    const onPageChanged = (page) => {
        setPaging({ ...paging, offset: defaultPageSize * (page - 1) });
        gotoPage(page - 1);
    };
    return (
        <div className={'col-sm-12'}>
            <table {...getTableProps()} className={'wfp-table--striped mt4 table-hover'}>
                <thead>
                    {headerGroups.map((headerGroup, rowKey) => {
                        return (
                            <tr {...headerGroup.getHeaderGroupProps()} key={`head-tr-${rowKey}`}>
                                {headerGroup.headers.map((column, columnKey) => {
                                    const borders = rowKey === 0 ? columnBordersForColspanHeader : columnBorders;
                                    let borderRight;
                                    if (borders.includes(columnKey)) {
                                        borderRight = bordersForTables;
                                    }
                                    return (
                                        <th
                                            style={{ textAlign: 'right', borderRight }}
                                            {...column.getHeaderProps()}
                                            key={`th-${columnKey}`}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={`tr-${key}`}>
                                {row.cells.map((cell, rowKey) => {
                                    let borderRight;
                                    if (columnBorders.includes(rowKey)) {
                                        borderRight = bordersForTables;
                                    }
                                    return (
                                        <td
                                            style={{ textAlign: 'right', borderRight }}
                                            {...cell.getCellProps()}
                                            key={`td-${rowKey}`}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {page.length > 1 && (
                        <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold', borderRight: bordersForTables }}>
                                Total
                            </td>
                            <td style={{ textAlign: 'right' }}>{formatNumberCell(reducedSums.uniqueToday)}</td>
                            <td style={{ textAlign: 'right' }}>{formatNumberCell(reducedSums.uniqueAverage)}</td>
                            <td style={{ textAlign: 'right', borderRight: bordersForTables }}>
                                {formatNumberCell(reducedSums.uniqueSum)}
                            </td>
                            <td style={{ textAlign: 'right' }}>{formatNumberCell(reducedSums.allToday)}</td>
                            <td style={{ textAlign: 'right' }}>{formatNumberCell(reducedSums.allAverage)}</td>
                            <td style={{ textAlign: 'right' }}>{formatNumberCell(reducedSums.allSum)}</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <PagingComponent key="beneficiariesTransactionPagination" onPageChanged={onPageChanged} paging={paging} />
        </div>
    );
});

MobilizationStatisticsTable.displayName = 'MobilizationStatisticsTable';
