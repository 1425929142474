import * as React from 'react';
import { Form } from '../utils/inputs';
import { Button, InputWrapper, PanelAccessSwitch, usePartnerForm } from './helpers/PartnerFormHelpers';
import { PartnerStatuses } from './partners';

interface Props {
    fields: {
        label: string;
        name: string;
        isFocused?: boolean;
        initialValue?: string;
        type?: string;
        required?: boolean;
        Component?: React.FC<any>;
    }[];
    onSubmit: (formData: { [key: string]: string }) => void;
    switchStatus?: boolean;
}
export const PartnerForm: React.FC<Props> = ({ fields, onSubmit, switchStatus }) => {
    const { handleSubmit, handleChange, data, handleActiveChange } = usePartnerForm({ fields, onSubmit });
    return (
        <Form className="wfp-form col-sm-12" onSubmit={handleSubmit}>
            {fields.map(({ Component, ...input }) =>
                Component ? (
                    <Component key={input.name} {...input} onChange={handleChange} />
                ) : (
                    <InputWrapper
                        isFocused={input.isFocused}
                        key={input.name}
                        label={input.label}
                        name={input.name}
                        onChange={handleChange}
                        type={input.type}
                        value={data[input.name]}
                    />
                )
            )}
            {switchStatus ? (
                <PanelAccessSwitch
                    checked={data['status'] === PartnerStatuses.active}
                    disabled={false}
                    name={'status'}
                    onClick={handleActiveChange}
                />
            ) : (
                ''
            )}
            <Button disabled={false}>Park</Button>
        </Form>
    );
};
