import React = require('react');
import { AsyncTask, AsyncTasksTypes } from '../utils/asyncTasks';
import { PagedState } from '../utils/paging';
import { bindActionCreators } from 'redux';
import { ActionCreators as AsyncTaskActionCreators } from '../utils/asyncTasks';
import State from '../app/store/state';
import { connect } from 'react-redux';
import { RefreshSignaler } from '../utils/refresher';
import { Link } from 'react-router';
import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';
import { PredefinedDateRanges } from '../../utils/createPredefinedFilters';
import { Filter, DateSelectFilter } from '../utils/FilterTypes';
import ActivityLogsExportDateFilterField from '../utils/filterFields/ActivityLogsExportDateFilterField';

export enum ActivityLogPageType {
    Manager = 'manager',
    Beneficiary = 'beneficiary',
    Vendor = 'vendor',
}

interface OwnProps {
    entityType: ActivityLogPageType;
    activities: any;
    entityId: string;
    selectedOptions: string[];
    exportActivityLog: (managerId: string, options: any, filter: any) => void;
}

interface Props extends OwnProps {
    exports: PagedState<AsyncTask>;
    managerId: string;
    loadAsyncTasksList: (page: number, limit: number, type: string) => Promise<RefreshSignaler>;
    downloadFile: (type: string, id: string) => string;
    appendAsyncTasksList: (limit: number, type: string) => void;
    authorizeTask: (type: string, id: string) => Promise<any>;
}

const defaultFilter = new DateSelectFilter('Activity Date', 'createdAt', '', PredefinedDateRanges);

interface ExportLogState {
    selectedOptions: string[];
    filter: any;
    selectAll: boolean;
}
class ActivityLogExportPage extends React.Component<Props, ExportLogState> {
    private defaultPageSize = 10;
    private refresher?: RefreshSignaler;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedOptions: props.selectedOptions,
            filter: defaultFilter,
            selectAll: true,
        };
    }

    async UNSAFE_componentWillMount() {
        this.refresher = await this.props.loadAsyncTasksList(
            1,
            this.defaultPageSize,
            AsyncTasksTypes.EXPORT_ACTIVITY_LOG
        );
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    async changePageRequested() {
        this.props.appendAsyncTasksList(this.defaultPageSize, AsyncTasksTypes.EXPORT_ACTIVITY_LOG);
    }

    _handleCheck(name) {
        let newOptions;
        if (this.state.selectedOptions.includes(name)) {
            newOptions = this.state.selectedOptions.filter((o) => o !== name);
            this.setState({ selectedOptions: newOptions });
        } else {
            newOptions = this.state.selectedOptions;
            newOptions.push(name);
            this.setState({ selectedOptions: newOptions });
        }
    }

    _handleCheckAll(event) {
        const value = event.target.checked;
        const selectedOptions = [];

        if (value) {
            this.props.activities.forEach((column) => {
                selectedOptions.push(column.key);
            });
        }

        this.setState({ selectedOptions, selectAll: value });
    }

    private applyFilters(filter: Filter) {
        this.setState({ filter });
    }

    renderExportFeature() {
        return (
            <div>
                <p>Select what to export</p>
                <div className="wfp-form--group">
                    <div className="checkbox" key="all" style={{ marginBottom: 25 }}>
                        <label>
                            <input
                                checked={this.state.selectAll}
                                name="all"
                                onChange={this._handleCheckAll.bind(this)}
                                type="checkbox"
                            />{' '}
                            Select All
                        </label>
                    </div>
                    {this.props.activities.map((column) => (
                        <div className="checkbox" key={column.key}>
                            <label>
                                <input
                                    checked={this.state.selectedOptions.includes(column.key) || false}
                                    name={column.key}
                                    onChange={this._handleCheck.bind(this, column.key)}
                                    type="checkbox"
                                />{' '}
                                {column.header}
                            </label>
                        </div>
                    ))}
                    <div>
                        <ActivityLogsExportDateFilterField
                            filter={this.state.filter}
                            filterChanged={this.applyFilters.bind(this)}
                            key="dateFilter"
                            withIsSelected={true}
                        />
                    </div>
                </div>

                <div className="wfp-form--actions">
                    <button className="wfp-btn" type="submit">
                        Generate
                    </button>
                </div>
            </div>
        );
    }

    exportActivityLog(event) {
        event.preventDefault();
        this.props.exportActivityLog(this.props.entityId, this.state.selectedOptions, this.state.filter);
    }

    renderNav = (activity: ActivityLogPageType) => {
        switch (activity) {
            case ActivityLogPageType.Manager:
                return (
                    <nav className="wfp-breadcrumbs">
                        <ol className="breadcrumbs--wrapper">
                            <li className="breadcrumbs--item">
                                <Link className="breadcrumbs--link" to="/home">
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <Link className="breadcrumbs--link" to={`/admin/${this.props.entityId}`}>
                                    <span>User Details</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <span className="breadcrumbs--last">Download Activity Log</span>
                            </li>
                        </ol>
                    </nav>
                );
            case ActivityLogPageType.Beneficiary:
                return (
                    <nav className="wfp-breadcrumbs">
                        <ol className="breadcrumbs--wrapper">
                            <li className="breadcrumbs--item">
                                <Link className="breadcrumbs--link" to="/home">
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <Link
                                    className="breadcrumbs--link"
                                    to={{
                                        pathname: '/beneficiaries/beneficiary',
                                        state: {
                                            beneficiaryId: this.props.entityId,
                                        },
                                    }}
                                >
                                    <span>Beneficiary Details</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <span className="breadcrumbs--last">Download Activity Log</span>
                            </li>
                        </ol>
                    </nav>
                );
            case ActivityLogPageType.Vendor:
                return (
                    <nav className="wfp-breadcrumbs">
                        <ol className="breadcrumbs--wrapper">
                            <li className="breadcrumbs--item">
                                <Link className="breadcrumbs--link" to="/home">
                                    <span>Home</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <Link className="breadcrumbs--link" to={`/vendors/${this.props.entityId}`}>
                                    <span>Vendor Details</span>
                                </Link>
                            </li>
                            <li className="breadcrumbs--item">
                                <span className="breadcrumbs--last">Download Activity Log</span>
                            </li>
                        </ol>
                    </nav>
                );
        }
    };

    render() {
        return (
            <main>
                {this.renderNav(this.props.entityType)}
                <h3>Download Activity Logs</h3>
                <form onSubmit={this.exportActivityLog.bind(this)}>{this.renderExportFeature()}</form>
                {this.props.exports && (
                    <div>
                        <AsyncTasksListExportView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={this.props.managerId}
                            canDownload={true}
                            data={this.props.exports.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <div className="wfp-form--actions">
                            <button
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                                onClick={this.changePageRequested.bind(this)}
                                type="button"
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }
}

function mapDispatchToProps(dispatch: any, ownProps: OwnProps) {
    return {
        loadAsyncTasksList: bindActionCreators(AsyncTaskActionCreators.loadAsyncTasksList, dispatch),
        downloadFile: bindActionCreators(AsyncTaskActionCreators.downloadFile, dispatch),
        appendAsyncTasksList: bindActionCreators(AsyncTaskActionCreators.appendAsyncTasksList, dispatch),
        authorizeTask: bindActionCreators(AsyncTaskActionCreators.authorizeAsyncTask, dispatch),
        exportActivityLog: bindActionCreators(ownProps.exportActivityLog, dispatch),
    };
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        entityType: ownProps.entityType,
        activities: ownProps.activities,
        exports: state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_ACTIVITY_LOG],
        managerId: state.auth.manager ? state.auth.manager.id : null,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(ActivityLogExportPage as any);
