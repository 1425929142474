import { useDispatch, useSelector } from 'react-redux';
import { loadProductQuantityOptions, loadProductUnits, parkAndAuthorizeProduct } from '../../apiClient';
import { Product } from '../helpers';
import { errorAction, successAction } from '../../utils/notifications';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import State from '../../app/store/state';
import { wfpFormat } from '../../utils/utils';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

export function useProductsAdd() {
    const dispatch = useDispatch();
    const [units, setUnits] = useState([]);
    const [quantityOptions, setQuantityOptions] = useState([]);
    useEffect(() => {
        Promise.all([loadProductUnits(), loadProductQuantityOptions()])
            .then(([units, quatityOptions]) => {
                setUnits(
                    Object.entries(units).map(([key, value]) => ({
                        label: `${value} - ${key}`,
                        value: `${value} - ${key}`,
                    }))
                );
                setQuantityOptions(
                    Object.values(quatityOptions).map((val: string) => ({
                        value: wfpFormat(val),
                        label: wfpFormat(val),
                    }))
                );
            })
            .catch((v) => v);
    }, []);
    const handleSubmit = (event) => {
        event.preventDefault();
        product.unit = product.unit?.split(' - ')?.[0]?.trim();
        product.quantityOption = product.quantityOption.toLowerCase();

        parkAndAuthorizeProduct(product as Product)
            .then(() => {
                dispatch(routerHelperActions.makeRedirect('/products'));
                dispatch(successAction('Product added'));
            })
            .catch(() => {
                dispatch(errorAction('Cannot add product'));
            });
    };

    const [product, setProduct] = useState<Partial<Product>>({});
    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement> | any) => {
        event.persist?.();
        setProduct((state) => ({ ...state, [event.target.name]: event.target.value }));
    }, []);
    const categories = useSelector((state: State) =>
        state.appConfig.entitlementsConfig.categories.map((category) => ({
            value: category,
            label: category,
        }))
    );
    return { categories, handleSubmit, handleChange, product, units, quantityOptions };
}
