import * as React from 'react';
import { Calendar } from 'react-date-range';
import { Input } from './inputs';
import { useCallback, useEffect, useState } from 'react';
import * as moment from 'moment';
import { useUpdateEffect } from './useUpdateEffect';
import styled from 'styled-components';
import { countries } from './countries';
import { wfpFormat } from './utils';

interface Props {
    initialValue?: Date;
    onChange?: (data: { name: string; value: string }) => void;
    name?: string;
    label?: string;
    minDate?: Date;
    maxDate?: Date | string;
    minTime?: string;
    disabled?: boolean;
    value?: Date;
    showLocalTime?: boolean;
    timezone?: string;
    country?: countries;
}

const defaultValue = formatDate(new Date());
const minute = 1000 * 60;

export const DateAndTimePicker: React.FC<Props> = function DateAndTimePicker({
    initialValue,
    onChange,
    name,
    label,
    minDate,
    maxDate,
    disabled,
    value: _value,
    timezone,
    showLocalTime,
    country,
}) {
    const [localTime, setLocalTime] = useState<string>();
    useEffect(() => {
        if (showLocalTime) {
            setLocalTime(moment(new Date()).tz(timezone).format('YYYY-MM-DD HH:mm'));
            const intervalId = setInterval(() => {
                setLocalTime(moment(new Date()).tz(timezone).format('YYYY-MM-DD HH:mm'));
            }, minute);
            return () => clearInterval(intervalId);
        }
    }, []);
    const [date, setDate] = useState<string | Date>(formatDate(initialValue) || defaultValue);
    const [time, setTime] = useState<string>(moment(new Date()).tz(timezone).format('HH:mm'));
    const [selected, setSelected] = useState<HTMLButtonElement>(null);
    const highlightSelectedDay = (event) => {
        event.persist();
        const el = event.target as HTMLElement;
        if (!Array.from(el.classList).includes('rdrDay')) return;
        if (selected) {
            selected.dataset.state = '';
        }
        el.dataset.state = 'selected';
        setSelected(el as HTMLButtonElement);
    };
    const [isVisible, setIsVisible] = useState(false);
    const handleChange = useCallback(
        (date) => {
            if (disabled) return;
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setDate(formattedDate);
            onChange({ value: `${formattedDate} ${time}`, name: name || 'dateAndTimePicker' });
        },
        [disabled]
    );
    useUpdateEffect(() => {
        if (_value) {
            setDate(formatDate(_value));
        }
    }, [_value]);
    const toggleCalendar = useCallback(() => {
        if (disabled) return;
        setIsVisible((state) => !state);
    }, [disabled]);
    return (
        <Container>
            <InputWrapper
                disabled={disabled ?? false}
                label={label}
                name={name || 'datePicker'}
                onClick={toggleCalendar}
                value={`${date} ${time}`}
            />
            {isVisible && (
                <div className="calendar-container" onClick={highlightSelectedDay}>
                    <StyledCalendar
                        className="rdrDateRangePickerWrapper"
                        maxDate={new Date(maxDate)}
                        minDate={minDate ? new Date(minDate) : new Date()}
                        onChange={handleChange}
                    />
                    <div className="time-picker-container">
                        <input
                            name="time"
                            onChange={(event) => {
                                event.persist();
                                setTime(event.target.value);
                                onChange({ value: `${date} ${event.target.value}`, name: name || 'dateAndTimePicker' });
                            }}
                            type="time"
                            value={time}
                        />
                        <em>
                            {wfpFormat(country) || 'Local'} Time: {localTime}
                        </em>
                    </div>
                    <div className="wfp-form--actions text-center">
                        <button className="wfp-btn--primary" onClick={toggleCalendar} type="button">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </Container>
    );
};

function InputWrapper({ disabled, value, name, label, onClick }) {
    return (
        <div className="bb-input-wrapper" onClick={onClick}>
            {label && <label htmlFor={name}>{label}</label>}
            <Input
                className="form-control"
                disabled={disabled}
                inputStyle={{
                    cursor: disabled ? 'not-allowed' : 'default',
                    textShadow: '0 0 0 black',
                    color: 'transparent',
                }}
                name={name}
                value={value}
            />
        </div>
    );
}

function formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
}

const Container = styled.div`
    position: relative;
    margin-bottom: 2rem;
    align-items: center;
    .bb-input-wrapper {
        display: flex;
        gap: 1rem;
    }
    .calendar-container {
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.125);
        top: 100%;
        left: 67%;
        background: #fff;
        box-sizing: border-box;
        z-index: 1;
        transform: translate3d(-50%, 0, 0);
    }
    .time-picker-container {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        padding: 1rem;
        flex-direction: column;
    }
    .wfp-form--actions {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        padding: 5px 0;
    }
`;

const StyledCalendar = styled(Calendar)`
    .rdrDay {
        & > span[class=''] {
            pointer-events: none;
        }
    }
    .rdrDayNumber {
        pointer-events: none;
    }
    [data-state='selected'] {
        position: relative;
        span.rdrDayNumber {
            color: white;
            & > span {
                color: inherit;
            }
        }
        &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            background: #3d91ff;
            width: 100%;
            height: 2.5em;
            border-radius: 1.333em;
            z-index: 0;
        }
    }
`;
