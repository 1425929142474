import 'whatwg-fetch';
import * as querystring from 'querystring';
import { DateSelectFilter, Filter, FilterTypes } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';
import VendorTransaction from './transactions/transaction';
import { VendorTransactionChain } from './transactions/actions';
import { TransactionRequestParams } from '../apiClient';
import { AsyncTask } from '../utils/asyncTasks';
import { BigNumber } from '../utils/bigNumber';
import { TransactionChain, TransactionDetails } from '../transactions/transactions';
import * as moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { transformDateFilters } from '../../utils/transformDateFilters';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { getProjectUrl } from '../app/appConfig';
import { windowStorage, WindowStorageToken } from '../windowStorage';

const BaseUrl = () => {
    return `${getProjectUrl()}api/vendor`;
};

function authHeaders() {
    return {
        Authorization: windowStorage.getItem(WindowStorageToken.vendorUserApiToken) || '',
    };
}

function jsonHeaders() {
    return new Headers(
        Object.assign(
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            authHeaders()
        )
    );
}

function fetch(url, requestInit) {
    return window.fetch(url, requestInit).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            throw response;
        }
        if (response.status !== 204) {
            return response.json();
        }
        return response;
    });
}

function get(url): Promise<any> {
    return fetch(url, {
        method: 'GET',
        headers: jsonHeaders(),
    });
}

// function deleteRequest(url): Promise<any> {
//     return fetch(url, {
//         method: 'DELETE',
//         headers: jsonHeaders(),
//     });
// }

function post(url, body): Promise<any> {
    return fetch(url, {
        method: 'POST',
        headers: jsonHeaders(),
        body: JSON.stringify(body),
    });
}

export function loginVendor(email: string, password: string, otp?: string) {
    return post(`${BaseUrl()}/auth`, { email, password, otp });
}

export function newAuthToken() {
    return post(`${BaseUrl()}/new-auth-token`, {});
}

const assignFiltersToParams = function (filters: Array<Filter>, params: {}) {
    filters.forEach((f) => {
        if (f.isSelected) {
            if (f instanceof DateSelectFilter) {
                const dateFilter = f as DateSelectFilter;
                const startDateString = moment(dateFilter.dateRange.startDate).tz('utc').format();
                const endDateString = moment(dateFilter.dateRange.endDate).tz('utc').format();
                params['createdAt:gte'] = startDateString;
                params['createdAt:lte'] = endDateString;
            } else if (f.type === FilterTypes.multiselect) {
                const values = f.value ? f.value.map((selectOption) => selectOption.value) : [];
                params[f.name] = values.toString();
            } else {
                params[f.name] = f.value;
            }
        }
    });
};

async function getWithOffset<T>(
    url: string,
    filters: Array<Filter>,
    offset: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const params = Object.assign({}, { _offset: offset, _limit: limit });
    assignFiltersToParams(filters, params);
    Object.assign(params, customParams);
    const queryString = querystring.stringify(params);

    const res = await get(`${url}?${queryString}`);
    if (res.status === 204) {
        return new PagedState<T>().configureFor204Response(limit, filters);
    }
    return res;
}

function getWithPaging<T>(
    url: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    customParams: any = {}
): Promise<PagedState<T>> {
    const offset = (page - 1) * limit;
    return getWithOffset(url, filters, offset, limit, customParams);
}
/////// exported functions

export function healthCheck() {
    return get(`${BaseUrl()}/health-check`);
}
export function loadVendorTransactions(
    timezone: string,
    filters: Array<Filter>,
    page: number,
    limit: number,
    sortOptions: SORT_OPTIONS
) {
    const _filters = filters.map(transformDateFilters(timezone));
    return getWithPaging<VendorTransaction>(`${BaseUrl()}/transactions`, _filters, page, limit, sortOptions).then(
        (result) => {
            result.items.forEach((transaction) => (transaction.amount = new BigNumber(transaction.amount)));
            return result;
        }
    );
}

export function loadVendorTransactionChain(transactionId: string): Promise<VendorTransactionChain> {
    return get(`${BaseUrl()}/transactions/${transactionId}`).then((result) => {
        const chain: TransactionChain = result;
        chain.amount = new BigNumber(chain.amount);
        chain.items.forEach((transaction) => {
            transaction.amount = new BigNumber(transaction.amount);
        });
        return result;
    });
}
export function loadTransactionDetails(transactionId: string): Promise<{ data: TransactionDetails }> {
    return get(`${BaseUrl()}/transactions/${transactionId}/details`);
}
export function exportTransactions(timezone: string, columns, filters, customParams: TransactionRequestParams = {}) {
    const params = { _offset: 0, _limit: 0 };
    const _filters = filters.map(transformDateFilters(timezone));
    assignFiltersToParams(_filters, params);
    Object.assign(params, customParams);
    const queryString = querystring.stringify(params);

    return post(`${BaseUrl()}/transactions/export?${queryString}`, {
        fileName: 'Transactions.xlsx',
        columns,
    });
}

export function getAsyncTasksList(filters: Array<Filter>, page: number, limit: number) {
    return getWithPaging<AsyncTask>(`${BaseUrl()}/tasks/vendor-transaction-export-requests`, filters, page, limit);
}

export function appendAsyncTasksList(filters: Array<Filter>, offset: number, limit: number) {
    return getWithOffset<AsyncTask>(`${BaseUrl()}/tasks/vendor-transaction-export-requests`, filters, offset, limit);
}

export function getAsyncTask(id: string): Promise<AsyncTask> {
    return get(`${BaseUrl()}/tasks/vendor-transaction-export-requests/${id}`);
}

export function downloadFile(taskType: string, id: string) {
    return get(`${BaseUrl()}/tasks/${taskType}/${id}/download`)
        .then((data) => data.url)
        .catch((err) => (err.status ? err.status : 500));
}

export function cancelRunningExport(type: string, id: string) {
    return post(`${BaseUrl()}/tasks/${type}/${id}/cancel-running-export`, {});
}

export function logout() {
    return post(`${BaseUrl()}/logout`, {});
}

export function getAppConfig() {
    return get(`${BaseUrl()}/app-config`);
}
