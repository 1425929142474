import * as React from 'react';
import { useState } from 'react';

interface Props {
    publicGpgKey: string;
}

const DisplayPublicGpgKey: React.FC<Props> = ({ publicGpgKey }) => {
    const [showMore, setShowMore] = useState(false);

    if (publicGpgKey.length < 36) {
        return <div>{publicGpgKey}</div>;
    }

    if (showMore) {
        return (
            <div>
                {publicGpgKey}
                <br /> <a onClick={() => setShowMore(false)}>Show less</a>
            </div>
        );
    }

    return (
        <div>
            {publicGpgKey.substring(0, 36)}
            <br />
            {publicGpgKey.substring(37, 70)}...
            <br />
            <a onClick={() => setShowMore(true)}>Show more</a>
        </div>
    );
};

DisplayPublicGpgKey.displayName = 'DisplayPublicGpgKey';

export { DisplayPublicGpgKey };
