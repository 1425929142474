import * as React from 'react';
import BreadCrumbs from '../../utils/BreadCrumbs';
import { Select } from '../../utils/form/fields/Select';
import { wfpFormat } from '../../utils/utils';
import { Outlets } from '../outlets.component';
import { Loader } from '../../utils/Loader';
import { useProductUpdate } from '../hooks/use-product-update/use-product-update.hook';
import { ConfirmProductManagementUpdateModalComponent } from '../confirm-product-management-update-modal.component';
import { useToggle } from '../../utils/useToggle';
import { ProductUpdateFormComponent } from './product-update-form.component';
import { ButtonContainer, ProductUpdatePageContainer } from './product-update.style';
import { DateAndTimePicker } from '../../utils/date-and-time-picker';
import { EntityUpdate } from '../../authorization/taskAuthorizations';
import * as moment from 'moment-timezone';
import { SPREADSHEET_EXTENSIONS } from '../../constants/file-upload-extensions.constant';

interface Props {
    location: { state: { groupId: number; updates?: EntityUpdate[]; validFrom?: string | Date } };
    params: { groupName: string };
}

export function ProductUpdatePage({ location: { state: locationState }, params }: Props) {
    const {
        toggleRemove,
        addProduct,
        state,
        applyChanges,
        handleChange,
        products,
        setDecimal,
        toggleCheckbox,
        timePickerProps,
        validFromError,
        priceSchedulingEnabled,
        handleSetFile,
        file,
    } = useProductUpdate({
        groupId: locationState?.groupId,
        groupName: params.groupName,
        updates: locationState?.updates,
    });
    const [confirmationModal, toggleConfirmationModal] = useToggle();
    const formData = Object.entries(state?.products || {});
    const hasErrors = formData.some(([, values]) => values.error);
    const wasEdited = formData.some(([, values]) => values.edited);
    return state ? (
        <>
            <BreadCrumbs path="/products/update" />
            <h5>{wfpFormat(params.groupName)}</h5>
            <main className="wfp-form">
                {priceSchedulingEnabled && (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <DateAndTimePicker label="Valid from: " {...timePickerProps} />
                        {validFromError && (
                            <p style={{ color: 'red', paddingTop: '0.5rem' }}>
                                Selected date has to be after currently active version:{' '}
                                {moment(state.metadata.validFrom)
                                    .tz(timePickerProps.timezone)
                                    .format('YYYY-MM-DD HH:mm UTCZ')}
                            </p>
                        )}
                    </div>
                )}
                <ProductUpdatePageContainer>
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Min Price</th>
                                <th>Max Price</th>
                                <th>Fixed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.map(([productName, values]) => (
                                <ProductUpdateFormComponent
                                    handleChange={handleChange(productName)}
                                    key={productName}
                                    productName={productName}
                                    setDecimal={setDecimal(productName)}
                                    toggleCheckbox={toggleCheckbox(productName)}
                                    toggleRemove={toggleRemove}
                                    values={values}
                                />
                            ))}
                            <tr className="add-product">
                                <td colSpan={2}>
                                    <Select
                                        name="addProduct"
                                        onChange={addProduct}
                                        options={products.filter(
                                            (product) => !Object.keys(state.products).includes(product.label)
                                        )}
                                        placeholder="Add Product"
                                        value={null}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ProductUpdatePageContainer>
                <section>
                    <h5>Outlets</h5>
                    <table className="wfp-table" style={{ gridColumn: 'span 3', tableLayout: 'fixed' }}>
                        <Outlets outlets={state?.outlets || []} />
                    </table>
                </section>
                <label className="mb-0 cursor-pointer" htmlFor="product-update-file" style={{ fontWeight: 'normal' }}>
                    <a>{file ? file?.name : 'Upload File'}</a>
                    <input
                        accept={SPREADSHEET_EXTENSIONS}
                        hidden
                        id="product-update-file"
                        name="product-update-file"
                        onChange={handleSetFile}
                        type="file"
                    />
                </label>
                <ButtonContainer>
                    <button
                        className="wfp-btn--primary"
                        disabled={hasErrors || !wasEdited}
                        onClick={toggleConfirmationModal}
                        type="button"
                    >
                        Park Changes
                    </button>
                    {confirmationModal && (
                        <ConfirmProductManagementUpdateModalComponent
                            entityName={params.groupName}
                            onClose={toggleConfirmationModal}
                            onConfirm={applyChanges}
                            title="Park Changes"
                        />
                    )}
                </ButtonContainer>
            </main>
        </>
    ) : (
        <Loader />
    );
}
