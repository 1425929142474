import * as React from 'react';
import styled from 'styled-components';
import { ActionContainer } from './entity-update-request-actions';
import { displayTime } from '../../utils/utils';

interface EntityUpdateRequestCardCC extends React.FC {
    Header: any;
    HeaderLeft: any;
    HeaderRight: any;
    Actions: any;
    EntityDetails: any;
    Signatories: any;
    Details: any;
}

export const EntityUpdateRequestCard = function EntityUpdateRequestCard({ children }) {
    return <Card className="shadowed-container">{children}</Card>;
} as EntityUpdateRequestCardCC;

export enum MILESTONE {
    PARKED = 'Parked',
    POSTED = 'Posted',
    VERIFIED = 'Verified',
    SIGNED = 'Signed Level',
    REJECTED = 'Rejected',
    UPLOAD = 'Document Upload',
}

export function Signatory({
    type,
    date,
    author,
    level,
}: {
    type: MILESTONE;
    date: string;
    author: string;
    level?: number;
}) {
    return (
        <p>
            <strong>
                {type} {level ?? ''} at:{' '}
            </strong>
            {displayTime(date)} by {author}
        </p>
    );
}

const Card = styled.div`
    display: grid;
    margin-bottom: 10px;
    padding: 20px;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
        'entity signatories'
        'updates updates'
        'actions actions';
`;

const Header = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: 'left right';
    gap: 1rem;
    margin-bottom: 10px;
`;

const Left = styled.div`
    grid-area: left;
`;

const Right = styled.div`
    grid-area: right;
`;
EntityUpdateRequestCard.Header = Header;
EntityUpdateRequestCard.HeaderLeft = Left;
EntityUpdateRequestCard.HeaderRight = Right;

const EntityDetails = styled.div`
    grid-area: entity;
    p {
        margin: revert;
    }
`;

const Signatories = styled.div`
    grid-area: signatories;
`;
const Details = styled.div`
    grid-area: updates;
`;

const Actions = styled(ActionContainer)`
    grid-area: actions;
`;

EntityUpdateRequestCard.EntityDetails = EntityDetails;
EntityUpdateRequestCard.Signatories = Signatories;
EntityUpdateRequestCard.Details = Details;
EntityUpdateRequestCard.Actions = Actions;
