import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';

import { ActionCreators } from './vendors';
import State from '../app/store/state';
import VendorsList from './VendorsList';
import FeatureButton from '../utils/FeatureButton';
import { hasFeatureAccess } from '../login/auth';
import { ManagerPermission } from '../permission-profiles/permission';

class VendorsPage extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }
    render() {
        const { auth, currency } = this.props;
        const postEnabled = hasFeatureAccess(auth, ManagerPermission.vendorsPost);
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Vendors</span>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <h3 className="col-sm-4">Vendors</h3>
                    <div className="col-sm-8 tr">
                        <FeatureButton
                            linkTo={'/vendors/new'}
                            manager={auth.manager}
                            restrictedToFeatures={[ManagerPermission.vendorsPark]}
                            title={'Add Vendor'}
                        />
                        <FeatureButton
                            linkTo={'/vendors/download'}
                            manager={auth.manager}
                            restrictedToFeatures={[ManagerPermission.vendorsDownload]}
                            title={'Download Vendors'}
                        />
                        {/*<Link to="/vendors/import" className="ml2"><button type="button" className="wfp-btn">Import Vendors</button></Link>*/}
                    </div>
                </div>
                {auth.manager && (
                    <VendorsList
                        authorize={this.props.authorizeVendor}
                        cancelPark={this.props.cancelPark.bind(this)}
                        currency={currency}
                        managerId={auth.manager.id}
                        postEnabled={postEnabled}
                    />
                )}
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        vendors: state.vendors.list,
        auth: state.auth,
        currency: state.appConfig.entitlementCurrencyConfig,
    };
}

export default connect(mapStateToProps, (dispatch) => bindActionCreators(ActionCreators, dispatch))(VendorsPage as any);
