import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Product } from '../helpers';
import State from '../../app/store/state';
import { loadProduct, updateAndAuthorizeProduct } from '../../apiClient';
import { errorAction, successAction } from '../../utils/notifications';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

export function useProductDetails(params) {
    const dispatch = useDispatch();
    const productId = params.productId;
    const [product, setProduct] = useState<Product>(null);
    const [editing, setEditing] = useState(false);
    const categories = useSelector((state: State) =>
        state.appConfig.entitlementsConfig.categories.map((category) => ({
            value: category,
            label: category,
        }))
    );
    const tz = useSelector((state: State) => state.appConfig.timeZone);

    useEffect(() => {
        loadProduct(productId).then(setProduct);
    }, []);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        updateAndAuthorizeProduct(product)
            .then(() => {
                dispatch(routerHelperActions.makeRedirect('/products'));
                dispatch(successAction('Product updated'));
            })
            .catch(() => {
                dispatch(errorAction('Cannot update product'));
            });
    };

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        event.persist?.();
        setProduct((state) => ({ ...state, [event.target.name]: event.target.value }));
    }, []);

    const toggleEdit = useCallback(() => {
        setEditing((state) => !state);
    }, []);
    return { editing, product, productId, toggleEdit, handleSubmit, handleChange, categories, tz };
}
