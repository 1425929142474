import {
    authorizePartner,
    authorizePartnerUser,
    cancelPartner,
    cancelPartnerUser,
    createPartner,
    createPartnerUser,
    loadParkedPartnerUsers,
    loadPartner,
    loadPartners,
    loadPartnersNames,
    loadPartnerUsers,
    loadPartnerUsersNames,
    loadSinglePartnerUser,
    requestPartnerPasswordReset,
    resetPartnerUserPassword,
    savePartnerUser,
    uploadPartnerPermissionsFile,
} from '../apiClient';
import Action from '../action';
import { errorAction, successAction } from '../utils/notifications';
import * as moment from 'moment';
import { EntityUpdateReference } from '../authorization/taskAuthorizations';
import { defaultPartnerSortOptions, defaultPartnerUserSortOptions, Partner, PartnersState } from './partners';
import hasNewFieldsChanges from '../utils/hasNewFieldsChanges';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { Filter } from '../utils/FilterTypes';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

export const ActionTypes = {
    partnersLoaded: 'PartnersPage.partnersLoaded',
    authorizedPartner: 'PartnersPage.authorizedPartner',
    dataLoaded: 'PartnerPage.dataLoaded',
    singlePartnerLoaded: 'PartnerPage.singlePartnerLoaded',
    partnerUsersLoaded: 'PartnerPage.PartnerPanel.partnerUsersLoaded',
    singlePartnerUserLoaded: 'PartnerPage.PartnerPanel.singlePartnerUserLoaded',
    partnerUserAuthorized: 'PartnerPage.PartnerPanel.partnerUserAuthorized',
    resetPartnerUserPassword: 'PartnerPage.PartnerPanel.resetPartnerUserPassword',
    parkPartnerUserEntityUpdateRequest: 'PartnerPage.PartnerPanel.parkPartnerUserEntityUpdateRequest',
    cancelPartnerUser: 'PartnerPage.PartnerPanel.cancelPartnerUser',
    cancelPartner: 'PartnerPage.cancelPartner',
    partnerNamesLoaded: 'PartnerPage.partnerNamesLoaded',
    partnerUsersNamesLoaded: 'PartnerPage.partnerUsersNamesLoaded',
};

function actionForError(error) {
    if (error.status === 422) {
        return errorAction('Conflicting access features');
    } else if (error.status === 409) {
        return errorAction('Conflicting data');
    } else {
        return errorAction('Could not create new partner');
    }
}

export const ActionCreators = {
    loadPartner(partnerId: string, withPartnerUsers = true) {
        return async (dispatch) => {
            const partner = await loadPartner(partnerId);
            await dispatch({
                type: ActionTypes.singlePartnerLoaded,
                payload: partner,
            });
            if (withPartnerUsers) {
                const partnerUsers = await loadPartnerUsers(partnerId, defaultPartnerUserSortOptions);
                dispatch({ type: ActionTypes.partnerUsersLoaded, payload: partnerUsers });
            }
        };
    },
    cancelPartner(partnerId) {
        return async (dispatch) => {
            try {
                await cancelPartner(partnerId);
                dispatch({ type: ActionTypes.cancelPartner });
                dispatch(successAction('Partner was cancelled'));
                const partners = await loadPartners(defaultPartnerSortOptions);
                const parkedUsers = await loadParkedPartnerUsers();
                dispatch({ type: ActionTypes.partnersLoaded, payload: { partners, parkedUsers } });
            } catch (err) {
                dispatch(errorAction('Could not cancel partner user'));
            }
        };
    },
    loadPartners(sortOptions: SORT_OPTIONS, filters?: Filter[]) {
        return async (dispatch) => {
            const partners = await loadPartners(sortOptions, filters);
            const parkedUsers = await loadParkedPartnerUsers();
            dispatch({
                type: ActionTypes.partnersLoaded,
                payload: { partners, parkedUsers },
            });
        };
    },
    loadPartnersNames(sortOptions: SORT_OPTIONS) {
        return (dispatch) =>
            loadPartnersNames(sortOptions).then((partners) => {
                dispatch({
                    type: ActionTypes.partnerNamesLoaded,
                    payload: partners,
                });
            });
    },
    loadPartnerUsersName(sortOptions: SORT_OPTIONS) {
        return (dispatch) =>
            loadPartnerUsersNames(sortOptions).then((partnerUsers) =>
                dispatch({ type: ActionTypes.partnerUsersNamesLoaded, payload: partnerUsers })
            );
    },
    authorizePartner(id: string) {
        return async (dispatch) => {
            await authorizePartner(id);
            dispatch({
                type: ActionTypes.authorizedPartner,
            });
            dispatch(successAction('Partner authorized'));
            const partners = await loadPartners(defaultPartnerSortOptions).catch(() =>
                dispatch(errorAction('Could not load partners'))
            );
            const parkedUsers = await loadParkedPartnerUsers();
            dispatch({
                type: ActionTypes.partnersLoaded,
                payload: { partners, parkedUsers },
            });
        };
    },
    resetPassword(id: string) {
        return (dispatch) =>
            requestPartnerPasswordReset(id, 'email').then(() => dispatch(successAction('Reset password link sent.')));
    },
    uploadPermissionsFile(id: string, file: File) {
        return async (dispatch) => {
            try {
                await uploadPartnerPermissionsFile(id, file);
                dispatch(successAction(`${file.name} was uploaded successfully`));

                const partners = await loadPartners(defaultPartnerSortOptions);

                dispatch({
                    type: ActionTypes.partnersLoaded,
                    payload: { partners },
                });
            } catch (err) {
                const error = await err.json();

                if (error.message) {
                    dispatch(errorAction(error.message));
                } else {
                    dispatch(errorAction(`${file.name} could not be uploaded`));
                }
            }
        };
    },
};
export const HooksActionCreators = {
    createPartner(dispatch) {
        return (partner: Partner) =>
            createPartner(partner)
                .then(() => {
                    dispatch(routerHelperActions.makeRedirect('/partners'));
                    dispatch(successAction('Partner parked'));
                })
                .catch((err) => {
                    dispatch(actionForError(err));
                });
    },
    cancelPartnerUser(dispatch) {
        return async (partnerUser: PartnerUser) => {
            try {
                await cancelPartnerUser(partnerUser.id);
                dispatch({ type: ActionTypes.cancelPartnerUser });
                dispatch(successAction('Partner user cancelled'));
                const payload = await loadPartnerUsers(partnerUser.partnerId, defaultPartnerUserSortOptions);
                dispatch({ type: ActionTypes.partnerUsersLoaded, payload });
            } catch (err) {
                dispatch(errorAction('Could not cancel partner user'));
            }
        };
    },
    createPartnerUser(dispatch) {
        return (partnerUser) => {
            dispatch(showLoading());
            createPartnerUser({ ...partnerUser })
                .then(() => {
                    dispatch(routerHelperActions.makeRedirect(`/partners/${partnerUser.partnerId}/panel`));
                    dispatch(successAction('Partner user parked'));
                    dispatch(hideLoading());
                })
                .catch(async (err) => {
                    const response = await err.json();
                    if (response) {
                        dispatch(errorAction(response.message));
                    } else {
                        dispatch(errorAction('Action failed'));
                    }
                    dispatch(hideLoading());
                });
        };
    },
    savePartnerUser(dispatch, oldPartnerUserData: PartnerUser) {
        return (partnerUser, partnerId) => {
            const noChangesToUpdate = oldPartnerUserData && !hasNewFieldsChanges(oldPartnerUserData, partnerUser);
            if (noChangesToUpdate) {
                dispatch(errorAction('There are no changes to be updated'));
                return;
            }
            savePartnerUser(partnerUser)
                .then(() => {
                    dispatch({
                        type: ActionTypes.parkPartnerUserEntityUpdateRequest,
                    });
                    dispatch(routerHelperActions.makeRedirect(`/partners/${partnerId}/panel`));
                    dispatch(successAction('Partner user update request was added'));
                })
                .catch(() => {
                    dispatch(errorAction('Could not add an update request for partner user'));
                });
        };
    },
    loadPartnerUsers(dispatch) {
        return (partnerId, sortOptions: SORT_OPTIONS, filters: Filter[]) => {
            loadPartnerUsers(partnerId, sortOptions, filters)
                .then((payload) => {
                    dispatch({
                        type: ActionTypes.partnerUsersLoaded,
                        payload,
                    });
                })
                .catch(() => dispatch(errorAction('Could not load partner users')));
        };
    },
    authorizePartnerUser(dispatch) {
        return async (partnerUser) => {
            await authorizePartnerUser(partnerUser.id).catch(() =>
                dispatch(errorAction('Could not authorize partner user'))
            );
            dispatch({
                type: ActionTypes.partnerUserAuthorized,
            });
            dispatch(successAction('Partner user posted successfully.'));
            const payload = await loadPartnerUsers(partnerUser.partnerId, defaultPartnerUserSortOptions).catch(() =>
                dispatch(errorAction('Could not load partner users'))
            );
            dispatch({
                type: ActionTypes.partnerUsersLoaded,
                payload,
            });
        };
    },
    resetPartnerUserPassword(dispatch) {
        return async (partnerUser) => {
            resetPartnerUserPassword(partnerUser.id)
                .then(() => {
                    dispatch({ type: ActionTypes.resetPartnerUserPassword });
                    dispatch(successAction('Password reset email sent'));
                })
                .catch(() => {
                    dispatch(errorAction('Could not reset password'));
                });
        };
    },
    loadSinglePartnerUser(dispatch) {
        return (partnerUserId) => {
            loadSinglePartnerUser(partnerUserId)
                .then((payload) => {
                    dispatch({
                        type: ActionTypes.singlePartnerUserLoaded,
                        payload,
                    });
                })
                .catch(() => {
                    dispatch(errorAction('Could not load partner user'));
                });
        };
    },
};

export function partnersReducer(state: PartnersState = new PartnersState(), action: Action) {
    switch (action.type) {
        case ActionTypes.partnersLoaded:
            return new PartnersState(action.payload.partners, action.payload.parkedUsers);
        case ActionTypes.authorizedPartner:
            return new PartnersState(action.payload);
        case ActionTypes.singlePartnerLoaded:
            const existingPartner = state.list.find((v) => v.id === action.payload.id);
            if (existingPartner) {
                Object.assign(existingPartner, action.payload);
                return state;
            } else {
                return new PartnersState(state.list.concat([action.payload]));
            }
        case ActionTypes.partnerNamesLoaded:
            return {
                ...state,
                nameList: action.payload,
            };
        case ActionTypes.partnerUsersNamesLoaded:
            return {
                ...state,
                partnerUsersNamesList: action.payload,
            };
    }
    return state;
}

export function partnerUsersReducer(state = new PartnerUserState({}), action: Action) {
    switch (action.type) {
        case ActionTypes.partnerUsersLoaded:
            return new PartnerUserState({ ...state, partnerUsers: action.payload });
        case ActionTypes.singlePartnerUserLoaded:
            return new PartnerUserState({ ...state, partnerUser: action.payload });
        default:
            return state;
    }
}

const mapPartnerUserToPartnerUserWithExpirationDate = (partnerUser) => ({
    ...partnerUser,
    expirationDate: moment(partnerUser.expirationDate).format('YYYY-MM-DD'),
});

export class PartnerUserState {
    current?: PartnerUser;
    list?: PartnerUser[];

    constructor({
        partnerUsers,
        partnerUser,
        current,
    }: {
        partnerUsers?: PartnerUser[];
        partnerUser?: PartnerUser;
        current?: PartnerUser;
    }) {
        const users = partnerUsers ? partnerUsers : partnerUser ? [partnerUser] : [];

        this.current = partnerUser || current || {};
        this.list = users.map(mapPartnerUserToPartnerUserWithExpirationDate);
    }
}

export interface PartnerUser {
    id?: number;
    status?: string;
    statusText?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    createdByManagerId?: number;
    partnerId?: number;
    expirationDate?: string;
    authorizedAt?: Date;
    pendingEntityUpdates?: EntityUpdateReference[];
    isPasswordBlocked?: boolean;
}
