import * as React from 'react';
import { Location, LocationActionCreators, LocationsState, LocationStatus, withLocation } from '../locations';
import { pick } from 'lodash';
import { Input } from '../../../utils/inputs';
import { displayTime } from '../../../utils/utils';
import { AuthState, hasFeatureAccess } from '../../../login/auth';
import { ManagerPermission } from '../../../permission-profiles/permission';
import { withNavigate } from '@wfp-common/hooks/withNavigate';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';

const locationsList = '/beneficiaries/locations';

interface State {
    currentData: Location;
    disabled: boolean;
    isEditing: boolean;
}

interface Props extends LocationActionCreators {
    locations: LocationsState;
    location: {
        pathname: string;
    };
    params: {
        locationId: string;
    };
    auth: AuthState;
    navigate: NavigateHook;
}

export function withLocationDetailsContainer(WrappedComponent) {
    class Container extends React.Component<Props, State> {
        private editableKeys: string[] = ['status', 'location1', 'location2'];
        state = {
            disabled: true,
            currentData: this.props.locations.details,
            isEditing: false,
        };

        componentDidMount() {
            this.props.loadLocationById(this.props.params.locationId);
            this.setState(() => ({
                currentData: { ...this.props.locations.details },
            }));
        }

        componentDidUpdate(prevProps: Readonly<any>): void {
            if (!prevProps.locations.details.id && this.props.locations.details.id) {
                this.setState({
                    currentData: { ...this.props.locations.details },
                });
            }
        }

        componentWillUnmount(): void {
            this.props.clearDetails();
        }

        parkEntityUpdateRequest = (e) => {
            e.preventDefault();
            this.props.updateLocation(this.state.currentData);
            this.props.navigate(locationsList);
        };
        toggleEditing = () => {
            this.setState((state) => ({
                ...state,
                isEditing: !state.isEditing,
            }));
        };
        toggleActive = () => {
            const { currentData } = this.state;
            const {
                locations: { details },
            } = this.props;
            switch (currentData.status) {
                case LocationStatus.posted:
                    if (details.status === LocationStatus.cancelled) {
                        currentData.status = LocationStatus.cancelled;
                        break;
                    }
                    currentData.status = LocationStatus.blocked;
                    break;
                case LocationStatus.parked:
                    break;
                default:
                    currentData.status = LocationStatus.posted;
                    break;
            }
            const disabled = !this.checkForChanges(currentData);
            this.setState((state) => ({ ...state, currentData, disabled }));
        };

        checkForChanges = (currentData) => {
            const entries = Object.entries(pick(currentData, this.editableKeys));
            const { details } = this.props.locations;
            return entries.some(([key, value]) => details[key] !== value);
        };
        handleChange = (event) => {
            const { name, value } = event.target;
            const { currentData } = this.state;
            currentData[name] = value;
            const disabled = !this.checkForChanges(currentData);
            this.setState((state) => ({ ...state, currentData, disabled }));
        };

        render() {
            const { currentData, disabled, isEditing } = this.state;
            const {
                location,
                locations: { details },
                auth,
            } = this.props;
            const isActive = currentData.status === LocationStatus.posted;
            const hasEditPermission = hasFeatureAccess(auth, ManagerPermission.beneficiariesPark);
            return (
                <WrappedComponent
                    activeState={[isActive, this.toggleActive]}
                    details={details}
                    disabled={disabled}
                    editingState={[isEditing, this.toggleEditing]}
                    hasEditPermission={hasEditPermission}
                    inputsState={[currentData, this.handleChange]}
                    parkEntityUpdateRequest={this.parkEntityUpdateRequest}
                    path={location.pathname}
                />
            );
        }
    }

    return withNavigate(withLocation(Container), 'Container');
}

interface TextFieldProps {
    isEditing: boolean;
    onChange: OnErrorEventHandlerNonNull;
    value: string;
    label: string;
    name: string;
}

export function TextField({ isEditing, onChange, value, label, name }: TextFieldProps) {
    return (
        <div className="wfp-form--group row">
            <label className="col-sm-4 ta-right" htmlFor={name}>
                {label}
            </label>
            {isEditing ? <Input name={name} onChange={onChange} value={value} /> : <p>{value}</p>}
        </div>
    );
}
const styles = {
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
    },
    item: {
        justifySelf: 'end',
    },
};
export function Status({ details }: { details: Location }) {
    return (
        <div className="wfp-form--group row" style={styles.grid}>
            <label className="col-sm-4 ta-right" style={styles.item}>
                Status
            </label>
            <span>
                <p style={{ marginTop: 0 }}>{displayLog('Parked', details.createdAt, details.createdByManager)}</p>
                {details.authorizedAt && (
                    <p>{displayLog('Posted', details.authorizedAt, details.authorizedByManager)}</p>
                )}
                {details.cancelledAt && (
                    <p>{displayLog('Cancelled', details.cancelledAt, details.cancelledByManager)}</p>
                )}
            </span>
        </div>
    );
}

export function Button({ children, disabled }: { disabled: boolean; children: string }) {
    return (
        <div className="wfp-form--actions text-center">
            <div className="col-sm-12 col-sm-offset-4">
                <button className="wfp-btn--primary" disabled={disabled} type="submit">
                    {children}
                </button>
            </div>
        </div>
    );
}

export function displayLog(action: string, time: Date, manager: { firstName: string; lastName: string }) {
    return `${action} at ${displayTime(time)} by ${manager.firstName} ${manager.lastName}`;
}
