import * as React from 'react';
import { Link } from 'react-router';

import { AsyncTask, AsyncTasksTypes } from '../../utils/asyncTasks';
import PagingComponent, { PagedState } from '../../utils/paging';
import AsyncTasksListImportView from '../../utils/AsyncTasksListImportView';
import { RefreshSignaler } from '../../utils/refresher';
import { UploadStatusWidget } from '../../utils/import';
import { UploadTaskState } from '../beneficiaries';
import DryRunDialog from './DryRunDialog';
import { Input } from '../../utils/inputs';
import { DownloadStaticFileButton } from '../../utils/DownloadStaticFileButton';
import { SPREADSHEET_EXTENSIONS } from '../../constants/file-upload-extensions.constant';

export interface ListViewProps {
    data: Array<AsyncTask>;
    canAuthorizeTask?: boolean;
    authorizedManagerId: string;
    authorize: (type: string, id: string) => Promise<void>;
    downloadFile: (type: string, id: string) => string;
    canDownload: boolean;
}

export interface AsyncTasksPageStateProps {
    authorizedManagerId?: string;
    name: string;
    type?: string;
    uploadDescription: string;
    exampleLinkTitle: string;
    exampleUploadFile: any;
    tasks?: PagedState<AsyncTask>;
    canPark: boolean;
    canPost: boolean;
    canDownload?: boolean;
    uploadTaskState: UploadTaskState;
    shouldRunDryRun?: boolean;
    shouldShowInlineWidget?: boolean;
    clearUploadTask?(): void;
}

export interface AsyncTasksPageDispatchProps {
    downloadFile: (type: string, id: string) => void;
    loadAsyncTasksList: (page: number, limit: number) => Promise<RefreshSignaler>;
    uploadFile: (data: File, dryRun?: boolean, dryRunAsyncTaskId?: string) => Promise<void>;
    authorizeTask: (type: string, id: string) => void;
    listView?: (props: ListViewProps) => any;
    clearUploadTask?(): void;
}

type AsyncTasksPageProps = AsyncTasksPageStateProps & AsyncTasksPageDispatchProps;

interface AsyncTasksPageState {
    isDryRun: boolean;
    file?: File;
}

export default class AsyncTasksPage extends React.Component<AsyncTasksPageProps, AsyncTasksPageState> {
    private defaultPageSize = 25;
    private refresher?: RefreshSignaler;

    static defaultProps = {
        listView: AsyncTasksListImportView,
        canDownload: true,
        shouldRunDryRun: false,
    };

    constructor(props: AsyncTasksPageProps) {
        super(props);

        this.defaultPageSize = props.type === AsyncTasksTypes.IMPORT_BENEFICIARIES ? 10 : 25;

        this.state = {
            isDryRun: props.shouldRunDryRun,
        };
    }

    async UNSAFE_componentWillMount() {
        this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize);
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
        this.props.clearUploadTask?.();
    }

    UNSAFE_componentWillReceiveProps(nextProps: AsyncTasksPageProps) {
        if (!this.state.file) {
            this.setState({
                isDryRun: nextProps.shouldRunDryRun,
            });
        }
    }

    private async changePageRequested(newPage) {
        this.refresher = await this.props.loadAsyncTasksList(newPage, this.defaultPageSize);
    }

    private upload = async (event, dryRun: boolean) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ file });
            event.target.value = '';

            await this.props.uploadFile(file, dryRun);

            if (!dryRun) {
                this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize);
            }
        }
    };

    private onDryRunCancel = () => {
        this.setState({ file: undefined });
    };

    private onConfirmDryRun = async (dryRunAsyncTaskId: string) => {
        this.setState({ isDryRun: false });
        await this.props.uploadFile(this.state.file, false, dryRunAsyncTaskId);
        this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize);
        this.setState({ file: undefined });
    };

    private renderUploadFeature() {
        const {
            name,
            uploadDescription,
            exampleUploadFile,
            exampleLinkTitle,
            uploadTaskState,
            shouldRunDryRun,
            shouldShowInlineWidget,
        } = this.props;
        const { isDryRun, file } = this.state;
        return (
            <div>
                <p>{uploadDescription}</p>
                <p>
                    Please ensure that you have exported this list with the correct structure.{' '}
                    <DownloadStaticFileButton filename={exampleUploadFile}>{exampleLinkTitle}</DownloadStaticFileButton>
                </p>

                <div className="wfp-form--actions">
                    <button className="wfp-btn replaced-input-file" type="button">
                        <span>Upload</span>
                        <Input
                            accept={SPREADSHEET_EXTENSIONS}
                            name="data"
                            onChange={(event) => this.upload(event, shouldRunDryRun)}
                            type="file"
                        />
                    </button>

                    {isDryRun && !!file && (
                        <DryRunDialog
                            asyncTask={this.props.uploadTaskState.task}
                            downloadFile={this.props.downloadFile}
                            onCancel={this.onDryRunCancel}
                            onConfirm={this.onConfirmDryRun}
                            taskStateError={this.props.uploadTaskState.error}
                            title={name}
                        />
                    )}

                    {shouldShowInlineWidget && !isDryRun && (
                        <UploadStatusWidget
                            importError={uploadTaskState.error}
                            importStatus={uploadTaskState.status}
                            importTask={uploadTaskState.task}
                        />
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { canPark, canPost, canDownload, tasks, authorizedManagerId } = this.props;

        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/beneficiaries">
                                <span>Beneficiaries</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{this.props.name}</span>
                        </li>
                    </ol>
                </nav>

                <h3>{this.props.name}</h3>

                {canPark && this.renderUploadFeature()}

                {this.props.tasks && (
                    <div>
                        <this.props.listView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={authorizedManagerId}
                            canAuthorizeTask={canPost}
                            canDownload={canDownload}
                            data={tasks.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <PagingComponent onPageChanged={this.changePageRequested.bind(this)} paging={tasks.paging} />
                    </div>
                )}
            </main>
        );
    }
}
