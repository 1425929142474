import * as React from 'react';
import { Link } from 'react-router';
import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';
import { useDispatch, useSelector } from 'react-redux';
import State from '../app/store/state';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes } from '../utils/asyncTasks';
import PagingComponent, { PagedState } from '../utils/paging';
import { ActionCreators } from './beneficiaries';
import { bindActionCreators } from 'redux';
import { useRefresh } from '../utils/hooks/useRefresh';
import { ButtonWithModalConfirmation } from '../utils/components/button-with-modal-confirmation';
import { useState } from 'react';

const defaultPageSize = 10;

const BeneficiaryPinExportPage = () => {
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const beneficiaryFilters = useSelector((state: State) => state.beneficiaries?.filters);
    const timezone = useSelector((state: State) => state.appConfig.timeZone);
    const beneficiariesTotalCount = useSelector((state: State) => state.beneficiaries?.paging?.total);
    const exports = useSelector(
        (state: State) => state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_BENEFICIARIES_PINS] || new PagedState()
    );

    const downloadFile = bindActionCreators(AsyncActionCreators.downloadFile, dispatch);

    const loadAsyncTasksList = (newPage?: number) =>
        dispatch(
            AsyncActionCreators.fetchAsyncTasksList(
                newPage ? newPage : page,
                defaultPageSize,
                AsyncTasksTypes.EXPORT_BENEFICIARIES_PINS,
                beneficiaryFilters
            )
        );

    const handleExportBeneficiaryPins = () => {
        ActionCreators.exportBeneficiaryPins(timezone, beneficiaryFilters)(dispatch).then(() => loadAsyncTasksList());
    };

    const changePageHandler = (newPage: number) => {
        loadAsyncTasksList(newPage);
        setPage(newPage);
    };

    useRefresh(loadAsyncTasksList, true, 8000);

    return (
        <main>
            <nav className="wfp-breadcrumbs">
                <ol className="breadcrumbs--wrapper">
                    <li className="breadcrumbs--item">
                        <Link className="breadcrumbs--link" to="/home">
                            <span>Home</span>
                        </Link>
                    </li>
                    <li className="breadcrumbs--item">
                        <Link className="breadcrumbs--link" to="/beneficiaries">
                            <span>Beneficiaries</span>
                        </Link>
                    </li>
                    <li className="breadcrumbs--item">
                        <span className="breadcrumbs--last">Download Beneficiary Pins</span>
                    </li>
                </ol>
            </nav>
            <h3>Download Beneficiary Pins</h3>
            <p>{beneficiariesTotalCount || 0} Beneficiaries are listed.</p>
            {beneficiariesTotalCount > 0 && (
                <div className="wfp-form--actions">
                    <ButtonWithModalConfirmation
                        buttonClassName="wfp-btn"
                        customModalBody={'Do you confirm start of beneficiary pins export task ?'}
                        customModalTitle={'Download beneficiary pins'}
                        onApprove={handleExportBeneficiaryPins}
                    >
                        Download
                    </ButtonWithModalConfirmation>
                </div>
            )}
            {exports && exports.items.length > 0 && (
                <div>
                    <AsyncTasksListExportView
                        canDownload={true}
                        data={exports.items}
                        downloadFile={downloadFile}
                        showCreatedByManager={true}
                    />
                    <PagingComponent onPageChanged={changePageHandler} paging={exports.paging} />
                </div>
            )}
        </main>
    );
};

BeneficiaryPinExportPage.displayName = 'BeneficiaryPinExportPage';

export { BeneficiaryPinExportPage };
