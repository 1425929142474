import * as React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import State from '../app/store/state';
import { ActionCreators as VendorActionCreators, defaultVendorSortingOrder, Vendor } from './vendors';
import { AsyncTasksTypes, AsyncTask, ActionCreators as AsyncActionCreators } from '../utils/asyncTasks';
import { RefreshSignaler } from '../utils/refresher';
import { PagedState } from '../utils/paging';
import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';
import { ExportFeatureDescription } from './ExportFeatureDescription';
import { SORT_OPTIONS } from '../utils/hooks/useSort';

interface Props {
    vendors: Array<Vendor>;
    exports: PagedState<AsyncTask>;
    managerId: string;
    downloadFile: (type: string, id: string) => string;
    loadVendors: (sortOptions: SORT_OPTIONS) => Promise<any>;
    exportVendors: (mapping: Array<{ key; header }>) => Promise<any>;
    loadAsyncTasksList: (page: number, limit: number, type: string) => Promise<RefreshSignaler>;
    authorizeTask: (type: string, id: string) => Promise<any>;
    appendAsyncTasksList: (limit: number, type: string) => void;
}

interface LocalState {
    selectedColumns: any;
}

class VendorExportPage extends React.Component<Props, LocalState> {
    private availableColumns = [
        { key: 'id', header: '#' },
        { key: 'name', header: 'Name' },
        { key: 'sapId', header: 'WFP Vendor ID' },
        { key: 'sublocation', header: 'Sublocation' },
        { key: 'city', header: 'City' },
        { key: 'country', header: 'Country' },
        { key: 'createdAt', header: 'Create Date' },
        { key: 'users', header: 'Users' },
        { key: 'panelAccessStatus', header: 'Status' },
    ];

    private defaultPageSize = 10;
    private refresher?: RefreshSignaler;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedColumns: {
                id: true,
                name: true,
            },
        };
    }

    async changePageRequested() {
        this.props.appendAsyncTasksList(this.defaultPageSize, AsyncTasksTypes.EXPORT_VENDORS);
    }

    async UNSAFE_componentWillMount() {
        if (!this.props.vendors.length) {
            await this.props.loadVendors(defaultVendorSortingOrder);
        }
        this.refresher = await this.props.loadAsyncTasksList(1, this.defaultPageSize, AsyncTasksTypes.EXPORT_VENDORS);
    }

    componentWillUnmount() {
        if (this.refresher) {
            this.refresher.stop();
        }
    }

    renderExportFeature() {
        return (
            <div>
                <div className="wfp-form--group" style={{ columns: 3 }}>
                    {this.availableColumns.map((column) => (
                        <div className="checkbox" key={column.key}>
                            <label>
                                <input
                                    checked={this.state.selectedColumns[column.key] || false}
                                    name={column.key}
                                    onChange={this._handleCheck.bind(this)}
                                    type="checkbox"
                                />{' '}
                                {column.header}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="wfp-form--actions">
                    <button className="wfp-btn" type="submit">
                        Generate
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/vendors">
                                <span>Vendors</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Download Vendors</span>
                        </li>
                    </ol>
                </nav>
                <h3>Download Vendors</h3>

                <ExportFeatureDescription />

                <form onSubmit={this._exportVendors.bind(this)}>{this.renderExportFeature()}</form>
                {this.props.exports && this.props.exports.items.length > 0 && (
                    <div>
                        <AsyncTasksListExportView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={this.props.managerId}
                            canDownload={true}
                            data={this.props.exports.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <div className="wfp-form--actions">
                            <button
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                                onClick={this.changePageRequested.bind(this)}
                                type="button"
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleCheck(event) {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState((prevState) => {
            const selectedColumns = prevState.selectedColumns;
            selectedColumns[key] = value;
            return { selectedColumns };
        });
    }

    _exportVendors(event) {
        event.preventDefault();

        const mapping = this.availableColumns.filter((column) => this.state.selectedColumns[column.key]);

        return this.props
            .exportVendors(mapping)
            .then(() => this.props.loadAsyncTasksList(1, this.defaultPageSize, AsyncTasksTypes.EXPORT_VENDORS));
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadVendors: bindActionCreators(VendorActionCreators.loadVendors, dispatch),
        exportVendors: bindActionCreators(VendorActionCreators.exportVendors, dispatch),
        loadAsyncTasksList: bindActionCreators(AsyncActionCreators.loadAsyncTasksList, dispatch),
        appendAsyncTasksList: bindActionCreators(AsyncActionCreators.appendAsyncTasksList, dispatch),
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
    };
}

function mapStateToProps(state: State) {
    return {
        vendors: state.vendors.list,
        exports: state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_VENDORS] || new PagedState(),
        managerId: state.auth.manager ? state.auth.manager.id : null,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorExportPage as any);
