/* eslint-disable */
import { currencyFormatter, displayTime } from '../../utils/utils';
import { BigNumberTypeTotal, GenerationPeriod, TypeTotal } from '../../transactions/transactionTypeTotals';
import * as React from 'react';
import { TransactionType, TransactionTypes } from '../../transactions/transactions';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { alignDateToUtcMoment } from '../../utils/DateFilter';
import { BigNumber } from '../../utils/bigNumber';
import { EntitlementCurrencyConfig } from '../../app/appConfig';

export interface MappedTotals {
    [key: string]: BigNumberTypeTotal;
}

export interface SumWithTypesContribution {
    transactionsSum: BigNumber;
    transactionTypesContribution: string;
    transactionsCount: BigNumber;
}

interface Props {
    startDate: Date;
    endDate: Date;
    dynamicStartTypeTotals: Array<TypeTotal>;
    typeTotalsSinceSystemStart: Array<TypeTotal>;
    generationPeriod: GenerationPeriod;
    downloadSummaryStatisticsTransactions: (
        timezone: string,
        typesContribution: string,
        transactionCount: number
    ) => void;
    canDownloadSummaryReport: boolean;
    currency: EntitlementCurrencyConfig;
    timezone: string;
    systemStartDate: Date;
}

export function WfpTotals({
    startDate,
    endDate,
    dynamicStartTypeTotals,
    typeTotalsSinceSystemStart,
    generationPeriod,
    downloadSummaryStatisticsTransactions,
    canDownloadSummaryReport,
    currency,
    timezone,
    systemStartDate,
}: Props) {
    let generatedFrom = null;
    let generatedTo = null;

    if (generationPeriod) {
        generatedFrom = moment(
            new Date(moment(generationPeriod.generatedFrom).tz(timezone).format().slice(0, 19))
        ).format();
        generatedTo = moment(
            new Date(moment(generationPeriod.generatedTo).tz(timezone).format().slice(0, 19))
        ).format();
    }

    const formattedStartDate = moment(startDate).format();
    const formattedEndDate = moment(endDate).format();

    if (
        dynamicStartTypeTotals &&
        dynamicStartTypeTotals.length > 0 &&
        dynamicStartTypeTotals[0] &&
        typeTotalsSinceSystemStart &&
        typeTotalsSinceSystemStart.length > 0 &&
        typeTotalsSinceSystemStart[0] &&
        generationPeriod &&
        moment(formattedStartDate).isSameOrAfter(moment(generatedFrom)) &&
        moment(formattedEndDate).isSameOrBefore(moment(generatedTo))
    ) {
        const intl = new Intl.NumberFormat(navigator.language);

        const bigNumberDynamicStartTypeTotals: Array<BigNumberTypeTotal> = dynamicStartTypeTotals.map((typeTotal) =>
            Object.assign(
                {},
                typeTotal,
                {
                    transactionsSum: new BigNumber(typeTotal.transactionsSum),
                },
                { transactionsCount: Number(typeTotal.transactionsCount) }
            )
        );

        const bigNumberTypeTotalsSinceSystemStart: Array<BigNumberTypeTotal> = typeTotalsSinceSystemStart.map(
            (typeTotal) =>
                Object.assign(
                    {},
                    typeTotal,
                    {
                        transactionsSum: new BigNumber(typeTotal.transactionsSum),
                    },
                    { transactionsCount: Number(typeTotal.transactionsCount) }
                )
        );

        const dynamicTypeTotalsObject: MappedTotals = _.keyBy(bigNumberDynamicStartTypeTotals, 'transactionType');
        const typeTotalsSinceStartObject: MappedTotals = _.keyBy(
            bigNumberTypeTotalsSinceSystemStart,
            'transactionType'
        );

        const beneficiariesDynamicNetUploads = calculateSumWithTypesContribution(
            dynamicTypeTotalsObject,
            [TransactionType.topup],
            [TransactionType.unload]
        );

        const beneficiariesDynamicNetExpenditures = calculateSumWithTypesContribution(
            dynamicTypeTotalsObject,
            [TransactionType.spendBalance],
            [TransactionType.reverse]
        );

        const vendorDynamicNetPayments = calculateSumWithTypesContribution(
            dynamicTypeTotalsObject,
            [TransactionType.reclaimBalance],
            [TransactionType.addVendorBalance]
        );

        const beneficiariesBalanceSinceSystemStart = calculateSumWithTypesContribution(
            typeTotalsSinceStartObject,
            [TransactionType.topup, TransactionType.reverse],
            [TransactionType.unload, TransactionType.spendBalance]
        );

        const vendorsBalanceSinceSystemStart = calculateSumWithTypesContribution(
            typeTotalsSinceStartObject,
            [TransactionType.addVendorBalance, TransactionType.spendBalance],
            [TransactionType.reverse, TransactionType.reclaimBalance]
        );

        const wfpBalanceSinceSystemStart = calculateSumWithTypesContribution(
            typeTotalsSinceStartObject,
            [TransactionType.unload, TransactionType.reclaimBalance],
            [TransactionType.topup, TransactionType.addVendorBalance]
        );

        const formattedEndDate = moment.utc(alignDateToUtcMoment(endDate)).format('DD-MM-YYYY');

        const formattedStartDate = moment.utc(alignDateToUtcMoment(startDate)).format('DD-MM-YYYY');

        // const countOfAllTransactions = beneficiariesDynamicNetUploads.transactionsCount + beneficiariesDynamicNetExpenditures.transactionsCount +
        //                             vendorDynamicNetPayments.transactionsCount + beneficiariesBalanceSinceSystemStart.transactionsCount

        const currentDayStart = `${moment().format('YYYY-MM-DD')} 00:00:00`;
        const currentDayEnd = `${moment().format('YYYY-MM-DD')} 23:59:59`;

        return (
            <div>
                <table className="wfp-table mt4 table-hover wfp-account-table">
                    <tbody>
                        <tr>
                            <td>Description</td>
                            <td>Tx Sum</td>
                            <td>Tx Count</td>
                            {/*{canDownloadSummaryReport && countOfAllTransactions > 0 && <td>Action</td>}*/}
                        </tr>
                        <tr>
                            <td>
                                Beneficiary Net Uploads from {formattedStartDate} to {formattedEndDate}{' '}
                                {renderToolTip(beneficiariesDynamicNetUploads.transactionTypesContribution)}
                            </td>
                            <td>
                                {currencyFormatter(currency).format(
                                    beneficiariesDynamicNetUploads.transactionsSum.toNumber()
                                )}
                            </td>
                            <td>{intl.format(beneficiariesDynamicNetUploads.transactionsCount.toNumber())}</td>
                            {/*<td>*/}
                            {/*{canDownloadSummaryReport && beneficiariesDynamicNetUploads.transactionsCount > 0 ?*/}
                            {/*<a onClick={() => downloadSummaryStatisticsTransactions(beneficiariesDynamicNetUploads.transactionTypesContribution, beneficiariesDynamicNetUploads.transactionsCount)}>Download</a> :*/}
                            {/*<p></p>*/}
                            {/*}</td>*/}
                        </tr>
                        <tr>
                            <td>
                                Beneficiary Net Expenditures from {formattedStartDate} to {formattedEndDate}{' '}
                                {renderToolTip(beneficiariesDynamicNetExpenditures.transactionTypesContribution)}
                            </td>
                            <td>
                                {currencyFormatter(currency).format(
                                    beneficiariesDynamicNetExpenditures.transactionsSum.toNumber()
                                )}
                            </td>
                            <td>{intl.format(beneficiariesDynamicNetExpenditures.transactionsCount.toNumber())}</td>

                            {/*<td>*/}
                            {/*{canDownloadSummaryReport && beneficiariesDynamicNetExpenditures.transactionsCount > 0 ?*/}
                            {/*<a onClick={() => downloadSummaryStatisticsTransactions(beneficiariesDynamicNetExpenditures.transactionTypesContribution, beneficiariesDynamicNetExpenditures.transactionsCount)}>Download</a> :*/}
                            {/*<p></p>*/}
                            {/*}</td>*/}
                        </tr>
                        <tr>
                            <td>
                                Vendor Net Payments from {formattedStartDate} to {formattedEndDate}{' '}
                                {renderToolTip(vendorDynamicNetPayments.transactionTypesContribution)}
                            </td>
                            <td>
                                {currencyFormatter(currency).format(
                                    vendorDynamicNetPayments.transactionsSum.toNumber()
                                )}
                            </td>
                            <td>{intl.format(vendorDynamicNetPayments.transactionsCount.toNumber())}</td>

                            {/*<td>*/}
                            {/*{canDownloadSummaryReport && vendorDynamicNetPayments.transactionsCount > 0 ?*/}
                            {/*<a onClick={() => downloadSummaryStatisticsTransactions(vendorDynamicNetPayments.transactionTypesContribution, vendorDynamicNetPayments.transactionsCount)}>Download</a> :*/}
                            {/*<p></p>*/}
                            {/*}</td>*/}
                        </tr>
                        <tr>
                            <td>
                                Total Beneficiary balance as of {formattedEndDate}{' '}
                                {renderToolTip(beneficiariesBalanceSinceSystemStart.transactionTypesContribution)}
                            </td>
                            <td>
                                {currencyFormatter(currency).format(
                                    beneficiariesBalanceSinceSystemStart.transactionsSum.toNumber()
                                )}
                            </td>
                            <td></td>

                            {/*<td>*/}
                            {/*{canDownloadSummaryReport && beneficiariesBalanceSinceSystemStart.transactionsCount > 0 ?*/}
                            {/*<a onClick={() => downloadSummaryStatisticsTransactions(beneficiariesBalanceSinceSystemStart.transactionTypesContribution, beneficiariesBalanceSinceSystemStart.transactionsCount)}>Download</a> :*/}
                            {/*<p></p>*/}
                            {/*}</td>*/}
                        </tr>
                        <tr>
                            <td>
                                Total Vendor balance as of {formattedEndDate}{' '}
                                {renderToolTip(vendorsBalanceSinceSystemStart.transactionTypesContribution)}
                            </td>
                            <td>
                                {currencyFormatter(currency).format(
                                    vendorsBalanceSinceSystemStart.transactionsSum.toNumber()
                                )}
                            </td>
                            <td></td>

                            {/*<td>*/}
                            {/*{canDownloadSummaryReport && vendorsBalanceSinceSystemStart.transactionsCount > 0 ?*/}
                            {/*<a onClick={() => downloadSummaryStatisticsTransactions(vendorsBalanceSinceSystemStart.transactionTypesContribution, vendorsBalanceSinceSystemStart.transactionsCount)}>Download</a> :*/}
                            {/*<p></p>*/}
                            {/*}</td>*/}
                        </tr>
                        <tr>
                            <td>
                                Total WFP Liability as of {formattedEndDate}{' '}
                                {renderToolTip(wfpBalanceSinceSystemStart.transactionTypesContribution)}
                            </td>
                            <td>
                                {currencyFormatter(currency).format(
                                    Math.abs(wfpBalanceSinceSystemStart.transactionsSum.toNumber())
                                )}
                            </td>
                            <td></td>

                            {/*<td>*/}
                            {/*{canDownloadSummaryReport && wfpBalanceSinceSystemStart.transactionsCount > 0 ?*/}
                            {/*<a onClick={() => downloadSummaryStatisticsTransactions(wfpBalanceSinceSystemStart.transactionTypesContribution, wfpBalanceSinceSystemStart.transactionsCount)}>Download</a> :*/}
                            {/*<p></p>*/}
                            {/*}</td>*/}
                        </tr>
                    </tbody>
                </table>
                <br />
                <small>Total WFP liability should equal total Beneficiary balance plus total Vendor balance</small>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <small>
                            <i>All numbers calculated as of {displayTime(new Date(generationPeriod.generatedTo))}</i>
                        </small>
                        <br />
                        <small>
                            <i>
                                All "from" dates have start time{' '}
                                {moment.tz(currentDayStart, timezone).utc().format('HH:mm:ss')} UTC, all "to" dates have
                                end time {moment.tz(currentDayEnd, timezone).utc().format('HH:mm:ss')} UTC
                            </i>
                        </small>
                        <br />
                        <small>
                            <i>
                                Available transactions period from {displayTime(systemStartDate)} to{' '}
                                {displayTime(new Date(generationPeriod.generatedTo))} (total{' '}
                                {alignDateToUtcMoment(new Date(generationPeriod.generatedTo)).diff(
                                    alignDateToUtcMoment(systemStartDate),
                                    'days'
                                )}{' '}
                                days)
                            </i>
                        </small>
                    </div>
                </div>
            </div>
        );
    } else if (generationPeriod) {
        return (
            <div>
                Selected period is out of range.
                <br />
                Available transactions period from {displayTime(systemStartDate)} to{' '}
                {displayTime(new Date(generationPeriod.generatedTo))}
            </div>
        );
    } else {
        return (
            <div className="col-sm-12">
                <small>
                    <i>No records for selected date</i>
                </small>
            </div>
        );
    }
}

const calculateSumWithTypesContribution = (
    typeTotals: MappedTotals,
    plusContributingTransactionTypes: Array<TransactionType>,
    minusContributingTransactionTotals: Array<TransactionType>
): SumWithTypesContribution => {
    const plusContribution: SumWithTypesContribution = plusContributingTransactionTypes.reduce(
        (previousSum, transactionType, index) => {
            const currentTransactionTypeSum = typeTotals[transactionType].transactionsSum;
            const currentTransactionTypeCount = typeTotals[transactionType].transactionsCount;
            return {
                transactionsSum: previousSum.transactionsSum.plus(currentTransactionTypeSum),
                transactionTypesContribution:
                    previousSum.transactionTypesContribution +
                    (index === 0 ? '' : ' + ') +
                    TransactionTypes.displayName(transactionType),
                transactionsCount: previousSum.transactionsCount.plus(currentTransactionTypeCount),
            };
        },
        {
            transactionsSum: new BigNumber(0),
            transactionTypesContribution: '',
            transactionsCount: new BigNumber(0),
        }
    );

    const totalSum: SumWithTypesContribution = minusContributingTransactionTotals.reduce(
        (previousSum, transactionType) => {
            const currentTransactionTypeSum = typeTotals[transactionType].transactionsSum;
            const currentTransactionTypeCount = typeTotals[transactionType].transactionsCount;
            return {
                transactionsSum: previousSum.transactionsSum.minus(currentTransactionTypeSum),
                transactionTypesContribution:
                    previousSum.transactionTypesContribution + ' - ' + TransactionTypes.displayName(transactionType),
                transactionsCount: previousSum.transactionsCount.minus(currentTransactionTypeCount),
            };
        },
        {
            transactionsSum: plusContribution.transactionsSum,
            transactionTypesContribution: plusContribution.transactionTypesContribution,
            transactionsCount: plusContribution.transactionsCount,
        }
    );

    return totalSum;
};

const renderToolTip = (infoToBeRendered) => (
    <span className="icon-help-dark xsmall" title={`${infoToBeRendered || ''}`}></span>
);
