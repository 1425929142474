import { useSelector } from 'react-redux';
import State from '../app/store/state';
import { hasFeatureAccess } from '../login/auth';
import { supStyle } from '../utils/utils';
import { stopPropagation } from '../utils/events';
import * as React from 'react';
import { Vendor } from './vendors';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    authorize: (Vendor) => void;
    cancelPark: (id: string) => void;
    postEnabled: boolean;
    showAnnotation: boolean;
    thisManagerParked: (Vendor) => boolean;
    vendor: Vendor;
}
export function VendorNextAction({
    vendor,
    showAnnotation,
    thisManagerParked,
    postEnabled,
    authorize,
    cancelPark,
}: Props) {
    const cancellationText = 'Cancel';
    const cancelledText = 'Cancelled';
    const auth = useSelector((state: State) => state.auth);
    let requestCount = hasFeatureAccess(auth, ManagerPermission.vendorUserPost)
        ? Number(vendor.vendorUsersRequestCount ? vendor.vendorUsersRequestCount : 0)
        : 0;

    requestCount += hasFeatureAccess(auth, ManagerPermission.vendorsPost)
        ? Number(vendor.vendorBranchesRequestCount ? vendor.vendorBranchesRequestCount : 0)
        : 0;

    return (
        <td>
            {vendor.authorizedByManager ? (
                <div>Posted {requestCount > 0 && <sup style={supStyle}>{requestCount}</sup>}</div>
            ) : vendor.cancelledByManagerId ? (
                cancelledText
            ) : (
                <span>
                    Parked
                    {thisManagerParked(vendor) ? (
                        <label style={{ color: 'red' }}>*</label>
                    ) : (
                        postEnabled && (
                            <span>
                                <br />
                                <a onClick={stopPropagation(() => authorize(vendor.id))}>Post</a>
                            </span>
                        )
                    )}
                    <br />
                    <a onClick={stopPropagation(() => cancelPark(vendor.id))}>{cancellationText}</a>
                </span>
            )}
        </td>
    );
}
