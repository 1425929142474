import * as React from 'react';
import { FormEvent } from 'react';
import { Input } from '../utils/inputs';

interface Props {
    search: (value: string) => void;
    style?: React.CSSProperties;
}

interface LocalState {
    searchValue: string;
}

const SearchContainerStyle = (): React.CSSProperties => ({
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
});

export default class BeneficiarySearch extends React.Component<Props, LocalState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            searchValue: '',
        };
    }

    private onSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.search(this.state.searchValue);
        this.setState({ searchValue: '' });
    };

    render() {
        return (
            <form
                className="col-sm-6"
                onSubmit={(e) => this.onSearch(e)}
                style={Object.assign(SearchContainerStyle(), this.props.style)}
            >
                <Input
                    className="form-control"
                    name={'beneficiary_search'}
                    onChange={({ target }) => this.setState({ searchValue: target.value.trim() })}
                    placeholder="Beneficiary ID"
                    style={{ width: '30%' }}
                    value={this.state.searchValue}
                />
                <button className="wfp-btn--primary xsmall" style={{ marginLeft: 10 }} type="submit">
                    Go
                </button>
            </form>
        );
    }
}
