import * as React from 'react';
import { browserHistory, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { Provider } from 'react-redux';
import getRoutes from '../Routes';
import scheduleManagerTokenExpirationChecks from '../utils/sessionExpiration/scheduleManagerTokenExpirationChecks';
import { dispatch, store } from '@wfp-root-app/store/store';

export const history = syncHistoryWithStore(browserHistory, store);

function scheduleTokenExpirationChecks() {
    if (document.visibilityState === 'visible') {
        dispatch(scheduleManagerTokenExpirationChecks());
    }
}

document.addEventListener('visibilitychange', scheduleTokenExpirationChecks);
scheduleTokenExpirationChecks();

export default class App extends React.Component<any, any> {
    render() {
        return (
            <Provider store={store as any}>
                <Router history={history}>{getRoutes(store)}</Router>
            </Provider>
        );
    }
}
