import * as React from 'react';
import FeatureButton from '../utils/FeatureButton';
import { stopPropagation } from '../utils/events';
import { trClassName, usePartnerUsersList } from './helpers/PartnerUsersListHelpers';
import { wfpFormat } from '../utils/utils';
import { SortableTableHead } from '../utils/table/table-head/sortable-table-head';
import FiltersComponent from '../utils/filters';
import { ManagerPermission } from '../permission-profiles/permission';

const columns = ['First Name', 'Last Name', 'Email', 'Status', 'Valid Until', ''];

export function PartnerUsersList({ params, location }) {
    const {
        partnerUsersList,
        manager,
        handleResetPassword,
        handleCancel,
        handlePost,
        redirectToPartnerUserPage,
        partner,
        handleSort,
        filters,
        handleFilterChanged,
    } = usePartnerUsersList(params.partnerId, location.pathname);
    return (
        <>
            {partner && partner.authorizedAt && !partner.cancelledByManager && (
                <FeatureButton
                    linkTo={`/partners/${params.partnerId}/panel/add-user`}
                    manager={manager}
                    restrictedToFeatures={[ManagerPermission.partnerUserPark]}
                    title="Add Partner User"
                />
            )}
            <FiltersComponent
                defaultFilters={filters}
                filters={filters}
                onFilterChanged={handleFilterChanged}
                withApply={false}
                withIsSelected={false}
            />
            <table className="wfp-table mt4 table-hover">
                <SortableTableHead changeSortOptions={handleSort}>
                    {columns.map((column) =>
                        column ? (
                            <th key={column}>{column}</th>
                        ) : (
                            <SortableTableHead.DisabledColumn key={column}>{column}</SortableTableHead.DisabledColumn>
                        )
                    )}
                </SortableTableHead>
                <tbody>
                    {partnerUsersList &&
                        partnerUsersList.map((partnerUser) => (
                            <tr
                                className={trClassName(partnerUser)}
                                key={partnerUser.id}
                                onClick={redirectToPartnerUserPage(partnerUser.id)}
                            >
                                <td>{partnerUser.firstName}</td>
                                <td>{partnerUser.lastName}</td>
                                <td>{partnerUser.email}</td>
                                <td>{wfpFormat(partnerUser.status)}</td>
                                <td>{partnerUser.expirationDate}</td>
                                <td style={{ minWidth: '160px' }}>
                                    {partnerUser.renderCancel && (
                                        <a className="mr-3" onClick={stopPropagation(handleCancel(partnerUser))}>
                                            Cancel
                                        </a>
                                    )}
                                    {partnerUser.renderPost && (
                                        <a onClick={stopPropagation(handlePost(partnerUser))}>Post</a>
                                    )}
                                    {partnerUser.renderResetPassword && (
                                        <a className="mr-3" onClick={stopPropagation(handleResetPassword(partnerUser))}>
                                            Reset Password
                                        </a>
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </>
    );
}
