import * as React from 'react';

export function IndividualIcon() {
    return (
        <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
            <defs />
            <path
                className="a"
                d="M9.105 3.067A2.845 2.845 0 116.26 5.912a2.845 2.845 0 012.845-2.845m0-1.138a3.983 3.983 0 103.983 3.983 3.983 3.983 0 00-3.983-3.983zM14.795 17.862h-1.138v-2.845a2.848 2.848 0 00-2.845-2.845H7.398a2.848 2.848 0 00-2.845 2.845v2.845H3.415v-2.845a3.988 3.988 0 013.983-3.983h3.417a3.988 3.988 0 013.983 3.983z"
            />
            <path d="M0 0h19v19H0z" fill="none" />
        </svg>
    );
}
