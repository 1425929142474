import * as React from 'react';

import { PaymentRequest, PaymentRequestStatus } from './paymentRequests';
import { AuthState } from '../login/auth';
import { currencyFormatter, displayTime } from '../utils/utils';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import { last } from 'lodash';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';
import { withNavigate } from '@wfp-common/hooks/withNavigate';

interface Props {
    auth: AuthState;
    payments: PaymentRequest[];
    periodEnd: Date | string;
    category: string;
    isSapModuleActive: boolean;
    currency: EntitlementCurrencyConfig;
    changePaymentRequestStatus: (
        paymentRequestId: string,
        targetStatus: PaymentRequestStatus,
        routeOverride?: string
    ) => void;
    navigate: NavigateHook;
}

class CancelledPaymentsRequestsTable extends React.Component<Props, any> {
    private _onRequestChosen(request: PaymentRequest) {
        this.props.navigate('/payments/' + request.id);
    }

    private renderTableHeader() {
        return (
            <thead>
                <tr>
                    <th>Vendor</th>
                    <th>Total Outstanding For Period</th>
                    <th>Discount</th>
                    <th>WFP Payable</th>
                    <th>Status</th>
                    <th>Last Status Change</th>
                    <th />
                </tr>
            </thead>
        );
    }

    private lastStatusChange(request: PaymentRequest): string {
        const statusChange = last(request.statusTransitions);
        return displayTime(statusChange.at);
    }

    private renderTableBody() {
        const formatter = currencyFormatter(this.props.currency).format;

        return (
            <tbody>
                {this.props.payments &&
                    this.props.payments.map((request) => {
                        return (
                            <tr
                                className={'cursor-pointer'}
                                key={`${request.vendor.id}-${request.id}`}
                                onClick={() => this._onRequestChosen(request)}
                            >
                                <td>{request.vendor.name}</td>
                                <td>{formatter(request.outstandingAmount)}</td>
                                <td>{formatter(request.bankFeeAmount)}</td>
                                <td>{formatter(request.payableAmount)}</td>
                                <td>{request.status}</td>
                                <td>{this.lastStatusChange(request)}</td>
                            </tr>
                        );
                    })}
            </tbody>
        );
    }

    render() {
        return (
            <table className="wfp-table--striped mt3 table-hover">
                {this.renderTableHeader()}
                {this.renderTableBody()}
            </table>
        );
    }
}

export default withNavigate(CancelledPaymentsRequestsTable, 'CancelledPaymentsRequestsTable');
