import * as React from 'react';
import { useState } from 'react';
import { EntityUpdate } from '../taskAuthorizations';
import {
    checkProductGroupPendingUpdateRequests,
    checkProductGroupScheduledUpdates,
} from '../../api/product-management';
import { PendingUpdateModal } from '../../product-management/modals/pending-update.modal';
import { ScheduledUpdateModal } from '../../product-management/modals/scheduled-update.modal';
import { displayTime } from '../../utils/utils';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

interface Props {
    updates: EntityUpdate[];
    validFrom: string | Date;
    productGroup: string;
    productGroupId: number | string;
}

export function ProductManagementReworkUpdateRequestComponent({
    updates,
    validFrom,
    productGroup,
    productGroupId,
}: Props) {
    const navigate = useNavigate();
    const [activeModal, setActiveModal] = useState(null);
    const [scheduledDate, setScheduledDate] = useState<Date>(null);

    const toggleModal = () => {
        setActiveModal(null);
    };
    const handleClick = async () => {
        const [hasPendingUpdates, scheduledUpdates] = await Promise.all([
            checkProductGroupPendingUpdateRequests(productGroupId),
            checkProductGroupScheduledUpdates(productGroupId),
        ]);
        if (hasPendingUpdates) {
            setActiveModal('update');
        } else if (Boolean(scheduledUpdates.length)) {
            setActiveModal('schedule');
            setScheduledDate(scheduledUpdates?.[0]?.validFrom);
        }
        if (!hasPendingUpdates && !Boolean(scheduledUpdates.length)) {
            navigate(`/products/update/${productGroup}`, { updates, validFrom, groupId: productGroupId });
        }
    };

    return (
        <p>
            {activeModal === 'update' && <PendingUpdateModal groupName={productGroup} onClose={toggleModal} />}
            {activeModal === 'schedule' && (
                <ScheduledUpdateModal
                    groupName={productGroup}
                    onClose={toggleModal}
                    scheduledDate={displayTime(scheduledDate)}
                />
            )}
            <a onClick={handleClick}>Rework</a>
        </p>
    );
}
