import {
    blockBeneficiaries,
    exportBeneficiaries,
    exportBeneficiaryPins,
    getClustersInfo,
    getEntitlements,
    importAlternativeCollectors,
    importBeneficiaries,
    importBeneficiaryMapping,
    loadBeneficiaries,
    removeAlternativeCollectors,
    unblockBeneficiaries,
    zeroBeneficiaries,
} from '../apiClient';
import Action from '../action';
import { Filter } from '../utils/FilterTypes';
import { PagedState } from '../utils/paging';
import { UploadStatus, UploadStatuses } from '../utils/import';
import { SerializedBalanceEntry } from '../vendors/model';
import { AsyncTask } from '../utils/asyncTasks';
import NamedId from '../utils/NamedId';
import { EntityUpdateReference } from '../authorization/taskAuthorizations';
import { errorAction } from '../utils/notifications';
import { HttpStatus } from '../common/enums';

export type BeneficiaryStatus = 'active' | 'blocked';

export class BeneficiaryStatuses {
    static active: BeneficiaryStatus = 'active';
    static blocked: BeneficiaryStatus = 'blocked';

    static allStatuses(): Array<BeneficiaryStatus> {
        return [BeneficiaryStatuses.active, BeneficiaryStatuses.blocked];
    }
}

export type BeneficiaryAccountType = 'family' | 'individual' | 'not-refugee';

export class BeneficiaryAccountTypes {
    static family: BeneficiaryAccountType = 'family';
    static individual: BeneficiaryAccountType = 'individual';
}

export type BeneficiaryCampType = 'refugee' | 'non-refugee';

export class BeneficiaryCampTypes {
    static refugee: BeneficiaryCampType = 'refugee';
    static nonRefugee: BeneficiaryCampType = 'non-refugee';

    static allCampTypes(): Array<BeneficiaryCampType> {
        return [BeneficiaryCampTypes.nonRefugee, BeneficiaryCampTypes.refugee];
    }
}

export type BeneficiaryBalanceDbMatch = 'valid' | 'invalid';

export class BeneficiaryDbBalanceStatuses {
    static valid: BeneficiaryBalanceDbMatch = 'valid';
    static invalid: BeneficiaryBalanceDbMatch = 'invalid';

    static allStatuses(): Array<BeneficiaryBalanceDbMatch> {
        return [BeneficiaryDbBalanceStatuses.valid, BeneficiaryDbBalanceStatuses.invalid];
    }
}

export class BeneficiaryComment {
    id: string;
    comment: string;
    createdByManager: NamedId;
    createdAt: string;
}

interface StatusInfo {
    reason: string;
    source: string;
    zeroingReason: string;
    zeroingSource: string;
    balanceBeforeBlocking?: SerializedBalanceEntry[];
    balanceBeforeZeroing?: {
        active: SerializedBalanceEntry[];
        inactive: SerializedBalanceEntry[];
    };
}

export class Beneficiary {
    id = '';
    firstName = '';
    lastName = '';
    phoneNumber = '';
    blockchainAddress = '';
    locationOne = '';
    locationTwo = '';
    locationThree = '';
    householdSize = 0;
    householdId = '';
    createdAt = '';
    lastActiveAt = '';
    lastTransactionAt = '';
    status: BeneficiaryStatus = BeneficiaryStatuses.active;
    balance: SerializedBalanceEntry[];
    comments: BeneficiaryComment[] = [];
    statusInfo: StatusInfo;
    statusChangedAt = '';
    pendingEntityUpdates: EntityUpdateReference[];
    alternativeCollectorId: string;
    alternativeCollectorCaseId: string;
    alternativeCollectorStatus: string;
    alternativeCollectorStatusInfo: { reason: string; source: string };
    proxyContractAddress = '';
    groupType: BeneficiaryAccountType = BeneficiaryAccountTypes.family;
    accountType: BeneficiaryCampType = BeneficiaryCampTypes.refugee;
    individualBeneficiaries: string[];
}

export class UploadTaskState {
    status?: UploadStatus;
    task?: AsyncTask;
    error?: string;

    constructor(status: UploadStatus = null, task: AsyncTask = null, error: string = null) {
        this.status = status;
        this.task = task;
        this.error = error;
    }
}

export const ActionTypes = {
    dataLoaded: 'BeneficiariesPage.dataLoaded',
    newEntitlements: 'BeneficiariesPage.newEntitlements',
    uploadTaskStarted: 'Beneficiaries.uploadTaskStarted',
    uploadTaskLoaded: 'Beneficiaries.uploadTaskLoaded',
    uploadTaskFinished: 'Beneficiaries.uploadTaskFinished',
    uploadTaskError: 'Beneficiaries.uploadTaskError',
};

const displayErrorForStatusCode = (err) => {
    if (err.status === 422) {
        return 'File type not supported';
    } else if (err.status === 413) {
        return 'File size is too big';
    } else if (err.statusText) {
        return err.statusText;
    } else {
        return 'Server Error';
    }
};

export const ActionCreators = {
    loadBeneficiaries(timezone: string, page, limit = 25, filters: Array<Filter> = [], sortingOptions) {
        return (dispatch) => {
            return loadBeneficiaries(timezone, filters, page, limit, sortingOptions).then((beneficiaries) =>
                dispatch({
                    type: ActionTypes.dataLoaded,
                    payload: Object.assign(beneficiaries, {
                        filters,
                    }),
                })
            );
        };
    },

    loadEntitlements(beneficiaryId: string, pageNumber: number, pageSize: number) {
        return () => {
            return getEntitlements(beneficiaryId, pageNumber, pageSize);
        };
    },

    loadClustersInfo(beneficiaryId: string) {
        return () => {
            return getClustersInfo(beneficiaryId);
        };
    },

    exportBeneficiaries(timezone: string, columns: any[], filters: Array<Filter> = []) {
        return (dispatch) =>
            exportBeneficiaries(timezone, columns, filters).catch((err) => {
                if (err.status === 423) {
                    dispatch(
                        errorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        )
                    );
                }
            });
    },

    exportBeneficiaryPins(timezone: string, filters: Array<Filter>) {
        return (dispatch) =>
            exportBeneficiaryPins(timezone, filters).catch((error) => {
                const dispatchErrorAction = (msg: string) => {
                    dispatch(errorAction(msg));
                };

                switch (error.status) {
                    case HttpStatus.badRequest:
                        dispatchErrorAction('Could not export pins because the GPG key is missing');
                        break;
                    case HttpStatus.locked:
                        dispatchErrorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        );
                        break;
                }
            });
    },

    uploadWithStatusUpdates(uploadFile: () => Promise<AsyncTask>) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.uploadTaskStarted });

            return uploadFile()
                .then((result) =>
                    dispatch({
                        type: ActionTypes.uploadTaskFinished,
                        payload: result,
                    })
                )
                .catch((err) => {
                    dispatch({
                        type: ActionTypes.uploadTaskError,
                        payload: displayErrorForStatusCode(err),
                    });
                });
        };
    },

    importBeneficiaries(data) {
        return ActionCreators.uploadWithStatusUpdates(() => importBeneficiaries(data));
    },
    importBeneficiariesMapping(data) {
        return ActionCreators.uploadWithStatusUpdates(() => importBeneficiaryMapping(data));
    },
    importAlternativeCollectors(data) {
        return ActionCreators.uploadWithStatusUpdates(() => importAlternativeCollectors(data));
    },
    removeAlternativeCollectors(data) {
        return ActionCreators.uploadWithStatusUpdates(() => removeAlternativeCollectors(data));
    },
    blockBeneficiaries(data, isZeroingBalance?: boolean) {
        return ActionCreators.uploadWithStatusUpdates(() => blockBeneficiaries(data, isZeroingBalance));
    },
    zeroBeneficiaries(data) {
        return ActionCreators.uploadWithStatusUpdates(() => zeroBeneficiaries(data));
    },
    unblockBeneficiaries(data) {
        return ActionCreators.uploadWithStatusUpdates(() => unblockBeneficiaries(data));
    },
    clearUploadTask() {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.uploadTaskLoaded,
            });
        };
    },
};

export function beneficiariesReducer(state: PagedState<Beneficiary> = new PagedState<Beneficiary>(), action: Action) {
    switch (action.type) {
        case ActionTypes.dataLoaded:
            return action.payload;
    }
    return state;
}

export function uploadTaskReducer(state: UploadTaskState = new UploadTaskState(), action: Action) {
    switch (action.type) {
        case ActionTypes.uploadTaskStarted:
            return new UploadTaskState(UploadStatuses.pending, null);
        case ActionTypes.uploadTaskFinished:
            return new UploadTaskState(UploadStatuses.finished, action.payload);
        case ActionTypes.uploadTaskLoaded:
            return new UploadTaskState(null, null);
        case ActionTypes.uploadTaskError:
            return new UploadTaskState(UploadStatuses.finished, null, action.payload);
        default:
            return state;
    }
}
