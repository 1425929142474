import * as React from 'react';
import { Filter, FilterTypes, DateSelectFilter } from './FilterTypes';
import * as moment from 'moment';
import * as _ from 'lodash';

interface Props {
    filters: Array<Filter>;
}

export default class SelectedFiltersComponent extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            filters: this.props.filters,
        };
    }

    filterComponent(filter: Filter) {
        if (!filter.isSelected) {
            return null;
        }
        switch (filter.type) {
            case FilterTypes.select:
                return (
                    <div key={filter.name}>
                        {filter.label}: {_.upperFirst(filter.value)}
                    </div>
                );
            case FilterTypes.multiselect:
                return (
                    <div key={filter.name}>
                        {filter.label}:{' '}
                        {filter.value.map((selectObject) => _.upperFirst(selectObject.label)).toString()}
                    </div>
                );
            case FilterTypes.text:
                if (filter.value === '') {
                    return null;
                }
                return (
                    <div key={filter.name}>
                        {filter.label}:{' '}
                        {filter.value
                            .split(',')
                            .map((val) => _.upperFirst(val))
                            .toString()}
                    </div>
                );
            case FilterTypes.date:
                const start = filter.dateRange.startDate
                    ? moment((filter as DateSelectFilter).dateRange.startDate).format('YYYY-MM-DD')
                    : 'Beginning';
                const end = moment((filter as DateSelectFilter).dateRange.endDate).format('YYYY-MM-DD');
                const label = start + ' - ' + end;
                return (
                    <div key={filter.name}>
                        {filter.label}: {label}
                    </div>
                );
        }
    }

    render() {
        return (
            <div>
                <form className="wfp-form--stacked filters-form" onSubmit={(e) => e.preventDefault()}>
                    {this.state.filters.map((filter) => {
                        return this.filterComponent(filter);
                    })}
                </form>
            </div>
        );
    }
}
