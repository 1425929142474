import * as React from 'react';
import { Outlets } from '../../product-management/outlets.component';
import { useToggle } from '../../utils/useToggle';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from '../../utils/utils';
import { errorAction } from '../../utils/notifications';
import { Outlet } from '../../product-management/types/outlet.type';
import styled, { css } from 'styled-components';
import { Loader } from '../../utils/Loader';
import { useDisableGlobalScrollHook } from '../../utils/hooks/use-disable-global-scroll.hook';
import { findOutletsByGroupId } from '../../api/product-management';

interface Props {
    groupId: number | string;
    validFrom: Date;
    inline?: boolean;
}

export function ProductManagementVendorModalComponent({ groupId, validFrom, inline }: Props) {
    const [isOpen, toggleIsOpen] = useToggle(inline === true);
    const [outlets, setOutlets] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isOpen && !outlets) {
            setLoading(true);
            findOutletsByGroupId(groupId, validFrom)
                .then((response: Outlet[]) => {
                    const outlets = response.reduce(
                        (outlets, branchCode) => ({
                            ...outlets,
                            [branchCode.outlet]: [...(outlets[branchCode.outlet] || []), branchCode],
                        }),
                        {}
                    );
                    setOutlets(Object.entries(outlets));
                })
                .catch(() => {
                    compose(dispatch, errorAction)('Downloading outlets was unsuccessful');
                    toggleIsOpen(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [isOpen]);
    const shouldDisableScroll = inline === true ? false : isOpen;
    useDisableGlobalScrollHook(shouldDisableScroll);
    return (
        <>
            {!inline && <a onClick={toggleIsOpen}>View Outlets</a>}
            {isOpen && (
                <ModalContainer inline={inline}>
                    <div className="outlet-modal">
                        {loading ? (
                            <Loader />
                        ) : outlets?.length ? (
                            <>
                                {!inline && <h5>Outlets</h5>}
                                <table className="wfp-table" style={{ tableLayout: 'fixed' }}>
                                    <Outlets outlets={outlets} />
                                </table>
                            </>
                        ) : (
                            <p style={{ textAlign: 'center' }}>
                                <strong>Did not find any outlets for this group</strong>
                            </p>
                        )}
                        {!inline && (
                            <footer>
                                <button className="wfp-btn" onClick={toggleIsOpen}>
                                    Close
                                </button>
                            </footer>
                        )}
                    </div>
                </ModalContainer>
            )}
        </>
    );
}

const ModalContainer = styled.div<{ inline: boolean }>`
    display: grid;
    place-items: center;
    ${(props) => (props.inline ? `` : modalCSS)}
    thead tr th {
        background: rgba(238, 246, 255, 1);
    }
    .outlet-modal {
        border-radius: 3px;
        overflow: auto;
        height: auto;
        display: grid;
        grid-template-rows: 1fr auto;
        padding-top: 1rem;
        footer {
            padding: 1rem;
            display: flex;
            flex-direction: row-reverse;
        }
    }
`;

const modalCSS = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.05);
    .outlet-modal {
        border: 1px solid rgba(0, 0, 0, 0.125);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background: white;
        max-width: 50vw;
        max-height: 90vh;
        padding: 1rem;
    }
`;
