import * as _ from 'lodash';
import { Filter, SelectFilter, SelectOption } from './FilterTypes';

function createSelectOptionLabel(nickName: any, filterName: string) {
    if (filterName === 'vendorId') {
        return nickName.nickName;
    } else if (filterName === 'partnerId') {
        return nickName.name;
    } else {
        return nickName.firstName + ' ' + nickName.lastName;
    }
}

export function updateFiltersWithNickNames(entityNames, filters: Filter[], filterName: string): Filter[] {
    const entityOptions = entityNames.map(
        (entityName) => new SelectOption(createSelectOptionLabel(entityName, filterName), entityName.id)
    );
    const entityFilterIndex = filters.findIndex((filter) => filter.name === filterName);
    const entityFilter = filters[entityFilterIndex] as SelectFilter;
    const newEntityFilter = { ...entityFilter, options: entityOptions };

    const newFilters = [...filters];
    newFilters[entityFilterIndex] = newEntityFilter;
    return newFilters;
}

export function updateFiltersWithBranchCodes(branchCodes: string[], filters: Filter[], filterName: string): Filter[] {
    const entityOptions = branchCodes.map((entityName) => new SelectOption(entityName, entityName));

    const entityFilterIndex = filters.findIndex((filter) => filter.name === filterName);
    const entityFilter = filters[entityFilterIndex] as SelectFilter;
    const newEntityFilter = { ...entityFilter, options: entityOptions };

    const newFilters = [...filters];
    newFilters[entityFilterIndex] = newEntityFilter;
    return newFilters;
}

export function updateFiltersWithCategories(categories, filters: Filter[], filterName: string): Filter[] {
    const entityOptions = categories.map((category) => new SelectOption(category, category));

    const entityFilterIndex = filters.findIndex((filter) => filter.name === filterName);
    const entityFilter = filters[entityFilterIndex] as SelectFilter;
    const newEntityFilter = { ...entityFilter, options: entityOptions };

    const newFilters = [...filters];
    newFilters[entityFilterIndex] = newEntityFilter;
    return newFilters;
}
