import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import loadingMiddleware from '../../utils/loadingMiddleware';
import errorMiddleware from '../../utils/errorMiddleware';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { browserHistory } from 'react-router';
import reducers from '@wfp-root-app/store/reducers';
import { BlockUiMiddleware } from 'react-block-ui/redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const composeEnhancers =
    process.env.NODE_ENV === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const enhancer = composeEnhancers(
    applyMiddleware(loadingMiddleware, errorMiddleware, thunk, routerMiddleware(browserHistory), BlockUiMiddleware)
);
export const store = createStore(combineReducers(reducers), enhancer);

export type RootAppState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootAppState> = useSelector;

export const dispatch = store.dispatch as ThunkDispatch<Store<RootAppState>, void, AnyAction>;
