import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import State from '../../app/store/state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HooksActionCreators } from '../partnersActions';
import { capitalizeFirstLetter, compose, separateCamelCase } from '../../utils/utils';
import { DatePicker } from '../../utils/DatePicker';
import { EntityType } from '../../authorization/taskAuthorizations';
import * as moment from 'moment';
const editableFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'expirationDate'];

export function usePartnerUserDetails(partnerUserId: string, partnerId: string) {
    const [isEditing, setIsEditing] = useState(false);
    const [hasPendingDetailsUpdate, setHasPendingDetailsUpdate] = useState(false);

    const dispatch = useDispatch();
    const loadPartnerUser = useMemo(() => HooksActionCreators.loadSinglePartnerUser(dispatch), []);
    useEffect(() => {
        loadPartnerUser(partnerUserId);
    }, [partnerUserId]);
    const partnerUser = useSelector((state: State) => state.partnerUsers.current);
    const savePartnerUser = useSelector((state: State) => {
        return HooksActionCreators.savePartnerUser(dispatch, state.partnerUsers?.current);
    });

    const formFields = useMemo(() => generateFormFieldsArray({ ...partnerUser, status: partnerUser?.status }), [
        partnerUser,
    ]);
    const toggleEdit = useCallback(() => {
        let hasUpdates = false;
        if (partnerUser && partnerUser.pendingEntityUpdates) {
            const hasBeneficiaryDetailsEntity = partnerUser.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.partnerUserDetails
            );
            hasUpdates = hasBeneficiaryDetailsEntity.length !== 0;
        }
        if (hasUpdates) {
            setHasPendingDetailsUpdate(true);
        } else {
            setIsEditing((state) => !state);
        }
    }, [partnerUser]);
    const handleSubmit = (formData) => {
        const { status, statusText, ...data } = formData;
        savePartnerUser({ ...data, status, statusText }, partnerId);
    };
    const auth = useSelector((state: State) => state.auth);

    return {
        partnerUser,
        isEditing,
        formFields,
        toggleEdit,
        handleSubmit,
        auth,
        hasPendingDetailsUpdate,
        setHasPendingDetailsUpdate,
    };
}
const sixMonthsAhead = new Date(moment().add(6, 'month').format());
function generateFormFieldsArray(partnerUser) {
    const array = Object.entries(partnerUser);
    const fields = array
        .filter(([key]) => !['pendingEntityUpdates', 'isPasswordBlocked'].includes(key))
        .map(([key, value]) => {
            const field = {
                name: key,
                label: compose(capitalizeFirstLetter, separateCamelCase)(key),
                initialValue: value,
                type: key === 'email' ? 'email' : 'text',
            } as any;
            if (key === 'expirationDate') {
                field.Component = function ExpirationDatePicker(props) {
                    return <DatePicker {...props} maxDate={sixMonthsAhead} />;
                };
            }
            if (!editableFields.includes(key)) {
                field.Component = ReadOnlyField;
            }
            return field;
        });
    return fields;
}

function ReadOnlyField({ label, initialValue }) {
    return (
        <p className="wfp-form--group row">
            <strong className="col-sm-2 ta-right">{compose(capitalizeFirstLetter, separateCamelCase)(label)}</strong>
            <span className="col-sm-6">{initialValue}</span>
        </p>
    );
}
