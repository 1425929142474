import * as React from 'react';
import PagingComponent, { PagedState } from '../utils/paging';
import ActivityLogCell from './ActivityLogCell';
import { ManagerNickName } from '../managers/managers';
import {
    defaultVendorSortingOrder,
    defaultVendorUsersSortingOrder,
    VendorNickName,
    VendorUserName,
} from '../vendors/vendors';
import { AuthState } from '../login/auth';
import FeatureButton from '../utils/FeatureButton';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { ManagerPermission } from '../permission-profiles/permission';
import {
    defaultPartnerSortOptions,
    defaultPartnerUserSortOptions,
    Partner,
    PartnerName,
    PartnerUserName,
} from '../partners/partners';

interface Props {
    loadActivityLogs: (page: number, limit: number, managerId?: string) => void;
    activityLogs: PagedState<any>;
    managersNickNames: ManagerNickName[];
    vendorsNickNames: VendorNickName[];
    vendorUsersNames: VendorUserName[];
    isManager: boolean;
    entityId: string;
    url?: string;
    loadManagersNickNames: () => void;
    loadVendorsNickNames: (sortOptions: SORT_OPTIONS) => void;
    loadVendorUsersNames: (sortOptions: SORT_OPTIONS) => void;
    loadPartnersNames: (sortOptions: SORT_OPTIONS) => void;
    loadPartnerUserNames: (sortOptions: SORT_OPTIONS) => void;
    auth: AuthState;
    downloadRestriction: ManagerPermission[];
    partnersNickNames: Array<PartnerName>;
    partnerUsersNickNames: Array<PartnerUserName>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

const PageLimit = 25;
export class ActivityLogsList extends React.Component<Props, State> {
    componentDidMount() {
        this.props.loadManagersNickNames();
        this.props.loadVendorUsersNames(defaultVendorUsersSortingOrder);
        this.props.loadVendorsNickNames(defaultVendorSortingOrder);
        this.props.loadPartnersNames(defaultPartnerSortOptions);
        this.props.loadPartnerUserNames(defaultPartnerUserSortOptions);
        this.onChangePageRequested(1);
    }

    onChangePageRequested(page: number) {
        if (this.props.isManager) {
            this.props.loadActivityLogs(page, PageLimit, this.props.auth.manager.id);
        } else {
            this.props.loadActivityLogs(page, PageLimit);
        }
    }

    checkIfEntitiesListsAreFetched() {
        return (
            this.props.managersNickNames &&
            this.props.vendorsNickNames &&
            this.props.vendorUsersNames &&
            this.props.managersNickNames.length > 0 &&
            this.props.vendorsNickNames.length > 0
        );
    }
    render() {
        const manager = this.props.auth.manager;
        const downloadRestriction = this.props.downloadRestriction;
        return (
            <div>
                <div className="row">
                    <h6 className="col-sm-4">Activity Logs</h6>

                    {this.props.url && (
                        <div className="col-sm-8 tr">
                            <FeatureButton
                                linkTo={this.props.url}
                                manager={manager}
                                restrictedToFeatures={downloadRestriction}
                                state={{ entityId: this.props.entityId }}
                                title={'Download'}
                            />
                        </div>
                    )}
                </div>

                {this.checkIfEntitiesListsAreFetched() &&
                    this.props.activityLogs.items &&
                    this.props.activityLogs.items.length > 0 &&
                    this.props.activityLogs.items.map((log) =>
                        ActivityLogCell(
                            log,
                            this.props.managersNickNames,
                            this.props.vendorsNickNames,
                            this.props.vendorUsersNames,
                            this.props.partnersNickNames,
                            this.props.partnerUsersNickNames
                        )
                    )}
                <PagingComponent
                    onPageChanged={this.onChangePageRequested.bind(this)}
                    paging={this.props.activityLogs.paging}
                />
            </div>
        );
    }
}
