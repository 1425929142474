import * as React from 'react';
import { useDispatch } from 'react-redux';
import { MouseEvent, useCallback, useState } from 'react';
import { ActionCreators as AsyncTaskActionCreators, AsyncTask } from '../utils/asyncTasks';
import { FileDocument } from './utils/types';
import { PencilIcon } from '../icons/Pencil.svg';
import { ClearIcon } from '../icons/Clear.svg';
import { DoneIcon } from '../icons/Done.svg';
import { updateDocumentFileName } from '../apiClient';
import { closeNotificationAction, errorAction } from '../utils/notifications';

interface Props {
    document: FileDocument;
    hasPermission: boolean;
    onSuccess(res: any): void;
    onClickCapture(event: MouseEvent<HTMLButtonElement>): void;
    getDocuments(): Promise<void>;
}
export const DocumentListItem: React.FC<Props> = ({
    getDocuments,
    document,
    hasPermission,
    onSuccess,
    onClickCapture,
}) => {
    const { asyncTask, isNew } = document;

    const [fileName, setFileName] = useState(document.fileName);
    const [edit, setEdit] = useState(false);

    const dispatch = useDispatch();
    const downloadFile = useCallback((type, id) => AsyncTaskActionCreators.downloadFile(type, id)(dispatch), []);
    const handleDownload = useCallback(async () => {
        try {
            const res = await downloadFile(asyncTask.type, asyncTask.id);
            await onSuccess(res);
        } catch (err) {}
    }, []);

    const handleCancel = () => {
        setFileName(document.fileName);
        setEdit(false);
    };

    const handleEdit = () => {
        if (document.fileName === fileName) {
            return dispatch(errorAction('The new file name must be different from the previous one.'));
        }

        if (fileName.length < 1) {
            return dispatch(errorAction('The new file name must not be empty.'));
        }

        updateDocumentFileName(document.id, document.fileName, fileName)
            .then(() => {
                dispatch(closeNotificationAction);
                setEdit(false);
                getDocuments();
            })
            .catch(() => dispatch(errorAction('Something went wrong.')));
    };

    const toggleEdit = () => setEdit(true);

    return (
        <>
            <div>
                {edit ? (
                    <input autoFocus onChange={(e) => setFileName(e.target.value)} value={fileName} />
                ) : (
                    <a onClick={handleDownload}>{fileName}</a>
                )}
            </div>
            {document.isNew && <span className="dot" />}
            {hasPermission &&
                (edit ? (
                    <>
                        <button onClick={handleCancel} type="button">
                            <ClearIcon />
                        </button>
                        <button onClick={handleEdit} type="button">
                            <DoneIcon />
                        </button>
                    </>
                ) : (
                    <>
                        <button onClick={toggleEdit} type="button">
                            <PencilIcon />
                        </button>
                        <button onClickCapture={onClickCapture} type="button">
                            <ClearIcon />
                        </button>
                    </>
                ))}
        </>
    );
};
