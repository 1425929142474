import * as React from 'react';
import { EventHandler, SyntheticEvent } from 'react';
import { stopPropagation } from '../../utils/events';
import { compose } from '../../utils/utils';
import { LocationStatus } from './locations';
import { hasFeatureAccess } from '../../login/auth';
import { ManagerPermission } from '../../permission-profiles/permission';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

export default function LocationsList({ locations, currentManagerId, actions, auth }) {
    return (
        <table className="wfp-table mt4 table-hover">
            <thead>
                <tr>
                    <th>Location 1</th>
                    <th>Location 2</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {locations.map((location) => (
                    <Row
                        actions={actions}
                        auth={auth}
                        currentManagerId={currentManagerId}
                        key={location.id}
                        location={location}
                    />
                ))}
            </tbody>
        </table>
    );
}

function Row({ location, currentManagerId, actions, auth }) {
    const navigate = useNavigate();
    const selectLocation = (locationId: string) => () => {
        navigate(`/beneficiaries/locations/${locationId}`);
    };
    const handleCancel = compose<string, EventHandler<SyntheticEvent>>(stopPropagation, actions.cancel)(location.id);

    const handleAuthorize = compose<string, EventHandler<SyntheticEvent>>(
        stopPropagation,
        actions.authorize
    )(location.id);

    const createdByCurrentManager = Number(location.createdByManagerId) === Number(currentManagerId);
    const isParked = location.status === LocationStatus.parked;
    const isCancelled = location.cancelledAt;
    const isPosted = location.authorizedAt;
    const hasPendingUpdates = location.pendingUpdates;
    const canCancel =
        (hasFeatureAccess(auth, ManagerPermission.beneficiariesPark) || createdByCurrentManager) &&
        !isCancelled &&
        !isPosted;
    const canAuthorize =
        hasFeatureAccess(auth, ManagerPermission.beneficiariesPost) &&
        !isCancelled &&
        !isPosted &&
        !createdByCurrentManager;

    return (
        <tr
            className={`cursor-pointer ${hasPendingUpdates ? 'attention-color' : ''}`}
            onClick={selectLocation(location.id)}
        >
            <td>{location.location1}</td>
            <td>{location.location2}</td>
            <td>
                {location.status}
                {isParked && createdByCurrentManager && <span className="text-danger">*</span>}
                {canCancel && (
                    <div>
                        <a onClick={handleCancel}>Cancel</a>
                    </div>
                )}
                {canAuthorize && (
                    <div>
                        <a onClick={handleAuthorize}>Post</a>
                    </div>
                )}
            </td>
        </tr>
    );
}
