import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Input } from '../utils/inputs';
import { SecurityProps } from '../security/security';
import classNames from 'classnames';
import styled from 'styled-components';
import { HttpStatus } from '../common/enums';

type OtpError = {
    status: HttpStatus;
    jsonResponse: {
        type: string;
    };
    statusText: string;
};

enum ErrorTypes {
    invalidOtp = 'invalidOtp',
    invalidOldOtp = 'invalidOldOtp',
}

export type ManagerOtpDialogProps = {
    security?: SecurityProps;
    title: string;
    confirmTitle: string;
    error: OtpError;
    success: boolean;
    managerId: number;
    onSubmit: (otp: string, secret: string, managerId: number, oldOtp?: string) => Promise<void>;
    onClose: () => void;
};

const FormDiv = styled.div`
    margin-top: 2rem;
`;

const CloseBtn = styled.button`
    margin-right: 1rem;
`;

export const ManagerOtpDialog: React.FC<ManagerOtpDialogProps> = ({
    title,
    confirmTitle,
    security,
    error,
    managerId,
    onSubmit,
    onClose,
}) => {
    const [otp, setOtp] = useState('');
    const [oldOtp, setOldOtp] = useState('');
    const invalidOtpError: ErrorTypes = useMemo(() => {
        return (error?.jsonResponse?.type as ErrorTypes) || undefined;
    }, [error?.jsonResponse?.type]);

    useEffect(() => {
        if (error && error.status === HttpStatus.forbidden) {
            switch (error.jsonResponse.type) {
                case ErrorTypes.invalidOtp:
                    setOtp('');
                    break;
                case ErrorTypes.invalidOldOtp:
                    setOldOtp('');
                    break;
            }
        }
    }, [error]);

    const renderOtpForm = () => {
        return (
            <div>
                {security.otpSecret && (
                    <div>
                        <div className="row">
                            <img alt="QR Code" src={security.otpQRDataUrl} style={{ margin: '5px auto 10px' }} />
                            <span style={{ textAlign: 'center' }}>
                                <p>Secret key: {security.otpSecret}</p>
                            </span>
                            <br />
                        </div>
                    </div>
                )}
                {security.otpEnabled && (
                    <FormDiv className="form-group">
                        <label htmlFor="login_old_otp">Old Multi-factor Authentication</label>
                        <Input
                            className={classNames('form-control', {
                                invalid: invalidOtpError === ErrorTypes.invalidOldOtp,
                            })}
                            name="login_old_otp"
                            onChange={(event) => {
                                setOldOtp(event.target.value);
                            }}
                            type="text"
                            value={oldOtp}
                        />
                        {invalidOtpError === ErrorTypes.invalidOldOtp && (
                            <span className="error">Invalid MFA Code.</span>
                        )}
                        <br />
                    </FormDiv>
                )}
                <div className="form-group">
                    <label htmlFor="login_otp">Multi-factor Authentication</label>
                    <Input
                        className={classNames('form-control', { invalid: invalidOtpError === ErrorTypes.invalidOtp })}
                        name="login_otp"
                        onChange={(event) => {
                            setOtp(event.target.value);
                        }}
                        type="text"
                        value={otp}
                    />
                    {invalidOtpError === ErrorTypes.invalidOtp && <span className="error">Invalid MFA Code.</span>}
                    <br />
                </div>
            </div>
        );
    };

    const handleOnSubmit = async () => {
        await onSubmit(otp, security.otpSecret, managerId, oldOtp);
    };

    return (
        <div>
            <div className="fade modal-backdrop in" />
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Form className="mv1 wfp-form" onSubmit={handleOnSubmit}>
                    <Modal.Body>{renderOtpForm()}</Modal.Body>
                    <Modal.Footer>
                        <CloseBtn className="wfp-btn" onClick={onClose} type="button">
                            Close
                        </CloseBtn>
                        <button className="wfp-btn--primary" type="submit">
                            {confirmTitle}
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal.Dialog>
        </div>
    );
};
