export class ReactSelectOption {
    label: string;
    value: number | string;
    isDisabled?: boolean;
    constructor(entity: { id: string | number; name: string; isDisabled?: boolean }) {
        this.label = entity.name;
        this.value = entity.id;
        this.isDisabled = entity.isDisabled ?? false;
    }
}
