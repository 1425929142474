import VendorTransaction from '../vendorPanel/transactions/transaction';
import { Transaction } from '../transactions/transactions';

export enum TransactionHeaders {
    agency = 'Agency',
    time = 'Time',
    sender = 'Sender',
    receiver = 'Receiver',
    transactionType = 'Type',
    transactionId = 'TX #',
    originalTransactionId = 'Original Transaction ID',
    amount = 'Amount',
    transactionStatus = 'TX Status',
    paymentStatus = 'Payment Status',
    itn = 'Transaction ITN',
    did = 'DID',
    outlet = 'Outlet',
    category = 'Category',
}

export const defaultManagerPanelTransactionHeaders = [
    TransactionHeaders.transactionId,
    TransactionHeaders.outlet,
    TransactionHeaders.agency,
    TransactionHeaders.time,
    TransactionHeaders.sender,
    TransactionHeaders.receiver,
    TransactionHeaders.transactionType,
    TransactionHeaders.category,
    TransactionHeaders.amount,
    TransactionHeaders.transactionStatus,
    TransactionHeaders.paymentStatus,
];

export const defaultVendorPanelTransactionHeaders = [
    TransactionHeaders.time,
    TransactionHeaders.agency,
    TransactionHeaders.transactionId,
    TransactionHeaders.outlet,
    TransactionHeaders.did,
    TransactionHeaders.category,
    TransactionHeaders.amount,
    TransactionHeaders.transactionStatus,
    TransactionHeaders.paymentStatus,
];

export function isTransaction(transaction: Transaction | VendorTransaction): transaction is Transaction {
    return (<Transaction>transaction).pendingTransactionRequestId !== undefined;
}
