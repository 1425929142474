import { SerializedBalanceEntry } from '../vendors/model';
import { ReadonlyCard } from './Cards';
import { currencyFormatter } from '../utils/utils';
import * as React from 'react';
import { PredefinedCurrency } from '../app/appConfig';
import { ExpirationTokenType } from '../transactions/transactions';

interface Props {
    balanceBefore: SerializedBalanceEntry[];
    action: string;
    categories: Array<string>;
    currency: PredefinedCurrency;
    expirationTokenType?: ExpirationTokenType;
}

const StatusInfoBalance = ({ balanceBefore, action, categories, currency, expirationTokenType }: Props) => {
    const tokenType = expirationTokenType.slice(0, 1).toUpperCase() + expirationTokenType.slice(1);
    const components = categories.map((category, index) => {
        const balanceBeforeByCategory = balanceBefore && balanceBefore.find((balance) => balance.category === category);
        const value = balanceBeforeByCategory ? currencyFormatter(currency).format(balanceBeforeByCategory.value) : '-';
        let image;
        switch (category) {
            case 'FOOD':
                image = 'thematic-food-security-cluster-pos';
                break;
            case 'WASH':
                image = 'thematic-food-for-assets-pos';
                break;
            case 'CASH':
                image = 'thematic-cash-pos';
                break;
            default:
                image = 'thematic-food-pos';
                break;
        }
        return (
            <ReadonlyCard
                image={image}
                key={index}
                title={`${tokenType} ${category} tokens before last ${action}`}
                value={value}
            />
        );
    });

    const componentsWithSpacing = [];
    components.forEach((card, index) => {
        if (index !== 0) {
            componentsWithSpacing.push(<div key={'spacing-' + index} style={{ width: 20 }} />);
        }
        componentsWithSpacing.push(card);
    });
    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>{componentsWithSpacing}</div>;
};

export default StatusInfoBalance;
