import * as React from 'react';
import { PartnerForm } from './PartnerForm';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { HooksActionCreators } from './partnersActions';
import { DatePicker } from '../utils/DatePicker';
import * as moment from 'moment';
const sixMonthsAhead = new Date(moment().add(6, 'month').format());
const initialValue = moment(sixMonthsAhead).format('YYYY-MM-DD');
const fields = [
    { name: 'firstName', label: 'First Name' },
    { name: 'lastName', label: 'Last Name' },
    { name: 'phoneNumber', label: 'Phone Number' },
    { name: 'email', label: 'Email', type: 'email' },
    {
        name: 'expirationDate',
        label: 'Valid Until',
        Component(props) {
            return <DatePicker {...props} maxDate={sixMonthsAhead} />;
        },
        initialValue,
    },
];

export function PartnerUserAddPage({ params: { partnerId } }) {
    const dispatch = useDispatch();
    const parkPartnerUser = useMemo(() => HooksActionCreators.createPartnerUser(dispatch), []);
    const handleSubmit = useCallback(
        (formData) => {
            parkPartnerUser({ ...formData, partnerId });
        },
        [partnerId]
    );
    return (
        <>
            <h6>Create new partner user</h6>
            <PartnerForm fields={fields} onSubmit={handleSubmit} />
        </>
    );
}
