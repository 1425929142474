import { useEffect, useState } from 'react';
import { Filter, FilterTypes } from '../../utils/FilterTypes';
import {
    updateFiltersWithBranchCodes,
    updateFiltersWithCategories,
    updateFiltersWithNickNames,
} from '../../utils/createEntityMultiSelect';
import { alignDateToUtcMoment } from '../../utils/DateFilter';
import * as moment from 'moment';
import { useDispatch } from 'react-redux';
import { errorAction } from '../../utils/notifications';
import * as React from 'react';
import { SortOptions } from '../../utils/hooks/useSort';
import { defaultVendorSortingOrder } from '../../vendors/vendors';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

export const useMobilizationStatistics = (
    defaultFilters,
    fetchToday,
    fetchRange,
    fetchSyncDate,
    vendors,
    categories,
    branchCodes
) => {
    const [filters, setFilters] = useState<Filter[]>(defaultFilters);
    const [todayData, setTodayData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [syncDate, setSyncDate] = useState('');

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const errorWhileDataLoading = () => {
        dispatch(errorAction('Failed while loading beneficiaries statistics.'));
    };

    useEffect(() => {
        const newFilters = updateFiltersWithNickNames(vendors, filters, 'vendorId');
        setFilters(newFilters);
    }, [vendors]);

    useEffect(() => {
        const newFilters = updateFiltersWithCategories(categories, filters, 'category');
        setFilters(newFilters);
    }, [categories]);

    useEffect(() => {
        const newFilters = updateFiltersWithBranchCodes(branchCodes, filters, 'branch');
        setFilters(newFilters);
    }, [branchCodes]);

    useEffect(() => {
        setLoading(true);
        dispatch(showLoading());

        const fetchSyncDateRequest = fetchSyncDate()
            .then((res) => setSyncDate(moment.utc(res).format('DD-MMM-YYYY HH:mm:ss z')))
            .catch(errorWhileDataLoading);

        const fetchTodayRequest = fetchToday({}).then(setTodayData).catch(errorWhileDataLoading);

        const fetchRangeRequest = fetchRange(filtersToReqBody(defaultFilters))
            .then(setFilteredData)
            .catch(errorWhileDataLoading);

        Promise.all([fetchSyncDateRequest, fetchRangeRequest, fetchTodayRequest]).finally(() => {
            setLoading(false);
            dispatch(hideLoading());
        });
    }, []);

    const refreshTodayResults = (allFilters: Filter[]) => () => {
        if (loading) {
            return;
        }

        setLoading(true);
        dispatch(showLoading());

        const todayDataBody = filtersToReqBody(allFilters, true);
        fetchToday(todayDataBody)
            .then(setTodayData)
            .catch(errorWhileDataLoading)
            .finally(() => {
                setLoading(false);
                dispatch(hideLoading());
            });
    };

    const applyFilters = (allFilters: Filter[]) => {
        if (loading) {
            return;
        }

        setLoading(true);
        dispatch(showLoading());

        const reqBody = filtersToReqBody(allFilters);
        const fetchRangeRequest = fetchRange(reqBody).then(setFilteredData).catch(errorWhileDataLoading);

        const todayDataBody = filtersToReqBody(allFilters, true);
        const fetchTodayRequest = fetchToday(todayDataBody).then(setTodayData).catch(errorWhileDataLoading);

        Promise.all([fetchRangeRequest, fetchTodayRequest]).finally(() => {
            setLoading(false);
            dispatch(hideLoading());
        });
    };

    return {
        filters,
        applyFilters,
        refreshTodayResults,
        todayData,
        filteredData,
        syncDate,
    };
};

export const filtersToReqBody = (filters: Filter[], forToday = false) => {
    const reqBody = {};

    filters
        .filter((filter) => filter.isSelected)
        .forEach((filter) => {
            if (filter.type === FilterTypes.multiselect) {
                if (!filter.value) {
                    reqBody[filter.name] = [];
                } else {
                    reqBody[filter.name] = filter.value.map((singleValue) => singleValue.value);
                }
            } else if (filter.type === FilterTypes.date && !forToday) {
                if (filter.dateRange.startDate) {
                    reqBody[`${filter.name}:gte`] = alignDateToUtcMoment(filter.dateRange.startDate);
                }
                if (filter.dateRange.startDate) {
                    reqBody[`${filter.name}:lte`] = alignDateToUtcMoment(filter.dateRange.endDate);
                }
            } else {
                reqBody[filter.name] = filter.value;
            }
        });

    if (forToday) {
        delete reqBody['createdAt'];
    }

    return reqBody;
};

export const mapRowToMergedData = (filteredData, todayDataLength) => (todayRow) => {
    let row = {
        uniqueAverage: 0,
        uniqueSum: 0,
        allAverage: 0,
        allSum: 0,
    };
    if (filteredData.length === todayDataLength) {
        const {
            beneficiariesAvg,
            beneficiariesSum,
            uniqueBeneficiariesAvg,
            uniqueBeneficiariesSum,
        } = filteredData.find((x) => x.time === todayRow.time);
        row = {
            uniqueAverage: uniqueBeneficiariesAvg,
            uniqueSum: uniqueBeneficiariesSum,
            allAverage: beneficiariesAvg,
            allSum: beneficiariesSum,
        };
    }
    return {
        ...row,
        time: todayRow.time,
        uniqueToday: todayRow.uniqueBeneficiariesSum,
        allToday: todayRow.beneficiariesSum,
    };
};

export const getDataByIntAsHour = (hour: number, data: { time: string }[]) => {
    const valueAsHour = moment(hour, 'h').format('HH:mm:ss');
    return { ...data.find((x) => x.time === valueAsHour) };
};

export const getTimeRangeString = (start: string, timeRangeInHours = 1) => {
    const startTime = moment(start, 'HH:mm:ss');
    const endTime = startTime.clone().add(timeRangeInHours, 'hour');
    return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
};

export const mapRangeFromConfigToRange = (mergedData) => (range) => {
    if (Array.isArray(range)) {
        const data = range.reduce((acc, curr) => {
            const currentDataObject = getDataByIntAsHour(curr, mergedData);
            if (!acc) {
                return currentDataObject;
            }

            Object.keys(acc)
                .filter((key) => key !== 'time')
                .forEach((accKey) => {
                    const currValue = currentDataObject[accKey];
                    acc[accKey] = Number(acc[accKey]) + Number(currValue);
                });

            return acc;
        }, null);

        const timeRangeString = getTimeRangeString(data.time, range.length).split(' - ');
        timeRangeString[1] = timeRangeString[1].replace(/00:00/, '24:00');

        return { ...data, time: timeRangeString.join(' - ') };
    } else {
        const data = getDataByIntAsHour(range, mergedData);
        return { ...data, time: getTimeRangeString(data.time) };
    }
};

export const roundKey = (key) => (el) => {
    if (!el[key]) {
        return el;
    }

    return { ...el, [key]: Math.round(el[key]) };
};

export const getReducedSumsFromData = (data, initialData) => {
    const initialState = { ...initialData };
    const reducedData = data.reduce((acc, curr) => {
        Object.keys(acc).forEach((accKey) => {
            const currValue = curr[accKey] ? curr[accKey] : 0;
            acc[accKey] = Number(acc[accKey]) + Number(currValue);
        });

        return acc;
    }, initialState);

    reducedData.uniqueAverage = Math.round(reducedData.uniqueAverage);
    reducedData.allAverage = Math.round(reducedData.allAverage);

    return reducedData;
};

// export const formatNumberCell = props => <div> {isNaN(props.value) ? 0 : Number(props.value).toLocaleString()} </div>
export const formatNumberCell = (value) => <div> {isNaN(value) ? 0 : Number(value).toLocaleString()} </div>;
export const formatPropsToNumberCell = (props) => formatNumberCell(props.value);
const tableHeader = function BeneficiariesTransactionStatisticsTableHeader(header: string) {
    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            {header}
        </div>
    );
};

tableHeader.displayName = '';

export const getColumns = (tz: string) => {
    return [
        {
            accessor: 'time_header',
            Header: '',
            columns: [
                {
                    Header: 'Time',
                    accessor: 'time',
                },
            ],
        },
        {
            accessor: 'unique_beneficiaries_header',
            Header: tableHeader('Unique Beneficiary Transactions'),
            columns: [
                {
                    Header: moment().tz(tz).format('DD-MMM-YYYY'),
                    accessor: 'uniqueToday',
                    Cell: formatPropsToNumberCell,
                },
                {
                    Header: 'Period Average',
                    accessor: 'uniqueAverage',
                    Cell: formatPropsToNumberCell,
                },
                {
                    Header: 'Period Sum',
                    accessor: 'uniqueSum',
                    Cell: formatPropsToNumberCell,
                },
            ],
        },
        {
            accessor: 'all_beneficiaries_header',
            Header: tableHeader('All Beneficiary Transactions'),
            columns: [
                {
                    Header: moment().tz(tz).format('DD-MMM-YYYY'),
                    accessor: 'allToday',
                    Cell: formatPropsToNumberCell,
                },
                {
                    Header: 'Period Average',
                    accessor: 'allAverage',
                    Cell: formatPropsToNumberCell,
                },
                {
                    Header: 'Period Sum',
                    accessor: 'allSum',
                    Cell: formatPropsToNumberCell,
                },
            ],
        },
    ];
};

export function useOptionsForSelectFields(
    { loadVendors, loadAppConfig, loadBranchCodes },
    onError = (v) => v
): { branchCodes: any[]; vendors: any[]; categories: any } {
    const [vendors, setVendors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [branchCodes, setBranchCodes] = useState([]);
    useEffect(() => {
        Promise.all([
            loadVendors(defaultVendorSortingOrder).then((vendors) => {
                const vendorNickNames = vendors.filter((vendor) => !!vendor.authorizedAt);
                setVendors(vendorNickNames);
            }),
            loadAppConfig().then((config) => {
                const categories = config.entitlementsConfig.categories;
                setCategories(categories);
            }),
            loadBranchCodes().then((branchCodes) => {
                setBranchCodes(branchCodes);
            }),
        ]).catch(onError);
    }, []);
    return { vendors, branchCodes, categories };
}
