import * as React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { last } from 'lodash';

import State from '../app/store/state';
import {
    ActionCreators,
    ExpirationTokenTypes,
    Transaction,
    TransactionChain,
    TransactionStatuses,
    TransactionType,
    TransactionTypes,
    TransactionVoidType,
} from './transactions';
import { currencyFormatter, displayTime } from '../utils/utils';
import { hasFeatureAccess } from '../login/auth';
import TransactionAdjustment from './adjustments/TransactionAdjustment';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import TransactionDetails from './TransactionDetails';
import { HooksActionCreators } from './transactions';
import { ManagerPermission } from '../permission-profiles/permission';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';
import { withNavigate } from '@wfp-common/hooks/withNavigate';

interface OwnProps {
    params: { transactionId: string };
}

interface PropsFromState {
    chain?: TransactionChain;
    canReadPayments: boolean;
    currency: EntitlementCurrencyConfig;
}

interface PropsFromDispatch {
    loadTransaction(transactionId: string): void;
}

type Props = OwnProps & PropsFromState & PropsFromDispatch & { navigate: NavigateHook };

const InterestingTransitions = {
    [TransactionStatuses.outstanding]: 'Verified',
    [TransactionStatuses.toReverse]: 'Flagged as To Reverse',
    [TransactionStatuses.verificationConflict]: 'Flagged as Verification Conflict',
    // [TransactionStatuses.adjusted]: 'Adjusted',
};

const ExtendedInterestingTransitions = {
    ...InterestingTransitions,
    [TransactionStatuses.adjustment]: 'Verified',
};

export class TransactionChainPageRaw extends React.Component<Props, null> {
    componentDidMount() {
        this.props.loadTransaction(this.props.params.transactionId);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.params.transactionId !== nextProps.params.transactionId) {
            this.props.loadTransaction(nextProps.params.transactionId);
        }
    }

    private tableHeader() {
        return (
            <thead>
                <tr>
                    <th>Agency</th>
                    <th>TX #</th>
                    <th>Time</th>
                    <th>Sender</th>
                    <th>Receiver</th>
                    <th>Type</th>
                    <th>Category</th>
                    <th>Amount</th>
                    <th>TX Status</th>
                    <th>Payment Status</th>
                </tr>
            </thead>
        );
    }

    private createRedirectToEntity(transaction, entityId, email?: string) {
        if (email) {
            return <a onClick={() => this.props.navigate('/admin/' + entityId)}>{email}</a>;
        }
        if (transaction.vendorNickName && transaction.vendorId === entityId) {
            return (
                <a onClick={() => this.props.navigate('/vendors/' + transaction.vendorId)}>
                    {transaction.vendorNickName}
                </a>
            );
        } else if (entityId !== 'WFP') {
            return (
                <a onClick={() => this.props.navigate('/beneficiaries/beneficiary', { beneficiaryId: entityId })}>
                    {entityId}
                </a>
            );
        }
        return entityId;
    }

    private renderBreadcrumb() {
        return (
            <nav className="wfp-breadcrumbs">
                <ol className="breadcrumbs--wrapper">
                    <li className="breadcrumbs--item">
                        <Link className="breadcrumbs--link" to="/home">
                            <span>Home</span>
                        </Link>
                    </li>
                    <li className="breadcrumbs--item">
                        <Link className="breadcrumbs--link" to="/transactions">
                            <span>Transactions</span>
                        </Link>
                    </li>
                    <li className="breadcrumbs--item">
                        <span className="breadcrumbs--last">Transaction Details</span>
                    </li>
                </ol>
            </nav>
        );
    }

    private shouldRenderTransitionIfCorrectVoidTransaction(transaction: Transaction) {
        return (
            transaction.voidType === TransactionVoidType.voiding &&
            transaction.status === TransactionStatuses.adjustment
        );
    }

    render() {
        const { chain, canReadPayments } = this.props;
        const transactionId = this.props.params.transactionId;
        const originalTx = chain && chain.items && chain.items[0];
        const adjustableTxs =
            chain &&
            chain.items &&
            chain.items.filter(
                (x) =>
                    TransactionTypes.contributingToVendorPayment.includes(x.type) &&
                    TransactionStatuses.adjustableStatuses.includes(x.status)
            );
        const isAdjusted = adjustableTxs && !!adjustableTxs.length;
        return (
            <main>
                {this.renderBreadcrumb()}

                {chain && chain.items && !!chain.items.length && (
                    <div>
                        <h3>Transaction #{transactionId} Overview</h3>

                        <table className="wfp-table--striped mt4">
                            {this.tableHeader()}
                            {chain.items &&
                                chain.items.map((transaction) => {
                                    const isHighlighted = transaction.id.toString() === transactionId;
                                    const interestingTransitionsTypes = this.shouldRenderTransitionIfCorrectVoidTransaction(
                                        transaction
                                    )
                                        ? ExtendedInterestingTransitions
                                        : InterestingTransitions;
                                    const interestingTransitions = (
                                        transaction.statusTransitions || []
                                    ).filter((transition) =>
                                        Object.keys(interestingTransitionsTypes).includes(transition.to)
                                    );
                                    const isIncludedInPayment = !!transaction.paymentRequest;
                                    return (
                                        <tbody key={transaction.id}>
                                            <tr
                                                className={isHighlighted ? 'highlighted' : ''}
                                                key={`${transaction.id}-row`}
                                            >
                                                <td>{transaction.agency}</td>
                                                <td>{transaction.id}</td>
                                                <td>{displayTime(transaction.createdAt)}</td>
                                                <td>
                                                    {this.createRedirectToEntity(transaction, transaction.senderId)}
                                                </td>
                                                <td>
                                                    {this.createRedirectToEntity(transaction, transaction.receiverId)}
                                                </td>
                                                <td>{TransactionTypes.displayName(transaction.type)}</td>
                                                <td>{transaction.category}</td>
                                                <td>
                                                    {currencyFormatter(this.props.currency).format(
                                                        transaction.amount.toNumber()
                                                    )}
                                                </td>
                                                <td>{transaction.displayNameForTransactionStatus}</td>
                                                <td data-testid={'transactionId'}>
                                                    {transaction.displayNameForFinancialStatus}
                                                    {canReadPayments && isIncludedInPayment && (
                                                        <div>
                                                            in Payment File{' '}
                                                            <Link
                                                                onClick={(e) => e.stopPropagation()}
                                                                to={'/payments/' + transaction.paymentRequest.id}
                                                            >
                                                                #{transaction.paymentRequest.id}
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {!canReadPayments && isIncludedInPayment && (
                                                        <div>in Payment File #{transaction.paymentRequest.id}</div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr key={`${transaction.id}-statuses`}>
                                                <td colSpan={9}>
                                                    <p
                                                        style={{
                                                            color: '#888',
                                                        }}
                                                    />
                                                    {transaction.alternativeCollectorId && (
                                                        <p
                                                            style={{
                                                                color: '#888',
                                                            }}
                                                        >
                                                            <small>
                                                                <strong>AC ID:</strong>{' '}
                                                                {transaction.alternativeCollectorId}
                                                            </small>
                                                        </p>
                                                    )}

                                                    {transaction.displayNameForAdjustmentType && (
                                                        <div className="ph5">
                                                            {' '}
                                                            Adjustment created at {displayTime(
                                                                transaction.createdAt
                                                            )}{' '}
                                                            by {transaction.displayNameForAdjustmentType}
                                                        </div>
                                                    )}
                                                    {interestingTransitions.map((transition) => (
                                                        <div
                                                            className="ph5"
                                                            key={`${transaction.id}-statuses-${transition.to}-${transition.at}`}
                                                        >
                                                            {ExtendedInterestingTransitions[transition.to]} at{' '}
                                                            {displayTime(transition.at)}
                                                            <span>
                                                                {' '}
                                                                by{' '}
                                                                {transition.byManager && transition.byManager.email
                                                                    ? this.createRedirectToEntity(
                                                                          null,
                                                                          transition.byManager.id,
                                                                          transition.byManager.email
                                                                      )
                                                                    : 'the system'}
                                                            </span>
                                                        </div>
                                                    ))}
                                                    {transaction.type === TransactionType.unload && (
                                                        <div className="ph5">
                                                            <span>
                                                                Unloaded{' '}
                                                                {ExpirationTokenTypes.displayName(
                                                                    transaction.expirationTokenType
                                                                )}{' '}
                                                                tokens
                                                            </span>
                                                        </div>
                                                    )}
                                                    {transaction.createdByManager && isAdjusted && (
                                                        <div className="ph5">
                                                            {' '}
                                                            Parked by{' '}
                                                            {this.createRedirectToEntity(
                                                                null,
                                                                transaction.createdByManager.id,
                                                                transaction.createdByManager.email
                                                            )}{' '}
                                                        </div>
                                                    )}
                                                    {transaction.authorizedByManager && isAdjusted && (
                                                        <div className="ph5">
                                                            {' '}
                                                            Posted by{' '}
                                                            {this.createRedirectToEntity(
                                                                null,
                                                                transaction.authorizedByManager.id,
                                                                transaction.authorizedByManager.email
                                                            )}{' '}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    );
                                })}
                            {!!adjustableTxs.length && (
                                <tfoot>
                                    <tr>
                                        <th colSpan={5}>Original Transaction Amount</th>
                                        <th>
                                            {currencyFormatter(this.props.currency).format(
                                                originalTx.amount.toNumber()
                                            )}
                                        </th>
                                        <th colSpan={2} />
                                    </tr>
                                    <tr>
                                        <th colSpan={5}>Current Transaction Amount</th>
                                        <th>
                                            {currencyFormatter(this.props.currency).format(chain.amount.toNumber())}
                                        </th>
                                        <th />
                                        <td>
                                            <TransactionAdjustment transaction={last(adjustableTxs)} />
                                        </td>
                                    </tr>
                                </tfoot>
                            )}
                        </table>
                    </div>
                )}

                <TransactionDetails
                    actionCreator={HooksActionCreators.loadTransactionDetails}
                    transactionId={this.props.params.transactionId}
                />
            </main>
        );
    }
}

function mapStateToProps(state: State): PropsFromState {
    return {
        chain: state.transactionChain,
        canReadPayments: hasFeatureAccess(state.auth, ManagerPermission.paymentsRead),
        currency: state.appConfig.entitlementCurrencyConfig,
    };
}

function mapDispatchToProps(dispatch): PropsFromDispatch {
    return {
        loadTransaction: bindActionCreators(ActionCreators.loadTransactionChain, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigate(TransactionChainPageRaw, 'TransactionChainPageRaw'));
