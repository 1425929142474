import { Input } from '../utils/inputs';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

interface PasswordCheckerProps {
    onClose: () => void;
    onConfirm: (data: any) => void;
    requestWithPasswordProtection: (userPassword: string) => any;
}

export const PasswordChecker: FC<PasswordCheckerProps> = (props: PasswordCheckerProps) => {
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (password.length) {
            setPasswordError(false);
        }

        setIsFormValid(!!password.length);
    }, [password]);

    const handleSubmit = async () => {
        setIsLoading(true);

        try {
            const res = await props.requestWithPasswordProtection(password);
            props.onConfirm(res);
        } catch (err) {
            setPasswordError(true);
            setIsLoading(false);
        }
    };

    return (
        <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>Password required</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span>Due to security reason this action requires to provide password</span>

                <div className="form-group pt-3">
                    <label htmlFor="password">Password</label>
                    <Input
                        className="form-control"
                        isFocused={true}
                        name="password"
                        onChange={(event) => setPassword(event.target.value)}
                        type="password"
                        value={password}
                    />
                    {passwordError && (
                        <span className="error text-danger">Wrong password or exceeded attempts limit</span>
                    )}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button className="wfp-btn" onClick={props.onClose} style={{ marginRight: 10 }} type="button">
                    Close
                </button>
                <button
                    className="wfp-btn--primary"
                    disabled={!isFormValid || isLoading}
                    onClick={handleSubmit}
                    type="submit"
                >
                    {isLoading ? <span>Submitting</span> : <span>Submit</span>}
                </button>
            </Modal.Footer>
        </Modal.Dialog>
    );
};
