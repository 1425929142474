import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';

import { ActionCreators } from './beneficiaries';
import { Entitlement } from './model';
import PagingComponent, { PagingResponse } from '../utils/paging';
import { currencyFormatter, displayMomentAsDay } from '../utils/utils';
import { InformationDialog } from '../utils/Dialogs';
import { PredefinedCurrency } from '../app/appConfig';

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

interface Props {
    beneficiaryId: string;
    currency: PredefinedCurrency;
    timezone: string;
    loadEntitlements: (beneficiaryId: string, pageNumber, pageSize) => Promise<Entitlement>;
}

interface State {
    entitlements: any;
    showDialog: boolean;
    tHashNo?: string;
}
class EntitlementsView extends React.Component<Props, State> {
    defaultPageSize = 25;

    constructor(props: Props) {
        super(props);
        this.state = {
            entitlements: { items: [], paging: null },
            showDialog: false,
            tHashNo: null,
        };
    }

    async componentDidMount() {
        const entitlements = await this.props.loadEntitlements(this.props.beneficiaryId, 1, this.defaultPageSize);
        this.setState({ entitlements });
    }

    private changePageRequested(newPage: number) {
        this.props
            .loadEntitlements(this.props.beneficiaryId, newPage, this.defaultPageSize)
            .then((entitlements) => {
                this.setState({ entitlements });
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    renderDialog() {
        return (
            <InformationDialog
                message={this.state.tHashNo}
                onClose={() => this.setState({ showDialog: false, tHashNo: null })}
                title="Copied"
            />
        );
    }

    render() {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const copyIcon = require('../styles/copy-icon.png');
        return (
            <div>
                {this.state.showDialog && this.renderDialog()}
                <h6>Entitlements</h6>
                <table className="wfp-table--striped mt4 table-hover">
                    <thead>
                        <tr>
                            <th>Agency</th>
                            <th>Entitlement category</th>
                            <th>Amount</th>
                            <th>Start</th>
                            <th>End</th>
                        </tr>
                    </thead>
                    {this.state.entitlements && this.state.entitlements.items && (
                        <tbody>
                            {this.state.entitlements.items.map((entitlement) => (
                                <tr key={entitlement.id}>
                                    <td>{entitlement.agency}</td>
                                    <td>{entitlement.category}</td>
                                    <td>{currencyFormatter(this.props.currency).format(entitlement.amount)}</td>
                                    <td>{displayMomentAsDay(moment(entitlement.validFrom).tz(this.props.timezone))}</td>
                                    <td>{displayMomentAsDay(moment(entitlement.expiry).tz(this.props.timezone))}</td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
                <PagingComponent
                    onPageChanged={this.changePageRequested.bind(this)}
                    paging={this.state.entitlements.paging}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadEntitlements: bindActionCreators(ActionCreators.loadEntitlements, dispatch),
    };
}

function mapStateToProps(state, ownProps: OwnProps) {
    return {
        beneficiaryId: ownProps.location.state.beneficiaryId,
        currency: state.appConfig.entitlementCurrencyConfig,
        timezone: state.appConfig.timeZone,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(EntitlementsView);
