import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { displayTime } from '../utils/utils';
import {
    ActionCreators,
    EntityBalanceResponse,
    EntityBalanceType,
    mapTypeSumBalanceEntryToAdjustmentsExpenditures,
} from '../entityBalance/entityBalance';
import { SerializedBalanceEntry } from '../vendors/model';
import { isNullOrUndefined } from 'util';
import { TypedBalance } from './typedBalance';
import { BalanceEntriesComponent } from './balanceEntries';
import { EntitlementCategoriesConfig, PredefinedCurrency } from '../app/appConfig';
import { getClustersInfo } from '../apiClient';

const flexRow: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

interface Props {
    beneficiaryId: string;
    lastEntityBalance: EntityBalanceResponse;
    lastEntityBalanceComparison: EntityBalanceResponse;
    categories: EntitlementCategoriesConfig;
    currency: PredefinedCurrency;
    loadLastEntityBalance: (entityId: string, entityType: EntityBalanceType) => void;
    loadEntityBalanceComparison: (entityId: string, entityType: EntityBalanceType) => void;
    timezone: string;
}

class BeneficiariesAlerts extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.loadEntityBalanceComparison(this.props.beneficiaryId, EntityBalanceType.Beneficiary);
        this.props.loadLastEntityBalance(this.props.beneficiaryId, EntityBalanceType.Beneficiary);
    }

    sectionHeader(title: string) {
        return <h6>{title}</h6>;
    }

    isBalanceComparisonNotAvailable(): boolean {
        const { lastEntityBalanceComparison, beneficiaryId } = this.props;

        return (
            isNullOrUndefined(lastEntityBalanceComparison) ||
            isNullOrUndefined(lastEntityBalanceComparison.databaseBalances) ||
            isNullOrUndefined(lastEntityBalanceComparison.blockchainBalance) ||
            lastEntityBalanceComparison.entityId !== beneficiaryId
        );
    }

    isLastEntityBalanceNotAvailable(): boolean {
        const { lastEntityBalance, beneficiaryId } = this.props;
        return (
            isNullOrUndefined(lastEntityBalance) ||
            isNullOrUndefined(lastEntityBalance.databaseBalances) ||
            lastEntityBalance.entityId !== beneficiaryId
        );
    }

    renderBalanceAlertsComponent() {
        return (
            <div className="beneficiary-alert-component">
                {this.sectionHeader('Beneficiary balance alerts')}
                {this.isBalanceComparisonNotAvailable() ? (
                    <div>
                        <p className={'alert alert-warning'}>No balance alerts entries for beneficiary available.</p>
                    </div>
                ) : (
                    this.renderBalanceAlert()
                )}
            </div>
        );
    }

    renderBalanceAlert() {
        const { lastEntityBalanceComparison } = this.props;

        if (lastEntityBalanceComparison.doBalancesMatch) {
            return <p className={'alert alert-success'}>Blockchain and backend balances match.</p>;
        } else {
            return (
                <div>
                    <p className={'alert alert-danger'}>Beneficiary blockchain and database balances do not match.</p>
                    {this.sectionHeader('Compared blockchain balance')}
                    <div style={flexRow}>{this.renderBalance(lastEntityBalanceComparison.blockchainBalance)}</div>
                    {this.sectionHeader('Compared database balance')}
                    <div style={flexRow}>
                        {this.renderBalance(
                            lastEntityBalanceComparison.databaseBalances.map((dbBalance) => {
                                return {
                                    category: dbBalance.category as string,
                                    currency: dbBalance.currency as string,
                                    value: dbBalance.dbBalance,
                                };
                            })
                        )}
                    </div>
                    <small>
                        Above balances were compared at {displayTime(lastEntityBalanceComparison.lastTransactionAt)}
                    </small>
                </div>
            );
        }
    }

    renderDebitAlertsComponent() {
        return (
            <div className="beneficiary-alert-component">
                {this.sectionHeader('Beneficiary debit alerts')}
                {this.isLastEntityBalanceNotAvailable() ? (
                    <p className={'alert alert-warning'}>No balance alerts entries for beneficiary available.</p>
                ) : (
                    <div>
                        {this.props.categories.categories.map((category) => {
                            return (
                                <div className="beneficiary-alert-debits-component" key={category}>
                                    {this.renderDebitAlert(category)}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }

    renderDebitAlert(categoryToBeChecked: string) {
        const entityBalanceOfGivenCategory = this.props.lastEntityBalance.databaseBalances.find(
            (total) => total.category === categoryToBeChecked
        );
        const groupedSums = mapTypeSumBalanceEntryToAdjustmentsExpenditures(entityBalanceOfGivenCategory);
        if (groupedSums.expenditures.toNumber() > groupedSums.entitlements.toNumber()) {
            return (
                <div>
                    {this.sectionHeader(`Beneficiary totals of type ${categoryToBeChecked}`)}
                    <div style={flexRow}>
                        <TypedBalance
                            beneficiaryId={this.props.beneficiaryId}
                            currency={this.props.currency}
                            desiredCategory={categoryToBeChecked}
                            entityBalance={this.props.lastEntityBalance}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <p
                    className={'alert alert-success'}
                >{`No spending alerts for entitlement ${categoryToBeChecked} - beneficiary has more entitlements than expenditures`}</p>
            );
        }
    }

    renderBalance(balanceToMap: Array<SerializedBalanceEntry>) {
        return (
            <BalanceEntriesComponent
                balanceToMap={balanceToMap}
                beneficiaryId={this.props.beneficiaryId}
                currency={this.props.currency}
                getClusterInfoFunction={getClustersInfo}
                timezone={this.props.timezone}
            />
        );
    }

    render() {
        return (
            <div>
                {this.renderBalanceAlertsComponent()}
                {/*{this.renderDebitAlertsComponent()}*/}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any, ownProps: OwnProps) {
    return {
        loadEntityBalanceComparison: bindActionCreators(ActionCreators.loadEntityBalanceComparison, dispatch),
        loadLastEntityBalance: bindActionCreators(ActionCreators.loadLastEntityBalance, dispatch),
    };
}

function mapStateToProps(state, ownProps: OwnProps) {
    return {
        beneficiaryId: ownProps.location.state.beneficiaryId,
        lastEntityBalance: state.entityBalance.lastEntityBalance,
        lastEntityBalanceComparison: state.entityBalance.lastEntityBalanceComparison,
        categories: state.appConfig.entitlementsConfig,
        currency: state.appConfig.entitlementCurrencyConfig,
        timezone: state.appConfig.timeZone,
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(BeneficiariesAlerts);
