import * as moment from 'moment-timezone';
import { DateSelectFilter } from '../src/utils/FilterTypes';
import { cloneDeep } from 'lodash';

export function transformDateFilters(timezone) {
    return function transform(filter) {
        if (!(filter instanceof DateSelectFilter) || !filter.dateRange) return filter;

        const _filter = cloneDeep(filter);
        _filter.dateRange.startDate = changeTimezone(_filter.dateRange.startDate, timezone);
        _filter.dateRange.endDate = changeTimezone(_filter.dateRange.endDate, timezone);
        return _filter;
    };
}

export function changeTimezone(date, tz) {
    const timezone = moment.tz(tz).format('UTCZ');
    const a = new Date(includeDST(date, tz)).toString().split(' ').slice(0, 6);
    a[5] = timezone;
    return new Date(a.join(' '));
}

export function includeDST(date, tz) {
    const currentDate = moment().tz(tz);
    const checkingDate = moment(date).tz(tz);

    const isCurrentDateDST = currentDate.isDST();
    const isCheckingDateDST = checkingDate.isDST();

    const currentDateOffset = new Date(currentDate.toString()).getTimezoneOffset();
    const checkingDateOffset = new Date(checkingDate.toString()).getTimezoneOffset();

    const differenceOffset = Math.abs(Math.abs(currentDateOffset) - Math.abs(checkingDateOffset));

    if (isCurrentDateDST && !isCheckingDateDST) {
        return new Date(moment(date).add(differenceOffset, 'm').toISOString());
    } else if (!isCurrentDateDST && isCheckingDateDST) {
        return new Date(moment(date).subtract(differenceOffset, 'm').toISOString());
    }
    return new Date(moment(date).toISOString());
}
