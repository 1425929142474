import * as React from 'react';

import { EntitlementCurrencyConfig } from '../app/appConfig';
import { currencyFormatter } from '../utils/utils';
import { SortableTableHead } from '../utils/table/table-head/sortable-table-head';
import { useVendorsList } from './utils/useVendorsList';
import { VendorNextAction } from './vendor-next-action';
import { DIRECTION_OPTIONS, SORT_OPTIONS } from '../utils/hooks/useSort';
import { VendorFilters } from './VendorsFilters';
import { useState } from 'react';
import { defaultVendorSortingOrder } from './vendors';

interface Props {
    managerId: string;
    authorize: (Vendor) => void;
    postEnabled: boolean;
    cancelPark: (id: string) => void;
    currency: EntitlementCurrencyConfig;
}

export default function VendorsList({ managerId, currency, authorize, cancelPark, postEnabled }: Props) {
    const {
        showAnnotation,
        vendorCreditsMessage,
        thisManagerParked,
        shouldDisplayAnnotation,
        _onVendorChosen,
        vendorMissingMessage,
        trClassName,
        handleSort,
        vendors,
        queryOptions,
    } = useVendorsList(managerId);

    return (
        <div>
            <VendorFilters sortOptions={queryOptions} />
            <table className="wfp-table mt4 table-hover">
                <SortableTableHead changeSortOptions={handleSort}>
                    <th>WFP Vendor ID</th>
                    <th>Nickname</th>
                    <SortableTableHead.DisabledColumn title={vendorCreditsMessage}>
                        Credits Earned
                    </SortableTableHead.DisabledColumn>
                    <SortableTableHead.DisabledColumn>Status</SortableTableHead.DisabledColumn>
                </SortableTableHead>
                <tbody>
                    {vendors.map((vendor) => (
                        <tr
                            className={trClassName(vendor)}
                            key={vendor.id}
                            onClick={() => _onVendorChosen.bind(this)(vendor)}
                        >
                            <td>{vendor.sapId}</td>
                            <td>{vendor.nickName}</td>
                            <td title={vendorCreditsMessage}>
                                {vendor.entitlements && vendor.entitlements.length ? (
                                    vendor.entitlements.map((e) => (
                                        <div key={e.category}>
                                            {e.category}: {currencyFormatter(currency).format(e.value)}
                                        </div>
                                    ))
                                ) : (
                                    <span className={vendorMissingMessage(vendor).style}>
                                        {vendorMissingMessage(vendor).message}
                                    </span>
                                )}
                            </td>
                            <VendorNextAction
                                authorize={authorize}
                                cancelPark={cancelPark}
                                postEnabled={postEnabled}
                                showAnnotation={showAnnotation}
                                thisManagerParked={thisManagerParked}
                                vendor={vendor}
                            />
                        </tr>
                    ))}
                </tbody>
            </table>
            {shouldDisplayAnnotation(vendors) && (
                <div>
                    <label style={{ color: 'red' }}>*</label>
                    <label>You cannot post it, because you parked it.</label>
                </div>
            )}
        </div>
    );
}
