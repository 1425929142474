import * as React from 'react';
import { SelectOption } from '../utils/FilterTypes';
import { Input } from '../utils/inputs';
import { ChangeEvent, useCallback } from 'react';
import BreadCrumbs from '../utils/BreadCrumbs';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

export interface Product {
    id: number;
    name: string;
    status: string;
    createdByManager: string;
    createdByManagerId: string;
    authorizedByManagerId: number;
    authorizedAt: string;
    cancelledByManagerId: number;
    cancelledAt: string;
    quantityOption: string;
    limit: number;
    unit: string;
    category: string;
    createdAt: string;
    updatedAt: string;
    updatedByManager: string;
    updatedByManagerId: string;
}

export interface Price {
    id: number;
    isFixed: boolean;
    price: number;
    productId: number;
    vendorId: string;
    vendorNickname: string;
    createdAt: string;
    updatedAt: string;
    createdByManager: string;
    createdByManagerId: string;
    updatedByManager: string;
    updatedByManagerId: string;
}

export enum TabType {
    main = 'Main',
}

const tabs: { [key in TabType]: string } = {
    Main: 'general',
};

export enum StatusType {
    active = 'active',
    blocked = 'blocked',
}

export const productStatuses: { value: StatusType; label: string }[] = [
    { value: StatusType.active, label: 'Active' },
    { value: StatusType.blocked, label: 'Blocked' },
];

export enum ProductTabs {
    Prices = 'Prices',
    // List = 'List',
    Groups = 'Groups',
    MobileGroups = 'Mobile Groups',
}

export const ProductContainer: React.FC<{
    productId: string;
    product: Product;
    activeTab: TabType;
}> = function ProductContainer({ productId, product, activeTab, children }) {
    const navigate = useNavigate();
    const handleChange = useCallback((tab: string) => {
        const url = tabs[tab];
        navigate(`/products/${productId}/${url}`, {
            productId,
        });
    }, []);
    return (
        <main>
            <div className="col-sm-12">
                <BreadCrumbs path={`/products/${product.name}`} />
            </div>
            <div className={'row'}>
                <div className="col-sm-3">
                    {/*
                    <h5 style={{ marginTop: 0, marginBottom: 0 }}>{product?.name}</h5>
                    <SideMenu activeTab={activeTab} onSelectTab={handleChange} tabs={Object.keys(tabs)} />
                    */}
                </div>
                <div className="col-sm-9" style={{ marginBottom: 150 }}>
                    {children}
                </div>
            </div>
        </main>
    );
};

export const InputField = function InputField(props: {
    readonly: boolean;
    value: string;
    name: string;
    required: boolean;
    type: string;
    autoFocused?: boolean;
    maxLength?: number;
    options?: SelectOption[];
    step?: string;
    labelValue?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <div className="col-sm-8">
            {props.readonly && <label className="normal">{props.labelValue ? props.labelValue : props.value}</label>}
            {!props.readonly && (
                <Input
                    className="form-control"
                    isFocused={props.autoFocused}
                    maxLength={props.maxLength}
                    name={props.name}
                    onChange={props.onChange}
                    options={props.options}
                    required={props.required}
                    step={Number(props.step)}
                    type={props.type}
                    value={props.value}
                />
            )}
        </div>
    );
};
