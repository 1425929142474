import 'bootstrap/dist/css/bootstrap.css';
import 'wfp-ui/dist/css/bootstrap-theme.css';
import 'wfp-ui/dist/css/wfpui.css';
import 'wfp-ui/dist/assets/fonts/aleo/aleo.css';
import 'wfp-ui/dist/assets/fonts/lato/lato.css';
import './styles/daterangepicker.scss';
import './styles/main.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './app/App';

ReactDOM.render(<App />, document.getElementById('root'));
