import * as React from 'react';
import { FC } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Switch from 'rc-switch';

import State from '../app/store/state';
import { ActionCreators, SecurityProps } from './security';
import Manager from '../managers/manager';
import { ActionCreators as ManagerActionCreators } from '../managers/managers';
import { AuthState, hasFeatureAccess } from '../login/auth';
import ChangePasswordModal from './ChangePasswordModal';
import { ManagerPermission } from '../permission-profiles/permission';
import { ManagerOtpDialog } from '../managers/ManagerOtpDialog';
import { createOtpSecret, deleteOtpSecret, getOtpData } from '../apiClient';
import { withVerifier } from './WithVerifier';

interface Props {
    security: SecurityProps;
    auth: AuthState;
    error: any;
    success: boolean;
    managerId: number;

    updateOtpData: (otpData: OtpData) => void;
    clearOtpData: () => void;
    updateOtpSecret: (string) => any;
    getSecurityData: () => void;
    postOtpSecret: (otp: string, secret: string, managerId: number, oldOtp: string) => void;
    resetPasswordRequest: (manager: Manager) => void;
    removeSecretFromState: () => void;
    changePassword: (oldPass: string, newPass: string) => void;
}

export interface OtpData {
    otpSecret: string;
    otpQRDataUrl: string;
    otpEnabled: boolean;
}

class SecurityPage extends React.Component<Props, any> {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showOtpSecret: false,
            isSetOtpDialogVisible: false,
            isSetOtpDeactivatingWarningDialog: false,
            changePassword: false,
        };
    }

    componentDidMount() {
        this.props.getSecurityData();
    }

    UNSAFE_componentWillReceiveProps(newProps: Props) {
        if (newProps.success !== this.props.success) {
            this.setState({
                isSetOtpDialogVisible: false,
            });
        }
    }

    componentWillUnmount() {
        this.props.removeSecretFromState();
    }

    renderSetOtpDialog() {
        return (
            <ManagerOtpDialog
                confirmTitle="Confirm"
                error={this.props.error}
                managerId={this.props.managerId}
                onClose={() => {
                    this.props.clearOtpData();
                    this.setState({ isSetOtpDialogVisible: false });
                }}
                onSubmit={this.props.postOtpSecret.bind(this)}
                security={this.props.security}
                success={this.props.success}
                title="Generate new secret key for multi-factor authentication"
            />
        );
    }

    changePassword(oldPass, newPass) {
        this.props.changePassword(oldPass, newPass);
        this.setState({ changePassword: false });
    }

    render() {
        const canChangeOtpStatus = hasFeatureAccess(this.props.auth, ManagerPermission.changeOtpStatus);
        const { isSetOtpDialogVisible } = this.state;

        const OtpLink: FC<{ onClick: () => void }> = (props: { onClick: () => void }) => (
            <a className="cursor-pointer" onClick={props.onClick}>
                <small>Show MFA QR code</small>
            </a>
        );

        const OtpShowButton: FC = withVerifier<OtpData>(
            OtpLink,
            {
                eventName: 'onClick',
                postExecuteFunction: (otpData) => {
                    this.props.updateOtpData(otpData);
                    this.setState({ showOtpSecret: true });
                },
            },
            getOtpData
        );

        const SwitchWithVerify = withVerifier<OtpData>(
            Switch,
            {
                eventName: 'onChange',
                postExecuteFunction: (data) => {
                    if (!this.props.security.otpEnabled) {
                        this.setState({ isSetOtpDialogVisible: true, showOtpSecret: false });
                    }

                    this.props.updateOtpData(data);
                },
            },
            this.props.security.otpEnabled ? deleteOtpSecret : createOtpSecret
        );

        const ChangeOtpButton: FC<{ onClick: () => void }> = (props) => (
            <button className="wfp-btn" onClick={props.onClick}>
                Change OTP
            </button>
        );

        const ChangeOtpButtonWithVerify = withVerifier<OtpData>(
            ChangeOtpButton,
            {
                eventName: 'onClick',
                postExecuteFunction: (otpData) => {
                    this.props.updateOtpData(otpData);
                    this.setState({ isSetOtpDialogVisible: true, showOtpSecret: false });
                },
            },
            createOtpSecret
        );

        const OtpHideButton: FC = () => (
            <span>
                <a
                    className="cursor-pointer"
                    onClick={() => {
                        this.setState({ showOtpSecret: false });
                        this.props.clearOtpData();
                    }}
                >
                    <small>Hide OTP QR code</small>
                </a>
            </span>
        );

        return (
            <main>
                {isSetOtpDialogVisible && this.renderSetOtpDialog()}

                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h5>Password</h5>
                        </div>
                    </div>
                    <div>
                        <a onClick={() => this.setState({ changePassword: true })}>Change Password</a>
                    </div>
                    <div>
                        {canChangeOtpStatus && (
                            <div className="row">
                                <div className="col-md-6">
                                    <h5>Multi-factor authentication</h5>
                                </div>
                            </div>
                        )}
                        {canChangeOtpStatus && (
                            <div className="row">
                                <div className="col-sm-2">Active</div>
                                <div className="col-sm-10">
                                    <SwitchWithVerify checked={!!this.props.security.otpEnabled} />
                                </div>
                            </div>
                        )}
                        {this.state.changePassword && (
                            <ChangePasswordModal
                                onClose={() => this.setState({ changePassword: false })}
                                onConfirm={this.changePassword.bind(this)}
                            />
                        )}

                        {this.props.security.otpEnabled ? (
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {this.state.showOtpSecret && this.props.security.otpEnabled ? (
                                            <div>
                                                <img
                                                    alt="QR Code"
                                                    src={this.props.security.otpQRDataUrl}
                                                    style={{
                                                        margin: '5px 10px',
                                                    }}
                                                />
                                                Secret key: {this.props.security.otpSecret}
                                                <br />
                                                <OtpHideButton />
                                            </div>
                                        ) : (
                                            <OtpShowButton />
                                        )}
                                        <br />
                                        <br />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <ChangeOtpButtonWithVerify />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        security: state.security,
        auth: state.auth,
        error: state.security.error,
        success: state.security.success,
        managerId: state.auth.manager ? Number(state.auth.manager.id) : null,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateOtpData: bindActionCreators(ActionCreators.updateOtpData, dispatch),
        clearOtpData: bindActionCreators(ActionCreators.clearOtpData, dispatch),
        getSecurityData: bindActionCreators(ActionCreators.getSecurityData, dispatch),
        postOtpSecret: bindActionCreators(ActionCreators.postOtpSecret, dispatch),
        resetPasswordRequest: bindActionCreators(ManagerActionCreators.resetPasswordRequest, dispatch),
        removeSecretFromState: bindActionCreators(ActionCreators.removeSecretFromState, dispatch),
        changePassword: bindActionCreators(ManagerActionCreators.changePassword, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityPage as any);
