import * as React from 'react';
import Switch from 'rc-switch';
import * as moment from 'moment-timezone';

import { InputField } from './helpers';
import { Form } from '../utils/inputs';
import { useProductsPricesDetails } from './hooks/useProductsPricesDetails';
import { Link } from 'react-router';
import { hasFeatureAccess } from '../login/auth';
import { ProductPriceDeleteModal } from './ProductPriceDeleteModal';
import { ManagerPermission } from '../permission-profiles/permission';

export const ProductsPricesDetails: React.FC<{
    params: { productId: string; priceId: string };
}> = function ProductsPricesDetails({ params }) {
    const {
        product,
        price,
        vendors,
        productId,
        editing,
        toggleEditing,
        handleSubmit,
        handleChange,
        tz,
        currencyConfig,
        auth,
        toggleIsOpen,
        isOpen,
        handleDelete,
    } = useProductsPricesDetails(params);

    if (!product || !price?.vendorId || !vendors.length) return <div>loading...</div>;

    return (
        <>
            {isOpen && <ProductPriceDeleteModal onCancel={toggleIsOpen} onDelete={handleDelete} price={price} />}
            <div>
                <div className="row">
                    <h6 className="col-sm-8">Price Information</h6>
                    {hasFeatureAccess(auth, ManagerPermission.productsPark) && (
                        <div className="col-sm-4 text-right">
                            <button className="wfp-btn" onClick={toggleIsOpen} type="button">
                                Delete Product Park/Post
                            </button>
                        </div>
                    )}
                </div>
                <div className={'row'}>
                    <label className="col-sm-4 ta-right">Toggle Edit</label>
                    <div className="col-sm-8 pv1">
                        <Switch checked={editing} onClick={toggleEditing} />
                    </div>
                </div>
                <Form className="wfp-form" onSubmit={handleSubmit}>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Vendor</label>
                        <div className="col-sm-8">
                            <Link to={`/vendors/${price.vendorId}`}>{price.vendorNickname}</Link>
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Price</label>
                        {!editing ? (
                            <div className="col-sm-8">
                                <p>
                                    {currencyConfig.name} {Number(price.price).toFixed(currencyConfig.fractionDigits)}
                                </p>
                            </div>
                        ) : (
                            <InputField
                                autoFocused={true}
                                maxLength={35}
                                name="price"
                                onChange={handleChange}
                                readonly={!editing}
                                required={true}
                                type="number"
                                value={price.price.toString()}
                            />
                        )}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Is Fixed</label>
                        <div className="col-sm-8 pv1">
                            <Switch
                                checked={price.isFixed}
                                disabled={!editing}
                                name="isFixed"
                                onChange={(val, event) => {
                                    event.persist();
                                    handleChange({ target: { name: event.target.name, value: val } });
                                }}
                            />
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Created At</label>
                        <div className="col-sm-8">
                            <label className="normal">
                                {moment(price.createdAt).tz(tz).format('DD/MM/YYYY HH:mm')}
                            </label>
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Updated At</label>
                        <div className="col-sm-8">
                            <label className="normal">
                                {moment(price.updatedAt).tz(tz).format('DD/MM/YYYY HH:mm')}
                            </label>
                        </div>
                    </div>
                    {editing && (
                        <div className="wfp-form--actions text-center">
                            <div className="col-sm-8 col-sm-offset-4">
                                <button className="wfp-btn--primary" type="submit">
                                    Park & Post Changes
                                </button>
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        </>
    );
};
