import { Filter, FilterTypes, MultiSelectFilter } from '../utils/FilterTypes';

function updateFilterValueFromUrl(filter: Filter, value) {
    if (!value) {
        return filter;
    }
    switch (filter.type) {
        case FilterTypes.multiselect:
            const multiSelectFilter = filter as MultiSelectFilter;
            if (!Array.isArray(value)) {
                filter.value = [multiSelectFilter.options.find((select) => select.value === value)];
            } else {
                filter.value = value.map((label) => multiSelectFilter.options.find((select) => select.value === label));
            }
            break;
        case FilterTypes.date:
            if (value.length > 1) {
                filter.dateRange = {
                    startDate: new Date(value[0]).setHours(0, 0, 0, 0),
                    endDate: new Date(value[1]).setHours(23, 59, 59, 999),
                };
            }
            break;
        case FilterTypes.select:
            filter.value = value;
            break;
        case FilterTypes.text:
            filter.value = value;
            break;
    }
    filter.isSelected = true;
}

export function updateFiltersByUrl(filterValues: TransactionFiltersQuery, filters: Filter[]): Filter[] {
    filters.map((filter) => updateFilterValueFromUrl(filter, filterValues[filter.name]));
    return filters;
}

export interface TransactionFiltersQuery {
    beneficiaryId?: string;
    vendorId?: string[];
    createdAt?: Date[];
    status?: string[];
    type?: string[];
    paymentStatus?: string[];
}
