import * as React from 'react';
import { ACTION, MODAL, useEntityUpdateRequests } from '../entity-update-request/entity-update-request.context';
import RejectionDialog from '../../utils/RejectionDialog';
import { ConfirmationDialog } from '../../utils/Dialogs';
import styled from 'styled-components';
import { ConfirmProductManagementUpdateModalComponent } from '../../product-management/confirm-product-management-update-modal.component';

export function ProductManagementAuthorizationModalsComponent() {
    const { modalStateReducer } = useEntityUpdateRequests();
    const [state, send] = modalStateReducer;
    return (
        <>
            {state.modal === MODAL.REJECT && (
                <RejectionDialog
                    message={
                        <p>
                            Are you sure you want to reject changes made to{' '}
                            <strong>{state.updateRequest.title || state.updateRequest.entityId}</strong>?
                        </p>
                    }
                    onClose={() => send(ACTION.CANCEL)}
                    onConfirm={(comment) => send({ type: ACTION.CONFIRM, comment })}
                    title={'Reject Changes'}
                />
            )}
            {state.modal === MODAL.VERIFY && (
                <ConfirmProductManagementUpdateModalComponent
                    entityName={state.updateRequest.title || state.updateRequest.entityId}
                    onClose={() => send(ACTION.CANCEL)}
                    onConfirm={() => send(ACTION.CONFIRM)}
                    title={'Post Changes'}
                />
            )}
            {state.modal === MODAL.SIGN && (
                <ConfirmProductManagementUpdateModalComponent
                    entityName={state.updateRequest.title || state.updateRequest.entityId}
                    onClose={() => send(ACTION.CANCEL)}
                    onConfirm={() => send(ACTION.CONFIRM)}
                    title={'Sign Changes'}
                />
            )}
        </>
    );
}
