import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import State from '../app/store/state';
import { ActionCreators } from './vendors';
import { Input } from '../utils/inputs';
import { useState } from 'react';
import { hasFeatureAccess } from '../login/auth';
import { ManagerPermission } from '../permission-profiles/permission';

export default function VendorBranchesAdd({ params }: { params: { vendorId: string } }) {
    const [outlet, setOutlet] = useState('');
    const auth = useSelector((state: State) => state.auth);
    const vendorId = params.vendorId;
    const dispatch = useDispatch();

    const submitHandler = () => {
        if (outlet.length < 1) return;
        ActionCreators.parkBranch(vendorId, outlet)(dispatch);
    };

    return (
        <div>
            <div className="wfp-form--group row">
                <label className="col-sm-4 ta-right">Outlet</label>
                <div className="col-sm-8">
                    <Input
                        className="form-control"
                        isFocused={true}
                        name="outlet"
                        onChange={(event) => {
                            setOutlet(event.target.value);
                        }}
                        placeholder="Outlet"
                        required={true}
                        type="text"
                        value={outlet}
                    />
                </div>
            </div>
            {hasFeatureAccess(auth, ManagerPermission.vendorUserPark) && (
                <div className="wfp-form--actions text-center">
                    <div className="col-sm-8 col-sm-offset-4">
                        <button className="wfp-btn--primary" onClick={() => submitHandler()} type="submit">
                            Park New Branch
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
