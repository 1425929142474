import * as React from 'react';

import { Filter, MultiSelectFilter, SelectOption } from '../utils/FilterTypes';
import FiltersComponent from '../utils/filters';
import { PagedState } from '../utils/paging';
import TransactionsTable from '../utils/TransactionsTable';
import { updateFiltersWithNickNames } from '../utils/createEntityMultiSelect';
import { createDateFilter, createTransactionStatusFilter } from '../../utils/createPredefinedFilters';
import { ActionCreators as VendorActionCreators, defaultVendorSortingOrder, VendorsState } from '../vendors/vendors';
import { bindActionCreators } from 'redux';
import { ActionCreators as TransactionsActionCreators, Transaction } from '../transactions/transactions';
import AppState from '../app/store/state';
import { connect } from 'react-redux';
import { defaultManagerPanelTransactionHeaders } from '../utils/transactionTableUtils';
import { EntitlementCategoriesConfig, EntitlementCurrencyConfig } from '../app/appConfig';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { withNavigate } from '@wfp-common/hooks/withNavigate';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';

let defaultFilters = [
    createDateFilter(),
    createTransactionStatusFilter(),
    new MultiSelectFilter('Vendor nickname', 'vendorId', []),
];
let categoryFilter = new MultiSelectFilter('Category', 'category', [], []);

interface Props {
    title: string;
    vendors: VendorsState;
    transactions: PagedState<Transaction>;
    categories: EntitlementCategoriesConfig;
    currency: EntitlementCurrencyConfig;

    loadVendorsNickNames: (sortOptions: SORT_OPTIONS) => void;
    loadTransactions: (
        timezone: string,
        page: number,
        limit: number,
        filters?: Array<Filter>,
        sortOptions?: SORT_OPTIONS | {}
    ) => void;
    timezone: string;
    navigate: NavigateHook;
}

interface State {
    filters: Filter[];
    categoryFilter: Filter;
    sortOptions: SORT_OPTIONS | {};
    currentPage: number;
}

class BeneficiaryTransactions extends React.Component<Props, State> {
    defaultPageSize = 25;

    constructor(props) {
        super(props);
        this.state = {
            filters: defaultFilters,
            categoryFilter: new MultiSelectFilter('Category', 'category', [], []),
            currentPage: 1,
            sortOptions: {},
        };
        this.props.loadVendorsNickNames(defaultVendorSortingOrder);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.vendors.nickNameList !== this.props.vendors.nickNameList) {
            const nickameList = nextProps.vendors.nickNameList.filter((name) => name.authorizedAt);
            defaultFilters = updateFiltersWithNickNames(nickameList, defaultFilters, 'vendorId');
            this.setState({ filters: [...defaultFilters] });
        }

        if (this.props.categories && this.props.categories.categories) {
            const categories = this.props.categories.categories;
            const categoryOptions = categories.map((category) => new SelectOption(category, category));
            categoryFilter = new MultiSelectFilter('Category', 'category', [], categoryOptions);
        }
    }

    UNSAFE_componentWillMount() {
        this.loadTransactionsForPage(1);
    }

    private applyFilters(filters: Filter[]) {
        this.setState({ filters });
        this.props.loadTransactions(this.props.timezone, 1, this.defaultPageSize, filters, this.state.sortOptions);
    }

    private loadTransactionsForPage(newPage) {
        this.props.loadTransactions(
            this.props.timezone,
            newPage,
            this.defaultPageSize,
            this.state.filters,
            this.state.sortOptions
        );
        this.setState({ currentPage: newPage });
    }

    private applySort = (sortOptions: SORT_OPTIONS) => {
        this.props.loadTransactions(
            this.props.timezone,
            this.state.currentPage,
            this.defaultPageSize,
            this.state.filters,
            sortOptions
        );
        this.setState({ sortOptions });
    };

    render() {
        const filters =
            this.state.filters.length === defaultFilters.length
                ? [...this.state.filters, categoryFilter]
                : this.state.filters;
        const cleanFilters = [...defaultFilters, categoryFilter];

        return (
            <div>
                <h6>{this.props.title}</h6>
                {this.props.transactions && (
                    <div>
                        <FiltersComponent
                            defaultFilters={cleanFilters}
                            filters={filters}
                            onApplyFilters={this.applyFilters.bind(this)}
                            withApply={true}
                            withIsSelected={true}
                        />
                        <TransactionsTable
                            currency={this.props.currency}
                            headers={defaultManagerPanelTransactionHeaders}
                            onChangePageRequested={this.loadTransactionsForPage.bind(this)}
                            onSort={this.applySort}
                            onTransactionChosen={(transaction) =>
                                this.props.navigate(`/transactions/${transaction.id}`)
                            }
                            transactions={this.props.transactions}
                        />
                    </div>
                )}
            </div>
        );
    }
}

interface TransactionsConfig {
    title: string;
    getTransactions: (state: AppState) => PagedState<Transaction>;
    loadTransactions: (timezone, beneficiaryId, page, limit, filters: Array<Filter>, sortOptions: SORT_OPTIONS) => void;
}

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

function mapDispatchToProps(transactionsConfig: TransactionsConfig) {
    return function mapDispatchToProps(dispatch: any, ownProps: OwnProps) {
        const beneficiaryId = ownProps.location.state.beneficiaryId;
        return {
            loadVendorsNickNames: bindActionCreators(VendorActionCreators.loadVendorsNickNames, dispatch),
            loadTransactions: (timezone, page, limit, filters, sortOptions) =>
                dispatch(
                    transactionsConfig.loadTransactions(timezone, beneficiaryId, page, limit, filters, sortOptions)
                ),
        };
    };
}

function mapStateToProps(transactionsConfig: TransactionsConfig) {
    return function mapStateToProps(state: AppState) {
        return {
            title: transactionsConfig.title,
            transactions: transactionsConfig.getTransactions(state),
            vendors: state.vendors,
            categories: state.appConfig.entitlementsConfig,
            currency: state.appConfig.entitlementCurrencyConfig,
            timezone: state.appConfig.timeZone,
        };
    };
}

const adjustmentsConfig: TransactionsConfig = {
    title: 'Adjustments',
    getTransactions: (state: AppState) => state.editBeneficiary.adjustmentTransactions,
    loadTransactions: TransactionsActionCreators.loadEditBeneficiaryAdjustmentTransactions,
};

const expendituresConfig: TransactionsConfig = {
    title: 'Expenditures',
    getTransactions: (state: AppState) => state.editBeneficiary.expenditureTransactions,
    loadTransactions: TransactionsActionCreators.loadEditBeneficiaryExpendituresTransactions,
};

export const AdjustmentTransactions = connect(
    mapStateToProps(adjustmentsConfig),
    mapDispatchToProps(adjustmentsConfig)
)(withNavigate(BeneficiaryTransactions, 'BeneficiaryTransactions'));
export const ExpendituresTransactions = connect(
    mapStateToProps(expendituresConfig),
    mapDispatchToProps(expendituresConfig)
)(withNavigate(BeneficiaryTransactions, 'BeneficiaryTransactions'));
