// import { useNavigate as useRouterNavigate } from 'react-router'; TODO: uncomment after update router to V6

import { browserHistory } from 'react-router';

export type NavigateHook = (url: string, state?: any) => void;

export const useNavigate = (): NavigateHook => {
    return (url: string, state?: any) => {
        // routerNavigate(url, { state });
        browserHistory.push({
            pathname: url,
            state: { ...state },
        });
    };
};
