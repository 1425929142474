import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { recursiveChildrenPropCheck } from '../table/table-head/table-head.utils';

const UNICODE_DOWN_ARROW = '\u2193';
const directions = ['ASC', 'DESC'] as const;

export type DIRECTION_OPTIONS = typeof directions[number];
export type SORT_OPTIONS<T = string> = {
    'orderBy:column': T;
    'orderBy:direction': DIRECTION_OPTIONS;
};

export class SortOptions<T = string> {
    'orderBy:column': T;
    'orderBy:direction': DIRECTION_OPTIONS;
    constructor({ column, direction }: { column: T; direction: DIRECTION_OPTIONS }) {
        this['orderBy:column'] = column;
        this['orderBy:direction'] = direction;
    }
}

export type MultisortChangeFunction<T = string> = (
    options: Array<[T, DIRECTION_OPTIONS]>
) => void | Array<[T, DIRECTION_OPTIONS]> | Promise<Array<[T, DIRECTION_OPTIONS]>> | Promise<void>;

export function useMultiSort(userOverrideSortOptions?: MultisortChangeFunction) {
    const [column, setColumn] = useState<[string, DIRECTION_OPTIONS][]>([]);

    useEffect(() => {
        changeSortOptions(column);
    }, [column]);
    const handleClick = useCallback((column) => {
        setColumn((state) => {
            const index = state.findIndex(([col]) => col === column);
            if (!~index) {
                return [...state, [column, 'ASC']];
            }

            const [, direction] = state[index];
            const [before, after] = splicedInTwoByIndex(state, index);
            if (direction === 'ASC') {
                return [...before, [column, 'DESC'], ...after];
            }
            return [...before, ...after];
        });
    }, []);

    const changeSortOptions = useCallback(
        async (current: [string, DIRECTION_OPTIONS][]) => {
            if (userOverrideSortOptions) {
                const options = await userOverrideSortOptions(current);
                if (Array.isArray(options)) {
                    // const [newColumn, newDirection] = options;

                    setColumn((state) => options ?? state);
                    // setDirection((state) => newDirection ?? state);
                }
            }
        },
        [userOverrideSortOptions]
    );
    const renderChildren = useCallback(
        (children) =>
            React.Children.map(children, (child: any) => {
                const text = recursiveChildrenPropCheck(child);
                const isDisabled = child?.type?.displayName === 'DisabledColumn';
                const index = column.findIndex(([col]) => col === text);
                const [_column, _direction] = column[index] || [];
                return child
                    ? React.cloneElement(child, {
                          ...child?.props,
                          children: [
                              <div className="BB-column-wrapper" key={text}>
                                  {child.props.children}
                                  <span className="arrow" key="arrow">
                                      <span>{UNICODE_DOWN_ARROW}</span>
                                  </span>
                                  <span className="sort-order">{index + 1}</span>
                              </div>,
                          ],
                          onClick: isDisabled
                              ? undefined
                              : (event) => {
                                    handleClick(text);
                                    child?.props?.onClick?.(event);
                                },
                          className: isDisabled
                              ? 'disabled-column'
                              : !_column
                              ? ''
                              : _direction === 'ASC'
                              ? 'active asc'
                              : _direction === 'DESC'
                              ? 'active desc'
                              : '',
                      })
                    : null;
            }),
        [column]
    );
    return renderChildren;
}

function splicedInTwoByIndex<T = unknown>(array: T[], index: number) {
    const before = [...array].splice(0, index);
    const after = [...array].splice(index + 1);
    return [before, after];
}
export class NewSortOptions<T = string> {
    orderBy: string;
    constructor(options: { column: T; direction: DIRECTION_OPTIONS } | [T, DIRECTION_OPTIONS][]) {
        if (Array.isArray(options)) {
            this.orderBy = options.join(';');
        } else {
            this.orderBy = [[options.column, options.direction]].join(';');
        }
    }
}
