import * as React from 'react';
import Switch from 'rc-switch';

import { Vendor } from './vendors';
import { Form, Input, ExternalValidationError, ValidationContext } from '../utils/inputs';
import { paymentFrequencyMap } from '../utils/paymentFrequencyMap';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { EntityType, EntityUpdateReference } from '../authorization/taskAuthorizations';
import { InformationDialog } from '../utils/Dialogs';
import { BigNumber } from '../utils/bigNumber';
import { ManagerPermission } from '../permission-profiles/permission';

export class VendorPaymentInfo {
    id: string;
    wfpBankKey: string;
    vendorBankKey: string;
    paymentFrequency: string;
    authorizedAt: Date;
    pendingEntityUpdates?: EntityUpdateReference[];
    paymentDiscount: string;

    constructor(vendor: Vendor) {
        this.id = vendor.id;
        this.wfpBankKey = vendor.wfpBankKey;
        this.vendorBankKey = vendor.vendorBankKey;
        this.paymentFrequency = vendor.paymentFrequency;
        this.pendingEntityUpdates = vendor.pendingEntityUpdates;
        this.authorizedAt = vendor.authorizedAt;
        this.paymentDiscount = vendor.paymentDiscount;
    }
}

interface Props {
    vendorPaymentInfo: VendorPaymentInfo;
    validationError?: ExternalValidationError;
    parkEnabled: boolean;
    auth: AuthState;
    onSaved: (vendor: VendorPaymentInfo) => void;
}

interface State {
    vendorPaymentInfo: VendorPaymentInfo;
    editChecked: boolean;
    hasPendingDetailsUpdate: boolean;
}

export default class VendorPayment extends React.Component<Props, State> {
    _validationContext = new ValidationContext();
    defaultPaymentFrequency = 'BOM';

    constructor(props: Props) {
        super(props);
        this.state = {
            vendorPaymentInfo: props.vendorPaymentInfo,
            editChecked: false,
            hasPendingDetailsUpdate: false,
        };
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        if (props.vendorPaymentInfo.id) {
            this.setState({ vendorPaymentInfo: props.vendorPaymentInfo });
        }
        if (props.validationError) {
            this._validationContext.pushError(props.validationError);
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({ vendorPaymentInfo: this.props.vendorPaymentInfo });
    }

    isCreateVendorPage() {
        return this.props.vendorPaymentInfo.id === 'new';
    }

    renderSelectField(options: { readonly: boolean; value: string; name: string; required: boolean; type: string }) {
        return (
            <div className="col-sm-8">
                {options.readonly && <label className="normal">{paymentFrequencyMap[options.value] || '-'}</label>}
                {!options.readonly && (
                    <select
                        className="col-sm-8"
                        name={options.name}
                        onChange={this._handleInputChange.bind(this)}
                        value={options.value || this.defaultPaymentFrequency}
                    >
                        <option value="BOM">{paymentFrequencyMap['BOM']}</option>
                        <option value="15M">{paymentFrequencyMap['15M']}</option>
                        <option value="EOM">{paymentFrequencyMap['EOM']}</option>
                        <option value="MON">{paymentFrequencyMap['MON']}</option>
                        <option value="TUE">{paymentFrequencyMap['TUE']}</option>
                        <option value="WED">{paymentFrequencyMap['WED']}</option>
                        <option value="THU">{paymentFrequencyMap['THU']}</option>
                        <option value="FRI">{paymentFrequencyMap['FRI']}</option>
                        <option value="SAT">{paymentFrequencyMap['SAT']}</option>
                        <option value="SUN">{paymentFrequencyMap['SUN']}</option>
                    </select>
                )}
            </div>
        );
    }

    renderNumberFiled(options: { readonly: boolean; value: string; name: string; required: boolean; type: string }) {
        return (
            <div className="col-sm-8">
                {options.readonly && (
                    <label className="normal">{new BigNumber(options.value).multipliedBy(100).toString()}</label>
                )}
                {!options.readonly && (
                    <Input
                        className="form-control"
                        context={this._validationContext}
                        errorMessages={{
                            min: 'Payment Discount must be positive',
                            max: 'Payment Discount must be less than 10',
                        }}
                        max={10.0}
                        min={0}
                        name={options.name}
                        onChange={this._handlePaymentSavingInputChange.bind(this)}
                        required={options.required}
                        step={0.1}
                        type={options.type}
                        value={new BigNumber(options.value).multipliedBy(100).toString()}
                    />
                )}
            </div>
        );
    }

    renderInputField(options: {
        readonly: boolean;
        value: string;
        name: string;
        required: boolean;
        type: string;
        isFocused?: boolean;
    }) {
        return (
            <div className="col-sm-8">
                {options.readonly && <label className="normal">{options.value || '-'}</label>}
                {!options.readonly && (
                    <Input
                        className="form-control"
                        context={this._validationContext}
                        isFocused={options.isFocused}
                        name={options.name}
                        onChange={this._handleInputChange.bind(this)}
                        required={options.required}
                        type={options.type}
                        value={options.value}
                    />
                )}
            </div>
        );
    }

    renderSwitch() {
        const parkEnabled = hasFeatureAccess(this.props.auth, ManagerPermission.vendorsPark);
        const isEditSwitchVisible = parkEnabled && !this.isCreateVendorPage();
        if (isEditSwitchVisible) {
            return (
                <div className={'row'}>
                    <label className="col-sm-4 ta-right">Toggle Edit</label>
                    <div className="col-sm-8 pv1">
                        <Switch checked={this.state.editChecked} onClick={this.toggleEdit.bind(this)} />
                    </div>
                </div>
            );
        }
    }

    toggleEdit(editChecked) {
        const { vendorPaymentInfo } = this.props;
        let hasPendingDetailsUpdate = false;
        if (vendorPaymentInfo && vendorPaymentInfo.pendingEntityUpdates) {
            const hasBeneficiaryDetailsEntity = vendorPaymentInfo.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.vendorDetails
            );
            hasPendingDetailsUpdate = hasBeneficiaryDetailsEntity.length !== 0;
        }
        if (hasPendingDetailsUpdate) {
            this.setState({ hasPendingDetailsUpdate: hasPendingDetailsUpdate });
        } else if (vendorPaymentInfo.authorizedAt) {
            this.setState({ editChecked: editChecked });
        }
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Vendor details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending Updates"
            />
        );
    }

    render() {
        const { parkEnabled } = this.props;
        const { id, wfpBankKey, paymentFrequency, vendorBankKey, paymentDiscount } = this.state.vendorPaymentInfo;
        const isReadonly = !parkEnabled || !this.state.editChecked;
        const inputFieldOptions = ({ value, name, type }) => {
            return {
                readonly: isReadonly,
                value: value,
                name: name,
                required: true,
                type: type,
            };
        };
        return (
            <div>
                <h6>Payment Information</h6>

                {this.renderSwitch()}
                {this.state.hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}

                <Form className="wfp-form" context={this._validationContext} onSubmit={this._saveChanges.bind(this)}>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">WFP Bank Key</label>
                        {this.renderInputField({
                            ...inputFieldOptions({
                                value: wfpBankKey,
                                name: 'wfpBankKey',
                                type: 'text',
                            }),
                            readonly: true,
                            required: false,
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Vendor Bank Key</label>
                        {this.renderInputField({
                            ...inputFieldOptions({
                                value: vendorBankKey,
                                name: 'vendorBankKey',
                                type: 'text',
                            }),
                            isFocused: true,
                        })}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Payment Frequency</label>
                        {this.renderSelectField(
                            inputFieldOptions({
                                value: paymentFrequency,
                                name: 'paymentFrequency',
                                type: 'text',
                            })
                        )}
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Payment Discount (%)</label>
                        {this.renderNumberFiled(
                            inputFieldOptions({
                                value: paymentDiscount,
                                name: 'paymentDiscount',
                                type: 'number',
                            })
                        )}
                    </div>
                    {parkEnabled && this.state.editChecked && (
                        <div className="wfp-form--actions text-center">
                            <div className="col-sm-8 col-sm-offset-4">
                                <button className="wfp-btn--primary" type="submit">
                                    {id ? 'Park changes' : 'Park'}
                                </button>
                            </div>
                        </div>
                    )}
                </Form>
            </div>
        );
    }

    private _handleInputChange({ target }) {
        const vendorPaymentInfo = Object.assign({}, this.state.vendorPaymentInfo);
        vendorPaymentInfo[target.name] = target.value;
        this.setState({ vendorPaymentInfo });
    }

    private _handlePaymentSavingInputChange({ target }) {
        const vendorPaymentInfo = Object.assign({}, this.state.vendorPaymentInfo);
        vendorPaymentInfo[target.name] = new BigNumber(target.value).dividedBy(100).toString();
        this.setState({ vendorPaymentInfo });
    }

    private _saveChanges(event) {
        const vendor = this.state.vendorPaymentInfo;
        if (!vendor.paymentFrequency) {
            vendor.paymentFrequency = this.defaultPaymentFrequency;
        }
        this.props.onSaved(vendor);
        event.preventDefault();
    }
}
