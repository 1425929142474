import { useEffect, useReducer, useState } from 'react';
import { loadPricesForProduct, loadProducts } from '../../../apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { loadPrices } from '../../utils/load-prices.utils';
import { compose, wfpFormat } from '../../../utils/utils';
import { errorAction, successAction } from '../../../utils/notifications';
import State from '../../../app/store/state';
import { AddProductEvent, EventType, Reducer } from './use-product-update.types';
import * as moment from 'moment-timezone';
import { createProductGroupUpdateRequest, uploadProductUpdateFile } from '../../../api/product-management';
import { normalizePriceFormat } from './use-product-update.utils';
import { createReducer } from './use-product-update.reducer';
import { EntityUpdate } from '../../../authorization/taskAuthorizations';
import { ProductType } from '../../types/product.type';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

const maxDate = moment(new Date()).add(6, 'month').format('YYYY-MM-DD');

interface Params {
    groupId: number;
    groupName: string;
    updates?: EntityUpdate[];
    validFrom?: string;
}

const initializeMachine = {
    initial: 'idle',
    states: {
        idle: { on: { TOGGLE: 'active' } },
        active: { on: { TOGGLE: 'initialized' } },
        initialized: {},
    },
};

function interpreter(state, event) {
    return initializeMachine.states?.[state]?.on?.[event] || state;
}

export function useProductUpdate({ groupId, groupName, updates }: Params) {
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const timezone = useSelector((state: State) => state.appConfig.timeZone);
    const country = useSelector((state: State) => state.appConfig.country);
    const [formInitializationState, dispatchFormState] = useReducer(interpreter, initializeMachine.initial);
    const [validFrom, setValidFrom] = useState<string>(moment().tz(timezone).format('YYYY-MM-DD HH:mm'));
    const [validFromError, setValidFromError] = useState(false);
    const [file, setFile] = useState(null);
    const priceSchedulingEnabled = useSelector(
        (state: State) => state.appConfig.productManagementConfig.priceSchedulingEnabled
    );
    useEffect(() => {
        loadProducts({ filter: groupName.toLowerCase() === 'fresh food corner' ? 'FFC' : 'BSE' })
            .then((products) =>
                products.map((product) => ({ label: product.name, value: product.id, minPrice: 0.01, maxPrice: 0.01 }))
            )
            .then(setProducts);
    }, []);
    const maxPriceValue = useSelector((state: State) => state.appConfig.productManagementConfig.maxPriceValue);
    const [state, send] = useReducer<Reducer>(createReducer(maxPriceValue), null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (updates && products.length && formInitializationState === 'initialized') {
            updates.forEach((update) => {
                const isNew = typeof update.oldValue === 'string';
                const isRemoved = typeof update.newValue === 'string';
                const productFromGroup = state.products[update.path];
                if (isNew) {
                    if (productFromGroup) return;
                    const product = products.find(
                        (product) => product.label.toLowerCase() === update.path.toLowerCase()
                    );
                    addProduct({ target: product }, update.newValue);
                } else if (isRemoved) {
                    if (!productFromGroup) return;
                    toggleRemove(update.path, 'remove')();
                } else {
                    const entries = Object.entries(update.newValue);
                    entries.forEach(([name, value]) => {
                        handleChange(update.path)({ target: { name, value } });
                    });
                }
            });
        }
    }, [products, formInitializationState]);
    const getPrices = async (groupId) => {
        dispatchFormState('TOGGLE');
        const prices = await loadPrices(groupId, timezone);
        send({ type: EventType.INITIALIZE, prices });
        dispatchFormState('TOGGLE');
    };

    useEffect(() => {
        getPrices(groupId);
    }, []);
    const handleChange = (product: string) => (event) => {
        event.persist?.();
        send({
            productName: product,
            value: event.target.value,
            field: event.target.name,
            type: EventType.EDIT,
        });
    };

    const applyChanges = async () => {
        try {
            if (validFrom || !priceSchedulingEnabled) {
                const updateRequest = await createProductGroupUpdateRequest(
                    {
                        products: Object.values(state.products)
                            .filter((product) => product.edited || product.markedForRemoval)
                            .map((product) => ({
                                ...product,
                                minPrice: normalizePriceFormat(product.minPrice),
                                maxPrice: product.maxPrice === '' ? null : normalizePriceFormat(product.maxPrice),
                            })),
                        productGroup: groupId,
                        ...(priceSchedulingEnabled ? { validFrom } : null),
                    },
                    timezone
                );
                if (file) {
                    await uploadProductUpdateFile(updateRequest.id, groupId, file);
                }
                compose(dispatch, successAction)('Update request was parked successfully');
                navigate('/products');
            } else {
                compose(dispatch, errorAction)('Valid from date is invalid');
            }
        } catch (error) {
            const errorMessage =
                error.status === 409
                    ? `Could not park an update because ${wfpFormat(groupName)} already has a pending request.`
                    : 'Update request park action was unsuccessful.';
            compose(dispatch, errorAction)(errorMessage);
        }
    };

    const addProduct = async (event, overrides?: ProductType) => {
        const price = await loadPricesForProduct(event.target.value);
        const minPrice = overrides?.minPrice || price?.[0]?.minPrice || 0.01;
        const maxPrice = overrides?.maxPrice || price?.[0]?.maxPrice || 0.01;
        const isFixed = overrides?.isFixed || false;
        const newProductField: AddProductEvent = {
            type: EventType.ADD_PRODUCT,
            productName: event.target.label,
            value: {
                minPrice: minPrice || 0.01,
                maxPrice: maxPrice || 0.01,
                name: event.target.label,
                priceId: null,
                productId: event.target.value,
                isNew: true,
                isFixed,
            },
        };
        send(newProductField);
    };
    const toggleRemove = (productName, type) => () => {
        send({
            productName,
            value: type === 'remove',
            type: EventType.MARKED_FOR_REMOVAL,
        });
    };
    const toggleCheckbox = (productName: string) => (event) => {
        event.persist?.();
        handleChange(productName)({ target: { value: event.target.checked, name: event.target.name } });
    };
    const setDecimal = (productName: string) => (event) => {
        event.persist?.();
        const value = Number(event.target.value) <= 0.01 ? 0.01 : event.target.value;
        const allowBlank = (event.target.name === 'maxPrice' && event.target.value === '') || isNaN(Number(value));
        handleChange(productName)({
            target: {
                value: allowBlank ? '' : Number(value).toFixed(2),
                name: event.target.name,
            },
        });
    };
    const handleSetFile = (event) => {
        event.persist();
        setFile(event.target.files[0]);
    };
    return {
        priceSchedulingEnabled,
        toggleRemove,
        addProduct,
        state,
        applyChanges,
        handleChange,
        setDecimal,
        toggleCheckbox,
        products,
        validFromError,
        file,
        handleSetFile,
        timePickerProps: {
            onChange(event) {
                const validFrom = new Date(event.value);
                const limit = new Date(moment(state.metadata.validFrom).tz(timezone).format('YYYY-MM-DD HH:mm'));
                if (+validFrom <= +limit) {
                    setValidFromError(true);
                } else {
                    setValidFromError(false);
                }
                setValidFrom(event.value);
            },
            maxDate,
            showLocalTime: true,
            timezone,
            country,
        },
    };
}
