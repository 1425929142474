// import { useSearchParams as useRouterSearchParams } from 'react-router-dom'; TODO: uncomment after update router to V6

import { browserHistory } from 'react-router';

export interface SearchParamsHook {
    redirectUrl: string;
    id: string;
}

export const useSearchParams = (): SearchParamsHook => {
    // const [searchParams] = useRouterSearchParams();
    const searchParams = new URLSearchParams(browserHistory.getCurrentLocation().search);

    return {
        redirectUrl: searchParams.get('redirectUrl'),
        id: searchParams.get('id'),
    };
};
