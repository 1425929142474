import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import State from '../../app/store/state';
import { AuthState } from '../../login/auth';
import { ActionCreators, ActivityLog, ActivityType, ActivityTypes, ExtendedActivityLog } from '../activityLogs';
import { compose, displayTime, separateCamelCase, wfpFormat } from '../../utils/utils';
import { importUpdatesView, transactionsView } from './ActivityLogUpdatesView';
import { PagedState } from '../../utils/paging';
import { VendorNickName } from '../../vendors/vendors';
import { ChildListView } from './ActivityLogsChildList';
import NamedId from '../../utils/NamedId';
import { PaymentTransitionView } from './PaymentRequestTransitionView';
import { TransactionTypes } from '../../transactions/transactions';
import { PaymentConfig, PredefinedCurrency } from '../../app/appConfig';
import { DefaultChangesList } from '../../authorization/change-lists/default-change-list';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';
import { withNavigate } from '@wfp-common/hooks/withNavigate';

interface Props {
    getActivityLog: (id: string) => void;
    getActivityChildList: (timezone: string, id: string, page: number, limit: number) => void;

    auth: AuthState;
    data: ExtendedActivityLog;
    params: { logId: string };
    activityLogsChildList: PagedState<ActivityLog<any>>;
    vendorsNickNames: VendorNickName[];
    currency: PredefinedCurrency;
    paymentsConfig: PaymentConfig;
    timezone: string;
    navigate: NavigateHook;
}

const redirectableAsyncTask = [
    'beneficiary-zeroing',
    'beneficiary-import-requests',
    'beneficiary-block-requests',
    'beneficiary-zeroing-block',
    'beneficiary-unblock-requests',
];

class ActivityLogsDetailsView extends React.Component<Props, any> {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.getActivityLog(this.props.params.logId);
    }

    UNSAFE_componentWillReceiveProps(next: Props) {
        if (next.params.logId !== this.props.params.logId) {
            this.props.getActivityLog(next.params.logId);
        }
    }

    private getAffectedOn(activityLog: ExtendedActivityLog) {
        const affectedOn = [];
        if (activityLog.manager) {
            affectedOn.push({
                entityType: 'User',
                link: `/admin/${activityLog.manager.id}`,
                reference: activityLog.manager.name,
            });
        }
        if (activityLog.beneficiaryId) {
            affectedOn.push({
                entityType: 'Beneficiary',
                link: `/beneficiaries/beneficiary`,
                historyState: { beneficiaryId: activityLog.beneficiaryId },
                reference: activityLog.beneficiaryId,
            });
        }
        if (activityLog.payload && activityLog.payload.vendorUserId) {
            affectedOn.push({
                entityType: 'Vendor User',
                link: `/vendors/${activityLog.systemVendorId}/panel/${activityLog.payload.vendorUserId}`,
                reference: activityLog.payload.vendorUserId,
            });
        }
        if (activityLog.vendorId) {
            affectedOn.push({
                entityType: 'Vendor',
                link: `/vendors/${activityLog.systemVendorId}`,
                reference: activityLog.vendorId,
            });
        }
        if (activityLog.documentSection) {
            affectedOn.push({
                entityType: 'DocumentSection',
                link: `/documents`,
                reference: activityLog.documentSection,
            });
        }

        if (activityLog.documentId) {
            if (activityLog.relatedModelResponse.deleted) {
                affectedOn.push({
                    entityType: 'Document',
                    reference: activityLog.relatedModelResponse.fileName + ' (Deleted)',
                });
            } else {
                affectedOn.push({
                    entityType: 'Document',
                    link: `/documents`,
                    reference: activityLog.relatedModelResponse.fileName,
                });
            }
        }
        if (activityLog.permissionProfileId) {
            affectedOn.push({
                entityType: 'Permission Profile',
                link: `/admin/permission-profiles/`,
                historyState: { permissionProfileId: activityLog.permissionProfileId },
                reference: activityLog.permissionProfileName,
            });
        }

        if (activityLog.payload && activityLog.payload.partnerUserId) {
            affectedOn.push({
                entityType: 'Partner User',
                link: `/partners/${activityLog.partnerId}/panel/${activityLog.payload.partnerUserId}`,
                reference: activityLog.payload.partnerUserId,
            });
        }
        if (activityLog.partnerId) {
            affectedOn.push({
                entityType: 'Partner',
                link: `/partners/${activityLog.partnerId}`,
                reference: activityLog.partnerId,
            });
        }
        return affectedOn;
    }

    private _getActivityChildList(page: number, limit: number) {
        this.props.getActivityChildList(this.props.timezone, this.props.params.logId, page, limit);
    }

    private onActivityLogChosen = (activityLog: ActivityLog<any>) => {
        this.props.navigate(`/activity-logs/${activityLog.id}`);
    };

    private renderSingleRow(label, value) {
        return (
            <tr>
                <th>{label}</th>
                <td>{value}</td>
            </tr>
        );
    }

    private renderManagerRow(label, manager, time) {
        return (
            <tr>
                <th>{label}</th>
                <td>
                    {displayTime(time)}
                    <div>
                        by <a onClick={() => this.props.navigate('/admin/' + manager.id)}>{manager.email}</a>
                    </div>{' '}
                </td>
            </tr>
        );
    }

    private renderTransactionRow(label, transactionId) {
        return (
            <tr>
                <th>{label}</th>
                <td>
                    <a onClick={() => this.props.navigate('/transactions/' + transactionId)}>{transactionId}</a>
                </td>
            </tr>
        );
    }

    private showSignInfo(signAt: Date, sign1By: NamedId, level: string) {
        return sign1By ? (
            <tr>
                <th>{wfpFormat(`Level ${level} signature signed`)}</th>
                <td>
                    {signAt && displayTime(signAt)}
                    <div>
                        by <a onClick={() => this.props.navigate('/admin/' + sign1By.id)}>{sign1By.email}</a>
                    </div>
                </td>
            </tr>
        ) : null;
    }

    private checkBeneficiaryNewStatus(data: ExtendedActivityLog) {
        if (data.activity === ActivityType.beneficiaryMassStatusChange) {
            return data.relatedModelResponse.asyncTaskType === 'beneficiary-block-requests' ? 'Block' : 'Unblock';
        } else {
            return data.relatedModelResponse.updates.find((v) => v.newValue === 'blocked') ? 'Block' : 'Unblock';
        }
    }

    render() {
        const { data } = this.props;
        if (!data) {
            return <main />;
        }

        const relatedModelResponse = data.relatedModelResponse;
        const relatedTransactions = data.relatedTransactions;
        const relatedRowProcessing = data.relatedRowProcessing;
        const isStatusChanged = [
            ActivityType.beneficiaryMassStatusChange,
            ActivityType.beneficiarySingleBlock,
            ActivityType.beneficiarySingleUnblock,
        ].includes(data.activity);
        const affectedOn = this.getAffectedOn(data);
        return (
            <div>
                <table className="wfp-table mt4 table-hover">
                    {data && (
                        <tbody>
                            {this.renderSingleRow('ID', data.id)}
                            {data.payload?.hasOwnProperty('asyncTaskId') &&
                                redirectableAsyncTask.includes(data.payload['asyncTaskType']) && (
                                    <tr>
                                        <th>Task ID</th>
                                        <td>
                                            <a
                                                onClick={() =>
                                                    this.props.navigate(
                                                        `/tasks/` +
                                                            data.payload['asyncTaskType'] +
                                                            `/` +
                                                            data.payload['asyncTaskId']
                                                    )
                                                }
                                            >
                                                {data.payload['asyncTaskId']}
                                            </a>
                                        </td>
                                    </tr>
                                )}
                            {data.payload?.hasOwnProperty('paymentRequestId') && (
                                <tr>
                                    <th>Payment ID</th>
                                    <td>
                                        <a
                                            onClick={() =>
                                                this.props.navigate(`/payments/` + data.payload['paymentRequestId'])
                                            }
                                        >
                                            {data.payload['paymentRequestId']}
                                        </a>
                                    </td>
                                </tr>
                            )}
                            {data.payload?.type &&
                                this.renderSingleRow(
                                    'Transaction Type',
                                    TransactionTypes.displayName(data.payload.type)
                                )}
                            {this.renderSingleRow(
                                'Activity Type',
                                compose(wfpFormat, separateCamelCase, ActivityTypes.displayName)(data.activity)
                            )}
                            {isStatusChanged &&
                                this.renderSingleRow('New Status', this.checkBeneficiaryNewStatus(data))}
                            {affectedOn && (
                                <tr>
                                    <th>Affected on</th>
                                    <td>
                                        {affectedOn.map((entity) => {
                                            return (
                                                <div key={entity.reference}>
                                                    {compose(wfpFormat, separateCamelCase)(entity.entityType)}
                                                    <div>
                                                        {entity.link ? (
                                                            <a
                                                                onClick={() =>
                                                                    this.props.navigate(
                                                                        entity.link,
                                                                        entity.historyState
                                                                    )
                                                                }
                                                            >
                                                                {entity.reference}
                                                            </a>
                                                        ) : (
                                                            entity.reference
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </td>
                                </tr>
                            )}
                            {relatedModelResponse.firstName &&
                                this.renderSingleRow('First Name', relatedModelResponse.firstName)}
                            {relatedModelResponse.lastName &&
                                this.renderSingleRow('Last Name', relatedModelResponse.lastName)}
                            {relatedModelResponse.email && this.renderSingleRow('Email', relatedModelResponse.email)}
                            {data.parkedByManager &&
                                this.renderManagerRow(
                                    'Parked',
                                    data.parkedByManager,
                                    relatedModelResponse.createdAt || data.createdAt
                                )}
                            {data.postedByManager &&
                                this.renderManagerRow(
                                    'Posted',
                                    data.postedByManager,
                                    relatedModelResponse.authorizedAt
                                        ? displayTime(relatedModelResponse.authorizedAt)
                                        : displayTime(data.createdAt)
                                )}
                            {this.showSignInfo(relatedModelResponse.signOneAt, data.signOneByManager, 'one')}
                            {this.showSignInfo(relatedModelResponse.signTwoAt, data.signTwoByManager, 'two')}
                            {relatedModelResponse.cancelledAt &&
                                this.renderManagerRow(
                                    'Cancelled',
                                    relatedModelResponse.cancelledByManager,
                                    relatedModelResponse.cancelledAt
                                )}
                            {relatedModelResponse.deletedByManager &&
                                this.renderManagerRow(
                                    'Deleted',
                                    relatedModelResponse.deletedByManager,
                                    relatedModelResponse.deletedAt
                                )}
                            {relatedModelResponse.startedAt &&
                                this.renderSingleRow('Started', displayTime(relatedModelResponse.startedAt))}
                            {relatedModelResponse.finishedAt &&
                                this.renderSingleRow('Finished', displayTime(relatedModelResponse.finishedAt))}
                            {data.payload?.reason && this.renderSingleRow('Reason', data.payload.reason)}
                            {data.payload?.source && this.renderSingleRow('Source', data.payload.source)}
                            {data.payload?.originalTransactionId &&
                                this.renderTransactionRow(
                                    'Original Transaction ID',
                                    data.payload.originalTransactionId
                                )}
                            {relatedTransactions &&
                                this.renderSingleRow(
                                    'Transactions',
                                    transactionsView(relatedTransactions, this.props.currency)
                                )}
                            {relatedRowProcessing &&
                                this.renderSingleRow('Updates', importUpdatesView(relatedRowProcessing))}
                            {relatedModelResponse.updates &&
                                this.renderSingleRow(
                                    'Updates',
                                    <DefaultChangesList
                                        isActivityLogsView={true}
                                        updates={relatedModelResponse.updates}
                                    />
                                )}
                            {PaymentTransitionView(data.relatedModelResponse)}
                        </tbody>
                    )}
                </table>
                <div>
                    {data.isParent && (
                        <ChildListView
                            activityLogsChildList={this.props.activityLogsChildList}
                            getActivityLogs={this._getActivityChildList.bind(this)}
                            onActivityLogChosen={this.onActivityLogChosen.bind(this)}
                            vendorsNickNames={this.props.vendorsNickNames}
                        />
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        data: state.activityLogs.singleActivityLogs,
        auth: state.auth,
        activityLogsChildList: state.activityLogs.activityLogs,
        vendorsNickNames: state.vendors.nickNameList,
        currency: state.appConfig.entitlementCurrencyConfig,
        paymentsConfig: state.appConfig.paymentConfig,
        timezone: state.appConfig.timeZone,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getActivityLog: bindActionCreators(ActionCreators.getActivityLog, dispatch),
        getActivityChildList: bindActionCreators(ActionCreators.loadAsyncTaskActivityLogs, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigate(ActivityLogsDetailsView, 'ActivityLogDetailsView'));
