import * as React from 'react';
import { useCallback } from 'react';
import { ActionCreators } from './asyncTasks';
import { useDispatch } from 'react-redux';

export const DownloadStaticFileButton: React.FC<{ filename: string }> = ({ filename, children }) => {
    const dispatch = useDispatch();
    const downloadStatic = useCallback(() => {
        ActionCreators.downloadStatic(filename)(dispatch).catch((v) => v);
    }, []);
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="mt-2">
                <a onClick={downloadStatic}>{children}</a>
            </div>
        </div>
    );
};

export const DownloadAdditionalFileButton: React.FC<{ fileKey: string }> = ({ fileKey, children }) => {
    const dispatch = useDispatch();
    const downloadFile = useCallback(() => {
        ActionCreators.downloadAdditionalFile(fileKey)(dispatch).catch((v) => v);
    }, []);
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="mt-2">
                <a onClick={downloadFile}>{children}</a>
            </div>
        </div>
    );
};
