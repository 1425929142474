import * as React from 'react';
import { Outlet } from './types/outlet.type';
import { ChangeSortOptions } from '../utils/hooks/useSort';
import { SortableTableHead } from '../utils/table/table-head/sortable-table-head';

interface Props {
    outlets: Array<[string, Outlet[]]>;
    onSort?: ChangeSortOptions;
}

export function Outlets({ outlets, onSort }: Props) {
    return (
        <>
            {onSort ? (
                <SortableTableHead changeSortOptions={onSort}>
                    <SortableTableHead.Cell columnName="outlet">Branch</SortableTableHead.Cell>
                    <SortableTableHead.DisabledColumn colSpan={6} columnName="nickName">
                        Vendor(s)
                    </SortableTableHead.DisabledColumn>
                </SortableTableHead>
            ) : (
                <thead>
                    <tr>
                        <th>Branch</th>
                        <th colSpan={6}>Vendor(s)</th>
                    </tr>
                </thead>
            )}
            <tbody>
                {[...outlets].map(([outlet, vendors]: any) => (
                    <React.Fragment key={outlet}>
                        <tr>
                            <th colSpan={1} rowSpan={vendors.length + 1}>
                                {outlet}
                            </th>
                        </tr>

                        {vendors
                            .sort((a, b) => (a.vendorNickname.toLowerCase() > b.vendorNickname.toLowerCase() ? 1 : -1))
                            .map((vendor) => (
                                <tr key={vendor.vendorNickname}>
                                    <td colSpan={6}>
                                        <p>{vendor.vendorNickname}</p>
                                    </td>
                                </tr>
                            ))}
                    </React.Fragment>
                ))}
            </tbody>
        </>
    );
}
