import * as React from 'react';
import { useEffect, useState } from 'react';
import { getVendorCount } from '../apiClient';

export function ExportFeatureDescription() {
    const [count, setCount] = useState(0);
    useEffect(() => {
        getVendorCount()
            .then((res) => {
                setCount(res);
            })
            .catch((v) => v);
    }, []);
    return (
        <div>
            <p>{count} Vendors are listed.</p>
            <p>Please select the information you would like to download per Vendor:</p>
        </div>
    );
}
