import * as React from 'react';
import ActivityLogExportPage, { ActivityLogPageType } from './ActivityLogExport';
import { ActionCreators, ActivityType } from '../managers/managers';

const activities = [
    { key: ActivityType.login, header: 'Logging in' },
    { key: ActivityType.logout, header: 'Logging out' },
    { key: ActivityType.passwordChange, header: 'Password Changes' },
    { key: ActivityType.otpSecretChange, header: 'MFA Secret Changes' },
    { key: ActivityType.managerDetails, header: 'This User Data Changes' },
    {
        key: ActivityType.entityUpdatePark,
        header: 'Updates Parked for Managers',
    },
    {
        key: ActivityType.entityUpdatePost,
        header: 'Updates Posted for Managers',
    },
    { key: ActivityType.userParked, header: 'User Parked' },
    { key: ActivityType.userPost, header: 'User Posted' },
    { key: ActivityType.phoneNumberChange, header: 'Phone Number Changes' },
];

interface Props {
    params: { managerId: string };
}

const selectedOptions = activities.map((activity) => activity.key);
export default function ManagerLogExport(props: Props) {
    return (
        <ActivityLogExportPage
            activities={activities}
            entityId={props.params.managerId}
            entityType={ActivityLogPageType.Manager}
            exportActivityLog={ActionCreators.exportActivityLog}
            selectedOptions={selectedOptions}
        />
    );
}
