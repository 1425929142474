import styled from 'styled-components';

export const ProductUpdatePageContainer = styled.section`
    padding: 1rem 0;
    table {
        td,
        th {
            padding-right: 1rem;
        }

        button[type='button'] {
            all: unset;
            cursor: pointer;
            &:hover {
                svg {
                    opacity: 0.9;
                }
            }
            svg {
                width: 24px;
                fill: red;
                opacity: 0.5;
                will-change: opacity;
                transition: opacity ease-in 135ms;
            }
        }

        label {
            width: auto;
            margin: 0;
            text-align: center;
            input[type='checkbox'] {
                margin: 0;
            }
        }
        tr.isChanged {
            color: #ffc600;
        }

        tr.markedForRemoval {
            color: red;
            button {
                svg {
                    fill: #2a93fc;
                }
            }
            input {
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
            }
        }

        tr.isNew {
            color: green;
        }

        tr.hasError .error-message {
            color: red;
            margin: 0;
        }
        .actions {
            display: flex;
            gap: 1.5rem;
        }

        .add-product td {
            padding-top: 1rem;
        }
    }
`;

export const ButtonContainer = styled.section`
    margin-top: 1rem;
    display: flex;
    padding-bottom: 1rem;
    justify-content: center;
`;
