import * as React from 'react';
import Switch from 'rc-switch';
import BreadCrumbs from '../../utils/BreadCrumbs';
import { Location, LocationStatus } from './locations';
import { Form } from '../../utils/inputs';
import { Button, Status, TextField, withLocationDetailsContainer } from './utils/locationDetailsUtilities';
import ErrorPage from '../../app/ErrorPage';

interface Props {
    details: Location;
    path: string;
    editingState: [boolean, () => void];
    activeState: [boolean, () => void];
    disabled: boolean;
    parkEntityUpdateRequest: (e: Event) => void;
    inputsState: [Location, () => void];
    hasEditPermission: boolean;
}

const inputs = [
    { name: 'location1', label: 'Location 1' },
    { name: 'location2', label: 'Location 2' },
];

function LocationDetailsPage({
    editingState,
    activeState,
    inputsState,
    disabled,
    details,
    path,
    parkEntityUpdateRequest,
    hasEditPermission,
}: Props) {
    const canEdit = Boolean(details.status === LocationStatus.parked || details.pendingUpdates);
    const [isEditing, toggleIsEditing] = editingState;
    const [isActive, toggleIsActive] = activeState;
    const [data, setData] = inputsState;
    if (!details.id) return <ErrorPage />;
    return (
        <>
            <BreadCrumbs path={path} />
            {details.id && (
                <>
                    <h3>
                        {details.location1}, {details.location2}
                    </h3>
                    {hasEditPermission && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Toggle edit</label>
                            <Switch checked={isEditing} disabled={canEdit} onChange={toggleIsEditing} />
                        </div>
                    )}
                    <Form className="wfp-form" onSubmit={parkEntityUpdateRequest}>
                        {inputs.map((input) => (
                            <TextField
                                isEditing={isEditing}
                                key={input.name}
                                label={input.label}
                                name={input.name}
                                onChange={setData}
                                value={data[input.name]}
                            />
                        ))}

                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Active</label>
                            <Switch checked={isActive} disabled={!isEditing} onChange={toggleIsActive} />
                        </div>
                        <Status details={details} />
                        {isEditing && <Button disabled={disabled}>Park changes</Button>}
                    </Form>
                </>
            )}
        </>
    );
}

export default withLocationDetailsContainer(LocationDetailsPage);
