import * as React from 'react';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useAssignDispatch } from '../../utils/hooks/use-assign-dispatch';
import { ActionCreators, EntityType, EntityUpdateRequest, UpdateStateListType } from '../taskAuthorizations';
import { useSelector } from 'react-redux';
import State from '../../app/store/state';

const context = createContext(null);

const actions = [
    ActionCreators.editEntityUpdateRequest,
    ActionCreators.loadEntityUpdateRequests,
    ActionCreators.confirmEntityUpdateRequest,
    ActionCreators.signEntityUpdateRequest,
    ActionCreators.parkEntityUpdateRequest,
    ActionCreators.rejectEntityUpdateRequest,
    ActionCreators.clearUpdateRequests,
] as const;

const PAGE_LIMIT = 15 as const;

interface Props {
    tab: EntityType;
}

type Actions = Record<MODAL | 'callback', () => void>;

export enum MODAL {
    REJECT = 'reject',
    VERIFY = 'verify',
    SIGN = 'sign',
}

export enum ACTION {
    CONFIRM = 'confirm',
    CANCEL = 'cancel',
}

const initialState = {
    modal: '',
    updateRequest: null,
};
type Action =
    | ACTION
    | { type: undefined; updateRequest: EntityUpdateRequest; modal: MODAL }
    | { type: ACTION.CONFIRM; comment?: string }
    | { type: ACTION };

const createReducer = ({ callback, ...sideEffects }: Actions) =>
    function reducer(state, action: Action) {
        const actionType = typeof action === 'string' ? action : action.type;
        switch (actionType) {
            case ACTION.CONFIRM:
                if (typeof action !== 'string' && 'comment' in action) {
                    sideEffects?.[state.modal]?.(state.updateRequest, action.comment, callback);
                } else {
                    sideEffects?.[state.modal]?.(state.updateRequest, callback);
                }

                return initialState;
            case ACTION.CANCEL:
                return initialState;
            default:
                if (typeof action === 'string') return state;
                return { ...action };
        }
    };

export function EntityUpdateRequestContext(props: PropsWithChildren<Props>) {
    const {
        loadEntityUpdateRequests,
        confirmEntityUpdateRequest,
        rejectEntityUpdateRequest,
        signEntityUpdateRequest,
        clearUpdateRequests,
    } = useAssignDispatch(...actions);
    const [loading, setLoading] = useState(true);
    const loadUpdateRequests = useCallback((activeListTypeTab: UpdateStateListType, page: number) => {
        setLoading(true);
        loadEntityUpdateRequests(props.tab, activeListTypeTab, page, PAGE_LIMIT).finally(() => setLoading(false));
    }, []);
    const activeListType = useSelector((state: State) => state.taskAuthorizations.activeListType);
    const manager = useSelector((state: State) => state.auth.manager);
    const modalStateReducer = useReducer(
        createReducer({
            [MODAL.SIGN]: signEntityUpdateRequest,
            [MODAL.VERIFY]: confirmEntityUpdateRequest,
            [MODAL.REJECT]: rejectEntityUpdateRequest,
            callback: () => loadUpdateRequests(activeListType, 1),
        }),
        initialState
    );
    useEffect(() => {
        loadUpdateRequests(activeListType, 1);
    }, [activeListType]);
    useEffect(() => clearUpdateRequests, []);

    const updateRequests = useSelector((state: State) => state.taskAuthorizations.entityUpdateRequests.items);
    return (
        <context.Provider
            value={{
                updateRequests,
                modalStateReducer,
                manager,
                loading,
                isShowingPendingUpdates: activeListType === UpdateStateListType.Requested,
            }}
        >
            {props.children}
        </context.Provider>
    );
}

export function useEntityUpdateRequests() {
    const ctx = useContext(context);
    if (!ctx) {
        throw new Error(`useEntityUpdateRequests hook has to be used within EntityUpdateRequestContext`);
    }
    return ctx;
}
