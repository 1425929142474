import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadDocument } from '../../apiClient';
import { errorAction } from '../../utils/notifications';
import { useToggle } from '../../utils/useToggle';
import { HttpStatus } from '@wfp-common/enums';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';
import { ALLOWED_FILE_EXTENSIONS } from '@wfp-common/constants/documents';

interface ReturnObject {
    values: {
        section: string;
        file: File;
        fileName: string;
    };
    uploading: boolean;
    handleChange(event: ChangeEvent<HTMLInputElement>): void;
    handleSubmit(event: FormEvent<HTMLFormElement>): void;
}

export function useDocumentUpload(): ReturnObject {
    const [values, setValues] = useState({
        section: '',
        file: null,
        fileName: '',
    });
    const [uploading, toggleUploading] = useToggle();
    const dispatch = useDispatch();
    const handleChange = useCallback((event) => {
        event.persist();
        if (event.target.type === 'file') {
            return setValues((state) => {
                const isChanged = Boolean(state.fileName && state.fileName !== state.file?.name);
                const fileName = isChanged ? state.fileName : event.target.files[0].name;
                return {
                    ...state,
                    file: event.target.files[0],
                    fileName,
                };
            });
        }
        setValues((state) => ({ ...state, [event.target.name]: event.target.value }));
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            toggleUploading(true);
            await uploadDocument(values);
            toggleUploading();
            dispatch(routerHelperActions.makeRedirect('/documents'));
        } catch (error) {
            toggleUploading(false);

            if (error.status === HttpStatus.unprocessable) {
                dispatch(
                    errorAction(
                        `Unsupported format of document. Allowed formats: ${Object.keys(ALLOWED_FILE_EXTENSIONS).join(
                            ', '
                        )}.`
                    )
                );
            } else if (error.status === HttpStatus.forbidden) {
                const errorResponse = await error.json();

                dispatch(errorAction(errorResponse.message));
            } else {
                dispatch(errorAction('Could not upload file'));
            }
        }
    };
    return {
        values,
        handleChange,
        handleSubmit,
        uploading,
    };
}
