export enum UserEntityType {
    manager = 'm',
    vendor = 'v',
    partner = 'p',
}

export enum Entities {
    'm' = 'manager',
    'v' = 'vendor',
    'p' = 'partner',
}

export enum UserStatuses {
    active = 'active',
    expired = 'expired',
    blocked = 'blocked',
    rejected = 'rejected',
    parked = 'parked',
    cancelled = 'cancelled',
}
