import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';

import { ActionCreators, Beneficiary, BeneficiaryStatuses } from './beneficiaries';
import { EditBeneficiaryCreators } from './edit/editBeneficiaryActionCreator';
import State from '../app/store/state';
import BeneficiariesList from './BeneficiariesList';
import FiltersComponent from '../utils/filters';
import { DateSelectFilter, Filter, SelectFilter, SelectOption, TextFilter } from '../utils/FilterTypes';
import PagingComponent, { PagedState } from '../utils/paging';
import FeatureButton from '../utils/FeatureButton';
import { AuthState, hasFeatureAccess } from '../login/auth';
import BeneficiarySearch from './BeneficiarySearch';
import { PredefinedDateRanges } from '../../utils/createPredefinedFilters';
import { FiltersCreators, FiltersTypes } from '../utils/filtersStore';
import { PendingActionsState } from '../utils/pedingActionsReducer';
import { countries } from '../utils/countries';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { startCase } from 'lodash';
import { defaultDateLimit } from '../utils/DateFilter';
import { ManagerPermission } from '../permission-profiles/permission';

const beneficiaryStatusOptions = BeneficiaryStatuses.allStatuses().map((s) => {
    return new SelectOption(startCase(s.toString()), s);
});

interface Props {
    auth: AuthState;
    beneficiaries: PagedState<Beneficiary>;
    filters: Filter[];
    loadBeneficiaries: (
        timezone: string,
        page: number,
        limit: number,
        filters?: Array<Filter>,
        sortOptions?: SORT_OPTIONS | {}
    ) => void;
    searchBeneficiary: (beneficiaryId: string) => void;
    saveFilters: (filters: Filter[], relatedTo: string) => void;
    pendingActions: PendingActionsState;
    locationToggle: boolean;
    timezone: string;
    accountTypes: string[];
    showRefugeeFilter: boolean;
}

interface LocalState {
    searchValue: string;
    sortingOptions?: SORT_OPTIONS | {};
    currentPage: number;
}

const defaultFilters = [
    new TextFilter('Beneficiary ID', 'beneficiaryId', ''),
    new TextFilter('Household ID', 'householdId', ''),
    new DateSelectFilter('Created at', 'createdAt', '', PredefinedDateRanges, null, defaultDateLimit),
    new DateSelectFilter('Last Activity at', 'lastActiveAt', '', PredefinedDateRanges, null, defaultDateLimit),
    new DateSelectFilter('Last Transaction at', 'lastTransactionAt', '', PredefinedDateRanges, null, defaultDateLimit),
    new SelectFilter('Status', 'status', BeneficiaryStatuses.active, beneficiaryStatusOptions),
];

class BeneficiariesPage extends React.Component<Props, LocalState> {
    defaultPageSize = 25;

    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            sortingOptions: {},
            currentPage: 1,
        };
    }

    UNSAFE_componentWillMount() {
        this.props.loadBeneficiaries(
            this.props.timezone,
            1,
            this.defaultPageSize,
            this.props.filters,
            this.state.sortingOptions
        );
        if (!this.props.showRefugeeFilter) {
            const newFilters = this.props.filters.filter((filter) => filter.name !== 'accountType');
            this.props.saveFilters(newFilters, FiltersTypes.beneficiaries);
        }
    }

    private changePageRequested(newPage: number) {
        this.props.loadBeneficiaries(
            this.props.timezone,
            newPage,
            this.defaultPageSize,
            this.props.filters,
            this.state.sortingOptions
        );
        this.setState({ currentPage: newPage });
    }

    private applyFilters(filters: Filter[]) {
        this.props.loadBeneficiaries(this.props.timezone, 1, this.defaultPageSize, filters, this.state.sortingOptions);
        this.props.saveFilters(filters, FiltersTypes.beneficiaries);
        this.setState({ currentPage: 1 });
    }

    private applySorting = (sortingOptions) => {
        this.props.loadBeneficiaries(
            this.props.timezone,
            this.state.currentPage,
            this.defaultPageSize,
            this.props.filters,
            sortingOptions
        );
        this.setState({ sortingOptions });
    };

    render() {
        const { auth, beneficiaries, pendingActions } = this.props;
        let statusFilter: Filter;
        if (this.props.filters) {
            statusFilter = this.props.filters.find((filter) => filter.name === 'status');
        }
        const locationCounter = pendingActions.locationsPost || '';
        const uploadCounter =
            pendingActions.beneficiaries && pendingActions.beneficiaries.upload
                ? pendingActions.beneficiaries.upload
                : 0;
        const cancelImportCounter =
            pendingActions.beneficiaries && pendingActions.beneficiaries.cancelImport
                ? pendingActions.beneficiaries.cancelImport
                : 0;
        const importCounter = uploadCounter + cancelImportCounter > 0 ? uploadCounter + cancelImportCounter : '';
        const unblockCounter =
            pendingActions.beneficiaries && pendingActions.beneficiaries.unblock
                ? pendingActions.beneficiaries.unblock
                : '';
        const blockCounter =
            pendingActions.beneficiaries && pendingActions.beneficiaries.block
                ? pendingActions.beneficiaries.block
                : '';
        const zeroCounter =
            pendingActions.beneficiaries && pendingActions.beneficiaries.zero ? pendingActions.beneficiaries.zero : '';
        const alternativeCollectorImportCounter =
            pendingActions.beneficiaries && pendingActions.beneficiaries.alternativeCollectorImport
                ? pendingActions.beneficiaries.alternativeCollectorImport
                : '';
        const removeAlternativeCollectorImportCounter =
            pendingActions.beneficiaries && pendingActions.beneficiaries.removeAlternativeCollectorImport
                ? pendingActions.beneficiaries.removeAlternativeCollectorImport
                : '';

        const hasBeneficiaryPinsPermission = hasFeatureAccess(
            this.props.auth,
            ManagerPermission.beneficiariesPinManagement
        );
        return (
            <main>
                {beneficiaries && (
                    <div>
                        <nav className="wfp-breadcrumbs">
                            <ol className="breadcrumbs--wrapper">
                                <li className="breadcrumbs--item">
                                    <Link className="breadcrumbs--link" to="/home">
                                        <span>Home</span>
                                    </Link>
                                </li>
                                <li className="breadcrumbs--item">
                                    <span className="breadcrumbs--last">Beneficiaries</span>
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <h3 className="col-sm-2">Beneficiaries</h3>
                            <div className="col-sm-10 tr">
                                {beneficiaries && beneficiaries.items.length > 0 && (
                                    <FeatureButton
                                        linkTo={'/beneficiaries/download'}
                                        manager={auth.manager}
                                        restrictedToFeatures={[ManagerPermission.beneficiariesDownload]}
                                        title={'Download'}
                                    />
                                )}
                                {hasBeneficiaryPinsPermission && beneficiaries.items.length > 0 && (
                                    <FeatureButton
                                        linkTo={'/beneficiaries/export-pins'}
                                        manager={auth.manager}
                                        restrictedToFeatures={[ManagerPermission.beneficiariesRead]}
                                        title={`Export Pins`}
                                    />
                                )}
                                {this.props.locationToggle && (
                                    <FeatureButton
                                        counter={`${locationCounter}`}
                                        linkTo="/beneficiaries/locations"
                                        manager={auth.manager}
                                        restrictedToFeatures={[ManagerPermission.beneficiariesRead]}
                                        title="Locations"
                                    />
                                )}
                                <FeatureButton
                                    linkTo={'/beneficiaries/mapping'}
                                    manager={auth.manager}
                                    restrictedToFeatures={[ManagerPermission.beneficiaryMappingImport]}
                                    title={`Mapping`}
                                />
                                <FeatureButton
                                    counter={`${importCounter}`}
                                    linkTo={'/beneficiaries/import'}
                                    manager={auth.manager}
                                    restrictedToFeatures={[ManagerPermission.beneficiariesRead]}
                                    title={`Import`}
                                />
                                <FeatureButton
                                    counter={`${unblockCounter}`}
                                    linkTo={'/beneficiaries/unblock'}
                                    manager={auth.manager}
                                    restrictedToFeatures={[ManagerPermission.beneficiariesRead]}
                                    title={`Unblock`}
                                />
                                <FeatureButton
                                    counter={`${blockCounter}`}
                                    linkTo={'/beneficiaries/block'}
                                    manager={auth.manager}
                                    restrictedToFeatures={[ManagerPermission.beneficiariesRead]}
                                    title={`Block`}
                                />
                                <FeatureButton
                                    counter={`${zeroCounter}`}
                                    linkTo={'/beneficiaries/zeroing'}
                                    manager={auth.manager}
                                    restrictedToFeatures={[ManagerPermission.beneficiariesRead]}
                                    title={`Zero`}
                                />
                                <FeatureButton
                                    counter={`${alternativeCollectorImportCounter}`}
                                    linkTo={'/beneficiaries/import/alternative-collectors'}
                                    manager={auth.manager}
                                    restrictedToFeatures={[ManagerPermission.beneficiariesRead]}
                                    title={`Add AC`}
                                />
                                <FeatureButton
                                    counter={`${removeAlternativeCollectorImportCounter}`}
                                    linkTo={'/beneficiaries/import/remove-alternative-collectors'}
                                    manager={auth.manager}
                                    restrictedToFeatures={[ManagerPermission.beneficiariesRead]}
                                    title={`Remove AC`}
                                />
                            </div>
                        </div>
                        <BeneficiarySearch search={this.props.searchBeneficiary.bind(this)} />
                        <FiltersComponent
                            defaultFilters={defaultFilters}
                            filters={this.props.filters}
                            onApplyFilters={this.applyFilters.bind(this)}
                            withApply={true}
                            withIsSelected={true}
                        />
                        {statusFilter && (
                            <BeneficiariesList
                                beneficiaries={beneficiaries.items}
                                defaultView={!statusFilter.isSelected}
                                onSort={this.applySorting}
                                status={statusFilter.value}
                            />
                        )}
                        <PagingComponent
                            onPageChanged={this.changePageRequested.bind(this)}
                            paging={this.props.beneficiaries.paging}
                        />
                    </div>
                )}
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        locationToggle: state.appConfig.location.isActive,
        beneficiaries: state.beneficiaries,
        auth: state.auth,
        pendingActions: state.pendingActions,
        filters: state.filters.find((x) => x.relatedTo === FiltersTypes.beneficiaries)
            ? state.filters.find((x) => x.relatedTo === FiltersTypes.beneficiaries).selectedFilters
            : defaultFilters,
        timezone: state.appConfig.timeZone,
        showRefugeeFilter: state.appConfig.country === countries.BANGLADESH,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadBeneficiaries: bindActionCreators(ActionCreators.loadBeneficiaries, dispatch),
        searchBeneficiary: bindActionCreators(EditBeneficiaryCreators.searchBeneficiary, dispatch),
        saveFilters: bindActionCreators(FiltersCreators.saveFilters, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiariesPage as React.ComponentClass);
