import * as React from 'react';
import { classNames } from '../../utils/utils';
import { UndoIcon } from '../../icons/Undo.svg';
import { ClearIcon } from '../../icons/Clear.svg';
import { ProductType } from '../types/product.type';
import { FieldState } from '../hooks/use-product-update/use-product-update.types';
import { ChangeEventHandler, FocusEventHandler, MouseEventHandler } from 'react';
interface Props {
    productName: string;
    values: ProductType & FieldState;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    setDecimal: FocusEventHandler<HTMLInputElement>;
    toggleCheckbox: ChangeEventHandler<HTMLInputElement>;
    toggleRemove: (productName: string, action: 'remove' | 'undo') => MouseEventHandler<HTMLButtonElement>;
}

const unlimitedMaxPrice =
    'A maximum price for this product has not been set. This means the vendor can charge as high a price as they want for this product. Are you sure you want this?';

export function ProductUpdateFormComponent({
    productName,
    values,
    handleChange,
    setDecimal,
    toggleCheckbox,
    toggleRemove,
}: Props) {
    const isMaxPriceUnlimited = values.maxPrice === '' ? unlimitedMaxPrice : '';
    return (
        <tr
            className={classNames({
                markedForRemoval: values.markedForRemoval,
                isNew: values.isNew,
                isChanged: values.edited,
                hasError: Boolean(values.error || isMaxPriceUnlimited),
            })}
            key={productName}
        >
            <td>
                <span>{productName}</span>
            </td>
            <td>
                <input name="minPrice" onBlur={setDecimal} onChange={handleChange} value={values.minPrice} />
            </td>
            <td>
                <input name="maxPrice" onBlur={setDecimal} onChange={handleChange} value={values.maxPrice} />
            </td>
            <td colSpan={2}>
                <label>
                    <input checked={values.isFixed} name="isFixed" onChange={toggleCheckbox} type="checkbox" />
                </label>
            </td>
            <td>
                {values.markedForRemoval ? (
                    <button
                        className="undo-remove"
                        onClick={toggleRemove(productName, 'undo')}
                        title="Undo Remove Product"
                        type="button"
                    >
                        <UndoIcon />
                    </button>
                ) : (
                    <button onClick={toggleRemove(productName, 'remove')} title="Remove Product" type="button">
                        <ClearIcon />
                    </button>
                )}
            </td>
            <td>
                <p className="error-message">
                    <small>{!values.markedForRemoval && (values.error || isMaxPriceUnlimited)}</small>
                </p>
            </td>
        </tr>
    );
}
