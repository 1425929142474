import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router';

import { ActionCreators, Transaction } from './transactions';
import State from '../app/store/state';
import { PagedState } from '../utils/paging';
import { DateSelectFilter, Filter, MultiSelectFilter, SelectOption, TextFilter } from '../utils/FilterTypes';
import FiltersComponent from '../utils/filters';
import { getCategories, TransactionRequestParams } from '../apiClient';
import TransactionsTable from '../utils/TransactionsTable';
import { ActionCreators as VendorActionCreators, defaultVendorSortingOrder, VendorsState } from '../vendors/vendors';
import {
    updateFiltersWithBranchCodes,
    updateFiltersWithCategories,
    updateFiltersWithNickNames,
} from '../utils/createEntityMultiSelect';
import {
    createBranchCodeFilter,
    createCategoryFilter,
    createPaymentStatusFilter,
    createTransactionStatusFilter,
    createTransactionTypeFilter,
    PredefinedDateRanges,
} from '../../utils/createPredefinedFilters';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { TransactionFiltersQuery, updateFiltersByUrl } from './updateFilterByUrlQuery';

import { FiltersCreators, FiltersTypes } from '../utils/filtersStore';
import { defaultManagerPanelTransactionHeaders } from '../utils/transactionTableUtils';
import { EntitlementCategoriesConfig, EntitlementCurrencyConfig } from '../app/appConfig';
import { countries } from '../utils/countries';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { defaultDateLimit } from '../utils/DateFilter';
import { errorAction } from '../utils/notifications';
import { ManagerPermission } from '../permission-profiles/permission';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

interface Props {
    vendors: VendorsState;
    transactions: PagedState<Transaction>;
    auth: AuthState;
    filters: Filter[];
    location: { query?: TransactionFiltersQuery };
    categories: EntitlementCategoriesConfig;
    currency: EntitlementCurrencyConfig;

    loadVendorsNickNames: (sortOptions: SORT_OPTIONS) => void;
    loadBranchCodes: () => void;
    loadTransactions: (
        timezone: string,
        page: number,
        limit: number,
        filters?: Array<Filter>,
        customParams?: TransactionRequestParams,
        sortOptions?: SORT_OPTIONS | {}
    ) => void;
    saveFilters: (filters: Filter[], relatedTo: string) => void;
    timezone: string;
    beneficiaryAccountType: string[];
    showRefugeeFilter: boolean;

    errorAction: (error: string) => void;
    redirect: (url: string) => void;
}

interface LocalState {
    categoryOptions: SelectOption[];
    defaultFilters: Filter[];
    currentPage: number;
    sortOptions: SORT_OPTIONS | {};
}

export class TransactionsPage extends React.Component<Props, LocalState> {
    defaultPageSize = 25;
    customTransactionsParams: TransactionRequestParams = {};

    constructor(props) {
        super(props);
        this.props.loadVendorsNickNames(defaultVendorSortingOrder);
        this.props.loadBranchCodes();

        // eslint-disable-next-line
        this.state = {
            categoryOptions: [],
            defaultFilters: this.props.filters,
            sortOptions: {},
            currentPage: 1,
        };
    }

    saveBranchCodes(branchCodes: Array<string>) {
        this.setState({
            defaultFilters: updateFiltersWithBranchCodes(branchCodes, this.state.defaultFilters, 'branchCode'),
        });
        let newFilters = updateFiltersWithBranchCodes(branchCodes, this.props.filters, 'branchCode');
        newFilters = updateFiltersByUrl(this.props.location.query, newFilters);
        this.props.saveFilters(newFilters, FiltersTypes.transactions);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.vendors.nickNameList !== this.props.vendors.nickNameList) {
            const nickameList = nextProps.vendors.nickNameList.filter((name) => name.authorizedAt);
            this.setState({
                defaultFilters: updateFiltersWithNickNames(nickameList, this.state.defaultFilters, 'vendorId'),
            });
            let newFilters = updateFiltersWithNickNames(nickameList, this.props.filters, 'vendorId');
            newFilters = updateFiltersByUrl(this.props.location.query, newFilters);
            this.props.saveFilters(newFilters, FiltersTypes.transactions);
            this.props.loadTransactions(
                this.props.timezone,
                1,
                this.defaultPageSize,
                this.props.filters,
                this.customTransactionsParams,
                this.state.sortOptions
            );
        }

        if (JSON.stringify(nextProps.vendors.branchCodes) !== JSON.stringify(this.props.vendors.branchCodes)) {
            this.saveBranchCodes(nextProps.vendors.branchCodes);
        }
    }

    async UNSAFE_componentWillMount() {
        let newFilters;
        if (this.props.categories.categories.length > 0) {
            newFilters = updateFiltersWithCategories(this.props.categories.categories, this.props.filters, 'category');
        } else {
            const categories = await getCategories();
            newFilters = updateFiltersWithCategories(categories, this.props.filters, 'category');
        }
        this.setState({ defaultFilters: newFilters });

        if (!this.props.showRefugeeFilter) {
            newFilters = newFilters.filter((filter) => filter.name !== 'beneficiaryAccountType');
        }
        this.props.saveFilters(newFilters, FiltersTypes.transactions);
        this.props.loadTransactions(
            this.props.timezone,
            1,
            this.defaultPageSize,
            this.props.filters,
            this.customTransactionsParams,
            this.state.sortOptions
        );
        this.setState({ currentPage: 1 });
    }

    private changePageRequested(newPage) {
        this.props.loadTransactions(
            this.props.timezone,
            newPage,
            this.defaultPageSize,
            this.props.filters,
            this.customTransactionsParams,
            this.state.sortOptions
        );
        this.setState({ currentPage: newPage });
    }

    private applyFilters(filters: Array<Filter>) {
        const transactionIdFilter = filters.find((f) => f.name === 'transactionId');
        if (transactionIdFilter?.isSelected && isNaN(parseInt(transactionIdFilter.value.trim()))) {
            this.props.errorAction('Transaction ID must be a number.');
            return;
        }

        this.props.loadTransactions(
            this.props.timezone,
            1,
            this.defaultPageSize,
            filters,
            this.customTransactionsParams,
            this.state.sortOptions
        );
        this.setState({ currentPage: 1 });
        this.props.saveFilters(filters, FiltersTypes.transactions);
    }

    private applySort = (sortOptions: SORT_OPTIONS) => {
        this.props.loadTransactions(
            this.props.timezone,
            this.state.currentPage,
            this.defaultPageSize,
            this.props.filters,
            this.customTransactionsParams,
            sortOptions
        );
        this.setState({ sortOptions });
    };

    render() {
        const { auth, transactions } = this.props;

        const filters = this.props.filters;
        const cleanFilters = this.state.defaultFilters.map((filter) => {
            if (filter.type === 'MULTISELECT') {
                return {
                    ...filter,
                    isSelected: false,
                    value: [],
                };
            } else {
                return {
                    ...filter,
                    isSelected: false,
                    value: '',
                };
            }
        });

        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Transactions</span>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <h3 className="col-sm-6">Transactions</h3>
                    <div className="col-sm-6 tr">
                        {hasFeatureAccess(auth, ManagerPermission.transactionsDownload) &&
                            transactions &&
                            transactions.items.length > 0 && (
                                <Link className="ml2" to="/transactions/download">
                                    <button className="wfp-btn" type="button">
                                        Download Transactions
                                    </button>
                                </Link>
                            )}
                    </div>
                </div>
                {this.props.transactions && (
                    <div>
                        <FiltersComponent
                            defaultFilters={cleanFilters}
                            filters={filters}
                            onApplyFilters={this.applyFilters.bind(this)}
                            withApply={true}
                            withIsSelected={true}
                            withTimeRangeLimit={true}
                        />
                        <TransactionsTable
                            currency={this.props.currency}
                            headers={defaultManagerPanelTransactionHeaders}
                            onChangePageRequested={this.changePageRequested.bind(this)}
                            onSort={this.applySort}
                            onTransactionChosen={(transaction) =>
                                this.props.redirect(`/transactions/${transaction.id}`)
                            }
                            transactions={this.props.transactions}
                        />
                    </div>
                )}
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        vendors: state.vendors,
        transactions: state.transactions,
        auth: state.auth,
        categories: state.appConfig.entitlementsConfig,
        currency: state.appConfig.entitlementCurrencyConfig,
        filters: state.filters.find((x) => x.relatedTo === FiltersTypes.transactions)
            ? state.filters.find((x) => x.relatedTo === FiltersTypes.transactions).selectedFilters
            : [
                  new TextFilter('Transaction ID', 'transactionId', ''),
                  new TextFilter('Beneficiary ID', 'beneficiaryId', ''),
                  new MultiSelectFilter('Vendor Nickname', 'vendorId', []),
                  createTransactionStatusFilter(),
                  createTransactionTypeFilter(),
                  createPaymentStatusFilter(),
                  new DateSelectFilter('Date', 'createdAt', '', PredefinedDateRanges, null, defaultDateLimit),
                  createBranchCodeFilter(),
                  createCategoryFilter(),
              ],
        timezone: state.appConfig.timeZone,
        showRefugeeFilter: state.appConfig.country === countries.BANGLADESH,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        redirect: bindActionCreators(routerHelperActions.makeRedirect, dispatch),
        errorAction: bindActionCreators(errorAction, dispatch),
        loadTransactions: bindActionCreators(ActionCreators.loadTransactions, dispatch),
        loadVendorsNickNames: bindActionCreators(VendorActionCreators.loadVendorsNickNames, dispatch),
        loadBranchCodes: bindActionCreators(VendorActionCreators.loadVendorBranchCodes, dispatch),
        saveFilters: bindActionCreators(FiltersCreators.saveFilters, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage as any);
