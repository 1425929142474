import { useCallback, useState } from 'react';
import { defaultVendorSortingOrder, Vendor } from '../vendors';
import State from '../../app/store/state';
import { useSelector } from 'react-redux';
import { DIRECTION_OPTIONS, SORT_OPTIONS } from '../../utils/hooks/useSort';
import {useNavigate} from "@wfp-common/hooks/useNavigate";

export function useVendorsList(managerId: string) {
    const navigate = useNavigate();
    const showAnnotation = false;
    const vendorCreditsMessage = 'Pending adjustments are not included';
    const vendors = useSelector((state: State) => state.vendors.list);
    const thisManagerParked = useCallback(
        (vendor: Vendor) => vendor && vendor.createdByManagerId && vendor.createdByManagerId === managerId,
        [managerId]
    );

    const [queryOptions, setQueryOptions] = useState<SORT_OPTIONS>(defaultVendorSortingOrder);

    const shouldDisplayAnnotation = useCallback(
        (vendors: Array<Vendor>) =>
            vendors.some((vendor) => !vendor.authorizedAt && !vendor.cancelledByManagerId && thisManagerParked(vendor)),
        [thisManagerParked]
    );

    const _onVendorChosen = useCallback((vendor: Vendor) => {
        navigate('/vendors/' + vendor.id);
    }, []);

    const vendorMissingMessage = useCallback(
        (vendor: Vendor): { message: string; style: string } =>
            vendor.authorizedAt
                ? { message: 'Vendor Missing', style: 'negative-color' }
                : { message: 'Vendor Not Posted', style: 'attention-color' },
        []
    );
    const trClassName = useCallback(
        (vendor) =>
            'cursor-pointer ' +
            (vendor.pendingEntityUpdates.length > 0 || vendor.isPasswordBlocked ? 'attention-color' : ''),
        []
    );
    const handleSort = ([column, direction]: [string, DIRECTION_OPTIONS]) => {
        if (column) {
            setQueryOptions({
                'orderBy:column': columnToAttributeMapping[column],
                'orderBy:direction': direction,
            });
        } else {
            setQueryOptions(defaultVendorSortingOrder);
        }
    };

    return {
        showAnnotation,
        vendorCreditsMessage,
        thisManagerParked,
        shouldDisplayAnnotation,
        _onVendorChosen,
        vendorMissingMessage,
        trClassName,
        handleSort,
        vendors,
        queryOptions,
    };
}

const columnToAttributeMapping = {
    'WFP Vendor ID': 'sapId',
    Nickname: 'nickName',
    'Credits Earned': 'entitlements',
};
