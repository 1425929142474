import * as React from 'react';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Switch from 'rc-switch';

import State from '../app/store/state';
import { ActionCreators, ActionCreators as ManagerActionCreators, ExtendedManager, ManagerNickName } from './managers';
import ManagerDetails from './ManagerDetails';
import { ExternalValidationError } from '../utils/inputs';
import { AuthState, hasFeatureAccess } from '../login/auth';
import Manager from '../managers/manager';
import { InformationDialog } from '../utils/Dialogs';
import { EntityType } from '../authorization/taskAuthorizations';
import { getManagerActivityLogs } from '../apiClient';
import { ActivityLogsList } from '../activityLogs/ActivityLogsList';
import { PagedState } from '../utils/paging';
import { SideMenu, SideMenuProps } from '../utils/SideMenu';
import { ActionCreators as VendorActionCreators, VendorNickName, VendorUserName } from '../vendors/vendors';
import { ManagerPermissionsFiles } from './ManagerPermissionsFiles';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { ManagerPermission } from '../permission-profiles/permission';
import { ActionCreators as PartnerActionCreators } from '../partners/partnersActions';
import { PartnerName, PartnerUserName } from '../partners/partners';
import { withNavigate } from '@wfp-common/hooks/withNavigate';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';

interface OwnProps {
    params: { managerId: string };
}

enum ManagerTab {
    GeneralInformation = 'General Information',
    DownloadUser = 'Download User',
    ActivityLogs = 'Activity Logs',
    PermissionsFiles = 'Permissions Files',
}

function EditManagerSideMenu(props: SideMenuProps<ManagerTab>) {
    return SideMenu(props);
}

interface Props extends OwnProps {
    getManagers: () => void;
    editManager: (prevVersion: Manager, manager: ExtendedManager) => void;
    createManager: (manager: any) => void;
    resetPasswordRequest: (manager: ExtendedManager) => void;
    resetOtpRequest: (manager: ExtendedManager) => void;
    resetOtpPasswordRequest: (manager: ExtendedManager) => void;
    getBasicActivityLog: (managerId: string) => void;
    authorizeManager: (managerId: string) => void;
    cancelPark: (id: string) => void;
    loadManagersNickNames: () => void;
    loadVendorsNickNames: (sortOptions: SORT_OPTIONS) => void;
    loadVendorUsersNames: (sortOptions: SORT_OPTIONS) => void;
    validationError?: ExternalValidationError;
    manager: ExtendedManager;
    auth: AuthState;
    managerNickNameList: Array<ManagerNickName>;
    vendorsNickNames: Array<VendorNickName>;
    vendorUsersNames: Array<VendorUserName>;
    partnersNickNames: Array<PartnerName>;
    partnerUsersNickNames: Array<PartnerUserName>;
    loadPartnersNames: (sortOptions: SORT_OPTIONS) => void;
    loadPartnerUserNames: (sortOptions: SORT_OPTIONS) => void;
    navigate: NavigateHook;
}

interface EditPageState {
    isEditEnabled: boolean;
    hasPendingDetailsUpdate: boolean;
    cannotParkedThemSelf: boolean;
    activeTab: ManagerTab;
    activityLogs: PagedState<any>;
}

class ManagerEditPage extends React.Component<Props, EditPageState> {
    UNSAFE_componentWillMount() {
        this.props.getManagers();
    }

    constructor(props) {
        super(props);
        this.state = this.createDefaultState();
        this.toggleEdit = this.toggleEdit.bind(this);
    }

    createDefaultState() {
        const editEnabled = this.isCreateManagerPage();
        return {
            isEditEnabled: editEnabled,
            hasPendingDetailsUpdate: false,
            cannotParkedThemSelf: false,
            activeTab: ManagerTab.GeneralInformation,
            activityLogs: new PagedState<any>(),
            isParkOtpDialogVisible: false,
        };
    }

    isCreateManagerPage() {
        return this.props.params.managerId === 'new';
    }

    toggleEdit(checked) {
        const { manager, auth } = this.props;
        let hasPendingDetailsUpdate = manager.authorizedAt ? false : checked;
        const cannotParkedThemSelf = manager.id !== auth.manager.id || auth.manager.id === '1' ? false : checked;

        if (manager) {
            const hasManagerDetailsEntity = manager.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.managerDetails
            );
            hasPendingDetailsUpdate = hasManagerDetailsEntity.length !== 0 || hasPendingDetailsUpdate;
        }
        if (cannotParkedThemSelf) {
            this.setState({ cannotParkedThemSelf });
        } else if (hasPendingDetailsUpdate) {
            this.setState({ hasPendingDetailsUpdate });
        } else {
            this.setState({ isEditEnabled: checked });
        }
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="User details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending Updates"
            />
        );
    }

    renderCannotParkedDialog() {
        return (
            <InformationDialog
                message="User details cannot be edited by yourself"
                onClose={() => this.setState({ cannotParkedThemSelf: false })}
                title="Forbidden Access"
            />
        );
    }

    renderGeneralInformation() {
        const { isEditEnabled, hasPendingDetailsUpdate, cannotParkedThemSelf } = this.state;
        const isEditSwitchVisible = !this.isCreateManagerPage() && this.hasWritePermission();
        const thisManagerParked = this.props.auth.manager ? this.thisManagerParked(this.props.auth.manager.id) : true;
        const postEnabled = hasFeatureAccess(this.props.auth, ManagerPermission.managersManagementPost);
        return (
            <div>
                <h6>General Information</h6>
                <div className={'row'}>
                    {isEditSwitchVisible && <label className="col-sm-4 ta-right">Toggle Edit</label>}
                    {isEditSwitchVisible && (
                        <div className="col-sm-8 pv1">
                            {hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}
                            {cannotParkedThemSelf && this.renderCannotParkedDialog()}
                            {
                                <Switch
                                    checked={isEditEnabled}
                                    disabled={!this.props.manager.authorizedAt}
                                    onClick={this.toggleEdit}
                                />
                            }
                        </div>
                    )}
                </div>
                {this.props.auth && this.props.auth.manager && (
                    <ManagerDetails
                        authState={this.props.auth}
                        authorizeManager={this.props.authorizeManager.bind(this)}
                        cancelPark={this.props.cancelPark.bind(this)}
                        editDisabled={!isEditEnabled}
                        manager={this.props.manager}
                        managerId={this.props.auth.manager.id}
                        onSaved={this.saveManager.bind(this)}
                        postEnabled={postEnabled}
                        resetOtpPasswordRequest={this.props.resetOtpPasswordRequest}
                        resetOtpRequest={this.props.resetOtpRequest}
                        resetPasswordRequest={this.props.resetPasswordRequest}
                        thisManagerParked={thisManagerParked}
                        validationError={this.props.validationError}
                    />
                )}
            </div>
        );
    }

    saveManager(manager) {
        if (this.isCreateManagerPage()) {
            this.props.createManager(manager);
        } else {
            this.props.editManager(this.props.manager, manager);
        }
    }

    loadActivityLogs(page, limit) {
        const managerId = this.props.params.managerId;
        return getManagerActivityLogs(managerId, page, limit).then((result) => {
            this.setState({ activityLogs: result });
        });
    }

    renderActivityLogs() {
        return (
            <div>
                <ActivityLogsList
                    activityLogs={this.state.activityLogs}
                    auth={this.props.auth}
                    downloadRestriction={[ManagerPermission.userActivityDownload]}
                    entityId={this.props.manager.id}
                    isManager={true}
                    loadActivityLogs={this.loadActivityLogs.bind(this)}
                    loadManagersNickNames={this.props.loadManagersNickNames}
                    loadPartnerUserNames={this.props.loadPartnerUserNames}
                    loadPartnersNames={this.props.loadPartnersNames}
                    loadVendorUsersNames={this.props.loadVendorUsersNames}
                    loadVendorsNickNames={this.props.loadVendorsNickNames}
                    managersNickNames={this.props.managerNickNameList}
                    partnerUsersNickNames={this.props.partnerUsersNickNames}
                    partnersNickNames={this.props.partnersNickNames}
                    url={`/admin/${this.props.manager.id}/export-activity-log`}
                    vendorUsersNames={this.props.vendorUsersNames}
                    vendorsNickNames={this.props.vendorsNickNames}
                />
            </div>
        );
    }

    hasWritePermission() {
        return hasFeatureAccess(this.props.auth, ManagerPermission.managersManagementPark);
    }

    thisManagerParked(parkedByManagerId) {
        return (
            this.props.manager &&
            this.props.manager.createdByManager &&
            parkedByManagerId === this.props.manager.createdByManager.id
        );
    }

    renderSidemenu() {
        const subtabs = [ManagerTab.GeneralInformation];
        if (hasFeatureAccess(this.props.auth, ManagerPermission.userActivityView)) {
            subtabs.push(ManagerTab.ActivityLogs);
        }
        if (hasFeatureAccess(this.props.auth, ManagerPermission.userDownload)) {
            subtabs.push(ManagerTab.DownloadUser);
        }
        if (hasFeatureAccess(this.props.auth, ManagerPermission.userDownload)) {
            subtabs.push(ManagerTab.PermissionsFiles);
        }
        return (
            <div>
                <EditManagerSideMenu
                    activeTab={this.state.activeTab}
                    onSelectTab={(activeTab) => {
                        this.setState({ activeTab });
                    }}
                    tabs={subtabs}
                />
                {/*<FeatureButton title={'Download User'} linkTo={'/admin/download'} manager={this.props.auth.manager}*/}
                {/*restrictedToFeatures={[ManagerPermission.userDownload]}*/}
                {/*/>*/}
            </div>
        );
    }

    goToDownload() {
        browserHistory.push('/admin/download?id=' + this.props.manager.id);
    }

    renderPermissionsFiles = () => {
        return <ManagerPermissionsFiles manager={this.props.manager} />;
    };

    render() {
        const { activeTab } = this.state;
        // const { auth } = this.props;
        // const withActivityLogTab = hasFeatureAccess(
        //     auth,
        //     ManagerPermission.userActivityView
        // );
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/admin">
                                <span>Admin</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">
                                {this.props.manager.id
                                    ? `Edit Admin "${this.props.manager.firstName} ${this.props.manager.lastName}"`
                                    : 'Add User'}
                            </span>
                        </li>
                    </ol>
                </nav>

                <div className={'row'}>
                    {this.props.manager.id && (
                        <div className="col-sm-3">
                            <h5 style={{ marginTop: 0, marginBottom: 0 }}>
                                {this.props.manager.firstName} {this.props.manager.lastName}
                            </h5>
                            {this.renderSidemenu()}
                        </div>
                    )}
                    <div className="col-sm-9" style={{ marginBottom: 150 }}>
                        <h3>{!this.props.manager.id ? 'Add User' : ''}</h3>
                        {activeTab === ManagerTab.GeneralInformation && this.renderGeneralInformation()}
                        {activeTab === ManagerTab.ActivityLogs && this.renderActivityLogs()}
                        {activeTab === ManagerTab.DownloadUser && this.goToDownload()}
                        {activeTab === ManagerTab.PermissionsFiles && this.renderPermissionsFiles()}
                    </div>
                </div>
                {/*{!withActivityLogTab &&  <div>*/}
                {/*<h3 style={{ marginTop: 0, marginBottom: 0 }}>{this.props.manager.firstName} {this.props.manager.lastName}</h3>*/}
                {/*<h3>{!this.props.manager.id ? 'Add User' : ''}</h3>*/}
                {/*{this.renderGeneralInformation()}*/}
                {/*</div>}*/}
            </main>
        );
    }
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        manager:
            state.managers.items.find((manager) => manager.id.toString() === ownProps.params.managerId) ||
            new ExtendedManager(),
        validationError: state.managers.validationError,
        auth: state.auth,
        managerNickNameList: state.managers.managerNickNameList,
        vendorsNickNames: state.vendors.nickNameList,
        vendorUsersNames: state.vendors.usersNames,
        partnersNickNames: state.partners.nameList,
        partnerUsersNickNames: state.partners.partnerUsersNamesList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getManagers: bindActionCreators(ActionCreators.getManagers, dispatch),
        editManager: bindActionCreators(ActionCreators.editManager, dispatch),
        createManager: bindActionCreators(ActionCreators.createManager, dispatch),
        resetPasswordRequest: bindActionCreators(ActionCreators.resetPasswordRequest, dispatch),
        resetOtpRequest: bindActionCreators(ActionCreators.resetOtpRequest, dispatch),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        loadVendorsNickNames: bindActionCreators(VendorActionCreators.loadVendorsNickNames, dispatch),
        loadVendorUsersNames: bindActionCreators(VendorActionCreators.loadVendorUsersNames, dispatch),
        loadPartnersNames: bindActionCreators(PartnerActionCreators.loadPartnersNames, dispatch),
        loadPartnerUserNames: bindActionCreators(PartnerActionCreators.loadPartnerUsersName, dispatch),
        authorizeManager: bindActionCreators(ActionCreators.authorizeManager, dispatch),
        cancelPark: bindActionCreators(ActionCreators.cancelPark, dispatch),
        resetOtpPasswordRequest: bindActionCreators(ActionCreators.resetOtpPasswordRequest, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigate(ManagerEditPage as React.ComponentClass<Props | any, EditPageState>, 'ManagerEditPage'));
