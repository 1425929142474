import * as React from 'react';

import FiltersComponent from '../../utils/filters';
import { DateSelectFilter, MultiSelectFilter } from '../../utils/FilterTypes';
import { PredefinedDateRanges } from '../../../utils/createPredefinedFilters';
import { useMobilizationStatistics } from './helpers';
import { MobilizationStatisticsTable } from './MobilizationStatisticsTable';
import * as moment from 'moment';
import { defaultDateLimit } from '../../utils/DateFilter';

const defaultFilters = [
    new MultiSelectFilter('Vendor Nickname', 'vendorId', ''),
    new MultiSelectFilter('Outlet', 'branch', ''),
    new MultiSelectFilter('Category', 'category', [], []),
    new DateSelectFilter(
        'TX Period',
        'createdAt',
        '',
        PredefinedDateRanges,
        {
            startDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate(),
        },
        defaultDateLimit,
        true
    ),
];
interface Props {
    branchCodes?: any[];
    categories?: any;
    fetchToday: (data: any) => Promise<any>;
    fetchRange: (data: any) => Promise<any>;
    fetchSyncDate: (data: any) => Promise<any>;
    vendors?: any[];
}
export const MobilizationStatistics: React.FC<Props> = React.memo(function MobilizationStatistics({
    fetchToday,
    fetchRange,
    fetchSyncDate,
    vendors,
    categories,
    branchCodes,
}) {
    const { filters, applyFilters, refreshTodayResults, todayData, filteredData, syncDate } = useMobilizationStatistics(
        defaultFilters,
        fetchToday,
        fetchRange,
        fetchSyncDate,
        vendors,
        categories,
        branchCodes
    );

    return (
        <>
            <FiltersComponent
                defaultFilters={defaultFilters}
                disableRemove={true}
                extraButtons={[
                    {
                        className: 'wfp-btn ml3',
                        onClick: refreshTodayResults,
                        name: 'Refresh Results',
                    },
                ]}
                filters={filters}
                onApplyFilters={applyFilters}
                withApply={true}
                withIsSelected={true}
                withTimeRangeLimit={true}
            />
            <p style={{ margin: 0, textAlign: 'right' }}>
                <em>
                    <small>Statistics data as of {syncDate}</small>
                </em>
            </p>
            <div className="row">
                <MobilizationStatisticsTable filteredData={filteredData} todayData={todayData} />
            </div>
        </>
    );
});
