import * as React from 'react';
import { Modal } from 'react-bootstrap';

import { UploadStatuses, UploadStatusWidget } from '../../utils/import';
import { UploadTaskState } from '../beneficiaries';
import { getAsyncTask } from '../../apiClient';
import { AsyncTask, AsyncTasksTypes } from '../../utils/asyncTasks';

export interface DryRunDialogProps {
    title: string;
    asyncTask?: AsyncTask;
    taskStateError: string;

    onCancel: () => void;
    onConfirm: (dryRunAsyncTaskId: string) => void;
    downloadFile?: (type: string, id: string) => void;
}

export interface DryRunDialogState {
    taskState: UploadTaskState;
    refreshHandle?: number;
    asyncTask?: AsyncTask;
}

export default class DryRunDialog extends React.Component<DryRunDialogProps, DryRunDialogState> {
    constructor(props: DryRunDialogProps) {
        super(props);

        this.state = {
            taskState: new UploadTaskState(UploadStatuses.pending),
            asyncTask: null,
        };
    }

    componentDidMount() {
        this.setState({
            refreshHandle: window.setInterval(this.loadTaskState, 2000),
        });
    }

    componentWillUnmount() {
        if (this.state.refreshHandle) {
            clearInterval(this.state.refreshHandle);
        }
    }

    private loadTaskState = async () => {
        if (this.props.taskStateError) {
            this.setState({
                taskState: new UploadTaskState(UploadStatuses.finished, null, this.props.taskStateError),
            });
        } else if (this.props.asyncTask) {
            const response = await getAsyncTask(this.props.asyncTask.type, this.props.asyncTask.id);
            const taskStatus =
                response.task.finishedAt && response.task.children && response.task.children.length > 0
                    ? UploadStatuses.finished
                    : UploadStatuses.pending;

            const taskState = new UploadTaskState(taskStatus, response.task);
            this.setState({
                taskState,
                asyncTask: response.task,
            });
            if (taskStatus !== UploadStatuses.pending) {
                clearInterval(this.state.refreshHandle);
                this.setState({
                    refreshHandle: undefined,
                });
            } else {
                this.setState({ taskState });
            }
        }
    };

    render() {
        const { title, onCancel, onConfirm, downloadFile } = this.props;
        const { taskState } = this.state;
        const errors = (taskState.task && taskState.task.errors) || [];
        const warnings = (taskState.task && taskState.task.warnings) || [];

        return (
            <div>
                <div className="fade modal-backdrop in" />
                <Modal.Dialog style={{ overflow: 'auto' }}>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UploadStatusWidget
                            downloadFile={downloadFile}
                            importError={taskState.error}
                            importStatus={taskState.status}
                            importTask={taskState.task}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {!!(errors.length || warnings.length) && (
                            <p className="modal-label">The link above provided the validation results.</p>
                        )}
                        <button className="wfp-btn" onClick={onCancel} style={{ marginRight: 10 }} type="button">
                            Cancel
                        </button>
                        <button
                            className="wfp-btn--primary"
                            disabled={this.shouldRunButtonBeDisabled(taskState)}
                            onClick={() => onConfirm(taskState.task.id)}
                            type="button"
                        >
                            Run import
                        </button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        );
    }

    shouldRunButtonBeDisabled(taskState: UploadTaskState): boolean {
        if (taskState.status !== UploadStatuses.finished) {
            return true;
        }
        if (!taskState.task) {
            return true;
        } else if (
            taskState.error ||
            (this.state.asyncTask &&
                this.state.asyncTask.type !== AsyncTasksTypes.IMPORT_BENEFICIARIES &&
                this.state.asyncTask.type !== AsyncTasksTypes.IMPORT_BENEFICIARIES_MAPPING &&
                taskState.task.dryRunErrors.length > 0)
        ) {
            return true;
        } else if (this.state.asyncTask && this.state.asyncTask.errors && this.state.asyncTask.errors.length > 0) {
            return true;
        }
        return false;
    }
}
