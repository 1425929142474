import { useEffect } from 'react';

export function useDisableGlobalScrollHook(disableScroll: boolean) {
    useEffect(() => {
        if (disableScroll) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.removeAttribute('style');
        }
        return () => {
            document.body.removeAttribute('style');
        };
    }, [disableScroll]);
}
