import { Filter, MultiSelectFilter, SelectOption } from '../utils/FilterTypes';
import { OperativeActivityType, OperativeActivityTypes } from '../activityLogs/activityLogs';
import { separateCamelCase, wfpFormat } from '../utils/utils';

export function createActivityTypeFilter(activityTypes: OperativeActivityType[]): Filter {
    const activityTypeSelectOptionsMap = new Map<OperativeActivityType, SelectOption>();
    OperativeActivityTypes.all().forEach((activityType) => {
        activityTypeSelectOptionsMap.set(
            activityType,
            new SelectOption(separateCamelCase(activityType).replace(/-/g, ' '), activityType)
        );
    });

    const value = activityTypes.map((type) => activityTypeSelectOptionsMap.get(type));
    const options = Array.from(activityTypeSelectOptionsMap.entries()).map(([optionName, option]) => {
        let activityTypeName = '';
        switch (optionName) {
            case OperativeActivityType.otpSecretChange:
                activityTypeName = separateCamelCase('mfa-secret-change').replace(/-/g, ' ');
                break;
            case OperativeActivityType.otpSecretReset:
                activityTypeName = separateCamelCase('mfa-secret-reset').replace(/-/g, ' ');
                break;
            default:
                activityTypeName = option.label;
        }

        return {
            ...option,
            label: wfpFormat(activityTypeName),
        };
    });

    return new MultiSelectFilter('Activity Type', 'activity', value, options);
}
