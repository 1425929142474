import * as React from 'react';
import Switch from 'rc-switch';

import { ProductContainer, InputField, TabType } from './helpers';
import { Form } from '../utils/inputs';
import { ProductsPricesTable } from './ProductsPricesTable';
import { useProductsPricesAdd } from './hooks/useProductsPricesAdd';
import { Select } from '../utils/form/fields/Select';
interface Props {
    params: { productId: string };
}
export const ProductsPricesAdd: React.FC<Props> = ({ params }) => {
    const {
        product,
        vendors,
        productId,
        handleSubmit,
        handleChange,
        availableVendorsOptions,
        price,
        prices,
    } = useProductsPricesAdd(params);
    if (!product || !vendors.length) return <div>loading...</div>;
    return (
        <>
            <div>
                <h3>{product.name} New Price</h3>
                <Form className="wfp-form" onSubmit={handleSubmit}>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Vendor</label>
                        <div className="col-sm-8">
                            <Select
                                name="vendorId"
                                onChange={handleChange}
                                options={availableVendorsOptions}
                                value={price.vendorId}
                            />
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Price</label>
                        <InputField
                            autoFocused={true}
                            maxLength={35}
                            name="price"
                            onChange={handleChange}
                            readonly={false}
                            required={true}
                            step="any"
                            type="number"
                            value={price.price?.toString()}
                        />
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Is Fixed</label>
                        <div className="col-sm-8 pv1">
                            <Switch
                                checked={price.isFixed}
                                disabled={false}
                                name="isFixed"
                                onChange={(val, event) => {
                                    event.persist();
                                    handleChange({ target: { name: event.target.name, value: val } });
                                }}
                            />
                        </div>
                    </div>
                    <div className="wfp-form--actions text-center">
                        <div className="col-sm-8 col-sm-offset-4">
                            <button className="wfp-btn--primary" type="submit">
                                Park & Post Changes
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
            <div className={'row'}>
                <ProductsPricesTable prices={prices} product={product} />
            </div>
        </>
    );
};
