import { useNavigate } from '@wfp-common/hooks/useNavigate';
import * as React from 'react';

export const withNavigate = (Component: any, compName: string) => {
    const WithHooks = (props: any) => {
        const navigate = useNavigate();

        return <Component {...props} navigate={navigate} />;
    };

    const componentName = Component.displayName || Component.name || compName;

    WithHooks.displayName = `withNavigate(${componentName})`;

    return WithHooks;
};
