import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import { classNames, compose, wfpFormat, withDecimal } from '../../utils/utils';
import { errorAction } from '../../utils/notifications';
import { useDispatch } from 'react-redux';
import { Loader } from '../../utils/Loader';
import styled from 'styled-components';
import { leftBorder, rightBorder } from '../change-lists/product-change-list';
import { findProductsByGroupId } from '../../api/product-management';

interface Props {
    groupId: number | string;
    excludedProducts: string[];
    isShowingPendingUpdates: boolean;
    validFrom: Date;
}

const machine = {
    initial: 'idle',
    states: {
        idle: {
            on: { TOGGLE: 'loading' },
        },
        loading: {
            on: { LOADED: 'open' },
        },
        open: {
            on: { TOGGLE: 'closed' },
        },
        closed: {
            on: { TOGGLE: 'open' },
        },
    },
};

function reducer(state, event) {
    return machine.states?.[state]?.on?.[event];
}

export function ProductManagementMoreProductsSectionComponent({
    groupId,
    excludedProducts,
    isShowingPendingUpdates,
    validFrom,
}: Props) {
    const [products, setProducts] = useState(null);
    const [current, send] = useReducer(reducer, machine.initial);

    const handleShowMoreProducts = () => {
        send('TOGGLE');
    };

    const dispatch = useDispatch();
    useEffect(() => {
        if (current === 'loading') {
            findProductsByGroupId(groupId, validFrom)
                .then((response: any) => response?.data?.filter((res) => !excludedProducts.includes(res.productName)))
                .then(setProducts)
                .then(() => {
                    send('LOADED');
                })
                .catch((error) => {
                    compose(dispatch, errorAction)(`Unable to load additional products`);
                });
        }
    }, [current]);

    useEffect(() => {
        if (isShowingPendingUpdates) {
            handleShowMoreProducts();
        }
    }, []);
    const isOpen = current === 'open';
    const isLoading = current === 'loading';
    return (
        <>
            {isShowingPendingUpdates ? null : (
                <tr>
                    <td colSpan={7}>
                        <ShowMore className={classNames({ loading: isLoading })}>
                            <a onClick={handleShowMoreProducts}>
                                {wfpFormat(`show ${isOpen ? 'less' : 'more'}`)} <Loader />
                            </a>
                        </ShowMore>
                    </td>
                </tr>
            )}
            {isOpen || isShowingPendingUpdates ? (
                <>
                    {!products?.length && !isShowingPendingUpdates ? (
                        <td colSpan={7}>
                            <Paragraph>
                                <strong> There are no more products for this group</strong>
                            </Paragraph>
                        </td>
                    ) : (
                        <>
                            {products?.map((product) => (
                                <tr className={'cursor-pointer'} key={product?.product?.name}>
                                    <td style={leftBorder}>{wfpFormat(product?.productName) || '-'}</td>
                                    <td>{withDecimal(product.minPrice)}</td>
                                    <td>{withDecimal(product.maxPrice)}</td>
                                    <td style={rightBorder}>{product.isFixed ? 'Yes' : 'No'}</td>
                                    <td>{withDecimal(product.minPrice)}</td>
                                    <td>{withDecimal(product.maxPrice)}</td>
                                    <td>{product.isFixed ? 'Yes' : 'No'}</td>
                                </tr>
                            ))}
                        </>
                    )}
                </>
            ) : null}
        </>
    );
}

const Paragraph = styled.p`
    text-align: center;
`;
const ShowMore = styled.div`
    display: flex;
    place-content: center;

    .loader.loader {
        position: absolute;
        visibility: hidden;
        font-size: 1rem;
        padding: 0 0 0 0.5rem;
        margin: 0;
    }
    &.loading {
        .loader.loader {
            visibility: visible;
        }
    }
`;
