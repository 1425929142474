import * as React from 'react';
import { hasFeatureAccess } from '../../../login/auth';
import { withLocation } from '../locations';
import { ManagerPermission } from '../../../permission-profiles/permission';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

export function withLocationsUtils(WrappedComponent) {
    const navigate = useNavigate();

    class Container extends React.Component<any, any> {
        componentDidMount(): void {
            const { loadLocations, auth } = this.props;
            const hasAccess = hasFeatureAccess(auth, ManagerPermission.beneficiariesRead);
            if (!hasAccess) {
                navigate('/');
            } else {
                loadLocations();
            }
        }

        cancel = (locationId) => (): void => {
            this.props.cancelLocation(locationId);
        };
        authorize = (locationId) => (): void => {
            this.props.authorizeLocation(locationId);
        };
        render() {
            if (!this.props.auth.manager) {
                return null;
            }
            return (
                <WrappedComponent
                    actions={{
                        cancel: this.cancel,
                        authorize: this.authorize,
                    }}
                    auth={this.props.auth}
                    locations={this.props.locations.list}
                    path={this.props.location.pathname}
                />
            );
        }
    }
    return withLocation(Container as any);
}
