import * as React from 'react';
import { EntityUpdateRequest } from '../authorization/taskAuthorizations';
import { Modal } from 'react-bootstrap';
import * as clonedeep from 'lodash.clonedeep';
import { Input, ValidationContext } from './inputs';

interface EditionDialogProps {
    update: EntityUpdateRequest;
    onClose: () => void;
    onConfirm: (editedUpdate: EntityUpdateRequest) => void;
}

export default class EditionDialog extends React.Component<EditionDialogProps, EntityUpdateRequest> {
    validationContext = new ValidationContext();

    constructor(props) {
        super(props);
        this.state = null;
    }

    UNSAFE_componentWillMount() {
        const updates = clonedeep(this.props.update);
        this.setState(updates);
    }

    _handleInputChange({ target }) {
        const newUpdates = this.state.updates.map((u) => {
            if (u.path === target.name) {
                const newRow = u;
                newRow.newValue = target.value;
                return newRow;
            } else {
                return u;
            }
        });
        this.setState({ updates: newUpdates });
    }

    _onConfirm() {
        let tooLongValue = false;
        this.state.updates.forEach((update) => {
            if (update.newValue.length > 35) {
                tooLongValue = true;
            }
        });

        if (!tooLongValue) {
            this.props.onConfirm(this.state);
        }
    }

    renderInputField(options: {
        value: string;
        name: string;
        required: boolean;
        type: string;
        isFocused?: boolean;
        maxLength: number;
    }) {
        return (
            <div className="col-sm-4">
                <Input
                    className="form-control"
                    context={this.validationContext}
                    isFocused={options.isFocused}
                    isModal={true}
                    maxLength={options.maxLength}
                    name={options.name}
                    onChange={this._handleInputChange.bind(this)}
                    required={options.required}
                    type={options.type}
                    value={options.value}
                />
            </div>
        );
    }

    render() {
        const { onClose } = this.props;
        return (
            <div>
                <div className="fade modal-backdrop in" />
                <Modal.Dialog>
                    <Modal.Header className="col-sm-auto">
                        <Modal.Title>Edit pre parked Beneficiary update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: 450, overflowY: 'auto' }}>
                        {this.state.updates.map((u) => (
                            <div className="wfp-form--group row" key={u.path}>
                                <label className="col-sm-3 ta-right">{u.path}</label>
                                {/*<div className="wfp-form--group row">*/}
                                <p className="col-sm-4 ta-right">{u.oldValue ? u.oldValue : ''}</p>
                                {this.renderInputField({
                                    value: u.newValue,
                                    name: u.path,
                                    required: true,
                                    type: u.path === 'householdSize' ? 'number' : 'text',
                                    isFocused: false,
                                    maxLength: 35,
                                })}
                                {/*</div>*/}
                            </div>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
                                Close
                            </button>
                            <button className="wfp-btn--primary" onClick={this._onConfirm.bind(this)} type="submit">
                                Confirm
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        );
    }
}
