import * as React from 'react';
import * as moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import State from '../app/store/state';
import { currencyFormatter, displayTime } from '../utils/utils';
import {
    ActionCreators,
    PaymentRequest,
    PaymentRequestStatus,
    PaymentStatusTransition,
    SapPaymentRequest,
    SapPaymentRequestMappedStatuses,
} from './paymentRequests';
import { ActionCreators as AsyncTaskActionCreators } from '../utils/asyncTasks';
import PaymentRequestActions from './PaymentRequestActions';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { FileStatus } from '../utils/fileTaskReference';
import CancelledPaymentsRequestsTable from './CancelledPaymentsRequestsTable';
import SapPaymentRequestProposalsDialog from './PaymentReqeustPoItemProposalDialog';
import { EntitlementCurrencyConfig, PaymentConfig, SapConfig } from '../app/appConfig';
import { RefreshSignaler } from '../utils/refresher';
import { BigNumber } from '../utils/bigNumber';
import { periodLabelFor } from '../utils/periodLabelFor';
import { ManagerPermission } from '../permission-profiles/permission';
import { Link } from 'react-router';
import { withNavigate } from '@wfp-common/hooks/withNavigate';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';

interface Props {
    params: { requestId?: string };
    location: {
        query?: { vendorId?: string; periodEnd?: string; category?: string };
    };
    currency: EntitlementCurrencyConfig;
    paymentsConfig: PaymentConfig;

    auth: AuthState;
    request: PaymentRequest;
    possibleStatuses: Array<PaymentStatusTransition>;
    sapConfig: SapConfig;
    loadSapPaymentRequests: (requestId: string) => Promise<RefreshSignaler>;
    loadPaymentRequest: (requestId: string) => Promise<any>;
    loadTargetStatuses: (requestId: string) => Promise<void>;
    changePaymentRequestStatus: (
        paymentRequestId: string,
        targetStatus: PaymentRequestStatus,
        routeOverride?: string,
        paymentSapDetails?: Array<SapPaymentRequest>
    ) => void;
    downloadFile: (type: string, id: string) => string;
    handlePoDetailsFetchingError: (err) => void;
    navigate: NavigateHook;
}

const PaymentStatusesHidingPdf = [
    PaymentRequestStatus.GenerationPostProcessing,
    PaymentRequestStatus.GenerationParkedProcessing,
    PaymentRequestStatus.NotGenerated,
    PaymentRequestStatus.GenerationParked,
    PaymentRequestStatus.CancellationProcessing,
    PaymentRequestStatus.RejectionProcessing,
]; // all statuses before pdf Download link is shown

const PaymentStatusesHidingExcel = [PaymentRequestStatus.NotGenerated, PaymentRequestStatus.GenerationParkedProcessing]; // all statuses before excel download link is shown

const refreshStatuses = [
    PaymentRequestStatus.GenerationParkedProcessing,
    PaymentRequestStatus.GenerationPostProcessing,
    PaymentRequestStatus.RejectionProcessing,
    PaymentRequestStatus.CancellationProcessing,
    PaymentRequestStatus.IssuePaymentPark,
    PaymentRequestStatus.PaidPostProcessing,
];

class PaymentRequestDetailsPage extends React.Component<Props, { isSapRequestsProposalDialogActive: boolean }> {
    private sapDependenciesRefresher: RefreshSignaler;

    constructor(props, context) {
        super(props, context);

        this.state = {
            isSapRequestsProposalDialogActive: false,
        };
    }

    private async loadRequest(newProps: Props) {
        const { requestId } = newProps.params;
        await newProps.loadPaymentRequest(requestId);
        if (!refreshStatuses.includes(this.props.request.status)) {
            clearInterval(this.interval);
        }
        await this.props.loadTargetStatuses(requestId);
    }

    interval = null;

    async updateAndSetInterval(props: Props) {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        } else {
            await this.loadRequest(props);
            if (props.request && refreshStatuses.includes(this.props.request.status) && !this.interval) {
                const refresher = () => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.loadRequest(props);
                };
                if (!this.interval) {
                    this.interval = setInterval(refresher, 3000);
                }
            }
        }
    }

    async componentDidMount() {
        await this.updateAndSetInterval(this.props);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.sapDependenciesRefresher) {
            this.sapDependenciesRefresher.stop();
        }
    }

    async UNSAFE_componentWillReceiveProps(newProps: Props) {
        if (this.props.request && newProps.request.status !== this.props.request.status) {
            await this.updateAndSetInterval(newProps);
        } else if (newProps.params.requestId !== this.props.params.requestId) {
            await this.updateAndSetInterval(newProps);
        }
    }

    renderSapPaymentRequestProposals() {
        const { request } = this.props;
        if (request) {
            return (
                <SapPaymentRequestProposalsDialog
                    confirmTitle="Park Paid"
                    currency={this.props.currency}
                    errorHandler={this.props.handlePoDetailsFetchingError}
                    onClose={() =>
                        this.setState({
                            isSapRequestsProposalDialogActive: false,
                        })
                    }
                    onSubmit={this._changePaymentRequestStatus.bind(this)}
                    request={this.props.request}
                    sapConfig={this.props.sapConfig}
                    title="Purchase Order Payment Proposition"
                />
            );
        } else {
            return null;
        }
    }

    showProposalDialog = async () => {
        this.setState({ isSapRequestsProposalDialogActive: true });
    };

    _changePaymentRequestStatus(paymentRequestId: string, routeOverride?: string, paymentsByPo?: SapPaymentRequest[]) {
        this.props.changePaymentRequestStatus(
            paymentRequestId,
            PaymentRequestStatus.IssuePaymentPark,
            routeOverride,
            paymentsByPo
        );
        this.setState({ isSapRequestsProposalDialogActive: false });
    }

    private renderTableWithSendSapAttempts() {
        const { request } = this.props;
        if (request && request.sendSapPaymentAttempts && request.sendSapPaymentAttempts.length > 0) {
            return (
                <div>
                    <h4 style={{ margin: '1em 0' }}>Send SAP Payment Request Attempts</h4>
                    <table className="wfp-table--striped mt3 table-hover">
                        {this.renderTableHeadersWithSendSapAttempts()}
                        <tbody>
                            {Boolean(request.sendSapPaymentAttempts.length) &&
                                request.sendSapPaymentAttempts.map((sendSapPaymentAttempt) => {
                                    return (
                                        <tr key={`${sendSapPaymentAttempt.id}`}>
                                            <td>
                                                {sendSapPaymentAttempt.createdAt
                                                    ? moment
                                                          .tz(sendSapPaymentAttempt.createdAt, 'CET')
                                                          .format('YYYY-MM-DD HH:mm:ss') + 'CET'
                                                    : '-'}
                                            </td>
                                            <td>
                                                {sendSapPaymentAttempt.startedAt
                                                    ? moment
                                                          .tz(sendSapPaymentAttempt.startedAt, 'CET')
                                                          .format('YYYY-MM-DD HH:mm:ss') + 'CET'
                                                    : '-'}
                                            </td>
                                            <td>
                                                {sendSapPaymentAttempt.finishedAt
                                                    ? moment
                                                          .tz(sendSapPaymentAttempt.finishedAt, 'CET')
                                                          .format('YYYY-MM-DD HH:mm:ss') + 'CET'
                                                    : '-'}
                                            </td>
                                            <td>
                                                {sendSapPaymentAttempt.additionalInfo
                                                    ? sendSapPaymentAttempt.additionalInfo.status
                                                    : '-'}
                                            </td>
                                            <td>
                                                {sendSapPaymentAttempt.errors ? (
                                                    sendSapPaymentAttempt.errors.map((error) => (
                                                        // eslint-disable-next-line react/jsx-key
                                                        <p>{error}</p>
                                                    ))
                                                ) : (
                                                    <p>-</p>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return null;
        }
    }

    private renderTableWithSapPaymentRequests() {
        const { request, currency } = this.props;
        if (request && request.sapPaymentRequests && request.sapPaymentRequests.length > 0) {
            const formatter = currencyFormatter(currency).format;
            return (
                <div>
                    <h4 style={{ margin: '1em 0' }}>Payment SAP Purchase Order Items</h4>
                    <table className="wfp-table--striped mt3 table-hover">
                        {this.renderTableHeaderForSapPaymentRequests()}
                        <tbody>
                            {request.sapPaymentRequests.map((sapPaymentRequest) => {
                                return (
                                    <tr key={`${sapPaymentRequest.poItem}-${sapPaymentRequest.poNumber}`}>
                                        <td>{sapPaymentRequest.poNumber}</td>
                                        <td>{sapPaymentRequest.poItem}</td>
                                        <td>{sapPaymentRequest.recordId ? sapPaymentRequest.recordId : '-'}</td>
                                        <td>{SapPaymentRequestMappedStatuses[sapPaymentRequest.status] || '-'}</td>
                                        <td>
                                            {sapPaymentRequest.lastStatusUpdate
                                                ? sapPaymentRequest.lastStatusUpdate
                                                : '-'}
                                        </td>
                                        <td>
                                            {formatter(new BigNumber(sapPaymentRequest.availableAmount).toNumber())}
                                        </td>
                                        <td>
                                            {formatter(new BigNumber(sapPaymentRequest.requestedAmount).toNumber())}
                                        </td>
                                        <td>
                                            {moment
                                                .tz(sapPaymentRequest.terminationDate, 'CET')
                                                .endOf('day')
                                                .format('YYYY-MM-DD HH:mm:ss')}{' '}
                                            CET
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return null;
        }
    }

    private renderTableHeaderForSapPaymentRequests() {
        return (
            <thead>
                <tr>
                    <th>PO Number</th>
                    <th>Line Item</th>
                    <th>Record ID</th>
                    <th>WINGSII Status</th>
                    <th>Last status update</th>
                    <th>Available Amount</th>
                    <th>Requested Amount</th>
                    <th>TDD</th>
                </tr>
            </thead>
        );
    }

    private renderTableHeadersWithSendSapAttempts() {
        return (
            <thead>
                <tr>
                    <th>Created at</th>
                    <th>Started at</th>
                    <th>Finished at</th>
                    <th>Status</th>
                    <th>Error</th>
                </tr>
            </thead>
        );
    }

    render() {
        const {
            request,
            auth,
            changePaymentRequestStatus,
            downloadFile,
            sapConfig,
            currency,
            possibleStatuses,
        } = this.props;
        const { isSapRequestsProposalDialogActive } = this.state;
        if (!request) {
            return <main />;
        }

        const formatter = currencyFormatter(currency).format;
        const canDownloadPDF = hasFeatureAccess(auth, ManagerPermission.paymentsSummaryDownload);
        const canDownloadExcel = hasFeatureAccess(auth, ManagerPermission.paymentsDetailsDownload);

        const periodEnd = (this.props.location.query || {}).periodEnd
            ? moment((this.props.location.query || {}).periodEnd).toDate()
            : request.periodEnd;

        return (
            <main>
                {sapConfig.isSapActive && isSapRequestsProposalDialogActive && this.renderSapPaymentRequestProposals()}
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/payments">
                                <span>Payments</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Payment Details</span>
                        </li>
                    </ol>
                </nav>

                <h3 style={{ textAlign: 'center' }}>
                    Payment {request.paymentNumber && <span>#{request.paymentNumber}</span>}
                </h3>
                <div className="mt4">
                    <div className="row">
                        <label className="col-sm-6 ta-right">Vendor</label>
                        <div className="col-sm-6">{request.vendor.name}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">Period</label>
                        <div className="col-sm-6">{periodLabelFor(request.periodStart, request.periodEnd)}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">Category</label>
                        <div className="col-sm-6">{request.category}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">Current Period Includes</label>
                        <div className="col-sm-6">{formatter(request.currentPeriod.credits)}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">Current Period Excludes</label>
                        <div className="col-sm-6">{formatter(request.currentPeriod.debits)}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">Previous Periods Adjustment Includes</label>
                        <div className="col-sm-6">{formatter(request.previousPeriods.credits)}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">Previous Periods Adjustment Excludes</label>
                        <div className="col-sm-6">{formatter(request.previousPeriods.debits)}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">Total Outstanding for Period</label>
                        <div className="col-sm-6">{formatter(request.outstandingAmount)}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">
                            Discount {new BigNumber(request.paymentDiscount).multipliedBy(100).toString()}%
                        </label>
                        <div className="col-sm-6">{formatter(request.bankFeeAmount)}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">WFP Payable</label>
                        <div className="col-sm-6">{formatter(request.payableAmount)}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-6 ta-right">Current Status</label>
                        <div className="col-sm-6">{request.status}</div>
                    </div>
                    {canDownloadExcel && (
                        <div className="row">
                            <label className="col-sm-6 ta-right">Payment Excel File</label>
                            <div className="col-sm-6">
                                <FileStatus
                                    downloadFile={downloadFile}
                                    fileReference={request.excelFileTask}
                                    shouldShow={!PaymentStatusesHidingExcel.includes(request.status)}
                                />
                            </div>
                        </div>
                    )}
                    {canDownloadPDF && (
                        <div className="row">
                            <label className="col-sm-6 ta-right">Payment PDF File</label>
                            <div className="col-sm-6">
                                <FileStatus
                                    downloadFile={downloadFile}
                                    fileReference={request.pdfFileTask}
                                    shouldShow={!PaymentStatusesHidingPdf.includes(request.status)}
                                />
                            </div>
                        </div>
                    )}
                    {this.renderStatusChanges(request)}
                    <div className="row">
                        <label className="col-sm-6 ta-right">{/*Action*/}</label>
                        <div className="col-sm-6">
                            <PaymentRequestActions
                                changePaymentRequestStatus={changePaymentRequestStatus}
                                isSapModuleActive={sapConfig.isSapActive}
                                possibleStatuses={possibleStatuses}
                                request={request}
                                shouldRedirect={true}
                                showPoItemDialog={() => this.showProposalDialog()}
                            />
                        </div>
                    </div>
                </div>
                {sapConfig.isSapActive && (
                    <div>
                        {this.renderTableWithSapPaymentRequests()}
                        {this.props.request.sendSapPaymentAttempts &&
                            this.props.request.sendSapPaymentAttempts.length > 1 &&
                            this.renderTableWithSendSapAttempts()}
                    </div>
                )}
                {request.status !== PaymentRequestStatus.Cancelled &&
                    request.relatedPayments.find((related) => related.status === PaymentRequestStatus.Cancelled) &&
                    !!request.relatedPayments.length && (
                        <div>
                            <h4 style={{ margin: '1em 0' }}>Cancelled Payments</h4>
                            <CancelledPaymentsRequestsTable
                                auth={auth}
                                category={this.props.request.category}
                                changePaymentRequestStatus={changePaymentRequestStatus}
                                currency={this.props.currency}
                                isSapModuleActive={sapConfig.isSapActive}
                                payments={request.relatedPayments.filter(
                                    (related) => related.status === PaymentRequestStatus.Cancelled
                                )}
                                periodEnd={periodEnd}
                            />
                        </div>
                    )}
                {this.renderNewPayment(request)}
            </main>
        );
    }

    private renderStatusChanges(request: PaymentRequest) {
        const statusTransitions = request.statusTransitions.filter((statusTransaction) => statusTransaction.action);
        return (
            <>
                {(statusTransitions || []).map((transition, index) => (
                    <div className="row" key={`${transition.to}-${transition.at}`}>
                        <label className="col-sm-6 ta-right">{`Action ${index + 1}`}</label>
                        <div className="col-sm-6">
                            {transition.action} by {transition.byManager ? transition.byManager.name : 'system'} at{' '}
                            {displayTime(transition.at)}
                        </div>
                    </div>
                ))}
            </>
        );
    }

    private renderNewPayment(request: PaymentRequest) {
        if (
            request.relatedPayments.length &&
            request.status === PaymentRequestStatus.Cancelled &&
            request.relatedPayments.find((related) => related.status !== PaymentRequestStatus.Cancelled)
        ) {
            const newPayment = request.relatedPayments.filter(
                (related) => related.status !== PaymentRequestStatus.Cancelled
            )[0];
            return (
                <div className="row">
                    <label className="col-sm-6 ta-right">New payment</label>
                    <div className="col-sm-6">
                        <td>
                            <a onClick={() => this.props.navigate('/payments/' + newPayment.id)}>{newPayment.id}</a>
                        </td>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

function mapStateToProps(state: State) {
    return {
        request: state.paymentRequest.request,
        possibleStatuses: state.paymentRequest.requestPossibleStatuses,
        sapConfig: state.appConfig.sapConfig,
        auth: state.auth,
        currency: state.appConfig.entitlementCurrencyConfig,
        paymentsConfig: state.appConfig.paymentConfig,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadPaymentRequest: bindActionCreators(ActionCreators.loadPaymentRequest, dispatch),
        loadTargetStatuses: bindActionCreators(ActionCreators.loadTargetStatuses, dispatch),
        handlePoDetailsFetchingError: bindActionCreators(ActionCreators.handlePoDetailsFetchingError, dispatch),
        changePaymentRequestStatus: bindActionCreators(ActionCreators.changePaymentRequestStatus, dispatch),
        downloadFile: bindActionCreators(AsyncTaskActionCreators.downloadFile, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigate(PaymentRequestDetailsPage, 'PaymentRequestDetailsPage'));
