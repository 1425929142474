import { hasFeatureAccess } from '../../login/auth';
import { ManagerStatus } from '../../managers/manager';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HooksActionCreators } from '../partnersActions';
import State from '../../app/store/state';
import { ChangeSortOptions, SORT_OPTIONS } from '../../utils/hooks/useSort';
import { defaultPartnerUserSortOptions } from '../partners';
import { Filter, MultiSelectFilter } from '../../utils/FilterTypes';
import { UserStatuses } from '../../utils/users';
import * as _ from 'lodash';
import { startCase } from 'lodash';
import { ManagerPermission } from '../../permission-profiles/permission';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

export function usePartnerUsersList(partnerId: string, pathname: string) {
    const dispatch = useDispatch();
    const loadPartnerUsers = useMemo(() => HooksActionCreators.loadPartnerUsers(dispatch), []);
    const authorizePartnerUser = useMemo(() => HooksActionCreators.authorizePartnerUser(dispatch), []);
    const resetPartnerUserPassword = useMemo(() => HooksActionCreators.resetPartnerUserPassword(dispatch), []);
    const cancelPartnerUser = useMemo(() => HooksActionCreators.cancelPartnerUser(dispatch), []);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [sortingOrder, setSortingOrder] = useState<SORT_OPTIONS>(defaultPartnerUserSortOptions);
    const { partnerUsersList, auth, partner, partnerUserStatuses } = useSelector((state: State) => ({
        partnerUsersList: state.partnerUsers.list,
        auth: state.auth,
        partner: state.partners.list.find((p) => p.id.toString() === partnerId.toString()),
        partnerUserStatuses: state.appConfig.userStatuses.partnerUserStatus,
    }));
    const extendedPartnerUsersList = useMemo(
        () =>
            partnerUsersList.map((partnerUser) => ({ ...partnerUser, ...getAvailableActions({ partnerUser, auth }) })),
        [partnerUsersList]
    );

    useEffect(() => {
        const statusesOptions = Object.values(partnerUserStatuses).map((status: UserStatuses) => ({
            label: startCase(status),
            value: status,
        }));

        setFilters([
            new MultiSelectFilter(
                'Status',
                'status',
                [
                    { label: startCase(UserStatuses.active), value: UserStatuses.active },
                    { label: startCase(UserStatuses.parked), value: UserStatuses.parked },
                ],
                statusesOptions,
                null,
                true
            ),
        ]);
    }, []);

    useEffect(() => {
        if (filters.length > 0) {
            loadPartnerUsers(
                partnerId,
                sortingOrder,
                filters.filter((input) => input.isSelected)
            );
        }
    }, [partnerId, sortingOrder, filters]);

    const handleResetPassword = useCallback(
        (partnerUser) => () => {
            dispatch(showLoading());

            resetPartnerUserPassword(partnerUser).finally(() => dispatch(hideLoading()));
        },
        []
    );
    const handlePost = useCallback(
        (partnerUser) => () => {
            dispatch(showLoading());
            authorizePartnerUser(partnerUser).finally(() => dispatch(hideLoading()));
        },
        []
    );
    const handleCancel = useCallback(
        (partnerUser) => () => {
            dispatch(showLoading());
            cancelPartnerUser(partnerUser).finally(() => dispatch(hideLoading()));
        },
        []
    );
    const redirectToPartnerUserPage = useCallback(
        (partnerUserId) => () => {
            dispatch(routerHelperActions.makeRedirect(`${pathname}/${partnerUserId}`));
        },
        []
    );
    const handleSort: ChangeSortOptions = ([column, direction]) => {
        if (column) {
            setSortingOrder({
                'orderBy:column': columnToAttributeMapping[column],
                'orderBy:direction': direction,
            });
        } else {
            setSortingOrder(defaultPartnerUserSortOptions);
        }
    };
    const handleFilterChanged = (filter: Filter) => {
        const activeFiltersCopy = _.clone(filters);
        const index = activeFiltersCopy.findIndex((x) => x.name === filter.name);

        if (index === -1) {
            setFilters(activeFiltersCopy.concat([filter]));
        } else {
            activeFiltersCopy.splice(index, 1, filter);
            setFilters(activeFiltersCopy);
        }
    };
    return {
        partnerUsersList: extendedPartnerUsersList,
        manager: auth.manager,
        handleResetPassword,
        handlePost,
        redirectToPartnerUserPage,
        handleCancel,
        partner,
        handleSort,
        filters,
        handleFilterChanged,
    };
}

const columnToAttributeMapping = {
    'First Name': 'firstName',
    'Last Name': 'lastName',
    Email: 'email',
    Status: 'status',
    'Valid Until': 'expirationDate',
};

export function trClassName(partnerUser) {
    return (
        'cursor-pointer ' +
        (partnerUser.status === ManagerStatus.blocked ? 'failed' : '') +
        (partnerUser.isPasswordBlocked ? 'attention-color' : '')
        //TODO add pending request indicator when those be ready
    );
}

function getAvailableActions({ partnerUser, auth }) {
    const parkedByCurrentManager = auth.manager
        ? Number(partnerUser.createdByManagerId) === Number(auth.manager.id)
        : true;
    const hasPostPermissions = hasFeatureAccess(auth, ManagerPermission.partnerUserPost);
    const hasResetPasswordPermissions = hasFeatureAccess(auth, ManagerPermission.partnerUserPasswordReset);
    const renderCancel = partnerUser.status === 'parked' && (hasPostPermissions || parkedByCurrentManager);
    const renderPost =
        !parkedByCurrentManager && hasPostPermissions && partnerUser.status === 'parked' && !partnerUser.authorizedAt;
    const renderResetPassword = partnerUser.status === 'active' && hasResetPasswordPermissions;
    return { renderCancel, renderPost, renderResetPassword };
}
