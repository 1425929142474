import * as React from 'react';
import BreadCrumbs from '../../utils/BreadCrumbs';
import LocationsList from './LocationsList';
import FeatureButton from '../../utils/FeatureButton';
import { withLocationsUtils } from './utils/locationsPageUtilities';
import { ManagerPermission } from '../../permission-profiles/permission';

interface Props {
    path: string;
    locations: Location[];
    auth: any;
    actions: {
        cancel: (locationId: number) => () => void;
        authorize: (locationId: number) => () => void;
    };
}

function LocationsPage({ path, locations, auth, actions }: Props) {
    return (
        <main>
            <div>
                <BreadCrumbs path={path} />
                <div className="row">
                    <div className="col-sm-8 ta-left">
                        <h3>Manage Locations</h3>
                    </div>
                    <div className="col-sm-4 ta-right">
                        <FeatureButton
                            linkTo="/beneficiaries/locations/new"
                            manager={auth.manager}
                            restrictedToFeatures={[ManagerPermission.beneficiariesPark]}
                            title={'Add Location'}
                        />
                    </div>
                </div>
                {locations.length ? (
                    <section className="row">
                        <LocationsList
                            actions={actions}
                            auth={auth}
                            currentManagerId={auth.manager.id}
                            locations={locations}
                        />
                        <div>
                            <strong>
                                <span className="text-danger">*</span>You cannot post it, because you parked it
                            </strong>
                        </div>
                    </section>
                ) : (
                    <p className="text-center">
                        <strong>No locations found.</strong>
                    </p>
                )}
            </div>
        </main>
    );
}

export default withLocationsUtils(LocationsPage);
