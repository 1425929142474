import * as React from 'react';
import PagingComponent, { PagedState } from '../utils/paging';
import { ManagerNickName } from '../managers/managers';
import { AuthState } from '../login/auth';
import { defaultPartnerSortOptions, defaultPartnerUserSortOptions, PartnerName, PartnerUserName } from './partners';
import { useEffect } from 'react';
import FeatureButton from '../utils/FeatureButton';
import ActivityLogCell from '../activityLogs/ActivityLogCell';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    loadActivityLogs: (page: number, limit: number, managerId?: string) => void;
    activityLogs: PagedState<any>;
    managersNickNames: ManagerNickName[];
    isManager: boolean;
    entityId: string;
    url?: string;
    loadManagersNickNames: () => void;
    auth: AuthState;
    downloadRestriction: ManagerPermission[];
    partnersNames: PartnerName[];
    loadPartnersNames: (sortOptions: SORT_OPTIONS) => void;
    loadPartnerUsersNames: (sortOptions: SORT_OPTIONS) => void;
    partnerUsersNames: PartnerUserName[];
}

const PageLimit = 25;

const PartnerActivityLogsList: React.FC<Props> = ({
    loadActivityLogs,
    activityLogs,
    managersNickNames,
    isManager,
    entityId,
    url,
    loadManagersNickNames,
    auth,
    downloadRestriction,
    partnersNames,
    loadPartnersNames,
    loadPartnerUsersNames,
    partnerUsersNames,
}) => {
    const manager = auth.manager;

    useEffect(() => {
        loadManagersNickNames();
        loadPartnersNames(defaultPartnerSortOptions);
        loadPartnerUsersNames(defaultPartnerUserSortOptions);
        onChangePageRequested(1);
    }, []);

    const onChangePageRequested = (page: number) => {
        if (isManager) {
            loadActivityLogs(page, PageLimit, auth.manager.id);
        } else {
            loadActivityLogs(page, PageLimit);
        }
    };

    const checkIfEntitiesListsAreFetched = () => {
        return managersNickNames && partnersNames && managersNickNames.length > 0 && partnersNames.length > 0;
    };

    return (
        <div>
            <div className="row">
                <h6 className="col-sm-4">Activity Logs</h6>

                {url && (
                    <div className="col-sm-8 tr">
                        <FeatureButton
                            linkTo={url}
                            manager={manager}
                            restrictedToFeatures={downloadRestriction}
                            state={{ entityId: entityId }}
                            title={'Download'}
                        />
                    </div>
                )}
            </div>
            {checkIfEntitiesListsAreFetched() &&
                activityLogs.items &&
                activityLogs.items.length > 0 &&
                activityLogs.items.map((log) =>
                    ActivityLogCell(log, managersNickNames, null, null, partnersNames, partnerUsersNames)
                )}
            <PagingComponent onPageChanged={onChangePageRequested} paging={activityLogs.paging} />
        </div>
    );
};

PartnerActivityLogsList.displayName = 'PartnerActivityLogList';

export { PartnerActivityLogsList };
