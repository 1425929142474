import { useDispatch } from 'react-redux';
import { Price, Product } from '../helpers';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { loadPricesForProduct, loadProduct, loadProductVendors, parkAndAuthorizeProductPrice } from '../../apiClient';
import { errorAction, successAction } from '../../utils/notifications';
import { Vendor } from '../../vendors/vendors';
import { SelectOption } from '../../utils/FilterTypes';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

type UseProductsPricesAdd = (params: {
    productId: string;
}) => {
    product: Product;
    vendors: Vendor[];
    productId: string;
    handleSubmit(event: Event): void;
    handleChange(event: ChangeEvent<HTMLInputElement> | any): void;
    availableVendorsOptions: SelectOption[];
    price: Partial<Price>;
    prices: Partial<Price>[];
};

export const useProductsPricesAdd: UseProductsPricesAdd = (params) => {
    const dispatch = useDispatch();
    const productId = params.productId;
    const [price, setPrice] = useState<Partial<Price>>({
        isFixed: false,
    });
    const [prices, setPrices] = useState<Partial<Price>[]>([]);
    const [product, setProduct] = useState<Product>(null);
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [availableVendorsOptions, setAvailableVendorsOptions] = useState([]);
    const handleSubmit = (evt) => {
        evt.preventDefault();
        const vendorId = availableVendorsOptions.find((option) => option.value === price.vendorId)?.vendorId;
        parkAndAuthorizeProductPrice(productId, { ...price, vendorId })
            .then(() => {
                dispatch(routerHelperActions.makeRedirect(`/products/${productId}/prices/general`));
                dispatch(successAction('Product price added'));
            })
            .catch(() => {
                dispatch(errorAction('Cannot add product price'));
            });
    };
    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        event.persist?.();
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setPrice((state) => ({ ...state, [event.target.name]: value }));
    }, []);
    useEffect(() => {
        Promise.all([loadProduct(productId), loadPricesForProduct(productId), loadProductVendors()])
            .then(([product, prices, vendors]) => {
                setProduct(product);
                setPrices(prices);
                setVendors(vendors);
                const vendorsWithPrices = prices.map((price) => price.vendorId);
                setAvailableVendorsOptions(
                    vendors
                        .filter((vendor) => !vendorsWithPrices.includes(vendor.id))
                        .map((vendor) => ({ label: vendor.nickname, value: vendor.nickname, vendorId: vendor.id }))
                );
            })
            .catch((v) => v);
    }, []);
    return {
        product,
        vendors,
        productId,
        handleSubmit,
        handleChange,
        availableVendorsOptions,
        price,
        prices,
    };
};
