import * as React from 'react';
import { ActivityType } from './activityLogs';
import ActivityLogExportPage, { ActivityLogPageType } from './ActivityLogExport';
import { ActionCreators } from '../vendors/vendors';

const activities = [
    { key: ActivityType.passwordBlocked, header: 'Password Blocked' },
    { key: ActivityType.passwordUnblocked, header: 'Password Unblocked' },
    { key: ActivityType.paymentRequestCancellation, header: 'Payment Request Cancellation' },
    { key: ActivityType.paymentRequestGeneration, header: 'Payment Request Generation' },
    { key: ActivityType.paymentRequestPaid, header: 'Payment Request Paid' },

    { key: ActivityType.transactionAdjustment, header: 'Transaction Adjustment' },

    { key: ActivityType.vendorDetails, header: 'Vendor Details Update' },
    { key: ActivityType.vendorCreate, header: 'Vendor Create' },
    { key: ActivityType.vendorAuthorize, header: 'Vendor Authorize' },

    { key: ActivityType.vendorUserDetails, header: 'Vendor User Details Update' },
    { key: ActivityType.vendorUserCreate, header: 'Vendor User Create' },
    { key: ActivityType.vendorUserAuthorize, header: 'Vendor User Authorize' },
    { key: ActivityType.vendorUserLogin, header: 'Vendor User Login' },
    { key: ActivityType.vendorUserLogout, header: 'Vendor User Logout' },
    { key: ActivityType.vendorUserPasswordReset, header: 'Vendor User Password Reset' },
];

interface Props {
    params: { vendorId: string };
}
const selectedOptions = activities.map((activity) => activity.key);

const VendorActivityLogExport = (props: Props) => {
    return (
        <ActivityLogExportPage
            activities={activities}
            entityId={props.params.vendorId}
            entityType={ActivityLogPageType.Vendor}
            exportActivityLog={ActionCreators.exportActivityLog}
            selectedOptions={selectedOptions}
        />
    );
};

VendorActivityLogExport.displayName = 'VendorActivityLogExport';

export { VendorActivityLogExport };
