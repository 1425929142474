import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import SummaryStatisticsPage from './summaryStatistics/SummaryStatisticsPage';
import { Tabs } from '../utils/Tabs';
import { TabsContainer } from './styles';
import { MobilizationStatisticsPage } from './mobilizationStatistics/MobilizationStatisticsPage';
import { useSelector } from 'react-redux';
import State from '../app/store/state';
import { countries } from '../utils/countries';
import { TokensStatistics } from './tokensStatistics/TokensStatistics';

export const StatisticsPage = function StatisticsPage() {
    const country = useSelector((state: State) => state.appConfig.country);
    const tabs = useMemo(
        () =>
            country === countries.JORDAN
                ? ['Summary Statistics', 'Detailed Statistics']
                : ['Summary Statistics', 'Mobilization Statistics', 'Detailed Statistics'],
        [country]
    );
    const [active, setActive] = useState(tabs[0]);
    const handleClick = useCallback(
        (label) => () => {
            setActive(label);
        },
        []
    );

    return (
        <main>
            <div className="row">
                <h3 className="col-sm-4">Home</h3>
            </div>
            {tabs.length > 1 && (
                <TabsContainer>
                    <Tabs>
                        {tabs.map((tab) => (
                            <span key={tab} onClick={handleClick(tab)}>
                                {tab}
                            </span>
                        ))}
                    </Tabs>
                </TabsContainer>
            )}
            <section>
                <Statistics active={active} />
            </section>
        </main>
    );
};

function Statistics({ active }: any) {
    switch (active) {
        case 'Summary Statistics':
            return <SummaryStatisticsPage />;
        case 'Mobilization Statistics':
            return <MobilizationStatisticsPage />;
        case 'Detailed Statistics':
            return <TokensStatistics />;
    }
}
