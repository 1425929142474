import * as React from 'react';
import { ActionCreators, ExtendedManager } from './managers';
import PermissionsFiles from '../permissions-files/PermissionsFiles';
import { useDispatch } from 'react-redux';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    manager: ExtendedManager;
}

function ManagerPermissionsFiles({ manager }: Props) {
    const dispatch = useDispatch();

    const uploadPermissionsFile = (userId: string, file: File) => {
        return dispatch(ActionCreators.uploadPermissionsFile(userId, file));
    };

    return (
        <PermissionsFiles
            permissionsFiles={manager.permissionsFiles}
            requiredPermission={[ManagerPermission.managersManagementPark, ManagerPermission.managersManagementPost]}
            uploadPermissionsFile={uploadPermissionsFile}
            userId={manager.id}
        />
    );
}

export { ManagerPermissionsFiles };
