import { currencyFormatter } from '../utils/utils';
import * as React from 'react';
import { EntityBalanceResponse, mapTypeSumBalanceEntryToAdjustmentsExpenditures } from '../entityBalance/entityBalance';
import { ReadonlyCard } from './Cards';
import { PredefinedCurrency } from '../app/appConfig';

const flexRow: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'wrap',
};

interface Props {
    beneficiaryId: string;
    entityBalance: EntityBalanceResponse;
    desiredCategory: string;
    currency: PredefinedCurrency;
}

export const TypedBalance: React.SFC<Props> = ({ entityBalance, beneficiaryId, desiredCategory, currency }: Props) => {
    const noBalanceWarning = (
        <p className={'alert alert-warning'}>No entry for balance of category {desiredCategory} available</p>
    );

    if (!entityBalance || !entityBalance.databaseBalances) {
        return noBalanceWarning;
    }
    if (entityBalance.entityId !== beneficiaryId) {
        return null;
    }
    const entityBalanceOfGivenCategory = entityBalance.databaseBalances.find(
        (total) => total.category === desiredCategory
    );
    if (entityBalanceOfGivenCategory) {
        const groupedSums = mapTypeSumBalanceEntryToAdjustmentsExpenditures(entityBalanceOfGivenCategory);
        const components = Object.keys(groupedSums).map((groupedKey, index) => {
            const img = 'thematic-resourcing-pos';
            // switch(entityBalanceOfGivenCategory.category) {
            //     case EntitlementPredefinedCategories.FOOD:
            //         img = 'thematic-food-pos'
            //         break
            //     case EntitlementPredefinedCategories.CASH:
            //         img = 'thematic-resourcing-pos'
            //         break
            //     case EntitlementPredefinedCategories.CHOICE:
            //         img = 'thematic-resourcing-pos'
            // }
            // turn off for more generic approach unless there are all images
            return (
                <ReadonlyCard
                    image={img}
                    key={index}
                    title={groupedKey.toUpperCase()}
                    value={currencyFormatter(currency).format(groupedSums[groupedKey].toFixed(currency.fractionDigits))}
                />
            );
        });
        return <div style={flexRow}>{components}</div>;
    } else {
        return noBalanceWarning;
    }
};
