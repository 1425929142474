import { ManagerPermission } from '../../permission-profiles/permission';

import AsyncTasksPage from './AsyncTasksPage';
import { bindActionCreators } from 'redux';
import State from '../../app/store/state';
import { connect } from 'react-redux';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes } from '../../utils/asyncTasks';
import { ActionCreators as BeneficiaryActionCreators } from '../beneficiaries';
import { hasFeatureAccess } from '../../login/auth';
import AsyncTasksListView from '../AsyncTasksListView';

const importMapping = {
    poNumbers: 1,
    poLineItem: 2,
    intervention: 3,
    cycle: 4,
    locationOne: 5,
    locationTwo: 6,
    locationThree: 7,
    reason: 8,
    reloadDate: 9,
    expiry: 10,
    id: 11,
    householdSize: 12,
    reloadAmount: 13,
};

function mapDispatchToProps(dispatch: any) {
    return {
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
        loadAsyncTasksList: (page, limit) =>
            dispatch(AsyncActionCreators.loadAsyncTasksList(page, limit, AsyncTasksTypes.IMPORT_BENEFICIARIES)),
        uploadFile: (data: File, dryRun?: boolean, dryRunAsyncTaskId?: string) => {
            const params: any = {
                data,
                mapping: importMapping,
                startIndex: 2,
                schedule: !dryRun,
            };

            if (dryRun) {
                params.dryRun = true;
            }
            if (dryRunAsyncTaskId) {
                params.dryRunAsyncTaskId = dryRunAsyncTaskId;
            }

            return dispatch(BeneficiaryActionCreators.importBeneficiaries(params));
        },
        clearUploadTask: bindActionCreators(BeneficiaryActionCreators.clearUploadTask, dispatch),
    };
}

function mapStateToProps(state: State) {
    const canImport = hasFeatureAccess(state.auth, ManagerPermission.beneficiariesEntitlementPark);
    const canAuthorizeTask = hasFeatureAccess(state.auth, ManagerPermission.beneficiariesEntitlementVerify);
    const canDownload = hasFeatureAccess(state.auth, ManagerPermission.beneficiariesDownload);

    return {
        canPark: canImport,
        canPost: canAuthorizeTask,
        canDownload: canDownload,
        tasks: state.asyncTasks.asyncTasks[AsyncTasksTypes.IMPORT_BENEFICIARIES],
        authorizedManagerId: state.auth.manager ? state.auth.manager.id : null,
        exampleUploadFile: 'BeneficiaryListExample.xlsx',
        name: 'Import Beneficiaries',
        type: AsyncTasksTypes.IMPORT_BENEFICIARIES,
        uploadDescription: 'Upload the Beneficiaries XLSX file by clicking the button below.',
        exampleLinkTitle: 'See example import file',
        listView: AsyncTasksListView,
        uploadTaskState: state.uploadTaskState,
        shouldRunDryRun: true,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AsyncTasksPage);
