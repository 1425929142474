import * as React from 'react';
import { EntityType, EntityUpdateRequest, ProductUpdateRequest } from './taskAuthorizations';
import { displayTime } from '../utils/utils';
import * as _ from 'lodash';
import { Link } from 'react-router';
import { getManager, loadPartner, loadVendor } from '../apiClient';
import { useEffect, useState } from 'react';
import { DownloadPermissionsButton } from '../permissions-files/DownloadPermissionsButton';
import { ChangesListRenderer } from './change-lists/change-list-renderer';

const columnStyle: React.CSSProperties = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
};

const flexRow: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
};

const flexColumn: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
};

const CommentCellHeader: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
};

const CommentCellLabel: React.CSSProperties = {
    marginRight: 5,
};

const CommentCellContainer: React.CSSProperties = {
    display: 'flex',
    marginBottom: 10,
    flexDirection: 'column',
    padding: 20,
};

const noUnderline = {
    textDecoration: 'none',
};

interface Props {
    updateRequest: EntityUpdateRequest | ProductUpdateRequest;
    readonly: boolean | { edit: boolean; park: boolean; post: boolean };
    comment?: string;
    onEdit?: () => void;
    onPark?: () => void;
    onPost: () => void;
    onReject: () => void;
    categories: string[];
}

interface ValueFieldProps {
    title: string;
    value: string;
}
function getUpdatedManager(entityId, setState) {
    (async () => {
        const manager = await getManager(entityId);
        if (manager.permissionsFiles) {
            setState(manager.permissionsFiles[0]);
        }
    })().catch((v) => v);
}

function getUpdatedVendor(entityId, setState) {
    (async () => {
        const vendor = await loadVendor(entityId);
        if (vendor.permissionsFiles) {
            setState(vendor.permissionsFiles[0]);
        }
    })().catch((v) => v);
}

function getUpdatedPartner(entityId, setState) {
    (async () => {
        const partner = await loadPartner(entityId);
        if (partner?.permissionFiles.length) {
            setState(partner.permissionFiles[0]);
        }
    })().catch((v) => v);
}

function ValueField(props: ValueFieldProps) {
    return (
        <div style={{ marginBottom: 15 }}>
            <label style={CommentCellLabel}>{props.title}</label>
            <p>{props.value}</p>
        </div>
    );
}

function renderTimeEntry(title: string, time: string, name: string) {
    return (
        <div style={flexRow}>
            <label>{title}</label>
            <p style={{ marginLeft: 5 }}>
                {displayTime(time)} by {name}
            </p>
        </div>
    );
}

function renderTimeEntries(updateRequest) {
    const { createdAt, authorizedAt, deletedAt } = updateRequest;
    const createdName = updateRequest.createdByManager ? updateRequest.createdByManager.name : '-';
    const authorizedName = updateRequest.authorizedByManager ? updateRequest.authorizedByManager.name : '-';
    const deletedName = updateRequest.deletedByManager ? updateRequest.deletedByManager.name : '-';
    return (
        <div>
            {updateRequest.createdAt && renderTimeEntry('Parked at:', createdAt, createdName)}
            {updateRequest.authorizedAt && renderTimeEntry('Posted at:', authorizedAt, authorizedName)}
            {updateRequest.deletedAt && renderTimeEntry('Rejected at:', deletedAt, deletedName)}
        </div>
    );
}

function titleForEntityType(entityType: EntityType) {
    switch (entityType) {
        case EntityType.beneficiaryDetails:
        case EntityType.beneficiarySingleBlock:
        case EntityType.beneficiarySingleUnblock:
        case EntityType.beneficiaryZeroing:
        case EntityType.beneficiaryZeroingBlock:
            return 'Beneficiary';
        case EntityType.vendorDetails:
            return 'Vendor';
        case EntityType.managerDetails:
            return 'User';
        case EntityType.permissionProfileDetails:
            return 'Permissions Profile';
        case EntityType.partnerDetails:
            return 'Partner';
    }
}

function displayedUpdateTitle(entityType: EntityType) {
    switch (entityType) {
        case EntityType.beneficiaryDetails:
            return 'Details update';
        case EntityType.beneficiarySingleBlock:
            return 'Beneficiary block';
        case EntityType.beneficiarySingleUnblock:
            return 'Beneficiary unblock';
        case EntityType.beneficiaryZeroingBlock:
            return 'Zeroing block';
        case EntityType.beneficiaryZeroing:
            return 'Zeroing balance';
        default:
            return '';
    }
}

export default function EntityDetailsUpdateRequestCell(props: Props) {
    const [permissionFile, setPermissionFile] = useState(null);
    const { updateRequest, onPost, onReject, readonly, comment, onPark, onEdit } = props;
    const isZeroingBalanceUpdate = updateRequest.entityType === EntityType.beneficiaryZeroing;
    const categoryToZeroUpdate = updateRequest.updates.find((update) => update.path === 'categories');
    const availabilityType = updateRequest.updates.find((update) => update.path === 'availabilityType');
    const expirationTokenType = availabilityType ? _.capitalize(availabilityType.newValue) : 'All';
    const categories = categoryToZeroUpdate ? categoryToZeroUpdate.newValue : props.categories;
    const entityType = titleForEntityType(updateRequest.entityType);
    useEffect(() => {
        if (entityType === 'User') {
            getUpdatedManager(updateRequest.entityId, setPermissionFile);
        } else if (entityType === 'Vendor') {
            getUpdatedVendor(updateRequest.entityId, setPermissionFile);
        } else if (entityType === 'Partner') {
            getUpdatedPartner(updateRequest.entityId, setPermissionFile);
        }
    }, []);
    return (
        <div className="shadowed-container" style={{ ...CommentCellContainer }}>
            <label>{displayedUpdateTitle(updateRequest.entityType)}</label>
            <div style={CommentCellHeader}>
                <div style={flexColumn}>
                    <div style={flexRow}>
                        <label>{titleForEntityType(updateRequest.entityType)} ID:</label>
                        <p style={{ marginLeft: 5 }}>{updateRequest.entityId}</p>
                    </div>

                    {updateRequest.title && updateRequest.title.length > 0 && Array.isArray(updateRequest.title) && (
                        <div>
                            {updateRequest.title.map((t, index) => {
                                const key = `updateRequest/tittle/${index}`;
                                return entityType === 'User' ? (
                                    <Link key={key} style={noUnderline} to={`/admin/${updateRequest.entityId}`}>
                                        ({t})
                                    </Link>
                                ) : entityType === 'Permissions Profile' ? (
                                    <Link
                                        key={key}
                                        style={noUnderline}
                                        to={{
                                            pathname: `/admin/permission-profiles/`,
                                            state: { permissionProfileId: updateRequest.entityId },
                                        }}
                                    >
                                        ({t})
                                    </Link>
                                ) : (
                                    <p key={key}>({t})</p>
                                );
                            })}
                        </div>
                    )}
                    {permissionFile && permissionFile.hasAccess && (
                        <DownloadPermissionsButton
                            asyncTaskId={permissionFile.id}
                            fileName="Open Permissions File"
                            open
                        />
                    )}
                    {updateRequest.entityType === EntityType.preParkedBeneficiary && (
                        <div style={flexRow}>
                            <label>{titleForEntityType(updateRequest.entityType)} Pre Parked by Partner ID::</label>
                            <p style={{ marginLeft: 5 }}>{updateRequest.preParkedByPartnerId}</p>
                        </div>
                    )}
                </div>
                {updateRequest.createdByManager && <div style={flexColumn}>{renderTimeEntries(updateRequest)}</div>}
            </div>
            {updateRequest.comment && <ValueField title="Comment: " value={updateRequest.comment} />}
            {!isZeroingBalanceUpdate && (
                <ChangesListRenderer entityType={updateRequest.entityType} updates={updateRequest.updates} />
            )}
            {isZeroingBalanceUpdate && (
                <label>
                    {expirationTokenType} {categories.join(', ')} values will be zeroed.
                </label>
            )}
            <div
                style={{
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    ...columnStyle,
                }}
            >
                {((!readonly && updateRequest.entityType !== EntityType.preParkedBeneficiary) ||
                    (updateRequest.entityType === EntityType.preParkedBeneficiary &&
                        (!(readonly as any).edit || !(readonly as any).park || !(readonly as any).post))) && (
                    <div className="wfp-form--actions">
                        {updateRequest.createdByManager &&
                            (!readonly || (typeof readonly !== 'boolean' ? !(readonly as any).post : false)) && (
                                <button
                                    className="wfp-btn negative-color"
                                    onClick={() => {
                                        onReject();
                                    }}
                                    type="button"
                                >
                                    Reject
                                </button>
                            )}
                        {updateRequest.entityType === EntityType.preParkedBeneficiary &&
                            !updateRequest.createdByManager &&
                            !(readonly as any).edit && (
                                <button
                                    className="wfp-btn"
                                    onClick={() => {
                                        onEdit();
                                    }}
                                    style={{ marginLeft: 15 }}
                                    type="button"
                                >
                                    Edit
                                </button>
                            )}
                        {updateRequest.createdByManager &&
                        (!readonly || (typeof readonly !== 'boolean' ? !(readonly as any).post : false)) ? (
                            <button
                                className="wfp-btn"
                                onClick={() => {
                                    onPost();
                                }}
                                style={{ marginLeft: 15 }}
                                type="button"
                            >
                                Post
                            </button>
                        ) : updateRequest.entityType === EntityType.preParkedBeneficiary &&
                          !updateRequest.createdByManager &&
                          (!readonly || (typeof readonly !== 'boolean' ? !(readonly as any).park : false)) ? (
                            <button
                                className="wfp-btn"
                                onClick={() => {
                                    onPark();
                                }}
                                style={{ marginLeft: 15 }}
                                type="button"
                            >
                                Park
                            </button>
                        ) : null}
                    </div>
                )}
                {comment && <p style={{ marginTop: 20, fontSize: 14 }}>{comment}</p>}
            </div>
        </div>
    );
}
