import * as React from 'react';
import { Modal } from 'react-bootstrap';

import { TransactionChain } from '../transactions';
import { Form, Input, ValidationContext } from '../../utils/inputs';
import { currencyFormatter } from '../../utils/utils';
import { BigNumber } from '../../utils/bigNumber';
import { PredefinedCurrency } from '../../app/appConfig';
import { countries } from '../../utils/countries';

interface Props {
    transactionChain: TransactionChain;
    currency: PredefinedCurrency;
    onClose: () => void;
    onAdjust: (
        transaction: TransactionChain,
        source: string,
        reason: string,
        amount: BigNumber,
        currency: PredefinedCurrency
    ) => void;
    country: countries;
}

interface State {
    reason: string;
    source: string;
    amount: string;

    validationContext: ValidationContext;
}

export default class TransactionAdjustmentDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const amount = props.country === countries.BANGLADESH ? '0' : '';
        this.state = {
            source: '',
            reason: '',
            amount,
            validationContext: new ValidationContext(),
        };
    }

    componentDidUpdate() {
        const originalValue = this.props.transactionChain.amount;
        this.state.validationContext.pushError({
            path: 'amount',
            type: 'equal',
            value: originalValue,
        });
    }

    handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const value = target.value;
        this.setState({ ...this.state, [target.name]: value });
    };

    renderInputFields() {
        const { transactionChain, country } = this.props;
        const originalTransaction = transactionChain.items && transactionChain.items[0];

        const { source, reason, amount, validationContext } = this.state;

        return (
            <div>
                {originalTransaction && (
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Original Amount</label>
                        <div className="col-sm-8 mv1">
                            {currencyFormatter(this.props.currency).format(originalTransaction.amount.toNumber())}
                        </div>
                    </div>
                )}
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Current Amount</label>
                    <div className="col-sm-8 mv1">
                        {currencyFormatter(this.props.currency).format(transactionChain.amount.toNumber())}
                    </div>
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">New Amount</label>
                    <div className="col-sm-8">
                        {this.props.currency && this.props.currency.fractionDigits && (
                            <Input
                                className="form-control"
                                context={validationContext}
                                disabled={country === countries.BANGLADESH}
                                errorMessages={{
                                    min: 'Amount must be positive',
                                    max: 'Amount must be less than 10 000',
                                    equal: 'Amount must be different from the original',
                                }}
                                isFocused={true}
                                max={10000}
                                min={0}
                                name="amount"
                                onChange={this.handleInputChange}
                                required={true}
                                step={new BigNumber(10).exponentiatedBy(-this.props.currency.fractionDigits).toNumber()}
                                type="number"
                                value={amount}
                            />
                        )}
                    </div>
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Source</label>
                    <div className="col-sm-8">
                        <Input
                            className="form-control"
                            context={validationContext}
                            name="source"
                            onChange={this.handleInputChange}
                            required={true}
                            type="text"
                            value={source}
                        />
                    </div>
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Reason</label>
                    <div className="col-sm-8">
                        <Input
                            className="form-control"
                            context={validationContext}
                            name="reason"
                            onChange={this.handleInputChange}
                            required={true}
                            type="text"
                            value={reason}
                        />
                    </div>
                </div>
            </div>
        );
    }

    onInitiateAdjustment(event) {
        event.preventDefault();

        const { transactionChain, currency } = this.props;
        const { source, reason } = this.state;
        const amount = new BigNumber(this.state.amount);
        if (!amount.isNegative() && !amount.isEqualTo(transactionChain.amount)) {
            this.props.onAdjust(transactionChain, source, reason, amount, currency);
        }
    }

    render() {
        const { onClose } = this.props;
        const { validationContext } = this.state;

        return (
            <div>
                <div className="fade modal-backdrop in" />
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title>Transaction Adjustment</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="wfp-form"
                        context={validationContext}
                        onSubmit={this.onInitiateAdjustment.bind(this)}
                    >
                        <Modal.Body>{this.renderInputFields()}</Modal.Body>
                        <Modal.Footer>
                            <button className="wfp-btn" onClick={onClose} style={{ marginRight: 10 }} type="button">
                                Close
                            </button>
                            <button className="wfp-btn--primary" type="submit">
                                Park
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal.Dialog>
            </div>
        );
    }
}
