import { InformationDialog } from '../../utils/Dialogs';
import * as React from 'react';

export function PendingUpdateModal({ groupName, onClose }) {
    return (
        <InformationDialog
            message={<PendingUpdateMessage group={groupName} />}
            onClose={onClose}
            title="Pending updates"
        />
    );
}
const PendingUpdateMessage = ({ group }) => (
    <>
        <strong>{group} </strong> product group cannot be updated because another update is pending
    </>
);
