import * as React from 'react';

import {
    PaymentRequest,
    PaymentRequestStatus,
    PaymentStatusTransition,
    SapPaymentRequest,
    SapPaymentRequestStatus,
} from './paymentRequests';
import { stopPropagation } from '../utils/events';

interface Props {
    request: PaymentRequest;

    shouldRedirect: boolean;
    possibleStatuses: Array<PaymentStatusTransition>;

    isSapModuleActive: boolean;

    showPoItemDialog?: () => void;
    changePaymentRequestStatus: (
        paymentRequestId: string,
        targetStatus: PaymentRequestStatus,
        routeOverride?: string,
        paymentSapDetails?: Array<SapPaymentRequest>
    ) => void;
}

export default function PaymentRequestActions(props: Props) {
    const { changePaymentRequestStatus, request, showPoItemDialog, possibleStatuses, isSapModuleActive } = props;

    const determineStatusChange = (possibleStatus: PaymentStatusTransition) => {
        if (!isSapModuleActive) {
            return (
                <a onClick={stopPropagation(() => changePaymentRequestStatus(request.id, possibleStatus.to))}>
                    {possibleStatus.action}
                </a>
            );
        } else {
            if (possibleStatus.to === PaymentRequestStatus.IssuePaymentPark) {
                return <a onClick={() => showPoItemDialog()}>{possibleStatus.action}</a>;
            } else if (possibleStatus.to === PaymentRequestStatus.PaidParked) {
                const renderPaymentParkPaid = (request: PaymentRequest) => {
                    if (request && request.sapPaymentRequests) {
                        const sapPaymentRequests = request.sapPaymentRequests;
                        const sapPaymentRequestRunOrCompleted =
                            sapPaymentRequests &&
                            sapPaymentRequests.every((sap) =>
                                [SapPaymentRequestStatus.Run, SapPaymentRequestStatus.Completed].includes(sap.status)
                            );

                        const sapPaymentRequestFailedOrUnknown =
                            sapPaymentRequests &&
                            sapPaymentRequests.find((sap) =>
                                [SapPaymentRequestStatus.Failed, SapPaymentRequestStatus.Unknown].includes(sap.status)
                            );

                        if (sapPaymentRequestRunOrCompleted) {
                            return (
                                <a
                                    onClick={stopPropagation(() =>
                                        changePaymentRequestStatus(request.id, PaymentRequestStatus.PaidParked)
                                    )}
                                >
                                    Park Paid
                                </a>
                            );
                        } else if (sapPaymentRequestFailedOrUnknown) {
                            return (
                                <label>
                                    {' '}
                                    <span style={{ color: 'red' }}>*</span>Cannot park paid - contact WINGS super user
                                </label>
                            );
                        } else {
                            return (
                                <label>
                                    {' '}
                                    <span style={{ color: 'red' }}>*</span>
                                    {'Cannot park paid - payment run has not yet been performed in WINGSII.'}
                                </label>
                            );
                        }
                    }
                };
                return <span>{renderPaymentParkPaid(request)}</span>;
            } else {
                return (
                    <a onClick={stopPropagation(() => changePaymentRequestStatus(request.id, possibleStatus.to))}>
                        {possibleStatus.action}
                    </a>
                );
            }
        }
    };
    if (possibleStatuses && possibleStatuses.length > 0) {
        return (
            <div>
                {possibleStatuses.map((possibleStatus: PaymentStatusTransition, index: number) => {
                    return <div key={index}>{determineStatusChange(possibleStatus)}</div>;
                })}
            </div>
        );
    }
    return <span />;
}
