import * as React from 'react';

import { isZeroAndPaidPayment, PaymentRequest, PaymentRequestStatus } from './paymentRequests';
import { AuthState } from '../login/auth';
import { compose, currencyFormatter, wfpFormat } from '../utils/utils';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';
import { withNavigate } from '@wfp-common/hooks/withNavigate';

interface Props {
    auth: AuthState;
    payments: PaymentRequest[];
    periodEnd: Date;
    category: string;
    isSapModuleActive: boolean;
    currency: EntitlementCurrencyConfig;
    changePaymentRequestStatus: (
        paymentRequestId: string,
        targetStatus: PaymentRequestStatus,
        routeOverride?: string
    ) => void;
    navigate: NavigateHook;
}

class PaymentRequestsTable extends React.Component<Props, any> {
    private _onRequestChosen(request: PaymentRequest) {
        this.props.navigate('/payments/' + request.id);
    }

    private renderTableHeader() {
        return (
            <thead>
                <tr>
                    <th>Vendor</th>
                    <th>Total Outstanding For Period</th>
                    <th>Discount</th>
                    <th>WFP Payable</th>
                    <th>Current Status</th>
                </tr>
            </thead>
        );
    }

    private determineStatus(request: PaymentRequest): string {
        if (request.status === PaymentRequestStatus.NotGenerated && request.isParkGeneratingAllowed) {
            return 'Pending generation';
        } else {
            return request.status;
        }
    }

    private renderTableBody() {
        const formatter = currencyFormatter(this.props.currency).format;
        const payments = this.props.payments.filter((payment) => !isZeroAndPaidPayment(payment));
        return (
            <tbody>
                {payments &&
                    payments.map((request) => {
                        return (
                            <tr
                                className={'cursor-pointer'}
                                key={`${request.vendor.id}-${request.id}`}
                                onClick={() => this._onRequestChosen(request)}
                            >
                                <td>{request.vendor.name}</td>
                                <td>{formatter(request.outstandingAmount)}</td>
                                <td>{formatter(request.bankFeeAmount)}</td>
                                <td>{formatter(request.payableAmount)}</td>
                                <td>{compose(wfpFormat, this.determineStatus)(request)}</td>
                            </tr>
                        );
                    })}
            </tbody>
        );
    }

    render() {
        return (
            <table className="wfp-table--striped mt3 table-hover">
                {this.renderTableHeader()}
                {this.renderTableBody()}
            </table>
        );
    }
}

export default withNavigate(PaymentRequestsTable, 'PaymentRequestsTable');
