import * as React from 'react';
import { useSelector } from 'react-redux';
import State from '../app/store/state';
import { Permission } from '../app/appConfig';
import { ManagerPermission } from './permission';

interface Props {
    editDisabled: boolean;
    permissions: ManagerPermission[];
    onPermissionsChanged?: (permissions: ManagerPermission[]) => void;
}
export default function PermissionsList({ permissions, editDisabled, onPermissionsChanged }: Props) {
    const allPermissions = useSelector((state: State) => state.appConfig.permissions);
    const handleChange = (permission: Permission) => (event) => {
        if (!onPermissionsChanged) return;
        const { checked } = event.target;
        if (checked) {
            const { requiredPermissions } = permission.metadata;
            const newPermissionsArray = Array.from(
                new Set([...permissions, ...requiredPermissions, permission.permissionName])
            );
            return onPermissionsChanged(newPermissionsArray as ManagerPermission[]);
        }
        const newFeaturesAccess = [...permissions].filter((feature) => feature !== permission.permissionName);
        const dependentFeatures = allPermissions[permission.metadata.section]
            .filter((permission) => permission.metadata.requiredPermissions.includes(permission.permissionName))
            .map((permission) => permission.permissionName);
        const difference = newFeaturesAccess.filter((permission) => !dependentFeatures.includes(permission));
        return onPermissionsChanged(difference);
    };
    return (
        <>
            {Object.entries(allPermissions).map(([sectionHeader, features]) => (
                <div key={sectionHeader}>
                    <h6 style={{ textDecoration: 'underline' }}>{sectionHeader}</h6>
                    {features.map((feature) => (
                        <div key={feature.permissionName} title={feature.metadata.explanation}>
                            <input
                                checked={permissions?.includes(feature.permissionName as ManagerPermission)}
                                disabled={editDisabled}
                                name={feature.permissionName}
                                onChange={handleChange(feature)}
                                type="checkbox"
                            />
                            <label htmlFor={feature.permissionName}>{feature.metadata.displayText}</label>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}
