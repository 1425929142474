import * as React from 'react';
import EntityUpdateAuthorization, { UpdateRowConfiguration } from '../authorization/EntityUpdatesAuthorizationList';
import { UpdateStateListType, EntityUpdateRequest, EntityType } from '../authorization/taskAuthorizations';
import AppState from '../app/store/state';
import { AuthState } from '../login/auth';
import configurationForBeneficiaryUpdate from '../authorization/configurationForBeneficiaryUpdate';
import { bindActionCreators } from 'redux';
import { EditBeneficiaryCreators } from './edit/editBeneficiaryActionCreator';
import { connect } from 'react-redux';
import { PagedState } from '../utils/paging';
import { EntitlementCategoriesConfig } from '../app/appConfig';

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

interface Props {
    auth: AuthState;
    beneficiaryId: string;
    entityUpdateRequests: PagedState<EntityUpdateRequest>;
    confirmBeneficiaryUpdate: (entityUpdateRequest: EntityUpdateRequest) => void;
    rejectBeneficiaryUpdate: (entityUpdateRequest: EntityUpdateRequest, comment: string) => void;
    loadBeneficiaryUpdates: (beneficiaryId: string, page: number, limit: number) => void;
    categories: EntitlementCategoriesConfig;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

const EntityUpdateRequestPageLimit = 10;

class BeneficiaryPendingActions extends React.Component<Props, State> {
    UNSAFE_componentWillMount() {
        this.loadEntityUpdateRequests(1);
    }

    loadEntityUpdateRequests(page: number) {
        const { loadBeneficiaryUpdates, beneficiaryId } = this.props;
        loadBeneficiaryUpdates(beneficiaryId, page, EntityUpdateRequestPageLimit);
    }

    configurationForUpdateRequest(entityUpdateRequest: EntityUpdateRequest): UpdateRowConfiguration {
        if (entityUpdateRequest.authorizedAt || entityUpdateRequest.deletedAt) {
            return new UpdateRowConfiguration(true, null);
        }
        const isCreator = entityUpdateRequest.createdByManager.id === this.props.auth.manager.id;
        if (isCreator) {
            const comment = isCreator ? 'You cannot post an update that you parked yourself' : null;
            return new UpdateRowConfiguration(isCreator, comment);
        }
        switch (entityUpdateRequest.entityType) {
            case EntityType.beneficiaryDetails:
            case EntityType.beneficiaryZeroing:
            case EntityType.beneficiaryZeroingBlock:
            case EntityType.beneficiarySingleBlock:
            case EntityType.beneficiarySingleUnblock: {
                return configurationForBeneficiaryUpdate(entityUpdateRequest, this.props.auth);
            }
            default:
                return new UpdateRowConfiguration(false, null);
        }
    }

    render() {
        const { entityUpdateRequests, confirmBeneficiaryUpdate, rejectBeneficiaryUpdate } = this.props;
        return (
            <div>
                <h6>Pending actions</h6>
                {this.props.auth.manager && (
                    <EntityUpdateAuthorization
                        categories={this.props.categories.categories}
                        configurationForUpdate={this.configurationForUpdateRequest.bind(this)}
                        entityUpdateRequests={entityUpdateRequests}
                        listType={UpdateStateListType.Requested}
                        managerId={this.props.auth.manager.id}
                        onConfirmUpdateRequest={confirmBeneficiaryUpdate}
                        onPageChanged={this.loadEntityUpdateRequests.bind(this)}
                        onRejectUpdateRequest={rejectBeneficiaryUpdate}
                    />
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadBeneficiaryUpdates: bindActionCreators(EditBeneficiaryCreators.loadBeneficiaryUpdates, dispatch),
        confirmBeneficiaryUpdate: bindActionCreators(EditBeneficiaryCreators.confirmBeneficiaryUpdate, dispatch),
        rejectBeneficiaryUpdate: bindActionCreators(EditBeneficiaryCreators.rejectBeneficiaryUpdate, dispatch),
    };
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
    return {
        beneficiaryId: ownProps.location.state.beneficiaryId,
        auth: state.auth,
        entityUpdateRequests: state.editBeneficiary.entityUpdateRequests,
        categories: state.appConfig.entitlementsConfig,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryPendingActions as any);
