import { useSelector } from 'react-redux';
import State from '../../app/store/state';
import { useEffect } from 'react';
import { useActionCreator } from '../../utils/useActionCreator';

export function useTransactionDetails(
    transactionId: string,
    actionCreator: (dispatch) => (transactionId: string) => void
) {
    const transactionDetails = useSelector((state: State) => state.transactionDetails) as any[];
    const loadTransactionDetails = useActionCreator(actionCreator, true);
    useEffect(() => {
        if (transactionId) {
            loadTransactionDetails(transactionId);
        }
    }, [transactionId]);
    return transactionDetails ? transactionDetails : [];
}
