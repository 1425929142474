import styled from 'styled-components';

export const Controls = styled.div`
    margin-top: 2.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    label {
        //max-width: 20%;
        width: 100%;
        flex: 1;
        display: grid;
        gap: 0.25rem;
    }
    & .Select {
        span[aria-label='Clear value'] {
            display: none;
            pointer-events: none;
        }
    }
    a.ml2 {
        align-self: flex-end;
    }
    .controls__filters {
        width: 100%;
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(4, 1fr);
        label.btn-container {
            place-content: center;
            justify-content: end;
            grid-column: 4 / 5;
        }
    }
`;

export const StatusContainer = styled.div`
    button {
        all: unset;
        padding: 0.25rem;
        color: #036fdc;
        text-decoration: #e8e8e8 solid underline;
        text-underline-position: under;
        line-height: 1.25;
        &:hover {
            text-decoration-color: #ffc759;
        }
    }
`;
