import * as React from 'react';
import { Form, Input, ValidationContext } from '../utils/inputs';
import PermissionsList from './permissions-list';
import { displayTime } from '../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import State from '../app/store/state';
import { useEffect, useState } from 'react';
import Switch from 'rc-switch';
import {
    authorizePermissionProfile,
    cancelPermissionProfile,
    createPermissionProfileUpdate,
    rejectPermissionProfile,
} from '../apiClient';
import { errorAction, successAction } from '../utils/notifications';
import { InformationDialog } from '../utils/Dialogs';
import PermissionProfile from './profile';

interface Props {
    permissionProfile: PermissionProfile;
    refetchPermissionProfiles: () => void;
}

const PermissionProfileDetailsPage: React.FC<Props> = ({ permissionProfile, refetchPermissionProfiles }) => {
    const dispatch = useDispatch();
    const validationContext = new ValidationContext();

    const managers = useSelector((state: State) => state.managers.managerNickNameList);
    const currentManager = useSelector((state: State) => state.auth.manager);

    const [toggleEdit, setToggleEdit] = useState(false);
    const [newPermissionProfile, setNewPermissionProfile] = useState(permissionProfile);

    const [modalInfoMessage, setModalInfoMessage] = useState('');

    const isEditable = !permissionProfile.authorizedAt;

    useEffect(() => {
        setToggleEdit(false);
        setNewPermissionProfile(permissionProfile);
    }, [permissionProfile]);

    const toggleButtonHandler = () => {
        if (!toggleEdit && permissionProfile.pendingEntityUpdates.length > 0) {
            setModalInfoMessage(
                'Permission profile cannot be currently edited because another edit is waiting to be posted'
            );
        } else {
            setToggleEdit(!toggleEdit);
        }
    };

    const onSubmit = () => {
        createPermissionProfileUpdate(newPermissionProfile)
            .then(() => {
                dispatch(successAction('The update request was sent successfully.'));
                setToggleEdit(false);
                refetchPermissionProfiles();
                window.scrollTo(0, 0);
            })
            .catch(() => {
                dispatch(errorAction('Something went wrong.'));
                window.scrollTo(0, 0);
            });
    };

    const displayManagerName = (id: number) => {
        const manager = managers.find((manager) => manager.id === id.toString());
        if (!manager) return '';
        return manager.firstName + ' ' + manager.lastName;
    };

    const activeProfileHandler = () => {
        if (newPermissionProfile.managers?.length > 0) {
            setModalInfoMessage('Can not deactivate the profile. There are managers assigned to it.');
        } else {
            setNewPermissionProfile({
                ...newPermissionProfile,
                active: !newPermissionProfile.active,
            });
        }
    };

    const authorizeProfile = () => {
        authorizePermissionProfile(permissionProfile.id)
            .then(() => {
                refetchPermissionProfiles();
                dispatch(successAction('Permission profile was authorized successfully.'));
                window.scrollTo(0, 0);
            })
            .catch(() => {
                dispatch(errorAction('Something went wrong.'));
                window.scrollTo(0, 0);
            });
    };

    const rejectProfile = () => {
        rejectPermissionProfile(permissionProfile.id)
            .then(() => {
                refetchPermissionProfiles();
                dispatch(successAction('Permission profile was rejected successfully.'));
                window.scrollTo(0, 0);
            })
            .catch(() => {
                dispatch(errorAction('Something went wrong.'));
                window.scrollTo(0, 0);
            });
    };

    const cancelProfile = () => {
        cancelPermissionProfile(permissionProfile.id)
            .then(() => {
                refetchPermissionProfiles();
                dispatch(successAction('Permission profile was canceled successfully.'));
                window.scrollTo(0, 0);
            })
            .catch(() => {
                dispatch(errorAction('Something went wrong.'));
                window.scrollTo(0, 0);
            });
    };

    const renderModalInfo = () => (
        <InformationDialog message={modalInfoMessage} onClose={() => setModalInfoMessage('')} title="Can not update" />
    );

    return (
        <div style={{ margin: 'auto auto' }}>
            {modalInfoMessage && renderModalInfo()}
            <div className={'row'}>
                <label className="col-sm-4 ta-right">Toggle Edit</label>
                <div className="col-sm-8 pv1">
                    <Switch checked={toggleEdit} disabled={isEditable} onClick={toggleButtonHandler} />
                </div>
            </div>
            <Form className="wfp-form" context={validationContext} onSubmit={onSubmit}>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Profile ID</label>
                    <label className="col-sm-8 normal">{newPermissionProfile.id}</label>
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Profile Name</label>
                    <div className="col-sm-8">
                        {toggleEdit && (
                            <Input
                                className="form-control"
                                context={validationContext}
                                isFocused={true}
                                name={'name'}
                                onChange={(e) =>
                                    setNewPermissionProfile({ ...newPermissionProfile, name: e.target.value })
                                }
                                required={true}
                                type={'text'}
                                value={newPermissionProfile.name}
                            />
                        )}
                        {!toggleEdit && <label className="normal">{newPermissionProfile.name}</label>}
                    </div>
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Active</label>
                    <div className="col-sm-8 pv1">
                        {
                            <Switch
                                checked={newPermissionProfile.active === true}
                                disabled={!toggleEdit}
                                onChange={activeProfileHandler}
                            />
                        }
                    </div>
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Status</label>
                    <div className="col-sm-8">
                        <label className="normal">
                            Parked at {displayTime(newPermissionProfile.createdAt)}
                            <div>
                                by{' '}
                                {newPermissionProfile.createdByManagerId
                                    ? displayManagerName(newPermissionProfile.createdByManagerId)
                                    : 'System'}
                            </div>
                        </label>
                        {newPermissionProfile.authorizedAt ? (
                            <label className="normal">
                                Posted at {displayTime(newPermissionProfile.authorizedAt)}
                                <div>
                                    by{' '}
                                    {newPermissionProfile.authorizedByManagerId
                                        ? displayManagerName(newPermissionProfile.authorizedByManagerId)
                                        : 'System'}
                                </div>
                            </label>
                        ) : newPermissionProfile.rejectedAt ? (
                            <label className="normal">
                                Rejected at {displayTime(newPermissionProfile.rejectedAt)}
                                {newPermissionProfile.rejectedByManagerId && (
                                    <div>by {displayManagerName(newPermissionProfile.rejectedByManagerId)}</div>
                                )}
                            </label>
                        ) : currentManager &&
                          currentManager.id !== newPermissionProfile.createdByManagerId.toString() ? (
                            <div>
                                <span>
                                    <a onClick={() => authorizeProfile()} style={{ color: 'green', marginRight: 10 }}>
                                        Post
                                    </a>
                                </span>
                                <span>
                                    <a onClick={() => rejectProfile()} style={{ color: 'red', marginLeft: 10 }}>
                                        Reject
                                    </a>
                                </span>
                            </div>
                        ) : (
                            <>
                                <span>
                                    <a onClick={() => cancelProfile()} style={{ color: 'red', marginLeft: 10 }}>
                                        Cancel
                                    </a>
                                </span>
                                <label>
                                    {' '}
                                    <span style={{ color: 'red' }}>*</span>You cannot post it, because you parked it.
                                </label>
                            </>
                        )}
                    </div>
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Permissions</label>
                    <div className="col-sm-8">
                        <PermissionsList
                            editDisabled={!toggleEdit}
                            onPermissionsChanged={(newPermissions) =>
                                setNewPermissionProfile({ ...newPermissionProfile, permissions: newPermissions })
                            }
                            permissions={newPermissionProfile.permissions}
                        />
                    </div>
                </div>
                {toggleEdit && (
                    <div className="wfp-form--actions text-center">
                        <div className="col-sm-8 col-sm-offset-4">
                            <button className="wfp-btn--primary" type="submit">
                                Park Changes
                            </button>
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
};

PermissionProfileDetailsPage.displayName = 'PermissionProfileDetailsPage';

export { PermissionProfileDetailsPage };
