import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';

import {
    ActionCreators as PartnerActionCreators,
    Partner,
    PartnerGeneralInfo,
    PartnerName,
    PartnerUserName,
    updatePartner,
} from './partners';

import { ActionCreators } from './partnersActions';
import { getPartnerActivityLogs } from '../apiClient';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { ActionCreators as ManagerActionCreators, ManagerNickName } from '../managers/managers';
import State from '../app/store/state';
import { InformationDialog } from '../utils/Dialogs';
import { ExternalValidationError } from '../utils/inputs';
import { PagedState } from '../utils/paging';
import { AuthorizationSideMenu, AuthorizationSideMenuProps } from '../utils/SideMenu';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { ManagerPermission } from '../permission-profiles/permission';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

interface OwnProps {
    params: { partnerId: string };
}

enum PartnerTab {
    GeneralInformation = 'General Information',
    PartnerPanel = 'Partner Panel',
    ActivityLogs = 'Activity Logs',
    PermissionFiles = 'Permission Files',
}

const urlPathForTab = {
    [PartnerTab.GeneralInformation]: 'general',
    [PartnerTab.PartnerPanel]: 'panel',
    [PartnerTab.ActivityLogs]: 'activity',
    [PartnerTab.PermissionFiles]: 'permission-files',
};

function EditPartnerSideMenu(props: AuthorizationSideMenuProps<any>) {
    return AuthorizationSideMenu(props);
}

interface Props {
    loadPartner: (partnerId: string, withPartnerUsers?: boolean) => void;
    savePartner: (partner: any) => void;
    cancelPark: (id: string) => void;
    redirectToPage: (url: string) => void;
    partnerId: string;
    routes: { path: string }[];
    loadManagersNickNames: () => void;
    loadPartnersNames: (sortOptions: SORT_OPTIONS) => void;
    loadPartnerUsersNames: (sortOptions: SORT_OPTIONS) => void;

    validationError?: ExternalValidationError;
    partner: Partner;
    auth: AuthState;
    managerNickNameList: Array<ManagerNickName>;
    partnerUsersList: Array<any>;
    authorizePartner: () => (vendorId: string) => (dispatch: any) => Promise<void>;
    partnersNames: PartnerName[];
    partnerUsersNames: PartnerUserName[];
}

interface PartnerEditPageState {
    editDisabled: boolean;
    hasPendingDetailsUpdate: boolean;
    activeTab: { text: PartnerTab; counter: string | null };
    searchValue: string;
    activityLogs: PagedState<any>;
}

class PartnerEditPage extends React.Component<Props, PartnerEditPageState> {
    UNSAFE_componentWillMount() {
        const partnerId = this.props.partnerId;
        if ((!this.props.partner || !this.props.partner.id) && !this.isCreatePartnerPage()) {
            this.props.loadPartner(partnerId, false);
        }
    }

    componentDidMount(): void {
        if (!this.isCreatePartnerPage()) {
            this.props.loadPartner(this.props.partnerId, false);
        }
    }

    isCreatePartnerPage() {
        return this.props.partnerId === 'new';
    }

    constructor(props) {
        super(props);
        this.state = this.createDefaultState();
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Partner details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending updates"
            />
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.partnerId !== this.props.partnerId) {
            this.setState(this.createDefaultState());
        }
    }

    createDefaultState() {
        const editDisabled = !this.isCreatePartnerPage();
        return {
            searchValue: '',
            activeTab: { text: PartnerTab.GeneralInformation, counter: null },
            entitlements: [],
            editDisabled: editDisabled,
            hasPendingDetailsUpdate: false,
            vendorGeneralInfo: null,
            vendorPaymentInfo: null,
            activityLogs: new PagedState<any>(),
        };
    }

    loadActivityLogs = (page, limit) => {
        const partnerId = this.props.partnerId;
        return getPartnerActivityLogs(partnerId, page, limit).then((result) => {
            this.setState({ activityLogs: result });
        });
    };

    renderSidemenu() {
        const activeTab = this.activeMenuTab();
        const subtabs = [
            { text: PartnerTab.GeneralInformation, counter: 0 },
            { text: 'Partner Panel', counter: Number(this.props.partner?.requestCount || 0) },
            { text: PartnerTab.ActivityLogs, counter: 0 },
            { text: PartnerTab.PermissionFiles, counter: 0 },
        ];

        return (
            <div>
                <EditPartnerSideMenu
                    activeTab={activeTab}
                    onSelectTab={(activeTab) => {
                        this.handleTabChange(activeTab);
                    }}
                    tabs={subtabs}
                />
            </div>
        );
    }

    handleTabChange(activeTab) {
        let path = '';

        this.setState({ activeTab: activeTab });

        switch (activeTab) {
            case PartnerTab.GeneralInformation:
                path = 'general';
                break;
            case PartnerTab.PartnerPanel:
                path = 'panel';
                break;
            case PartnerTab.ActivityLogs:
                path = 'activity';
                break;
            case PartnerTab.PermissionFiles:
                path = 'permission-files';
                break;
        }

        this.props.redirectToPage(`/partners/${this.props.partnerId}/${path}`);
    }

    renderBreadcrumbs() {
        const { partner } = this.props as any;
        return (
            <div className="row col-sm-12">
                <nav className="wfp-breadcrumbs col-sm-6">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/partners">
                                <span>Partners</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{`Edit Partner ${
                                partner.id ? '"' + partner.id + '"' : ''
                            }`}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        );
    }

    savePartnerData(data) {
        const oldPartner = this.props.partner;
        const updatedPartner = updatePartner(oldPartner, data);

        this.props.savePartner(updatedPartner);
    }

    activeMenuTab(): PartnerTab {
        const { routes } = this.props;
        const lastUrlPart = routes[routes.length - 1].path;
        for (const key of Object.keys(urlPathForTab)) {
            if (urlPathForTab[key] === lastUrlPart) {
                return key as PartnerTab;
            }
        }
    }

    render() {
        const { auth, partner, validationError, authorizePartner } = this.props;
        const { hasPendingDetailsUpdate } = this.state;
        const isEditPartner = !this.isCreatePartnerPage();
        const parkEnabled = hasFeatureAccess(auth, ManagerPermission.partnerPark);
        const postEnabled = hasFeatureAccess(auth, ManagerPermission.partnerPost);
        const partnerGeneralInfo = new PartnerGeneralInfo(partner);

        const generalInfoProps = {
            partnerGeneralInfo,
            onSaved: this.savePartnerData.bind(this),
            validationError: validationError,
            parkEnabled: parkEnabled,
            postEnabled: postEnabled,
            isNewPartner: this.isCreatePartnerPage(),
            managerId: auth && auth.manager ? auth.manager.id : null,
            cancelPark: this.props.cancelPark.bind(this),
            authState: auth,
            authorize: authorizePartner,
        };

        const activityLogsProps = {
            activityLogs: this.state.activityLogs,
            loadActivityLogs: this.loadActivityLogs,
            managersNickNames: this.props.managerNickNameList,
            isManager: false,
            loadManagersNickNames: this.props.loadManagersNickNames,
            auth: auth,
            downloadRestriction: [ManagerPermission.vendorsDownload],
            partnersNames: this.props.partnersNames,
            loadPartnersNames: this.props.loadPartnersNames,
            loadPartnerUsersNames: this.props.loadPartnerUsersNames,
            partnerUsersNames: this.props.partnerUsersNames,
        };

        const childrenWithProps = React.Children.map(this.props.children, (child) => {
            let props: any = { someProp: 'myText' };
            const path = (child as any).props.route.path;

            switch (path) {
                case 'general':
                    props = generalInfoProps;
                    break;
                case 'activity':
                    props = activityLogsProps;
                    break;
                case 'permission-files':
                    props = { partner: partnerGeneralInfo };
                    break;
            }

            return React.cloneElement(child as React.ReactElement<any>, props);
        });

        return (
            <main>
                {this.renderBreadcrumbs()}
                {hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}
                <div className={'row'}>
                    {isEditPartner && (
                        <div className="col-sm-3">
                            <h5 style={{ marginTop: 0, marginBottom: 0 }}>Partner {this.props.partner.name}</h5>
                            {this.renderSidemenu()}
                        </div>
                    )}
                    <div className="col-sm-9" style={{ marginBottom: 150 }}>
                        {childrenWithProps}
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        partner: state.partners.list.find((v) => v.id.toString() === ownProps.params.partnerId) || new Partner(),
        validationError: state.vendors.validationError,
        auth: state.auth,
        managerNickNameList: state.managers.managerNickNameList,
        partnerUsersList: state.partnerUsers.list,
        partnerId: ownProps.params.partnerId,
        partnersNames: state.partners.nameList,
        partnerUsersNames: state.partners.partnerUsersNamesList,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadPartner: bindActionCreators(ActionCreators.loadPartner, dispatch),
        savePartner: bindActionCreators(PartnerActionCreators.savePartner, dispatch),
        authorizePartner: bindActionCreators(ActionCreators.authorizePartner, dispatch),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        cancelPark: bindActionCreators(ActionCreators.cancelPartner, dispatch),
        loadPartnersNames: bindActionCreators(ActionCreators.loadPartnersNames, dispatch),
        loadPartnerUsersNames: bindActionCreators(ActionCreators.loadPartnerUsersName, dispatch),
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerEditPage as any);
