import * as React from 'react';
import { Input } from '../../utils/inputs';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from '../../utils/utils';
import Switch from 'rc-switch';
import { Partner, PartnerStatuses } from '../partners';

export function SwitchToggle({ checked, disabled, onClick }) {
    return (
        <div className={'row'}>
            <label className="col-sm-2 ta-right">Toggle edit</label>
            <div className="col-sm-6 pv1">
                <Switch checked={checked} disabled={disabled} onClick={onClick} />
            </div>
        </div>
    );
}

export function PanelAccessSwitch({ checked, disabled, onClick, name }) {
    return (
        <div className="wfp-form--group row">
            <label className="col-sm-2 ta-right">Panel Access</label>
            <div className="col-sm-6 pv1">
                <Switch checked={checked} disabled={disabled} name={name} onClick={onClick} />
            </div>
        </div>
    );
}

export function InputWrapper({ onChange, value, name, label, isFocused, type }) {
    return (
        <div className="wfp-form--group row">
            <label className="col-sm-2 ta-right" htmlFor="name">
                {label}
            </label>
            <div className="col-sm-6">
                <Input
                    className="form-control"
                    isFocused={isFocused}
                    name={name}
                    onChange={onChange}
                    type={type}
                    value={value}
                />
            </div>
        </div>
    );
}

export function Button({ children, disabled }) {
    return (
        <div className="wfp-form--actions text-center">
            <div className="col-sm-10">
                <button className="wfp-btn--primary" disabled={disabled} type="submit">
                    {children}
                </button>
            </div>
        </div>
    );
}

export function usePartnerForm({ fields, onSubmit }) {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(() => fields.reduce((acc, val) => ({ ...acc, [val.name]: val.initialValue || '' }), {}));
    }, [fields]);
    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        setData((state) => {
            onSubmit(state);
            return state;
        });
    }, []);
    const handleChange = useCallback((event: any | SyntheticEvent | { name: string; value: string }) => {
        const { name, value } = event.target ? event.target : event;
        setData((state) => {
            const newState = { ...state, [name]: value };
            return newState;
        });
    }, []);

    const handleActiveChange = useCallback((value: boolean) => {
        const newStatus = value ? PartnerStatuses.active : PartnerStatuses.blocked;
        setData((state) => {
            const newState = { ...state, status: newStatus };
            return newState;
        });
    }, []);

    return { handleSubmit, handleChange, data, handleActiveChange };
}
