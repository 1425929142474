import { SortOptions } from '../utils/hooks/useSort';
import { Outlet } from '../product-management/types/outlet.type';
import { ProductResponseObject, ProductType } from '../product-management/types/product.type';
import { changeTimezone } from '../../utils/transformDateFilters';
import { BaseUrl, get, post, upload } from '../apiClient';
import { PriceVersion, ProductVersionType } from '../product-management/types/product-version.type';

export async function findOutletsByGroupId(
    groupId: number | string,
    validFrom: Date = new Date(),
    sortOptions?: SortOptions
): Promise<Outlet[]> {
    const searchParams = (sortOptions ? { validFrom, ...sortOptions } : { validFrom: validFrom }) as any;
    const query = new URLSearchParams(searchParams);
    return get(`${BaseUrl()}/product-management/${groupId}/outlets?${query}`);
}

export async function findProductsByGroupId(
    groupId: number | string,
    validFrom: Date = new Date(),
    sortOptions?: SortOptions,
    timezone?: string
): Promise<{ metadata: PriceVersion; data: ProductResponseObject[] }> {
    // validFrom = changeTimezone(validFrom, timezone ?? 'Asia/Dhaka');
    const searchParams = sortOptions ? { validFrom, ...sortOptions } : ({ validFrom: validFrom } as any);
    const query = new URLSearchParams(searchParams);
    return get(`${BaseUrl()}/product-management/${groupId}/products?${query}`);
}

export async function checkProductGroupPendingUpdateRequests(groupId: number | string): Promise<boolean> {
    return get(`${BaseUrl()}/product-management/${groupId}/pending-update-requests`);
}

export async function checkProductGroupScheduledUpdates(groupId: number | string): Promise<ProductVersionType[]> {
    return get(`${BaseUrl()}/product-management/${groupId}/scheduled-updates`);
}

export async function createProductGroupUpdateRequest(
    update: {
        productGroup: number;
        products: Partial<ProductType>[];
        validFrom: string | Date;
    },
    timezone: string
) {
    update.validFrom = changeTimezone(update.validFrom, timezone);
    return post(`${BaseUrl()}/entity-update-requests/product-management-details`, update);
}

export async function getProductGroupUpdateRequests() {
    return post(`${BaseUrl()}/entity-update-requests/product-group`, {});
}

export async function getAllProductGroups() {
    return get(`${BaseUrl()}/product-group/get-all-groups`);
}

export function uploadProductUpdateFile(
    entityUpdateRequestId: number | string,
    productGroupId: number | string,
    file: File
) {
    return upload(`${BaseUrl()}/product-management/${productGroupId}/file`, { file, entityUpdateRequestId });
}
