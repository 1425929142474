import * as React from 'react';

import { Form } from '../utils/inputs';
import { productStatuses, InputField } from './helpers';

import { useProductsAdd } from './hooks/useProductsAdd';
import { Select } from '../utils/form/fields/Select';

export const ProductsAdd: React.FC = () => {
    const { categories, handleSubmit, handleChange, product, units, quantityOptions } = useProductsAdd();

    if (!categories.length) return <div>loading...</div>;
    return (
        <>
            <div>
                <h3>New Product</h3>
                <Form className="wfp-form" onSubmit={handleSubmit}>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Name</label>
                        <InputField
                            autoFocused={true}
                            maxLength={35}
                            name="name"
                            onChange={handleChange}
                            readonly={false}
                            required={true}
                            type="text"
                            value={product.name}
                        />
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Category</label>
                        <div className="replaced-form-input col-sm-8">
                            <Select
                                name={'category'}
                                onChange={handleChange}
                                options={categories}
                                value={product.category}
                            />
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Quantity Option</label>
                        <div className="replaced-form-input col-sm-8">
                            <Select
                                name="quantityOption"
                                onChange={handleChange}
                                options={quantityOptions}
                                value={product.quantityOption}
                            />
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Unit</label>
                        <div className="replaced-form-input col-sm-8">
                            <Select name="unit" onChange={handleChange} options={units} value={product.unit} />
                        </div>
                    </div>
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Status</label>
                        <div className="replaced-form-input col-sm-8">
                            <Select
                                name="status"
                                onChange={handleChange}
                                options={productStatuses}
                                value={product.status}
                            />
                        </div>
                    </div>
                    <div className="wfp-form--actions text-center">
                        <div className="col-sm-12 col-sm-offset-8">
                            <button className="wfp-btn--primary" type="submit">
                                Park & Post Changes
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
};
