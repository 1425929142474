import * as React from 'react';
import { ProductChangesList } from '../change-lists/product-change-list';
import { EntityUpdateRequest } from '../taskAuthorizations';
import { EntityUpdateRequestCard, MILESTONE, Signatory } from '../entity-update-request/entity-update-request-card';
import { PostAction, RejectAction, SignAction } from '../entity-update-request/entity-update-request-actions';
import { ProductManagementVendorModalComponent } from './product-management-vendor-modal.component';
import { ProductManagementMoreProductsSectionComponent } from './product-management-more-products-section.component';
import Manager from '../../managers/manager';
import { hasFeatureAccess } from '../../login/auth';
import { ManagerPermission } from '../../permission-profiles/permission';
import { displayTime } from '../../utils/utils';
import { ProductManagementUploadUpdateFileComponent } from './product-management-upload-update-file.component';
import { ProductManagementReworkUpdateRequestComponent } from './product-management-rework-update-request.component';

interface Props {
    updateRequest: EntityUpdateRequest;
    manager: Manager;
    isShowingPendingUpdates: boolean;
}

export function ProductManagementAuthorizationCardComponent({
    updateRequest,
    manager,
    isShowingPendingUpdates,
}: Props) {
    const signatures = getSignatures(updateRequest);
    const isRejected = Boolean(updateRequest?.deletedAt || updateRequest.signatureChain?.rejectedAt);
    const isVerified = Boolean(updateRequest?.authorizedAt && updateRequest?.authorizedByManager);
    const isSettled = updateRequest?.signatureChain?.isSettled ?? true;
    const signed =
        updateRequest?.signatureChain?.signatures
            ?.filter((signature) => signature?.signedAt)
            .sort((a, b) => a.signatureLevel - b.signatureLevel) ?? [];
    const isSignedByUser = signed.find((signature) => signature.signedByManagerId === Number(manager?.id));
    const canSign =
        hasFeatureAccess({ manager }, ManagerPermission.productsPostLevelOne) ||
        hasFeatureAccess({ manager }, ManagerPermission.productsPostLevelTwo);
    const isParkedByUser = Number(updateRequest.createdByManager.id) === Number(manager?.id);
    const canVerify = hasFeatureAccess({ manager }, ManagerPermission.productsVerify) && !isParkedByUser;

    const showSignAction = !isSettled && !isSignedByUser && canSign;
    const showPostAction = !isVerified && canVerify && !isParkedByUser;
    const showActions = !((isVerified || isRejected) && isSettled);
    const canReject = showPostAction || showSignAction;
    const showPostDisclaimer = isParkedByUser && !isVerified && !isRejected;
    const comment = updateRequest.comment || updateRequest.signatureChain?.comment;
    const documentUploadedByManager = updateRequest?.documentUploadedByManager?.name;

    return (
        <EntityUpdateRequestCard>
            <EntityUpdateRequestCard.EntityDetails>
                <p>
                    <strong>Product Group:</strong> {updateRequest?.title}
                </p>
                {updateRequest.validFrom && (
                    <p>
                        <strong>Valid from: </strong>
                        {displayTime(updateRequest.validFrom)}
                    </p>
                )}
                {comment && (
                    <p>
                        <strong>Comment: </strong>
                        {comment}
                    </p>
                )}
                {isShowingPendingUpdates ? null : (
                    <ProductManagementVendorModalComponent
                        groupId={updateRequest.entityId}
                        validFrom={new Date(updateRequest.createdAt)}
                    />
                )}
                {isRejected ? (
                    <ProductManagementReworkUpdateRequestComponent
                        productGroup={String(updateRequest?.title)}
                        productGroupId={updateRequest.entityId}
                        updates={updateRequest.updates}
                        validFrom={updateRequest.validFrom}
                    />
                ) : (
                    <ProductManagementUploadUpdateFileComponent
                        asyncTaskId={updateRequest?.updateFile?.id}
                        entityUpdateRequestId={updateRequest.id}
                        productGroupId={updateRequest.entityId}
                    />
                )}
            </EntityUpdateRequestCard.EntityDetails>
            <EntityUpdateRequestCard.Signatories>
                {documentUploadedByManager && (
                    <Signatory
                        author={documentUploadedByManager}
                        date={String(updateRequest?.updateFile?.created_at)}
                        type={MILESTONE.UPLOAD}
                    />
                )}
                {signatures.map(([type, { date, author }]) => (
                    <RenderSignatory author={author} date={date} key={type} type={type} />
                ))}
                {signed.map((signature: any) => (
                    <Signatory
                        author={signature.signedByManager.name}
                        date={signature.signedAt}
                        key={signature?.id}
                        level={signature.signatureLevel}
                        type={MILESTONE.SIGNED}
                    />
                ))}
                {updateRequest.signatureChain?.rejectedAt && (
                    <Signatory
                        author={updateRequest.signatureChain?.rejectedByManager?.name}
                        date={updateRequest.signatureChain?.rejectedAt}
                        type={MILESTONE.REJECTED}
                    />
                )}
            </EntityUpdateRequestCard.Signatories>
            <EntityUpdateRequestCard.Details>
                <ProductChangesList updates={updateRequest.updates}>
                    <ProductManagementMoreProductsSectionComponent
                        excludedProducts={updateRequest.updates.map((update) => update.path)}
                        groupId={updateRequest.entityId}
                        isShowingPendingUpdates={isShowingPendingUpdates}
                        validFrom={new Date(updateRequest.createdAt)}
                    />
                </ProductChangesList>
                {isShowingPendingUpdates && (
                    <ProductManagementVendorModalComponent
                        groupId={updateRequest.entityId}
                        inline
                        validFrom={new Date(updateRequest.createdAt)}
                    />
                )}
            </EntityUpdateRequestCard.Details>
            <EntityUpdateRequestCard.Actions>
                {showActions && !showPostDisclaimer && (
                    <>
                        {canReject && <RejectAction updateRequest={updateRequest} />}
                        {showPostAction && <PostAction updateRequest={updateRequest}>Verify</PostAction>}
                        {showSignAction && (
                            <SignAction updateRequest={updateRequest}>Sign Level {signed.length + 1}</SignAction>
                        )}
                    </>
                )}
                {showPostDisclaimer && (
                    <p>
                        <strong>You cannot post this request because you parked it</strong>
                    </p>
                )}
            </EntityUpdateRequestCard.Actions>
        </EntityUpdateRequestCard>
    );
}

const RenderSignatory = ({ type, ...props }: { type: string; date: string; author: string }) => {
    switch (type) {
        case 'parked':
            return <Signatory {...props} type={MILESTONE.PARKED} />;
        case 'verified':
            return <Signatory {...props} type={MILESTONE.VERIFIED} />;
        case 'rejected':
            return <Signatory {...props} type={MILESTONE.REJECTED} />;
        default:
            return null;
    }
};

function getSignatures(updateRequest: EntityUpdateRequest) {
    const signatures = {
        parked: {
            date: updateRequest?.createdAt,
            author: updateRequest?.createdByManager?.name,
        },
        verified: {
            date: updateRequest?.authorizedAt,
            author: updateRequest?.authorizedByManager?.name,
        },
        rejected: {
            date: updateRequest?.deletedAt,
            author: updateRequest?.deletedByManager?.name,
        },
    } as Record<'parked' | 'verified' | 'rejected', { date: string; author: string }>;

    return Object.entries(signatures).filter(([, { date, author }]) => date && author) as Array<
        ['parked' | 'verified' | 'rejected', { date: string; author: string }]
    >;
}
