/* eslint-disable */
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { OperationTypes, SesRequest, SESRequestStatus } from './asyncTasks';
import { currencyFormatter } from './utils';
import { EntitlementCurrencyConfig } from '../app/appConfig';

interface Props {
    onClose: () => void;
    sesRequests: Array<SesRequest>;
    currency: EntitlementCurrencyConfig;
}

const renderSesRequestTableHeaders = () => {
    return (
        <thead>
            <tr>
                <th>PO Number</th>
                <th>Line Item</th>
                <th>Amount</th>
                <th>Operation type</th>
                <th>Created at</th>
                <th>Status</th>
            </tr>
        </thead>
    );
};

const renderSesRequestTableBody = (
    sesRequests: Array<SesRequest>,
    currency: EntitlementCurrencyConfig
) => {
    return sesRequests.map((sesRequest) => {
        return (
            <tr
                key={`poOverrideRow${sesRequest.id}`}
                className={'border p-3 m-3'}
            >
                {renderSesRequestRow(sesRequest, currency)}
            </tr>
        );
    });
};

const renderSesRequestRow = (
    sesRequest: SesRequest,
    currency: EntitlementCurrencyConfig
) => {
    const sesStatus =
        sesRequest.status === SESRequestStatus.sent
            ? 'parked'
            : sesRequest.status;
    const formatter = currencyFormatter(currency).format;
    return (
        <>
            <td>{sesRequest.poNumber}</td>
            <td>{sesRequest.poItem}</td>
            <td>{formatter(parseFloat(sesRequest.poAmount))}</td>
            <td>{sesRequest.operationType}</td>
            <td>{sesRequest.createdAt}</td>
            <td>
                {sesStatus}{' '}
                {sesRequest.error && (
                    <span
                        className="icon-help-dark xsmall"
                        title={sesRequest.error}
                    />
                )}{' '}
            </td>
        </>
    );
};

export const SESRequestsDialog: React.SFC<Props> = ({
    onClose,
    sesRequests,
    currency,
}) => {
    return (
        <div>
            <div className="fade modal-backdrop in" />
            <Modal.Dialog dialogClassName="modal-large">
                <Modal.Header>
                    <Modal.Title>SES Requests Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pre-scrollable m-3">
                        <table className="wfp-table--striped mt4 table-hover">
                            {renderSesRequestTableHeaders()}
                            <tbody>
                                {renderSesRequestTableBody(
                                    sesRequests,
                                    currency
                                )}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="wfp-btn"
                        style={{ marginRight: 10 }}
                        onClick={() => onClose()}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
};
