import * as React from 'react';
import { SortOptions } from '../utils/hooks/useSort';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FiltersComponent from '../utils/filters';
import { Filter, MultiSelectFilter } from '../utils/FilterTypes';
import { startCase } from 'lodash';
import { UserStatuses } from '../utils/users';
import State from '../app/store/state';
import { ActionCreators, VendorUserSortAttributes } from './vendors';
import * as _ from 'lodash';

interface Props {
    sortOptions: SortOptions<VendorUserSortAttributes>;
    vendorId: string;
}

const VendorUserFilters: React.FC<Props> = ({ sortOptions, vendorId }) => {
    const [activeFilters, setActiveFilters] = useState<Filter[]>([]);

    const dispatch = useDispatch();
    const vendorUserStatuses = useSelector((state: State) => state.appConfig.userStatuses.vendorUserStatus);

    useEffect(() => {
        const statusesOptions = Object.values(vendorUserStatuses).map((status: UserStatuses) => ({
            label: startCase(status),
            value: status,
        }));

        setActiveFilters([
            new MultiSelectFilter(
                'Status',
                'status',
                [
                    { label: startCase(UserStatuses.active), value: UserStatuses.active },
                    { label: startCase(UserStatuses.parked), value: UserStatuses.parked },
                ],
                statusesOptions,
                null,
                true
            ),
        ]);
    }, []);

    useEffect(() => {
        if (activeFilters.length > 0) {
            fetchVendorUsers();
        }
    }, [activeFilters, sortOptions]);

    const fetchVendorUsers = () => {
        dispatch(
            ActionCreators.loadVendorUsers(
                vendorId,
                sortOptions,
                activeFilters.filter((input) => input.isSelected)
            )
        );
    };

    const filterChangedHandler = (filter: Filter) => {
        const activeFiltersCopy = _.clone(activeFilters);
        const index = activeFiltersCopy.findIndex((x) => x.name === filter.name);

        if (index === -1) {
            setActiveFilters(activeFiltersCopy.concat([filter]));
        } else {
            activeFiltersCopy.splice(index, 1, filter);
            setActiveFilters(activeFiltersCopy);
        }
    };

    return (
        <FiltersComponent
            defaultFilters={activeFilters}
            filters={activeFilters}
            onFilterChanged={filterChangedHandler}
            withApply={false}
            withIsSelected={false}
        />
    );
};

VendorUserFilters.displayName = 'VendorUserFilters';

export { VendorUserFilters };
