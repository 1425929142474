import styled from 'styled-components';
import * as React from 'react';
import { ReactElement, useLayoutEffect, useMemo, useRef, useState } from 'react';

const StyledTabs = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: start;
    align-items: center;
    grid-gap: 10px;
    position: relative;
`;

const ActiveIndicator = styled.div<{ width: number; x: number }>`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: ${(props) => props.width}px;
    will-change: width, transform;
    transition: all 180ms ease-in;
    transform: translate3d(${(props) => props.x + 'px'}, 0, 0);
    background: #0b77c2;
`;

const Tab = styled.button`
    all: unset;
    font-size: 1.125rem;
    display: flex;
    &:focus {
        outline: none;
    }
`;

export const Tabs: React.FC<{ active?: string }> & { Item: React.FC<{ label: string; onClick: any }> } = ({
    children,
    active: _active,
}) => {
    const tabs = useMemo(() => {
        return React.Children.map(children, (child: ReactElement, index) => {
            const clone = React.cloneElement(child);
            const label = clone?.props?.label || `tab-${index}`;
            return {
                label,
                Component(props) {
                    return <Tab {...props}>{child}</Tab>;
                },
            };
        });
    }, []);
    const [active, setActive] = useState(_active || 'tab-0');
    const [dims, setDims] = useState({ width: 0, x: 0 });
    const ref = useRef<HTMLDivElement>();
    const handleActive = (tab) => () => {
        setActive(tab.label);
    };
    useLayoutEffect(() => {
        if (ref.current) {
            const activeTab = Array.from(ref.current.childNodes).filter((el: HTMLElement) =>
                el.classList.contains('bds-active')
            )[0] as HTMLElement;
            const rect = activeTab.getBoundingClientRect().toJSON();
            rect.x = rect.x - countOffset(ref.current);
            setDims(rect);
        }
    }, [active, ref.current]);

    if (!tabs.length) return null;
    return (
        <StyledTabs ref={ref}>
            {tabs.map((tab) => (
                <tab.Component
                    className={active === tab.label ? 'bds-active' : ''}
                    key={tab.label}
                    onClick={handleActive(tab)}
                >
                    {tab.label}
                </tab.Component>
            ))}
            <ActiveIndicator width={dims.width} x={dims.x} />
        </StyledTabs>
    );
};

Tabs.displayName = 'Tabs';

Tabs.Item = (props: React.PropsWithChildren<{ label: string; onClick: any }>) => <span {...props} />;
function countOffset(element, count = 0) {
    const _count = count + element.offsetLeft;
    if (!element.offsetParent) {
        return _count;
    }
    return countOffset(element.offsetParent, _count);
}

Tabs.Item.displayName = 'Item';
