import * as React from 'react';
import { useSearchParams } from '@wfp-common/hooks/useSearchParams';

export const withSearchParams = (Component: any, compName: string) => {
    const WithHooks = (props: any) => {
        const searchParams = useSearchParams();

        return <Component {...props} searchParams={searchParams} />;
    };

    const componentName = Component.displayName || Component.name || compName;

    WithHooks.displayName = `withSearchParams(${componentName})`;

    return WithHooks;
};
