import * as React from 'react';
import { useState, useEffect } from 'react';
import { getUIGroupProducts } from '../../apiClient';
import { SortableTableHead } from '../../utils/table/table-head/sortable-table-head';
import { wfpFormat } from '../../utils/utils';
import { ChangeSortOptions, SORT_OPTIONS, SortOptions } from '../../utils/hooks/useSort';
import { useDispatch } from 'react-redux';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

interface Props {
    groupId: number;
}

const columnToAttributeMapping = {
    Product: 'name',
    Category: 'category',
    'Quantity Option': 'quantityOption',
    Unit: 'unit',
};

const ProductMobileUIProducts: React.FC<Props> = ({ groupId }) => {
    const dispatch = useDispatch();

    const [products, setProducts] = useState([]);
    const [sortOptions, setSortOptions] = useState<SORT_OPTIONS>(new SortOptions({ column: 'name', direction: 'ASC' }));

    useEffect(() => {
        fetchProductsForUiGroup();
    }, [groupId, sortOptions]);

    const fetchProductsForUiGroup = async () => {
        const products = groupId ? await getUIGroupProducts(groupId, sortOptions) : [];
        setProducts(products);
    };

    const handleSort: ChangeSortOptions = ([column, direction]) => {
        if (!column) column = 'Product';
        const sortingOptions = new SortOptions({ column: columnToAttributeMapping[column], direction });
        setSortOptions(sortingOptions);
    };

    return (
        <div>
            <table className="wfp-table mt4 wfp-table--striped table-hover cursor-pointer">
                <SortableTableHead changeSortOptions={handleSort}>
                    <th>Product</th>
                    <th>Category</th>
                    <th>Quantity Option</th>
                    <th>Unit</th>
                </SortableTableHead>
                <tbody>
                    {products?.map((product) => (
                        <tr
                            key={`${product.name}+${product.category?.name}`}
                            onClick={() =>
                                dispatch(routerHelperActions.makeRedirect(`/products/${product.id}/general`))
                            }
                        >
                            <td>{product.name}</td>
                            <td>{wfpFormat(product.category?.name)}</td>
                            <td>{wfpFormat(product.quantityOption)}</td>
                            <td>{product.unit}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

ProductMobileUIProducts.displayName = 'ProductMobileUIProducts';

export { ProductMobileUIProducts };
