import { bindActionCreators } from 'redux';

import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import ReduxBlockUi from 'react-block-ui/redux';
import { HIDE as StopLoadingAction, SHOW as StartLoadingAction } from 'react-redux-loading-bar/build/loading_bar_ducks';

import 'react-block-ui/style.css';
import 'rc-switch/assets/index.css';

import State from './store/state';
import { ActionCreators as authActionCreators, AuthState } from '../login/auth';
import { closeNotificationAction, Notification, NotificationState } from '../utils/notifications';
import SessionProlongAlert from '../utils/sessionExpiration/sessionProlongAlert';
import { SessionExpirationState } from '../utils/sessionExpiration/sessionExpiration';
import Manager, {
    hasAdjustmentsViewFeature,
    hasBeneficiariesFeature,
    hasManagersManagementFeature,
    hasOversightFeature,
    hasPartnersFeature,
    hasPaymentRequestsFeature,
    hasProductsFeature,
    hasTransactionsFeature,
    hasVendorsFeature,
} from '../managers/manager';
import { PendingActionsState } from '../utils/pedingActionsReducer';
import { supStyle } from '../utils/utils';
import { ProjectType, projectTypeNaming } from './appConfig';
import * as logoImg from '../styles/en-standard.png';
import * as profileImg from '../styles/profile-icon.png';
import { useNavigate } from '@wfp-common/hooks/useNavigate';
import { useAppDispatch, useAppSelector } from '@wfp-root-app/store/store';
import { routerHelperActions, selectRouterHelper } from '@wfp-common/store/routerHelperSlice';

interface Props {
    auth: AuthState;
    project: ProjectType;
    notification?: NotificationState;
    sessionExpiration: SessionExpirationState;
    logout: () => void;
    prolongSession: () => void;
    closeNotification: () => void;
    closeSessionProlongAlert: () => void;
    reloadUserData: () => void;
    linkToPersonal: () => void;
    pendingActions: PendingActionsState;
    loadPendingActions: () => void;
    getMyself: () => void;
}

class AppContainer extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        this.setState({});
        this.props.reloadUserData();
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props.auth.manager && (!this.props.pendingActions || this.props.pendingActions.usersPost === null)) {
            this.props.loadPendingActions();
        }
    }

    createNavLinks() {
        if (!this.props.auth.apiToken || !this.props.auth.manager) {
            return null;
        }
        const pendingActions: PendingActionsState = this.props.pendingActions;
        const manager = this.props.auth.manager as Manager;
        const vendorsTabPendingCounter = pendingActions.vendorsPost;
        const vendorsTab = (
            <div>
                Vendors {vendorsTabPendingCounter > 0 ? <sup style={supStyle}>{vendorsTabPendingCounter}</sup> : null}
            </div>
        );
        const partnersTabPendingCounter = pendingActions.partnersPost;
        const partnersTab = (
            <div>
                Partners{' '}
                {partnersTabPendingCounter > 0 ? <sup style={supStyle}>{partnersTabPendingCounter}</sup> : null}
            </div>
        );
        const beneficiariesTabPendingCounter = pendingActions.beneficiaries
            ? [...Object.values(pendingActions.beneficiaries), pendingActions.locationsPost].reduce(
                  (acc, val) => acc + val,
                  0
              )
            : null;
        const beneficiariesTab = (
            <div>
                Beneficiaries{' '}
                {beneficiariesTabPendingCounter > 0 ? (
                    <sup style={supStyle}>{beneficiariesTabPendingCounter}</sup>
                ) : null}
            </div>
        );
        const transactionTab = <div>Transactions</div>;
        const productsTab = <div>Products</div>;
        const adminTabPendingCounter = pendingActions.usersPost + pendingActions.permissionProfilePost;
        const adminTab = (
            <div>Admin {adminTabPendingCounter > 0 ? <sup style={supStyle}>{adminTabPendingCounter}</sup> : null}</div>
        );
        const adjustmentsTabPendingCounter = pendingActions.adjustments
            ? Object.values(pendingActions.adjustments).reduce((acc, val) => acc + val, 0)
            : null;
        const adjustmentsTab = (
            <div>
                Requests{' '}
                {adjustmentsTabPendingCounter > 0 ? <sup style={supStyle}>{adjustmentsTabPendingCounter}</sup> : null}
            </div>
        );
        const paymentsTabPendingCounter = pendingActions.paymentsActions;
        const paymentsTab = (
            <div>
                Payments{' '}
                {paymentsTabPendingCounter > 0 ? <sup style={supStyle}>{paymentsTabPendingCounter}</sup> : null}
            </div>
        );
        const oversightTab = <div>Oversight</div>;
        return (
            !manager?.askForNewPassword && (
                <ul className="navbar nav">
                    {hasVendorsFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/vendors">{vendorsTab}</Link>
                        </li>
                    )}
                    {hasBeneficiariesFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/beneficiaries">{beneficiariesTab}</Link>
                        </li>
                    )}
                    {hasTransactionsFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/transactions">{transactionTab}</Link>
                        </li>
                    )}
                    {hasPaymentRequestsFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/payments">{paymentsTab}</Link>
                        </li>
                    )}
                    {hasAdjustmentsViewFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/adjustments">{adjustmentsTab}</Link>
                        </li>
                    )}
                    {hasOversightFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/oversight">{oversightTab}</Link>
                        </li>
                    )}
                    {hasManagersManagementFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/admin">{adminTab}</Link>
                        </li>
                    )}
                    {hasManagersManagementFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/documents">
                                <div>
                                    Documents
                                    {!!pendingActions.documentsUpload && (
                                        <sup style={supStyle}>{pendingActions.documentsUpload}</sup>
                                    )}
                                </div>
                            </Link>
                        </li>
                    )}
                    {hasPartnersFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/partners">{partnersTab}</Link>
                        </li>
                    )}
                    {hasProductsFeature(manager) && (
                        <li className="myNavbarItem">
                            <Link to="/products">{productsTab}</Link>
                        </li>
                    )}
                </ul>
            )
        );
    }

    renderUserName() {
        const manager = this.props.auth.manager as Manager;
        if (manager) {
            return (
                <div className={'col-sm-2'} style={{ display: 'flex', flexDirection: 'column' }}>
                    <a
                        onClick={this.props.linkToPersonal}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            alignSelf: 'center',
                        }}
                    >
                        <img
                            className="visible-sm visible-md visible-lg"
                            src={profileImg}
                            alt={'user-icon'}
                            style={{ height: 35, width: 35 }}
                        />
                        <label
                            style={{
                                fontSize: 8,
                                textAlign: 'center',
                                color: 'white',
                            }}
                        >
                            {manager.firstName} {manager.lastName}
                        </label>
                        <label
                            style={{
                                fontSize: 8,
                                textAlign: 'center',
                                color: 'white',
                            }}
                        >
                            {projectTypeNaming[this.props.project]}
                        </label>
                    </a>
                    <a
                        onClick={this.props.logout}
                        style={{
                            fontSize: 12,
                            color: 'white',
                            border: 'none',
                            alignSelf: 'center',
                        }}
                    >
                        Log Out
                    </a>
                </div>
            );
        }
    }

    renderHeader() {
        const navLinks: JSX.Element = this.createNavLinks();
        return (
            <header className="wfp-header-ext">
                <nav className="wfp-wrapper" role="banner" style={{ display: 'flex' }}>
                    <div className="col-sm-2">
                        <Link to="/home">
                            <img alt="WFP Building Blocks" src={logoImg} style={{ padding: 10 }} />
                        </Link>
                    </div>
                    <div className="col-sm-8" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {navLinks}
                    </div>
                    {this.renderUserName()}
                </nav>
            </header>
        );
    }

    render() {
        const shouldPresentSessionProlongAlert = this.props.sessionExpiration.prolongSessionAlertPresented;
        return (
            <div>
                {shouldPresentSessionProlongAlert && (
                    <SessionProlongAlert
                        onClose={this.props.closeSessionProlongAlert}
                        onProlongAlert={this.props.prolongSession}
                    />
                )}
                {this.renderHeader()}
                <LoadingBar />
                {this.props.notification && (
                    <Notification close={this.props.closeNotification} of={this.props.notification} />
                )}
                <ReduxBlockUi
                    block={StartLoadingAction}
                    className="wfp-wrapper pv3"
                    tag="div"
                    unblock={StopLoadingAction}
                >
                    {this.props.children}
                </ReduxBlockUi>
            </div>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        project: state.appConfig.project,
        auth: state.auth,
        notification: state.notification,
        sessionExpiration: state.sessionExpiration,
        pendingActions: state.pendingActions,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getMyself: bindActionCreators(authActionCreators.getMyself, dispatch),
        logout: bindActionCreators(authActionCreators.logout, dispatch),
        loadPendingActions: bindActionCreators(authActionCreators.getPendingActions, dispatch),
        prolongSession: bindActionCreators(authActionCreators.prolongSession, dispatch),
        closeNotification: () => dispatch(closeNotificationAction),
        closeSessionProlongAlert: bindActionCreators(authActionCreators.endSession, dispatch),
        reloadUserData: bindActionCreators(authActionCreators.reloadUserData, dispatch),
        linkToPersonal: () => dispatch(routerHelperActions.makeRedirect('/personal')),
    };
}

const withHooks = (Component: any) => {
    return (props: any) => {
        const navigate = useNavigate();
        const routerHelper = useAppSelector(selectRouterHelper);
        const dispatch = useAppDispatch();

        useEffect(() => {
            if (!routerHelper.redirectUrl) return;

            navigate(routerHelper.redirectUrl, routerHelper.navigateState);
            dispatch(routerHelperActions.clearRedirect());
        }, [routerHelper.redirectUrl]);

        return <Component {...props} />;
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AppContainer));
