import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAllUIGroups } from '../../apiClient';
import styled from 'styled-components';
import { SimpleSideMenu } from '../../utils/SideMenu';
import { ProductMobileUIProducts } from './ProductMobileUIProducts';
import { Loader } from '../../utils/Loader';

const Container = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    margin-top: 2rem;
    && table {
        margin-top: 0;
    }
`;

export const LoaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ProductMobileUIGroups: React.FC = () => {
    const [uiGroups, setUiGroups] = useState([]);
    const [activeGroup, setActiveGroup] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUiGroups();
    }, []);

    const getUiGroups = async () => {
        setLoading(true);
        const groups = await getAllUIGroups();
        const groupObjects = groups.map((group) => {
            return { id: group.id, text: group.name };
        });
        setUiGroups(groupObjects);
        setActiveGroup(groupObjects[0]);
        setLoading(false);
    };

    const tabChangeHandler = (tab) => {
        setActiveGroup(tab);
    };

    if (loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }

    return (
        <Container>
            <SimpleSideMenu activeTab={activeGroup} onSelectTab={tabChangeHandler} tabs={uiGroups} />
            {activeGroup && <ProductMobileUIProducts groupId={activeGroup.id} />}
        </Container>
    );
};

ProductMobileUIGroups.displayName = 'ProductMobileUIGroups';

export { ProductMobileUIGroups };
