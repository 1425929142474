import * as React from 'react';
import { LocationActionCreators, LocationsState, withLocation } from '../locations';
import { Input } from '../../../utils/inputs';

const locationExistsError = `Location already exists.`;
interface State {
    values: {
        location1: string;
        location2: string;
    };
}
interface Props extends LocationActionCreators {
    locations: LocationsState;
}
export function withCreateLocationContainer(WrappedComponent) {
    class Container extends React.Component<Props, State> {
        state = {
            values: { location1: '', location2: '' },
        };
        componentDidMount(): void {
            this.props.loadLocations();
        }

        handleChange = (event) => {
            const { values } = this.state;
            const { name, value } = event.target;
            values[name] = value;
            this.setState((state) => ({ ...state, values }));
        };

        handleSubmit = (event) => {
            event.preventDefault();
            const { createLocation, errorAction } = this.props;
            if (this.validateForm()) {
                errorAction(locationExistsError);
            }
            createLocation(this.state.values);
            this.setState({ values: { location1: '', location2: '' } });
        };

        validateForm = () => {
            const { location1, location2 } = this.state.values;
            const locations = this.props.locations.list;
            return locations.some((item) => item.location1 === location1 && item.location2 === location2);
        };
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    handleSubmit={this.handleSubmit}
                    inputsState={[this.state.values, this.handleChange]}
                />
            );
        }
    }
    return withLocation(Container as any);
}

export function InputWrapper({ onChange, value, name, label, isFocused }) {
    return (
        <div className="wfp-form--group row">
            <label className="col-sm-2 ta-right" htmlFor="name">
                {label}
            </label>
            <div className="col-sm-6">
                <Input className="form-control" isFocused={isFocused} name={name} onChange={onChange} value={value} />
            </div>
        </div>
    );
}

export function Button({ children, disabled }) {
    return (
        <div className="wfp-form--actions text-center">
            <div className="col-sm-10">
                <button className="wfp-btn--primary" disabled={disabled} type="submit">
                    {children}
                </button>
            </div>
        </div>
    );
}
