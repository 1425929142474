import { useReducer, useState } from 'react';
import { useProductGroupsOptions } from '../../utils/hooks/select-options/use-product-groups-options.hook';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from '../../utils/useUpdateEffect';
import { getOutlets, getProductData, LoadPrices, loadPrices } from '../utils/load-prices.utils';
import { compose } from '../../utils/utils';
import { errorAction } from '../../utils/notifications';
import State from '../../app/store/state';
import { hasFeatureAccess } from '../../login/auth';
import { ChangeSortOptions, SortOptions } from '../../utils/hooks/useSort';
import { Outlets } from '../types/outlet.type';
import { Products } from '../types/product.type';
import { ManagerPermission } from '../../permission-profiles/permission';
import { useNavigate } from '@wfp-common/hooks/useNavigate';

export function useProductManagement() {
    const [current, send] = useReducer(reducer, machine.initial);
    const [productGroupOptions] = useProductGroupsOptions();
    const [group, setGroup] = useState<{ value: string; label: string }>(null);
    const [prices, setPrices] = useState<LoadPrices>(null);
    const [isModalOpen, setIsModalOpen] = useState<string | null>(null);
    const dispatch = useDispatch();
    const manager = useSelector((state: State) => state.auth.manager);
    const timezone = useSelector((state: State) => state.appConfig.timeZone);
    const navigate = useNavigate();

    useUpdateEffect(() => {
        if (Array.isArray(productGroupOptions)) {
            setGroup(productGroupOptions[0]);
        }
    }, [productGroupOptions]);

    const redirectToUpdatePage = () =>
        navigate(`/products/update/${encodeURIComponent(group?.label.toLowerCase())}`, { groupId: group?.value });

    const checkBehaviour = () => {
        if (prices.hasPendingUpdateRequest) {
            toggleDialog('pending');
        } else if (prices.scheduledUpdates.length) {
            toggleDialog('scheduled');
        } else {
            redirectToUpdatePage();
        }
    };
    const toggleDialog = (state) => {
        setIsModalOpen(state);
    };
    useUpdateEffect(() => {
        if (group) {
            updatePrices(group?.value);
        }
    }, [group]);

    const updatePrices = async (groupId, sortOptions?: any) => {
        try {
            send('LOAD');
            const prices = await loadPrices(groupId, timezone);
            setPrices(prices);
        } catch (error) {
            compose(dispatch, errorAction)('Unable to load product prices for group ' + group?.label);
        } finally {
            send('DONE');
        }
    };

    const handleChange = async (event) => {
        await updatePrices(event.target.value);
        setGroup(event.target);
    };

    const isLoading = current === 'loading';

    const handleSort = <T>(
        fetcher: (groupId: string, sortOptions?: SortOptions, timezone?: string) => Promise<T>,
        key: string
    ): ChangeSortOptions => async ([column, direction]) => {
        const sortOptions = new SortOptions({ column, direction });
        const response = await fetcher(group.value, sortOptions, timezone);
        setPrices((state) => ({
            ...state,
            [key]: response,
        }));
    };
    const getProductPrices = async (groupId: string, sortOptions?: SortOptions, timezone?: string) => {
        const response = await getProductData(groupId, sortOptions, timezone);
        return response.data;
    };
    const handleOutletSort = handleSort<Outlets>(getOutlets, 'outlets');
    const handleProductSort = handleSort<Products>(getProductPrices, 'products');
    return {
        isLoading,
        handleChange,
        checkBehaviour,
        isModalOpen,
        group,
        toggleDialog: () => toggleDialog(null),
        productGroupOptions,
        prices,
        canParkUpdate: hasFeatureAccess({ manager }, ManagerPermission.productsPark),
        handleOutletSort,
        handleProductSort,
        timezone,
    };
}

const machine = {
    initial: 'idle',
    states: {
        idle: {
            on: { LOAD: 'loading' },
        },
        loading: {
            on: { DONE: 'loaded' },
        },
        loaded: {
            LOAD: 'loading',
        },
    },
};
function reducer(state, event) {
    return machine.states?.[state]?.on?.[event] || state;
}
