import {
    loadBeneficiary,
    addBeneficiaryComment,
    updateBeneficiaryStatus,
    zeroBeneficiary,
    getEntityUpdateRequests,
    authorizeEntityUpdateRequest,
    rejectEntityUpdateRequest,
    editBeneficiary,
    getBeneficiaryActivityLogs,
    exportActivityLog,
    acStatusChange,
    tooLongValueError,
    resetBeneficiaryPin,
} from '../../apiClient';
import { EditActionTypes } from './editBeneficiaryActionTypes';
import { errorAction, successAction } from '../../utils/notifications';
import { BeneficiaryStatuses, Beneficiary } from '../beneficiaries';
import State from '../../app/store/state';
import { hasOnlyWhitespaces } from '../../utils/utils';
import { hasFeatureAccess } from '../../login/auth';
import {
    CustomEntityUpdateListType,
    UpdateStateListType,
    EntityUpdateRequest,
} from '../../authorization/taskAuthorizations';
import { TextFilter } from '../../utils/FilterTypes';
import handleEntityUpdateConfirmationError from '../../utils/handleEntityUpdateConfirmationError';
import { throwErrorIfNotHandled } from '../../utils/errorMiddleware';
import hasNewFieldsChanges from '../../utils/hasNewFieldsChanges';
import { ActivityLogPageType } from '../../activityLogs/ActivityLogExport';
import { ZeroingOption } from '../BeneficiaryActivitiesPage';
import { ManagerPermission } from '../../permission-profiles/permission';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

function beneficiaryLoadedAction(beneficiary) {
    return {
        type: EditActionTypes.editBeneficiaryLoadSuccess,
        payload: beneficiary,
    };
}

const cleanBeneficiaryData = {
    type: EditActionTypes.cleanBeneficiaryData,
};

export const EditBeneficiaryCreators = {
    resetPin(beneficiaryId: string) {
        return (dispatch) => {
            return resetBeneficiaryPin(beneficiaryId)
                .then(() => {
                    dispatch(successAction('The pin has been reset successfully.'));
                })
                .catch(() => {
                    dispatch(errorAction('Something went wrong.'));
                });
        };
    },
    loadBeneficiary(beneficiaryId: string) {
        return (dispatch, getState: () => State) => {
            const currentlyLoadedBeneficiary = getState().editBeneficiary.beneficiary;
            if (currentlyLoadedBeneficiary && currentlyLoadedBeneficiary.id !== beneficiaryId) {
                dispatch(cleanBeneficiaryData);
            }
            return loadBeneficiary(beneficiaryId).then((beneficiary) => dispatch(beneficiaryLoadedAction(beneficiary)));
        };
    },
    updateBeneficiary(beneficiary: Beneficiary) {
        return (dispatch, getState: () => State) => {
            const oldBeneficiaryData = getState().editBeneficiary.beneficiary;
            const isUpdateNeeded = hasNewFieldsChanges(oldBeneficiaryData, beneficiary);
            if (!isUpdateNeeded) {
                dispatch(errorAction('There are no changes to be updated'));
                return;
            }
            return editBeneficiary(beneficiary)
                .then(() => {
                    dispatch(EditBeneficiaryCreators.loadBeneficiary(beneficiary.id));
                    dispatch(successAction('Beneficiary parked.'));
                })
                .catch((err) => {
                    let message = 'Unknown error';
                    if (err.status === 404) {
                        message = 'Invalid alternative collector id';
                    }
                    dispatch(errorAction(message));
                });
        };
    },

    addComment(beneficiaryId: string, comment: string) {
        return (dispatch) => {
            return addBeneficiaryComment(beneficiaryId, comment)
                .then((result) => {
                    dispatch({
                        type: EditActionTypes.addComment,
                        payload: result,
                    });
                    dispatch(successAction('Comment has been added successfully.'));
                })
                .catch(() => {
                    dispatch(errorAction('Failed to add a comment.'));
                });
        };
    },

    updateBeneficiaryStatus(beneficiaryId: string, status: BeneficiaryStatuses, reason: string, source: string) {
        return (dispatch, getState: () => State) => {
            const auth = getState().auth;
            const noPermissionToBlock =
                status === BeneficiaryStatuses.blocked &&
                !hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleBlockPark);
            const noPermissionToActivate =
                status === BeneficiaryStatuses.active &&
                !hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleUnblockPark);

            if (noPermissionToActivate) {
                dispatch(errorAction('You do not have permission to activate user.'));
                return;
            }
            if (noPermissionToBlock) {
                dispatch(errorAction('You do not have permission to block user.'));
                return;
            }

            return updateBeneficiaryStatus(beneficiaryId, status, reason, source)
                .then(() => {
                    dispatch(EditBeneficiaryCreators.loadBeneficiary(beneficiaryId));
                    dispatch(successAction('Beneficiary status updated successfully.'));
                })
                .catch(() => {
                    dispatch(errorAction('Failed to update beneficiary status.'));
                });
        };
    },

    zeroBeneficiary(beneficiaryId: string, reason: string, source: string, zeroingOption: ZeroingOption) {
        return (dispatch, getState: () => State) => {
            const auth = getState().auth;
            const noPermissionToZero = !hasFeatureAccess(auth, ManagerPermission.beneficiariesSingleZeroPark);

            if (noPermissionToZero) {
                dispatch(errorAction('You do not have permission to zero beneficiary balance.'));
                return;
            }
            return zeroBeneficiary(beneficiaryId, reason, source, zeroingOption)
                .then(() => {
                    dispatch(EditBeneficiaryCreators.loadBeneficiary(beneficiaryId));
                    dispatch(successAction('Balance zeroing has been parked successfully.'));
                })
                .catch(() => {
                    dispatch(errorAction('Failed to park balance zeroing.'));
                });
        };
    },

    acStatusChange(id: string, reason: string, source: string, newStatus: string) {
        return (dispatch) => {
            return acStatusChange(id, reason, source, newStatus)
                .then(() => {
                    dispatch(EditBeneficiaryCreators.loadBeneficiary(id));
                    dispatch(successAction('AC status change has been parked successfully.'));
                })
                .catch(() => {
                    dispatch(errorAction('Failed to park AC status change.'));
                });
        };
    },

    searchBeneficiary(beneficiaryId: string) {
        return (dispatch) => {
            if (hasOnlyWhitespaces(beneficiaryId) || beneficiaryId.length < 5) {
                //todo to fix, it's just a quick fix, logic is broken after introducing NRF []
                dispatch(errorAction(`Please provide valid beneficiary ID.`));
                return;
            }
            return loadBeneficiary(beneficiaryId)
                .then((beneficiary) => {
                    dispatch(
                        routerHelperActions.makeRedirect('/beneficiaries/beneficiary', {
                            beneficiaryId: beneficiary.id,
                        })
                    );
                    dispatch(beneficiaryLoadedAction(beneficiary));
                    dispatch(successAction(`Beneficiary "${beneficiaryId}" successfully found!`));
                })
                .catch((err: Error) => {
                    if (err.message === tooLongValueError.message) {
                        dispatch(errorAction(err.message));
                    } else {
                        dispatch(errorAction(`Beneficiary "${beneficiaryId}" not found!`));
                    }
                });
        };
    },
    loadBeneficiaryUpdates(beneficiaryId: string, page: number, limit: number) {
        return (dispatch) => {
            const filter = new TextFilter('entityId', 'entityId', beneficiaryId);
            filter.isSelected = true;
            return getEntityUpdateRequests(
                CustomEntityUpdateListType.beneficiaryAllUpdates,
                UpdateStateListType.Requested,
                page,
                limit,
                [filter]
            ).then((response) => {
                dispatch({
                    type: EditActionTypes.beneficiaryUpdatesLoaded,
                    payload: response,
                });
            });
        };
    },
    confirmBeneficiaryUpdate(entityUpdateRequest: EntityUpdateRequest) {
        return (dispatch) => {
            return authorizeEntityUpdateRequest(entityUpdateRequest)
                .then(() => {
                    dispatch({
                        type: EditActionTypes.beneficiaryUpdatePosted,
                        payload: { id: entityUpdateRequest.id },
                    });
                    dispatch(EditBeneficiaryCreators.loadBeneficiary(entityUpdateRequest.entityId));
                    const message = `Successfully authorized entity update request ${entityUpdateRequest.id}`;
                    dispatch(successAction(message));
                })
                .catch((err) => handleEntityUpdateConfirmationError(err, entityUpdateRequest, dispatch));
        };
    },
    rejectBeneficiaryUpdate(entityUpdateRequest: EntityUpdateRequest, comment: string) {
        return (dispatch) => {
            return rejectEntityUpdateRequest(entityUpdateRequest, comment)
                .then(() => {
                    dispatch({
                        type: EditActionTypes.beneficiaryUpdateRejected,
                        payload: { id: entityUpdateRequest.id },
                    });
                    dispatch(EditBeneficiaryCreators.loadBeneficiary(entityUpdateRequest.entityId));
                    dispatch(successAction('Entity update request has been successfully rejected.'));
                })
                .catch(throwErrorIfNotHandled('Failed to reject entity update request.'));
        };
    },
    loadBeneficiaryActivityLogs(beneficiaryId: string, page: number, limit: number) {
        return (dispatch) => {
            return getBeneficiaryActivityLogs(beneficiaryId, page, limit).then((result) => {
                dispatch({
                    type: EditActionTypes.beneficiaryActivityLogsLoaded,
                    payload: result,
                });
            });
        };
    },

    exportActivityLog(managerId: string, options: any, filter: any) {
        return (dispatch) =>
            exportActivityLog(managerId, ActivityLogPageType.Beneficiary, options, filter).catch((err) => {
                if (err.status === 423) {
                    dispatch(
                        errorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        )
                    );
                }
            });
    },
};
