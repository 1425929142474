import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';

import { VendorPaymentInfo } from './VendorPayment';
import {
    ActionCreators as VendorActionCreators,
    defaultVendorUsersSortingOrder,
    updateVendor,
    Vendor,
    VendorGeneralInfo,
    VendorNickName,
    VendorUser,
    VendorUserName,
} from './vendors';
import { getVendorActivityLogs } from '../apiClient';
import { EntityType } from '../authorization/taskAuthorizations';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { ActionCreators as ManagerActionCreators, ManagerNickName } from '../managers/managers';
import State from '../app/store/state';
import { InformationDialog } from '../utils/Dialogs';
import { ExternalValidationError, Input, ValidationContext } from '../utils/inputs';
import { PagedState } from '../utils/paging';
import { AuthorizationSideMenu, AuthorizationSideMenuProps } from '../utils/SideMenu';
import { countries } from '../utils/countries';
import { SORT_OPTIONS } from '../utils/hooks/useSort';
import { ManagerPermission } from '../permission-profiles/permission';
import { ActionCreators as PartnerActionCreators } from '../partners/partnersActions';
import { PartnerName, PartnerUserName } from '../partners/partners';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

const SearchContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
};

interface OwnProps {
    params: { vendorId: string };
}

enum VendorTab {
    GeneralInformation = 'General Information',
    PaymentInformation = 'Payment Information',
    ActivityLogs = 'Activity Logs',
    VendorPanel = 'Vendor Panel',
    VendorProducts = 'Products',
    Branches = 'Branches',
    PermissionFiles = 'Permission Files',
}

const urlPathForTab = {
    [VendorTab.GeneralInformation]: 'general',
    [VendorTab.PaymentInformation]: 'payment',
    [VendorTab.ActivityLogs]: 'activity',
    [VendorTab.VendorPanel]: 'panel',
    // [VendorTab.VendorProducts]: 'products', //todo this feature needs to be reworked
    [VendorTab.Branches]: 'branches',
    [VendorTab.PermissionFiles]: 'permission-files',
};

function EditVendorSideMenu(props: AuthorizationSideMenuProps<any>) {
    return AuthorizationSideMenu(props);
}

interface Props {
    loadVendor: (vendorId: string) => void;
    loadVendorUsers: (id: string, sortOptions: SORT_OPTIONS) => void;
    saveVendor: (vendor: any, permissionsFile: File) => void;
    requestPasswordReset: (vendorUser: VendorUser) => void;
    authorizeVendor: (vendorId: string) => void;
    searchVendor: (vendorId: string) => void;
    loadManagersNickNames: () => void;
    loadVendorsNickNames: (sortOrder: SORT_OPTIONS) => void;
    loadVendorUsersNames: (sortOptions: SORT_OPTIONS) => void;
    cancelPark: (id: string) => void;
    redirectToPage: (url: string) => void;
    vendorId: string;
    routes: { path: string }[];
    vendorUsersParked: number;
    validationError?: ExternalValidationError;
    vendor: Vendor;
    auth: AuthState;
    managerNickNameList: Array<ManagerNickName>;
    vendorsNickNames: Array<VendorNickName>;
    vendorUsersNames: Array<VendorUserName>;
    vendorUsersList: Array<VendorUser>;
    showBranchesTab: boolean;
    loadPartnersNames: (sortOptions: SORT_OPTIONS) => void;
    loadPartnerUserNames: (sortOptions: SORT_OPTIONS) => void;
    partnersNickNames: Array<PartnerName>;
    partnerUsersNickNames: Array<PartnerUserName>;
}

interface VendorEditPageState {
    editDisabled: boolean;
    hasPendingDetailsUpdate: boolean;
    activeTab: { text: VendorTab; counter: string | null };
    searchValue: string;
    activityLogs: PagedState<any>;
}

class VendorEditPage extends React.Component<Props, VendorEditPageState> {
    _validationContext = new ValidationContext();

    UNSAFE_componentWillMount() {
        const vendorId = this.props.vendorId;
        if (!this.props.vendor.id && !this.isCreateVendorPage()) {
            this.props.loadVendor(vendorId);
        }
    }

    componentDidMount(): void {
        if (!this.isCreateVendorPage()) {
            this.props.loadVendorUsers(this.props.vendorId, defaultVendorUsersSortingOrder);
        }
    }

    isCreateVendorPage() {
        return this.props.vendorId === 'new';
    }

    constructor(props) {
        super(props);
        this.state = this.createDefaultState();
        this.toggleEdit = this.toggleEdit.bind(this);
    }

    toggleEdit(editChecked) {
        const { vendor } = this.props;
        let hasPendingDetailsUpdate = false;
        if (vendor && vendor.pendingEntityUpdates) {
            const hasBeneficiaryDetailsEntity = vendor.pendingEntityUpdates.filter(
                (update) => update.entityType === EntityType.vendorDetails
            );
            hasPendingDetailsUpdate = hasBeneficiaryDetailsEntity.length !== 0;
        }
        if (hasPendingDetailsUpdate) {
            this.setState({ hasPendingDetailsUpdate });
        } else if (vendor.authorizedAt) {
            this.setState({ editDisabled: !editChecked });
        }
    }

    renderPendingUpdatesDialog() {
        return (
            <InformationDialog
                message="Vendor details cannot be currently edited because another edit is waiting to be posted"
                onClose={() => this.setState({ hasPendingDetailsUpdate: false })}
                title="Pending updates"
            />
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.vendorId !== this.props.vendorId) {
            this.setState(this.createDefaultState());
        }
    }

    createDefaultState() {
        const editDisabled = !this.isCreateVendorPage();
        return {
            searchValue: '',
            activeTab: { text: VendorTab.GeneralInformation, counter: null },
            entitlements: [],
            editDisabled: editDisabled,
            hasPendingDetailsUpdate: false,
            vendorGeneralInfo: null,
            vendorPaymentInfo: null,
            activityLogs: new PagedState<any>(),
        };
    }

    onSearchVendor() {
        const vendorId = this.state.searchValue;
        this.props.searchVendor(vendorId);
        this.setState({ searchValue: '' });
    }

    renderSearch() {
        return (
            <div className="col-sm-6" style={SearchContainerStyle}>
                <Input
                    className="form-control"
                    name={'search_input'}
                    onChange={({ target }) => this.setState({ searchValue: target.value })}
                    placeholder="Vendor ID"
                    style={{ width: '30%' }}
                    value={this.state.searchValue}
                />
                <button
                    className="wfp-btn--primary xsmall"
                    onClick={this.onSearchVendor.bind(this)}
                    style={{ marginLeft: 10 }}
                    type="button"
                >
                    Search
                </button>
            </div>
        );
    }

    renderSidemenu() {
        const activeTab = this.activeMenuTab();
        const { auth, vendor, showBranchesTab } = this.props;
        const subtabs = [
            { text: VendorTab.GeneralInformation, counter: null },
            { text: VendorTab.PaymentInformation, counter: null },
            {
                text: VendorTab.VendorPanel,
                counter: vendor.vendorUsersRequestCount,
            },
        ];
        const ActivityLog = { text: VendorTab.ActivityLogs, counter: null };
        const withActivityLogTab = hasFeatureAccess(auth, ManagerPermission.operationActivityView);
        let displayedSubtabs = withActivityLogTab ? [...subtabs, ActivityLog] : subtabs;
        // const Products = { text: VendorTab.VendorProducts, counter: null };
        // const withProductsTab = hasFeatureAccess(auth, ManagerFeature.productsRead);
        // displayedSubtabs = withProductsTab ? [...displayedSubtabs, Products] : displayedSubtabs;
        const Branches = { text: VendorTab.Branches, counter: vendor.vendorBranchesRequestCount };
        const withBranchesTab = showBranchesTab;
        displayedSubtabs = withBranchesTab ? [...displayedSubtabs, Branches] : displayedSubtabs;

        if (hasFeatureAccess(auth, ManagerPermission.vendorsDownload)) {
            displayedSubtabs.push({ text: VendorTab.PermissionFiles, counter: null });
        }

        return (
            <div>
                <EditVendorSideMenu
                    activeTab={activeTab}
                    onSelectTab={(activeTab) => {
                        this.handleTabChange(activeTab);
                    }}
                    tabs={displayedSubtabs}
                />
            </div>
        );
    }

    handleTabChange(activeTab) {
        this.setState({ activeTab: activeTab });
        const path = urlPathForTab[activeTab];

        this.props.redirectToPage(`/vendors/${this.props.vendorId}/${path}`);
    }

    renderBreadcrumbs() {
        const { vendor } = this.props;
        return (
            <div className="row col-sm-12">
                <nav className="wfp-breadcrumbs col-sm-6">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/vendors">
                                <span>Vendors</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{`Edit Vendor ${
                                vendor.id ? '"' + vendor.id + '"' : ''
                            }`}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        );
    }

    saveVendorData(data, permissionsFile: File) {
        const oldVendor = this.props.vendor;
        const updatedVendor = updateVendor(oldVendor, data);
        this.props.saveVendor(updatedVendor, permissionsFile);
    }

    loadActivityLogs(page, limit) {
        const vendorId = this.props.vendorId;
        return getVendorActivityLogs(vendorId, page, limit).then((result) => {
            this.setState({ activityLogs: result });
        });
    }

    activeMenuTab(): VendorTab {
        const { routes } = this.props;
        const lastUrlPart = routes[routes.length - 1].path;
        if (lastUrlPart === ':userId') return VendorTab.VendorPanel;
        for (const key of Object.keys(urlPathForTab)) {
            if (urlPathForTab[key] === lastUrlPart) {
                return key as VendorTab;
            }
        }
    }

    render() {
        const { auth, vendor, authorizeVendor, validationError } = this.props;
        const { hasPendingDetailsUpdate, editDisabled } = this.state;
        const isEditVendor = !this.isCreateVendorPage();
        const parkEnabled = hasFeatureAccess(auth, ManagerPermission.vendorsPark);
        const postEnabled = hasFeatureAccess(auth, ManagerPermission.vendorsPost);
        const vendorGeneralInfo = new VendorGeneralInfo(vendor);
        const vendorPaymentInfo = new VendorPaymentInfo(this.props.vendor);

        const generalInfoProps = {
            vendorGeneralInfo: vendorGeneralInfo,
            onSaved: this.saveVendorData.bind(this),
            validationError: validationError,
            authorize: authorizeVendor,
            parkEnabled: parkEnabled,
            postEnabled: postEnabled,
            isNewVendor: this.isCreateVendorPage(),
            managerId: auth && auth.manager ? auth.manager.id : null,
            cancelPark: this.props.cancelPark.bind(this),
            authState: auth,
        };

        const activityLogsProps = {
            activityLogs: this.state.activityLogs,
            loadActivityLogs: this.loadActivityLogs.bind(this),
            managersNickNames: this.props.managerNickNameList,
            isManager: false,
            loadManagersNickNames: this.props.loadManagersNickNames,
            vendorsNickNames: this.props.vendorsNickNames,
            vendorUsersNames: this.props.vendorUsersNames,
            loadVendorsNickNames: this.props.loadVendorsNickNames,
            loadVendorUsersNames: this.props.loadVendorUsersNames,
            loadPartnersNames: this.props.loadPartnersNames,
            loadPartnerUserNames: this.props.loadPartnerUserNames,
            partnersNickNames: this.props.partnersNickNames,
            partnerUsersNickNames: this.props.partnerUsersNickNames,
            auth: auth,
            downloadRestriction: [ManagerPermission.vendorsDownload],
            url: `/vendors/${this.props.vendor.id}/export-activity-log`,
        };

        const paymentProps = {
            vendorPaymentInfo: vendorPaymentInfo,
            onSaved: this.saveVendorData.bind(this),
            validationError: validationError,
            parkEnabled: parkEnabled,
            editDisabled: editDisabled,
            auth: auth,
        };

        const panelProps = { vendor };
        const branchesProps = { vendor, managersNickNames: this.props.managerNickNameList };
        const productProps = {};
        const childrenWithProps = React.Children.map(this.props.children, (child) => {
            let props: any = {};
            const path = (child as any).props.route.path;

            switch (path) {
                case 'general':
                    props = generalInfoProps;
                    break;
                case 'activity':
                    props = activityLogsProps;
                    break;
                case 'payment':
                    props = paymentProps;
                    break;
                case 'panel':
                    props = panelProps;
                    break;
                case 'branches':
                    props = branchesProps;
                    break;
                case 'products':
                    props = productProps;
                    break;
                case 'permission-files':
                    props = { vendor: vendorGeneralInfo };
                    break;
            }
            return React.cloneElement(child as React.ReactElement<any>, props);
        });

        return (
            <main>
                {this.renderBreadcrumbs()}
                {hasPendingDetailsUpdate && this.renderPendingUpdatesDialog()}
                <div className={'row'}>
                    {isEditVendor && (
                        <div className="col-sm-3">
                            <h5 style={{ marginTop: 0, marginBottom: 0 }}>Vendor {this.props.vendor.nickName}</h5>
                            {this.renderSidemenu()}
                        </div>
                    )}
                    <div className="col-sm-9" style={{ marginBottom: 150 }}>
                        {childrenWithProps}
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        vendor: state.vendors.list.find((v) => v.id.toString() === ownProps.params.vendorId) || new Vendor(),
        validationError: state.vendors.validationError,
        auth: state.auth,
        managerNickNameList: state.managers.managerNickNameList,
        vendorUsersList: state.vendors.usersList,
        vendorsNickNames: state.vendors.nickNameList,
        vendorUsersNames: state.vendors.usersNames,
        vendorId: ownProps.params.vendorId,
        vendorUsersParked: state.pendingActions.vendorUsersPost,
        showBranchesTab: state.appConfig.country !== countries.JORDAN,
        partnersNickNames: state.partners.nameList,
        partnerUsersNickNames: state.partners.partnerUsersNamesList,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        loadVendor: bindActionCreators(VendorActionCreators.loadVendor, dispatch),
        loadVendorUsers: bindActionCreators(VendorActionCreators.loadVendorUsers, dispatch),
        saveVendor: bindActionCreators(VendorActionCreators.saveVendor, dispatch),
        requestPasswordReset: bindActionCreators(VendorActionCreators.requestPasswordReset, dispatch),
        authorizeVendor: bindActionCreators(VendorActionCreators.authorizeVendor, dispatch),
        loadManagersNickNames: bindActionCreators(ManagerActionCreators.loadManagersNickNames, dispatch),
        loadVendorsNickNames: bindActionCreators(VendorActionCreators.loadVendorsNickNames, dispatch),
        loadVendorUsersNames: bindActionCreators(VendorActionCreators.loadVendorUsersNames, dispatch),
        loadPartnersNames: bindActionCreators(PartnerActionCreators.loadPartnersNames, dispatch),
        loadPartnerUserNames: bindActionCreators(PartnerActionCreators.loadPartnerUsersName, dispatch),
        cancelPark: bindActionCreators(VendorActionCreators.cancelPark, dispatch),
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorEditPage as any);
