import * as React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Filter } from '../utils/FilterTypes';
import SelectedFiltersComponent from '../utils/selectedFilters';
import State from '../app/store/state';
import { ActionCreators } from './transactions';
import { TransactionRequestParams } from '../apiClient';
import AsyncTasksListExportView from '../utils/AsyncTasksListExportView';
import { PagedState } from '../utils/paging';
import { ActionCreators as AsyncActionCreators, AsyncTask, AsyncTasksTypes } from '../utils/asyncTasks';
import { isTimeDiffMoreThanYear } from '../utils/utils';
import { countries } from '../utils/countries';

interface Props {
    exports: PagedState<AsyncTask>;
    transactionsCount?: number;
    atLeastTransactionsCount?: number;
    transactionFilters: Array<Filter>;

    location: any;
    managerId: string;

    downloadFile: (type: string, id: string) => string;
    loadAsyncTasksList: (page: number, limit: number, type: string) => void;
    appendAsyncTasksList: (limit: number, type: string) => void;
    exportTransactions: (
        timezone: string,
        mapping: Array<{ key; header }>,
        filters: Array<Filter>,
        customParams?: TransactionRequestParams
    ) => Promise<void>;
    authorizeTask: (type: string, id: string) => Promise<void>;
    country: countries;
    timezone: string;
}

interface LocalState {
    selectedColumns: { [key: string]: boolean };
    transactionsTotalCount?: number;
    transactionsAtLeastTotalCount?: number;
    filters: Array<Filter>;
    selectAll: boolean;
}

class TransactionsExportPage extends React.Component<Props, LocalState> {
    defaultPageSize = 10;
    private jordanExcludedColumns = ['oid', 'stno', 'products', 'financialTracking'];
    private bangladeshExcludedColumns = ['receiptNumber'];
    private columns = [
        { key: 'agency', header: 'Agency' },
        { key: 'id', header: 'TX #' },
        { key: 'originalTransactionId', header: 'Original TX #' },
        { key: 'receiptNumber', header: 'Vendor Receipt Number' },
        { key: 'createdAt', header: 'Time' },
        { key: 'updatedAt', header: 'Last Update Date' },
        { key: 'originalTransactionTime', header: 'Original Time' },
        { key: 'verifiedAt', header: 'TX Confirmation Time' },
        { key: 'entitlementValidityStart', header: 'Entitlement Token Validity Start' },
        { key: 'entitlementValidityEnd', header: 'Entitlement Token Validity End' },
        { key: 'type', header: 'Type' },
        { key: 'category', header: 'Category' },
        { key: 'currency', header: 'Currency' },
        { key: 'amount', header: 'Amount' },
        { key: 'sender', header: 'Sender' },
        { key: 'alternativeCollectorId', header: 'AC ID' },
        { key: 'receiver', header: 'Receiver' },
        { key: 'branchCode', header: 'Branch' },
        { key: 'oid', header: 'OID' },
        { key: 'stno', header: 'DID' },
        { key: 'individualId', header: 'Individual ID' },
        {
            key: 'displayNameForTransactionStatus',
            header: 'Transaction Status',
        },
        { key: 'displayNameForFinancialStatus', header: 'Payment Status' },
        { key: 'paymentRequestId', header: 'Payment Request ID' },
        { key: 'beneficiary.status', header: 'Beneficiary Status' },
        { key: 'authenticationMethod', header: 'Authentication Method' },
        { key: 'products', header: 'Products' },
        { key: 'intervention', header: 'Intervention Name' },
        { key: 'financialTracking', header: 'Financial Tracking' },
    ];
    private availableColumns =
        this.props.country === countries.JORDAN
            ? this.columns.filter((column) => !this.jordanExcludedColumns.includes(column.key))
            : this.columns.filter((column) => !this.bangladeshExcludedColumns.includes(column.key));

    constructor(props: Props) {
        super(props);

        this.state = {
            transactionsTotalCount: this.props.transactionsCount,
            transactionsAtLeastTotalCount: this.props.atLeastTransactionsCount,
            filters: this.props.transactionFilters,
            selectedColumns: {
                agency: true,
                id: true,
                originalTransactionId: true,
                createdAt: true,
                originalTransactionTime: true,
                type: true,
                category: true,
                currency: true,
                amount: true,
                individualId: true,
                sender: true,
                alternativeCollectorId: true,
                receiver: true,
                branchCode: true,
                oid: true,
                stno: true,
                itn: true,
                displayNameForTransactionStatus: true,
                displayNameForFinancialStatus: false,
                'beneficiary.status': false,
                authenticationMethod: false,
                updatedAt: false,
                blockchainHash: false,
                products: false,
                financialTracking: false,
            },
            selectAll: false,
        };
    }

    private customParams(): TransactionRequestParams {
        return this.props.location.query;
    }

    private changePageRequested() {
        this.props.appendAsyncTasksList(this.defaultPageSize, AsyncTasksTypes.EXPORT_TRANSACTIONS);
    }

    UNSAFE_componentWillMount() {
        this.props.loadAsyncTasksList(1, this.defaultPageSize, AsyncTasksTypes.EXPORT_TRANSACTIONS);
    }

    toWideDateRange(filters) {
        const dateFilter = filters.find((filter) => filter.type === 'DATE');
        return (
            !dateFilter ||
            !dateFilter.isSelected ||
            (dateFilter.isSelected &&
                isTimeDiffMoreThanYear(dateFilter.dateRange.startDate, dateFilter.dateRange.endDate))
        );
    }

    isExportInProcess() {
        return this.props.exports.items.find((task) => task.finishedAt === null);
    }

    renderExportOptions() {
        if (this.toWideDateRange(this.state.filters)) {
            if (this.isExportInProcess()) {
                return;
            } else {
                return (
                    <p>
                        Transaction date range for export is limited to 1 year. In order to export transactions, reduce
                        the transactions time frame.
                    </p>
                );
            }
        } else {
            return (
                <div>
                    {this.state.transactionsTotalCount !== null && this.state.transactionsTotalCount !== undefined && (
                        <p>{this.state.transactionsTotalCount} Transactions are listed.</p>
                    )}

                    {(this.state.transactionsTotalCount === null || this.state.transactionsTotalCount === undefined) &&
                        this.state.transactionsAtLeastTotalCount !== null &&
                        this.state.transactionsAtLeastTotalCount !== undefined && (
                            <p>At least {this.state.transactionsAtLeastTotalCount} transactions listed.</p>
                        )}

                    {this.state.filters && <SelectedFiltersComponent filters={this.state.filters} />}
                    <p>Please select the information you would like to download per Transaction:</p>
                    <form onSubmit={this._exportTransactions.bind(this)}>
                        <div className="wfp-form--group" style={{ columns: 3 }}>
                            <div className="checkbox" key="all">
                                <label>
                                    <input
                                        checked={this.state.selectAll}
                                        name="all"
                                        onChange={this._handleCheckAll.bind(this)}
                                        type="checkbox"
                                    />
                                    Select All
                                </label>
                            </div>

                            {this.availableColumns.map((column) => (
                                <div className="checkbox" key={column.key}>
                                    <label>
                                        <input
                                            checked={this.state.selectedColumns[column.key] || false}
                                            name={column.key}
                                            onChange={this._handleCheck.bind(this)}
                                            type="checkbox"
                                        />{' '}
                                        {column.header}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div className="wfp-form--actions">
                            <button className="wfp-btn" data-toggle="tooltip" type="submit">
                                Generate
                            </button>
                        </div>
                    </form>
                </div>
            );
        }
    }

    render() {
        const breadcrumbsLink = 'Transactions';
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/transactions">
                                <span>{breadcrumbsLink}</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Download Transactions</span>
                        </li>
                    </ol>
                </nav>
                <h3>Download Transactions</h3>
                {this.renderExportOptions()}

                {this.props.exports && this.props.exports.items.length > 0 && (
                    <div>
                        <AsyncTasksListExportView
                            authorize={this.props.authorizeTask.bind(this)}
                            authorizedManagerId={this.props.managerId}
                            canDownload={true}
                            data={this.props.exports.items}
                            downloadFile={this.props.downloadFile.bind(this)}
                        />
                        <div className="wfp-form--actions">
                            <button
                                className="wfp-btn--primary"
                                disabled={this.props.exports.items.length === this.props.exports.paging.total}
                                onClick={this.changePageRequested.bind(this)}
                                type="button"
                            >
                                More
                            </button>
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleCheck(event) {
        const key = event.target.name;
        const value = event.target.checked;

        this.setState((prevState) => {
            const selectedColumns = prevState.selectedColumns;
            selectedColumns[key] = value;
            return { selectedColumns };
        });
    }

    _handleCheckAll(event) {
        const { country } = this.props;
        const value = event.target.checked;
        const selectedColumns: { [key: string]: boolean } = {};

        this.availableColumns.forEach((column) => {
            selectedColumns[column.key] = value;
        });
        if (country === countries.JORDAN) {
            selectedColumns.products = false;
        }
        this.setState(() => {
            return { selectedColumns, selectAll: value };
        });
    }

    _exportTransactions(event) {
        event.preventDefault();
        const { timezone, exportTransactions, loadAsyncTasksList } = this.props;
        const mapping = this.availableColumns.filter((column) => this.state.selectedColumns[column.key]);
        return exportTransactions(timezone, mapping, this.state.filters, this.customParams()).then(() =>
            loadAsyncTasksList(1, this.defaultPageSize, AsyncTasksTypes.EXPORT_TRANSACTIONS)
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        exportTransactions: bindActionCreators(ActionCreators.exportTransactions, dispatch),
        loadAsyncTasksList: bindActionCreators(AsyncActionCreators.loadAsyncTasksList, dispatch),
        appendAsyncTasksList: bindActionCreators(AsyncActionCreators.appendAsyncTasksList, dispatch),
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
    };
}

function mapStateToProps(state: State, ownProps) {
    return {
        transactionsCount: ownProps.location.state
            ? ownProps.location.state.transactionsTotalCount
            : state.transactions && state.transactions.paging && state.transactions.paging.total,
        atLeastTransactionsCount:
            state.transactions && state.transactions.paging && state.transactions.paging.totalAtLeast,
        transactionFilters: ownProps.location.state
            ? ownProps.location.state.transactionsFilters
            : state.transactions && state.transactions.filters,
        exports: state.asyncTasks.asyncTasks[AsyncTasksTypes.EXPORT_TRANSACTIONS] || new PagedState(),
        managerId: state.auth.manager ? state.auth.manager.id : null,
        country: state.appConfig.country,
        timezone: state.appConfig.timeZone,
    };
}

export default connect<any, any, any>(
    mapStateToProps,
    mapDispatchToProps
)(TransactionsExportPage as React.ComponentClass<Props, LocalState>);
