import * as React from 'react';
import BreadCrumbs from '../../utils/BreadCrumbs';
import { Form } from '../../utils/inputs';
import { withCreateLocationContainer, InputWrapper, Button } from './utils/createLocationUtilities';

interface Props {
    handleSubmit(e: Event): void;
    inputsState: [{ location1: string; location2: string }, () => void];
}

const inputs = [
    { name: 'location1', label: 'Location 1', isFocused: true },
    { name: 'location2', label: 'Location 2' },
];

function CreateLocationPage({ handleSubmit, inputsState }: Props) {
    const [data, setData] = inputsState;
    const disabled = !data.location1 || !data.location2;
    return (
        <>
            <BreadCrumbs path={`/beneficiaries/locations/new`} />
            <h3>Create location</h3>
            <Form className="wfp-form col-sm-12" onSubmit={handleSubmit}>
                {inputs.map((input) => (
                    <InputWrapper
                        isFocused={input.isFocused}
                        key={input.name}
                        label={input.label}
                        name={input.name}
                        onChange={setData}
                        value={data[input.name]}
                    />
                ))}
                <Button disabled={disabled}>Park</Button>
            </Form>
        </>
    );
}

export default withCreateLocationContainer(CreateLocationPage);
