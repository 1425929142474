import { routerReducer } from 'react-router-redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import { authReducer } from '../../login/auth';
import { vendorsReducer } from '../../vendors/vendors';
import { uploadTaskReducer, beneficiariesReducer } from '../../beneficiaries/beneficiaries';
import {
    transactionChainReducer,
    transactionsReducer,
    transactionDetailsReducer,
} from '../../transactions/transactions';
import { editBeneficiaryReducer } from '../../beneficiaries/edit/editBeneficiaryReducer';
import { notificationReducer } from '../../utils/notifications';
import { paymentRequestsReducer, paymentRequestReducer } from '../../paymentRequests/paymentRequests';
import { asyncTasksReducer } from '../../utils/asyncTasks';
import { securityReducer } from '../../security/security';
import { sessionExpirationReducer } from '../../utils/sessionExpiration/sessionExpiration';
import { managersReducer } from '../../managers/managers';
import { taskAuthorizationsReducer } from '../../authorization/taskAuthorizations';
import { typeTotalsReducer } from '../../transactions/transactionTypeTotals';
import { filtersReducer } from '../../utils/filtersStore';
import { partnersReducer, partnerUsersReducer } from '../../partners/partnersActions';
import { entityBalanceReducer } from '../../entityBalance/entityBalance';
import { activityLogsReducer } from '../../activityLogs/activityLogs';
import { pendingActionsReducer } from '../../utils/pedingActionsReducer';
import { appConfigReducer } from '../appConfig';
import { locationReducer } from '../../beneficiaries/locations/locations';
import { ProductPriceReducer } from '../../product-management/state/reducer';
import { routerHelperReducer } from '@wfp-common/store/routerHelperSlice';

export default {
    auth: authReducer,
    notification: notificationReducer,
    loadingBar: loadingBarReducer,
    vendors: vendorsReducer,
    routing: routerReducer,
    beneficiaries: beneficiariesReducer,
    uploadTaskState: uploadTaskReducer,
    filters: filtersReducer,
    editBeneficiary: editBeneficiaryReducer,
    transactions: transactionsReducer,
    transactionChain: transactionChainReducer,
    transactionDetails: transactionDetailsReducer,
    typeTotals: typeTotalsReducer,
    paymentRequests: paymentRequestsReducer,
    paymentRequest: paymentRequestReducer,
    asyncTasks: asyncTasksReducer,
    security: securityReducer,
    sessionExpiration: sessionExpirationReducer,
    managers: managersReducer,
    taskAuthorizations: taskAuthorizationsReducer,
    partners: partnersReducer,
    partnerUsers: partnerUsersReducer,
    entityBalance: entityBalanceReducer,
    activityLogs: activityLogsReducer,
    pendingActions: pendingActionsReducer,
    appConfig: appConfigReducer,
    locations: locationReducer,
    productPrices: ProductPriceReducer,
    routerHelper: routerHelperReducer,
};
