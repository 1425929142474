import * as React from 'react';

export function FamilyIcon() {
    return (
        <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
            <defs />
            <path
                className="a"
                d="M11.213 18.421h1.158v-1.158a.579.579 0 01.579-.579h3.474a.579.579 0 01.579.579v1.158h1.158v-1.158a1.739 1.739 0 00-1.737-1.737H12.95a1.739 1.739 0 00-1.737 1.737zM14.687 14.711a2.316 2.316 0 112.316-2.316 2.318 2.318 0 01-2.316 2.316zm0-3.474a1.158 1.158 0 101.158 1.158 1.159 1.159 0 00-1.158-1.158zM1.158 18.421h1.158v-1.158a.58.58 0 01.579-.579h3.474a.58.58 0 01.579.579v1.158h1.158v-1.158a1.739 1.739 0 00-1.737-1.737H2.895a1.739 1.739 0 00-1.737 1.737zM4.632 14.711a2.316 2.316 0 112.316-2.316 2.318 2.318 0 01-2.316 2.316zm0-3.474a1.158 1.158 0 101.158 1.158 1.159 1.159 0 00-1.158-1.158zM11.213 9.258h1.158V8.1a.58.58 0 01.579-.579h3.474a.58.58 0 01.579.579v1.158h1.158V8.1a1.739 1.739 0 00-1.737-1.737H12.95A1.739 1.739 0 0011.213 8.1zM14.687 5.471a2.316 2.316 0 112.316-2.316 2.318 2.318 0 01-2.316 2.316zm0-3.474a1.158 1.158 0 101.158 1.158 1.159 1.159 0 00-1.158-1.158zM1.158 9.258h1.158V8.1a.58.58 0 01.579-.579h3.474a.58.58 0 01.579.579v1.158h1.158V8.1a1.739 1.739 0 00-1.737-1.737H2.895A1.739 1.739 0 001.158 8.1zM4.632 5.471a2.316 2.316 0 112.316-2.316 2.318 2.318 0 01-2.316 2.316zm0-3.474A1.158 1.158 0 105.79 3.155a1.159 1.159 0 00-1.158-1.158z"
            />
            <path d="M0 0h19v19H0z" fill="none" />
        </svg>
    );
}
