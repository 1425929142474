import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import styled from 'styled-components';

interface Props {
    children: React.ReactNode;
    onApprove: (event: any) => void;
    buttonClassName?: string;
    buttonStyle?: any;
    withFinishNotifications?: boolean;
    customModalTitle?: React.ReactNode;
    customModalBody?: React.ReactNode;
    modalSize?: 'large' | 'small';
}

const ButtonWithModalConfirmation: React.FC<Props> = ({
    children,
    onApprove,
    customModalTitle,
    customModalBody,
    buttonClassName,
    buttonStyle,
    modalSize = 'small',
}) => {
    const [showModal, setShowModal] = useState(false);

    const onApproveHandler = (event) => {
        onApprove(event);
        setShowModal(false);
    };

    return (
        <div>
            <button
                className={buttonClassName ? buttonClassName : 'wfp-btn--primary'}
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setShowModal(true);
                }}
                style={buttonStyle ? buttonStyle : {}}
            >
                {children}
            </button>
            {showModal && (
                <ModalConfirmation
                    body={customModalBody}
                    modalSize={modalSize}
                    onApprove={onApproveHandler}
                    onClose={() => setShowModal(false)}
                    title={customModalTitle}
                />
            )}
        </div>
    );
};

ButtonWithModalConfirmation.displayName = 'ButtonWithModalConfirmation';

interface ModalConfirmationProps {
    title?: React.ReactNode;
    body?: React.ReactNode;
    customModalTitle?: React.ReactNode;
    customModalBody?: React.ReactNode;
    onClose: () => void;
    onApprove: (event: any) => void | Promise<any>;
    modalSize: 'large' | 'small';
}

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({ modalSize, title, body, onClose, onApprove }) => {
    const defaultModalTitle = 'Confirmation is required';
    const defaultModalMessage = 'Do you confirm ?';

    const modalDialogClassName = `modal-${modalSize}`;

    return (
        <div>
            <Background />
            <Modal.Dialog dialogClassName={modalDialogClassName}>
                <Modal.Header>
                    <Modal.Title>{title || defaultModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'text-left'}>{body || defaultModalMessage}</Modal.Body>
                <Modal.Footer>
                    <button
                        className="wfp-btn negative-color"
                        onClick={onClose}
                        style={{ marginRight: 10 }}
                        type="button"
                    >
                        Close
                    </button>
                    <button className="wfp-btn--primary" onClick={onApprove} style={{ marginRight: 10 }} type="button">
                        Approve
                    </button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
};

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.4);
`;

ModalConfirmation.displayName = 'ModalConfirmation';

export { ButtonWithModalConfirmation };
