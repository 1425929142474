import * as React from 'react';
import { useEntityUpdateRequests } from '../entity-update-request/entity-update-request.context';
import { ProductManagementAuthorizationCardComponent } from './product-management-authorization-card.component';
import styled from 'styled-components';
import { EntityUpdateRequest } from '../taskAuthorizations';
import { Loader } from '../../utils/Loader';

export default function ProductManagementAuthorizationListComponent() {
    const { updateRequests, manager, isShowingPendingUpdates, loading } = useEntityUpdateRequests();

    if (loading) {
        return (
            <Paragraph>
                <Loader />
            </Paragraph>
        );
    }

    if (!updateRequests.length) {
        return (
            <Paragraph>
                <strong>There are no requests to be authorized</strong>
            </Paragraph>
        );
    }

    return updateRequests.map((updateRequest: EntityUpdateRequest) => (
        <ProductManagementAuthorizationCardComponent
            isShowingPendingUpdates={isShowingPendingUpdates}
            key={updateRequest.id}
            manager={manager}
            updateRequest={updateRequest}
        />
    ));
}

const Paragraph = styled.p`
    text-align: center;
`;
