import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Beneficiary, BeneficiaryStatus, BeneficiaryStatuses } from '../beneficiaries';
import State from '../../app/store/state';
import { EditBeneficiaryCreators } from './editBeneficiaryActionCreator';
import { AuthState, hasFeatureAccess } from '../../login/auth';
import { SideMenu, SideMenuProps } from '../../utils/SideMenu';
import BeneficiarySearch from '../BeneficiarySearch';
import { ManagerPermission } from '../../permission-profiles/permission';
import { Link } from 'react-router';
import { NavigateHook } from '@wfp-common/hooks/useNavigate';
import { withNavigate } from '@wfp-common/hooks/withNavigate';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

export enum BeneficiaryTab {
    Main = 'Main',
    Details = 'Details',
    Comments = 'Comments',
    Entitlements = 'Entitlements',
    Adjustments = 'Adjustments',
    Expenditures = 'Expenditures',
    PendingActions = 'Pending actions',
    ActivityLogs = 'Activity logs',
    // BeneficiaryTotals = 'Beneficiary totals',
    // BeneficiaryAlerts = 'Beneficiary alerts',
    Tokens = 'Tokens',
    Limitations = 'Limitations',
}

export const urlPathForTab = {
    [BeneficiaryTab.Main]: 'main',
    [BeneficiaryTab.Details]: 'details',
    [BeneficiaryTab.Comments]: 'comments',
    [BeneficiaryTab.Entitlements]: 'entitlements',
    [BeneficiaryTab.PendingActions]: 'pending-actions',
    [BeneficiaryTab.Expenditures]: 'expenditures',
    [BeneficiaryTab.Adjustments]: 'adjustments',
    [BeneficiaryTab.ActivityLogs]: 'activity-logs',
    // [BeneficiaryTab.BeneficiaryTotals]: 'totals',
    // [BeneficiaryTab.BeneficiaryAlerts]: 'alerts',
    [BeneficiaryTab.Tokens]: 'tokens',
    [BeneficiaryTab.Limitations]: 'limitations',
};

interface OwnProps {
    location: { state: { beneficiaryId: string } };
}

interface Props {
    routes: { path: string }[];
    router: any;
    beneficiaryId: string;
    beneficiaryStatus?: BeneficiaryStatus;
    auth: AuthState;
    beneficiary: Beneficiary;
    searchBeneficiary: (beneficiaryId: string) => void;
    redirectToPage: (url: string) => void;
    loadBeneficiary: (string) => void;
    navigate: NavigateHook;
}

interface DetailsState {
    activeTab: BeneficiaryTab;
    searchValue: string;
}

function EditBeneficiarySideMenu(props: SideMenuProps<BeneficiaryTab>) {
    return SideMenu(props);
}

class EditBeneficiaryPage extends React.Component<Props, DetailsState> {
    private createDefaultState() {
        return {
            searchValue: '',
            beneficiary: new Beneficiary(),
            activeTab: BeneficiaryTab.Details,
        };
    }

    constructor(props) {
        super(props);
        this.state = this.createDefaultState();
    }

    componentDidMount() {
        const { loadBeneficiary, beneficiaryId } = this.props;
        loadBeneficiary(beneficiaryId);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { loadBeneficiary, beneficiaryId } = this.props;

        if (prevProps.beneficiaryId !== beneficiaryId) {
            loadBeneficiary(beneficiaryId);
        }
    }

    renderBreadcrumbs() {
        const { beneficiary } = this.props;
        return (
            <div className="col-sm-12 row">
                <nav className="wfp-breadcrumbs col-sm-6">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/beneficiaries">
                                <span>Beneficiaries</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{`Edit Beneficiary ${
                                beneficiary ? '"' + beneficiary.id + '"' : ''
                            }`}</span>
                        </li>
                    </ol>
                </nav>
                <BeneficiarySearch
                    search={(value) => this.props.searchBeneficiary(value)}
                    style={{ justifyContent: 'flex-end' }}
                />
            </div>
        );
    }

    activeMenuTab(): BeneficiaryTab {
        const { routes } = this.props;
        const lastUrlPart = routes[routes.length - 1].path;
        for (const key of Object.keys(urlPathForTab)) {
            if (urlPathForTab[key] === lastUrlPart) {
                return key as BeneficiaryTab;
            }
        }
    }

    renderSidemenu() {
        const generalSubtabs = [
            BeneficiaryTab.Main,
            BeneficiaryTab.Details,
            BeneficiaryTab.Entitlements,
            BeneficiaryTab.Tokens,
        ];
        const transactionSubtabs = [
            BeneficiaryTab.Adjustments,
            BeneficiaryTab.Expenditures,
            // BeneficiaryTab.BeneficiaryAlerts,
            // BeneficiaryTab.BeneficiaryTotals,
        ];
        const beneficiaryUpdatesTab = BeneficiaryTab.PendingActions;
        const beneficiaryActivityLog = BeneficiaryTab.ActivityLogs;
        const beneficiaryProductLimitationsTab = BeneficiaryTab.Limitations;

        const { auth, beneficiaryStatus, beneficiary } = this.props;
        const withTransactionTabs = hasFeatureAccess(auth, ManagerPermission.transactionRequestRead);
        const withPendingActions = hasFeatureAccess(auth, ManagerPermission.beneficiariesRead);
        const withActivityLogTab = hasFeatureAccess(auth, ManagerPermission.beneficiariesRead);
        const withProductLimitationsTab = hasFeatureAccess(auth, ManagerPermission.productsLimitationRead);

        let displayedSubtabs = withTransactionTabs ? [...generalSubtabs, ...transactionSubtabs] : generalSubtabs;
        displayedSubtabs = withProductLimitationsTab
            ? [...displayedSubtabs, beneficiaryProductLimitationsTab]
            : displayedSubtabs;
        displayedSubtabs = withPendingActions ? [...displayedSubtabs, beneficiaryUpdatesTab] : displayedSubtabs;
        displayedSubtabs = withActivityLogTab ? [...displayedSubtabs, beneficiaryActivityLog] : displayedSubtabs;

        const activeTab = this.activeMenuTab();
        return (
            <div>
                <h5 style={{ marginTop: 0, marginBottom: 0 }}>
                    {beneficiary ? beneficiary.id : ''}
                    {beneficiaryStatus === BeneficiaryStatuses.active && (
                        <span className={'active-indicator'}>Active</span>
                    )}
                    {beneficiaryStatus === BeneficiaryStatuses.blocked && (
                        <span className={'blocked-indicator'}>Blocked</span>
                    )}
                </h5>
                <EditBeneficiarySideMenu
                    activeTab={activeTab}
                    onSelectTab={(activeTab) => {
                        const url = urlPathForTab[activeTab];
                        this.props.navigate(`/beneficiaries/beneficiary/${url}`, { beneficiaryId: beneficiary.id });
                    }}
                    tabs={displayedSubtabs}
                />
            </div>
        );
    }

    render() {
        return (
            <main className={'row'}>
                {this.renderBreadcrumbs()}
                <div className="col-sm-3">{this.renderSidemenu()}</div>
                <div className="col-sm-9" style={{ marginBottom: 150, position: 'static' }}>
                    {this.props.children}
                </div>
                <div className="clearfix" />
            </main>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        searchBeneficiary: bindActionCreators(EditBeneficiaryCreators.searchBeneficiary, dispatch),
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
        loadBeneficiary: bindActionCreators(EditBeneficiaryCreators.loadBeneficiary, dispatch),
    };
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        beneficiaryStatus: state.editBeneficiary.beneficiary ? state.editBeneficiary.beneficiary.status : null,
        beneficiaryId: ownProps.location.state.beneficiaryId,
        beneficiary: state.editBeneficiary.beneficiary,
        auth: state.auth,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(EditBeneficiaryPage, 'EditBeneficiaryPage'));
