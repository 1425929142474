import * as React from 'react';
import { LOCATION_CHANGE as RouterLocationChangeType } from 'react-router-redux';
import { ActionTypes as LoginActionTypes } from '../login/auth';
import Action from '../action';

export const ActionTypes = {
    success: 'Global.success',
    error: 'Global.error',
    warning: 'Gloabl.warning',
    clear: 'Global.clearNotifications',
};

export class NotificationState {
    type?: string;
    message?: string;
}

export function notificationReducer(state = null, action: Action) {
    switch (action.type) {
        case RouterLocationChangeType:
        case ActionTypes.clear:
        case LoginActionTypes.loginFailed:
            return null;
        case ActionTypes.success:
            return { type: 'success', message: action.payload };
        case ActionTypes.warning:
            return { type: 'warning', message: action.payload };
        case ActionTypes.error:
            return { type: 'danger', message: action.payload };
    }

    return state;
}

export function successAction(message) {
    return {
        type: ActionTypes.success,
        payload: message,
    };
}

export const StatusTexts = {
    400: 'Your request was incomplete or invalid.',
    401: 'You are not authenticated. Please log in.',
    403: 'You are not authorized to perform this action.',
    404: 'There is no content at this location.',
    449: 'Your previous session is expired, please login again.',
};

function errorText(error) {
    if (typeof error === 'string') {
        return error;
    }

    return error.message || StatusTexts[error.status] || 'Something went wrong!';
}

export function errorAction(error) {
    return {
        type: ActionTypes.error,
        payload: errorText(error),
    };
}

export function warningAction(warningMessage) {
    return {
        type: ActionTypes.warning,
        payload: warningMessage,
    };
}

export const closeNotificationAction = {
    type: ActionTypes.clear,
};

interface Props {
    of: NotificationState;
    close: () => void;
}

export class Notification extends React.Component<Props, any> {
    _close(event) {
        event.preventDefault();
        this.props.close();
    }

    render() {
        const style = {
            textDecoration: 'none',
            color: '#000',
        };

        return (
            <div className={`alert alert-${this.props.of.type}`}>
                {this.props.of.message}
                <a className="close" onClick={this._close.bind(this)} style={style}>
                    &times;
                </a>
            </div>
        );
    }
}
