import * as React from 'react';

// eslint-disable-next-line react/display-name
export default class extends React.Component<any, any> {
    render() {
        return (
            <main>
                <nav className="wfp-breadcrumbs">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">Home</span>
                        </li>
                    </ol>
                </nav>
                <h3>Home</h3>
            </main>
        );
    }
}
