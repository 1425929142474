import * as jwtDecode from 'jwt-decode';
import { ActionTypes } from './sessionExpiration';

const SecondsForExpirationAlert = 5 * 60;

function secondsLeftToTokenExpiration(apiToken) {
    const tokenPrefix = 'JWT ';
    const tokenWithoutPrefix = apiToken.slice(tokenPrefix.length);
    if (tokenPrefix + tokenWithoutPrefix !== apiToken) {
        return 0;
    }
    const decodedToken = jwtDecode(tokenWithoutPrefix);
    const dateNowSeconds = Date.now() / 1000;
    return decodedToken.exp - dateNowSeconds;
}

function isTokenExpired(apiToken) {
    const secondsLeftToExpiration = secondsLeftToTokenExpiration(apiToken);
    return secondsLeftToExpiration <= 0;
}

function isTokenExpiredSoon(apiToken) {
    const secondsLeftToExpiration = secondsLeftToTokenExpiration(apiToken);
    return secondsLeftToExpiration <= SecondsForExpirationAlert;
}

function shouldPresentSessionProlongAlert(params) {
    const shouldPresentAlert = params.apiToken && isTokenExpiredSoon(params.apiToken);
    const canPresentAlert = !params.prolongSessionAlertPresented && !params.didCloseAlert;
    return shouldPresentAlert && canPresentAlert;
}

export function checkTokenExpiration(getTokenFromState, onTokenExpiration) {
    return (dispatch, getState) => {
        const state = getState();
        const apiToken = getTokenFromState(state);
        const { prolongSessionAlertPresented, didCloseAlert } = state.sessionExpiration;
        const params = {
            apiToken,
            prolongSessionAlertPresented,
            didCloseAlert,
        };

        if (shouldPresentSessionProlongAlert(params)) {
            dispatch({ type: ActionTypes.showSessionProlongAlert });
        }
        if (apiToken && isTokenExpired(apiToken)) {
            dispatch(onTokenExpiration);
        }
    };
}
