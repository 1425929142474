import * as React from 'react';
import styled from 'styled-components';
import { Outlets } from './outlets.component';
import { LoadPrices } from './utils/load-prices.utils';
import { SortableTableHead } from '../utils/table/table-head/sortable-table-head';
import { ProductType } from './types/product.type';
import { ChangeSortOptions } from '../utils/hooks/useSort';

interface Props {
    products: ProductType[];
    onSort: ChangeSortOptions;
}
export function ProductList({ products, onSort }: Props) {
    return (
        <Container>
            <table className="wfp-table">
                <colgroup>
                    <col className="col-width" />
                    <col />
                    <col />
                    <col />
                </colgroup>
                {products.length ? (
                    <>
                        <SortableTableHead changeSortOptions={onSort}>
                            <SortableTableHead.Cell columnName="productName">Item</SortableTableHead.Cell>
                            <SortableTableHead.Cell columnName="minPrice">Min Price</SortableTableHead.Cell>
                            <SortableTableHead.Cell columnName="maxPrice">Max Price</SortableTableHead.Cell>
                            <SortableTableHead.Cell columnName="isFixed">Fixed</SortableTableHead.Cell>
                            <SortableTableHead.DisabledColumn colSpan={3} />
                        </SortableTableHead>
                        <tbody>
                            {products?.map((product) => (
                                <tr key={product.name}>
                                    <th>
                                        <span style={{ whiteSpace: 'nowrap' }}> {product.name} </span>
                                    </th>
                                    <td> {Number(product.minPrice).toFixed(2)} </td>
                                    <td> {product.maxPrice === null ? '' : Number(product.maxPrice).toFixed(2)} </td>
                                    <td colSpan={4}> {product.isFixed ? 'Yes' : 'No'} </td>
                                </tr>
                            ))}
                        </tbody>
                    </>
                ) : (
                    <tr>
                        <td colSpan={7}>
                            <p>
                                <strong>No products found</strong>
                            </p>
                        </td>
                    </tr>
                )}
                <tr>
                    <td colSpan={7} style={{ border: 'none' }} />
                </tr>
            </table>
        </Container>
    );
}
const Container = styled.div`
    display: grid;
    padding-bottom: 1rem;
    table {
        table-layout: fixed;
        .col-width {
            width: 300px;
        }
        thead tr th {
            background: rgba(238, 246, 255, 1);
        }
    }
    h6 {
        color: rgba(55, 55, 55, 0.8);
        font-size: 1rem;
        font-weight: 700;
    }
`;
