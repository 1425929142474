import * as React from 'react';
import { useActionCreator } from '../../utils/useActionCreator';
import { ActionCreators as AsyncTaskActionCreators, AsyncTasksTypes } from '../../utils/asyncTasks';
import { useState } from 'react';
import { uploadProductUpdateFile } from '../../api/product-management';
import { SPREADSHEET_EXTENSIONS } from '../../constants/file-upload-extensions.constant';
import { ConfirmationDialog } from '../../utils/Dialogs';

interface Props {
    asyncTaskId?: string;
    entityUpdateRequestId: string;
    productGroupId: string;
}

export function ProductManagementUploadUpdateFileComponent({
    entityUpdateRequestId,
    productGroupId,
    asyncTaskId,
}: Props) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [file, setFile] = useState<File>(null);
    const downloadFile = useActionCreator(AsyncTaskActionCreators.downloadFile);
    const handleClick = () => {
        downloadFile(AsyncTasksTypes.PRODUCT_UPDATE, asyncTaskId);
    };
    const uploadPermissions = () => {
        uploadProductUpdateFile(entityUpdateRequestId, productGroupId, file).catch((v) => v);
        closeModal();
    };
    const openModal = (event) => {
        setFile(event.target.files[0]);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setFile(null);
    };
    return (
        <>
            {isOpen && (
                <ConfirmationDialog
                    message={
                        <p>
                            Are you sure you want to upload <strong>{file.name}</strong>?
                        </p>
                    }
                    onClose={closeModal}
                    onConfirm={uploadPermissions}
                    title="Upload product update file"
                />
            )}
            <p>
                {asyncTaskId ? (
                    <>
                        <a onClick={handleClick}>Download Update File</a>
                    </>
                ) : (
                    <label
                        className="mb-0 cursor-pointer"
                        htmlFor="product-update-file"
                        style={{ fontWeight: 'normal' }}
                    >
                        <a>Upload File</a>
                        <input
                            accept={SPREADSHEET_EXTENSIONS}
                            hidden
                            id="product-update-file"
                            name="product-update-file"
                            onChange={openModal}
                            type="file"
                        />
                    </label>
                )}
            </p>
        </>
    );
}
