import * as React from 'react';
import { Form, Input, LinkWithValidationContext, ValidationContext } from '../utils/inputs';
import { Modal } from 'react-bootstrap';
import { PaymentRequest, SapPaymentRequestProposal } from './paymentRequests';
import { currencyFormatter } from '../utils/utils';
import { stopPropagation } from '../utils/events';
import { BigNumber } from '../utils/bigNumber';
import { EntitlementCurrencyConfig, SapConfig } from '../app/appConfig';
import * as moment from 'moment-timezone';
import { getPaymentSapPoDetails } from '../apiClient';

interface PoDetailsResponse {
    paymentRequestId: number;
    availableSapPos: Array<SapPaymentRequestProposal>;
}
interface Props {
    onSubmit: (
        paymentRequestId: string,
        routeOverride?: string,
        paymentsByPo?: UserSapPaymentRequestProposal[]
    ) => void;
    onClose: () => void;
    errorHandler: (err) => void;

    title: string;
    confirmTitle: string;
    request: PaymentRequest;
    sapConfig: SapConfig;
    currency: EntitlementCurrencyConfig;
}

interface UserSapPaymentRequestProposal extends SapPaymentRequestProposal {
    isEditable: boolean;
}

interface State {
    userSapPaymentRequests: UserSapPaymentRequestProposal[];
}

export default class SapPaymentRequestProposalsDialog extends React.Component<Props, State> {
    private validationContext = new ValidationContext();

    constructor(props) {
        super(props);
        this.state = {
            userSapPaymentRequests: [],
        };
    }

    async componentDidMount(): Promise<void> {
        const { id } = this.props.request;
        try {
            const sapPoResponse: PoDetailsResponse = await getPaymentSapPoDetails(id);
            const sapPoDetails = sapPoResponse.availableSapPos.map((poDetails: UserSapPaymentRequestProposal) => {
                return {
                    ...poDetails,
                    isEditable: false,
                };
            });
            this.setSortedState(sapPoDetails);
        } catch (err) {
            this.props.onClose();
            this.props.errorHandler(err);
        }
    }

    private setSortedState(userSapPaymentRequests: UserSapPaymentRequestProposal[]) {
        const sortedState = this.sortPOToAmount(userSapPaymentRequests);
        this.setState({ userSapPaymentRequests: sortedState });
    }

    private formatter = currencyFormatter(this.props.currency).format;

    private sortPOToAmount(userSapPaymentRequest: Array<UserSapPaymentRequestProposal>) {
        const sortedAmounts = userSapPaymentRequest
            .slice(0)
            .sort((firstEl: UserSapPaymentRequestProposal, secondEl: UserSapPaymentRequestProposal) => {
                return (
                    moment(firstEl.terminationDate).diff(moment(secondEl.terminationDate)) ||
                    parseFloat(firstEl.poNumber) - parseFloat(secondEl.poNumber) ||
                    parseFloat(firstEl.poItem) - parseFloat(secondEl.poItem)
                );
            });
        return sortedAmounts;
    }

    private renderBody(sapPaymentRequestDetails: UserSapPaymentRequestProposal[]) {
        return (
            <div>
                <div className="form-group">
                    {this.renderTableWithSapPaymentRequestProposals(sapPaymentRequestDetails)}
                </div>
            </div>
        );
    }

    private renderTableWithSapPaymentRequestProposals(sapPaymentRequestDetails: UserSapPaymentRequestProposal[]) {
        return this.renderTable(sapPaymentRequestDetails);
    }

    private renderEditableAmountFields(value: string, sapPaymentRequest: UserSapPaymentRequestProposal) {
        if (sapPaymentRequest.isEditable) {
            return (
                <Input
                    className="form-control"
                    context={this.validationContext}
                    errorMessages={{
                        min: 'Amount must be positive',
                        max: 'Amount must be less or equal available amount',
                    }}
                    hideErrorBox={true}
                    isFocused={true}
                    max={parseFloat(sapPaymentRequest.availableAmount)}
                    min={0}
                    name={`requestedAmount${sapPaymentRequest.poItem}`}
                    onChange={(event) =>
                        this.handleCellEdit({
                            name: 'requestedAmount',
                            id: `${sapPaymentRequest.poNumber}-${sapPaymentRequest.poItem}`,
                            newValue: event.target.value,
                        })
                    }
                    required={true}
                    step={0.001}
                    type="number"
                    value={Number.isNaN(parseFloat(value)) ? '' : value}
                />
            );
        } else {
            return this.formatter(parseFloat(sapPaymentRequest.requestedAmount));
        }
    }

    private renderCellEditButton(sapPaymentRequest: UserSapPaymentRequestProposal) {
        return (
            <LinkWithValidationContext
                context={this.validationContext}
                onClick={stopPropagation((event) => {
                    event.preventDefault();
                    this.handleCellClick({
                        id: `${sapPaymentRequest.poNumber}-${sapPaymentRequest.poItem}`,
                    });
                })}
            >
                {sapPaymentRequest.isEditable ? 'Save' : 'Edit'}
            </LinkWithValidationContext>
        );
    }

    private handleCellClick = (rowToEdit: { id: string }) => {
        const editedAmountPo = this.state.userSapPaymentRequests.find(
            (sapPaymentRequest) => `${sapPaymentRequest.poNumber}-${sapPaymentRequest.poItem}` === rowToEdit.id
        );
        const isCurrentlyEditable = editedAmountPo.isEditable;
        const allOther = this.state.userSapPaymentRequests
            .filter(
                (sapPaymentRequest) =>
                    sapPaymentRequest.poItem !== editedAmountPo.poItem ||
                    sapPaymentRequest.poNumber !== editedAmountPo.poNumber
            )
            .map((sapPaymentRequest) => {
                return { ...sapPaymentRequest, isEditable: false };
            });
        const newState = [...allOther, { ...editedAmountPo, isEditable: !isCurrentlyEditable }];
        this.setSortedState(newState);
    };
    private handleCellEdit = (rowToEdit: {
        name: keyof UserSapPaymentRequestProposal;
        id: string;
        newValue: string;
    }) => {
        const editedField = rowToEdit.name;
        const value = rowToEdit.newValue;
        const editedSapPaymentProposal = this.state.userSapPaymentRequests.find(
            (sapPaymentRequest) => `${sapPaymentRequest.poNumber}-${sapPaymentRequest.poItem}` === rowToEdit.id
        );
        const allOther = this.state.userSapPaymentRequests.filter(
            (sapPaymentRequest) =>
                sapPaymentRequest.poItem !== editedSapPaymentProposal.poItem ||
                sapPaymentRequest.poNumber !== editedSapPaymentProposal.poNumber
        );
        const newState = [...allOther, { ...editedSapPaymentProposal, [editedField]: value }];
        this.setSortedState(newState);
    };

    private renderTable(sapPaymentRequestDetails: UserSapPaymentRequestProposal[]) {
        return (
            <div className="pre-scrollable m-3">
                <table className="wfp-table--striped mt3 table-hover">
                    {this.renderTableHeaderForSapPaymentRequestProposals()}
                    <tbody>
                        {sapPaymentRequestDetails.map((sapPaymentRequest: UserSapPaymentRequestProposal) => {
                            const isPoDisabled = new BigNumber(sapPaymentRequest.availableAmount).isEqualTo(0);

                            return (
                                <tr key={`${sapPaymentRequest.poNumber}-${sapPaymentRequest.poItem}`}>
                                    <td>{sapPaymentRequest.poNumber ? sapPaymentRequest.poNumber : '-'}</td>
                                    <td>{sapPaymentRequest.poItem}</td>
                                    <td>{this.formatter(parseFloat(sapPaymentRequest.availableAmount))}</td>
                                    <td>
                                        {this.renderEditableAmountFields(
                                            sapPaymentRequest.requestedAmount,
                                            sapPaymentRequest
                                        )}
                                    </td>
                                    <td>
                                        {moment
                                            .tz(sapPaymentRequest.terminationDate, 'CET')
                                            .endOf('day')
                                            .format('YYYY-MM-DD HH:mm:ss')}{' '}
                                        CET
                                    </td>
                                    {<td>{!isPoDisabled && this.renderCellEditButton(sapPaymentRequest)}</td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    private renderTableHeaderForSapPaymentRequestProposals() {
        return (
            <thead>
                <tr>
                    <th>PO Number</th>
                    <th>Line Item</th>
                    <th>Available Amount</th>
                    <th>Payment Value</th>
                    <th>TDD</th>
                    <th>Action</th>
                </tr>
            </thead>
        );
    }

    renderWaitingModal() {
        const { onClose } = this.props;
        return (
            <div>
                <div className="fade modal-backdrop in small" />
                <Modal.Dialog dialogClassName="modal-small">
                    <Modal.Header>
                        <Modal.Title>SAP PO Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="mv3">
                                <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-striped progress-bar-animated"
                                        role="progressbar"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div>Please wait while WINGSII data is loading...</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="wfp-btn" onClick={() => onClose()} style={{ marginRight: 10 }} type="button">
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        );
    }

    renderReadyModal() {
        const { onClose, title, confirmTitle, request, sapConfig } = this.props;
        const { userSapPaymentRequests } = this.state;
        const selectedSapPaymentProposals = userSapPaymentRequests.filter((sapPaymentRequest) =>
            new BigNumber(sapPaymentRequest.requestedAmount).isGreaterThan(0)
        );
        const isAnyCellEdited = Boolean(
            this.state.userSapPaymentRequests.find((sapPaymentRequest) => sapPaymentRequest.isEditable)
        );
        const isAnyCellSelected = Boolean(selectedSapPaymentProposals.length > 0);
        const isSelectedLengthMoreThanMax = Boolean(
            selectedSapPaymentProposals.length > this.props.sapConfig.maxPOnumber
        );

        const paymentAmount: BigNumber = new BigNumber(this.props.request.payableAmount);
        const allocatedAmount: BigNumber = selectedSapPaymentProposals.reduce((prevValue, currentValue) => {
            const currentBigNumber = new BigNumber(currentValue.requestedAmount);
            if (currentBigNumber.isNaN()) {
                return prevValue;
            } else {
                return prevValue.plus(currentBigNumber);
            }
        }, new BigNumber(0));
        const difference: BigNumber = paymentAmount.minus(allocatedAmount);
        const areAmountsDifferent = !difference.isEqualTo(0);
        const isTotalLessThanMinimum = allocatedAmount.isLessThan(sapConfig.sapDocumentChainMinimumAmount);

        return (
            <div>
                <div className="fade modal-backdrop in large" />
                <Modal.Dialog dialogClassName="modal-large">
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Form
                        className="mv1 wfp-form"
                        context={this.validationContext}
                        onSubmit={() => this.props.onSubmit(request.id, null, selectedSapPaymentProposals)}
                    >
                        <Modal.Body>
                            <h6 className="m-3">
                                Payment amount:
                                <small className="text-muted">{this.formatter(paymentAmount.toNumber())}</small>
                            </h6>
                            <h6 className="m-3">
                                Selected POs amount:
                                <small className="text-muted">{this.formatter(allocatedAmount.toNumber())}</small>
                            </h6>
                            <h6 className="m-3">
                                Difference:
                                <small className={areAmountsDifferent ? 'text-danger' : 'text-success'}>
                                    {this.formatter(difference.toNumber())}
                                </small>
                            </h6>
                            {isTotalLessThanMinimum && !isAnyCellEdited && (
                                <p className="modal-label m-3">
                                    Minimum WINGSII payment amount is {sapConfig.sapDocumentChainMinimumAmount} JOD
                                </p>
                            )}
                            {isSelectedLengthMoreThanMax && (
                                <p className="modal-label m-3">Maximum different possible POs to select is 5</p>
                            )}
                            {request && this.renderBody(userSapPaymentRequests)}
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="wfp-btn"
                                onClick={() => onClose()}
                                style={{ marginRight: 10 }}
                                type="button"
                            >
                                Cancel
                            </button>
                            <button
                                className="wfp-btn--primary"
                                disabled={
                                    isAnyCellEdited ||
                                    areAmountsDifferent ||
                                    isSelectedLengthMoreThanMax ||
                                    isTotalLessThanMinimum ||
                                    !isAnyCellSelected
                                }
                                type="submit"
                            >
                                {confirmTitle}
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal.Dialog>
            </div>
        );
    }

    render() {
        const { userSapPaymentRequests } = this.state;
        if (userSapPaymentRequests.length > 0) {
            return this.renderReadyModal();
        } else {
            return this.renderWaitingModal();
        }
    }
}
