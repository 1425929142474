import * as React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import State from '../app/store/state';
import { ExternalValidationError } from '../utils/inputs';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { SideMenu } from '../utils/SideMenu';
import { ActionCreators } from './managers';
import { ActionCreators as authActionCreators } from '../login/auth';
import { PagedState } from '../utils/paging';
import { ManagerPermission } from '../permission-profiles/permission';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

enum ManagerTabs {
    PasswordAndSecurity = 'Password & Security',
    DetailsAndPreferences = 'Personal Details & Preferences',
    ActivityLogs = 'Activity Logs',
}

const urlPathForTab = {
    [ManagerTabs.PasswordAndSecurity]: 'security',
    [ManagerTabs.DetailsAndPreferences]: 'details',
    [ManagerTabs.ActivityLogs]: 'activity-log',
};

interface Props {
    routes: { path: string }[];
    redirectToPage: (url: string) => void;
    validationError?: ExternalValidationError;
    auth: AuthState;
    getManagers: () => void;
    reloadUserData: () => void;
}

interface ManagerState {
    activeTab: ManagerTabs;
    activityLogs: PagedState<any>;
}

class ManagersMainPage extends React.Component<Props, ManagerState> {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.reloadUserData();
        this.setState({
            activeTab: ManagerTabs.DetailsAndPreferences,
        });
    }

    renderSidemenu() {
        const subtabs = [ManagerTabs.DetailsAndPreferences];

        const { auth } = this.props;
        subtabs.push(ManagerTabs.PasswordAndSecurity);
        if (hasFeatureAccess(auth, ManagerPermission.userActivityView)) {
            subtabs.push(ManagerTabs.ActivityLogs);
        }
        const activeTab = this.activeMenuTab();

        return (
            <div>
                <SideMenu
                    activeTab={activeTab}
                    onSelectTab={(tab) => {
                        const url = urlPathForTab[tab];
                        this.props.redirectToPage(`/personal/${url}`);
                    }}
                    tabs={subtabs}
                />
            </div>
        );
    }

    activeMenuTab(): ManagerTabs {
        const { routes } = this.props;
        const lastUrlPart = routes[routes.length - 1].path;
        for (const key of Object.keys(urlPathForTab)) {
            if (urlPathForTab[key] === lastUrlPart) {
                return key as ManagerTabs;
            }
        }
    }

    renderBreadcrumbs() {
        return (
            <div className="row col-sm-12">
                <nav className="wfp-breadcrumbs col-sm-6">
                    <ol className="breadcrumbs--wrapper">
                        <li className="breadcrumbs--item">
                            <Link className="breadcrumbs--link" to="/home">
                                <span>Home</span>
                            </Link>
                        </li>
                        <li className="breadcrumbs--item">
                            <span className="breadcrumbs--last">{`Personal`}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        );
    }

    render() {
        return (
            <main className={'row'}>
                {this.renderBreadcrumbs()}
                <div className="col-sm-3">{this.renderSidemenu()}</div>
                <div className="col-sm-9" style={{ marginBottom: 150 }}>
                    {this.props.children}
                </div>
            </main>
        );
    }
}

function mapStateToProps(state: State) {
    return {
        auth: state.auth,
        validationError: state.managers.validationError,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
        getManagers: bindActionCreators(ActionCreators.getManagers, dispatch),
        reloadUserData: bindActionCreators(authActionCreators.reloadUserData, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagersMainPage as any);

// const SearchContainerStyle: React.CSSProperties = {
//     display: 'flex',
//     justifyContent: 'flex-end',
// };
//
// const TabsContainerStyle: React.CSSProperties = {
//     display: 'flex',
//     justifyContent: 'center',
//     marginBottom: 20,
//     marginTop: 20,
// };
