import { getPermissionsList } from '../../apiClient';
import { Filter, MultiSelectFilter, SelectOption } from '../../utils/FilterTypes';
import { useEffect, useState } from 'react';
import { compose, separateCamelCase, splitSubstring, wfpFormat } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from '../managers';
import { sortableColumnNames, sortingOrder } from '../ManagersPage';
import State from '../../app/store/state';
import { UserStatuses } from '../../utils/users';
import { startCase } from 'lodash';
import {
    PermissionProfiles,
    PermissionProfileStatus,
    usePermissionProfiles,
} from '../../permission-profiles/use-permission-profiles';

export function useManagerFilters() {
    const [filters, setFilters] = useState<Filter[]>([]);
    const managerStatuses = useSelector((state: State) => state.appConfig.userStatuses.managerStatus);
    const sortingOptions = useSelector((state: State) => state.managers.sortingOptions);
    const profilesData: PermissionProfiles = usePermissionProfiles(PermissionProfileStatus.authorizedActive);

    const dispatch = useDispatch();
    useEffect(() => {
        createFilters(managerStatuses, profilesData)
            .then((res) => {
                setFilters(res);
                ActionCreators.getManagers(
                    sortableColumnNames.firstName,
                    sortingOrder.ASC,
                    res.filter((input) => input.isSelected)
                )(dispatch).catch((v) => v);
            })
            .catch((v) => v);
    }, [profilesData.items]);

    const handleApplyFilters = (formInputs: Filter[]) => {
        ActionCreators.getManagers(
            sortingOptions.sortingColumn,
            sortingOptions.sortingOrder,
            formInputs.filter((input) => input.isSelected)
        )(dispatch).catch((v) => v);
    };

    return { filters, handleApplyFilters };
}
async function createFilters(managerStatuses: UserStatuses[], profilesData: PermissionProfiles): Promise<Filter[]> {
    const profilesFilter = await createProfilesFilter(profilesData);
    const permissionsFilter = await createPermissionsFilter();

    const statusFilter = createStatusFilter(managerStatuses);

    return [profilesFilter, permissionsFilter, statusFilter];
}

async function createProfilesFilter(profilesData: PermissionProfiles): Promise<Filter> {
    const profiles = profilesData.items.map((profile) => new SelectOption(profile.name, profile.id));
    return new MultiSelectFilter('Profiles', 'profiles', [], [...profiles]);
}
async function createPermissionsFilter(): Promise<Filter> {
    const permissions = (await getPermissionsList()).data.map((permission) => {
        const label = compose<string, string>(wfpFormat, splitSubstring('mfa'), separateCamelCase)(permission);
        return {
            label,
            value: permission,
        };
    });
    return new MultiSelectFilter('Permissions', 'permissions', [], permissions);
}

function createStatusFilter(managerStatuses: UserStatuses[]): Filter {
    const statusesOptions = Object.values(managerStatuses).map((status: UserStatuses) => ({
        label: startCase(status),
        value: status,
    }));

    return new MultiSelectFilter(
        'Status',
        'status',
        [
            { label: startCase(UserStatuses.active), value: UserStatuses.active },
            { label: startCase(UserStatuses.parked), value: UserStatuses.parked },
        ],
        statusesOptions,
        null,
        true
    );
}
