import * as React from 'react';
import FeatureButton from '../utils/FeatureButton';
import { connect } from 'react-redux';
import State from '../app/store/state';
import { AuthState } from '../login/auth';
import { bindActionCreators } from 'redux';
import {
    ActionCreators,
    defaultVendorUsersSortingOrder,
    Vendor,
    VendorUser,
    vendorUserColumnToAttributeMapping,
    VendorUserSortAttributes,
    VendorUserTableColumns,
} from './vendors';
import { useCallback, useState } from 'react';
import { SortableTableHead } from '../utils/table/table-head/sortable-table-head';
import { VendorUserRow } from './VendorUserRow';
import { ChangeSortOptions, SortOptions } from '../utils/hooks/useSort';
import { VendorUserFilters } from './VendorUserFilters';
import { ManagerPermission } from '../permission-profiles/permission';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

interface OwnProps {
    params: { vendorId: string };
}

interface Props {
    vendor: Vendor;
    auth: AuthState;
    vendorId: string;
    vendorUsersList: Array<VendorUser>;
    authorizeVendorUser: (vendorUserId: string) => void;
    requestPasswordReset: (vendorUser: VendorUser) => void;
    redirectToPage: (url: string) => void;
    cancelVendorUser: (vendorUserId: string) => void;
}

function VendorUserList({
    auth,
    vendorId,
    vendorUsersList,
    vendor,
    authorizeVendorUser,
    requestPasswordReset,
    redirectToPage,
    cancelVendorUser,
}: Props) {
    const [sortOptions, setSortOptions] = useState<SortOptions<VendorUserSortAttributes>>(
        defaultVendorUsersSortingOrder
    );

    const handleVendorUserChosen = useCallback(
        (id) => () => {
            redirectToPage(`/vendors/${vendorId}/panel/${id}`);
        },
        [vendorId]
    );
    const handleSort: ChangeSortOptions<VendorUserTableColumns> = useCallback(
        ([column, direction]) => {
            if (column) {
                setSortOptions(
                    new SortOptions<VendorUserSortAttributes>({
                        column: vendorUserColumnToAttributeMapping[column],
                        direction,
                    })
                );
            } else {
                setSortOptions(defaultVendorUsersSortingOrder);
            }
        },
        [vendorId]
    );
    return (
        <div>
            {vendor?.authorizedAt && (
                <FeatureButton
                    linkTo={`/vendors/${vendorId}/panel/add-user`}
                    manager={auth.manager}
                    restrictedToFeatures={[ManagerPermission.vendorUserPark]}
                    title={'Add Vendor User'}
                />
            )}
            <VendorUserFilters sortOptions={sortOptions} vendorId={vendorId} />
            <table className="wfp-table mt4 table-hover">
                <SortableTableHead changeSortOptions={handleSort}>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <SortableTableHead.DisabledColumn />
                </SortableTableHead>
                <tbody>
                    {vendorUsersList?.map((vendorUser) => (
                        <VendorUserRow
                            auth={auth}
                            authorizeVendorUser={authorizeVendorUser}
                            cancelVendorUser={cancelVendorUser}
                            key={vendorUser.id}
                            onVendorUserChosen={handleVendorUserChosen(vendorUser.id)}
                            requestPasswordReset={requestPasswordReset}
                            vendorUser={vendorUser}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function mapStateToProps(state: State, ownProps: OwnProps) {
    return {
        auth: state.auth,
        vendorId: ownProps.params.vendorId,
        vendorUsersList: state.vendors.usersList,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        authorizeVendorUser: bindActionCreators(ActionCreators.authorizeVendorUser, dispatch),
        cancelVendorUser: bindActionCreators(ActionCreators.cancelVendorUserPark, dispatch),
        requestPasswordReset: bindActionCreators(ActionCreators.requestPasswordReset, dispatch),
        redirectToPage: (url) => dispatch(routerHelperActions.makeRedirect(url)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorUserList as any);
