import * as React from 'react';
import { DoneIcon } from '../icons/Done.svg';
import { PencilIcon } from '../icons/Pencil.svg';
import { ClearIcon } from '../icons/Clear.svg';
import { useDocumentSection } from './utils/useDocumentSection';

interface Props {
    hasPermission: boolean;
    name: string;
    onSave(newValue: string, oldValue: string): Promise<void>;
}

export function DocumentSection({ hasPermission, name, onSave }: Props) {
    const { edit, value, handleChange, handleEdit, handleCancel, toggleEdit } = useDocumentSection(name, onSave);
    return (
        <h5>
            {edit ? <input autoFocus onChange={handleChange} value={value} /> : name}
            {hasPermission &&
                (edit ? (
                    <>
                        <button onClick={handleCancel}>
                            <ClearIcon />
                        </button>
                        <button onClick={handleEdit}>
                            <DoneIcon />
                        </button>
                    </>
                ) : (
                    <button onClick={toggleEdit}>
                        <PencilIcon />
                    </button>
                ))}
        </h5>
    );
}
