import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorBanner } from '../../show-error-banner';
import { ProductGroup } from '../../../product-management/utils/create-prices-form.utils';
import { ReactSelectOption } from '../../react-select-option';
import { getAllProductGroups } from '../../../api/product-management';

type ReturnArray = [{ label: string; value: string }[], () => Promise<void>];

export function useProductGroupsOptions(constructor = ReactSelectOption): ReturnArray {
    const [productGroups, setProductGroups] = useState([]);
    const dispatch = useDispatch();
    const errorHandler = useMemo(() => showErrorBanner(dispatch), []);
    const load = useCallback(async () => {
        return getAllProductGroups()
            .then((productGroups: ProductGroup[]) => productGroups.map((group) => new constructor(group)))
            .then(setProductGroups)
            .catch(errorHandler('product groups'));
    }, []);
    useEffect(() => {
        load();
    }, []);

    return [productGroups, load];
}
