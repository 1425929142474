import * as React from 'react';
import { hasFeatureAccess } from '../login/auth';
import { ActionCreators, TransactionRequest, UpdateStateListType } from './taskAuthorizations';
import AppState from '../app/store/state';
import PagingComponent, { PagedState } from '../utils/paging';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RowMenuProps, RowMenu } from '../utils/RowMenu';
import { ConfirmationDialog } from '../utils/Dialogs';
import RejectionDialog from '../utils/RejectionDialog';
import TransactionRequestCell from './TransactionRequestCell';
import NoDataPlaceholder from './NoDataPlaceholder';
import { currencyFormatter } from '../utils/utils';
import { EntitlementCurrencyConfig } from '../app/appConfig';
import { ManagerPermission } from '../permission-profiles/permission';

interface Props {
    activeListType: UpdateStateListType;
    authorizedManagerId: string;
    isReadonly: boolean;
    transactionRequests: PagedState<TransactionRequest>;
    currency: EntitlementCurrencyConfig;

    loadTransactionRequests: (requestsType: UpdateStateListType, page: number, limit: number) => void;
    confirmTransactionRequest: (transactionRequestId: string) => void;
    rejectTransactionRequest: (transactionRequestId: string, comment: string) => void;
    changeListType: (activeListType: UpdateStateListType) => void;
}

interface State {
    amount?: number;
    originalTransactionToBePosted?: number;
    originalTransactionToBeRejected?: number;
    transactionToBePosted?: string;
    transactionToBeRejected?: string;
}

const PageLimit = 15;

function AuthorizationRowMenu(props: RowMenuProps<UpdateStateListType>) {
    return RowMenu(props);
}

class TransactionAuthorizationPage extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.loadTransactionRequests(this.props.activeListType, 1, PageLimit);
    }

    renderTopMenu() {
        const requestsTabs = [UpdateStateListType.Requested, UpdateStateListType.Posted, UpdateStateListType.Rejected];
        return (
            <AuthorizationRowMenu
                activeTab={this.props.activeListType}
                onSelectTab={this.changeRequestsTab.bind(this)}
                tabs={requestsTabs}
            />
        );
    }

    changeRequestsTab(activeListType) {
        const { changeListType, loadTransactionRequests } = this.props;
        changeListType(activeListType);
        loadTransactionRequests(activeListType, 1, PageLimit);
    }

    onCloseDialog() {
        this.setState({
            amount: null,
            originalTransactionToBePosted: null,
            originalTransactionToBeRejected: null,
            transactionToBePosted: null,
            transactionToBeRejected: null,
        });
    }

    renderPostingDialog() {
        const newValue = currencyFormatter(this.props.currency).format(this.state.amount);
        return (
            <ConfirmationDialog
                message={`Do you really want to post transaction request for transaction "${this.state.originalTransactionToBePosted}" on new value: ${newValue}?`}
                onClose={this.onCloseDialog.bind(this)}
                onConfirm={() => {
                    this.props.confirmTransactionRequest(this.state.transactionToBePosted);
                    this.onCloseDialog();
                }}
                title="Post transaction request"
            />
        );
    }

    renderRejectionDialog() {
        return (
            <RejectionDialog
                message={`Do you really want to reject transaction request for transaction "${this.state.originalTransactionToBeRejected}"?`}
                onClose={this.onCloseDialog.bind(this)}
                onConfirm={(comment) => {
                    this.props.rejectTransactionRequest(this.state.transactionToBeRejected, comment);
                    this.onCloseDialog();
                }}
                title="Reject transaction request"
            />
        );
    }

    render() {
        const { isReadonly, transactionRequests, authorizedManagerId } = this.props;
        const {
            originalTransactionToBePosted,
            originalTransactionToBeRejected,
            transactionToBePosted,
            transactionToBeRejected,
        } = this.state;
        const hasUpdateRequests = transactionRequests.items.length > 0;
        return (
            <div>
                {this.renderTopMenu()}
                {originalTransactionToBePosted && transactionToBePosted && this.renderPostingDialog()}
                {originalTransactionToBeRejected && transactionToBeRejected && this.renderRejectionDialog()}
                {transactionRequests.items.map((transactionRequest) => {
                    const authorization = transactionRequest.authorization;
                    const readonly = isReadonly || !!authorization.authorizedAt || !!authorization.deletedAt;
                    return (
                        <TransactionRequestCell
                            authorizedManagerId={authorizedManagerId}
                            currency={this.props.currency}
                            isRequested={this.props.activeListType === UpdateStateListType.Requested}
                            key={transactionRequest.id}
                            onPost={(transactionRequestId, originalTransactionRequestId, amount) => {
                                this.setState({
                                    transactionToBePosted: transactionRequestId,
                                    originalTransactionToBePosted: originalTransactionRequestId,
                                    amount: amount,
                                });
                            }}
                            onReject={(transactionRequestId, originalTransactionRequestId) => {
                                this.setState({
                                    transactionToBeRejected: transactionRequestId,
                                    originalTransactionToBeRejected: originalTransactionRequestId,
                                });
                            }}
                            readonly={readonly}
                            transactionRequest={transactionRequest}
                        />
                    );
                })}
                {!hasUpdateRequests && <NoDataPlaceholder listType={this.props.activeListType} />}
                <PagingComponent
                    onPageChanged={(page) =>
                        this.props.loadTransactionRequests(this.props.activeListType, page, PageLimit)
                    }
                    paging={transactionRequests.paging}
                />
            </div>
        );
    }
}

function mapStateToProps(state: AppState) {
    const isReadonly = !hasFeatureAccess(state.auth, ManagerPermission.transactionRequestPost);
    return {
        authorizedManagerId: state.auth.manager ? state.auth.manager.id : null,
        isReadonly: isReadonly,
        transactionRequests: state.taskAuthorizations.transactionRequests,
        activeListType: state.taskAuthorizations.activeListType,
        currency: state.appConfig.entitlementCurrencyConfig,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadTransactionRequests: bindActionCreators(ActionCreators.loadTransactionRequests, dispatch),
        confirmTransactionRequest: bindActionCreators(ActionCreators.confirmTransactionRequest, dispatch),
        rejectTransactionRequest: bindActionCreators(ActionCreators.rejectTransactionRequest, dispatch),
        changeListType: bindActionCreators(ActionCreators.changeListType, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(TransactionAuthorizationPage);
