import * as React from 'react';
import { wfpFormat } from '../utils/utils';
import { VendorStatuses, VendorUser } from './vendors';
import { AuthState, hasFeatureAccess } from '../login/auth';
import { ManagerStatus } from '../managers/manager';
import { stopPropagation } from '../utils/events';
import { useMemo } from 'react';
import { ManagerPermission } from '../permission-profiles/permission';

export function VendorUserRow({
    vendorUser,
    onVendorUserChosen,
    auth,
    authorizeVendorUser,
    requestPasswordReset,
    cancelVendorUser,
}: {
    vendorUser: VendorUser;
    onVendorUserChosen(): void;
    authorizeVendorUser(arg: string): void;
    auth: AuthState;
    requestPasswordReset(arg: VendorUser): void;
    cancelVendorUser(vendorUser: string): void;
}) {
    const trClassName = useMemo(
        () =>
            'cursor-pointer ' +
            (vendorUser.status === ManagerStatus.blocked ? 'failed' : '') +
            (vendorUser.pendingEntityUpdates.length > 0 || vendorUser.isPasswordBlocked ? 'attention-color' : ''),
        [vendorUser.status, vendorUser.pendingEntityUpdates.length, vendorUser.isPasswordBlocked]
    );
    const { canPostAction, canCancelAction } = canNextActions(auth, vendorUser);
    return (
        <tr className={trClassName} key={vendorUser.id} onClick={onVendorUserChosen}>
            <td>{vendorUser.firstName}</td>
            <td>{vendorUser.lastName}</td>
            <td>{vendorUser.email}</td>
            <td>{wfpFormat(vendorUser.status)}</td>
            <td>
                {canCancelAction && (
                    <a className="mr-3" onClick={stopPropagation(() => cancelVendorUser(vendorUser.id))}>
                        Cancel
                    </a>
                )}
                {canPostAction && <a onClick={stopPropagation(() => authorizeVendorUser(vendorUser.id))}>Post</a>}
                <PasswordAction auth={auth} requestPasswordReset={requestPasswordReset} vendorUser={vendorUser} />
            </td>
        </tr>
    );
}

function canNextActions(
    auth,
    vendorUser
): {
    canPostAction: boolean;
    canCancelAction: boolean;
} {
    if (!auth?.manager?.id) {
        return { canPostAction: false, canCancelAction: false };
    }

    const canPostAction =
        vendorUser.status === VendorStatuses.parked &&
        hasFeatureAccess(auth, ManagerPermission.vendorUserPost) &&
        Number(vendorUser.createdByManager.id) !== Number(auth.manager.id);

    const canCancelAction =
        vendorUser.status === VendorStatuses.parked &&
        (hasFeatureAccess(auth, ManagerPermission.vendorUserPost) ||
            Number(vendorUser.createdByManager.id) === Number(auth.manager.id));

    return { canPostAction, canCancelAction };
}

function PasswordAction({
    auth,
    vendorUser,
    requestPasswordReset,
}: {
    auth: AuthState;
    requestPasswordReset(arg: VendorUser): void;
    vendorUser: VendorUser;
}) {
    const isActive = vendorUser.status === 'active';

    return auth?.manager &&
        hasFeatureAccess(auth, ManagerPermission.vendorPasswordReset) &&
        vendorUser.authorizedAt &&
        isActive ? (
        <a onClick={stopPropagation(() => requestPasswordReset(vendorUser))}>Reset Password</a>
    ) : null;
}
