import * as React from 'react';
import { useEffect, useState } from 'react';
import { loadPrices, loadProductVendors } from '../apiClient';

export const ProductsVendors: React.FC = function ProductsVendors() {
    const [vendors, setVendors] = useState([]);

    useEffect(() => {
        Promise.all([loadProductVendors(), loadPrices()]).then(([vendors, prices]) => {
            setVendors(vendors);
        });
    }, []);

    return (
        <div>
            <h2>Vendors</h2>
            <table className="wfp-table mt4 wfp-table--striped table-hover">
                <thead>
                    <tr>
                        <th>WFP Vendor</th>
                        <th>Nickname</th>
                        <th>Product Count</th>
                    </tr>
                </thead>
                <tbody>
                    {vendors.map((vendor) => (
                        <VendorRow key={vendor.id} vendor={vendor} />
                    ))}
                </tbody>
            </table>

            {/*{JSON.stringify(vendors)}*/}
            {/*{JSON.stringify(products)}*/}
        </div>
    );
};

function VendorRow({ vendor }: { vendor }) {
    return (
        <tr>
            <td>{vendor.sapId}</td>
            <td>{vendor.nickname}</td>
            <td>{vendor.pricesCount}</td>
        </tr>
    );
}
